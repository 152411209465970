import React, { useEffect, useState } from "react";
import { Form, message } from "antd";
import { SaveButton, CancelButton, ModalComponent, trans, FormItem } from "@comps/components";
import { makeRequest } from "@utils/helpers";
import { updateLocationReq } from "../requests";
import { Commons, Const } from "../../constants";
const langKey = Const["location"].lngKey
const formName = "updateLocation";
const UpdateLocation = (props) => {
  const [form] = Form.useForm()
  const [loader, setLoader] = useState(false);
  const [saving, setSaving] = useState(false);
  useEffect(() => {
    setFormDataFromProps()
    // eslint-disable-next-line
  }, []);

  const setFormDataFromProps = () => {
    form.setFieldsValue({
      "name": props.record.name,
    })
  }
  const onSubmit = (data) => {
    setSaving(true)
    const payload = { name: data.name, id: props.record.id }
    makeRequest(setLoader, updateLocationReq, payload, onSuccess, onError);
  }
  const onSuccess = (data, res) => {
    setSaving(false)
    message.success("Location updated successfully");
    props.onCompleted(data.locations.data);
  }
  const onError = (err, res) => {
    setSaving(false)
    message.error(res.response.data.message);
    //setErrors(errorList);
  }
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------
  const footer = [
    <SaveButton loading={ saving } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];
  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------
  return (
    <ModalComponent top={ 20 } maskClosable={ false } mainTitle={ trans(Commons.update) } subTitle={ trans(`${langKey}.moduleName`) } open={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Form
        form={ form }
        name={ formName }
        layout="vertical"
        onFinish={ onSubmit }
        disabled={ props.disabled }
      >
        <FormItem name="name" type="text" label="Name" rule={ rules.name } />
      </Form>
    </ModalComponent>
  )
}

export default UpdateLocation
const rules = {
  name: [
    { required: true, message: trans(`${langKey}.name.required`) },
  ],
};
