import React, { useEffect, useState } from "react";
import { Form, message } from "antd";
import { SaveButton, CancelButton, ModalComponent, trans, InputTime, InputDate, InputTextArea, SelectResourceList } from "@comps/components"
import { makeRequest, makeRequestStateless, getErrorProps } from "@utils/helpers";
import { createDataReq, getRosterReq } from "../requests";
import { Commons, Const } from "../../../constants";
import { useSelector } from "react-redux";
import RoasterView from "@mods/common/RoasterView";
import moment from "moment";
import { getEmploymentsReq } from "../../../leave/companyLeaves/requests";
import { createSelectList } from '@comps/commonFunctions';
const langKey = Const["relaxationRequest"].lngKey
const formName = "createRelaxationRequest";
const CreateRelaxationRequest = (props) => {
  const authUser = useSelector((state) => state.auth.authUser);
  const [loader, setLoader] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [roasterView, setRoasterView] = useState(null)
  const [shifts, setShifts] = useState({})
  const [employments, setEmployments] = useState([])
  const [form] = Form.useForm()
  useEffect(() => {
    getEmployments()
    // eslint-disable-next-line
  }, []);

  const getEmployments = () => {

    if (props.relaxationof === "company") {
      const payload = null
      makeRequestStateless(getEmploymentsReq, payload, onGetEmployments, false)
    }
  }
  const onGetEmployments = (response) => {
    setEmployments(createSelectList(response.resources.data))
  }

  const getRoster = (date) => {
    const payload = { relaxation: { employment_id: authUser.employment_id, date: date.format('YYYY-MM-DD') } }
    makeRequest(setLoader, getRosterReq, payload, onGetRosterSuccess, onGetRosterSuccess);
  }
  const onGetRosterSuccess = (response, data) => {
    if (data.shifts) {
      setRoasterView(<RoasterView record={ data } />)
      setShifts(data.shifts[moment(data.from_date).format('YYYYMMDD') + '-' + data.employments[0].id][0]);
      let start_time = data.shifts[moment(data.from_date).format('YYYYMMDD') + '-' + data.employments[0].id][0]['start']
      let end_time = data.shifts[moment(data.from_date).format('YYYYMMDD') + '-' + data.employments[0].id][0]['end']

      form.setFieldsValue({
        start_time: moment(start_time),
        end_time: moment(end_time),
      })
    }
  }
  const onSubmit = (data) => {

    setSaveLoader(true)
    const payload = { relaxation: { shift: shifts, employment_id: shifts.employment_id, date: data.date, start_time: data.start_time.format('hh:mm a'), end_time: data.end_time.format('hh:mm a'), reason: data.reason, employee_shift_id: shifts.id } };
    makeRequest(setLoader, createDataReq, payload, onSuccess, onError);
  }

  const onSuccess = (data, res) => {
    setSaveLoader(false)
    message.success("Saved successfully");
    props.onCompleted(data.relaxations.data);
    props.onSuccess(data)
  }

  const onError = (err, res) => {
    setSaveLoader(false)
    message.error(res.response.data.message);
  }
  //-----------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------
  const footer = [
    <SaveButton loading={ saveLoader } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];
  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------
  return (
    <ModalComponent top={ 20 } width={ 1000 } maskClosable={ false }
      mainTitle={ trans(Commons.create) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Form { ...formLayout } form={ form } name={ formName } onFinish={ onSubmit } scrollToFirstError>

        {
          props.relaxationof === "company" ?
            <Form.Item name="employment_id" rules={ rules.employment_id } label={ trans(`${langKey}.employment_id`) }
              className="da-mb-16"
              { ...getErrorProps(errors['name']) } size="small"
            >
              <SelectResourceList type='employee'
              //onChange={ onChangeEmployment }
              />
            </Form.Item>
            : ''
        }
        <Form.Item name="date" rules={ rules.date } label={ trans(`${langKey}.date`) }
          className="da-mb-16"
          { ...getErrorProps(errors['date']) } size="small"
        >
          <InputDate onChange={ (e) => getRoster(e) } />
        </Form.Item>
        { roasterView }
        <Form.Item name="start_time" rules={ rules.start_time } label={ trans(`${langKey}.start_time`) }
          className="da-mb-16"
          { ...getErrorProps(errors['start_time']) } size="small"
        >
          <InputTime placeholder={ trans(`${langKey}.start_time`) } />
        </Form.Item>
        <Form.Item name="end_time" rules={ rules.end_time } label={ trans(`${langKey}.end_time`) }
          className="da-mb-16"
          { ...getErrorProps(errors['end_time']) } size="small"
        >
          <InputTime placeholder={ trans(`${langKey}.end_time`) } />
        </Form.Item>

        <Form.Item name="reason" rules={ rules.reason } label={ trans(`${langKey}.reason`) }
          className="da-mb-16"
          { ...getErrorProps(errors['reason']) } size="small"
        >
          <InputTextArea placeholder={ trans(`${langKey}.reason`) } />
        </Form.Item>

      </Form>
    </ModalComponent>
  )
}

export default CreateRelaxationRequest

const rules = {
  date: [
    { required: true, message: trans(`${langKey}.date`) },
  ],
  start_time: [
    { required: true, message: trans(`${langKey}.start_time`) },
  ],
  end_time: [
    { required: true, message: trans(`${langKey}.end_time`) },
  ],
  reason: [
    { required: true, message: trans(`${langKey}.reason`) },
  ],
};

const formLayout = {
  // layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 14, offset: 2 },
  labelAlign: "left"
}