import { get, del, postFd } from "@utils/axios";

const api = "employee";

export const getAllRelativesReq = (payload) => {
  return get(`${api}/relatives?page=${payload.page}&filters=${payload.filters}`);
};

export const createRelativeReq = (payload) => {
  return postFd(`${api}/relatives?&filters=${payload.filters}`, payload);
};

export const updateRelativeReq = (payload) => {
  return postFd(`${api}/relatives/${payload.id}/update?&filters=${payload.filters}`, payload);
};

export const deleteRelativeReq = (id) => {
  return del(`${api}/relatives/${id}`);
};

//-----------on create
export const getAllEmployeesReq = () => {
  return get("resources/employee?page=1");
};

export const getRelatedDataReq = () => {
  return get(`${api}/relatives/get-related-data?`)
}

export const getEmployeesRelativeByIdReq = (id) => {
  return get(`${api}/relatives/employee_related_data/${id}`)
}
export const getFilterReq = (id) => {
  return get(`filters?company_id=1&fields=page=1&filters=supporting_fields_|is_dependant,relation_name,employees;preset_|employee_relatives;`)
}
