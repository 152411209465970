import React, { useEffect, useState } from "react";
import { Button, Form, message, Row, Checkbox, Col } from "antd";
import { trans, InputDate, SelectWithSearch, AddNewButton } from "@comps/components"
import "./Roster.css"

import { getErrorProps } from "@utils/helpers";

import { Const } from "../constants";
import CreateTimeSlot from '../timeSlots/components/CreateTimeSlot';
import { createSelectList } from '@comps/commonFunctions';
const StepOne = (props) => {
  const langKey = Const["companyRoaster"].lngKey
  //========================use states start========================
  const [errors, setErrors] = useState([]);
  //------------intitial steps state---------
  const [dates, setDates] = useState([]);
  const [isRepeat, setIsRepeat] = useState(false);
  const [fixStartDate, setFixStartDate] = useState(null);
  const [daysOn, setDaysOn] = useState([]);
  const [childComponent, setChildComponent] = useState(null);
  const [timeSlots, setTimeSlots] = useState([]);
  const [form] = Form.useForm()
  //=======================================
  useEffect(() => {
    setData();
    // eslint-disable-next-line
  }, [props]);

  //set props data
  //=================Submit First Step 1==============================================
  const setData = () => {
    setTimeSlots(props.timeSlots)
    if (props.initialValues) {
      let start = props.initialValues.start_date.format('YYYY-MM-DD');
      let end = props.initialValues.end_date.format('YYYY-MM-DD');
      if (end > start) {
        setIsRepeat(true)
      }
    }
  }

  const onSelectDays = (day) => {
    setDaysOn(day)
  }
  const onChangeStartDate = (date) => {
    setFixStartDate(date)
  }
  const onChangeEndDate = (date) => {
    let start = fixStartDate ? fixStartDate.format('YYYY-MM-DD') : null;
    let end = date ? date.format('YYYY-MM-DD') : null
    if (date !== null) {
      if (end < start) {
        message.error('End Date should be greater than start date')
        setIsRepeat(false)
        return
      }
      if (start === end) {
        setIsRepeat(false)
      }
      else {
        setIsRepeat(true)
      }
    }
    if (date === null) {
      setIsRepeat(false)
    }
  }
  const days = [
    { value: 'Mon', label: 'Mon' },
    { value: 'Tue', label: 'Tue' },
    { value: 'Wed', label: 'Wed' },
    { value: 'Thu', label: 'Thu' },
    { value: 'Fri', label: 'Fri' },
    { value: 'Sat', label: 'Sat' },
    { value: 'Sun', label: 'Sun' },
  ];
  const onSubmitFirstStep = (data) => {
    if (data.end_date === undefined || data.end_date === null) {
      data.end_date = data.start_date;
    }
    if (data.end_date.format('YYYY-MM-DD') < data.start_date.format('YYYY-MM-DD')) {
      message.error('End Date can only be greater Than or equal to Start Date!')
      return
    }

    let dateRange =data.days && data.days.length > 0 ? getDatesRange(data.start_date, data.end_date, data.days) : [data.start_date.format('YYYY-MM-DD')];
    setDates(dateRange);
    //data.dates = dateRange;
    props.response(data, dateRange);
  }
  const getDatesRange = (start, end, daysOn) => {
    let startDate = start;
    let endDate = end;
    let daterange = []
    while (startDate <= endDate) {
      const day = startDate.format('dddd').substring(0, 3);
      if (daysOn.includes(day)) { //only push dates in selected days
        daterange.push(startDate.format('YYYY-MM-DD'));
      }
      startDate = startDate.add(1, 'days');
    }
    return daterange;
  }


  const onTimeSlotCreated = (data) => {
    if (data.length) {
      setTimeSlots(createSelectList(data))
      form.setFieldValue('time_slot_id', data[0].id)
    }
    setChildComponent(null);
  }
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  return (
    <>
      <Form
        // { ...formLayout } 
        layout="vertical"
        name={ 'steOneForm' }
        onFinish={ onSubmitFirstStep }
        initialValues={ props.initialValues ? props.initialValues : null }
        form={ form }
      >
        <Row gutter={ 20 }>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="start_date" rules={ rules.start_date } label={ trans(`${langKey}.start_date`) }
              className="da-mb-16"
              { ...getErrorProps(errors['name']) } size="small"
            >
              <InputDate
                placeholder={ "Select Roaster Start Date" }
                onChange={ onChangeStartDate } />
            </Form.Item>
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="end_date" rules={ []} label={ trans(`${langKey}.end_date`) }
              className="da-mb-16"
              { ...getErrorProps(errors['name']) } size="small"
            >
              <InputDate
                placeholder={ "Select Roaster End Date" }
                onChange={ onChangeEndDate }
              />
            </Form.Item>
          </Col>
          {
            isRepeat ?
              <>
                <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                  <Form.Item name="days" rules={ rules.days } label={ trans(`${langKey}.days`) } className="da-mb-16"
                    { ...getErrorProps(errors['name']) } size="small"
                  >
                    <Checkbox.Group
                      style={ { width: "100%" } }
                      value={ daysOn }
                      onChange={ onSelectDays }
                      options={ days }
                    >
                      { days.map((day) => (
                        <Checkbox key={ day.value } value={ day.value }>
                          { day.label }
                        </Checkbox>
                      )) }
                    </Checkbox.Group>
                  </Form.Item>
                </Col>
              </>
              : ''
          }

          { childComponent }
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <AddNewButton text="Time Slot" onClick={ () => setChildComponent(<CreateTimeSlot onCreated={ onTimeSlotCreated } />) } />
            <Form.Item name="time_slot_id" rules={ rules.time_slot_id } label={ trans(`${langKey}.time_slot_id`) }
              className="da-mb-10"
              { ...getErrorProps(errors['name']) } size="small"
            >
              <SelectWithSearch
                options={ timeSlots }
                placeholder={ trans(`${langKey}.time_slot_id`) }
              />
            </Form.Item>
          </Col>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="is_full_overtime_shift" rules={ rules.is_full_overtime_shift } label={ trans(`${langKey}.is_full_overtime_shift`) }
              className="da-mb-10"
              { ...getErrorProps(errors['name']) } size="small"
              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>
          </Col>
        </Row>

        <div className="button-style">
          <Button className="button-next" type="primary" htmlType="submit" >Next</Button>
          {/* <Button className="button-back" disabled={ currentStep ? false : true }>Back</Button> */ }
        </div>
      </Form>
    </>
  )
}
export default StepOne
const rules = {
  start_date: [
    { required: true, message: 'Required' },
  ],
  end_date: [
    { required: true, message: 'Required' },
  ],
  repeat: [
    { required: true, message: 'Required' },
  ],
  font_colour: [
    { required: true, message: 'Required' },
  ],
  bg_colour: [
    { required: true, message: 'Required' },
  ],
  days: [
    { required: true, message: 'Please Select At least one day' },
  ],

  time_slot_id: [
    { required: true, message: 'Please Select Time Slot' },
  ]

};
