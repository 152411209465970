import React from "react";
import { Route, Routes } from "react-router-dom";
import IndexSystemMasters from "./IndexSystemMaster";

const SystemMasterModules = () => {
  return (
    <Routes>
      <Route path="/" element={ <IndexSystemMasters /> } />
    </Routes>
  );
}

export default SystemMasterModules;
