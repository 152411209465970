import React, { useEffect, useState } from "react";
import { Form, message, Skeleton } from "antd";
import { SaveButton, InputCheck, SelectResourceList, CancelButton, ModalComponent, trans } from "@comps/components"
import { makeRequest, makeRequestStateless, } from "@utils/helpers";
import { createDataReq, getRelatedDataReq } from "../requests";
import { Commons, Const } from "../../constants";
import { createSelectList } from '@comps/commonFunctions';
import { getEmploymentsDataReq } from "@mods/common/requests";
import ViewPrivilegeFilters from "./ViewPrivilegeFilters";
import CreatePrivilegeFilters from "./CreatePrivilegeFilters";
import UpdatePrivilegeFilters from "./UpdatePrivilegeFilters";

const langKey = Const["privilege"].lngKey
const formName = "createPrivilege";

const CreatePrivilege = (props) => {
  const [loader, setLoader] = useState(false);
  const [saving, setsaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const [employments, setEmployments] = useState([])
  const [relatedData, setRelatedData] = useState('')
  const [allPriviliges, setAllPrivileges] = useState(false);
  const [childComponent, setChildComponent] = useState(null)
  const [privilegeFilters, setPrivilegeFilters] = useState([])
  const [form] = Form.useForm()
  useEffect(() => {
    getData()
    // eslint-disable-next-line
  }, []);

  const getData = () => {
    getEmployments()
    getRelatedData()
  }
  const getRelatedData = () => {
    makeRequestStateless(getRelatedDataReq, null, onGetRelated, false)
  }
  const onGetRelated = (response, data) => {
    setRelatedData(response)
  }
  const getEmployments = () => {
    const payload = { filters: null }
    makeRequestStateless(getEmploymentsDataReq, payload, onGetEmployments, false)
  }
  const onGetEmployments = (response) => {
    setLoading(false)
    setEmployments(createSelectList(response.resources.data))
  }

  const onSubmit = (data) => {

    if (allPriviliges && data.privilege_filters !== undefined && data.privilege_filters.length < 1) {
      message.info('Please Add Criteria!');
      return
    }

    const payload = { privilege: { ...data, all_privileges: allPriviliges } };
    makeRequest(setLoader, createDataReq, payload, onSuccess, onError);
  }

  const onSuccess = (data, res) => {

    message.success(res.message);
    props.onCompleted(data.ip_restrictions.data);
    props.onSuccess(data)
  }

  const onError = (err, res) => {

    setsaving(false)
    message.error(res.response.data.message);
  }
  // ==================================slabs actions =================================
  const onCreate = () => {
    setChildComponent(<CreatePrivilegeFilters onCompleted={ onCompleted } allData={ relatedData } employments={ employments } />)
  }

  //edit slab
  const onEdit = (key) => {
    const record = form.getFieldValue(`privilege_filters`)[key];
    setChildComponent(<UpdatePrivilegeFilters onCompleted={ onCompleted } allData={ relatedData } record={ { ...record, key: key } } disabled={ false } employments={ employments } />)
  };
  const onView = (key) => {
    const record = form.getFieldValue(`privilege_filters`)[key];
    setChildComponent(<UpdatePrivilegeFilters onCompleted={ onCompleted } allData={ relatedData } record={ { ...record, key: key } } disabled={ true } employments={ employments } />)
  };
  //set deleted rows
  const setRows = (key, state) => {

  }
  //on submit slabs
  const onCompleted = (data, action) => {

    let listArray = form.getFieldValue('privilege_filters');
    if (listArray === undefined) {
      listArray = []
    }
    if (data && action === 'Added') {
      listArray.push(data)
      form.setFieldValue('privilege_filters', listArray)
      setPrivilegeFilters(listArray)
    }
    if (data && action === 'Updated') {

      listArray[data.key] = data
      form.setFieldValue('privilege_filters', listArray)
      setPrivilegeFilters(listArray)
    }
    setChildComponent(null)
  }

  //-----------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [
    <SaveButton loading={ saving } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } width={ 600 } maskClosable={ false }
      mainTitle={ trans(Commons.create) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) } >
      { childComponent }
      <Skeleton loading={ loading }>
        <Form { ...formLayout } name={ formName } onFinish={ onSubmit } form={ form }>

          <Form.Item name="employment_id" rules={ rules.employment_id } label={ trans(`${langKey}.employment_id`) }
            className="da-mb-16"
          >
            <SelectResourceList type='employment' />
          </Form.Item>
          <Form.Item name="all_privileges" rules={ rules.all_privileges } label={ trans(`${langKey}.all_privileges`) }
            className="da-mb-16"
            valuePropName="checked"
          >
            <InputCheck onChange={ (e) => setAllPrivileges(e.target.checked) } />
          </Form.Item>

          {
            !allPriviliges &&
            <>
              <div className="formlist-container">
                <h5 className="formlist-title">{ trans(`${langKey}.privilege_filters`) }</h5>
                <ViewPrivilegeFilters setRows={ setRows } onCreate={ onCreate } onEdit={ onEdit } onView={ onView } />
              </div>
            </>
          }

        </Form>
      </Skeleton>

    </ModalComponent>
  )
}

export default CreatePrivilege

const rules = {
  employment_id: [
    { required: true, message: trans(`${langKey}.employment_id`) },
  ],

};

const formLayout = {
  layout: "horizontal",
  labelCol: { span: 6 },
  wrapperCol: { span: 14, offset: 2 },
  labelAlign: "left"
}
