import React from "react";
import { Route, Routes } from "react-router-dom";
import IndexCompanyTransferRequests from "./transfers/companyTransfers/IndexCompanyTransferRequests";
import IndexTransferApprovals from "./transfers/transferApproval/IndexTransferApprovals";
import IndexSubordinateTransferRequests from "./transfers/subordinateTransfers/IndexSubordinateTransferRequests";
import IndexCompanyPromotionRequests from "./promotions/companyPromotions/IndexCompanyPromotionRequests";
import IndexPromotionApprovals from "./promotions/promotionApproval/IndexPromotionApprovals";
import IndexSubordinatePromotionRequests from "./promotions/subordinatePromotions/IndexSubordinatePromotionRequests";
import IndexTranferConfigurations from "./transfers/settings/configuration/IndexTransferConfigurations";



const TransferPromotionModules = () => {
  return (
    <Routes>
      <Route path="transfers/company-requests" element={ <IndexCompanyTransferRequests modkey="companyTransfers"/> } />
      <Route path="transfers/subordinate-requests" element={ <IndexSubordinateTransferRequests modkey="subordinateTransfers"/> } />
      <Route path="transfers/approvals" element={ <IndexTransferApprovals api="transferApprovals" modkey="transferApprovals"/> } />
      <Route path="transfers/delegates" element={ <IndexTransferApprovals api="transferDelegates" modkey="transferDelegates"/> } />
      <Route path="promotions/company-requests" element={ <IndexCompanyPromotionRequests modkey="companyPromotions"/> } />
      <Route path="promotions/subordinate-requests" element={ <IndexSubordinatePromotionRequests modkey="subordinatePromotions"/> } />
      <Route path="promotions/approvals" element={ <IndexPromotionApprovals api="promotionApprovals" modkey="promotionApprovals"/> } />
      <Route path="promotions/delegates" element={ <IndexPromotionApprovals api="promotionDelegates" modkey="promotionDelegates"/> } />
      <Route path="settings/configurations" element={ <IndexTranferConfigurations api="transferConfigurations" modkey="transferConfigurations"/> } />
    </Routes>
  );
}

export default TransferPromotionModules;
