import { get, del, post, put } from "@utils/axios";

const api = "customPayitems";

export const getAllDataReq = (payload) => {
  return get(`payitem/${payload.id}/${api}?page=${payload.page}&filters=${payload.filters}`)
};

export const getFormulasReq = (payload) => {
  return post(`formulas/get-formulas`, payload);
}

export const createDataReq = (payload) => {
  return post(`payitem/${payload.payitemId}/${api}?page=1`,payload)
};

export const updateDataReq = (payload) => {
  return put(`payitem/${payload.payitemId}/${api}/${payload.id}`,payload)
};

export const deleteDataReq = (payload) => {
  return del(`payitem/${payload.payitemId}/${api}/${payload.id}`);
};

export const getFilterReq = (payload) => {
  return get(`filters?company_id=1&fields=page=1&filters=supporting_fields_|processed,employments;preset_|custom_payitem;`)
}