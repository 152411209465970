import { useState, useEffect } from "react";
import {
  BodyComponent,
  TableComponent,
  CreateButton,RefreshButton,
  trans,
} from "@comps/components";
import { makeRequest } from "@utils/helpers";
import { Const } from "../../constants.js";
import { Form, Skeleton, Space, message } from 'antd';
import { SelectWithSearch } from "@comps/components";
import { deleteDataReq, getAllDataReq, getFlowReq } from "./requests.js";
import { Header } from "antd/es/layout/layout";
//import CreateCode from "./components/CreateCode";
//import UpdateCode from "./components/UpdateCode";
import { createSelectList } from '@comps/commonFunctions';
import ChangeRequestFlow from "./components/ChangeRequestFlow.jsx";


const langKey = Const["requestFlow"].lngKey;
const headerStyle = { background: 'white', textAlign: "right" }
const IndexRequestFlows = (props) => {
  const [loading, setLoading] = useState(true)
  const [totalRecords, setTotalRecords] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [loader, setLoader] = useState(false);
  const [childComponent, setChildComponent] = useState(null);
  const [filerComponent, setFilterComponent] = useState(null);
  const [filters, setFilters] = useState();
  const [company, setCompany] = useState([])
  const [requestTypes, setRequestTypes] = useState([]);
  const [nodeTypes, setNodeTypes] = useState([]);
  const [flowNodes, setFlowNodes] = useState([]);
  const [requestFlow, setRequestFlow] = useState({ company_id: 1, type_id: 'attendance_request', for_node_id: null, for_employee_id: '', for_employment_id: '' })
  const [defaultNode, setDefaultNode] = useState('General Flow')
  const [flowData, setFlowData] = useState({})
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    sortName: "id",
    sortType: "desc",
  });

  // ==================================
  //   Use States End
  // ==================================

  // ================================== UpdateLocation
  //   Table Data End
  // ==================================

  const columns = [
    {
      key: 'id',
      title: trans(`${langKey}.id`),
      dataIndex: "id",
    },
    {
      key: 'request_as_name',
      title: trans(`${langKey}.request_as_name`),
      dataIndex: "request_as_name",
    },
    {
      key: 'editable',
      title: trans(`${langKey}.editable`),
      render: (record) => (
        record.editable ? 'Yes' : 'No'
      ),
    },
    {
      key: 'has_condition',
      title: trans(`${langKey}.has_condition`),
      render: (record) => (
        record.has_condition ? 'Yes' : 'No'
      ),
    },
    {
      key: 'next',
      title: trans(`${langKey}.next`),
      render: (record) => (
        record.next
      ),
    },
    {
      key: 'expression',
      title: trans(`${langKey}.expression`),
      dataIndex: "expression",
    },
    {
      key: 'if_true',
      title: trans(`${langKey}.if_true`),
      dataIndex: "if_true",
    },
    {
      key: 'if_false',
      title: trans(`${langKey}.if_false`),
      dataIndex: "if_false",
    },
  ];

  // ==================================
  //   Table Data End
  // ==================================

  useEffect(() => {
    getAllData();
    // eslint-disable-next-line
  }, [filters]);

  const getAllData = () => {
    makeRequest(setLoader, getAllDataReq, filters, onSuccess, null);
  };

  const onSuccess = (response) => {
    setLoading(false)
    setCompany(response.companies);
    setRequestTypes(createSelectList(response.request_types));
    setNodeTypes(createSelectList(response.node_types))
    setFlowNodes(createSelectList(response.flow_nodes));
    //get Flow
    getFlow(requestFlow)
  };
  const getFlow = (data) => {
    setLoading(true)
    const payload = { request_flow: data }
    makeRequest(setLoader, getFlowReq, payload, onGetFlow, onGetFlowError);
  }

  const onGetFlow = (response) => {
    setFlowData(response)
    setRequestTypes(createSelectList(response.request_types));
    setNodeTypes(createSelectList(response.node_types))
    setFlowNodes(createSelectNodeList(response.flow_nodes));
    setDataSource(response.flow)
    setLoading(false)
  }
  const createSelectNodeList = (data) => {
    let newList = []
    data.forEach(element => {
      let obj = { "value": element.id === null ? 0 : element.id, "label": element.name };
      newList.push(obj);
    });
    return newList;
  }
  const onGetFlowError = (response) => {

  }

  const onEdit = (record) => {
    setChildComponent(
      //<UpdateCode onCompleted={ onCompleted } record={ record } disabled={ false } />
    );
  };

  const onDelete = (record) => {
    makeRequest(setLoader, deleteDataReq, record.id, onDeleted, onError);
  };
  const onDeleted = (res) => {
    setDataSource(res.codes.data);
    message.success("Record Deleted")
  };

  const onError = (err, res) => {
    message.error(res.response.data.message);
  }

  // Create component modal
  const onCreate = () => {
    setChildComponent(<ChangeRequestFlow record={ flowData } onCompleted={ onCompleted } requestFlow={ requestFlow } />);
  };

  const onCompleted = (data) => {
    if (data === false) {
      setChildComponent(null);
      return
    }
    getFlow(requestFlow)
    setChildComponent(null);
  };

  const onChangeType = (id) => {
    setRequestFlow({ ...requestFlow, type_id: id })
    const payload = { request_flow: { company_id: 1, type_id: id, for_node_id: requestFlow.for_node_id, for_employee_id: '', for_employment_id: '' } }
    makeRequest(setLoader, getFlowReq, payload, onGetFlow, onGetFlowError);
  }
  const onChangeNode = (id) => {
    if (id === 0) {
      id = null
    }
    setRequestFlow({ ...requestFlow, for_node_id: id })
    const payload = { request_flow: { company_id: 1, type_id: requestFlow.type_id, for_node_id: id, for_employee_id: '', for_employment_id: '' } }
    makeRequest(setLoader, getFlowReq, payload, onGetFlow, onGetFlowError);
  }
  const handleTableChange = (page, fil, sorter) => {
    let payload = {
      ...pagination,
      current: page.current,
      pageSize: page.pageSize,
      sortName: sorter.field || "id",
      sortType: sorter.order === "ascend" ? "asc" : "desc",
    };
    setPagination(payload);
  };


  return (
    <>
      { filerComponent }
      { childComponent }
      <BodyComponent>
        <Header style={ headerStyle }>
         

        </Header>
        <Skeleton loading={ loading }>
          <Form { ...formLayout } style={ { paddingLeft: 20 } }>
            <Form.Item name="type_id" label={ trans(`${langKey}.type_id`) }
              className="da-mb-16"
              size="small"
            >
              <SelectWithSearch
                onChange={ onChangeType }
                defaultValue='attendance_request'
                placeholder="type_id"
                options={ requestTypes }
              />
            </Form.Item>
            <Form.Item name="node_type_id" label={ trans(`${langKey}.node_type_id`) }
              className="da-mb-16"
              size="small"
            //initialValue={ 'General Flow' }
            >
              <SelectWithSearch
                onChange={ onChangeNode }
                defaultValue={ 0 }
                placeholder={ trans(`${langKey}.node_type_id`) }
                options={ flowNodes }
              />
            </Form.Item>

          </Form>
          <Space>
            <CreateButton onClick={ onCreate } text="Change Flow" />
            <RefreshButton onClick={ getAllData } />
          </Space>
          <TableComponent
            loader={ loader }
            columns={ columns }
            dataSource={ dataSource }
            pagination={ { ...pagination, total: totalRecords } }
            onChange={ handleTableChange }
          />
        </Skeleton>

      </BodyComponent>
    </>
  );
};

export default IndexRequestFlows;

const formLayout = {
  // layout: "horizontal",
  labelCol: { span: 6 },
  wrapperCol: { span: 14, offset: 2 },
  labelAlign: "left"
}