import React from "react";
import { Route, Routes } from "react-router-dom";
import IndexEndOfEmployments from "./endOfEmployments/IndexEndOfEmployments";
import IndexReasons from "./settings/reasons/IndexReasons";
import IndexChecklists from "./settings/checklists/IndexChecklists";
import IndexQuestions from "./settings/questions/IndexQuestions";

const EndOfEmploymentsModules = () => {
  return (
    <Routes>
      <Route path="end-of-employment/index" element={ <IndexEndOfEmployments modKey="endOfEmployment"/> } />
      <Route path="settings/reasons" element={ <IndexReasons modKey="eoeReasons"/> } />
      <Route path="settings/checklist" element={ <IndexChecklists modKey="eoeChecklist"/> } />
      <Route path="settings/questions" element={ <IndexQuestions modKey="eoeQuestions" /> } />

    </Routes>
  );
}

export default EndOfEmploymentsModules;
