import React, { useEffect, useState } from "react";
import { Checkbox, Form, Input, message, Steps, Row, Col ,Button, Skeleton} from "antd";
import { SaveButton, CancelButton, ModalComponent, trans, SelectWithSearch, InputDate,InputText, UploadFile, UploadButton, SelectResourceList,ButtonComponent ,SelectMultiWithSearch} from "@comps/components"
import { makeRequest, makeRequestStateless, getErrorProps } from "@utils/helpers";
import { conductEoeInterviewReq, createDataReq, getEmployeeInfoByIdReq, getQuestionsReq, getRelatedReq } from "../requests";
import { Commons, Const } from "../../constants";
import { createSelectList } from '@comps/commonFunctions';
import { useSelector } from "react-redux";

const { TextArea } = Input;
const langKey = Const["EOEmployment"].lngKey
const formName = "ConductEoeInterview";

const ConductEoeInterview = (props) => {
  const authUser = useSelector((state) => state.auth.authUser);
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(true);
  const [saveLoader, setSaveLoader] = useState(false);
  const [employees, setEmployees] = useState([])
  const [questions,setQuestions]=useState([])
  const [interviewFormData,setInterviewFormData]=useState()

  useEffect(() => {
    makeRequestStateless(getQuestionsReq, null, onSuccessGetQuestions, null);
    // eslint-disable-next-line
  }, []);


  const onSuccessGetQuestions = (response) => {
    setLoading(false)
let newList=[];
response.questions.forEach(each=>{
      if(each.type==="radio" || each.type==='checkbox'){
       let arr= each.data=each.data.split(',')
       let ops=[];
arr.forEach(ar=>{
  let obj={label:ar,value:ar}
  ops.push(obj)
})
each.data=ops;
      }
      if(each.is_active){
        newList.push(each);
      }
    })

    setQuestions(newList);
  }

  const onSubmit = (data) => {
    const payload = {end_of_employment_id: props.record.id, interview:data};
    makeRequest(setLoader, conductEoeInterviewReq, payload, onSuccess, onError);
    setSaveLoader(true)
  }

  const onSuccess = (data, res) => {

    setSaveLoader(false)
    message.success("Saved successfully");
    props.onCreated(data.end_of_employments.data);
    props.onSuccess(data)
  }

  const onError = (err, res) => {
    setSaveLoader(false)
    message.error(res.response.data.message);
  }
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [
    questions.length>0 &&
    <SaveButton disabled={ saveLoader } form={ formName  } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCreated(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } width={ 1300 } maskClosable={ false }
      mainTitle={ trans(Commons.create) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCreated(false) }>

   <Skeleton loading={loading}>
   <div className="m-5">

{questions.length<1 && <div className="h6 text-center text-danger">Create Interview question & options from settings of end of Service!</div>}
<Form { ...formLayout } name={formName} onFinish={onSubmit}>

{
questions.map((question,key)=>(
<Col>
    <Form.Item name={question.id} label={ question.question_no+'. '+question.name }
      className="da-mb-16"
      rules={ question.required?rules.required:'' }
    >
      {question.type==='text'?<InputText placeholder={question.name}/>:question.type==='date'?<InputDate/>:question.type==='radio'?<SelectWithSearch placeholder={question.name}
      options={
        question.data
      }/>:
      <SelectMultiWithSearch placeholder={question.name}
      options={question.data}/>}
    </Form.Item>
  </Col>
))
}

</Form>
</div>
   </Skeleton>

    </ModalComponent>


  )
}

export default ConductEoeInterview

const rules = {
  employment_id: [
    { required: true, message: trans(`${langKey}.employment_id.req`) },
  ],
  reason_id: [
    { required: true, message: trans(`${langKey}.reason_id`) },
  ],
  resignation_date: [
    { required: true, message: trans(`${langKey}.resignation_date`) },
  ],
  last_working_date: [
    { required: true, message: trans(`${langKey}.last_working_date`) },
  ],
  required: [
    { required: true, message: "This Field is Required!" },
  ],



};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}