import { useState, useEffect } from "react";
import {
  BodyComponent,
  TableComponent,
  ActionComponent,
  CreateButton,RefreshButton,
  trans,
  HeaderComponent,
  PaginationComponent, CustomButton
} from "@comps/components";
import { makeRequest,makeRequestStateless } from "@utils/helpers";
import { Commons, Const, colors } from "../constants";
import { Badge, Popconfirm, message } from 'antd';
import { FilterComponent, FilterButton, ButtonComponent } from "@comps/components";
import { closePayrollDataReq, deleteDataReq, getAllDataReq, getFilterReq } from "./requests";
import { useSelector } from "react-redux";
import RunPayroll from "./RunPayroll";
import { WarningOutlined } from "@ant-design/icons";
import CreatePayroll from "./CreatePayroll";
import UpdatePayroll from "./UpdatePayroll";
import { convertTimeTotz } from '@comps/commonFunctions';
import { PlayArrowOutlined, StopOutlined } from "@mui/icons-material";
import {  pusherInitializer,decryptLocalData } from "@comps/commonFunctions"
import dayjs from 'dayjs'
import { getBatchProgressByIdReq } from "../../../containers/components/header/requests";
const langKey = Const["payroll"].lngKey;
const pageConfig = {
  headers: {
    title: trans(`${langKey}.moduleName`),
    breadcrumb: [
      {
        name: trans(`${langKey}.moduleName`),
        path: Const["payroll"].route
      }
    ]
  }
}

const IndexPayrolls = (props) => {
  const authUser = useSelector((state) => state.auth.authUser);
  const [totalRecords, setTotalRecords] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [loader, setLoader] = useState(false);
  const [childComponent, setChildComponent] = useState(null);
  const [filerComponent, setFilterComponent] = useState(null);
  const [allData, setAllData] = useState({});
  const [filters, setFilters] = useState(props.attendanceof === 'my' ? `employee_|${authUser.employment_id}` : '');
  const [statuses,setStatuses]=useState([]);

  //start pagination states
  const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState({
    "total": 0,
    "count": 0,
    "per_page": 0,
    "current_page": 0,
    "total_pages": 0,
    "links": {}
  });
  //end pagination states
  // ==================================
  //   Use States End
  // ==================================

  // ==================================
  //   Table Data End
  // ==================================

  const columns = [

    {
      key: trans(Commons.code),
      title: trans(Commons.code),
      dataIndex: 'code'
    },
    {
      key: trans(Commons.name),
      title: trans(Commons.name),
      dataIndex: 'name'
    },
    {
      key: "Employees",
      title: "Employees",
      dataIndex: 'emp_count'
    },
    {
      key: 'tax_structure_id',
      title: trans(`${langKey}.tax_structure_id`),
      dataIndex: 'tax_structure_name'
    },
    {
      key: 'month',
      title: trans(`${langKey}.month`),
      render: (record) => (
        record.history ? dayjs(record.history.month).format('YYYY MMMM'): null
      )
    },
    {
      key: 'running_date',
      title: trans(`${langKey}.running_date`),
      render: (record) => (
        record.history && record.history.running_date ? (convertTimeTotz(record.history.running_date).format('YYYY MMMM DD h:m a')) : null
      )
    },
    {
      key: 'status',
      title: trans(`${langKey}.status`),
      render: (record) => (
        record.history &&
        <Badge
          count={ record.history.status }
          style={ { background: colors[record.history.status_class], color: colors[record.history.status_class + '-color'] } }
        />
      )
    },
    {
      key: 'closing_date',
      title: trans(`${langKey}.closing_date`),
      render: (record) => (
        record.history && record.history.closing_date ? (convertTimeTotz(record.history.closing_date).format('YYYY MMMM DD h:m a')) : null
      )
    },
    {
      key: trans(Commons.actions),
      title: trans(Commons.actions),
      render: (record) => (
        <ActionComponent
          each={ record } modkey={props.modkey}
          onEdit={ onEdit }
          onView={ onView }
          onDelete={ !record.history && onDelete }
        >
          {
            !record.history || (record.history && record.history.status !== 'Running') ? <ButtonComponent onClick={ () => onRun(record) } icon={ <PlayArrowOutlined /> } className="text-success"></ButtonComponent> :
              ''
          }

          <Popconfirm title="Are you sure to close?" icon={ <WarningOutlined /> } onConfirm={ () => onClose(record) }>

            { record.history && record.history.status === 'Run' && <ButtonComponent className="text-warning" icon={ <StopOutlined /> }></ButtonComponent> }
          </Popconfirm>

        </ActionComponent>
      ),
    },
  ];

  // ==================================
  //   Table Data End
  // ==================================
    //==========================Start Pusher=================================
  useEffect(() => {
    const pusher=pusherInitializer();
    const channel= pusher.subscribe(decryptLocalData('user').tenant);
    channel.bind('bell-notification-event', function (data) {
       getAllData();
    });
     // Cleanup function for unsubscribing and unbinding when the component is unmounted
     return () => {
      // Unbind the event handler
      channel.unbind('bell-notification-event');
      // Unsubscribe from the channel
      pusher.unsubscribe(decryptLocalData('user').tenant);
      // Disconnect from Pusher
      pusher.disconnect();
    };
    // eslint-disable-next-line
  });

  //==========================end Pusher =================================
  useEffect(() => {
    getAllData();
    // eslint-disable-next-line
  }, [filters, currentPage]);

  const getAllData = () => {
    const payload = {
      page: currentPage,
      filters: filters
    }
    makeRequest(setLoader, getAllDataReq, payload, onSuccess, onGetAllDataError);
  };
  const onSuccess = (response) => {
    setAllData(response);
    setPagination(response.payrolls);
    setDataSource(response.payrolls.data);
  };
  const onGetAllDataError = (err, res) => {
    message.error(res.response.data.message);
  }

  const onEdit = (record) => {
    setChildComponent(
      <UpdatePayroll onCompleted={ onCompleted } record={ record } disabled={ false } />
    );
  };
  const onView = (record) => {
    setChildComponent(
      <UpdatePayroll onCompleted={ onCompleted } record={ record } disabled={ true } />
    );
  };
  const onRun = (record) => {
    setChildComponent(<RunPayroll onCompleted={ onRunCompleted } record={ record } authUser={ authUser } allData={ allData } disabled={ false } />);
  };
const onRunCompleted=(data)=>{
  if(data){
    getAllData();
  }
  setChildComponent(null)
}

  const onClose = (record) => {
    const payload = {
      payroll: record.id
    }
    makeRequest(setLoader, closePayrollDataReq, payload, onClosed, onError);
  };

  const onDelete = (record) => {
    const payload = {
      filters: `employee_|${record.employment_id}`, id: record.id
    }
    makeRequest(setLoader, deleteDataReq, payload, onDeleted, onError);
  };

  const onDeleted = (res) => {
    setDataSource(res.payrolls.data);
    onSuccess(res)
    message.success("Record Deleted")
  };
  const onClosed = (res) => {
    setDataSource(res.payrolls.data);
    onSuccess(res)
    message.success("Payroll Closed");
    
  };

  const onError = (err, res) => {
    message.error(res.response.data.message);
    //setErrors(errorList);
  }

  // Create component modal
  const onCreate = () => {
    setChildComponent(<CreatePayroll onCompleted={ onCompleted } onSuccess={ onSuccess } />);
  };

  const onCompleted = (data) => {
    if (data.length) {
      getAllData()
    }
    
    setChildComponent(null);
  };

  const handleTableChange = (page, fil, sorter) => {
    let payload = {
      ...pagination,
      current: page.current,
      pageSize: page.pageSize,
      sortName: sorter.field || "id",
      sortType: sorter.order === "ascend" ? "asc" : "desc",
    };
    setPagination(payload);
  };

  //---------------filters-----------------------
  const onOpenFilter = () => {
    setFilterComponent(<FilterComponent filters={ filterFields } modkey={props.modkey} selected={ filters } api={ getFilterReq } setCurrentPage={setCurrentPage} onFilter={ setFilters } onCloseFilter={ onCloseFilter }
    />
    )
  }

  const onCloseFilter = () => {
    setFilterComponent(null);

  }

  //----end filter--------------------

  return (
    <>
      { filerComponent }
      { childComponent }
      <BodyComponent>
        <HeaderComponent headers={ pageConfig.headers } modkey={props.modkey}>
          <CustomButton text="Refresh" onClick={ getAllData } />
          <RefreshButton onClick={ getAllData } /> <CreateButton onClick={ onCreate } />
          <FilterButton onClick={ onOpenFilter } />
        </HeaderComponent>
        <TableComponent modkey={props.modkey}
          loader={ loader }
          columns={ columns }
          dataSource={ dataSource }
          pagination={ { ...pagination, total: totalRecords } }
          onChange={ handleTableChange }
        />
      </BodyComponent>
      <PaginationComponent pagination={ pagination } modkey={props.modkey} setCurrentPage={ setCurrentPage } currentPage={ currentPage } />
    </>
  );
};

export default IndexPayrolls;
const filterFields = [
  {
    type: "text",
    placeholder: "Name",
    key: "name"
  },
  {
    type: "text",
    placeholder: "Code",
    key: "code"
  },
]

