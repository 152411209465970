import React, { useEffect, useState } from "react";
import { Checkbox, Form, Skeleton, message, Row, Col } from "antd";
import { SaveButton, InputText, CancelButton, ModalComponent, trans, SelectWithSearch } from "@comps/components"
import { makeRequest } from "@utils/helpers";
import { createDataReq, getLocationsReq } from "../requests";
import { Commons, Const } from "../../../constants";
import { createSelectList } from '@comps/commonFunctions';
const langKey = Const["machines"].lngKey
const formName = "createMachine";

const CreateMachine = (props) => {
  const [loader, setLoader] = useState(false);
  const [saving, setSaving] = useState(false)
  const [locations, setLocations] = useState([])
  const [branches, setBranches] = useState([]);
  const [machineTypes, setMachineTypes] = useState([])
  const [branchesByLocation, setBranchesByLocation] = useState([])
  const [locationsWithBranches, setlocationsWithBranches] = useState([])
  const [loading, setloading] = useState(true);

  useEffect(() => {
    setData()
    getLocations()
    // eslint-disable-next-line
  }, []);

  const getLocations = () => {
    const payload = { company_id: 1 }
    makeRequest(setLoader, getLocationsReq, payload, onGetLocations, null);

  }
  const onGetLocations = (response) => {
    setlocationsWithBranches(response.locations)
    setloading(false)
  }
  const setData = () => {
    setLocations(createSelectList(props.supportingData.locations));
    setBranches(createSelectList(props.supportingData.branches));
    setMachineTypes(createSelectList(props.supportingData.machine_types));
  }
  const onChangeLocation = (id) => {
    for (const key in locationsWithBranches) {
      if (locationsWithBranches[key].id === id) {
        setBranchesByLocation(createSelectList(locationsWithBranches[key].branches));
      }
    }
  }
  const onSubmit = (data) => {
    const payload = { machine: data };
    makeRequest(setLoader, createDataReq, payload, onSuccess, onError);
    setSaving(true)
  }

  const onSuccess = (data, res) => {
    setSaving(false)
    message.success("Saved successfully");
    props.onCompleted(data.machines.data);
    props.onSuccess(data)
  }

  const onError = (err, res) => {
    setSaving(false)
    message.error(res.response.data.message);
  }

  //-----------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [
    <SaveButton loading={ saving } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } width={ 1000 } maskClosable={ false }
      mainTitle={ trans(Commons.create) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Skeleton loading={ loading }>
        <Form { ...formLayout } name={ formName } onFinish={ onSubmit }>
          <Row gutter={ 30 }>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="name" rules={ rules.name } label={ trans(`${langKey}.name`) }
                className="da-mb-16"
                size="small"
              >
                <InputText placeholder="Name" />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="machine_type_id" rules={ rules.machine_type_id } label={ trans(`${langKey}.machine_type_id`) }
                className="da-mb-16"
                size="small"
              >
                <SelectWithSearch
                  placeholder={ trans(`${langKey}.machine_type_id`) }
                  options={ machineTypes }
                />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="location_id" rules={ rules.location_id } label={ trans(`${langKey}.location_id`) }
                className="da-mb-16"
                size="small"
              >
                <SelectWithSearch
                  placeholder={ trans(`${langKey}.location_id`) }
                  options={ locations }
                  onChange={ onChangeLocation }
                />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="branch_id" rules={ rules.branch_id } label={ trans(`${langKey}.branch_id`) }
                className="da-mb-16"
                size="small"
              >
                <SelectWithSearch
                  placeholder={ trans(`${langKey}.branch_id`) }
                  options={ branchesByLocation }
                />
              </Form.Item>
            </Col>

         
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="ip" rules={ rules.ip } label={ trans(`${langKey}.ip`) }
                className="da-mb-16"
                size="small"
              >
                <InputText placeholder="IP Address" />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="udp_port" rules={ rules.udp_port } label={ trans(`${langKey}.udp_port`) }
                className="da-mb-16"
                size="small"
              >
                <InputText placeholder="UDP Port" type="number" />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="soap_port" rules={ rules.soap_port } label={ trans(`${langKey}.soap_port`) }
                className="da-mb-16"
                size="small"
              >
                <InputText placeholder={ trans(`${langKey}.soap_port`) } type="number" />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="is_auto_import" rules={ rules.is_auto_import } label={ trans(`${langKey}.is_auto_import`) }
                className="da-mb-16"
                size="small"
                valuePropName="checked"
              >
                <Checkbox />
              </Form.Item>
            </Col>
          </Row>

        </Form>
      </Skeleton>

    </ModalComponent>
  )
}

export default CreateMachine

const rules = {
  name: [
    { required: true, message: trans(`${langKey}.name`) },
  ],
  ip: [
    { required: true, message: trans(`${langKey}.ip`) },
  ],
  location_id: [
    { required: true, message: trans(`${langKey}.location_id`) },
  ],
  branch_id: [
    { required: true, message: trans(`${langKey}.branch_id`) },
  ],
  udp_port: [
    { required: true, message: trans(`${langKey}.udp_port`) },
  ],
  machine_type_id: [
    { required: true, message: trans(`${langKey}.machine_type_id`) },
  ],

};

const formLayout = {
  layout: "vartical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}