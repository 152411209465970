import React, { useEffect, useState } from "react";
import { Form, Skeleton, message, Row, Col } from "antd";
import { SaveButton, InputText, InputTextArea, CancelButton, ModalComponent, trans, InputDate, SelectWithSearch } from "@comps/components"
import { makeRequest, getErrorProps } from "@utils/helpers";
import { updateDataReq } from "../requests";
import { Commons, Const } from "../../../constants";
import EmployeeInfo from '@mods/common/EmployeeInfo';
import dayjs from "dayjs";

import { createSelectList } from '@comps/commonFunctions';

const langKey = Const["companyPromotions"].lngKey
const formName = "createPromotionRequest";
const CreateCompanyPromotionRequest = (props) => {

  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [jobtitles, setJobtitles] = useState([]);
  const [grades, setGrades] = useState([]);

  const [form] = Form.useForm()
  useEffect(() => {
    setData()
    // eslint-disable-next-line
  }, []);

  const setData = () => {
    setGrades(createSelectList(props.allData.grades))
    setDesignations(createSelectList(props.allData.designations))
    setJobtitles(createSelectList(props.allData.job_titles))

    form.setFieldsValue(
      {
        employment_id: props.record.requestor.full_name_code,
        grade_id: props.record.grade_id,
        designation_id: props.record.designation_id,
        job_title_id: props.record.job_title_id,
        is_demotion: props.record.is_demotion,
        prev_salary: props.record.prev_salary,
        salary: props.record.salary,
        date: dayjs(props.record.date),
        description: props.record.description
      }
    )
  }


  const onSubmit = (data) => {

    let gradeObj = {}
    props.allData.grades.forEach(grade => {
      if (grade.id === data.grade_id) {
        gradeObj = grade;
      }
    })
    const payload = {
      id: props.record.id,
      'file_attachment': 'undefined',
      'promotion': {
        ...props.record,
        "grade_id": data.grade_id, "grade_level_id": null, "job_title_id": data.job_title_id, "designation_id": data.designation_id, "prev_salary": data.prev_salary,
        "date": data.date.format('YYYY-MM-DD'),
        "grade":
          gradeObj,
        "is_demotion": data.is_demotion, "salary": data.salary, "description": data.description,
      }
    }
    makeRequest(setLoader, updateDataReq, payload, onSuccess, onError);
    setSaveLoader(true)
  }

  const onSuccess = (data, res) => {

    setSaveLoader(false)
    message.success("Saved successfully");
    props.onCompleted(data.promotions.data);
  }

  const onError = (err, res) => {

    setSaveLoader(false)
    message.error(res.response.data.message);
  }
  //-----------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------
  const footer = [
    props.disabled ? '' :
      <SaveButton loading={ saveLoader } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];
  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------
  return (
    <ModalComponent top={ 20 } width={ 1000 } maskClosable={ false }
      mainTitle={ trans(Commons[props.disabled ? 'view' : 'update']) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Skeleton loading={ loading }>
        <EmployeeInfo record={ props.record.requestor && props.record.requestor } />
        <Form { ...formLayout } form={ form } name={ formName } onFinish={ onSubmit } disabled={ props.disabled }>
          <Row gutter={ 30 }>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="employment_id" rules={ rules.employment_id } label={ trans(`${langKey}.employment_id`) }
                className="da-mb-16"
                { ...getErrorProps(errors['name']) } size="small"
              >
                <InputText disabled={ true }
                />
              </Form.Item>
            </Col>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="is_demotion" rules={ rules.is_demotion } label={ trans(`${langKey}.is_demotion`) }
                className="da-mb-16"
                { ...getErrorProps(errors['name']) } size="small"
              >
                <SelectWithSearch
                  options={ [
                    {
                      label: 'Promotion',
                      value: false
                    },
                    {
                      label: 'Demotion',
                      value: true
                    },
                  ] }
                  placeholder={ trans(`${langKey}.is_demotion`) }

                />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="grade_id" rules={ rules.grade_id } label={ trans(`${langKey}.grade_id`) }
                className="da-mb-16"
                size="small"
              >
                <SelectWithSearch
                  placeholder={ trans(`${langKey}.grade_id`) }

                  options={ grades }
                />
              </Form.Item>
            </Col>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="designation_id" rules={ rules.designation_id } label={ trans(`${langKey}.designation_id`) }
                className="da-mb-16"
                size="small"
              >
                <SelectWithSearch
                  placeholder={ trans(`${langKey}.designation_id`) }
                  options={ designations }
                />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="job_title_id" rules={ rules.job_title_id } label={ trans(`${langKey}.job_title_id`) }
                className="da-mb-16"
                size="small"
              >
                <SelectWithSearch
                  placeholder={ trans(`${langKey}.job_title_id`) }
                  options={ jobtitles }
                />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="prev_salary" rules={ rules.prev_salary } label={ trans(`${langKey}.prev_salary`) }
                className="da-mb-16"
                { ...getErrorProps(errors['prev_salary']) } size="small"
              >
                <InputText type="number" placeholder="prev_salary" disabled={ true } />
              </Form.Item>
            </Col>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="salary" rules={ rules.salary } label={ trans(`${langKey}.salary`) }
                className="da-mb-16"
                { ...getErrorProps(errors['salary']) } size="small"
              >
                <InputText type="number" placeholder={ trans(`${langKey}.salary`) } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="date" rules={ rules.date } label={ trans(`${langKey}.date`) }
                className="da-mb-16"
                { ...getErrorProps(errors['date']) } size="small"
              >
                <InputDate />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="description" rules={ rules.description } label={ trans(`${langKey}.description`) }
                className="da-mb-16"
                { ...getErrorProps(errors['description']) } size="small"
              >
                <InputTextArea placeholder={ trans(`${langKey}.description`) } />
              </Form.Item>
            </Col>
          </Row>

        </Form>
      </Skeleton>
    </ModalComponent>
  )
}

export default CreateCompanyPromotionRequest

const rules = {
  date: [
    { required: true, message: trans(`${langKey}.date`) },
  ],
  grade_id: [
    { required: true, message: trans(`${langKey}.grade_id`) },
  ],
  designation_id: [
    { required: true, message: trans(`${langKey}.designation_id`) },
  ],
  job_title_id: [
    { required: true, message: trans(`${langKey}.job_title_id`) },
  ],
  salary: [
    { required: true, message: trans(`${langKey}.salary`) },
  ],

  employment_id: [
    { required: true, message: trans(`${langKey}.employment_id`) },
  ],
};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}
