import React, { useEffect, useState } from "react";
import { Form, message, Row, Col } from "antd";
import { SaveButton, CancelButton, ModalComponent, trans, InputTime, InputDate, InputTextArea, SelectResourceList } from "@comps/components"
import { makeRequest, getErrorProps } from "@utils/helpers";
import { createDataReq, getRosterReq } from "../requests";
import { Commons, Const } from "../../../constants";
import RoasterView from "@mods/common/RoasterView";
import { convertTimeTotz } from '@comps/commonFunctions'
import moment from "moment";

const langKey = Const["overtimeRequest"].lngKey
const formName = "createOvertimeRequest";
const CreateOvertimeRequest = (props) => {
  const [loader, setLoader] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [roasterView, setRoasterView] = useState(null)
  const [shifts, setShifts] = useState({})
  const [selectedEmploymentId, setEmploymentId] = useState(null)
  const [form] = Form.useForm()
  useEffect(() => {

    // eslint-disable-next-line
  }, []);

  const getRoster = (date) => {
    if (date) {
      const payload = { overtime: { employment_id: selectedEmploymentId, date: date.format('YYYY-MM-DD') } }
      makeRequest(setLoader, getRosterReq, payload, onGetRosterSuccess, onGetRosterSuccess);
    }

  }
  const onGetRosterSuccess = (response, data) => {
    if (data.shifts) {
      setRoasterView(<RoasterView record={ data } />)
      setShifts(data.shifts[moment(data.from_date).format('YYYYMMDD') + '-' + data.employments[0].id][0]);
      let start = data.shifts[moment(data.from_date).format('YYYYMMDD') + '-' + data.employments[0].id][0]['start']
      let end = data.shifts[moment(data.from_date).format('YYYYMMDD') + '-' + data.employments[0].id][0]['end']

      form.setFieldsValue({
        start: convertTimeTotz(start),
        end: convertTimeTotz(end),
      })
    }
  }

  const onSubmit = (data) => {
    setSaveLoader(true)
    const payload = {
      overtime:
      {
        is_manually_created: true, behalf_employment_id: props.authUser.employment_id, is_on_behalf: true, shift: shifts, employment_id: shifts.employment_id,
        date: data.date.format('YYYY-MM-DD'),
        start_time: data.start.second(0),
        end_time: data.end.second(0),
        start: data.start.second(0),
        end: data.end.second(0),
        description: data.description,
        employee_shift_id: shifts.id
      }
    };
    makeRequest(setLoader, createDataReq, payload, onSuccess, onError);
  }

  const onSuccess = (data, res) => {
    setSaveLoader(false)
    message.success("Saved successfully");
    props.onCompleted(data.overtimes.data);
    props.onSuccess(data)
  }

  const onError = (err, res) => {
    setSaveLoader(false)
    message.error(res.response.data.message);
  }
  //-----------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------
  const footer = [
    <SaveButton loading={ saveLoader } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];
  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------
  return (
    <ModalComponent top={ 20 } width={ 1000 } maskClosable={ false }
      mainTitle={ trans(Commons.create) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Form { ...formLayout } form={ form } name={ formName } onFinish={ onSubmit } scrollToFirstError>
        <Row gutter={ 30 }>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="employment_id" rules={ rules.employment_id } label={ trans(`${langKey}.employment_id`) }
              className="da-mb-16"
              { ...getErrorProps(errors['name']) } size="small"
            >
              <SelectResourceList type='employment'
                onChange={ (id) => setEmploymentId(id) }
              />
            </Form.Item>
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="date" rules={ rules.date } label={ trans(`${langKey}.date`) }
              className="da-mb-16"
              { ...getErrorProps(errors['date']) } size="small"
            >
              <InputDate onChange={ (e) => getRoster(e) } />
            </Form.Item>
          </Col>
        </Row>
        { roasterView }
        <Row gutter={ 30 }>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="start" rules={ rules.start } label={ trans(`${langKey}.start`) }
              className="da-mb-16"
              { ...getErrorProps(errors['start']) } size="small"
            >
              <InputTime placeholder={ trans(`${langKey}.start`) } />
            </Form.Item>
          </Col>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="end" rules={ rules.end } label={ trans(`${langKey}.end`) }
              className="da-mb-16"
              { ...getErrorProps(errors['end']) } size="small"
            >
              <InputTime placeholder={ trans(`${langKey}.end`) } />
            </Form.Item>
          </Col>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="description" rules={ rules.description } label={ trans(`${langKey}.description`) }
              className="da-mb-16"
              { ...getErrorProps(errors['description']) } size="small"
            >
              <InputTextArea placeholder={ trans(`${langKey}.description`) } />
            </Form.Item>
          </Col>
        </Row>

      </Form>
    </ModalComponent>
  )
}

export default CreateOvertimeRequest

const rules = {
  date: [
    { required: true, message: trans(`${langKey}.date`) },
  ],
  start: [
    { required: true, message: trans(`${langKey}.start`) },
  ],
  end: [
    { required: true, message: trans(`${langKey}.end`) },
  ],
  reason: [
    { required: true, message: trans(`${langKey}.reason`) },
  ],
  employment_id: [
    { required: true, message: trans(`${langKey}.employment_id`) },
  ],
};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}