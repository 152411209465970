import React, { useEffect, useState } from "react";
import { Form, Upload, message, Modal, Col, Row, Skeleton } from "antd";
import { SelectWithSearch, SaveButton, CancelButton, ModalComponent, trans, InputDate, InputText ,UploadFile} from "@comps/components";
import { makeRequest, makeRequestStateless, getErrorProps } from "@utils/helpers";
import { createSelectList, createFormattedCurrencies } from '@comps/commonFunctions';
import { getAllTimeZonesReq, getAllCurrenciesReq, updateCompanyReq } from '../requests';
import { Commons, Const } from '../../constants';
import dayjs from 'dayjs';
const formName = "updateCompany";
const langKey = Const["companies"].lngKey

const UpdateCompany = (props) => {

  const [form] = Form.useForm()
  const [loader, setLoader] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [loadingData, setLoading] = useState(true);
  const [errors, setErrors] = useState([]);
  const [timezone, setTimezones] = useState([]);
  const [company, setCompany] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [fiscalStartYear, setfiscalStartYear] = useState(dayjs(props.record.fiscal_start))
  const [fiscalEndYear, setfiscalEndYear] = useState(dayjs(props.record.fiscal_end))
  const [loading, setloading] = useState(true);
  const [saving, setsaving] = useState(false);

  useEffect(() => {
    getAndSetCompany();
    getRemoteData();
    // eslint-disable-next-line
  }, []);

  const getAndSetCompany = () => {
    setFileList(props.record.attachment ? [props.record.attachment] : [])
    form.setFieldsValue({
      'code': props.record.code,
      "name": props.record.name,
      "short_name": props.record.short_name,
      "time_zone": props.record.timezone_id,
      "currency": props.record.currency_id,
      "address": props.record.address,
      "fiscal_start": fiscalStartYear,
      "fiscal_end": fiscalEndYear,
    })
    setCompany(props.record)
  }
  const getRemoteData = () => {
     setTimezones(createSelectList(props.timezones))
    //makeRequestStateless(getAllTimeZonesReq, false, onTimeZonesSuccess, onTimeZonesError)
    makeRequestStateless(getAllCurrenciesReq, false, onCurrenciesSuccess, onCurrenciesError)
  }
  const onCurrenciesSuccess = (data, res) => {
    const formatedCurrencies = createFormattedCurrencies(data)
    setCurrencies(formatedCurrencies)
    setloading(false)
  }

  const onCurrenciesError = (err, res) => {
    console.error(err)
  }

  const getFiscalStartDate=(fullDate,date)=>{
    // Set the date to the first day of the month
    let firstDateMonth=fullDate.date(date);
    return firstDateMonth.format('YYYY-MM-DD');
  }
  const getFiscalEndDate=(fullDate)=>{
    // Set the date to the last day of the month
    const lastDayOfMonth = fullDate.endOf('month');
    return lastDayOfMonth.format('YYYY-MM-DD');
  }
  const onSubmit = (data) => {
    data.file_attachment = fileList.length > 0 && fileList[0].id === undefined ? fileList[0].originFileObj : null
    let startDate=getFiscalStartDate(data.fiscal_start,1);
    let endDate=getFiscalEndDate(data.fiscal_end);
    let payload = {
      id: company.id,
      code: data.code,
      name: data.name,
      short_name: data.short_name,
      timezone_id: data.time_zone,
      currency_id: data.currency,
      fiscal_start: startDate,
      fiscal_end: endDate,
      address: data.address,
      is_active: false,
      file_attachment: data.file_attachment
    }
    makeRequest(setLoader, updateCompanyReq, payload, onSuccess, onError);
    setsaving(true)
  }
  const onSuccess = (res, data) => {
    setsaving(false)
    message.success("Company Updated", res.msg);

    props.onCreated(res.companies.data);
  }
  const onError = (err, res) => {
    setsaving(false)
    message.error(res.response.data.message);
    //setErrors(errorList);
  }
  const handleFiscalYearStart = (date) => {
    if (date) {
      setfiscalStartYear(date)
      setfiscalEndYear(date.add(1, 'y').subtract(1, 'months'))
      form.setFieldValue('fiscal_end', date.add(1, 'y').subtract(1, 'months'))
    }
  }

  //============uploading file============
  const [fileList, setFileList] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const handleCancel = () => setPreviewOpen(false);
  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };
  const onPreview = async (file) => {

    if (file.url !== undefined && file.url && file.extension === 'pdf') {
      window.open(file.url, '_blank');
      return
    }

    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------
  const footer = [
    !props.disabled &&
    <SaveButton loading={ saving } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCreated(false) } />
  ]
  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------
  return (
    <ModalComponent width={ 1000 } top={ 20 } maskClosable={ false } mainTitle={ trans(Commons[props.disabled ? 'view' : 'update']) }
      subTitle={ trans(`${langKey}.moduleName`) } open={ true } footer={ footer } onCancel={ () => props.onCreated(false) }>
      <Skeleton loading={ loading }>
        <Form
          layout="vertical"
          form={ form }
          name={ formName }
          onFinish={ onSubmit }
          disabled={ props.disabled }
          scrollToFirstError
        >
          <Row gutter={ 30 }>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="name" rules={ rules.name } label={ trans(`${langKey}.name`) }
                className="da-mb-16"
                { ...getErrorProps(errors['name']) } size="small"
              >
                <InputText placeholder={ trans(`${langKey}.name`) } />
              </Form.Item>

              <Form.Item name="time_zone" label={ trans(`${langKey}.timezone`) } rules={ rules.time_zone } className="da-mb-16"
                { ...getErrorProps(errors['time_zone']) }
              >
                <SelectWithSearch
                  className="da-mb-16"
                  placeholder={ trans(`${langKey}.timezone.select`) }
                  options={ timezone } />
              </Form.Item>

              <Form.Item name='fiscal_start' label={ trans(`${langKey}.fiscalStart`) } rules={ rules.start_year }
                { ...getErrorProps(errors['fiscalStart']) }
              >
                <InputDate

                  onChange={ handleFiscalYearStart }
                  value={ fiscalStartYear }
                  picker="month" format="MMM-YYYY"
                />
              </Form.Item>

              <Form.Item name="address" rules={ rules.complete_address } label={ trans(`${langKey}.address`) }
                className="da-mb-16"
                { ...getErrorProps(errors['name']) }
              >
                <InputText placeholder={ trans(`${langKey}.address`) } />
              </Form.Item>

            </Col>
            <Col span={ 12 }>
              <Form.Item name="short_name" rules={ rules.short_name } label={ trans(`${langKey}.shortName`) } className="da-mb-16"
                { ...getErrorProps(errors['short_name']) }
              >
                <InputText placeholder={ trans(`${langKey}.short_name`) } />
              </Form.Item>

              <Form.Item name="currency" label={ trans(`${langKey}.currency`) } rules={ rules.currency } className="da-mb-16"
                { ...getErrorProps(errors['currency']) }
              >
                <SelectWithSearch
                  placeholder={ trans(`${langKey}.currency.select`) }
                  options={ currencies }
                />
              </Form.Item>
              <Form.Item name='fiscal_end' label={ trans(`${langKey}.fiscalEnd`) } rules={ rules.end_year }
                { ...getErrorProps(errors['fiscalEnd']) }
              >
                <InputDate
                  picker="month" format='MMM-YYYY'
                  value={ fiscalEndYear }
                  disabled rules={ rules.end_year }
                />
              </Form.Item>

              <Form.Item label="Logo" name="file_attachment" >
              <UploadFile fileList={ fileList } setFileList={ setFileList } accept=".jpg,.png,.jpeg"/>

              </Form.Item>


            </Col>

          </Row>
        </Form>
      </Skeleton>
      <Modal open={ previewOpen } title={ previewTitle } footer={ null } onCancel={ handleCancel }>
        <img
          alt="example"
          style={ {
            width: '100%',
          } }
          src={ previewImage }
        />
      </Modal>

    </ModalComponent >

  )
}
export default UpdateCompany
const rules = {
  name: [
    { required: true, message: trans(`${langKey}.name.required`) },
  ],
  short_name: [
    { required: true, message: trans(`${langKey}.shortName.required`) }
  ],
  time_zone: [
    { required: true, message: trans(`${langKey}.timezone.required`) },
  ],
  currency: [
    { required: true, message: trans(`${langKey}.currency.required`) },
  ],
  start_year: [
    { required: true, message: trans(`${langKey}.fiscalStart`) }
  ],
  end_year: [
    { required: true, message: trans(`${langKey}.fiscalEnd`) }
  ],
};
