import { useState, useEffect } from "react";
import {
  BodyComponent,
  TableComponent,
  ActionComponent,
  ButtonComponent,
  trans,
  HeaderComponent,
  EmployeeWithProfile,
  PaginationComponent
} from "@comps/components";
import { makeRequest } from "@utils/helpers";
import { Commons, Const } from "../constants";
import { message, Space } from 'antd';
import { FilterComponent, FilterButton } from "@comps/components";
import { downloadVoucherReq, getAllDataReq, getFilterReq } from "./requests";
import { DownloadOutlined } from "@ant-design/icons";
import dayjs from 'dayjs'
import { handleDownloadFile } from "@utils/helpers";

const langKey = Const["vouchers"].lngKey;
const pageConfig = {
  headers: {
    title: trans(`${langKey}.moduleName`),
    breadcrumb: [
      {
        name: trans(`${langKey}.moduleName`),
        path: Const["vouchers"].route
      }
    ]
  }
}

const IndexVouchers = (props) => {
  const [dataSource, setDataSource] = useState([]);
  const [loader, setLoader] = useState(false);
  const [childComponent, setChildComponent] = useState(null);
  const [filerComponent, setFilterComponent] = useState(null);
  const [filters, setFilters] = useState('');
  const [loading, setLoading] = useState(false)
  //start pagination states
  const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState({
    "total": 0,
    "count": 0,
    "per_page": 0,
    "current_page": 0,
    "total_pages": 0,
    "links": {}
  });
  //end pagination states

  // ==================================
  //   Use States End
  // ==================================

  // ==================================
  //   Table Data End
  // ==================================

  const columns = [
    {
      key: 'code',
      title: trans(Commons.code),
      render: (record) => {
        return record.code
      },
    },
    {
      key: 'employment_id',
      title: trans(Commons.employee),
      render: (record) => {
        return <EmployeeWithProfile record={ record.requestor } />
      },
    },
    {
      key: 'type',
      title: trans(`${langKey}.type`),
      render: (record) => {
        return record.type
      },
    },
    {
      key: 'date',
      title: trans(`${langKey}.date`),
      render: (record) => {
        return record.date ? dayjs(record.date).format('YYYY-MM-DD') : ''
      },
    },
    {
      key: 'amount',
      title: trans(`${langKey}.amount`),
      render: (record) => {
        return record.amount ? 'Rs .' + record.amount : 'Rs. ' + 0
      },
    },

    {
      key: trans(Commons.actions),
      title: trans(Commons.actions),
      render: (record) => (
        <ActionComponent
          each={ record } modkey={props.modkey}
          onView={ onView }
        >
          <Space>
            <ButtonComponent onClick={ () => onDownload(record) } icon={ <DownloadOutlined /> } loading={ loading } />
          </Space>
        </ActionComponent>
      ),
    },
  ];

  // ==================================
  //   Table Data End
  // ==================================

  useEffect(() => {
    getAllData()
    // eslint-disable-next-line
  }, [filters, currentPage]);

  const getAllData = () => {
    const payload = {
      page: currentPage,
      filters: filters
    }
    makeRequest(setLoader, getAllDataReq, payload, onSuccess, onGetAllDataError);
    onCloseFilter()
  };
  const onSuccess = (response) => {
    setPagination(response.vouchers)
    setDataSource(response.vouchers.data);
    onCloseFilter()
  };
  const onGetAllDataError = (err, res) => {
    message.error(res.response.data.message);
    onCloseFilter()
  }
  const onView = (record) => {
    // setChildComponent(<ViewMyPayslip onCompleted={ onCompleted } record={ record } authUser={ authUser } allData={ allData } disabled={ true } />);
  };

  const onDownload = (record) => {
    const payload = {
      filters: `${filters}ids_|${record.id};download_type_|pdf;`,
    }
    makeRequest(setLoader, downloadVoucherReq, payload, onDownloadSuccess, onDownloadSuccess);
  }

  const onDownloadSuccess = (err, res) => {
    setLoading(false)
    if (res.code === undefined) {
      const fileExtension = res.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ? 'xlsx' : 'pdf'
      const fileName = `voucher.${fileExtension}`
      handleDownloadFile(fileName, res)
    }
    else {
      message.error(res.message)
    }
  }

  const onError = (err, res) => {
    message.error(res.response.data.message);
    //setErrors(errorList);
  }

  const onCompleted = (data) => {
    if (data.length) {
      setDataSource(data);
    }
    setChildComponent(null);
  };

  //---------------filters-----------------------
  const onOpenFilter = () => {
    setFilterComponent(<FilterComponent filters={ filterFields } modkey={props.modkey} selected={ filters } api={ getFilterReq } setCurrentPage={setCurrentPage} onFilter={ setFilters } onCloseFilter={ onCloseFilter }
    />
    )
  }

  const onCloseFilter = () => {
    setFilterComponent(null);

  }

  //----end filter--------------------

  return (
    <>
      { filerComponent }
      { childComponent }
      <BodyComponent>
        <HeaderComponent headers={ pageConfig.headers } modkey={props.modkey}>
          <ButtonComponent text='Refresh' type='primary' onClick={ () => getAllData(filters !== undefined ? filters : '') } />
          <FilterButton onClick={ onOpenFilter } />
        </HeaderComponent>
        <TableComponent modkey={props.modkey}
          loader={ loader }
          columns={ columns }
          dataSource={ dataSource }
        />
      </BodyComponent>
      <PaginationComponent pagination={ pagination } modkey={props.modkey} setCurrentPage={ setCurrentPage } currentPage={ currentPage } />
    </>
  );
};

export default IndexVouchers;
const filterFields = [
  {
    type: "text",
    placeholder: "Code",
    key: "code"
  },

]

