import React, { useEffect, useState } from "react";
import { Form, message, Skeleton, Row, Col } from "antd";
import { SaveButton, InputText, CancelButton, ModalComponent, trans, SelectResourceList, UploadFile, InputDate } from "@comps/components"
import { makeRequest, makeRequestStateless, getErrorProps } from "@utils/helpers";
import { createCertificationReq, getAllEmployeesReq } from "../requests";
import { Commons, Const } from "../../constants";

const langKey = Const["certification"].lngKey

const formName = "createCertification";

const CreateCertification = (props) => {

  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [saving, setsaving] = useState(false);
  const [loading, setloading] = useState(true);
  const [fileList, setFileList] = useState([]);
  const [form] = Form.useForm()

  useEffect(() => {
    makeRequestStateless(getAllEmployeesReq, false, ongetAllEmployees, false)
    form.setFieldValue('employee_id', props.filters !== undefined ? props.filters.replace('employments_|', '') : null)
    // eslint-disable-next-line
  }, []);
  const ongetAllEmployees = (response) => {
    setloading(false)
  }

  const onSubmit = (data) => {
    setsaving(true)
    data.attachment = fileList.length > 0 && fileList[0].id === undefined ? fileList[0].originFileObj : null
    const payload = { employee_certification: data }
    makeRequest(setLoader, createCertificationReq, payload, onSuccess, onError);
  }

  const onSuccess = (data, res) => {
    message.success("Certification Saved successfully");
    props.onCompleted(data.employee_certifications.data);
    props.onSuccess(data)
  }

  const onError = (err, res) => {
    setsaving(false)
    message.error(res.response.data.message);
    //setErrors(errorList);
  }
  //-----------on changes------------


  // ------------------------------------
  // ------------------------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [
    <SaveButton loading={ saving } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } width={ 1000 } maskClosable={ false }
      mainTitle={ trans(Commons.create) } subTitle={ trans(`${langKey}.certification`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Skeleton loading={ loading }>
        <Form { ...formLayout } name={ formName } onFinish={ onSubmit } scrollToFirstError>
          <Row gutter={ 30 }>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="employee_id" label={ trans(`${langKey}.select.employee`) } rules={ rules.employee_id } className="da-mb-16"
                { ...getErrorProps(errors['employee_id']) }
              >
                <SelectResourceList type='employee'
                  disabled={ props.filters !== undefined }
                />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="name" rules={ rules.name } label={ trans(`${langKey}.name`) }
                className="da-mb-16"
                { ...getErrorProps(errors['name']) } size="small"
              >
                <InputText placeholder={ trans(`${langKey}.name`) } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="institute_name" rules={ rules.institute_name } label={ trans(`${langKey}.institute_name`) }
                className="da-mb-16"
                { ...getErrorProps(errors['institute_name']) } size="small"
              >
                <InputText placeholder={ trans(`${langKey}.institute_name`) } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="certification_date" rules={ rules.certification_date } label={ trans(`${langKey}.certification_date`) }
                className="da-mb-16"
                { ...getErrorProps(errors['certification_date']) } size="small"
              >
                <InputDate format='YYYY-MM-DD' />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="country" rules={ rules.country } label={ trans(`${langKey}.country`) }
                className="da-mb-16"
                { ...getErrorProps(errors['country']) } size="small"
              >
                <InputText placeholder={ trans(`${langKey}.country`) } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="city" rules={ rules.city } label={ trans(`${langKey}.city`) }
                className="da-mb-16"
                { ...getErrorProps(errors['city']) } size="small"
              >
                <InputText placeholder={ trans(`${langKey}.city`) } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item label={ trans(`${langKey}.attachment`) } name="attachment" >
                <UploadFile fileList={ fileList } setFileList={ setFileList } />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Skeleton>

    </ModalComponent>
  )
}

export default CreateCertification

const rules = {
  name: [
    { required: true, message: trans(`${langKey}.name.req`) },
  ],
  employee_id: [
    { required: true, message: trans(`${langKey}.employee_id.req`) },
  ],
  institute_name: [
    { required: true, message: trans(`${langKey}.institute_name.req`) },
  ],
};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}