import { get, del, post, put } from "@utils/axios";

const api = "relationships";

export const getAllDataReq = (payload) => {
  return get(`${api}?page=${payload.page}&filters=${payload.filters}`)
};

export const createDataReq = (payload) => {
  return post(`${api}?`, payload);
};

export const updateDataReq = (payload) => {
  return put(`${api}/${payload.id}`, payload);
};

export const deleteDataReq = (id) => {
  return del(`${api}/${id}`);
};

export const getFilterReq = (payload) => {
  return get(`${api}?page=1&filters?company_id=1&fields=page=1&filters=organization_structure_fields_|company;preset_|grade;`)
}