import { get, del, post, put } from "@utils/axios";

const api = "roles";

export const getAllDataReq = (payload) => {
  return get(`${api}`)
};

export const createRoleReq = (payload) => {
  return post(`${api}?`, payload);
};

export const getPermissionsByRoleReq = (roleid) => {
  return get(`role/${roleid}/permissions`)
};

export const updateRoleReq = (payload) => {
  return put(`${api}/${payload.id}`, payload);
};
export const updateRolePermissionsReq = (payload) => {
  return put(`role/${payload.id}/permissions`, payload);
};

export const deleteRoleReq = (id) => {
  return del(`${api}/${id}`);
};

export const getFilterReq = (payload) => {
  return get(`${api}?page=1&filters?company_id=1&fields=page=1&filters=organization_structure_fields_|company;preset_|grade;`)
}