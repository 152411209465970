import { get, del, put, postFd, putFd } from "@utils/axios";

const api = "branches";
const apiCountries = "common/getCountries"
const apiLocations = "common/getLocations"
export const getAllBranches = (payload) => {
  return get(`${api}?page=${payload.page}&filters=${payload.filters}`);
};

export const getAllCountriesReq = () => {
  return get(`${apiCountries}?`);
};

export const getLocationsManagersAndAreasByCompanyId = (id) => {
  return get(`${api}/getLocations/${id}`);
};

export const createBranch = (payload) => {
  return postFd(`${api}?`, payload);
};
export const getCompanyReq = () => {
  return get('common/getCompany')
}

export const updateBranch = (payload) => {
  return put(`${api}/${payload.id}`, payload);
};

export const deleteBranch = (id) => {
  return del(`${api}/${id}`);
};
export const getFilterReq = (payload) => {
  return get(`resources/filter-resources/${payload}`)
}
export const getResourceReq = (payload) => {
  return get(`resources/${payload}?page=1`)
}

