import { TimePicker } from "antd";
import { getPlaceholder } from '@comps/commonFunctions';
const BaseTimePicker = (props) => {
  return (

    <TimePicker
      style={ {
        width: '100%',
        height: '48px',
      } }
      format="hh:mm a"
      use12Hours
      hideDisabledOptions
      { ...props }
      placeholder={ getPlaceholder(props.placeholder) }
    />

  )
}

export default BaseTimePicker