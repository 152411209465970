import React, { useEffect, useState } from "react";
import { Form, Skeleton, message, Row, Col } from "antd";
import { SaveButton, InputText, CancelButton, ModalComponent, trans, InputDate, SelectWithSearch } from "@comps/components"
import { makeRequest, getErrorProps } from "@utils/helpers";
import { createDataReq } from "../requests";
import { Commons, Const } from "../../constants";
import { createSelectList } from '@comps/commonFunctions';


const langKey = Const["saleIncentives"].lngKey
const formName = "createAttendanceRequest";
const CreateSaleIncentives = (props) => {
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [branches, setBranches] = useState([])
  const [incentiveTypes, setIncentiveTypes] = useState([])

  const [form] = Form.useForm()
  useEffect(() => {
    setRelatedData()
    // eslint-disable-next-line
  }, []);

  const setRelatedData = () => {

    setBranches(createSelectList(props.allData.branches))
    setIncentiveTypes(createSelectList(props.allData.payitems))
  }

  const onSubmit = (data) => {
    const payload = {
      sale_incentive: {
        company_id: 1,
        branch_id: data.branch_id,
        payitem_id: data.payitem_id,
        month: data.month.format('YYYY-MM-DD'),
        total_amount: data.total_amount,
        is_flat: data.is_flat,
        applicable_amount: data.applicable_amount
      }
    }
    makeRequest(setLoader, createDataReq, payload, onSuccess, onError);
    setSaveLoader(true)
  }
  const onSuccess = (data, res) => {
    setSaveLoader(false)
    message.success("Saved successfully");
    props.onCompleted(data.sale_incentives.data);
    props.onSuccess(data)
  }

  const onError = (err, res) => {

    setSaveLoader(false)
    message.error(res.response.data.message);
  }
  //-----------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------
  const footer = [
    <SaveButton loading={ saveLoader } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];
  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------
  return (
    <ModalComponent top={ 20 } width={ 1000 } maskClosable={ false }
      mainTitle={ trans(Commons.create) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Skeleton loading={ loading }>
        <Form { ...formLayout } form={ form } name={ formName } onFinish={ onSubmit }>
          <Row gutter={ 30 }>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="branch_id" rules={ rules.branch_id } label={ trans(`${langKey}.branch_id`) }
                className="da-mb-16"
                { ...getErrorProps(errors['name']) } size="small"
              >
                <SelectWithSearch
                  options={ branches }
                  placeholder={ trans(`${langKey}.branch_id`) }
                />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="month" rules={ rules.month } label={ trans(`${langKey}.month`) }
                className="da-mb-16"
                { ...getErrorProps(errors['month']) } size="small"
              >
                <InputDate picker="month" format='YYYY-MM' />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="payitem_id" rules={ rules.payitem_id } label={ trans(`${langKey}.payitem_id`) }
                className="da-mb-16"
                { ...getErrorProps(errors['name']) } size="small"
              >
                <SelectWithSearch
                  options={ incentiveTypes }
                  placeholder={ trans(`${langKey}.payitem_id`) }
                />
              </Form.Item>
            </Col>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="total_amount" rules={ rules.total_amount } label={ trans(`${langKey}.total_amount`) }
                className="da-mb-16"
                { ...getErrorProps(errors['total_amount']) } size="small"
              >
                <InputText placeholder={ trans(`${langKey}.total_amount`) } type="number" />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="is_flat" rules={ rules.is_flat } label={ trans(`${langKey}.is_flat`) }
                className="da-mb-16"
                { ...getErrorProps(errors['is_flat']) } size="small"
              >
                <SelectWithSearch
                  options={ [
                    {
                      label: "Percentage",
                      value: false
                    },
                    {
                      label: "Flat",
                      value: true
                    }
                  ] }
                  placeholder={ trans(`${langKey}.payitem_id`) }

                />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="applicable_amount" rules={ rules.applicable_amount } label={ trans(`${langKey}.applicable_amount`) }
                className="da-mb-16"
                { ...getErrorProps(errors['applicable_amount']) } size="small"
              >
                <InputText placeholder={ trans(`${langKey}.applicable_amount`) } type="number" />
              </Form.Item>
            </Col>
          </Row>

        </Form>
      </Skeleton>
    </ModalComponent>
  )
}

export default CreateSaleIncentives

const rules = {
  branch_id: [
    { required: true, message: trans(`${langKey}.branch_id`) },
  ],
  payitem_id: [
    { required: true, message: trans(`${langKey}.payitem_id`) },
  ],
  month: [
    { required: true, message: trans(`${langKey}.month`) },
  ],
  applicable_amount: [
    { required: true, message: trans(`${langKey}.applicable_amount`) },
  ],
  total_amount: [
    { required: true, message: trans(`${langKey}.total_amount`) },
  ],
  is_flat: [
    { required: true, message: trans(`${langKey}.is_flat`) },
  ],
};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}

