import React, { useState, useEffect } from 'react';
import { Row, Col, Spin, Progress } from 'antd';
import { makeRequest } from '@utils/helpers';
import { getLeaveSummaryReq } from '../requests';
import "../dashboard.css"

const LeaveSummary = () => {
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    makeRequest(setLoader, getLeaveSummaryReq, null, onSuccess, onError)
  }
    , []);

  const onSuccess = (data) => {
    setData(data.leave_types);
  }

  const onError = (error) => {
  }

  return (
    <div className='widgetbox widgetboxminH' >
      <Row gutter={ 10 }>
        <Col span={ 12 }>
          <h3>Leave Summary</h3>
        </Col>
        <Col span={ 12 }>
          <div className='changeDate'>
          </div>
        </Col>
      </Row>
      <div className='widgetcontent ' style={ { maxHeight: '360px', overflowY: 'scroll' } }>
        <Spin spinning={ loader } tip="Loading...">
          <Row gutter={ 0 }>
            { data && data.map((item, index) =>
              <Col span={ 8 } className='p-1'>
                <div className='leaveSummaryBox'>
                  <h5>{ item.name }</h5>
                  <div className='leaveinfobox'>
                    <Progress type="circle" percent={ item.availed / item.count * 100 } format={ (percent) => `${item.availed}/${item.count}` } />
                  </div>
                  <div className='leaveRemain'>
                    <Row className='leaveSummaryBoxp'>
                      <Col span={ 12 }>
                        Availed
                      </Col>
                      <Col span={ 12 }>
                        { item.availed }
                      </Col>
                      <Col span={ 12 }>
                        Available
                      </Col>
                      <Col span={ 12 }>
                        { item.available }
                      </Col>
                      <Col span={ 12 }>
                        Balance
                      </Col>
                      <Col span={ 12 }>
                        { item.count }
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            ) }
          </Row>
        </Spin>
      </div>
    </div>
  );
}

export default LeaveSummary;