
import { trans } from '../../wrappers/components';




export const getRequestFilters = (payload) => {
  const common = "common";
  return [
    {
      type: "select",
      placeholder: trans(`${common}.employee`),
      key: "employees",
      data_key: "supporting_data.employments",
      resource_type: 'employment',
     
    },
    {
      type: "select",
      placeholder: trans(`${common}.location`),
      key: "location",
      data_key: "organization_structure_data.location",
      //is_resource: true
    },
    {
      type: "select",
      placeholder: trans(`${common}.branch`),
      key: "branch",
      data_key: "organization_structure_data.branch",
    },
    {
      type: "select",
      placeholder: trans(`${common}.department`),
      key: "department",
      data_key: "organization_structure_data.department",
      filterKey: "hierarchy_code"
    },
    {
      type: "select",
      placeholder: trans(`${common}.status`),
      key: "request_status",
      data_key: "supporting_data.request_status",
      //is_resource: true
    },
    {
      type: "select",
      placeholder: 'Delegator',
      key: "delegator",
      data_key: "supporting_data.delegator",
      resource_type: 'employment',
     
      //is_resource: true
    },
    {
      type: "select",
      placeholder: trans(`${common}.next_requestee`),
      key: "next_requestee",
      data_key: "supporting_data.employments",
          resource_type:'employment'
      //is_resource: true
    },
    {
      type: "text",
      placeholder: "Code",
      key: "code"
    },
    {
      type: "date",
      placeholder: "Completion From",
      key: "completion_from"
    },
    {
      type: "date",
      placeholder: "Completion To",
      key: "completion_to"
    },
  ]
}

export const getOnlyRequestFilters = (payload) => {
  const common = "common";
  return [
    {
      type: "select",
      placeholder: trans(`${common}.employee`),
      key: "employees",
      data_key: "supporting_data.employees",
      resource_type: 'employment',
     
    },
    {
      type: "select",
      placeholder: trans(`${common}.location`),
      key: "location",
      data_key: "organization_structure_data.location",
      //is_resource: true
    },
    {
      type: "select",
      placeholder: trans(`${common}.branch`),
      key: "branch",
      data_key: "organization_structure_data.branch",
    },
    {
      type: "select",
      placeholder: trans(`${common}.department`),
      key: "department",
      data_key: "organization_structure_data.department",
       filterKey: "hierarchy_code"
    },
    {
      type: "select",
      placeholder: trans(`${common}.status`),
      key: "request_status",
      data_key: "supporting_data.request_status",
      //is_resource: true
    },
    {
      type: "text",
      placeholder: "Code",
      key: "code"
    },
    {
      type: "date",
      placeholder: "Completion From",
      key: "completion_from"
    },
    {
      type: "date",
      placeholder: "Completion To",
      key: "completion_to"
    },
  ]
}
export const getOrganizationFilters = (payload) => {
  const common = "common";
  return [

    {
      type: "select",
      placeholder: trans(`${common}.location`),
      key: "location",
      data_key: "organization_structure_data.location",
      //is_resource: true
    },
    {
      type: "select",
      placeholder: trans(`${common}.branch`),
      key: "branch",
      data_key: "organization_structure_data.branch",
    },
    {
      type: "select",
      placeholder: trans(`${common}.department`),
      key: "department",
      data_key: "organization_structure_data.department",
       filterKey: "hierarchy_code"
    },
  ]
}

export const getDatesFilters = (payload) => {
  return [
    {
      type: "date",
      placeholder: "From",
      key: "from",
      required: false
    },
    {
      type: "date",
      placeholder: "To",
      key: "to",
      required: false
    },
  ]
}
export const getOptionalDatesFilters = (payload) => {
  return [
    {
      type: "date",
      placeholder: "From",
      key: "from",
      required: false
    },
    {
      type: "date",
      placeholder: "To",
      key: "to",
      required: false
    },
  ]
}

export const getEmployeesFilter = (payload) => {
  const obj = {
    type: "select",
    placeholder: 'Employee',
    key: "employees",
    data_key: "supporting_data.employments",
    resource_type: "employment",
  }
  return obj;
}

export const getNameAndCodeFilter = (payload) => {
  return [
    {
      type: "text",
      placeholder: "Code",
      key: "code"
    },
    {
      type: "text",
      placeholder: "Name",
      key: "name"
    },
  ]
}





