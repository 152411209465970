
import { Form, Button, Row, Col } from "antd"
import { useEffect, useState } from "react"
import { Commons, Const } from "../../constants";
import { SaveButton, CancelButton, ModalComponent, trans, SelectWithSearch, InputText } from "@comps/components"
import { DeleteOutlined } from '@ant-design/icons'

const langKey = Const["kpi"].lngKey

const CreateTaskData = (props) => {
  const [type, setType] = useState('text');
  const [employments, setEmployments] = useState([])
  const [form] = Form.useForm()
  useEffect(() => {
    getRelatedData()
    // eslint-disable-next-line
  }, []);

  const getRelatedData = () => {
    setEmployments(props.employments)
  }

  const onSubmit = (data) => {

    if (type === 'options') {
      let ops = ''
      if (data.field_options !== undefined && data.field_options.length > 0) {
        data.field_options.forEach(each => {
          ops += each.name + ','
        })
      }
      data.name = ops.slice(0, -1)
    }
    props.onCompleted({ type: data.type, name: data.name }, 'Added')
  }

  const footer = [
    <SaveButton form={ 'createWorkSheetTaskFields' } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];
  return (

    <ModalComponent top={ 20 } width={ 1000 } maskClosable={ false }
      mainTitle={ trans(Commons.create) } subTitle={ trans(`${langKey}.task_fields`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Form { ...formLayout } name={ 'createWorkSheetTaskFields' } onFinish={ onSubmit } form={ form } >

        <Form.Item name='type' rules={ rules.type } label={ trans(`${langKey}.type`) }
        >
          <SelectWithSearch options={
            [
              {
                label: 'Text',
                value: 'text'
              },

              {
                label: 'Options',
                value: 'options'
              },
              {
                label: 'Date',
                value: 'date'
              },

            ]
          }
            onChange={ (value) => setType(value) }
          />
        </Form.Item>
        {
          type === 'text' || type === 'date' ?
            <Form.Item name="name" rules={ rules.name } label={ trans(`${langKey}.name`) }
              className="da-mb-16"

            >
              <InputText />
            </Form.Item>

            :

            <>
              <Row style={ { marginTop: '20px' } }>
                <Col span={ 24 }>
                  <div className="formlist-container">
                    <h5 className="formlist-title">Options</h5>
                    <Form.List name="field_options" >
                      { (fields, { add, remove }) => (
                        <>
                          <Button onClick={ () => {
                            add()
                            //setRows(fields.length, 'Added')
                          } } type="primary" className="add-node-button">Add Option</Button>
                          <div className="table-container">
                            <table className="table">
                              {
                                fields.length !== 0 && <thead>
                                  <tr>
                                    <th>{ trans(`${langKey}.name`) }</th>
                                    <th>{ trans(Commons.actions) }</th>
                                  </tr>
                                </thead>
                              }
                              <tbody>
                                { fields.map(({ key, name, ...restField }) => (
                                  <>
                                    <tr>
                                      <td style={ { minWidth: '200px' } }>
                                        <Form.Item
                                          { ...restField }
                                          name={ [name, 'name'] }
                                          rules={ rules.name }
                                        >
                                          <InputText
                                          />
                                        </Form.Item>
                                      </td>

                                      <td>
                                        <DeleteOutlined className="formlist-remove" onClick={ () => {
                                          remove(name)
                                          //setRows(key, 'Deleted')
                                        } } />
                                      </td>
                                    </tr>
                                  </>
                                )) }
                              </tbody>
                            </table>
                          </div>
                        </>
                      ) }
                    </Form.List>
                  </div>
                </Col>
              </Row>

            </>
        }
      </Form>
    </ModalComponent>
  )
}
export default CreateTaskData

const formLayout = {
  layout: "horizontal",
  labelCol: { span: 6 },
  wrapperCol: { span: 14, offset: 2 },
  labelAlign: "left"
}
const rules = {
  type: [
    { required: true, message: trans(`${langKey}.type`) },
  ],
  name: [
    { required: true, message: trans(`${langKey}.name`) },
  ],
  title: [
    { required: true, message: trans(`${langKey}.title`) },
  ],

}
