import React, { useEffect, useState } from "react";
import { Form, message, Row, Col } from "antd";
import { SaveButton, InputText, CancelButton, ModalComponent, trans } from "@comps/components"
import { makeRequest, getErrorProps } from "@utils/helpers";
import { createDataReq } from "../requests";
import { Commons, Const } from "../../../constants";
const langKey = Const["breakType"].lngKey
const formName = "createBreakType";

const CreateBreakType = (props) => {
  const [loader, setLoader] = useState(false);
  const [saving, setsaving] = useState(false);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    // eslint-disable-next-line
  }, []);

  const onSubmit = (data) => {
    setsaving(true)
    const payload = { break_type: data };
    makeRequest(setLoader, createDataReq, payload, onSuccess, onError);
  }

  const onSuccess = (data, res) => {
    message.success("Saved successfully");
    props.onCompleted(data.break_types.data);
    props.onSuccess(data)
  }

  const onError = (err, res) => {
    setsaving(false)
    message.error(res.response.data.message);
  }

  //-----------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [
    <SaveButton loading={ saving } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } width={ 1000 } maskClosable={ false }
      mainTitle={ trans(Commons.create) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Form { ...formLayout } name={ formName } onFinish={ onSubmit } scrollToFirstError>
        <Row gutter={ 30 }>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="name" rules={ rules.name } label={ trans(Commons.name) }
              className="da-mb-16"
              { ...getErrorProps(errors['name']) } size="small"
            >
              <InputText placeholder={ trans(`${langKey}.name`) } />
            </Form.Item>
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="start" rules={ rules.start } label={ trans(`${langKey}.start`) }
              className="da-mb-16"
              { ...getErrorProps(errors['start']) } size="small"
            >
              <InputText placeholder={ trans(`${langKey}.start`) } />
            </Form.Item>
          </Col>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="end" rules={ rules.end } label={ trans(`${langKey}.end`) }
              className="da-mb-16"
              { ...getErrorProps(errors['end']) } size="small"
            >
              <InputText placeholder={ trans(`${langKey}.end`) } />
            </Form.Item>
          </Col>
        </Row>

      </Form>
    </ModalComponent>
  )
}

export default CreateBreakType

const rules = {
  name: [
    { required: true, message: trans(`${langKey}.name`) },
  ],
  start: [
    { required: true, message: trans(`${langKey}.start`) },
  ],
  end: [
    { required: true, message: trans(`${langKey}.end`) },
  ],

};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}