import React, { useEffect, useState } from "react";
import { Button, Form, Skeleton, message } from "antd";
import { SaveButton, CancelButton, ModalComponent, trans, InputDate, InputTextArea, InputTime, TableComponent, ActionComponent, FlowHistoryButton } from "@comps/components"
import { makeRequest, getErrorProps } from "@utils/helpers";
import { Commons, Const } from "../constants";
import moment from "moment/moment";
import RoasterView from "@mods/common/RoasterView";
import { getRosterReq } from "../relaxation/myRelaxation/requests";
import { approveRequest } from "../relaxation/relaxationApproval/requests";
import CreateConsent from "./CreateConsent";
import CreateConsentChat from "./CreateConsentChat";
import EmployeeInfo from '@mods/common/EmployeeInfo';
import { convertTimeTotz } from '@comps/commonFunctions';
const langKey = Const["relaxationApproval"].lngKey
const formName = "updateRelaxationApproval";

const UpdateRelaxationApproval = (props) => {
  const [loader, setLoader] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [roasterView, setRoasterView] = useState(null)
  const [preEdit, setPreEdit] = useState(true)
  const [remarks, setRemarks] = useState('');
  const [consents, setConsents] = useState([]);
  const [creatConsentModal, setCreateConsentModal] = useState(null);
  const [childComponent, setChildComponent] = useState(null)
  const [loading, setLoading] = useState(true)
  const [form] = Form.useForm()
  useEffect(() => {
    setData();
    // eslint-disable-next-line
  }, []);

  const setData = () => {
    setConsents(props.record.consents)
    let date = props.record.date ? convertTimeTotz(props.record.date) : null
    getRoster(date)
    props.record.date = date
    props.record.start_time = props.record.start_time ? convertTimeTotz(props.record.start_time) : null
    props.record.end_time = props.record.end_time ? convertTimeTotz(props.record.end_time) : null
    form.setFieldsValue(props.record);
  }
  const getRoster = (date) => {
    const payload = { relaxation: { employment_id: props.record.employment_id, date: date.format('YYYY-MM-DD') } }
    makeRequest(setLoader, getRosterReq, payload, onGetRosterSuccess, onGetRosterSuccess);
  }
  const onGetRosterSuccess = (response, data) => {
    if (data.shifts) {
      setRoasterView(<RoasterView record={ data } />)
      let start_time = data.shifts[convertTimeTotz(data.from_date).format('YYYYMMDD') + '-' + data.employments[0].id][0]['start']
      let end_time = data.shifts[convertTimeTotz(data.from_date).format('YYYYMMDD') + '-' + data.employments[0].id][0]['end']

      if (preEdit === false) {
        form.setFieldsValue({
          start_time: convertTimeTotz(start_time),
          end_time: convertTimeTotz(end_time),
        })
      }
    }
    else {
      message.error('No Shift Found For Given Date!')
      return
    }
    setLoading(false)
  }

  //===========constents =============
  const onCreateConsent = () => {
    setCreateConsentModal(<CreateConsent onCreatedConsent={ onCreatedConsent } employment_id={ props.record.employment_id } request_id={ props.record.id } request_as={ props.requestAsId } api="relaxationApprovals/createConsent" />)
  }
  const onCreatedConsent = (response) => {
    if (response) {
      message.success("Consent created successfully");
      props.onCompleted(response.relaxations.data);
    }

    setCreateConsentModal(null)
  }
  const onConsentChat = (record) => {
    setCreateConsentModal(<CreateConsentChat record={ record } onCreatedConsent={ onCreatedConsent } request_as={ props.requestAsId } api="relaxationApprovals/messageConsent" />)
  }
  //============end consents================================
  const onSubmit = (isApproval) => {
    const last_remarks = form.getFieldValue('last_remarks');
    setSaveLoader(true)
    let requestObj = {
      request: {
        ...props.record, request_as: props.requestAsId,
        is_approval: isApproval,
        last_remarks: last_remarks,
        remarks: last_remarks,
        //current_history: { last_remarks: last_remarks }
      },
      delegator_id: props.delegatorId, api: props.api, filters: props.filters
    }
    const payload = requestObj
    makeRequest(setLoader, approveRequest, payload, onSuccess, onError);
  }
  const onSuccess = (data, res) => {
    setSaveLoader(false)
    message.success("Saved successfully");
    props.onCompleted(data.relaxations.data);
  }
  const onError = (err, res) => {
    setSaveLoader(false)
    message.error(res.response.data.message);
  }
  //----------------------
  const consentColumns = [
    {
      key: 'consentor_name',
      title: trans(`${langKey}.consentor_name`),
      dataIndex: 'consentor_name'
    },
    {
      key: 'consentee_name',
      title: trans(`${langKey}.consentee_name`),
      dataIndex: 'consentee_name'
    },
    {
      key: 'consent_date',
      title: trans(`${langKey}.consent_date`),
      render: (record) => {
        return record.created_at ? convertTimeTotz(record.created_at).format('DD-MM-YYYY h:mm:ss a') : null
      }
    },
    {
      key: trans(Commons.actions),
      title: trans(Commons.actions),
      render: (record) => (
        <ActionComponent
          each={ record } modkey={props.modkey}
          onView={ onConsentChat }
        ></ActionComponent>
      ),
    },
  ]
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------
  const footer = [
    childComponent,
    <div style={ { float: 'left' } }>
      < FlowHistoryButton record={ props.record } setChildComponent={ setChildComponent } />
    </div>,
    <SaveButton disabled={ props.disabled } loading={ saveLoader } form={ formName } key="create_button" onClick={ () => onSubmit(true) } text='Approve' />,
    <CancelButton disabled={ props.disabled } loading={ saveLoader } key="reject_button" form={ formName } htmlType='submit' text='Reject' onClick={ () => onSubmit(false) } />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];
  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------
  return (
    <ModalComponent top={ 20 } width={ 1200 } maskClosable={ false }
      mainTitle={ trans(props.disabled ? Commons.view : Commons.update) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Skeleton loading={ loading }>
        <EmployeeInfo record={ props.record.requestor && props.record.requestor } />
        <Form { ...formLayout } name={ formName } onFinish={ onSubmit } form={ form } disabled={ true } scrollToFirstError>

          <h5 className="formlist-title">Relaxation Request</h5>
          <Form.Item name="date" rules={ rules.date } label={ trans(`${langKey}.date`) }
            className="da-mb-16"
            { ...getErrorProps(errors['date']) } size="small"
          >
            <InputDate />
          </Form.Item>
          { roasterView }
          <Form.Item name="start_time" rules={ rules.start_time } label={ trans(`${langKey}.start_time`) }
            className="da-mb-16"
            { ...getErrorProps(errors['start_time']) } size="small"
          >
            <InputTime placeholder={ trans(`${langKey}.start_time`) } />
          </Form.Item>
          <Form.Item name="end_time" rules={ rules.end_time } label={ trans(`${langKey}.end_time`) }
            className="da-mb-16"
            { ...getErrorProps(errors['end_time']) } size="small"
          >
            <InputTime placeholder={ trans(`${langKey}.end_time`) } />
          </Form.Item>

          <Form.Item name="reason" rules={ rules.reason } label={ trans(`${langKey}.reason`) }
            className="da-mb-16"
            { ...getErrorProps(errors['reason']) } size="small"
          >
            <InputTextArea placeholder={ trans(`${langKey}.reason`) } />
          </Form.Item>

          <Form.Item name="last_remarks" rules={ rules.last_remarks } label={ trans(`${langKey}.last_remarks`) }
            className="da-mb-16"
            { ...getErrorProps(errors['last_remarks']) } size="small"
          >
            <InputTextArea placeholder={ trans(`${langKey}.last_remarks`) } disabled={ props.disabled } onChange={ (e) => setRemarks(e.target.value) } />
          </Form.Item>
        </Form>

        <h5 className="formlist-title">Consents <Button type="primary" title='Create' onClick={ onCreateConsent } className="float-end me-5">Create</Button></h5>
        { creatConsentModal }
        <TableComponent dataSource={ consents } columns={ consentColumns } />
      </Skeleton>
    </ModalComponent>
  )
}

export default UpdateRelaxationApproval

const rules = {
  name: [
    { required: true, message: '' },
  ],

};

const formLayout = {
  // layout: "horizontal",
  labelCol: { span: 6 },
  wrapperCol: { span: 14, offset: 2 },
  labelAlign: "left"
}