import React from "react";
import { Route, Routes } from "react-router-dom";
import IndexCompanyRoaster from "./companyRoster/IndexCompanyRoaster";
import IndexTimeSlots from "./timeSlots/IndexTimeSlots";
import IndexHolidays from "./holiday/IndexHolidays";
import IndexRestDays from "./restday/IndexRestDays";
import CreateCompanyRoaster from "./companyRoster/components/CreateCompanyRoaster";
import DeleteCompanyRoaster from "./companyRoster/components/DeleteCompanyRoaster";
import { useSelector } from "react-redux";
const RosterModules = () => {

  const authUser = useSelector((state) => state.auth.authUser);
  return (
    <Routes>
      <Route path="my-roster" element={ <IndexCompanyRoaster module='myRoaster' filters={ `employee_|${authUser.employment_id}` } modkey="myRoster"/> } />
      <Route path="subordinate-roster" element={ <IndexCompanyRoaster module="subordinateRoaster" filters={ `subordinate_|${authUser.employment_id}` } modkey="subordinateRoster"/> } />
      <Route path="roster" element={ <IndexCompanyRoaster module="companyRoaster" filters="" modkey="roster"/> } />
      <Route path="timeslots" element={ <IndexTimeSlots modkey="timeSlot"/> } />
      <Route path="holidays" element={ <IndexHolidays modkey="holiday"/> } />
      <Route path="rest-days" element={ <IndexRestDays modkey="restDays"/> } />
      <Route path="create" element={ <CreateCompanyRoaster modkey="roster"/> } />
      <Route path="delete" element={ <DeleteCompanyRoaster modkey="roster"/> } />
    </Routes>
  );
}

export default RosterModules;