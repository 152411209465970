import { HeaderComponent, BodyComponent, TableComponent, ActionComponent, CreateButton,RefreshButton, FilterComponent, ImportButton,PaginationComponent } from '@comps/components';
import { makeRequest, formatCompleteDataTime, notify, removeById, replaceById } from '@utils/helpers';
import { useState, useEffect } from 'react';
import { getAllGroups, deleteJobTitleReq, getFilterReq } from "./requests";


// ==================================
//   Page Breadcrumbs Start
// ==================================
const pageConfig = {
  headers: {
    title: 'groups',
    breadcrumb: [
      {
        name: 'groups',
        path: '/organization/Route'
      }
    ]
  }
}

// ==================================
//   Page Breadcrumbs End
// ==================================

// ==================================
//   Use States Start
// ==================================

const Indexgroups = (props) => {
  const [totalRecords, setTotalRecords] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [loader, setLoader] = useState(false);
  const [childComponent, setChildComponent] = useState(null);
  const [filters, setFilters] = useState();
   //start pagination states
   const [currentPage, setCurrentPage] = useState(1)
   const [pagination, setPagination] = useState({
     "total": 14,
     "count": 10,
     "per_page": 10,
     "current_page": 1,
     "total_pages": 2,
     "links": {}
   });

  // ==================================
  //   Use States End
  // ==================================

  // ==================================
  //   Table Data End
  // ==================================
  const columns = [
    {
      key: 'code',
      title: 'Code',
      dataIndex: 'code',
      sorter: true,
    },
    {
      key: 'actions',
      title: 'Actions',
      render: (record) => <ActionComponent modkey={props.modkey} each={ record } onEdit={ onEdit } onView={ onView }>
      </ActionComponent>
    },
  ];

  // ==================================
  //   Table Data End
  // ==================================

  useEffect(() => {
    getAllData();
    // eslint-disable-next-line
  }, [filters, currentPage]);
  const getAllData = () => {
    const payload = {
      page: currentPage,
      filters: filters
    }
    makeRequest(setLoader, getAllGroups, payload, onSuccess, null);
  }

  const onSuccess = (response) => {
    setPagination(response.groups);
    setDataSource(response.groups.data);
  }

  const onEdit = (record) => {
    // setChildComponent(<UpdateCompany onCreated={ onCreated } record={ record } />);
  }

  const onView = (record) => {
    // setChildComponent(<ViewCompany onCreated={ onCreated } data={ record } />);
  }

  // Create component modal
  const onCreate = () => {
    // setChildComponent(<CreateCompany onCreated={ onCreated } />);
  }

  const onCreated = (data) => {
    if (data.length) {
      setDataSource(data)
    }
    setChildComponent(null);
  }

  const handleTableChange = (page, fil, sorter) => {
    let payload = {
      ...pagination,
      current: page.current,
      pageSize: page.pageSize,
      sortName: sorter.field || 'id',
      sortType: sorter.order === 'ascend' ? 'asc' : 'desc',
    };
    setPagination(payload);
  }

  // ==================================
  //   Component JSX Start
  // ==================================

  return (
    <>
      { childComponent }
      <HeaderComponent headers={ pageConfig.headers } modkey={props.modkey}>
        <RefreshButton onClick={ getAllData } /> <CreateButton onClick={ onCreate } />
      </HeaderComponent>
      <BodyComponent>

        <TableComponent loader={ loader } columns={ columns } dataSource={ dataSource } pagination={ {
          ...pagination,
          total: totalRecords
        } } onChange={ handleTableChange } />
      </BodyComponent>
      <PaginationComponent pagination={ pagination } modkey={props.modkey} setCurrentPage={ setCurrentPage } currentPage={ currentPage }/>
    </>
  );

}

// ==================================
//   Component JSX End
// ==================================

export default Indexgroups
