import React, { useEffect, useState } from "react";
import { Form, Modal, Upload, Row, Col } from "antd";
import { SaveButton, InputText, InputTextArea, CancelButton, ModalComponent, trans, InputTime, InputDate } from "@comps/components"

import { Commons, Const } from "../../../constants";

import "../../style.css"
const langKey = Const["companyTravel"].lngKey
const CreateTicketBookings = (props) => {
  const [saveLoader, setSaveLoader] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [form] = Form.useForm()
  useEffect(() => {
    // eslint-disable-next-line
  }, []);


  //============uploading file============
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const handleCancel = () => setPreviewOpen(false);
  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };
  const onPreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    //setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };
  const onRemove = () => {
    //setFileList([null])
  }

  //save

  const onSubmit = (data) => {
    props.onCompleted(data, 'Added')
  }

  const footer = [
    props.disabled ? '' :
      <SaveButton disabled={ saveLoader } form={ 'ticektForm' } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];
  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------
  return (
    <ModalComponent top={ 20 } width={ 1000 } maskClosable={ false }
      mainTitle={ trans(Commons.create) } subTitle={ "Ticket Booking" }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Form { ...formLayout } form={ form } name={ 'ticektForm' } disabled={ props.disabled } onFinish={ onSubmit } scrollToFirstError>
        <Row gutter={ 30 }>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item label={ trans(`${langKey}.ticket_no`) }
              name={ 'ticket_no' }
              rules={ rules.ticket_no }
            >
              <InputText placeholder={ trans(`${langKey}.ticket_no`) } />
            </Form.Item>
          </Col>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item label={ trans(`${langKey}.class`) }
              name={ 'class' }
              rules={ rules.class }
            >
              <InputText placeholder={ trans(`${langKey}.class`) } />
            </Form.Item>
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item label={ trans(`${langKey}.from`) }
              name={ 'from' }
              rules={ rules.from }
            >
              <InputText placeholder={ trans(`${langKey}.from`) } />
            </Form.Item>
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item label={ trans(`${langKey}.to`) }
              name={ 'to' }
              rules={ rules.to }
            >
              <InputText placeholder={ trans(`${langKey}.to`) } />
            </Form.Item>
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item label={ trans(`${langKey}.boarding_date`) }
              name={ 'boarding_date' }
              rules={ rules.boarding_date }
            >
              <InputDate />
            </Form.Item>
          </Col>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item label={ trans(`${langKey}.boarding_time`) }
              name={ 'boarding_time' }
              rules={ rules.boarding_time }
            >
              <InputTime placeholder={ trans(`${langKey}.boarding_time`) } />
            </Form.Item>
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item label={ trans(`${langKey}.description`) }
              name={ 'description' }
              rules={ rules.description }
            >
              <InputTextArea placeholder={ trans(`${langKey}.description`) } />
            </Form.Item>
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item label={ trans(`${langKey}.attachment`) } name="attachment" >
              <Upload accept=".jpg,.png,.jpeg"
                listType="picture-card"
                fileList={ fileList }
                onChange={ onChange }
                onPreview={ onPreview }
                onRemove={ onRemove }
                multiple={ false }
                beforeUpload={ (file) => {
                  return false;
                } }
              >
                { fileList.length === 1 ? null : 'Choose' }
              </Upload>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Modal open={ previewOpen } title={ previewTitle } footer={ null } onCancel={ handleCancel }>
        <img
          alt="example"
          style={ {
            width: '100%',
          } }
          src={ previewImage }
        />
      </Modal>
    </ModalComponent>

  )
}
export default CreateTicketBookings

const rules = {
  departure_date: [
    { required: true, message: trans(`${langKey}.departure_date`) },
  ],
  departure_time: [
    { required: true, message: trans(`${langKey}.departure_time`) },
  ],
  arrival_time: [
    { required: true, message: trans(`${langKey}.arrival_time`) },
  ],
  arrival_date: [
    { required: true, message: trans(`${langKey}.arrival_date`) },
  ],
  travel_type_id: [
    { required: true, message: trans(`${langKey}.travel_type_id`) },
  ],
  travel_mode_id: [
    { required: true, message: trans(`${langKey}.travel_mode_id`) },
  ],
  from: [
    { required: true, message: trans(`${langKey}.from`) },
  ],
  to: [
    { required: true, message: trans(`${langKey}.to`) },
  ],
  pupose: [
    { required: true, message: trans(`${langKey}.pupose`) },
  ],
  employment_id: [
    { required: true, message: trans(`${langKey}.employment_id`) },
  ],
  boarding_date: [
    { required: true, message: trans(`${langKey}.boarding_date`) },
  ],
  boarding_time: [
    { required: true, message: trans(`${langKey}.boarding_time`) },
  ],
};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}
