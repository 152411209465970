import React, { useEffect, useState } from "react";
import { Form, Skeleton, message, Row, Col } from "antd";
import { SaveButton, CancelButton, ModalComponent, trans, InputDate, InputTextArea, SelectWithSearch, SelectResourceList } from "@comps/components"
import { makeRequest, makeRequestStateless, getErrorProps } from "@utils/helpers";
import { createDataReq, getEmployeeRelatedDataReq, getOrgRelatedDataReq } from "../requests";
import { Commons, Const } from "../../../constants";
import dayjs from "dayjs";

import { createSelectList } from '@comps/commonFunctions';

const langKey = Const["companyTransfers"].lngKey
const formName = "createTransferRequest";
const CreateCompanyTransferRequest = (props) => {
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(true);
  const [saveLoader, setSaveLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [locations, setlocations] = useState([]);
  const [branches, setbranches] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [payrolls, setPayrolls] = useState([]);
  const [linemanagers, setlinemanagers] = useState([]);

  const [form] = Form.useForm()
  useEffect(() => {
    getRelated()
    // eslint-disable-next-line
  }, []);


  const getRelated = () => {
    makeRequestStateless(getOrgRelatedDataReq, null, ongetRelated, false)
  }
  const ongetRelated = (data,response) => {
    if(data){
      setDepartments(createSelectList(data.departments))
      setlocations(createSelectList(data.locations))
      setbranches(createSelectList(data.branches))
      setlinemanagers(createSelectList(data.linemanagers))
      setPayrolls(createSelectList(data.payrolls))
    }
    setLoading(false)
  }

  const onChangeEmployments = (id) => {
    form.setFieldsValue(
      {
        location_id: null,
        branch_id: null,
        department_id:null,
        linemanager_id: null,
        payroll_id: null
      }
    )
    const payload = {
      employment_id: id
    }
    if(id){
      makeRequest(setLoader, getEmployeeRelatedDataReq, payload, onGetEmployeeData, onGetEmployeeDataError);
      setLoading(true)
    }
    
  }
  const onGetEmployeeData = (response) => {
    form.setFieldsValue(
      {
        location_id: response.employment.location_id,
        branch_id: response.employment.branch_id,
        department_id: response.employment.department_id,
        linemanager_id: response.employment.linemanager_id,
        payroll_id: response.salary ? response.salary.payroll_id : null
      }
    )
    setlinemanagers(createSelectList(response.linemanagers))
    setLoading(false)
  }
  const onGetEmployeeDataError = (response) => {
setLoading(false)
  }
  const onSubmit = (data) => {
    const payload = {
      file_attachment: undefined,
      transfer: { "behalf_employment_id": props.authUser.employment_id, "is_on_behalf": true, "employment_id": data.employment_id, "location_id": data.location_id, "branch_id": data.branch_id, "department_id": data.department_id, "linemanager_id": data.linemanager_id, "payroll_id": data.payroll_id, "date": dayjs(data.data).format('YYYY-MM-DD'), "description": data.description }
    }
    makeRequest(setLoader, createDataReq, payload, onSuccess, onError);
    setSaveLoader(true)
  }

  const onSuccess = (data, res) => {

    setSaveLoader(false)
    message.success("Saved successfully");
    props.onCompleted(data.transfers.data);
    props.onSuccess(data)
  }

  const onError = (err, res) => {

    setSaveLoader(false)
    message.error(res.response.data.message);
  }
  //-----------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------
  const footer = [
    <SaveButton loading={ saveLoader } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];
  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------
  return (
    <ModalComponent top={ 20 } width={ 1000 } maskClosable={ false }
      mainTitle={ trans(Commons.create) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Skeleton loading={ loading }>
        <Form { ...formLayout } form={ form } name={ formName } onFinish={ onSubmit }>
          <Row gutter={ 30 }>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="employment_id" rules={ rules.employment_id } label={ trans(`${langKey}.employment_id`) }
                className="da-mb-16"
                { ...getErrorProps(errors['name']) } size="small"
              >
                <SelectResourceList type='employment'
                  onChange={ onChangeEmployments }
                />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="location_id" rules={ rules.location_id } label={ trans(`${langKey}.location_id`) }
                className="da-mb-16"
                size="small"
              >
                <SelectWithSearch
                  placeholder={ trans(`${langKey}.location_id`) }
                  options={ locations }
                />
              </Form.Item>
            </Col>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="branch_id" rules={ rules.branch_id } label={ trans(`${langKey}.branch_id`) }
                className="da-mb-16"
                size="small"
              >
                <SelectWithSearch
                  placeholder={ trans(`${langKey}.branch_id`) }
                  options={ branches }
                />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="department_id" rules={ rules.department_id } label={ trans(`${langKey}.department_id`) }
                className="da-mb-16"
                size="small"
              >
                <SelectWithSearch
                  placeholder={ trans(`${langKey}.department_id`) }
                  options={ departments }
                />
              </Form.Item>
            </Col>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="payroll_id" rules={ rules.payroll_id } label={ trans(`${langKey}.payroll_id`) }
                className="da-mb-16"
                size="small"
              >
                <SelectWithSearch
                  placeholder={ trans(`${langKey}.payroll_id`) }
                  options={ payrolls }
                />
              </Form.Item>
            </Col>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="linemanager_id" rules={ rules.linemanager_id } label={ trans(`${langKey}.linemanager_id`) }
                className="da-mb-16"
                size="small"
              >
                <SelectWithSearch
                  placeholder={ trans(`${langKey}.linemanager_id`) }
                  options={ linemanagers }
                />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="date" rules={ rules.date } label={ trans(`${langKey}.date`) }
                className="da-mb-16"
                { ...getErrorProps(errors['date']) } size="small"
              >
                <InputDate />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="description" rules={ rules.description } label={ trans(`${langKey}.description`) }
                className="da-mb-16"
                { ...getErrorProps(errors['description']) } size="small"
              >
                <InputTextArea placeholder={ trans(`${langKey}.description`) } />
              </Form.Item>
            </Col>
          </Row>

        </Form>
      </Skeleton>
    </ModalComponent>
  )
}

export default CreateCompanyTransferRequest

const rules = {
  date: [
    { required: true, message: trans(`${langKey}.date`) },
  ],
  location_id: [
    { required: true, message: trans(`${langKey}.location_id`) },
  ],
  branch_id: [
    { required: true, message: trans(`${langKey}.branch_id`) },
  ],
  department_id: [
    { required: true, message: trans(`${langKey}.department_id`) },
  ],
  payroll_id: [
    { required: true, message: trans(`${langKey}.payroll_id`) },
  ],
  linemanager_id: [
    { required: true, message: trans(`${langKey}.linemanager_id`) },
  ],
  employment_id: [
    { required: true, message: trans(`${langKey}.employment_id`) },
  ],
};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}

/*
on change date
request->companyTransferRequests/getExpectedTime?&transfer_date=2023-02-01T00:00:00.000Z&employment_id=fcf1b373-295f-4c2f-967b-9c830ceacd12
payload={
  transfer_date: 2023-02-01T00:00:00.000Z
employment_id: fcf1b373-295f-4c2f-967b-9c830ceacd12
}


on save
request->/companyTransferRequests?page=1

payload={
  file_attachment: undefined
transfer_request: {"behalf_employment_id":"d46dfabc-2966-42ca-a990-c7bfa0ef4343","is_on_behalf":true,"employment_id":"fcf1b373-295f-4c2f-967b-9c830ceacd12","date":"2023-02-01T00:00:00.000Z","expected_check_in":"2023-02-01T05:00:00.000Z","expected_check_out":"2023-02-01T13:00:00.000Z","punch_type":"Check In","punch_time":"2023-02-01T05:00:00.000Z","description":"test"}
}
*/