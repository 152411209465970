import { Form, Button, Space, Row, Col } from "antd"
import { useEffect, useState } from "react"
import { Commons, Const } from "../../constants";
import { SaveButton, CancelButton, ModalComponent, trans, SelectWithSearch, InputText, InputTextArea } from "@comps/components"
import { DeleteOutlined } from '@ant-design/icons'
import { useSelector } from "react-redux";
import CreateTaskData from "./CreateTaskData";
const langKey = Const["kpi"].lngKey

const CreateKpiTasks = (props) => {
  const authUser = useSelector((state) => state.auth.authUser);
  const [childComponent, setChildComponent] = useState(null)
  const [saving, setsaving] = useState(false);
  const [form] = Form.useForm()
  useEffect(() => {
    getRelatedData()
    // eslint-disable-next-line
  }, []);

  const getRelatedData = () => {

  }

  const onSubmit = (data) => {
    props.onCompleted(data, 'Added')
    setsaving(true)
  }
  const onCreateTaskData = () => {
    setChildComponent(<CreateTaskData onCompleted={ onCompleted } />)
  }
  const onCompleted = (data, action) => {
    if (data) {
      let listArray = form.getFieldValue('kpi_task_fields');
      if (listArray === undefined) {
        listArray = []
      }
      if (data && action === 'Added') {
        listArray.push(data)
        form.setFieldValue('kpi_task_fields', listArray)
        // setPrivilegeFilters(listArray)
      }
      if (data && action === 'Updated') {
        listArray[data.key] = data
        form.setFieldValue('kpi_task_fields', listArray)
        // setPrivilegeFilters(listArray)
      }

    }
    setChildComponent(null)
  }

  const footer = [
    <SaveButton loading={ saving } form={ 'createWorkSheetTasks' } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];
  return (

    <ModalComponent top={ 20 } width={ 1000 } maskClosable={ false }
      mainTitle={ trans(Commons.create) } subTitle={ trans(`${langKey}.tasks`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>

      <Form { ...formLayout } name={ 'createWorkSheetTasks' } onFinish={ onSubmit } form={ form } scrollToFirstError>
        <Row gutter={ 30 }>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="title" rules={ rules.title } label={ trans(`${langKey}.title`) }
              className="da-mb-16"
            >
              <InputTextArea placeholder={ trans(`${langKey}.title`) } />
            </Form.Item>
          </Col>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="description" rules={ rules.description } label={ trans(`${langKey}.description`) }
              className="da-mb-16"
            >
              <InputTextArea placeholder={ trans(`${langKey}.description`) } />
            </Form.Item>
          </Col>
        </Row>

        { childComponent }

        <Row style={ { marginTop: '20px' } }>
          <Col span={ 24 }>
            <div className="formlist-container">
              <h5 className="formlist-title">{ trans(`${langKey}.data`) }</h5>
              <span className="text-info">Options should be Seperated By Commas(ok,Not ok,etc)</span>
              <Form.List name="kpi_task_fields" >
                { (fields, { add, remove }) => (
                  <>
                    <Button onClick={ () => {
                      add()
                    } } type="primary" className="add-node-button">{ trans(`${langKey}.data`) } </Button>
                    <div className="table-container">
                      <table className="table">
                        {
                          fields.length !== 0 && <thead>
                            <tr>
                              <th style={ { paddingLeft: 20 } }>{ trans(`${langKey}.type`) }</th>
                              <th>{ trans(`${langKey}.name`) }</th>
                              <th>{ trans(Commons.actions) }</th>
                            </tr>
                          </thead>
                        }
                        <tbody>
                          { fields.map(({ key, name, ...restField }) => (
                            <>
                              <tr>
                                <td style={ { minWidth: '200px' } }>
                                  <Form.Item
                                    { ...restField }
                                    name={ [name, 'type'] }
                                  >

                                    <SelectWithSearch options={
                                      [
                                        {
                                          label: 'Text',
                                          value: 'text'
                                        },

                                        {
                                          label: 'Options',
                                          value: 'options'
                                        },
                                        {
                                          label: 'Date',
                                          value: 'date'
                                        },
                                      ]
                                    }
                                      placeholder={ trans(`${langKey}.type`) }
                                    //onChange={ (value) => setType(value) }
                                    />

                                  </Form.Item>
                                </td>
                                <td style={ { minWidth: '200px' } }>
                                  <Form.Item
                                    { ...restField }
                                    name={ [name, 'name'] }
                                  >
                                    <InputText placeholder={ trans(`${langKey}.name`) } />
                                  </Form.Item>
                                </td>

                                <td>
                                  <Space>
                                    <DeleteOutlined className="formlist-remove" onClick={ () => {
                                      remove(name)
                                      //props.setRows(key, 'Deleted')
                                    } } />
                                    {/* <EditOutlined onClick={ () => {
                                props.onEdit(key)

                              } } />
                              <EyeOutlined onClick={ () => {
                                props.onView(key)

                              } } /> */}
                                  </Space>
                                </td>

                              </tr>
                            </>
                          )) }
                        </tbody>
                      </table>
                    </div>
                  </>
                ) }
              </Form.List>
            </div>

          </Col>
        </Row>

      </Form>
    </ModalComponent>
  )
}
export default CreateKpiTasks

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}
const rules = {
  title: [
    { required: true, message: trans(`${langKey}.title`) },
  ],
  type: [
    { required: true, message: trans(`${langKey}.type`) },
  ],
  name: [
    { required: true, message: trans(`${langKey}.name`) },
  ],

}
