import { get, del, postFd } from "@utils/axios";

const api = "employee";

export const getAllCertificationsReq = (payload) => {
  return get(`${api}/certifications?page=${payload.page}&filters=${payload.filters}`);
};

export const createCertificationReq = (payload) => {
  return postFd(`${api}/certifications?`, payload);
};

export const updateCertificationReq = (payload) => {
  return postFd(`${api}/certifications/${payload.id}/update`, payload);
};

export const deleteCertificationReq = (id) => {
  return del(`${api}/certifications/${id}`);
};

//-----------on create
export const getAllEmployeesReq = () => {
  return get("resources/employee?page=1");
};

export const getRelatedDataReq = () => {
  return get(`${api}/certifications/get-related-data?`)
}
export const getFilterReq = (payload) => {
  return get(`filters?company_id=1&fields=page=1&filters=supporting_fields_|employees;preset_|employee_certifications;`)
}
