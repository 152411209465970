import React, { useEffect, useState } from "react";
import { Form, Skeleton, message, Row, Col } from "antd";
import { SaveButton, CancelButton, ModalComponent, trans, InputTime, InputTextArea, InputDate, SelectWithSearch, SelectResourceList } from "@comps/components"
import { makeRequest, makeRequestStateless, getErrorProps } from "@utils/helpers";
import { updateDataReq, getRelatedDataReq } from "../requests";
import { Commons, Const } from "../../constants";
import { getEmployeesDataReq } from "@mods/common/requests";
import { convertTimeTotz } from '@comps/commonFunctions';

const langKey = Const["attendanceLogs"].lngKey
const formName = "createAttendanceRequest";
const UpdateAttendanceLogs = (props) => {
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(true);
  const [saveLoader, setSaveLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [punchTypes, setPunchTypes] = useState([])
  const [form] = Form.useForm()
  useEffect(() => {
    getEmployments()
    getRelatedData()
    // eslint-disable-next-line
  }, []);

  const getRelatedData = () => {
    makeRequest(setLoader, getRelatedDataReq, null, setRelatedData, null);
  }
  const getEmployments = () => {
    const payload = { filters: null }
    makeRequestStateless(getEmployeesDataReq, payload, onGetEmployments, false)
  }
  const onGetEmployments = (response) => {
    setLoading(false)
  }

  const setRelatedData = (response) => {
    const punchTypeList = [];
    if (response.punch_types !== undefined) {
      response.punch_types.forEach(each => {
        punchTypeList.push({
          label: each.name,
          value: each.name
        })
      })
    }
    setPunchTypes(punchTypeList)

    form.setFieldsValue({
      employee_id: props.record.employee_id,
      punch_type: props.record.punch_type,
      punch_date: props.record.punch_time ? convertTimeTotz(props.record.punch_time) : null,
      punch_time: props.record.punch_time ? convertTimeTotz(props.record.punch_time) : null,
      description: props.record.description,
    })
  }

  const onSubmit = (data) => {

    const payload = {
      id: props.record.id,
      attendance_log: {
        id: props.record.id,
        employee_id: data.employee_id,
        punch_type: data.punch_type,
        punch_date: data.punch_date,
        punch_time: data.punch_time.year(data.punch_date.year()).month(data.punch_date.month()).date(data.punch_date.date()).second(0),
        description: data.description
      }
    }

    makeRequest(setLoader, updateDataReq, payload, onSuccess, onError);
    setSaveLoader(true)
  }
  const onSuccess = (data, res) => {
    setSaveLoader(false)
    message.success("Saved successfully");
    props.onCompleted(data.attendance_logs.data);
  }

  const onError = (err, res) => {

    setSaveLoader(false)
    message.error(res.response.data.message);
  }
  //-----------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------
  const footer = [
    props.disabled ? '' :
      <SaveButton loading={ saveLoader } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];
  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------
  return (
    <ModalComponent top={ 20 } width={ 1000 } maskClosable={ false }
      mainTitle={ trans(Commons[props.disabled ? 'view' : 'update']) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Skeleton loading={ loading }>
        <Form { ...formLayout } form={ form } name={ formName } onFinish={ onSubmit } disabled={ props.disabled } scrollToFirstError>
          <Row gutter={ 30 }>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="employee_id" rules={ rules.employment_id } label={ trans(`${langKey}.employment_id`) }
                className="da-mb-16"
                { ...getErrorProps(errors['name']) } size="small"
              >
                <SelectResourceList type='employee' disabled={ true } value={ props.record.employee_id } />

              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="punch_type" rules={ rules.punch_type } label={ trans(`${langKey}.punch_type`) }
                className="da-mb-16"
                { ...getErrorProps(errors['name']) } size="small"
              >
                <SelectWithSearch
                  options={ punchTypes }
                  placeholder={ trans(`${langKey}.punch_type`) }

                />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="punch_date" rules={ rules.punch_date } label={ trans(`${langKey}.punch_date`) }
                className="da-mb-16"
                { ...getErrorProps(errors['punch_date']) } size="small"
              >
                <InputDate />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="punch_time" rules={ rules.punch_time } label={ trans(`${langKey}.punch_time`) }
                className="da-mb-16"
                { ...getErrorProps(errors['punch_time']) } size="small"
              >
                <InputTime placeholder={ trans(`${langKey}.punch_time`) } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="description" rules={ rules.description } label={ trans(`${langKey}.description`) }
                className="da-mb-16"
                { ...getErrorProps(errors['description']) } size="small"
              >
                <InputTextArea placeholder={ trans(`${langKey}.description`) } />
              </Form.Item>
            </Col>
          </Row>

        </Form>
      </Skeleton>
    </ModalComponent>
  )
}

export default UpdateAttendanceLogs

const rules = {
  punch_date: [
    { required: true, message: trans(`${langKey}.punch_date`) },
  ],
  punch_time: [
    { required: true, message: trans(`${langKey}.punch_time`) },
  ],
  punch_type: [
    { required: true, message: trans(`${langKey}.punch_type`) },
  ],
  employment_id: [
    { required: true, message: trans(`${langKey}.employment_id`) },
  ],
};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}

