const rosterKey = "roster";
const commonKey = 'common';
const attendanceKey = 'attendanceManagement'
const baseRoute = "/eoemanagement/endofemployment";

export const Const = {

  reason: {
    lngKey: `${rosterKey}.reason`,
    route: `/eoemanagement/settings/reasons`,
  },
  timeslot: {
    lngKey: `${rosterKey}.timeslot`,
    route: ``,
  },
  holiday: {
    lngKey: `${rosterKey}.holiday`,
    route: ``,
  },
  restday: {
    lngKey: `${rosterKey}.restday`,
    route: ``,
  },
  myRoaster: {
    lngKey: `${rosterKey}.myRoaster`,
    route: ``,
  },
  companyRoaster: {
    lngKey: `${rosterKey}.companyRoaster`,
    route: ``,
  },
  viewFlow: {
    lngKey: `${commonKey}.viewFlow`,
    route: ``,
  },
  consents: {
    lngKey: `${commonKey}.consents`,
    route: ``,
  },
  approvalHistory: {
    lngKey: `${commonKey}.approvalHistory`,
    route: ``,
  },
  requestHistory: {
    lngKey: `${commonKey}.requestHistory`,
    route: ``,
  },
  myAttendance: {
    lngKey: `${attendanceKey}.myAttendance`,
    route: ``,
  },
  policySlab: {
    lngKey: `policyManagement.policySlab`,
    route: ``,
  },
  delegates: {
    lngKey: `${commonKey}.delegates`,
    route: ``,
  },
  import: {
    lngKey: `${commonKey}.import`,
    route: ``,
  }

};

const cmn = "common";
export const Commons = {
  create: `${cmn}.create`,
  update: `${cmn}.update`,
  view: `${cmn}.view`,
  name: `${cmn}.name`,
  code: `${cmn}.code`,
  location: `${cmn}.location`,
  branch: `${cmn}.branch`,
  department: `${cmn}.department`,
  designation: `${cmn}.designation`,
  joiningDate: `${cmn}.joiningDate`,
  grade: `${cmn}.grade`,
  ProfileCompletion: `${cmn}.ProfileCompletion`,
  actions: `${cmn}.actions`,
  is_active: `${cmn}.is_active`,
  status: `${cmn}.status`,
  description: `${cmn}.description`,
  shortname: `${cmn}.shortname`,
  country: `${cmn}.country`,
};

export const colors = {
  "bg-warning": "#faad14",
  "bg-warning-color": "white",
  "bg-success": "green",
  "bg-success-color": "white",
  "bg-darkgray": "#333",
  "bg-danger": "#d9534f",
  "bg-error": "#a02622",
  "bg-orange": "#ffc100",
  "bg-day-off": "#36c2aa",
  "bg-default-time-slot": "#5368a2",
  "bg-tr-black": "#B2B2B2",
  "bg-informed-late": "#FF5722",
  "bg-greensea": "#107863",
  "bg-info": "#f5791a",
  "bg-f5": "#f5f5f5",
}