import React, { useEffect, useState } from "react";
import { Button, Form, message, Row, Col } from "antd";
import { SaveButton, InputText, CancelButton, ModalComponent, trans, InputDate, InputTextArea } from "@comps/components"
import { makeRequest, getErrorProps } from "@utils/helpers";
import { createDataReq } from "../requests";
import { Commons, Const } from "../../../constants";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
const langKey = Const["taxStructure"].lngKey
const formName = "createTaxStrutcture";

const CreateTaxStrutcture = (props) => {
  const [loader, setLoader] = useState(false);
  const [saving, setsaving] = useState(false);
  const [errors, setErrors] = useState([]);
  const [slabs, setSlabs] = useState([])
  const [deletedRows, setDeletedRows] = useState([])
  useEffect(() => {
    // eslint-disable-next-line
  }, []);


  const setRows = (key, state) => {
    //const rows = form.getFieldValue('repayments')
    if (slabs[key] !== undefined && slabs[key].id !== undefined && state === 'Deleted') {
      slabs[key].row_state = 'Deleted'
      deletedRows.push(slabs[key])
    }
    setDeletedRows(deletedRows)
  }
  const onSubmit = (data) => {

    const payload = {
      tax_structure:
      {
        name: data.name,
        fiscal_start: data.fiscal_start.format('YYYY-MM-DD'),
        fiscal_end: data.fiscal_end.format('YYYY-MM-DD'),
        description: data.description,
        company_id: 1,
        slabs: data.slabs
      }

    };
    makeRequest(setLoader, createDataReq, payload, onSuccess, onError);
  }

  const onSuccess = (data, res) => {
    message.success("Saved successfully");
    props.onCompleted(data.tax_structures.data);
    props.onSuccess(data)
  }

  const onError = (err, res) => {
    setsaving(false)
    message.error(res.response.data.message);
  }

  //-----------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [
    <SaveButton loading={ saving } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } width={ 1000 } maskClosable={ false }
      mainTitle={ trans(Commons.create) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Form { ...formLayout } name={ formName } onFinish={ onSubmit } scrollToFirstError>
        <Row gutter={ 30 }>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="name" rules={ rules.name } label={ trans(Commons.name) }
              className="da-mb-16"
              { ...getErrorProps(errors['name']) } size="small"
            >
              <InputText placeholder={ trans(`${langKey}.name`) } />
            </Form.Item>
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="fiscal_start" rules={ rules.fiscal_start } label={ trans(`${langKey}.fiscal_start`) }
              className="da-mb-16"
              { ...getErrorProps(errors['fiscal_start']) } size="small"
            >
              <InputDate />
            </Form.Item>
          </Col>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="fiscal_end" rules={ rules.fiscal_end } label={ trans(`${langKey}.fiscal_end`) }
              className="da-mb-16"
              { ...getErrorProps(errors['fiscal_end']) } size="small"
            >
              <InputDate />
            </Form.Item>
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="description" rules={ rules.description } label={ trans(Commons.description) }
              className="da-mb-16"
              { ...getErrorProps(errors['description']) } size="small"
            >
              <InputTextArea placeholder={ trans(`${langKey}.description`) } />
            </Form.Item>
          </Col>
        </Row>

        <div className="formlist-container">
          <h5 className="formlist-title">{ trans(`${langKey}.slabs`) }</h5>
          <Form.List name="slabs" >
            { (fields, { add, remove }) => (
              <>
                <Button onClick={ () => {
                  add()
                  setRows(fields.length, 'Added')
                } } icon={ <PlusOutlined /> } type="primary" className="add-node-button">Add </Button>
                <div className="table-container">
                  <table className="table">
                    {
                      fields.length !== 0 && <thead>
                        <tr>
                          <th style={ { paddingLeft: 20 } }>{ trans(`${langKey}.min`) }</th>
                          <th>{ trans(`${langKey}.max`) }</th>
                          <th>{ trans(`${langKey}.fixed_amount`) }</th>
                          <th>{ trans(`${langKey}.percentage`) }</th>
                          <th>{ trans(Commons.actions) }</th>
                        </tr>
                      </thead>
                    }
                    <tbody>
                      { fields.map(({ key, name, ...restField }) => (
                        <>
                          <tr>

                            <td style={ { minWidth: '200px', paddingLeft: 20 } }>
                              <Form.Item
                                { ...restField }
                                name={ [name, 'min'] }
                                rules={ rules.min }
                              >
                                <InputText placeholder={ trans(`${langKey}.min`) } type="number" />
                              </Form.Item>
                            </td>
                            <td style={ { minWidth: '200px' } }>
                              <Form.Item
                                { ...restField }
                                name={ [name, 'max'] }
                                rules={ rules.max }
                              >
                                <InputText placeholder={ trans(`${langKey}.max`) } type="number" />
                              </Form.Item>
                            </td>
                            <td style={ { minWidth: '200px' } }>
                              <Form.Item
                                { ...restField }
                                name={ [name, 'fixed_amount'] }
                                rules={ rules.fixed_amount }
                              >
                                <InputText placeholder={ trans(`${langKey}.fixed_amount`) } type="number" />
                              </Form.Item>
                            </td>
                            <td style={ { minWidth: '200px' } }>
                              <Form.Item
                                { ...restField }
                                name={ [name, 'percentage'] }
                                rules={ rules.percentage }
                              >
                                <InputText placeholder={ trans(`${langKey}.percentage`) } type="number" />
                              </Form.Item>
                            </td>
                            <td>
                              <DeleteOutlined className="formlist-remove" onClick={ () => {
                                remove(name)
                                setRows(key, 'Deleted')
                              } } />
                            </td>
                          </tr>
                        </>
                      )) }

                    </tbody>
                  </table>
                </div>
              </>
            ) }
          </Form.List>
        </div>
      </Form>
    </ModalComponent>
  )
}

export default CreateTaxStrutcture

const rules = {
  name: [
    { required: true, message: trans(`${langKey}.name`) },
  ],
  fiscal_start: [
    { required: true, message: trans(`${langKey}.fiscal_start`) },
  ],
  fiscal_end: [
    { required: true, message: trans(`${langKey}.fiscal_end`) },
  ],
  min: [
    { required: true, message: trans(`${langKey}.min`) },
  ],
  max: [
    { required: true, message: trans(`${langKey}.max`) },
  ],
  fixed_amount: [
    { required: true, message: trans(`${langKey}.fixed_amount`) },
  ],
  percentage: [
    { required: true, message: trans(`${langKey}.percentage`) },
  ],

};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}