import { get, del, post, put } from "@utils/axios";

const api = "probationApprovals";

export const getAllDataReq = (payload) => {
  return post(`${api}?page=${payload.page}&filters=${payload.filters}`, payload.data)
};

export const createDataReq = (payload) => {
  return post(`${api}?`, payload);
};

export const updateDataReq = (payload) => {
  return put(`${api}/${payload.id}`, payload);
};

export const deleteDataReq = (id) => {
  return del(`${api}/${id}`);
};
export const getRelatedDatareq = (id) => {
  return get(`${api}/relatedData`);
};
export const getEmploymentExcludeId = (id) => {
  return get(`resources/employment?page=1&filters=exclude_ids_|${id}`);
};

export const getEmploymentsReq = (payload) => {
  return get(`resources/employment?page=1&filters=privilege_|false;`);
};
export const createConsentReq = (payload) => {
  return post(`${api}/createConsent`, payload);
};
export const createMessageConsentReq = (payload) => {
  return post(`${api}/messageConsent`, payload);
};
export const approveRequest = (payload) => {
  return post(`${api}/approveRequest`, payload);
}
export const getFilterReq = (payload) => {
  return get(`filters?company_id=1&fields=page=1&filters=supporting_fields_|employments,request_status;organization_structure_fields_|location,branch,department;preset_|probation_approval;`)
}