import React, { useEffect, useState } from "react";
import { Form, message, Row, Col } from "antd";
import { SaveButton, InputText, CancelButton, ModalComponent, trans } from "@comps/components"
import { makeRequest } from "@utils/helpers";
import { updateDataReq } from "../requests";
import { Commons, Const } from "../../../constants";

const langKey = Const["codes"].lngKey
const formName = "createCode";

const UpdateCode = (props) => {
  const [loader, setLoader] = useState(false);
  const [saving, setSaving] = useState(false)
  const [form] = Form.useForm()
  useEffect(() => {
    setFormData()
  }, []);

  const setFormData = () => {
    if (!props.record) {
      return
    }
    form.setFieldsValue(props.record);
  }
  const onSubmit = (data) => {
    data.id = props.record.id;
    const payload = { code: data };
    payload.id = data.id;
    makeRequest(setLoader, updateDataReq, payload, onSuccess, onError);

    setSaving(true)
  }

  const onSuccess = (data, res) => {
    setSaving(false)
    message.success("Record Updated Successfully");
    props.onCompleted(data.codes.data);
  }

  const onError = (err, res) => {
    setSaving(false)
    message.error(res.response.data.message);
  }

  //-----------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [
    <SaveButton loading={ saving } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } width={ 1000 } maskClosable={ false }
      mainTitle={ trans(Commons[props.disabled ? 'view' : 'update']) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Form { ...formLayout } name={ formName } onFinish={ onSubmit } form={ form }>
        <Row gutter={ 30 }>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="name" rules={ rules.name } label={ trans(Commons.name) }
              className="da-mb-16"
              size="small"
            >
              <InputText placeholder="Name" />
            </Form.Item>
          </Col>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="value" rules={ rules.value } label={ trans(`${langKey}.value`) }
              className="da-mb-16"
              size="small"
            >
              <InputText type="number" />
            </Form.Item>
          </Col>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="count" rules={ rules.count } label={ trans(`${langKey}.count`) }
              className="da-mb-16"
              size="small"
            >
              <InputText type="number" />
            </Form.Item>
          </Col>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="padding" rules={ rules.padding } label={ trans(`${langKey}.padding`) }
              className="da-mb-16"
              size="small"
            >
              <InputText type="number" />
            </Form.Item>
          </Col>
        </Row>

      </Form>
    </ModalComponent>
  )
}

export default UpdateCode

const rules = {
  name: [
    { required: true, message: trans(`${langKey}.name`) },
  ],
  value: [
    { required: true, message: trans(`${langKey}.value`) },
  ],
  count: [
    { required: true, message: trans(`${langKey}.count`) },
  ],
  padding: [
    { required: true, message: trans(`${langKey}.padding`) },
  ],

};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}