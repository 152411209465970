import React, { useEffect, useState } from 'react';
import './ChatBot.css'; // Import your CSS file for styling
import { makeRequest } from "@utils/helpers";
import { Popconfirm, message, Progress, Spin } from 'antd';
import { getReportsFromJobsReq, downloadReportFromJobsReq } from '../../modules/reports/allReports/requests';
import { CloseOutlined, Download } from '@mui/icons-material';
import { handleDownloadFile } from "@utils/helpers";
import { decryptLocalData, pusherInitializer } from '@comps/commonFunctions';
import { useSelector } from 'react-redux';
import { cancelPayrollJobReq, getBatchProgressReq } from '../../modules/common/requests';
import { PlusOutlined, MinusOutlined, LoadingOutlined } from '@ant-design/icons';
import { convertTimeTotz } from '../commonFunctions';

var selectedNotification = null;
var myInterval = null;
const ChatBot = (props) => {
  const authUser = useSelector((state) => state.auth.authUser);
  const [isOpen, setIsOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [dataSource, setDataSource] = useState([])
  const [jobBatches, setJobBatches] = useState([]);
  const [jobProgress, setJobProgress] = useState(false);
  //==========================Start Pusher=================================
  useEffect(() => {
    const pusher = pusherInitializer();
    const channel = pusher.subscribe(decryptLocalData('user').tenant);
    channel.bind('report-downloaded-event', function (res) {
      getJobStatus()
      setIsOpen(true)
    });
    // Cleanup function for unsubscribing and unbinding when the component is unmounted
    return () => {
      // Unbind the event handler
      channel.unbind('report-downloaded-event');
      // Unsubscribe from the channel
      pusher.unsubscribe(decryptLocalData('user').tenant);
      // Disconnect from Pusher
      pusher.disconnect();
    };
    // eslint-disable-next-line
  });


  useEffect(() => {
    const pusher = pusherInitializer();
    const channel = pusher.subscribe(decryptLocalData('user').tenant);
    channel.bind('batch-progress-event', function (res) {
      getJobStatus();
      setIsOpen(true)
    });
    // Cleanup function for unsubscribing and unbinding when the component is unmounted
    return () => {
      // Unbind the event handler
      channel.unbind('batch-progress-event');
      // Unsubscribe from the channel
      pusher.unsubscribe(decryptLocalData('user').tenant);
      // Disconnect from Pusher
      pusher.disconnect();
    };
    // eslint-disable-next-line
  });

  //==========================end Pusher =================================

  useEffect(() => {

    getJobStatus();

    // Set a timeout to call the function after 5 seconds
    const timer = setInterval(() => {
      if (jobProgress) {
        getJobStatus();
      }
      // alert("hello")
    }, 5000);

    // Cleanup the timeout if the component is unmounted
    return () => clearInterval(timer);
  }, [jobProgress,isOpen]);



  const getJobStatus = () => {
    makeRequest(setLoader, getReportsFromJobsReq, null, onSuccessReports, onErrorReports);
  }

  const onSuccessReports = (data, res) => {
    setJobProgress(data.job_in_progress)
    setDataSource(data.jobs)
  }

  const onErrorReports = (err) => {
    console.log(err);
  }

  const onView = (item) => {
    selectedNotification = item
    viewNotification(item)
  }
  const viewNotification = (item) => {
    makeRequest(setLoader, downloadReportFromJobsReq, item.id, onSuccessView, onError);
  }
  const onSuccessView = (err, res) => {
    if (res.code === undefined) {
      // Extract file extension
      const fileExtension = res.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ? 'xlsx' : 'pdf'
      const fileName = `${selectedNotification.title}.${fileExtension}`
      handleDownloadFile(fileName, res)
    }
    else {
      message.error(res.message)
    }
  }

  const onError = (err, res) => {
    if (res.code === undefined) {
      // Extract file extension
      const fileExtension = res.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ? 'xlsx' : 'pdf'
      const fileName = `${selectedNotification.title}.${fileExtension}`
      handleDownloadFile(fileName, res)
    }
    else {
      message.error(res.message)
    }
  }

  const getReportStatus = (item) => {

    if (item.is_downloaded) {
      return <Download className="text-success" style={ { cursor: "pointer" } } onClick={ () => onView(item) } />
    }
    else {
      if(item.progress===100){
return <Spin indicator={<LoadingOutlined spin />} />
      }
      return <Progress type="circle" percent={ item.progress } size={ 30 } />
    }
  }

  const getReportName = (each) => {
    return <div style={ {
      maxWidth: '200px',
      overflow: 'hidden',
    } }>
      <span>{ each.title ? each.title : each.name }</span><br />
      <small><i>{ convertTimeTotz(each.created_at).format('YYYY-MM-DD hh:mm a') }</i></small>
    </div>
  }

  const batchAction = (each) => {
    if (each.options.data.class === "App\\Jobs\\PayrollManagement\\RunPayroll") {
      return <Popconfirm title="Are you sure to cancel this task?" onConfirm={ () => onCancelBatch(each) } >
        <CloseOutlined className='text-danger' />
      </Popconfirm>
    }
  }

  const onCancelBatch = (each) => {
    const payrollId = each.options.data.param.payroll_id;
    makeRequest(setLoader, cancelPayrollJobReq, payrollId, onSuccessCancelBatch, null);
  }
  const onSuccessCancelBatch = (data, res) => {
    // getJobBatches();
  }
  const reportAction = (each) => {
    //return 'Cancel'
  }

  const toggleChatBot = (action) => {
    setIsOpen(action);
  };
  return (
    <>
      { !isOpen && (dataSource.length > 0 || jobBatches.length > 0) &&

        <div className='chat-bot-container' onClick={ () => toggleChatBot(true) } style={ {
          cursor: 'pointer',
          bottom: '0px',
          right: '50px',
          background: '#4079FC',
          borderBottomLeftRadius: '0',
          borderBottomRightRadius: '0',

        } }>
          <div style={ {
            height: '45px',
            display: 'flex',
            justifyContent: 'space-between',
            paddingTop: '5px',
          } }>
            <div className='h6 mt-2' style={ {
              color: 'white',
              paddingLeft: '10px',
            } }>
              <span className='text-white bg-danger badge' onClick={ () => toggleChatBot(true) }>
                { dataSource.length + jobBatches.length }
              </span> Notifications
            </div>
            <div style={ {
              marginRight: '15px',
              paddingTop: '8px',
            } }>
              <PlusOutlined onClick={ () => toggleChatBot(false) } style={ { cursor: 'pointer', color: 'white' } } />
            </div>
          </div>
        </div>
      }
      { isOpen && (dataSource.length > 0 || jobBatches.length > 0) &&
        <div className={ `chat-bot-container ${isOpen ? 'open' : ''}` } style={ {
          borderBottomLeftRadius: '0',
          borderBottomRightRadius: '0',
          borderBottomWidth: '0',
          right: '50px',
        } }>

          { isOpen &&

            <div className=''>
              <div onClick={ () => toggleChatBot(false) } style={ {
                cursor: 'pointer',
                background: '#4079FC',
                borderBottomLeftRadius: '0',
                borderBottomRightRadius: '0',

              } }>
                <div style={ {
                  height: '45px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  paddingTop: '5px',
                } }>
                  <div className='h6 mt-2' style={ {
                    color: 'white',
                    paddingLeft: '10px',
                  } }>
                    <span className='text-white bg-danger badge' onClick={ () => toggleChatBot(true) }>
                      { dataSource.length + jobBatches.length }
                    </span> Notifications
                  </div>
                  <div style={ {
                    marginRight: '15px',
                    paddingTop: '8px',
                  } }>
                    <MinusOutlined onClick={ () => toggleChatBot(false) } style={ { cursor: 'pointer', color: 'white' } } />
                  </div>
                </div>
              </div>
              <div style={ {
                border: '1px solid #4079FC',
                maxHeight: '300px',
                overflowY: 'auto',
              } }>
                <table className='table'>
                  <thead className='w-100'>
                    <tr>
                      <th>Title</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>

                    { isOpen && dataSource.length > 0 && dataSource.map(each => (

                      <tr>

                        <td>{ getReportName(each) }</td>
                        <td>{ getReportStatus(each) } { reportAction(each) }</td>

                      </tr>
                    )) }
                  </tbody>
                </table>
              </div>

            </div>
          }
        </div>
      }
    </>
  );
};

export default ChatBot;
