import React, { useEffect, useState } from "react";
import { Form, message, Row, Col ,Steps, Space,Button, Skeleton} from "antd";
import {InputText, InputDate, SelectWithSearch, ModalComponent, trans ,InputCheck} from "@comps/components"
import { makeRequest,makeRequestStateless } from "@utils/helpers";
import { createDataReq } from "../requests";
import { Commons, Const } from "../../../constants";
import { createSelectList } from '@comps/commonFunctions';
import FilterSelectEmployees from "@mods/common/FilterSelectEmployees";
import { getCompanyRelatedData } from "../../../../common/requests";
import { ShowErrorModal } from "@comps/components";
const langKey = Const["gratuity"].lngKey
const formName = "creategratuity";

const CreateGratuity = (props) => {
  const [loader, setLoader] = useState(false);
  const [saving, setsaving] = useState(false);
  const [form] = Form.useForm()
  const [formulas,setFormulas]=useState([]);
  const [afterProbation,setAfterProbation]=useState(false)
  const [forAllEmp,setForAllEmp]=useState(false)

  const [type,setType]=useState('before_revised_salary');
  const [companyRelatedData, setCompanyRelatedData] = useState([]);
  const [selectedEmployments, setEmployments] = useState([]);
  const [preEmployeesFilter, setPreEmployeesFilter] = useState({})
  const [unSelectedEmployees, setUnSelectedEmployees] = useState([])
  const [selectedEmployees, setSelectedEmployees] = useState([])
  const [currentStep, setCurrent] = useState(0);
  const [gratuityData,setGratuityData]=useState({});
  const [payitems,setPayitems]=useState([]);
  const [childComponent, setChildComponent] = useState(null)
  const types=[
    {
      label:"Fixed Amount",
      value:"fixed"
    },
    // {
    //   label:"Formula",
    //   value:"formula"
    // },
    {
      label:"Before revised Salary",
      value:"before_revised_salary"
    },
  ]
  useEffect(() => {
    setInitialData();
    // eslint-disable-next-line
  }, []);

const setInitialData=()=>{

  setFormulas(createSelectList(props.allData?props.allData.formulas:[]));
  setPayitems(createSelectList(props.allData?props.allData.payitems:[]));
  makeRequestStateless(getCompanyRelatedData, null, ongetCompanyRelatedDataReq, null);
}
const ongetCompanyRelatedDataReq = (response) => {
  setCompanyRelatedData(response)
}

  const onSave = (gratuityData,empIds) => {
    
    const payload = {
      gratuity: gratuityData,
      employment_ids:empIds
    };

    makeRequest(setLoader, createDataReq, payload, onSuccess, onError);
    setsaving(true)
  }

  const onSuccess = (data, res) => {
    
    if (res.message && res.message.length > 0) {
      setChildComponent(<ShowErrorModal errors={ res.message } module={ "Gratuity Creation" } onCompleted={ () => setChildComponent(null) } />)
      return;
    }
    setsaving(false)
    props.onCompleted(data)
  }

  const onError = (err, res) => {
    setsaving(false)
    message.error(res.response.data.message);
  }


  //=====================On Submit Second Step===========================
  const setSelectedEmploymentsIds = (employments) => {
    setEmployments(employments.employment_ids)
    let empIds = employments.employment_ids;
onSave(gratuityData,empIds);
  }
  const onCancel = () => {
    props.onCompleted(false);
  }
  const onBack = () => {
    setCurrent(currentStep - 1)
  }
const onSubmitGratuityStep=(data)=>{
  if(data && data.for_all_employees){
    onSave(data,[]);
   return
  }
  if(data){
    setGratuityData(data);
    setCurrent(currentStep+1);
  }
}

  const gratuityConfigForm=()=>{
return (
  <>
    <Form { ...formLayout } name={ formName } onFinish={ onSubmitGratuityStep } form={ form } initialValues={gratuityData}>
        <Row gutter={ 30 }>
        <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="name" rules={ rules.name } label={ trans(`${langKey}.name`) }
              className="da-mb-16"
            >
              <InputText  placeholder={ trans(`${langKey}.name`) } />
            </Form.Item>
          </Col>
        <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="payitem_id" rules={ rules.payitem_id } label={ trans(`${langKey}.payitem_id`) }
              className="da-mb-16"
              initialValue={8}
            >
              <SelectWithSearch options={payitems} placeholder={ trans(`${langKey}.payitem_id`) } disabled />
            </Form.Item>
          </Col>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="start_date" rules={ rules.start_date } label={ trans(`${langKey}.start_date`) }
              className="da-mb-16"
            >
              <InputDate />
            </Form.Item>
          </Col>
          {!afterProbation && <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="calc_month_after_doj" rules={ rules.month_of_calculation } label={ trans(`${langKey}.month_of_calculation`) }
              className="da-mb-16"
            >
              <InputText type="number" placeholder={ trans(`${langKey}.month_of_calculation`) } />
            </Form.Item>
          </Col>}
        
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="type" rules={ rules.type } label={ trans(`${langKey}.type`) }
              className="da-mb-16"
              initialValue={type}
            >
              <SelectWithSearch  options={types} placeholder={ trans(`${langKey}.type`) } onChange={setType} />
            </Form.Item>
          </Col>
          {
            type==='formula' &&    <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="formula_id" rules={ rules.formula_id } label={ trans(`${langKey}.formula_id`) }
              className="da-mb-16"
            >
              <SelectWithSearch options={formulas} type="number" placeholder={ trans(`${langKey}.formula_id`) } />
            </Form.Item>
          </Col>
          }
          {
          type==='fixed' &&  <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="fixed_amount" rules={ rules.fixed_amount } label={ trans(`${langKey}.fixed_amount`) }
              className="da-mb-16"
            >
              <InputText type="number" placeholder={ trans(`${langKey}.fixed_amount`) } />
            </Form.Item>
          </Col>
          }
     
        <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="disburse_month_after_doj" rules={ rules.month_of_reimbursement } label={ trans(`${langKey}.month_of_reimbursement`) }
              className="da-mb-16"
            >
              <InputText type="number" placeholder={ trans(`${langKey}.month_of_reimbursement`) } />
            </Form.Item>
          </Col>
        
{/* 
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="description" rules={ rules.description } label={ trans(`${langKey}.description`) }
              className="da-mb-16"
            >
              <InputTextArea placeholder={ trans(`${langKey}.description`) } />
            </Form.Item>
          </Col> */}
           {/* <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="after_probation" rules={ rules.after_probation } label={ trans(`${langKey}.after_probation`) }
              className="da-mb-16"
              valuePropName="checked"
            >
              <InputCheck checked={afterProbation} onChange={(e)=>setAfterProbation(e.target.checked)}/>
            </Form.Item>
          </Col> */}
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="for_all_employees" rules={ rules.for_all_employees } label={ trans(`${langKey}.for_all_employees`) }
              className="da-mb-16"
              valuePropName="checked"
            >
              <InputCheck checked={forAllEmp} onChange={(e)=>setForAllEmp(e.target.checked)}/>
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end">
          <Space>
            <Button className="button-next" type="primary" htmlType="submit">
              {forAllEmp ?"Save":"Next"}
            </Button>
          </Space>
        </Row>
      </Form>
  </>
)
  }

  
  return (

    <ModalComponent top={ 20 } width={ 1400 } maskClosable={ false }
      mainTitle={ trans(Commons.create) } subTitle={ <>
        <p style={ { paddingLeft: 75, marginTop: -30 } }>{ trans(`${langKey}.moduleName`) }</p>
        <Steps
          className="steps-style"
          current={ currentStep }
          onChange={ setCurrent }
        >
          <Steps.Step className="steps-step1" style={ { paddingLeft: 50 } } />
          <Steps.Step className="steps-step2"  />
       
        </Steps>
      </> }
     visible={ true } onCancel={ () => props.onCompleted(false) } footer={ '' }>
      {childComponent}
      <Skeleton loading={loader}>

      { currentStep === 0 ?
       gratuityConfigForm()
        : currentStep === 1 ?
          <FilterSelectEmployees setSelectedEmploymentsIds={ setSelectedEmploymentsIds } initialValues={ preEmployeesFilter } companyRelatedData={ companyRelatedData } onCancel={ onCancel } onBack={ onBack }
            employment_ids={ selectedEmployments }
            moduleName='companyRoaster' selected={ selectedEmployees } unSelected={ unSelectedEmployees } preFilters={ preEmployeesFilter } setSelected={ setSelectedEmployees } setUnselected={ setUnSelectedEmployees } setPrefilters={ setPreEmployeesFilter } saveButton={true}
          />
          : '' }
      </Skeleton>
      {/* {
        currentStep === 1 ?
          <Row justify="end">
            <Space>
              <Button className="button-back" onClick={ () => setCurrent(currentStep - 1) } disabled={ currentStep > 0 ? false : true }>
                <span style={ { color: "#4079FC" } }>Back</span>
              </Button>
              <SaveButton className="button-next"  form={ formName } key="create_button" onClick={ () => onSubmit() }  />
            </Space>
          </Row>
          : ''
      } */}
    </ModalComponent>
  )
}

export default CreateGratuity

const rules = {
  month_of_calculation: [
    { required: true, message: trans(`${langKey}.month_of_calculation`) },
  ],
  month_of_reimbursement: [
    { required: true, message: trans(`${langKey}.month_of_reimbursement`) },
  ],
  formula_id: [
    { required: true, message: trans(`${langKey}.formula_id`) },
  ],
  fixed_amount: [
    { required: true, message: trans(`${langKey}.fixed_amount`) },
  ],
  payitem_id: [
    { required: true, message: trans(`${langKey}.payitem_id`) },
  ],
  start_date: [
    { required: true, message: trans(`${langKey}.start_date`) },
  ],
  name: [
    { required: true, message: trans(`${langKey}.name`) },
  ],
  // description: [
  //   { required: true, message: trans(`${langKey}.description`) },
  // ],
};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}