import React from "react";
import { Route, Routes } from "react-router-dom";
import IndexEmployees from "./companyEmployees/IndexEmployees";
// import CreateNewEmployee from "./companyEmployees/components/CreateNewEmployee";

import IndexAddresses from "./addresses/IndexAddresses";
import IndexContacts from "./contacts/IndexContacts";
import IndexCertification from "./certification/IndexCertification";
import IndexEducations from "./educations/IndexEducations";
import IndexExperience from './experiences/IndexExperience';
import IndexRelatives from "./relatives/IndexRelatives";
import IndexBanks from "./bank/IndexBanks";
import IndexAssets from "./assets/IndexAssets";
import IndexSalaries from "./salleries/IndexSalaries";
import AddEmployee from "./companyEmployees/components/AddEmployee";
import { useSelector } from "react-redux";



const EmployeeModules = () => {
      const authUser = useSelector((state) => state.auth.authUser);
      return (
            <Routes>
                  <Route path="company" element={ <IndexEmployees module="employees" filters='' modkey="employeeIndex"/> } />
                  <Route path="/employees" element={ <IndexEmployees module="employees" filters='' modkey="employeeIndex"/> } />
                  <Route path="subordinates" element={ <IndexEmployees module="subordinates" filters={ `subordinate_|${authUser.employment_id}` } modkey="subordinateEmployees"/> } />
                  <Route path="employments/index" element={ <IndexEmployees module="employees" filters='' modkey="employeeIndex"/> } />
                  {/* <Route path="create" element={ <CreateNewEmployee /> } /> */}

                  <Route path="addresses" element={ <IndexAddresses modkey="employeeAddress"/> } />
                  <Route path="contacts" element={ <IndexContacts modkey="employeeContact"/> } />
                  <Route path="certifications" element={ <IndexCertification modkey="employeeCertification"/> } />
                  <Route path="educations" element={ <IndexEducations modkey="employeeEducation"/> } />
                  <Route path="experiences" element={ <IndexExperience modkey="employeeExperience"/> } />
                  <Route path="relatives" element={ <IndexRelatives modkey="employeeRelative"/> } />
                  <Route path="banks" element={ <IndexBanks modkey="employeeBank"/> } />
                  <Route path="employments/assets" element={ <IndexAssets modkey="employmentAsset"/> } />
                  <Route path="employments/salaries" element={ <IndexSalaries modkey="employmentSalary"/> } />
                  <Route path="add-employee" element={ <AddEmployee /> } />
            </Routes>
      );
}

export default EmployeeModules;
