import React, { useEffect, useState } from "react";
import { Button, Form, Row, Space, message, Col ,Spin,Popconfirm} from "antd";
import { trans, SelectWithSearch, SelectResourceList,PaginationComponent } from "@comps/components"
import { createSelectList, createDepartmentListWithCode } from "@comps/commonFunctions";
import { makeRequestStateless } from "@utils/helpers";
import { filterEmploymentsReq } from "./requests";
import { ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons";

import { Const } from "./constants";

const langKey = Const["restday"].lngKey
const formName = "createRoaster";

const FilterSelectEmployees = (props) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState({
    "total": 0,
    "count": 0,
    "per_page": 0,
    "current_page": 0,
    "total_pages": 0,
    "links": {}
  });

  const [locations, setlocations] = useState([]);
  const [branches, setbranches] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [grades, setgrades] = useState([]);
  const [designations, setdesignations] = useState([]);
  const [relatedData, setRelatedData] = useState([])
  const [locationBranches, setlocationBranches] = useState([]);
  const [initialSearchableList, setinitialSearchableList] = useState(props.initialEmployeesCriteria ? props.initialEmployeesCriteria : {});
  const [isSearching, setIsSearching] = useState(false);
  const [filteredEmployees, setFilteredEmployees] = useState(props.previewEmployment ? createSelectList(props.previewEmployment) : []);
  const [selectedEmpForm] = Form.useForm();
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [form] = Form.useForm()
  const [filterForm] = Form.useForm()
  const [isRest, setIsRest] = useState(false)
  const [initialFilters, setInitialFilters] = useState(props.preFilters)
  const [loader,setLoader]=useState(false);

  const moveEmployeeToSelected = (employeeIndex) => {
    const employeeToMove = filteredEmployees[employeeIndex];
    setFilteredEmployees((prevEmployees) => {
      const updatedEmployees = [...prevEmployees];
      updatedEmployees.splice(employeeIndex, 1);
      return updatedEmployees;
    });
    setSelectedEmployees((prevSelectedEmployees) => [...prevSelectedEmployees, employeeToMove]);
  };

  const moveEmployeeToUnSelected = (employeeIndex) => {
    const employeeToMove = selectedEmployees[employeeIndex];
    setSelectedEmployees((prevEmployees) => {
      const updatedEmployees = [...prevEmployees];
      updatedEmployees.splice(employeeIndex, 1);
      return updatedEmployees;
    });
    setFilteredEmployees((prevfilteredEmployees) => [...prevfilteredEmployees, employeeToMove]);
  };

  const moveAllToSelected = () => {
    setSelectedEmployees((prevSelectedEmployees) => [
      ...prevSelectedEmployees,
      ...filteredEmployees,
    ]);
    setFilteredEmployees([]);
    setPagination(null);
  };

  const moveAllToUnSelected = () => {
    setFilteredEmployees((prevfilteredEmployees) => [
      ...prevfilteredEmployees,
      ...selectedEmployees,
    ]);
    setSelectedEmployees([]);
  };



  useEffect(() => {
    ongetRelatedData(props.companyRelatedData)
    setData();
    // eslint-disable-next-line
  }, [props]);

  useEffect(() => {
    //onApplyFilter(props.preFilters);
    // eslint-disable-next-line
  }, [currentPage]);
  const setData = () => {
    selectedEmpForm.setFieldsValue(
      {
        employment_ids: props.employment_ids
      }
    )
    setFilteredEmployees(props.unSelected)
    setSelectedEmployees(selectedEmployees.length>0?selectedEmployees: props.selected)
  }

  const ongetRelatedData = (response) => {
    if (response) {
      setRelatedData(response);
      const locationsArray = [];
      for (const key in response.locations) {
        locationsArray.push(response.locations[key]);
      }
      setlocationBranches(response.locations)
      setlocations(createSelectList(locationsArray))
      setdesignations(createSelectList(response.designations));
      setgrades(createSelectList(response.grades));
      setDepartments(createDepartmentListWithCode(response.departments));
      const location_id = initialSearchableList ? initialSearchableList.location_id : null;
      //default branch
      for (const key in response.locations) {
        if (response.locations[key].id === location_id) {
          setbranches(createSelectList(response.locations[key].branches));
        }
      }
    }
  }

  const onChangeLocation = (id) => {

    if (id) {
      for (const key in locationBranches) {
        if (locationBranches[key].id === id) {
          setbranches(createSelectList(locationBranches[key].branches));
        }
      }
    }
    else {
      setbranches([])
    }
  }

  const onApplyFilter = (data) => {
    props.setPrefilters(data)
    setIsSearching(true)
    setinitialSearchableList(data);
    let filters = `privilege_|false;department_h_code_|${data.department_id};employment_id_|${data.employment_id};location_id_|${data.location_id};branch_id_|${data.branch_id};designation_id_|${data.designation_id};grade_id_|${data.grade_id}`;
    let payload={page:currentPage,filters:filters};
    makeRequestStateless(filterEmploymentsReq, payload, onfilteredEmployment, null);
  }

  const onfilteredEmployment = (response) => {
    setIsSearching(false)
    if (response.employee_employments.data.length === 0) {
      message.info('Employees Not Found');
      //setSelectedEmployees([])
      setFilteredEmployees([])
      return
    }
    message.success('Retrieved Successfully');

    setFilteredEmployees(response.employee_employments.data)
    setPagination(response.employee_employments.meta.pagination)
    //setSelectedEmployees([])
  }

  const onSubmitEmployments = () => {
    if (selectedEmployees.length === 0) {
      message.error('Please select at least one employee.');
      return;
    }
    let empIds = []
    let selectedEmpData = [];
    selectedEmployees.forEach(each => {
      empIds.push(each.id);
      selectedEmpData.push(each);
    })
    const payload = {
      employment_ids: empIds
    }
    setSelectedEmployees(selectedEmpData);

    props.setSelectedEmploymentsIds(payload)
    props.setUnselected(filteredEmployees)
    props.setSelected(selectedEmployees)
setLoader(true);
  }

  const onResetFilter = () => {
    filterForm.resetFields()
    filterForm.setFieldsValue({})
    filterForm.setFieldValue('employment_id',null)
    //setFilteredEmployees([])
    //setSelectedEmployees([])
    setIsRest(!isRest)
    setInitialFilters({})
  }

  return (
    <>
      <div style={ { paddingTop: 25, paddingLeft: 20, paddingRight: 20, border: "1px solid #C9D6DF", borderRadius: 10, background: 'rgba(201, 214, 223, 0.05)' } }>

        <Form
          { ...formLayout }
          name={ formName }
          onFinish={ onApplyFilter }
          initialValues={ initialFilters }
          form={ filterForm }
          scrollToFirstError
        >
          <Row gutter={ 20 }>
            <Col lg={ 8 } sm={ 12 } xs={ 24 }>
              <Form.Item name="employment_id" rules={ []} label={ trans(`${langKey}.employment_id`) }
                className="da-mb-16"
                size="small"
              >
                <SelectResourceList type='employment' mode="multiple" />
              </Form.Item>
            </Col>

            <Col lg={ 8 } sm={ 12 } xs={ 24 }>
              <Form.Item name="location_id" rules={ []} label={ trans(`${langKey}.location_id`) }
                className="da-mb-16"
                size="small"
              >
                <SelectWithSearch
                  placeholder={ "Select Location" }
                  onChange={ onChangeLocation }
                  options={ locations }
                />
              </Form.Item>
            </Col>

            <Col lg={ 8 } sm={ 12 } xs={ 24 } >
              <Form.Item name="designation_id" rules={ []} label={ trans(`${langKey}.designation_id`) }
                className="da-mb-16"
                size="small"
              >
                <SelectWithSearch
                  placeholder={ "Select Designation" }
                  options={ designations }
                />
              </Form.Item>
            </Col>

            <Col lg={ 8 } sm={ 12 } xs={ 24 }>
              <Form.Item name="grade_id" rules={ []} label={ trans(`${langKey}.grade_id`) }
                className="da-mb-16"
                size="small"
              >
                <SelectWithSearch
                  placeholder={ "Select Grade" }
                  options={ grades }
                />
              </Form.Item>
            </Col>

            <Col lg={ 8 } sm={ 12 } xs={ 24 }>
              <Form.Item name="department_id" rules={ []} label={ trans(`${langKey}.department_id`) }
                className="da-mb-16"
                size="small"
              >
                <SelectWithSearch
                  placeholder={ "Select Department" }
                  options={ departments }
                />
              </Form.Item>
            </Col>

            <Col lg={ 8 } sm={ 12 } xs={ 24 }>
              <Form.Item name="branch_id" rules={ []} label={ trans(`${langKey}.branch_id`) }
                className="da-mb-16"
                size="small"
              >
                <SelectWithSearch
                  placeholder={ "Select Branch" }
                  options={ branches }
                />
              </Form.Item>
            </Col>
          </Row>


          <div className="button-style-filter">

            <Button className="button-applyFilter" type="primary" disabled={ false } htmlType="submit" loading={ isSearching }>Apply Filter</Button>
            <Button onClick={ onResetFilter } form={ formName } htmlType="button" className="button-applyFilter" type="primary" disabled={ false } danger>Reset Filter</Button>
          </div>
        </Form>
      </div>

      <Form onFinish={ onSubmitEmployments } initialValues={ setSelectedEmployees } name="filterEmployeeForm" form={ form }>
        <Spin spinning={isSearching} tip="Loading...">
        <Row gutter={ 30 }>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <div className="unselected-employees-style">
              <div className="unselected-employees">
                <h5 className="unselected-employees-text">Unselected Employees {filteredEmployees.length>0?`(${filteredEmployees.length})`:''}</h5>
              </div>
              <Button className="unselected-employees-button" onClick={ moveAllToSelected }>
                <ArrowRightOutlined style={ { fontSize: 16 } } />
              </Button>
              { filteredEmployees.map((each, index) => (
                <Space>
                  <li key={ each.id }>
                    <div onClick={ () => moveEmployeeToSelected(index) } className="moveEmployee">
                      <div >
                        <div><img className="each-profile-pic-thumb-url" src={ each.profile_pic_thumb_url !== "images/arnold-avatar.jpg" ? each.profile_pic_thumb_url : "/images/arnold-avatar.jpg" } alt="" /></div>
                        <div className="each-employee"> <p className="each-full-name">{ each.full_name }</p>
                          <p className="each-designation-name">{ each.designation_name }</p>
                        </div>
                      </div>
                    </div>
                  </li>
                </Space>
              )) }
            </div>
            {/* <PaginationComponent pagination={ pagination } setCurrentPage={ onChangePagination } currentPage={ currentPage } numbering={false} /> */}
          </Col>


          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <div className="unselected-employees-style1">
              <div className="unselected-employees">
                <h5 className="unselected-employees-text">Selected Employees {selectedEmployees.length>0?`(${selectedEmployees.length})`:''}</h5>
              </div>
              <Button className="unselected-employees-button1" onClick={ moveAllToUnSelected }>
                <ArrowLeftOutlined style={ { fontSize: 16 } } />
              </Button>
              { selectedEmployees.map((each, index) => (
                <Space>
                  <li key={ each.id }>
                    <div onClick={ () => moveEmployeeToUnSelected(index) } className="moveEmployee">
                      <div>
                        <div><img className="each-profile-pic-thumb-url" src={ each.profile_pic_thumb_url !== "images/arnold-avatar.jpg" ? each.profile_pic_thumb_url : "/images/arnold-avatar.jpg" } alt="" /></div>
                        <div className="each-employee"> <p className="each-full-name">{ each.full_name }</p>
                          <p className="each-designation-name">{ each.designation_name }</p>
                        </div>
                      </div>
                    </div>
                  </li>
                </Space>
              )) }
            </div>
          </Col>
        </Row>

        </Spin>
        
        <div className="button-style">
        {props.is_delete ? <Popconfirm
                title="Delete"
                description="Are you sure to delete?"
                okText="Yes"
                cancelText="No"
                onConfirm={onSubmitEmployments}
              >   
          <Button disabled={selectedEmployees.length===0} className="button-next" loading={loader} style={ { background: props.is_delete ? "#EA4335" : "#4079FC" } } type="primary">
            { props.is_delete ? 'Delete' : 'Next' }
          </Button>
         </Popconfirm>:
          <Button disabled={selectedEmployees.length===0} className="button-next" onClick={onSubmitEmployments} loading={props.saveButton?false:loader} style={ { background: props.is_delete ? "#EA4335" : "#4079FC" } } type="primary">
          { props.is_delete ? 'Delete':props.saveButton?"Save" : 'Next' }
        </Button>
         }
          

          <Button className="button-back" onClick={ () => props.onBack() }>
            <span style={ { color: "#4079FC" } }>Back</span>
          </Button>
        </div>
      </Form>

    </>
  )
}

export default FilterSelectEmployees
const rules = {
  employment_ids: [
    { required: true, message: 'Required' },
  ],
  end_date: [
    { required: true, message: 'Required' },
  ],
  repeat: [
    { required: true, message: 'Required' },
  ],
  font_colour: [
    { required: true, message: 'Required' },
  ],
  bg_colour: [
    { required: true, message: 'Required' },
  ],

};
const formLayout = {
  layout: "vertical",
  labelCol: { span: 2 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}