import React, { useEffect, useState } from "react";
import { Form, message, Checkbox, Skeleton, Row, Col } from "antd";

import { SaveButton, InputText, InputTextArea, CancelButton, ModalComponent, trans, SelectWithSearch, InputDate, SelectResourceList } from "@comps/components"
import { makeRequest, makeRequestStateless, getErrorProps } from "@utils/helpers";
import { updateRelativeReq, getAllEmployeesReq, getEmployeesRelativeByIdReq, getRelatedDataReq } from "../requests";
import { Commons, Const } from "../../constants";
import { createSelectList, createRelationshipsList } from '@comps/commonFunctions';
import dayjs from "dayjs";
import moment from "moment";
const langKey = Const["relative"].lngKey

const formName = "updateRelative";

const UpdateRelative = (props) => {

  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [isRelativeWithinCompany, setIsRelativeWithinCompany] = useState(false);
  const [employeeRelative, setEmployeesGroup] = useState([]);
  const [relationships, setRelationships] = useState([]);
  const [saving, setsaving] = useState(false);
  const [loading, setloading] = useState(true);
  const [form] = Form.useForm()
  useEffect(() => {
    getAndSetRecord()
    makeRequestStateless(getRelatedDataReq, false, ongetRelatedData, false)
    makeRequestStateless(getAllEmployeesReq, false, ongetAllEmployees, false)
    // eslint-disable-next-line
  }, []);
  const getAndSetRecord = () => {
    props.record.name = props.record.relative_name;
    setIsRelativeWithinCompany(props.record.relative_within_group)
    props.record.d_o_b = props.record.d_o_b ? dayjs(props.record.d_o_b) : null
    form.setFieldsValue(props.record)
  }

  const ongetRelatedData = (response) => {
    setRelationships(createRelationshipsList(response.relationships))
  }
  const ongetAllEmployees = (response) => {
    setEmployees(createSelectList(response.resources.data))
    setloading(false)
  }

  const onSubmit = (data) => {
    const payload = { employee_relative: data, filters: props.filters }
    payload.id = props.record.id;
    makeRequest(setLoader, updateRelativeReq, payload, onSuccess, onError);
    setsaving(true)
  }

  const onSuccess = (data, res) => {
    message.success("Relative Updated successfully");
    props.onCompleted(data.employee_relatives.data);

  }

  const onError = (err, res) => {
    setsaving(false)
    message.error(res.response.data.message);
    //setErrors(errorList);
  }
  //-----------on changes------------
  const onChangeIsRelativeWithinCompany = (e) => {
    setIsRelativeWithinCompany(e.target.checked)
  }

  const onChangeGroupEmployee = (id) => {
    makeRequestStateless(getEmployeesRelativeByIdReq, id, ongetEmployeesRelative, false)
  }

  const ongetEmployeesRelative = (response) => {
    response.d_o_b = response.d_o_b ? moment(response.d_o_b) : null;
    form.setFieldsValue({
      phone_no: response.mobile_no,
      cnic_no: response.cnic_no,
      email: response.email,
      d_o_b: response.d_o_b
    })
    setEmployeesGroup(response);
  }
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [
    props.disabled ? '' :
      <SaveButton loading={ saving } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } width={ 1000 } maskClosable={ false }
      mainTitle={ trans(Commons[props.disabled ? 'view' : 'update']) } subTitle={ trans(`${langKey}.relative`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Skeleton loading={ loading }>
        <Form { ...formLayout } form={ form } name={ formName } onFinish={ onSubmit } disabled={ props.disabled } scrollToFirstError>
          <Row gutter={ 30 }>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="employee_id" label={ trans(`${langKey}.employee`) } rules={ rules.employee_id } className="da-mb-16"
                { ...getErrorProps(errors['employee_id']) }
              >
                <SelectResourceList type='employee' value={ props.record.employee_id } disabled={ props.filters !== undefined } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item valuePropName="checked" name="relative_within_group" label={ trans(`${langKey}.relative_within_group`) } className="da-mb-16">
                <Checkbox onChange={ onChangeIsRelativeWithinCompany }></Checkbox>
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="employee_relative_id" rules={ isRelativeWithinCompany ? rules.employee_relative_id : [] } label={ trans(`${langKey}.employee_relative_id`) }
                className="da-mb-16"
                { ...getErrorProps(errors['employee_relative_id']) } size="small"
                style={ { display: !isRelativeWithinCompany ? 'none' : 'block' } }
              >
                <SelectWithSearch
                  onChange={ onChangeGroupEmployee }
                  placeholder={ trans(`${langKey}.employee`) }
                  options={ employees }
                />
              </Form.Item>

              <Form.Item name="name" rules={ !isRelativeWithinCompany ? rules.name : [] } label={ trans(`${langKey}.name`) }
                className="da-mb-16"
                { ...getErrorProps(errors['name']) } size="small"
                style={ { display: !isRelativeWithinCompany ? 'block' : 'none' } }
              >
                <InputText placeholder={ trans(`${langKey}.name`) } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="relationship" label={ trans(`${langKey}.relationship`) } rules={ rules.relationship } className="da-mb-16"
                { ...getErrorProps(errors['relationship']) }
              >
                <SelectWithSearch
                  placeholder={ trans(`${langKey}.relationship`) }
                  options={ relationships }
                />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="phone_no" rules={ rules.phone_no } label={ trans(`${langKey}.phone_no`) }
                className="da-mb-16"
                { ...getErrorProps(errors['phone_no']) } size="small"
              >
                <InputText placeholder={ trans(`${langKey}.phone_no`) } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="email" rules={ rules.email } label={ trans(`${langKey}.email`) }
                className="da-mb-16"
                { ...getErrorProps(errors['email']) } size="small"
              >
                <InputText placeholder={ trans(`${langKey}.email`) } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="d_o_b" rules={ rules.d_o_b } label={ trans(`${langKey}.d_o_b`) }
                className="da-mb-16"
                { ...getErrorProps(errors['d_o_b']) } size="small"
              >
                <InputDate format='YYYY-MM-DD' />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="address" rules={ rules.address } label={ trans(`${langKey}.address`) }
                className="da-mb-16"
                { ...getErrorProps(errors['address']) } size="small"
              >
                <InputTextArea placeholder={ trans(`${langKey}.address`) } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="cnic_no" rules={ rules.cnic_no } label={ trans(`${langKey}.cnic_no`) }
                className="da-mb-16"
                { ...getErrorProps(errors['cnic_no']) } size="small"
              >
                <InputText placeholder={ trans(`${langKey}.cnic_no`) } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item valuePropName="checked" name="is_dependant" label={ trans(`${langKey}.is_dependant`) } className="da-mb-16">
                <Checkbox />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Skeleton>

    </ModalComponent>
  )
}

export default UpdateRelative

const rules = {
  phone_no: [
    { required: true, message: trans(`${langKey}.phone_no.req`) },
  ],
  employee_id: [
    { required: true, message: trans(`${langKey}.employee_id.req`) },
  ],
  relationship: [
    { required: true, message: trans(`${langKey}.relationship.req`) },
  ],
  employee_relative_id: [
    { required: true, message: trans(`${langKey}.employee_relative_id.req`) },
  ],
  name: [
    { required: true, message: trans(`${langKey}.name.req`) },
  ]
};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}