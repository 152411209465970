import React, { useEffect, useState } from "react";
import { Form, message, Skeleton } from "antd";
import { SaveButton, InputText, InputCheck, CancelButton, trans, SelectWithSearch,SelectResourceList } from "@comps/components"

import { Commons, Const } from "../../../constants";

const langKey = Const["requestFlow"].lngKey
const formName = "AddNode";

const AddNode = (props) => {

  const [saving, setSaving] = useState(false)
  const [loading, setloading] = useState(false);
  const [hasCondition, sethasCondition] = useState(false)
  const [isNode, setIsNode] = useState(null)
  const [form] = Form.useForm()
  useEffect(() => {
    setData()
    // eslint-disable-next-line
  }, []);


  const setData = () => {
    if (props.record) {
      sethasCondition(props.record.has_condition)
      setIsNode(props.record.request_as_id)
      form.setFieldsValue(props.record)
    }

  }

  const onSubmit = (data) => {
    
    let obj = { ...data }
    let rowState = 'Added'
    if (props.record) {
      rowState = 'Updated'
      obj = { ...props.record, ...data }
    }
    props.onCompleted({...obj,row_state:rowState}, rowState)
  }

  const rules = {
    request_as_id: [
      { required: true, message: trans(`${langKey}.request_as_id`) },
    ],
    employment_id: [
      { required: true, message: trans(`${langKey}.employment_id`) },
    ],
    // count: [
    //   { required: true, message: trans(`${langKey}.count`) },
    // ],
    // padding: [
    //   { required: true, message: trans(`${langKey}.padding`) },
    // ],
    next: [
      { type: 'number', min: props.nextLimit, message:'Must be greater than '+ props.nextLimit}
    ],
  };


  return (
    <>
      <Skeleton loading={ loading }>
        <Form { ...formLayout } name={ formName } onFinish={ onSubmit } form={ form }>
        {/* <Form.Item name="id" rules={ rules.id } label={ trans(`${langKey}.id`) }
            className="da-mb-16"
            initialValue={props.id} 
          >
            <InputText disabled
            />
          </Form.Item> */}
          <Form.Item name="editable" rules={ rules.editable } label={ trans(`${langKey}.editable`) }
            className="da-mb-16"
            valuePropName="checked"

          >
            <InputCheck
            />
          </Form.Item>
          <Form.Item name="has_condition" rules={ rules.has_condition } label={ trans(`${langKey}.has_condition`) }
            className="da-mb-16"
            valuePropName="checked"

          >
            <InputCheck onChange={ (e) => sethasCondition(e.target.checked) }
            />
          </Form.Item>

          <Form.Item name="request_as_id" rules={ rules.request_as_id } label={ trans(`${langKey}.request_as_name`) }
            className="da-mb-16"
            size="small"
          >
            <SelectWithSearch
              placeholder={ trans(`${langKey}.request_as_name`) }
              options={ props.nodeTypes }
              onChange={ (id) => setIsNode(id) }
              disabled={ hasCondition }
            />
          </Form.Item>
          {
            isNode === 8 &&
            <Form.Item name="employment_id" rules={ rules.employment_id } label={ trans(`${langKey}.employee_id`) }
              className="da-mb-16"
              size="small"
            >
              <SelectResourceList
                disabled={ hasCondition && isNode !== 8 }
                type="employment"
              />
            </Form.Item>
          }
          <Form.Item name="next" rules={ rules.next } label={ trans(`${langKey}.next`) }
            className="da-mb-16"
          >
            <InputText type='number' disabled={ hasCondition }
            />
          </Form.Item>
          <Form.Item name="expression" rules={ rules.expression } label={ trans(`${langKey}.expression`) }
            className="da-mb-16"
          >
            <InputText disabled={ !hasCondition }
            />
          </Form.Item>

          <Form.Item name="if_true" rules={ rules.if_true } label={ trans(`${langKey}.if_true`) }
            className="da-mb-16"
            size="small"
          >
            <InputText disabled={ !hasCondition } />
          </Form.Item>
          <Form.Item name="if_false" rules={ rules.if_false } label={ trans(`${langKey}.if_false`) }
            className="da-mb-16"
            size="small"
          >
            <InputText disabled={ !hasCondition } />
          </Form.Item>
          <div style={ { display: "flex", justifyContent: "right" } }>
            <SaveButton text={ "Save" } style={ { marginRight: 10 } } loading={ saving } form={ formName } key="create_button" htmlType="submit" />
            <CancelButton text={ "Cancel" } key="close_button" onClick={ () => props.onCompleted(false) } />
          </div>
        </Form>

      </Skeleton>

    </>
  )
}

export default AddNode
const formLayout = {
  // layout: "horizontal",
  labelCol: { span: 6 },
  wrapperCol: { span: 14, offset: 2 },
  labelAlign: "left"

}