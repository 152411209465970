import React from "react";
import { Button, Popconfirm, Tooltip, Popover } from "antd";
import { DeleteOutlined, EditOutlined, EyeOutlined, WarningOutlined, PlusOutlined, ClusterOutlined, StopOutlined, ClockCircleOutlined } from "@ant-design/icons";
import "./layouts-styles.css"
import { MoreVertSharp } from "@mui/icons-material";
import { checkPermissions } from "@comps/commonFunctions"
const classes = "da-px-10 da-my-0";

const BaseAction = (props) => {
  const {modkey } = { ...props };
  const dropdown = () => {
    return <>
      <div style={ { display: 'flex', flexDirection: 'row' } }>
        
        { props.onCancelRequest ? <Tooltip title="Cancel Request"><Button className={ classes } type="link" size="middle" onClick={ () => cancelRequest(props) } disabled={checkPermissions(modkey, 'cancel')?false:true}><StopOutlined className="icon-style da-text-color-info-1" /></Button></Tooltip> : null }

        { props.onViewFlow ? <Tooltip title={checkPermissions(modkey, 'flow')?'Flow':'No permission!'}><Button className={ classes } type="link" size="middle" onClick={ () => viewFlow(props) } disabled={checkPermissions(modkey, 'flow')?false:true}><ClusterOutlined className="icon-style da-text-color-info-1" /></Button></Tooltip> : null }

        { props.onViewHistory ? <Tooltip title={checkPermissions(modkey, 'history')?'History':'No permission!'}><Button className={ classes } type="link" size="middle" onClick={ () => viewHistory(props) } disabled={checkPermissions(modkey, 'history')?false:true}><ClockCircleOutlined className="icon-style da-text-color-info-1" /></Button></Tooltip> : null }

        { props.onView  ?<Tooltip title={checkPermissions(modkey, 'view')?'View':'No permission!'}> <Button className={ classes } type="link" size="middle" onClick={ () => view(props) } disabled={checkPermissions(modkey, 'view')?false:true}><EyeOutlined className="icon-style da-text-color-info-1" /></Button></Tooltip> : null }

        { props.onAdd ?<Tooltip title="Add New"> <Button className={ classes } type="link" size="middle" onClick={ () => add(props) }><PlusOutlined className="icon-style" /></Button></Tooltip> : null }

        { props.onEdit ?<Tooltip title={checkPermissions(modkey, 'update')?'Edit':'No permission!'}> <Button className={ classes } type="link" size="middle" onClick={ () => edit(props) }  disabled={checkPermissions(modkey, 'update')?false:true}><EditOutlined className="icon-style da-text-color-warning-1"/></Button></Tooltip> : null }

        { props.onDelete ? (
          <Popconfirm title="Are you sure?" icon={ <WarningOutlined /> } onConfirm={ () => del(props) } disabled={checkPermissions(modkey, 'delete')?false:true}>
<Tooltip title={checkPermissions(modkey, 'delete')?'Delete':'No permission!'}>
<Button disabled={checkPermissions(modkey, 'delete')?false:true} className={ classes } type="link" size="middle"><DeleteOutlined className="icon-style  da-text-color-danger-1" /></Button>
</Tooltip>
          </Popconfirm>
        ) : null }
        { props.children }
      </div>
    </>
  }
  return (
    <>
      <Popover content={ dropdown } title="" trigger="hover" placement="right">
        <MoreVertSharp className="text-primary" style={ { background: "rgb(222, 223, 224,0.5)", borderRadius: '25px', cursor: 'pointer' } } />
      </Popover>
    </>
  );
};

const cancelRequest = (props) => {
  if (props.onViewFlow) {
    props.onCancelRequest(props.each);
  }
}
const viewFlow = (props) => {
  if (props.onViewFlow) {
    props.onViewFlow(props.each);
  }
}
const viewHistory = (props) => {
  if (props.onView) {
    props.onViewHistory(props.each);
  }
}
const view = (props) => {
  if (props.onView) {
    props.onView(props.each);
  }
}

const edit = (props) => {
  if (props.onEdit) {
    props.onEdit(props.each);
  }
}

const add = (props) => {
  if (props.onAdd) {
    props.onAdd(props.each);
  }
}

const del = (props) => {
  if (props.onDelete) {
    props.onDelete(props.each);
  }
}

export default BaseAction;