

import React, { useEffect, useState } from "react";
import { Form, message } from "antd";
import { CancelButton, ModalComponent, trans, TableComponent } from "@comps/components"

import moment from "moment";



const BaseShowErrors = (props) => {

  const [dataSource, setDataSource] = useState([])
  const [columns, setColumns] = useState([])

  useEffect(() => {
    setData()
  }, []);

  const setData = () => {

    const errs = props.errors !== undefined ? props.errors : [];
    const data = [];
    const newCols = []
    const errObj = props.errors !== undefined ? props.errors[0] : null
    Object.entries(errObj).forEach(([key, value]) => {
      const col = {
        key: key,
        title: key,
        dataIndex: key
      }
      newCols.push(col)
    });
    setColumns(newCols)
    setDataSource(errs)
  }

  const footer = [
    <CancelButton key="close" onClick={ () => props.onCompleted(null) } />
  ]

  return (
    <>
      <ModalComponent width={ 1000 } open={ true } footer={ footer } mainTitle={ <span className="text-danger">{ props.module }</span> } subTitle={ <span className="text-danger">Errors</span> }>
        <TableComponent
          columns={ columns }
          dataSource={ dataSource }
        />

      </ModalComponent>

    </>
  )

}

export default BaseShowErrors
