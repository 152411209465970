
import React, { useEffect, useState } from "react";
import { Button, Form, message, Row, Col, Skeleton } from "antd";
import { trans, SelectWithSearch, InputText, SaveButton } from "@comps/components";
import { makeRequest, makeRequestStateless, getErrorProps } from "@utils/helpers";
import { createSelectList, checkPermissions } from '@comps/commonFunctions';
import { getCompanyRelatedDataReq, getUserByEmployeeIdReq, updateUserReq } from "../requests";
import { Const } from '../../constants';
import { EditOutlined } from "@ant-design/icons";

const langKey = Const["employee"].lngKey
const formName = "updateUser";
const UpdateUserInfo = (props) => {
  const [form] = Form.useForm()
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [roles, setRoles] = useState([]);
  const [userId, setUserId] = useState();
  const [userEditMode, setUserEditMode] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleSaveClick = () => {
    setUserEditMode(false);
  };

  const handleEditClick = () => {
    setUserEditMode(!userEditMode)
  };


  useEffect(() => {
    getUserByEmployeeId(props.id);
    getCompanyRelatedData();
    // eslint-disable-next-line
  }, [props]);

  //select
  const getUserByEmployeeId = (id) => {
    makeRequest(setLoader, getUserByEmployeeIdReq, id, onGetUserByEmployeeId, onGetUserByEmployeeId);
  }
  const onGetUserByEmployeeId = (response) => {
    //setLoading(false)
    setUserId(response.user.id)
    form.setFieldsValue({
      "email": response.user.email,
      "timezone_id": response.user.timezone_id,
      "role_id": response.user.role_id,
    })
  }
  const getCompanyRelatedData = () => {
    makeRequestStateless(getCompanyRelatedDataReq, false, onCompanyRelatedDataSuccess, onCompanyRelatedDataError);
  }
  const onCompanyRelatedDataSuccess = (data, res) => {
    setLoading(false)
    setRoles(createSelectList(data.roles));
    //setTimezones(createSelectList(data.time_zones))
  }
  const onCompanyRelatedDataError = (error, res) => {
    setLoading(false)
  }

  //------------on submit forms----------------
  const onSubmitStep = (data) => {
    setthirdStepData(data);
    setLoader(true)
    let payload = { user_info: data }
    payload.user_info.id = userId;
    payload.user_info.employee_id = props.id;
    makeRequest(setLoader, updateUserReq, payload, onUserCreateSuccess, onUserCreateError);
  }

  const onUserCreateSuccess = (response) => {
    setLoader(false)
    message.success('User Updated Successfully!')
  }
  const onUserCreateError = (error, response) => {
    message.error(response.response.data.message)
  }


  //-----------------onChange--------------------
  //-------------------end--------------------
  // ------------------------------------


  // ------------------------------------
  //---------------------form layout------------

  //------------------------------------
  //------------current step---------

  const [thirdStepData, setthirdStepData] = useState();
  //------------------step 3 form---------------------------
  const thirdStep = () => {
    return (<>
      <div style={ { border: '1px solid #C9D6DF', marginTop: 20, borderRadius: 10 } }>
        <div className="employeeInfo" ><h5 className="employeeInfo-text">User Account Information</h5></div>

        <Skeleton loading={ loading }>
          <Form
            { ...formLayout }
            layout="vertical"
            form={ form }
            name={ formName }
            onFinish={ onSubmitStep }
            className="custom-form"
            disabled={ !userEditMode }
            scrollToFirstError
          >
            <div style={ { marginLeft: 12, marginTop: 20 } }>
              <Row>
                <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                  <Form.Item name="email" rules={ rules.user_name } label={ trans(`${langKey}.username`) } className="da-mb-16"
                    { ...getErrorProps(errors['username']) } size="small"
                  >
                    <InputText
                      disabled={ userEditMode ? !checkPermissions('users', 'update') : true }
                      placeholder={ 'Enter here' } />
                  </Form.Item>
                </Col>

                <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                  <Form.Item name="role_id" label={ trans(`${langKey}.selectrole`) } rules={ rules.role_id } className="da-mb-16"
                    { ...getErrorProps(errors['role_id']) }
                  >
                    <SelectWithSearch
                      disabled={ userEditMode ? !checkPermissions('roles', 'update') : true }
                      className="selectWithSearch"
                      placeholder={ "Select your Role" }
                      options={ roles }
                    />
                  </Form.Item>
                </Col>

                <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                  <Form.Item name="password" rules={ rules.password } label={ trans(`${langKey}.password`) } className="da-mb-16"
                    { ...getErrorProps(errors['password']) } size="small"
                  >
                    <InputText
                      placeholder={ 'Enter here' } type="password" />
                  </Form.Item>
                </Col>
              </Row>
            </div>

            { userEditMode &&

              <div className="employeeInfo-update">
                <Form.Item style={ { float: 'right' } }>
                  <SaveButton className="employeeInfo-update-save" type="primary" htmlType="submit" loading={ loader }>Update</SaveButton>
                </Form.Item>
              </div>
            }
          </Form>
        </Skeleton>

        { userEditMode ? (
          <Button className="employeeInfo-update-cancel" style={ { marginTop: -58 } } onClick={ handleSaveClick }>
            <span style={ { color: "#EA4335" } }>Cancel</span>
          </Button>
        ) : (
          <div>
            { checkPermissions('profile_userInformation') &&
              <Button className="edit-button" type="primary" onClick={ handleEditClick } >
                <span className="edit-button-text"><EditOutlined /> Edit</span>
              </Button>
            }
          </div>
        ) }
      </div>

    </>)
  }


  return (
    <>
      { thirdStep() }
    </>

  )



}

export default UpdateUserInfo

const rules = {
  salutation_id: [
    { required: true, message: trans(`${langKey}.salutation.required`), },
  ],
  first_name: [
    { required: true, message: trans(`${langKey}.firstName.required`), },
  ],

  last_name: [
    { required: true, message: trans(`${langKey}.lastName.required`), },
  ],
  father_or_husband_name: [
    { required: true, message: trans(`${langKey}.fatherName.required`), },
  ],
  gender_id: [
    { required: true, message: trans(`${langKey}.gender.required`), },
  ],
  d_o_b: [
    { required: true, message: trans(`${langKey}.dob.required`), },
  ],
  marital_status_id: [
    { required: true, message: trans(`${langKey}.dob.required`), },
  ],
  nationality_id: [
    { required: true, message: trans(`${langKey}.nationality.required`), },
  ],
  mobile_no: [
    { required: true, message: trans(`${langKey}.mobileNo.required`), },
  ],
  cnic_no: [
    { required: true, message: trans(`${langKey}.cnicNo.required`), },
  ],
  passport_no: [
    { required: true, message: trans(`${langKey}.passportNo.required`), },
  ],
  joining_date: [
    { required: true, message: trans(`${langKey}.joiningDate.required`), },
  ],
  grade_id: [
    { required: true, message: trans(`${langKey}.grade.required`), },
  ],
  location_id: [
    { required: true, message: trans(`${langKey}.location.required`), },
  ],
  department_id: [
    { required: true, message: trans(`${langKey}.department.required`), },
  ],
  branch_id: [
    { required: true, message: trans(`${langKey}.branch.required`), },
  ],
  designation_id: [
    { required: true, message: trans(`${langKey}.designation.required`), },
  ],

  jobtitle_id: [
    { required: true, message: trans(`${langKey}.jobtitle.required`), },
  ],
  linemanager_id: [
    { required: true, message: trans(`${langKey}.lineManager.required`), },
  ],

  finalauthority: [
    { required: true, message: trans(`${langKey}.finalAuthority.required`), },
  ],
  official_email: [
    { required: true, message: trans(`${langKey}.officialEmail.required`), },
  ],
  email: [
    { required: true, message: trans(`${langKey}.email.required`), },
  ],
  employee_type_id: [
    { required: true, message: trans(`${langKey}.employeeType.required`), },
  ],
  contracttype_id: [
    { required: true, message: trans(`${langKey}.employmentType.required`), },
  ],
  user_name: [
    { required: true, message: trans(`${langKey}.userName.required`), },
  ],
  password: [
    { required: true, message: trans(`${langKey}.password.required`), }, { min: 6 },
  ],

  passwordconfirmation: [
    { required: true, message: trans(`${langKey}.passwordConfirmation.required`), }, { min: 6 },
  ],

  timezone_id: [
    { required: true, message: trans(`${langKey}.timezone.required`), },
  ],

  role_id: [
    { required: true, message: trans(`${langKey}.role.required`), },
  ],

  probation_end_date: [
    { required: true, message: trans(`${langKey}.probationEndDate.required`), },
  ]

};

const formLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 23 },
  labelAlign: "left"
}