
import saMessages from "../locales/ar_SA.json";

const saLang = {
  messages: {
    ...saMessages
  },
  locale: 'ar'
};
export default saLang;
