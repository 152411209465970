import { get, post, postFd, download } from "@utils/axios";

const api = "company/employees";
const apiTimeZones = "common/getTimezones"
const apiSalutations = "common/getSalutations"
export const getEmployeesReq = (payload) => {
  return get(`company/employee/employments?page=${payload.page}&filters=${payload.filters};`);

}

export const createEmployeeReq = (payload) => {
  return postFd(`${api}?`, payload);
}

export const getEmployeeByIdReq = (id) => {
  return get(`${api}/by-id/${id}`);
}
export const getSalaryPolicyByEmpId = (id) => {
  return get(`${api}/salary-policy/${id}`);
}
export const updateSalaryPolicyReq = (payload) => {
  return post(`${api}/salary-policy/update/${payload.employmentId}`, payload);
}

export const updateEmployeeReq = (payload) => {
  return postFd("company/employees/employee_info?", payload);
}
export const uploadPhotoReq = (payload) => {
  return postFd(`${api}/upload-photo/${payload.employeeId}`, payload);
}
export const getEmploymentByIdReq = (id) => {
  return get(`company/employee/employments/${id}`);
}
export const updateEmploymentReq = (payload) => {
  return postFd(`company/employee/employments/${payload.id}/update`, payload);
}
export const getUserByEmployeeIdReq = (id) => {
  return get(`company/users/get-by-employee/${id}`);
}
export const getContactByEmployeeIdReq = (id) => {
  return get(`employees/contacts/get-by-employee/${id}`);
}
export const updateUserReq = (payload) => {
  return post(`company/users/user_info?`, payload);
}
export const getProfileCardByEmploymentIdReq = (id) => {
  return get(`employee/employments/${id}/profile-card?`)
}
export const getAllTimeZonesReq = () => {
  return get(apiTimeZones)
};

export const getAllSalutationsReq = () => {
  return get(apiSalutations)
};
export const getCompanyReq = () => {
  return get('common/getCompany')
}

export const getAllGradesReq = () => {
  return get("common/getGrades")
};

export const getAllReligionsReq = () => {
  return get("common/getReligions")
};

export const getEmployeeRelatedDataReq = () => {
  return get("independent/employees/get-related-data")
}

export const getEmployeeUserRelatedDataReq = () => {
  return get("company/users/get-related-data")
}
export const getCompanyRelatedDataReq = () => {
  return get("employees/employee/employments/get-company-related-data/1")
}
export const getBranchsByLocationIdReq = (locationId) => {
  return get("common/getBranchsByLocationId/" + locationId)
}

export const matchCnicReq = (cnic) => {
  return get("independent/employees/by-cnic/" + cnic);
}
export const getFilterReq = (payload) => {
  return get(`filters?company_id=1&fields=page=1&filters=supporting_fields_|is_line_manager,is_final_authority,is_hod,employees,grade,designation,gender,job_titles,employee_types,employment_types;organization_structure_fields_|location,branch,department;preset_|company_employee;`);
}

export const getSalaryByEmployeeIdReq = (id) => {
  return get(`company/salary/get-by-employee/${id}`);
}
export const updateSalaryReq = (payload) => {
  return post(`company/Salary/user_info?`, payload);
}

// export const getAllEmployeesReq = () => {
//   return get("company/employee/employments?page=1");
// };

export const getRelatedDataReq = () => {
  return get(`independent/employment/salaries/get-related-data?`)
}
export const getSalaryRelatedDataReq = (payload) => {
  return get(`company/employment/salaries/get-employment-related-data/${payload.employmentId}?&module_name=dashboard&action_name=index&predefined_employment_id=${payload.employmentId}&predefined_employee_id=${payload.employeeId}`, payload);
}
export const getSalaryHistoryReq = (payload) => {
//   revision_history[requestable_class]: salary
// revision_history[requestable_id]: 161  //this is the salary id
  return post(`revisionHistory/getHistory?`, payload);
}
export const getSalaryHistoriesReq = (payload) => {
  return get(`company/employment/salary_histories?page=1&filters=employee_|${payload.employmentId}`);
}

export const onDownloadSampleEmployeesReq = () => {
  return download(`company-employee/download-sample`);
}
export const importEmployeeDataReq = (payload) => {
  return postFd(`company/employees/import`, payload);
}


//-----------Edit employee------------


//company / users / validate - username ? exclude_emp_id =

//get profile card
//https://demo-api.klok.ai/api/v1/employee/employments/94c5a64b-7ff7-40de-9f8f-9b01f1250c37/profile-card?

////download profile
//employee/employments/download?page=1&filters=download_type_|pdf;ids_|employmentId
