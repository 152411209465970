import React, { useEffect, useState } from "react";
import { Button, Checkbox, Form, Skeleton, message, Row, Col } from "antd";
import { SaveButton, InputText, CancelButton, ModalComponent, trans, InputDate } from "@comps/components"
import { makeRequest, getErrorProps } from "@utils/helpers";
import { getDataByIdReq, scheduleRepaymentReq } from "../requests";
import { Commons, Const } from "../../constants";
import dayjs from 'dayjs';
import { useSelector } from "react-redux";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import EmployeeInfo from '@mods/common/EmployeeInfo';
import { convertTimeTotz } from '@comps/commonFunctions';
import { moment } from 'moment';

const langKey = Const["lendRepayment"].lngKey
const formName = "createLendType";

const ScheduleRepayment = (props) => {

  const authUser = useSelector((state) => state.auth.authUser);
  const [loader, setLoader] = useState(false);
  const [saving, setsaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState([]);
  const [childComponent, setChildComponent] = useState(false)
  const [lend, setLend] = useState({});
  const [repayments, setRepayments] = useState([]);
  const [totalAmount, setTotalAmount] = useState(null)
  const [approvedAmount, setApprovedAmount] = useState(null);
  const [deletedRows, setDeletedRows] = useState([])
  const [form] = Form.useForm()
  useEffect(() => {
    getRepaymentById()
    // eslint-disable-next-line
  }, []);
  const getRepaymentById = () => {
    makeRequest(setLoader, getDataByIdReq, props.record.id, setData, onErrorGettingData);
  }


 const getPaidAmount = (lend) =>{
  var total = 0;
  for (var i in lend.repayments) {
    if (lend.repayments[i] && lend.repayments[i].processed) {
      total += lend.repayments[i].amount;
    }
  }
  return total;
}

  const setData = (response) => {
    setLend(response.lend)
    if (response.lend) {
      setRepayments(response.lend.repayments)

      response.lend.repayments.forEach(each => (
        each.repayment_date = each.repayment_date ? dayjs(each.repayment_date) : null
      ));

      form.setFieldsValue({
        approved_amount: response.lend.approved_amount,
        paid_amount: getPaidAmount(response.lend),
        remaining_amount: response.lend.remaining_amount,
        repayments: response.lend.repayments
      })
    }
    setLoading(false)
    getTotalAmount()
  }
  const onErrorGettingData = (err, res) => {

  }

  const onChangeAmount = (amount) => {
    const paid_amount = form.getFieldValue('paid_amount')
    const balance = amount - paid_amount
    form.setFieldValue('remaining_amount', balance)

  }
  const getTotalAmount = () => {
    const values = form.getFieldValue('repayments')
    let totalAmount = 0
    values.forEach((value) => {
      if (value !== undefined) {
        let amount = value.amount !== undefined ? parseFloat(value.amount) : 0
        if (value.amount === undefined || value.amount === '') {
          amount = 0
        }
        totalAmount = totalAmount + amount
      }
    })

    setTotalAmount(totalAmount)
  }

  const setRows = (key, state) => {
    if (repayments[key] !== undefined && repayments[key].id !== undefined && state === 'Deleted') {
      repayments[key].row_state = 'Deleted'
      deletedRows.push(repayments[key])
    }
    setDeletedRows(deletedRows)
  }
  //save
  const onSubmit = (data) => {

    if (totalAmount !== data.approved_amount) {
      message.info('Total Amount Sould be Same as Approved Amount!')
      return
    }
    const approvedAmount = data.approved_amount
    let newList = []
    data.repayments.forEach((each) => {
      if (each.id === undefined) {
        each.row_state = 'Added'
      }
      if (each.id !== undefined) {
        each.row_state = 'Updated'
      }
      if (each.is_manuall_entry === undefined) {
        each.is_manuall_entry = false
      }
      if (each.is_processed_manuall === undefined) {
        each.is_processed_manuall = false
      }

      if (each.repayment_date !== undefined && each.repayment_date) {
      
      } else {
        each.repayment_date = null
      }
      each.company_id = 1
      each.currency_id = 160
      each.employment_id = props.record.employment_id
      each.lend_id = props.record.id
      newList.push(each)
    })

    deletedRows.forEach(row => {
      newList.push(row)
    })
  
    const payload = { lend: { ...lend, approved_amount: approvedAmount, repayments: newList } };
    
  
    makeRequest(setLoader, scheduleRepaymentReq, payload, onSuccess, onError);
    setsaving(true)
  }

  const onSuccess = (data, res) => {
    message.success("Saved successfully");
    props.onCompleted(data.lends.data);
  }

  const onError = (err, res) => {
    setsaving(false)
    message.error(res.response.data.message);
  }
  //==============add details =================
  //-----------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------
  const footer = [
    props.disabled ? '' :
      <SaveButton loading={ saving } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } width={ 1200 } maskClosable={ false }
      mainTitle={ trans(Commons.update) } subTitle={ trans(`${langKey}.schedule`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      { childComponent }
      <Skeleton loading={ loading }>
        <EmployeeInfo record={ props.record.requestor && props.record.requestor } />
        <Form { ...formLayout } name={ formName } onFinish={ onSubmit } form={ form } disabled={ props.disabled } scrollToFirstError>
          <Row gutter={ 30 }>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="approved_amount" rules={ rules.approved_amount } label={ trans(`${langKey}.approved_amount`) }
                className="da-mb-16"
                { ...getErrorProps(errors['name']) } size="small"
              >
                <InputText type="number" onChange={ (e) => onChangeAmount(e.target.value) }
                />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="paid_amount" rules={ rules.all_paid_amount } label={ trans(`${langKey}.all_paid_amount`) }
                className="da-mb-16"
              >
                <InputText type="number" disabled={ true } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="remaining_amount" rules={ rules.remaining_amount } label={ trans(`${langKey}.remaining_amount`) }
                className="da-mb-16"
              >
                <InputText type="number" />
              </Form.Item>
            </Col>
          </Row>

          <div className="formlist-container">
            <h5 className="formlist-title">Re-Payments</h5>

            <Form.List name="repayments" >
              { (fields, { add, remove }) => (
                <>
                  <Button onClick={ () => {
                    add()
                    setRows(fields.length, 'Added')
                  } } icon={ <PlusOutlined /> } type="primary" className="add-node-button">Add </Button>
                  <div className="table-container">
                    <table className="table">
                      {
                        fields.length !== 0 && <thead>
                          <tr>
                            <th style={ { paddingLeft: 20 } }>{ trans(`${langKey}.repayment_date`) }</th>
                            <th>{ trans(`${langKey}.amount`) }</th>
                            <th>{ trans(`${langKey}.is_manuall_entry`) }</th>
                            <th>{ trans(`${langKey}.is_processed_manuall`) }</th>
                            <th>{ trans(`${langKey}.actions`) }</th>
                          </tr>
                        </thead>
                      }
                      <tbody>
                        { fields.map(({ key, name, ...restField }) => (
                          <>
                            <tr>
                              <td style={ { minWidth: '200px', paddingLeft: 20 } }>

                                <Form.Item
                                  { ...restField }
                                  name={ [name, 'repayment_date'] }
                                  rules={ rules.amount }
                                >
                                  <InputDate />
                                </Form.Item>
                              </td>
                              <td style={ { minWidth: '200px' } }>
                                <Form.Item
                                  { ...restField }
                                  name={ [name, 'amount'] }
                                  rules={ rules.amount }
                                >
<InputText
  type="number"
  onChange={() => getTotalAmount()}
  disabled={
    repayments[key] && repayments[key].hasOwnProperty('processed')
      ? repayments[key].processed
      : false
  }
/>
                                </Form.Item>
                              </td>
                              <td style={ { minWidth: '200px' } }>
                                <Form.Item
                                  valuePropName="checked"
                                  { ...restField }
                                  name={ [name, 'is_manuall_entry'] }
                                  rules={ rules.is_manuall_entry }
                                >
                                  <Checkbox className="formlist-remove" disabled={ true } />
                                </Form.Item>
                              </td>
                              <td style={ { minWidth: '200px' } }>
                                <Form.Item
                                  valuePropName="checked"
                                  { ...restField }
                                  name={ [name, 'is_processed_manuall'] }
                                  rules={ rules.is_processed_manuall }
                                >
                                  <Checkbox className="formlist-remove" disabled={ true } />
                                </Form.Item>
                              </td>
                              <td>
                                <DeleteOutlined className="formlist-remove" onClick={ () => {
                                  remove(name)
                                  getTotalAmount()
                                  setRows(key, 'Deleted')
                                } } />
                              </td>


                            </tr>
                          </>
                        )) }
                        <tr style={ { fontWeight: 'bold' } }>
                          <td >
                            Total Amount:
                          </td>
                          <td colspan={ 4 } >
                            { totalAmount }
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </>
              ) }
            </Form.List>
          </div>

        </Form>

      </Skeleton>

    </ModalComponent>
  )
}

export default ScheduleRepayment

const rules = {
  amount: [
    { required: true, message: trans(`${langKey}.amount`) },
  ],
  repayment_date: [
    { required: true, message: trans(`${langKey}.repayment_date`) },
  ],


};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}
