import { get, del, postFd } from "@utils/axios";

const api = "employee";

export const getAllExperiencesReq = (payload) => {
  return get(`${api}/experiences?page=${payload.page}&filters=${payload.filters}`);
};

export const createExperienceReq = (payload) => {
  return postFd(`${api}/experiences?&filters=${payload.filters}`, payload);
};

export const updateExperienceReq = (payload) => {
  return postFd(`${api}/experiences/${payload.id}/update?&filters=${payload.filters}`, payload);
};

export const deleteExperienceReq = (id) => {
  return del(`${api}/experiences/${id}`);
};

//-----------on create
export const getAllEmployeesReq = () => {
  return get("resources/employee?page=1");
};

export const getRelatedDataReq = () => {
  return get(`${api}/experiences/get-related-data?`)
}
export const getFilterReq = () => {
  return get(`filters?company_id=1&fields=page=1&filters=supporting_fields_|employees;preset_|employee_experiences;`)
}
