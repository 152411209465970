import { DatePicker } from "antd";

const BaseDatePicker = (props) => {
  return (

    <DatePicker
      style={ {
        width: '100%',
        height: '48px',
      } }
      format='YYYY-MM-DD'
      { ...props }
      placeholder={ props.placeholder }
    />

  )
}

export default BaseDatePicker
