import React, { useEffect, useState } from "react";
import { Form } from "antd";
import { CancelButton, ModalComponent, ActionComponent, trans, TableComponent } from "@comps/components"
import { Commons, Const } from "../../../constants";
import ViewDetailHistory from "./ViewDetailHistory";
import { EyeOutlined } from '@ant-design/icons';
const langKey = Const["probation"].lngKey
const formName = "updateProbation";

const ViewProbationHistory = (props) => {
  const [loader, setLoader] = useState(false);
  const [histories, setHistories] = useState([]);
  const [childComponent, setChildComponent] = useState(null);
  const [currentHistory,setCurrentHistory]=useState();
  const [form] = Form.useForm();
  useEffect(() => {
    setData();
  }, []);
  const setData = () => {
    if (!props) {
      return;
    }
    props.record.employment_id = props.record.requestor.full_name_code;
    form.setFieldsValue(props.record);
    if (props.record) {
      setHistories(props.record.histories);
      setCurrentHistory(props.record.current_history);
    }
  }

  //-----------------------on view--------------
  const onView = (record, attributes, scales) => {
    
    setChildComponent(
      <ViewDetailHistory onCompleted={ onCompleted } allRecord={props.record} record={ record } disabled={ true } histories={ histories } professional_attributes={ attributes } evaluation_scales={ scales } currentHistory={currentHistory}/>
    );
  };
  const onCompleted = (data) => {
    if (data.length) {
      setHistories(data);
    }
    setChildComponent(null);
  };

  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  const columns = [
    {
      key: 'requestee',
      title: trans(`${langKey}.requestee`),
      render: (record) => {
        return <div>{ record.requestee_full_name }<br /></div>
      },
    },
    {
      key: 'extended_duration',
      title: trans(`${langKey}.extended_duration`),
      dataIndex: "extended_duration",
    },
    {
      key: 'remarks',
      title: trans(`${langKey}.remarks`),
      dataIndex: "remarks",
    },
    {
      key: 'time',
      title: trans(`${langKey}.time`),
      dataIndex: "created_at",
    },
    {
      key: 'status',
      title: trans(`${langKey}.status`),
      dataIndex: 'status'
    },
    
    {
      key: trans(Commons.actions),
      title: trans(Commons.actions),
    
         render:(record)=>{
return <EyeOutlined onClick={()=>onView(record)}/>
         } 
      
    
    },
  ];
  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } width={ 1000 } maskClosable={ false }
      mainTitle={ trans(Commons.view) } subTitle={ trans(`${langKey}.history`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) } disabled={ props.disabled }>

      { childComponent }
      <TableComponent
        columns={ columns }
        dataSource={ histories }
        pagination={ false }
      />

    </ModalComponent>
  )
}

export default ViewProbationHistory

