import { Button, Tooltip } from "antd";


const BaseCustomButton = (props) => {
  let text = props.text ? props.text : props.children;

  let icon = null;
  let notify = props.notify;


  return (
    <Tooltip { ...notify }>
      <Button { ...props } className="custom-button">
        <span>{ text } </span>
        { icon }
      </Button>
    </Tooltip>
  );
};

export default BaseCustomButton;
