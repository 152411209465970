import React, { useEffect, useState } from "react";
import { Button, Form, message, Row, Checkbox, Col, Popconfirm } from "antd";
import { trans, InputDate } from "@comps/components"

import { getErrorProps } from "@utils/helpers";
import "./Roster.css"

import { Const } from "../constants";
const StepOneDelete = (props) => {
  const langKey = Const["companyRoaster"].lngKey
  const formName = "createRoaster";
  //========================use states start========================
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  //------------intitial steps state---------
  const [currentStep, setCurrent] = useState(0);
  const [isRepeat, setIsRepeat] = useState(false);
  const [fixStartDate, setFixStartDate] = useState(null);
  const [deleteAll, setDeleteAll] = useState(false);
  const [form] = Form.useForm()
  //=======================================
  useEffect(() => {
    setData();
    // eslint-disable-next-line
  }, [props]);

  //set props data
  //=================Submit First Step 1==============================================
  const setData = () => {
    if (props.initialValues) {
      let start = props.initialValues.start_date.format('YYYY-MM-DD');
      let end = props.initialValues.end_date.format('YYYY-MM-DD');
      if (end > start) {
        setIsRepeat(true)
      }
    }
  }

  const onChangeStartDate = (date) => {
    setFixStartDate(date)
  }
  const onChangeEndDate = (date) => {
    let start = fixStartDate ? fixStartDate.format('YYYY-MM-DD') : null;
    let end = date ? date.format('YYYY-MM-DD') : null
    if (date !== null) {
      if (end < start) {
        message.error('End Date should be greater than start date')
        setIsRepeat(false)
        return
      }
      if (start === end) {
        setIsRepeat(false)
      }
      else {
        setIsRepeat(true)
      }
    }
    if (date === null) {
      setIsRepeat(false)
    }
  }

  const onSubmitFirstStep = (data) => {
    data = form.getFieldsValue()
    if (deleteAll) {
      setLoader(true)
    }

    if (data.end_date === undefined || data.end_date === null) {
      data.end_date = data.start_date;
    }
    if (data.end_date.format('YYYY-MM-DD') < data.start_date.format('YYYY-MM-DD')) {
      message.error('End Date can only be greater Than or equal to Start Date!')
      return
    }

    props.response(data);
  }
  //-----------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------
  return (
    <>

      <Form
        // { ...formLayout }
        layout="vertical"
        name={ formName }
        onFinish={ onSubmitFirstStep }
        initialValues={ props.initialValues ? props.initialValues : null }
        form={ form }
      >
        <Row gutter={ 30 }>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="start_date" rules={ rules.start_date } label={ trans(`${langKey}.start_date`) }
              className="da-mb-16"
              { ...getErrorProps(errors['name']) } size="small"
            >
              <InputDate
                onChange={ onChangeStartDate }
              />
            </Form.Item>
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="end_date" rules={ []} label={ trans(`${langKey}.end_date`) }
              className="da-mb-16"
              { ...getErrorProps(errors['name']) } size="small"
            >
              <InputDate
                onChange={ onChangeEndDate } />
            </Form.Item>
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="delete_all_employees" rules={ rules.delete_all_employees } label={ trans(`${langKey}.delete_all_employees`) }
              className="da-mb-10"
              { ...getErrorProps(errors['name']) } size="small"
              valuePropName="checked"
            >
              <Checkbox onChange={ (e) => setDeleteAll(e.target.checked) } />
            </Form.Item>
          </Col>
        </Row>


        <div className="button-style">

          <Popconfirm
            title="Delete the task"
            description="Are you sure to delete this task?"
            okText="Yes"
            cancelText="No"
            onConfirm={ onSubmitFirstStep }
          >
            {

              deleteAll &&
              <Button className="button-next" style={ { background: deleteAll ? "#EA4335" : "#4079FC" } } type="primary" loading={ loader } >
                { 'Delete' }
              </Button>
            }
          </Popconfirm>
          {
            !deleteAll && <Button className="button-next" htmlType="submit" style={ { background: deleteAll ? "#EA4335" : "#4079FC" } } type="primary" loading={ loader } >
              { 'Next' }
            </Button>
          }
          {/* <Button className="button-back" disabled={ currentStep ? false : true }>Back</Button> */ }
        </div>
      </Form>
    </>
  )
}
export default StepOneDelete
const rules = {
  start_date: [
    { required: true, message: 'Required' },
  ],
  end_date: [
    { required: true, message: 'Required' },
  ],
  repeat: [
    { required: true, message: 'Required' },
  ],
  font_colour: [
    { required: true, message: 'Required' },
  ],
  bg_colour: [
    { required: true, message: 'Required' },
  ],
  days: [
    { required: true, message: 'Please Select At least one day' },
  ],

};
