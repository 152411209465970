import { get, del, put, postFd, putFd } from "@utils/axios";

const api = "areas";
const apiLocations = "common/getLocations"
export const getAllAreasReq = (payload) => {
  return get(`${api}?page=${payload.page}&filters=${payload.filters}`);
};

export const getLocationsAndManagersByCompanyIdReq = (id) => {
  return get(`${apiLocations}/${id}`);
};

export const createAreaReq = (payload) => {
  return postFd(`${api}?`, payload);
};

export const updateAreaReq = (payload) => {
  return put(`${api}/${payload.id}`, payload);
};

export const deleteAreaReq = (id) => {
  return del(`${api}/${id}`);
};

export const getCompanyReq = () => {
  return get('common/getCompany')
}

export const getFilterReq = (payload) => {
  return get(`${api}?page=1&filters=${payload}`)
}
export const getPreFilterReq = (payload) => {
  return get(`filters?company_id=1&fields=page=1&filters=organization_structure_fields_|location;preset_|organizationManagement.areas;`)
}
