import {
  HeaderComponent,
  BodyComponent,
  TableComponent,
  ActionComponent,
  trans,
  FilterComponent,
  FilterButton,
  ImportButton,
  DownloadButton,
  EmployeeWithProfile, SelectWithSearch, PaginationComponent,
} from "@comps/components";
import { makeRequest, makeRequestStateless, handleDownloadFile } from "@utils/helpers";
import { useState, useEffect } from 'react';
import { getEmployeesReq, getFilterReq, onDownloadSampleEmployeesReq } from "./requests"
import { Commons, Const, colors } from "../constants"
import ViewEmployee from "./components/ViewEmployee";
import moment from "moment";
import { Row, Col, Drawer, Card, Badge, Spin, message, Button } from "antd";
import { EyeOutlined } from '@ant-design/icons';
import AddEmployee from "./components/AddEmployee";
import { getResourceDataReq, createEmploymentResource } from "@comps/commonFunctions";
import ImportEmployees from "./components/ImportEmployees";
import { CreateButton } from "@comps/components";

// ==================================
//   Page Breadcrumbs Start
// ==================================

const langKey = Const["employee"].lngKey

// ==================================
//   Page Breadcrumbs End
// ==================================

const IndexEmployees = (props) => {
  const [drawerVisible, setDrawerVisible] = useState(false); // State to manage drawer visibility
  const [isCardView, setIsCardView] = useState(false); // Define the isCardView state
  const [viewEmployeeData, setViewEmployeeData] = useState(null);



  // ==================================
  //   Use States Start
  // ==================================
  const [dataSource, setDataSource] = useState([]);
  const [employments, setEmployments] = useState([])
  const [defaultEmployments, setDefaultEmployments] = useState([])
  const [loader, setLoader] = useState(false);
  const [filters, setFilters] = useState('');
  const [childComponent, setChildComponent] = useState(null);
  const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState({
    "total": 0,
    "count": 0,
    "per_page": 0,
    "current_page": 0,
    "total_pages": 0,
    "links": {}
  });
  //=========================================

  // ==================================
  //   Use States End
  // ==================================

  // ==================================
  //   Table Data End
  // ==================================

  const columns = [
    {
      key: trans(Commons.name),
      title: trans(Commons.name),
      render: (record) => (
        <EmployeeWithProfile record={ record } />
      )
    },
    {
      key: trans(Commons.branch),
      title: trans(Commons.branch),
      dataIndex: 'branch_name',

    },
    {
      key: trans(Commons.department),
      title: trans(Commons.department),
      dataIndex: 'department_name',

    },
    {
      key: trans(Commons.designation),
      title: trans(Commons.designation),
      dataIndex: 'designation_name',

    },
    {
      key: trans(Commons.joiningDate),
      title: trans(Commons.joiningDate),
      render: (record) => {
        return moment(record.joining_date).format('DD-MMM-YYYY');
      },

    },

    {
      key: trans(Commons.grade),
      title: trans(Commons.grade),
      dataIndex: 'grade_name'
    },
    // {
    //   key: trans(Commons.ProfileCompletion),
    //   title: trans(Commons.ProfileCompletion),
    //   render: (record) => {
    //     return <Badge
    //       count={ record.employee.profile_score + '% Completed' }
    //       style={ {
    //         background: 'white', color: colors[record.employee.profile_score_class], fontSize: 12,
    //         fontWeight: 700
    //       } }
    //     />
    //   }
    // },
    {
      key: trans(Commons.actions),
      title: trans(Commons.actions),
      render: (record) => <ActionComponent modkey={props.modkey} each={ record } onView={ onView } >
      </ActionComponent>
    },
  ];


  // ==================================
  //   Table Data End
  // ==================================

  useEffect(() => {
    getAllEmployees();
    // eslint-disable-next-line
  }, [currentPage, filters, props]);

  const getAllEmployees = () => {
    let payload = {
      page: currentPage,
      filters: `${props.filters};${filters}`
    };
    makeRequest(setLoader, getEmployeesReq, payload, onSuccess, onSuccess);
  }

  const onSuccess = (response) => {
    setPagination(response.employee_employments.meta.pagination);
    setDataSource(response.employee_employments.data);
    if (defaultEmployments.length === 0) {
      setDefaultEmployments(response.employee_employments.data)
    }
  }

  //search employee
  const getEmployments = (id) => {
    let payload = {
      page: 1,
      type: 'employment',
      filters: `search_|${id};${props.filters}`
    }
    makeRequestStateless(getResourceDataReq, payload, onSuccessResourse, null);
  };

  const onSuccessResourse = (response) => {
    setEmployments(response.resources.data);
  }
  // Create component modal
  const onAddEmployee = () => {
    setChildComponent(<AddEmployee onCompleted={ onCompleted } />)
  }

  const onCompleted = (data) => {
    if (data) {
      getAllEmployees()
    }

    setChildComponent(null)
  }
  const onView = (record) => {
    setViewEmployeeData(<ViewEmployee employment_id={ record.id } employee_id={ record.employee_id } disabled={ true } onCompleted={ getAllEmployees } />);
    // setSelectedRecord(record);
    setDrawerVisible(true);
  };

  const onCloseDrawer = () => {
    setDrawerVisible(false);
  };

  //---------------filters-----------------------
  const [filerComponent, setFilterComponent] = useState(null);
  const onOpenFilter = () => {
    setFilterComponent(<FilterComponent filters={ filterFields } modkey={props.modkey} selected={ filters } api={ getFilterReq } setCurrentPage={setCurrentPage} onFilter={ setFilters } onCloseFilter={ onCloseFilter } moduleName="address"
    />
    )
  }

  const onCloseFilter = () => {
    setFilterComponent(null);

  }

  //download sample
  const onDownloadSample = () => {
    makeRequestStateless(onDownloadSampleEmployeesReq, null, onSuccessDownloadSample, onSuccessDownloadSample);
  }
  const onSuccessDownloadSample = (res, err) => {

    if (res.code === undefined) {
      // Extract file extension
      const fileExtension = res.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ? 'xlsx' : 'xlsx'
      const fileName = `employee_employments.${fileExtension}`
      handleDownloadFile(fileName, res)
    }
    else {
      message.error(res.message)
    }
  }

  const onImport = () => {
    setChildComponent(<ImportEmployees onCompleted={ onCompleted } />);

  }

  //----end filter--------------------
  // ==================================
  //   Component JSX Start
  // ==================================

  return (
    <>
      { filerComponent }
      <HeaderComponent modkey={props.modkey}>
        <h5 className="total-employee">{ pagination.total } Employees</h5>
        <ImportButton onClick={ onImport } />
        <CreateButton onClick={ onAddEmployee } />
        <FilterButton onClick={ onOpenFilter } />
      </HeaderComponent>
      {/* <RefreshButton style={ { position: 'absolute', top: 19, right: 195 } } /> */ }


      <div className="table-card-view">
        <img src={ isCardView ? '../../sidebaricon/List.svg' : '../../sidebaricon/List1.svg' } alt="logo"
          onClick={ () => setIsCardView(false) }
          className={ isCardView ? 'table-view ' : 'table-view active' }
        />
        <img src={ isCardView ? '../../sidebaricon/CardView1.svg' : '../../sidebaricon/CardView.svg' } alt="logo"
          onClick={ () => {
            setIsCardView(true);
          } }
          className={ isCardView ? 'card-view active' : 'card-view ' }
        />
      </div>
      <DownloadButton style={ { float: "right", marginRight: 10 } } onClick={ onDownloadSample } />
      <BodyComponent>

<SelectWithSearch className="search-employees"
          loading={ loader }
          style={ { minWidth: 282, borderRadius: 5, background: '#FFFFFF' } }
          placeholder="Search Employee"
          size="large"
          options={ createEmploymentResource(defaultEmployments.concat(employments)) }
          onChange={ (id) => {
            setFilters('employees_|' + id)
            setCurrentPage(1)
          } }

          onSearch={ (id) => getEmployments(id) }
        />

        { isCardView ? (
          <TableComponent
            style={ { marginTop: 20 } }
            loader={ loader }
            columns={ columns }
            dataSource={ dataSource }
            pagination={ false }
          >
          </TableComponent>
        ) : (
          <div style={ {
            height: '65vh',
            overflowY: 'scroll',
            paddingRight: 10
          } }>
            <Spin spinning={ loader }>
              <Row gutter={ [20, 0] } >
                { dataSource.map((record, index) => (
                  <Col key={ record.id } xs={ 24 } sm={ 12 } md={ 12 } lg={ 8 } xl={ 6 }>
                    <div style={ { marginTop: 20 } }>
                      <Card onClick={ () => onView(record) } className="cards" >
                        <p style={ { display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: -10 } }><Badge count={ record.employee.profile_score + '% Completed' } style={ { background: 'white', color: colors[record.employee.profile_score_class], fontSize: 12, borderColor: colors[record.employee.profile_score_class] } } /></p>
                        <p className="card-icon"><EyeOutlined /></p>
                        <img className="card-img" src={ record.employee.profile_pic_url !== "images/arnold-avatar.jpg" ? record.employee.profile_pic_url : "/images/arnold-avatar.jpg" } alt="Employee Not Fond" />
                        <p className="card-employee-name" style={ { fontWeight: 700 } }>{ record.employee_name }</p>
                        <p className="card-employee-department" style={ { fontWeight: 700 } }>{ record.designation_name.substring(0, 17) }</p>
                        <div className="cards1">
                          <p><span className="card-employee-data">Emp ID</span><span style={ { position: "absolute", right: 35, marginTop: 5 } }> { record.code }</span></p>
                          <p><span className="card-employee-datas">Grade</span><span style={ { position: "absolute", right: 35 } }> { record.grade_name }</span></p>
                          <p><span className="card-employee-datas">Joining</span><span style={ { position: "absolute", right: 35 } }> { moment(record.joining_date).format('DD-MMM-YYYY') }</span></p>
                          <p><span className="card-employee-datas">Dept.</span><span style={ { position: "absolute", right: 35 } }> { record.department_name }</span></p>
                          <p><span className="card-employee-datas">Branch</span><span style={ { position: "absolute", right: 35 } }> { record.branch_name }</span></p>
                        </div>
                      </Card>
                    </div>
                  </Col>
                )) }
              </Row>
            </Spin>
          </div>
        ) }

        { childComponent }
      </BodyComponent>
      <PaginationComponent pagination={ pagination } modkey={props.modkey} setCurrentPage={ setCurrentPage } currentPage={ currentPage } />
      <Drawer
        // title={ (
        //   <div>
        //     <LeftButton
        //       style={ {
        //         fontSize: '16px',
        //         marginLeft: '200px',
        //         cursor: 'pointer',
        //       } }
        //       onClick={ handleLeftArrowClick }
        //     />
        //     <RightButton
        //       style={ {
        //         fontSize: '16px',
        //         marginLeft: '10px',
        //         cursor: 'pointer',
        //       } }
        //       onClick={ handleRightArrowClick }
        //     />
        //     <MessageButton
        //       style={ {
        //         fontSize: '16px',
        //         position: 'absolute',
        //         right: 20,
        //         cursor: 'pointer',
        //       } }
        //     />
        //     <StopsButton
        //       style={ {
        //         fontSize: '16px',
        //         position: 'absolute',
        //         right: 80,
        //         cursor: 'pointer',
        //       } }
        //     />
        //   </div>
        // ) }
        placement="right"
        onClose={ onCloseDrawer }
        open={ drawerVisible }
        width={ 615 }
      >
        <div style={ { overflow: "hidden" } }>
          <div style={ { marginTop: 60 } }>
            { viewEmployeeData }
          </div>
        </div>
      </Drawer>
    </>
  )
}


// ==================================
//   Component JSX End
// ==================================
export default IndexEmployees

const filterFields = [
  {
    text: 'Full Name',
    type: "select",
    placeholder: trans(`${langKey}.select.employee`),
    key: "employees",
    data_key: "supporting_data.employees",
    //is_resource: true
    resource_type: 'employment'
  },
  {
    type: "select",
    placeholder: trans(`${langKey}.selectlocation`),
    key: "location",
    data_key: "organization_structure_data.location",
  },
  {
    type: "select",
    placeholder: trans(`${langKey}.selectbranch`),
    key: "branch",
    data_key: "organization_structure_data.branch",
  },
  {
    type: "select",
    placeholder: trans(`${langKey}.selectdepartment`),
    key: "department",
    data_key: "organization_structure_data.department",
      filterKey: "hierarchy_code",
      multiple:true
  },
  {
    type: "select",
    placeholder: trans(`${langKey}.selectgrades`),
    key: "grade",
    data_key: "supporting_data.grade",
  },
  {
    type: "select",
    placeholder: trans(`${langKey}.selectdesignation`),
    key: "designation",
    data_key: "supporting_data.designation",
  },
  {
    type: "select",
    placeholder: trans(`${langKey}.selectGender`),
    key: "gender",
    data_key: "supporting_data.gender",
  },
  {
    type: "select",
    placeholder: trans(`${langKey}.islinemanager`),
    key: "is_line_manager",
    data_key: "supporting_data.is_line_manager",
  },
  {
    type: "select",
    placeholder: trans(`${langKey}.ishod`),
    key: "is_hod",
    data_key: "supporting_data.is_hod",
  },
  {
    type: "select",
    placeholder: trans(`${langKey}.isfinalauthority`),
    key: "is_final_authority",
    data_key: "supporting_data.is_final_authority",
  },
  {
    type: "select",
    placeholder: trans(`${langKey}.selectjobtitle`),
    key: "job_title",
    data_key: "supporting_data.job_titles",
  },
  {
    type: "select",
    placeholder: trans(`${langKey}.selectemployeetype`),
    key: "employee_type",
    data_key: "supporting_data.employee_types",
  },
  {
    type: "select",
    placeholder: trans(`${langKey}.selectemploymenttype`),
    key: "contract_type",
    data_key: "supporting_data.employment_types",
  },
  {
    type: "text",
    placeholder: "CNIC",
    key: "cnic",
  },

]