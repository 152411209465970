
import React, { useContext, useState } from "react";
import MUIDataTable from "mui-datatables";
import { Skeleton } from 'antd'
import { makeRequestStateless } from "@utils/helpers";
import { useLocation, useParams } from 'react-router-dom';
import { BodyComponent, HeaderComponent, CreateButton } from '@comps/components'
import { useNavigate } from 'react-router-dom';
import { saveAs } from 'file-saver';
import { DownloadOutlined } from '@ant-design/icons'
import { TableContext } from './TableContext';
const BaseMuiDataTable = (props) => {

const { tableData } = useContext(TableContext);
const { data, columns } = tableData;



  const navigate = useNavigate()
  //use states start

  //const [columns, setColumns] = useState([])
  const [dataSource, setDataSource] = useState([])

  const [loading, setLoading] = useState(true)
 
  const [hiddenColumns, setHiddenColumns] = useState([])
  const [visibleColumns, setVisibleColumns] = useState([])

  setDataSource(data);
  
  const options = {
    //filterType: 'checkbox',
    selectableRows: 'none',
    filter: true,
    download: true,
    // onColumnViewChange: (changedColumn, action) => {
    //   const column = columns.filter(col => col.name === changedColumn)
    //   if (action === 'add' && !columns.includes(column[0])) {
    //     setColumns([...columns, changedColumn])
    //   } else if (action === 'remove' && columns.includes(column[0])) {
    //     setColumns(columns.filter(col => col.name !== changedColumn))
    //   }
    // },
    downloadOptions: {
      filename: `export-table-data`, // Set your custom file name here
      //columns: columnsToDownload,
    },
  };


  return (
    <BodyComponent>
      <HeaderComponent modkey={props.modkey}>
        <CreateButton  text='Back' />

      </HeaderComponent>
      <Skeleton loading={ loading }>
        <MUIDataTable
          title={ 'data' }
          data={ dataSource }
          columns={ [] }
          options={ options }
        />
      </Skeleton>
    </BodyComponent>
  );
};

export default BaseMuiDataTable;