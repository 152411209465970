

import { get, post } from "@utils/axios";

export const getEmploymentExcludeId = (id) => {
  return get(`resources/employment?page=1&filters=exclude_ids_|${id}`);
};

export const createConsentReq = (payload) => {
  return post(`${payload.api}`, payload);
};
export const createMessageConsentReq = (payload) => {
  return post(`${payload.api}`, payload);
};

export const approveCancelRequestReq = (payload) => {
  return post(`cancelRequestApprovals/approveRequest?page=1&filters=${payload.filters}`, payload)
};

