import { useEffect, useState } from "react";
import { Commons, Const, colors } from "./constants";

import { CancelButton, ModalComponent, trans, TableComponent, EmployeeWithProfile } from "@comps/components"
import { Badge } from "antd";
const ViewFlow = (props) => {
  const langKey = Const["viewFlow"].lngKey
  const [dataSource, setDataSource] = useState([]);
  useEffect(() => {
    setFormData();
  }, []);

  const setFormData = () => {
    if (!props) {
      return;
    }
    if (props.record.flow) {
      setDataSource(props.record.flow);
    }
  }

  const footer = [
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  const columns = [
    {
      key: 'requestee',
      title: trans(`${langKey}.requestee`),
      render: (record) => {
        return <EmployeeWithProfile record={ record } />
      },
    },
    {
      key: 'identifier',
      title: trans(`${langKey}.identifier`),
      dataIndex: "identifier",
    },
    {
      key: 'status',
      title: trans(`${langKey}.status`),
      render: (record) => {
        return <Badge
          count={ record.status ? record.status : 'none' }
          style={ { background: colors[record.status_class], color: colors[record.status_class + '-color'] } }
        />
      }
    },
  ];
  return (
    <ModalComponent width={ 600 } maskClosable={ false }
      mainTitle={ trans(Commons.view) } subTitle={ trans(`${langKey}.flow`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) } disabled={ props.disabled } loader={ true }>
      <TableComponent
        columns={ columns }
        dataSource={ dataSource }
        pagination={ false }
      />
    </ModalComponent>
  )
}

export default ViewFlow