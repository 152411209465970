import React, { useEffect, useState } from "react";
import { Col, Form, Row, Skeleton, Space, message } from "antd";
import { SaveButton, InputTextArea, CancelButton, ButtonComponent, ModalComponent, trans } from "@comps/components"
import { makeRequest } from "@utils/helpers";
import { getFormulasReq } from "../requests";
import { Commons, Const } from "../../../constants";
const langKey = Const["expression"].lngKey
const formName = "createExpression";

const CreateExpression = (props) => {
  const [loader, setLoader] = useState(false);
  const [saving, setsaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const [formulas, setFormulas] = useState([]);
  const [payitems, setPayitems] = useState([]);
  const [rules, setRules] = useState([])
  let [exp, setExpression] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [form] = Form.useForm()
  useEffect(() => {
    getFormulas()
    // eslint-disable-next-line
  }, []);

  const getFormulas = () => {
    const payload = {
      options: props.options
    }
    makeRequest(setLoader, getFormulasReq, payload, onGetFormulas, onErrorGettingFormula);
    setExpression(props.record)
    setIsValid(props.isValid)
    setLoading(false)
  }
  const onGetFormulas = (response) => {
    if (response) {
      setFormulas(response.formulas)
      setPayitems(response.payitems)
      setRules(response.rules)
    }
  }
  const onErrorGettingFormula = (err, res) => {

  }
  const onSubmit = (data) => {
    if (exp) {
      props.onCompleted(exp, isValid)
    }
    else {
      message.info('Please Type Formula')
    }

  }

  const addToFormula = (tag, action) => {
    validateExpression(tag, action)
  }
  const validateExpression = (tag, action) => {

    if (action === 'adding') {
      exp += tag
    }
    if (action === 'typing') {
      exp = ''
      exp = tag
    }
    var expression = exp;

    if (expression) {
      var tags = expression.match(new RegExp("\{(.+?)\}", "g"));
      if (tags && tags.length > 0) {
        if (props.options.formulas) {
          formulas.forEach(function (col, index) {
            if (expression.indexOf(col.tag) >= 0) {
              expression = expression.split(col.tag).join("1");
            }
          });
        }
        if (props.options.payitems) {
          payitems.forEach(function (col, index) {
            if (expression.indexOf(col.tag) >= 0) {
              expression = expression.split(col.tag).join("1");
            }
          });
        }
        if (props.options.rules) {
          rules.forEach(function (col, index) {
            if (expression.indexOf(col.tag) >= 0) {
              expression = expression.split(col.tag).join("1");
            }
          });
        }
      }

      expression = expression.replace(/\[.+?\]/g, '');
      expression = expression.replace(/\'.+?\'/g, '1');
      expression = expression.replace(/\".+?\"/g, '1');
      try {
        var result = eval(expression);
        // var parser = math.parser();
        // var result = parser.eval(expression);
        // var result = eval(expression);
        if (!isNaN(result) && result !== Infinity && result != NaN) {
          setIsValid(true)

        } else {
          setIsValid(false)
        }

      } catch (e) {
        setIsValid(false)
      }
    }
    setExpression(exp)
  }

  //-----------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [
    <SaveButton disabled={ isValid ? false : true } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } width={ 1600 } maskClosable={ false }
      mainTitle={ trans(Commons.create) } subTitle={ trans(`${langKey}.expression`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Skeleton loading={ loading }>
        <Form name={ formName } form={ form } onFinish={ onSubmit } >

          <Row gutter={16}>

            {/* rules */ }

            {
              props.options.rules ?
                <Col lg={ 4 } sm={ 24 } xs={ 24 } style={ {
                  maxHeight: '450px',
                  overflowY: 'scroll',
                  paddingLeft: '20px',
                } } className="border overflow-y-scroll">
                  <h6 className="mt-2">
                    { trans(`${langKey}.rules`) }</h6>
                  <span>
                    {
                      rules.map(each => (
                        <li>
                          <Space>
                            <ButtonComponent onClick={ () => addToFormula(each.tag, 'adding') }>{ each.label }</ButtonComponent>
                          </Space>
                        </li>
                      ))
                    }
                  </span>
                </Col>
                : ''
            }

            {/* rules */ }

            {
              props.options.formulas ?

                <Col lg={ 4 } sm={ 24 } xs={ 24 } style={ {
                  maxHeight: '450px',
                  overflowY: 'scroll',
                  paddingLeft: '20px'
                } } className="border " >
                  <div >
                    <h6 className="mt-2">{ trans(`${langKey}.formulas`) }</h6>
                    <span>
                      {
                        formulas.map(each => (
                          <li>
                            <Space className="mb-2">
                              <ButtonComponent onClick={ () => addToFormula(each.tag, 'adding') }>{ each.label }</ButtonComponent>
                            </Space>
                          </li>
                        ))
                      }
                    </span>
                  </div>
                </Col>

                : '' }

            <Col lg={ 15 } sm={ 24 } xs={ 24 } className="border">
              <h6 className="mt-2">{ trans(`${langKey}.expression`) }</h6>
              <Form.Item name="expression"
                className="da-mb-16"
              >
                <label className="d-none">.</label>
                <InputTextArea style={ { minHeight: "15vh",fontSize:'25px' } } value={ exp } onChange={ (e) => validateExpression(e.target.value, 'typing') } placeholder="Type or add Formula here" className={ isValid ? 'text-success' : 'text-danger' } />
              </Form.Item>

              <h6 className="mt-2">{ trans(`${langKey}.preview`) }</h6>

              <p>
                { exp }
              </p>

            </Col>


            {/* payitems */ }

            {
              props.options.payitems ?
                <Col lg={ 4 } sm={ 24 } xs={ 24 } style={ {
                  maxHeight: '450px',
                  overflowY: 'scroll',
                  paddingLeft: '20px',
                } } className="border overflow-y-scroll">
                  <h6 className="mt-2">
                    { trans(`${langKey}.payitems`) }</h6>
                  <span>
                    {
                      payitems.map(each => (
                        <li>
                          <Space className="mb-2">
                            <ButtonComponent onClick={ () => addToFormula(each.tag, 'adding') }>{ each.label }</ButtonComponent>
                          </Space>
                        </li>
                      ))
                    }
                  </span>
                </Col>
                : ''
            }




          </Row>
        </Form>
      </Skeleton>
    </ModalComponent>
  )
}

export default CreateExpression


