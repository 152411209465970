import React, { useEffect, useState } from "react";
import { Col, Form, Skeleton, Row, message } from "antd";
import {
  SelectWithSearch,
  SaveButton,
  CancelButton,
  ModalComponent,
  trans,
  InputText, AddNewButton
} from "@comps/components";
import {
  makeRequest,
  makeRequestStateless,
  getErrorProps,

} from "@utils/helpers";
import { createSelectList } from "@comps/commonFunctions";
import { createAreaReq, getCompanyReq, getLocationsAndManagersByCompanyIdReq } from "../requests";

import { Commons, Const } from "../../constants";
import CreateLocation from '../../locations/components/CreateLocation';

const langKey = Const["area"].lngKey;

const formName = "createArea";

const CreateAreas = (props) => {
  const [loader, setLoader] = useState(false);
  const [saving, setsaving] = useState(false);
  const [loading, setloading] = useState(true);
  const [errors, setErrors] = useState([]);
  const [managers, setManagers] = useState([]);
  const [locations, setLocations] = useState([]);
  const [companyId, setCompanyId] = useState(null);
  const [childComponent, setChildComponent] = useState(null)
  const [form] = Form.useForm()

  useEffect(() => {
    getRemoteData()
    // eslint-disable-next-line
  }, []);

  const getRemoteData = () => {
    makeRequestStateless(
      getCompanyReq,
      false,
      onCompanySuccess,
      onCompanyError
    );
  };

  const onCompanySuccess = (data) => {
    setCompanyId(data.id)
    const companyId = data.id;
    makeRequestStateless(
      getLocationsAndManagersByCompanyIdReq,
      companyId,
      onLocationAndManagerSuccess,
      onLocationAndManagerError
    );
  }
  const onCompanyError = () => {
    //todo
  }
  const onLocationAndManagerSuccess = (res) => {
    setloading(false)
    setManagers(createSelectList(res.employments))
    setLocations(createSelectList(res.locations))
  }

  const onLocationAndManagerError = (res) => {
    message.error("Error getting Locations and Managers")
  }

  const onSubmit = (data) => {
    setsaving(true)
    if (!companyId) {
      message.error('please create Company first!')
      return;
    }
    const payload = { name: data.name, location_id: data.location, manager_id: data.manager }
    makeRequest(setLoader, createAreaReq, payload, onSuccess, onError);
  };

  const onSuccess = (data, res) => {
    setsaving(false)
    message.success("Area Created Successfully!!!")
    props.onCompleted(data.areas.data);
    props.onSuccess(data)

  };

  const onError = (err, res) => {
    setsaving(false)
    message.error(res.response.data.message);
    //setErrors(errorList);
  };
  //when location created
  const onLocationCreated = (data) => {
    if (data.length) {

      setLocations(createSelectList(data))
      form.setFieldValue('location', data[0].id)
    }
    setChildComponent(null);
  };

  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------
  const footer = [
    <SaveButton loading={ saving } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];
  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------
  return (
    <ModalComponent
      width={ 1000 }
      top={ 20 }
      maskClosable={ false }
      mainTitle={ trans(Commons.create) }
      subTitle={ trans(`${langKey}.area`) }
      visible={ true }
      footer={ footer }
      onCancel={ () => props.onCompleted(false) }
    >
      { childComponent }
      <Skeleton loading={ loading }>
        <Form form={ form } layout="vertical" name={ formName } onFinish={ onSubmit }>
          <Row gutter={ 30 }>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item
                name="name"
                rules={ rules.name }
                label={ trans(`${langKey}.name`) }
                className="da-mb-16"
                { ...getErrorProps(errors["name"]) }
                size="small"
              >
                <InputText placeholder={ trans(`${langKey}.name`) } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item
                name="manager"
                label={ trans(`${langKey}.selectManager`) }
                className="da-mb-16"
              >
                <SelectWithSearch
                  className="da-mb-16"
                  placeholder={ trans(`${langKey}.selectManager`) }
                  options={ managers }
                />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <AddNewButton text="Location" onClick={ () => setChildComponent(<CreateLocation onCompleted={ onLocationCreated } />) } />
              <Form.Item
                name="location"
                label={ trans(`${langKey}.selectLocation`) }
                rules={ rules.location }
                className="da-mb-16"
                { ...getErrorProps(errors["location"]) }
              >
                <SelectWithSearch options={ locations } placeholder={ trans(`${langKey}.selectLocation`) } />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Skeleton>
    </ModalComponent>
  );
};

export default CreateAreas;

const rules = {
  name: [{ required: true, message: trans(`${langKey}.name.required`) }],
  location: [
    { required: true, message: trans(`${langKey}.location.required`) },
  ],
};
