
import React, { useCallback } from "react";
import { useEffect, useState } from "react";
import {
  SelectWithSearch
} from "@comps/components";
import { makeRequest } from "@utils/helpers";
import { getResourceDataReq, createSelectList, encryptLocalData, decryptLocalData } from '@comps/commonFunctions';
import { Spin } from 'antd';
import { useSelector } from "react-redux";
import _ from "lodash";

const BaseSelectTrashedResource = (props) => {
  const authUser = useSelector((state) => state.auth.authUser);
  const [options, setOptions] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    getOptions()
  }, [])


  const getOptions = (searchString) => {
    let exclude_ids = '';
    if (props.exclude_ids) {
      const excludeids = props.exclude_ids.join(',');
      exclude_ids = `exclude_ids_|${excludeids}`;
    }
 
    //check exlude ids
    let payload = {
      page: 1,
      type: '',
      filters:"trashed_type_|onlyTrashed;"+ extraFilters()
    }

    const previousOptions =extraFilters()?decryptLocalData('initialTrashedEmployees'): decryptLocalData('initialTrashedEmployees')
    const defaultValue = props.defaultValue;
    const value = props.value;
    if (value && !searchString) {
      payload = {
        page: 1,
        type: props.type,
        filters:"trashed_type_|onlyTrashed;"+ `ids_|${value};${exclude_ids};${extraFilters()}`
      }
      sendRequest(payload)
      return
    }
    if (previousOptions && defaultValue && !searchString) {
      setOptions(previousOptions)
      return;
    }
    payload = {
      page: 1,
      type: props.type,
      filters:"trashed_type_|onlyTrashed;"+ `search_|${searchString};${exclude_ids};${extraFilters()}`
    }
    if (!searchString && options.length > 0) {
      setLoader(false)
    }
    else {
      sendRequest(payload)
    }

  };

  const findOption=(filters)=>{
  
  }
  const sendRequest = (payload) => {
    makeRequest(setLoader, getResourceDataReq, payload, onSuccess, onError);
  }
  const onSuccess = (response) => {

    if (props.module === 'header') {
      props.setallemployees(response.resources.data)
    }
    const newOptions = createSelectList(response.resources.data)

    encryptLocalData('initialEmployees', newOptions)

    

    setOptions(newOptions)
    setLoader(false)
  }
  const onError = (err, res) => {

  }

  const onClear=()=>{
    
   const payload = {
      page: 1,
      type: props.type,
      filters:"trashed_type_|onlyTrashed;"
    }
    sendRequest(payload)
  }
  const onSearch=(value)=>{
    debouncedGetEmployees(value)
  }
  const debouncedGetEmployees = useCallback(
    _.debounce((value) => {
      getOptions(value);
    }, 500),
    [] // Empty array ensures the function is created only once
  );
  // const onFocus=()=>{
  //  const payload = {
  //     page: 1,
  //     type: props.type,
  //     filters:"trashed_type_|onlyTrashed;"+ ``
  //   }
  //   sendRequest(payload)
  // }
  const isEmployeeDisabled=()=>{
    const module=localStorage.getItem('modulekey');
      if(module && (module==='leaveRequest' || module.includes('my'))){
        return true;
      }
      else{
        return false;
      }
  }
  
  const extraFilters=()=>{
    const module=localStorage.getItem('modulekey');
    if(props.activesOnly){
      return '';
    }
    if(props.subordinates){
      return `subordinate_|${authUser.employment_id}`;
    }
      if(module && module.includes('subordinat')){
        const filt=`subordinate_|${authUser.employment_id}`;
        return filt;
      }
      if(module && module.includes('endOf')){
        const filt=`trashed_type_|onlyTrashed`;
        return filt;
      }

      else{
        return '';
      }
  }
  return (
    <>
      <Spin spinning={ loader }>
        <SelectWithSearch
          { ...props }
          value={ props.value }
          loading={ loader }
          placeholder={ 'Search Employee with name or code' }
          options={ options }
          onChange={ (id) => props.onChange(id) }
          onSearch={ onSearch }
          maxTagCount={ 'responsive' }
          allowClear
        onClick={ () => getOptions('') }
        onClear={onClear}
        disabled={isEmployeeDisabled()}
        />
      </Spin>

    </>
  )
};

export default BaseSelectTrashedResource;
