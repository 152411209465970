import React, { useEffect, useState } from "react";
import { Form, message, Skeleton } from "antd";
import { SaveButton, InputCheck, SelectResourceList, CancelButton, ModalComponent, trans } from "@comps/components"
import { makeRequest, makeRequestStateless, } from "@utils/helpers";
import { getRelatedDataReq, updateDataReq } from "../requests";
import { Commons, Const } from "../../constants";
import { createSelectList } from '@comps/commonFunctions';
import { getEmploymentsDataReq } from "@mods/common/requests";
import ViewPrivilegeFilters from "./ViewPrivilegeFilters";
import CreatePrivilegeFilters from "./CreatePrivilegeFilters";
import UpdatePrivilegeFilters from "./UpdatePrivilegeFilters";

const langKey = Const["privilege"].lngKey
const formName = "UpdatePrivilege";

const UpdatePrivilege = (props) => {
  const [loader, setLoader] = useState(false);
  const [saving, setsaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const [employments, setEmployments] = useState([])
  const [relatedData, setRelatedData] = useState('')
  const [allPriviliges, setAllPrivileges] = useState(false);
  const [childComponent, setChildComponent] = useState(null)
  const [privilegeFilters, setPrivilegeFilters] = useState([])
  const [deletedRows, setDeletedRows] = useState([])

  const [form] = Form.useForm()
  useEffect(() => {
    getData()
    // eslint-disable-next-line
  }, []);

  const getData = () => {

    getEmployments()
    getRelatedData()
    setAllPrivileges(props.record.all_privileges)
    setPrivilegeFilters(props.record.criterias)
    if (props.record.criterias.length > 0) {
      props.record.criterias.forEach(each => (
        each.type_name = each.type ? 'Employees' : "Organization"
      ))
    }
    form.setFieldsValue({
      employment_id: props.record.employment_id,
      all_privileges: props.record.all_privileges,
      privilege_filters: props.record.criterias
    })
  }
  const getRelatedData = () => {
    makeRequestStateless(getRelatedDataReq, null, onGetRelated, false)
  }
  const onGetRelated = (response, data) => {
    setRelatedData(response)
  }
  const getEmployments = () => {
    const payload = { filters: null }
    makeRequestStateless(getEmploymentsDataReq, payload, onGetEmployments, false)
  }
  const onGetEmployments = (response) => {
    setLoading(false)
    setEmployments(createSelectList(response.resources.data))
  }

  const onSubmit = (data) => {

    if (allPriviliges && data.privilege_filters !== undefined && data.privilege_filters.length < 1) {
      message.info('Please Add Criteria!');
      return
    }
    const criterias = []
    if (data.privilege_filters !== undefined) {

      data.privilege_filters.forEach(each => {
        if (each.id === undefined) {
          each.row_state = 'Added'
        }
        if (each.id !== undefined) {
          each.row_state = 'Updated'
        }
        criterias.push(each)
      })

      deletedRows.forEach(row => {
        criterias.push(row)
      })
    }
    setsaving(true)

    const payload = {
      id: props.record.id, privilege: {
        ...props.record,
        employment_id: data.employment_id,
        privilege_filters: criterias,
        all_privileges: allPriviliges
      }
    };

    makeRequest(setLoader, updateDataReq, payload, onSuccess, onError);
  }

  const onSuccess = (data, res) => {

    message.success(res.message);
    props.onCompleted(data.privileges.data);
  }

  const onError = (err, res) => {

    setsaving(false)
    message.error(res.response.data.message);
  }
  // ==================================slabs actions =================================
  const onCreate = () => {
    setChildComponent(<CreatePrivilegeFilters onCompleted={ onCompleted } allData={ relatedData } employments={ employments } />)
  }

  //edit slab
  const onEdit = (key) => {
    const record = form.getFieldValue(`privilege_filters`)[key];
    setChildComponent(<UpdatePrivilegeFilters onCompleted={ onCompleted } allData={ relatedData } record={ { ...record, key: key } } disabled={ false } employments={ employments } />)
  };
  const onView = (key) => {
    const record = form.getFieldValue(`privilege_filters`)[key];
    setChildComponent(<UpdatePrivilegeFilters onCompleted={ onCompleted } allData={ relatedData } record={ { ...record, key: key } } disabled={ true } employments={ employments } />)
  };
  //set deleted rows
  const setRows = (key, state) => {
    if (privilegeFilters[key] !== undefined && privilegeFilters[key].id !== undefined && state === 'Deleted') {
      privilegeFilters[key].row_state = 'Deleted'
      deletedRows.push(privilegeFilters[key])
    }
  }
  //on submit slabs
  const onCompleted = (data, action) => {

    let listArray = form.getFieldValue('privilege_filters');
    if (listArray === undefined) {
      listArray = []
    }
    if (data && action === 'Added') {
      listArray.push(data)
      form.setFieldValue('privilege_filters', listArray)
      setPrivilegeFilters(listArray)
    }
    if (data && action === 'Updated') {

      listArray[data.key] = data
      form.setFieldValue('privilege_filters', listArray)
      setPrivilegeFilters(listArray)
    }
    setChildComponent(null)
  }

  //-----------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [
    !props.disabled &&
    <SaveButton loading={ saving } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } width={ 600 } maskClosable={ false }
      mainTitle={ trans(Commons[props.disabled ? 'view' : 'update']) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) } >
      { childComponent }
      <Skeleton loading={ loading }>
        <Form { ...formLayout } name={ formName } onFinish={ onSubmit } form={ form } disabled={ props.disabled }>

          <Form.Item name="employment_id" rules={ rules.employment_id } label={ trans(`${langKey}.employment_id`) }
            className="da-mb-16"

          >
            <SelectResourceList type='employment' value={ props.record.employment_id } />
          </Form.Item>
          <Form.Item name="all_privileges" rules={ rules.all_privileges } label={ trans(`${langKey}.all_privileges`) }
            className="da-mb-16"
            valuePropName="checked"
          >
            <InputCheck onChange={ (e) => setAllPrivileges(e.target.checked) } />
          </Form.Item>

          {
            !allPriviliges &&
            <>
              <div className="formlist-container">
                <h5 className="formlist-title">{ trans(`${langKey}.privilege_filters`) }</h5>
                <ViewPrivilegeFilters setRows={ setRows } onCreate={ onCreate } onEdit={ onEdit } onView={ onView } />
              </div>
            </>
          }



        </Form>
      </Skeleton>

    </ModalComponent>
  )
}

export default UpdatePrivilege

const rules = {
  employment_id: [
    { required: true, message: trans(`${langKey}.employment_id`) },
  ],

};

const formLayout = {
  // layout: "horizontal",
  labelCol: { span: 6 },
  wrapperCol: { span: 14, offset: 2 },
  labelAlign: "left"
}
