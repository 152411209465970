const rosterKey = "roster";
const baseRoute = "/eoemanagement/endofemployment";

export const Const = {

  reason: {
    lngKey: `${rosterKey}.reason`,
    route: `/eoemanagement/settings/reasons`,
  },
  timeslot: {
    lngKey: `${rosterKey}.timeslot`,
    route: ``,
  },
  holiday: {
    lngKey: `${rosterKey}.holiday`,
    route: ``,
  },
  restday: {
    lngKey: `${rosterKey}.restday`,
    route: ``,
  },
  myRoaster: {
    lngKey: `${rosterKey}.myRoaster`,
    route: ``,
  },
  companyRoaster: {
    lngKey: `${rosterKey}.companyRoaster`,
    route: ``,
  },


};

const cmn = "common";
export const Commons = {
  create: `${cmn}.create`,
  update: `${cmn}.update`,
  view: `${cmn}.view`,
  name: `${cmn}.name`,
  code: `${cmn}.code`,
  location: `${cmn}.location`,
  branch: `${cmn}.branch`,
  department: `${cmn}.department`,
  designation: `${cmn}.designation`,
  joiningDate: `${cmn}.joiningDate`,
  grade: `${cmn}.grade`,
  ProfileCompletion: `${cmn}.ProfileCompletion`,
  actions: `${cmn}.actions`,
  is_active: `${cmn}.is_active`,
  status: `${cmn}.status`,
  description: `${cmn}.description`,
  shortname: `${cmn}.shortname`,
  country: `${cmn}.country`,
};
