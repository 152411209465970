
import React, { useEffect, useState } from "react";
import { Button, Form, Steps, message, Space, Row, Skeleton } from "antd";
import { SaveButton, ModalComponent, trans, SelectMultiWithSearch } from "@comps/components"
import { makeRequest, makeRequestStateless } from "@utils/helpers";
import { getCompanyRelatedDataReq } from "@mods/common/requests";
import { applyLeaveTypeReq, getPreviewDataReq } from '../requests'
import { Commons, Const } from "../../../constants";
import FilterSelectEmployees from "@mods/common/FilterSelectEmployees"
import { createSelectList } from '@comps/commonFunctions';
import Preview from "@mods/common/Preview";
//const moment = require('moment');
const langKey = Const["leaveType"].lngKey
const formName = "applyLeaveType";

const ApplyLeaveType = (props) => {

  //========initial child states===============
  const [preEmployeesFilter, setPreEmployeesFilter] = useState({})
  const [unSelectedEmployees, setUnSelectedEmployees] = useState([])
  const [selectedEmployees, setSelectedEmployees] = useState([])
  //============================
  const [loader, setLoader] = useState(false);
  const [saving, setsaving] = useState(false)
  const [loading, setloading] = useState(true)
  const [companyRelatedData, setCompanyRelatedData] = useState([]);
  const [selectedEmployments, setEmployments] = useState([]);
  const [previewData, setPreviewData] = useState([]);
  //------------intitial steps state---------
  const [firstStepData, setFirstStepData] = useState(null);
  const [currentStep, setCurrent] = useState(0);
  const [leaveTypes, setLeaveTypes] = useState([])
  const [empIds, setEmpIds] = useState([])
  useEffect(() => {
    getRemoteData()
    setData()
    // eslint-disable-next-line
  }, []);
  const getRemoteData = () => {
    makeRequestStateless(getCompanyRelatedDataReq, null, ongetCompanyRelatedDataReq, null);
  }
  const ongetCompanyRelatedDataReq = (response) => {
    setCompanyRelatedData(response)
    setloading(false)
  }

  const setData = () => {
    if (!props) {
      return;
    }
    setLeaveTypes(createSelectList(props.record))
  }
  //=================Submit First Step 1==============================================
  const stepOne = () => {
    return (
      <>
        <Form { ...formLayout } name={ formName } onFinish={ onSubmitFirstStep } initialValues={ firstStepData } scrollToFirstError>
          <Form.Item label={ 'Leave type' } name='leave_type_ids'
            rules={ rules.leave_type_id }>
            <SelectMultiWithSearch
              options={ leaveTypes }
              placeholder={ trans(`${langKey}.leave_type_id`) }
            />
          </Form.Item>
          <Row justify="end">
            <Space>
              <Button className="button-next" type="primary" htmlType="submit">
                Next
              </Button>
            </Space>
          </Row>
        </Form>
      </>
    )
  }
  const onSubmitFirstStep = (data) => {
    setFirstStepData(data);
    setCurrent(currentStep + 1)
  }
  // ------------------------------------
  //=====================On Submit Second Step===========================
  const setSelectedEmploymentsIds = (employments) => {
    setEmpIds(employments)
    let payload = { leave_type_ids: firstStepData.leave_type_ids, employment_ids: employments.employment_ids };
    makeRequest(setLoader, getPreviewDataReq, payload, onGetPreview, null);
  }
  const onGetPreview = (response) => {
    setPreviewData(response)
    setCurrent(currentStep + 1)
  }
  const onCancel = () => {
    props.onCompleted(false);
  }
  const onBack = () => {
    setCurrent(currentStep - 1)
  }
  //==============================Save all Data=========================================
  const onSubmit = () => {
    const payload = {
      leave_type: { leave_type_ids: firstStepData.leave_type_ids, employment_ids: empIds.employment_ids }
    };
    makeRequest(setLoader, applyLeaveTypeReq, payload, onSuccess, onError);
    setsaving(true)
  }

  const onSuccess = (data, res) => {

    if (data.errors.length > 0) {
      message.error(`${data.errors[0].reason}`);
      return;
    }
    else {
      message.success("Applied successfully");
    }
    props.onCompleted(data);
  }
  const onError = (err, res) => {
    setsaving(false)
    if (res.message) {

      message.error(res.message);
    }

    message.error(res.response.data.message);
  }
  const footer = [];
  //================================Main Modal Component==================================
  return (
    <ModalComponent top={ 20 } width={ 1200 } maskClosable={ false }
      mainTitle={ trans(Commons.create) } subTitle={ <>
        <p style={ { paddingLeft: 75, marginTop: -30 } }>{ trans(`${langKey}.applyLeave`) }</p>
        <Steps
          className="steps-style"
          current={ currentStep }
          onChange={ setCurrent }
        >
          <Steps.Step className="steps-step1" title={ "Leave Type" } style={ { paddingLeft: 50 } } />
          <Steps.Step className="steps-step2" title={ "Select Employees" } disabled={ firstStepData ? false : true } />
          <Steps.Step className="steps-step3" title={ "Finalise" } disabled={ true } />
        </Steps>
      </> }
      visible={ true } onCancel={ () => props.onCompleted(false) } footer={ footer }>
      <Skeleton loading={ loading }>

        { currentStep === 0 ?
          stepOne()
          : currentStep === 1 ?
            <FilterSelectEmployees setSelectedEmploymentsIds={ setSelectedEmploymentsIds } companyRelatedData={ companyRelatedData } onCancel={ onCancel } onBack={ onBack }
              employment_ids={ selectedEmployments }
              moduleName='leaveType' selected={ selectedEmployees } unSelected={ unSelectedEmployees } preFilters={ preEmployeesFilter } setSelected={ setSelectedEmployees } setUnselected={ setUnSelectedEmployees } setPrefilters={ setPreEmployeesFilter }
            />
            : currentStep === 2 ?
              <Preview previewData={ previewData } /> : '' }
        {
          currentStep === 2 ?
            <Row justify="end">
              <Space>
                <Button className="button-back" onClick={ () => setCurrent(currentStep - 1) } disabled={ currentStep > 0 ? false : true }>
                  <span style={ { color: "#4079FC" } }>Back</span>
                </Button>
                <SaveButton className="button-next" form={ formName } key="create_button" onClick={ onSubmit } />
              </Space>
            </Row>
            : ''
        }
      </Skeleton>

    </ModalComponent>
  )
}

export default ApplyLeaveType
const rules = {
  leave_type_id: [
    { required: true, message: trans(`${langKey}.leave_type_id`) },
  ],

}

const formLayout = {
  // layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 14, offset: 2 },
  labelAlign: "left"
}