import React, { useState, useEffect,useRef } from 'react';
import { Row, Col, Spin } from 'antd';
import { makeRequest } from '@utils/helpers';
import { getGenderCountReq } from '../requests';
import "../dashboard.css"
import ReactApexChart from 'react-apexcharts';
import useInViewport from '../useInViewport';
const GenderWiseCount = () => {
  const [loader, setLoader] = useState(false);
  const [genderData, setGenderData] = useState([]);

  const viewRef = useRef(null);
  const viewport = useInViewport(viewRef);

  useEffect(() => {
    if(viewport && genderData.length===0){

      makeRequest(setLoader, getGenderCountReq, null, onSuccess, onError)
    }
  }
    , [viewport]);

  const onSuccess = (data) => {
    setGenderData(data);
  }

  const onError = (error) => {
  }

  const chartOptions = {
    // Define your chart options here
    chart: {
      type: 'donut',
    },
    series: [genderData.male_percentage, genderData.female_percentage],
    labels: ['<strong>Male</strong>', 'Female'],
    colors: ['#4079FC', '#EB7900']
  };

  return (
    <div className='widgetbox' ref={viewRef}>
      <Row>
        <Col span={ 24 }>
          <h3>Gender Diversity</h3>
        </Col>
      </Row>
      <div className='widgetcontent'>
        <Spin spinning={ loader } tip="Loading...">
          { genderData && genderData.male && (
            <>
              <ReactApexChart
                options={ chartOptions }
                series={ chartOptions.series }
                type="donut"
                width={ 350 }
              />
              <div className='genderdetails'>
                <h2 className='gendercount'>Total Active Employees { genderData.male + genderData.female }</h2>
                <Row className='gendertotalcount'>
                  <Col span={ 12 } className='gendertitle'>
                    Male Employees
                  </Col>
                  <Col span={ 12 } className='gendertotal'>
                    { genderData.male }
                  </Col>
                  <Col span={ 12 } className='gendertitle'>
                    Female Employees
                  </Col>
                  <Col span={ 12 } className='gendertotal'>
                    { genderData.female }
                  </Col>
                </Row>

              </div>
            </>
          ) }
        </Spin>
      </div>
    </div>
  );
}

export default GenderWiseCount;