import { get, del, post, postFd, put, putFd } from "@utils/axios";

const api = "leaveTypes";

export const getAllDataReq = (payload) => {
  return get(`${api}?page=${payload.page}&filters=${payload.filters}`)
};

export const createDataReq = (payload) => {
  return post(`${api}?`, payload);
};

export const updateDataReq = (payload) => {
  return put(`${api}/${payload.id}`, payload);
};

export const deleteDataReq = (id) => {
  return del(`${api}/${id}`);
};

export const getReleatedDataReq = (payload) => {
  return get(`${api}/get-related-data?`);
};
export const getPreviewDataReq = (payload) => {
  return post(`${api}/preview`, payload);
};
export const applyLeaveTypeReq = (payload) => {

  return post(`${api}/apply-leave-types`, payload);
};



export const getFilterReq = (payload) => {
  return get(`filters?company_id=1&fields=page=1&filters=supporting_fields_|is_active,gender,is_encashable,is_pro_rated,is_short,is_carry_forward,is_sandwich;preset_|leave_types;`)
}