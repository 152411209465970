import React, { useEffect, useState } from "react";
import { Form, message, Modal } from "antd";
import { SaveButton, InputText, InputTextArea, CancelButton, ModalComponent, trans, InputDate, UploadFile, UploadButton } from "@comps/components"
import { makeRequest, getErrorProps } from "@utils/helpers";
import { updateDataReq } from "../requests";
import { Commons, Const, Validations } from "../../../constants";
import dayjs from "dayjs";

const langKey = Const["accolade"].lngKey
const formName = "createAccolade";

const CreateAccolade = (props) => {
  const [loader, setLoader] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [form] = Form.useForm();
  useEffect(() => {
    setFormData();
    // eslint-disable-next-line
  }, []);

  const setFormData = () => {
    if (!props) {
      return;
    }

    props.record.accolade_date = props.record.accolade_date ? dayjs(props.record.accolade_date) : null;
    props.record.expiry_date = props.record.expiry_date ? dayjs(props.record.expiry_date) : null;
    setFileList(props.record.attachment ? [props.record.attachment
    ] : []);
    setCoverFileList(props.record.cover ? [props.record.cover
    ] : []);
    form.setFieldsValue(props.record);
  }

  const onSubmit = (data) => {
    setSaveLoader(true)
    if (data.file_attachment !== undefined && data.file_attachment !== null && data.file_attachment.url === undefined) {
      data.file_attachment.file = data.file_attachment.fileList.length > 0 ? data.file_attachment.file : null
    }
    if (data.file_cover !== undefined && data.file_cover !== null && data.file_cover.url === undefined) {
      data.file_cover.file = data.file_cover.fileList.length > 0 ? data.file_cover.file : null
    }

    data.id = props.record.id;
    const payload = { accolade: data };
    makeRequest(setLoader, updateDataReq, payload, onSuccess, onError);
  }

  const onSuccess = (data, res) => {
    setSaveLoader(false)
    message.success("Saved successfully");
    props.onCompleted(data.accolades.data);
  }

  const onError = (err, res) => {
    setSaveLoader(false)
    message.error(res.response.data.message);
  }

  //-----------------------
  //============uploading file============
  const [fileList, setFileList] = useState([]);
  const [coverFileList, setCoverFileList] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const handleCancel = () => setPreviewOpen(false);
  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };
  const onChangeCover = ({ fileList: newFileList }) => {
    setCoverFileList(newFileList);
  };
  const onPreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    //setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };
  //=========================================
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [
    !props.disabled &&
    <SaveButton loading={ saveLoader } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } width={ 600 } maskClosable={ false }
      mainTitle={ trans(Commons[props.disabled ? 'view' : 'update']) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Form { ...formLayout } form={ form } name={ formName } onFinish={ onSubmit } disabled={ props.disabled } scrollToFirstError>

        <Form.Item name="title" rules={ rules.title } label={ trans(`${langKey}.title`) }
          className="da-mb-16"
          { ...getErrorProps(errors['name']) } size="small"
        >
          <InputText placeholder={ trans(`${langKey}.name`) } />
        </Form.Item>

        <Form.Item name="accolade_date" rules={ rules.accolade_date } label={ trans(`${langKey}.accolade_date`) }
          className="da-mb-16"
          { ...getErrorProps(errors['accolade_date']) } size="small"
        >
          <InputDate format='YYYY-MM-DD' />
        </Form.Item>

        <Form.Item name="expiry_date" rules={ rules.expiry_date } label={ trans(`${langKey}.expiry_date`) }
          className="da-mb-16"
          { ...getErrorProps(errors['expiry_date']) } size="small"
        >
          <InputDate format='YYYY-MM-DD' />
        </Form.Item>

        <Form.Item label={ trans(`${langKey}.attachment`) } rules={ rules.attachment } name="file_attachment" >
          <UploadFile
            accept=".jpg,.png,.jpeg" listType="picture-card"
            fileList={ fileList }
            onChange={ onChange }
            onPreview={ onPreview }
            multiple={ false }
            beforeUpload={ (file) => {
              return false;
            } }
          >
            { fileList.length === 1 ? null : <UploadButton /> }
          </UploadFile>
        </Form.Item>

        <Form.Item label={ trans(`${langKey}.cover`) } rules={ rules.cover } name="file_cover" >
          <UploadFile
            accept=".jpg,.png,.jpeg" listType="picture-card"
            fileList={ coverFileList }
            onChange={ onChangeCover }
            onPreview={ onPreview }
            multiple={ false }
            beforeUpload={ (file) => {
              return false;
            } }
          >
            { coverFileList.length === 1 ? null : <UploadButton /> }
          </UploadFile>
        </Form.Item>

        <Form.Item name="description" rules={ rules.description } label={ trans(Commons.description) }
          className="da-mb-16"
          { ...getErrorProps(errors['description']) } size="small"
        >
          <InputTextArea placeholder={ trans(`${langKey}.description`) } />
        </Form.Item>

      </Form>

      <Modal open={ previewOpen } title={ previewTitle } footer={ null } onCancel={ handleCancel }>
        <img
          alt="example"
          style={ {
            width: '100%',
          } }
          src={ previewImage }
        />
      </Modal>
    </ModalComponent>
  )
}

export default CreateAccolade

const rules = {
  title: [
    { required: true, message: trans(Validations.title) },
  ],
  accolade_date: [
    { required: true, message: trans(`${langKey}.accolade_date.req`) },
  ],
  expiry_date: [
    { required: true, message: trans(`${langKey}.expiry_date.req`) },
  ],


};

const formLayout = {
  // layout: "horizontal",
  labelCol: { span: 6 },
  wrapperCol: { span: 14, offset: 2 },
  labelAlign: "left"
}