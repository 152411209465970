
import { List, Spin ,Row,Col} from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { convertTimeTotz } from "@comps/commonFunctions"
import { getTopNotificationsReq, viewNotificationReq } from "./requests";
import { makeRequest } from "@utils/helpers";

var selectedNotification=null;
const TopNotification=(props)=>{
  const [notificationFilters, setNotificationFilters] = useState('')
  const [notifications, setNotifications] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const navigate = useNavigate()
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    getNotifications('initial')
      }, [])
  useEffect(() => {
getNotifications()
  }, [currentPage])
    //===================notification start=======================================

    const getNotifications = (param) => {
      if(param==='initial'){
        setNotifications([]);
      }
      const payload = {
        page: currentPage,
        filters: notificationFilters
      }
      makeRequest(setLoader, getTopNotificationsReq, payload, onSuccessNotifications, onError);
  
    }
    const onSuccessNotifications = (response) => {
      if(response.notifications.data.length<1){
        setHasMore(false)
      }
      setNotifications((prevNotifications) => [...prevNotifications, ...response.notifications.data])
    }
    const onError = (res, err) => {
  
    }
  
    const handleScroll = (e) => {
      const { scrollTop, clientHeight, scrollHeight } = e.target;
      // Fetch more notifications if the user has scrolled to the bottom
      if (scrollTop + clientHeight === scrollHeight) {
        setCurrentPage((prevPage) => prevPage + 1);
      }
    };
  const onView=(item)=>{

    selectedNotification=item
  viewNotification(item)
  }
  const viewNotification=(item)=>{
      makeRequest(setLoader, viewNotificationReq, item.id, onSuccessView, onError);
  }
  const onSuccessView=(res)=>
  {
    const url=getUrl(selectedNotification.url)
    if(url){
      navigate(url)
    }
    props.onCompleted(null)
  }

  const getUrl = (sref) => {
    let url = sref ? sref.replace(/^app\./, '').replace(/_/g, '-').replace(/\./g, '/') : '';
    //remove extra
    if (url === "masters/general/accolades") {
      url = url.replace(/\/accolades/g, '');
    }
    if (url === "masters/system/users") {
      url = url.replace(/\/users/g, '');
    }
    if (url ==="app/payroll-management/payrolls/index") {
      url = url.replace('app/payroll-management/payrolls/index', 'app/payrollmanagement/payrolls');
    }
    //
    return url.replace('app','');
  }


    const renderNotificationItem = (item) => (
      <List.Item key={ item.id } className="bg-white" onClick={()=>onView(item)} style={{cursor:'pointer'}}>
        <Row>
          {
            item.title ? <Col span={5} style={{cursor:'pointer'}}>
            <img src={!item.image_url || item.image_url!=='images\/arnold-avatar.jpg'?item.image_url:"/images/arnold-avatar.jpg"} alt="" width={50} />
         
            </Col>:''
          }
         
          <Col span={19} className={item.is_opened?'text-black':'text-primary'} style={{cursor:'pointer'}}>
          <strong > { item.title }</strong><br/>
       <span > { item.message }</span><br/>
       <small ><i>{ convertTimeTotz(item.created_at).format('YYYY-MM-DD h:m a') }</i> </small>
          </Col>
        </Row>
      </List.Item>
    );

     return ( 
        <Spin spinning={loader}>
        <div style={ { maxHeight: '300px', overflowY: 'auto' } } onScroll={ handleScroll }>
          <List
            dataSource={ notifications }
            renderItem={ renderNotificationItem }
            loading={ loading }
            locale={ { emptyText: 'No notifications' } }
          />
          { loading && hasMore && (
            <div style={ { textAlign: 'center', marginTop: '16px' } }>
              <Spin />
            </div>
          ) }
          { !hasMore && notifications.length > 0 && (
            <div style={ { textAlign: 'center', marginTop: '16px' } }>
              All notifications loaded.
            </div>
          ) }
        </div>
          </Spin>
     )
}

export default TopNotification