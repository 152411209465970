import { get, post } from "@utils/axios";

const api = "leaveConfigurations";

export const getAllDataReq = (payload) => {
  return get(`${api}?page=1&filters=${payload}`)
};

export const createDataReq = (payload) => {
  return post(`${api}?`, payload);
};