import React, { useState } from "react";
import { Form, message, Divider } from "antd";
import {
  SaveButton, CancelButton, ModalComponent, trans,
  TableComponent,
  UploadFile
} from "@comps/components"
import { makeRequestStateless } from "@utils/helpers";
import { importMapperReq } from "../requests";
import { Commons, Const } from "../../../constants";
const langKey = Const["machines"].lngKey
const formName = "importMapper";

const ImportMapper = (props) => {
  const [childComponent, setChildComponent] = useState(null)
  const onSubmit = (data) => {

    if (data.attachment === undefined) {
      message.info("Please Upload Attachment!")
      return
    }
    if (data.attachment !== undefined) {
      data.attachment = data.attachment.fileList.length > 0 ? data.attachment.file : null
    }
    const payload = { machine_id: props.machine_id, attachment: data.attachment, mapper_import: {} };
    makeRequestStateless(importMapperReq, payload, onSuccess, onError);
  }


  const errorColumns = [
    {
      key: 'row',
      title: trans(`${langKey}.row`),
      dataIndex: "row",
    },
    {
      key: 'sheet',
      title: trans(`${langKey}.sheet`),
      dataIndex: "sheet",
    },
    {
      key: 'column',
      title: trans(`${langKey}.column`),
      dataIndex: "column",
    },
    {
      key: 'value',
      title: trans(`${langKey}.value`),
      dataIndex: "value",
    },
    {
      key: 'message',
      title: trans(`${langKey}.message`),
      dataIndex: "message",
    },
  ]
  const onSuccess = (data, res) => {
    if (data.errors.length === 0) {
      message.success(res.message);
      props.onCompleted(true)
      setChildComponent(null)
    }
    if (data.errors !== undefined && data.errors.length > 0) {
      setChildComponent(
        <>
          <Divider className="text-danger">Importing Errors</Divider>
          <TableComponent
            scroll={ { x: 'max-content' } }
            columns={ errorColumns }
            dataSource={ data.errors }
            pagination={ false }
          />
        </>
      )
      message.error('Not Imported');
    }
  }

  const onError = (err, res) => {

    message.error(res.response.data.message);
  }

  //============uploading file============
  const [fileList, setFileList] = useState([]);

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  //=========================================

  const footer = [
    <SaveButton form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 50 } width={ 600 } maskClosable={ false }
      mainTitle={ trans(Commons.view) } subTitle={ trans(`${langKey}.import`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>

      <Form name={ formName } onFinish={ onSubmit }>

        <Form.Item name="attachment" rules={ rules.attachment } label={ trans(`${langKey}.attachment`) }
          className="da-mb-16"
          size="small"
        >
          <UploadFile
            accept=".xlsx"
            listType="picture-card"
            fileList={ fileList }
            onChange={ onChange }
            //onPreview={ onPreview }
            multiple={ false }
            beforeUpload={ (file) => {
              return false;
            } }
          >
            { fileList.length === 1 ? null : 'Import' }
          </UploadFile>
        </Form.Item>
      </Form>

      { childComponent }
    </ModalComponent>
  )
}

export default ImportMapper


const rules = {
  attachment: [
    { required: true, message: trans(`${langKey}.attachment`) },
  ],

};