import { useState, useEffect } from "react";
import {
  BodyComponent,
  TableComponent,
  ActionComponent,
  trans,
  HeaderComponent,
  EmployeeWithProfile,
  PaginationComponent, CustomButton
} from "@comps/components";
import { makeRequest } from "@utils/helpers";
import { Commons, Const, colors } from "../../constants";
import { Badge, message, Popconfirm } from 'antd';
import { FilterComponent, FilterButton } from "@comps/components";
import { getAllDataReq, getFilterReq } from "./requests";
import dayjs from "dayjs";
import RequestAsTab from "@mods/common/RequestAsTab";
import ViewFlow from "@mods/common/ViewFlow";
import UpdateLendApproval from "./components/UpdateLendApproval";
import ViewApprovalHistory from "@mods/common/ViewApprovalHistory";
import { getRequestFilters } from '@mods/common/commonFilterFields';
import { approveBulkRequests } from "@mods/common/requests";

const langKey = Const["lendApproval"].lngKey;
const pageConfig = {
  headers: {
    title: trans(`${langKey}.moduleName`),
    breadcrumb: [
      {
        name: trans(`${langKey}.moduleName`),
        path: Const["lendApproval"].route
      }
    ]
  }
}

const IndexLendApprovals = (props) => {
  const [totalRecords, setTotalRecords] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [loader, setLoader] = useState(false);
  const [childComponent, setChildComponent] = useState(null);
  const [filerComponent, setFilterComponent] = useState(null);
  const [asFilters, setAsFilters] = useState([]);
  const [requestAs, setRequestAs] = useState(8)
  const [noOfNotifications, setNoOfnotifications] = useState(null)
  const [filters, setFilters] = useState();
  const [allData, setAllData] = useState({})
  const [delegator, setDelegator] = useState('')
  //start pagination states
  const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState({
    "total": 0,
    "count": 0,
    "per_page": 0,
    "current_page": 0,
    "total_pages": 0,
    "links": {}
  });
  //end pagination states

  // ==================================
  //   Use States End
  // ==================================

  // ================================== UpdateLocation
  //   Table Data End
  // ==================================

  const columns = [
    {
      key: 'code',
      title: trans(Commons.code),
      dataIndex: "code",
    },
    {
      key: 'employment_id',
      title: trans(`${langKey}.employment_id`),
      render: (record) => {
        return <EmployeeWithProfile record={ record.requestor } />
      },
    },
    {
      key: 'department_name',
      title: trans(`${langKey}.department_name`),
      render: (record) => {
        return record.requestor.department_name;
      }
    },
    {
      key: 'approved_amount',
      title: trans(`${langKey}.approved_amount`),
      render: (record) => {
        return record.approved_amount ? 'Rs ' + record.approved_amount : 'Rs ' + 0;
      }
    },
    {
      key: 'lend_type_name',
      title: trans(`${langKey}.lend_type_name`),
      render: (record) => {
        return record.lend_type_name;
      }
    },
    {
      key: 'effective_date',
      title: trans(`${langKey}.effective_date`),
      render: (record) => {
        return dayjs(record.effective_date).format('YYYY-MM-DD');
      }
    },
    {
      key: 'created_at',
      title: trans(`${langKey}.created_at`),
      render: (record) => {
        return dayjs(record.created_at).format('YYYY-MM-DD');
      }
    },

    {
      key: 'next_requestee',
      title: trans(`${langKey}.next_requestee`),
      render: (record) => {
        return <EmployeeWithProfile record={ record.next_requestee_name } />
      },
    },
    {
      key: 'status',
      title: trans(`${langKey}.status`),
      render: (record) => {
        return <Badge
          count={ record.status ? record.status : 'none' }
          style={ { background: colors[record.status_class], color: colors[record.status_class + '-color'] } }
        />
      }
    },

    {
      key: trans(Commons.actions),
      title: trans(Commons.actions),
      render: (record) => (
        <ActionComponent
          each={ record } modkey={props.modkey}
          onViewHistory={ (record.status === 'Completed' || record.status === 'Rejected' || record.status === 'Cancelled') ? onViewHistory : '' }
          onViewFlow={ record.flow.length !== 0 ? onViewFlow : '' }
          onEdit={ (record.status === 'Pending') ? onEdit : '' }
          onView={ onView }
        ></ActionComponent>
      ),
    },
  ];

  // ==================================
  //   Table Data End
  // ==================================

  useEffect(() => {
    getAllData();
    // eslint-disable-next-line
  }, [props, filters, requestAs, currentPage]);

  const getAllData = () => {
    let payload = {
      page: currentPage,
      filters: filters,
      data: { request_as: requestAs, delegator_id: delegator },
      api: props.api
    }
    if (props.api === 'lendDelegates' && !delegator) {
      setDataSource([])
      setAsFilters([]);
      onOpenFilter()
      return;
    }
    makeRequest(setLoader, getAllDataReq, payload, onSuccess, onError);
  };
  const onError = (err, res) => {
    message.error(res.response.data.message);
    setDataSource([]);
    setAsFilters([]);
    setAllData()
  }
  const onSuccess = (response) => {
    setNoOfnotifications(response.lends.length);
    setPagination(response.lends);
    setDataSource(response.lends.data);
    setAsFilters(response.filters);
    setAllData(response)
  };

  const onEdit = (record) => {
    setChildComponent(
      <UpdateLendApproval onCompleted={ onCompleted } record={ record } disabled={ false } requestAsId={ requestAs } allData={ allData } delegatorId={ delegator } api={ props.api } filters={ filters } />
    );
  };

  const onView = (record) => {
    setChildComponent(
      <UpdateLendApproval onCompleted={ onCompleted } record={ record } disabled={ true } requestAsId={ requestAs } allData={ allData } delegatorId={ delegator } api={ props.api } filters={ filters } />
    );
  };
  const onViewHistory = (record) => {
    setChildComponent(
      <ViewApprovalHistory onCompleted={ onCompleted } record={ record } disabled={ true } />
    );
  };
  const onViewFlow = (record) => {
    setChildComponent(
      <ViewFlow onCompleted={ onCompleted } record={ record } disabled={ true } />
    );
  };

  const onCompleted = (data) => {
    if (data.length) {
      setDataSource(data);
    }
    setChildComponent(null);
  };

  const handleTableChange = (page, fil, sorter) => {
    let payload = {
      ...pagination,
      current: page.current,
      pageSize: page.pageSize,
      sortName: sorter.field || "id",
      sortType: sorter.order === "ascend" ? "asc" : "desc",
    };
    setPagination(payload);
  };

  //---------------filters-----------------------
  const onOpenFilter = () => {
    setFilterComponent(<FilterComponent filters={ filterFields } modkey={props.modkey} selected={ filters } api={ getFilterReq } setCurrentPage={setCurrentPage} onFilter={ onApplyFilter } onCloseFilter={ onCloseFilter }
    />
    )
  }
  const onApplyFilter = (filters) => {
    setFilters(filters);
    getDelegatorId(filters)
    setFilterComponent(null);
  }
  const getDelegatorId = (filters) => {
    // Given string
    const inputString = filters;
    // Regular expression pattern to match the desired string
    const pattern = /delegator_\|(\w{8}-\w{4}-\w{4}-\w{4}-\w{12})/;
    //Extracting the match from the string
    const match = inputString && inputString.match(pattern);
    // Check if a match is found
    if (match) {
      const delegatorValue = match[1]; // Extract the UUID part
      setDelegator(delegatorValue)
    } else {
      setDelegator(delegator)
    }
  }
  const onCloseFilter = () => {
    setFilterComponent(null);

  }

  //----end filter--------------------
  //================Start bulk approved=================================
  const [selectedRows, setSelectedRows] = useState([]);
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows)
    },
    getCheckboxProps: (record) => ({
      disabled: record.status !== 'Pending',
      // Column configuration not to be checked
      name: record.name,
    }),
  };
  const onApproveBulk = (isApproval) => {
    const data = [];
    selectedRows.forEach((request, key) => {
      request.request_as = requestAs
      request.is_approval = isApproval
      //request.last_remarks = 'bulk'
      //request.remarks = 'bulk'
      const payload = {
        request: request, delegator_id: delegator
      }
      data.push(payload)
    })
    const bulkData = {
      data: data,
      api: props.api + "/approve-bulk",
      method: 'post'
    }
    makeRequest(setLoader, approveBulkRequests, bulkData, onBulkApproved, onError);
  }
  const onBulkApproved = (approval) => {
    setSelectedRows([])
    message.success('Success')
    getAllData();
  }
  //================End bulk approved=================================

  //==============================================================================
  return (
    <>
      { filerComponent }
      { childComponent }
      <BodyComponent>
        <RequestAsTab getAllData={ getAllData } setAsFilters={ asFilters } setRequestAs={ setRequestAs } />
        <HeaderComponent headers={ pageConfig.headers } modkey={props.modkey}>
          <Popconfirm
            description="Are you sure to Perform this Action?"
            okText="Yes"
            cancelText="No"
            onConfirm={ () => onApproveBulk(false) }
          //onCancel={ () => onApproveBulk(false) }
          >
            { selectedRows.length > 0 && <CustomButton loading={ loader } key="create_button" text='Reject' /> }
          </Popconfirm>
          <FilterButton text='Refresh' onClick={ getAllData } />
          <FilterButton onClick={ onOpenFilter } />

        </HeaderComponent>
        <TableComponent modkey={props.modkey}
          loader={ loader }
          columns={ columns }
          dataSource={ dataSource }
          pagination={ { ...pagination, total: totalRecords } }
          onChange={ handleTableChange }
          rowSelection={ {
            type: 'checkbox',
            ...rowSelection,
          } }
        />
      </BodyComponent>
      <PaginationComponent pagination={ pagination } modkey={props.modkey} setCurrentPage={ setCurrentPage } currentPage={ currentPage } />
    </>
  );
};

export default IndexLendApprovals;
const filterFields = getRequestFilters('')

