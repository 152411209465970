import { DeleteOutlined, EditOutlined } from "@ant-design/icons"
import { Form, Space, Row, Col, Button } from "antd"
import { useEffect } from "react"
import { Commons, Const } from "../../constants";
import { InputText, trans, InputTextArea } from "@comps/components"
const langKey = Const["kpi"].lngKey
const ViewWorkSheetTasks = (props) => {
  useEffect(() => {

    // eslint-disable-next-line
  }, []);


  return (
    <Row style={ { marginTop: '20px' } }>
      <Col span={ 24 }>
        <Form.List name="kpi_tasks" >
          { (fields, { add, remove }) => (
            <>
              <Button onClick={ () => {
                props.onCreate()
              } } type="primary" className="add-node-button">{ trans(`${langKey}.addTask`) } </Button>
              <div className="table-container">
                <table className="table">
                  {
                    fields.length !== 0 && <thead>
                      <tr>
                        <th>{ trans(`${langKey}.title`) }</th>
                        <th>{ trans(`${langKey}.description`) }</th>
                        <th>{ trans(Commons.actions) }</th>
                      </tr>
                    </thead>
                  }
                  <tbody>
                    { fields.map(({ key, name, ...restField }) => (
                      <>
                        <tr>
                          <td style={ { minWidth: '200px' } }>
                            <Form.Item
                              { ...restField }
                              name={ [name, 'title'] }
                              rules={ rules.title }
                            >
                              <InputTextArea placeholder={ trans(`${langKey}.title`) } disabled />
                            </Form.Item>
                          </td>
                          <td style={ { minWidth: '200px' } }>
                            <Form.Item
                              { ...restField }
                              name={ [name, 'description'] }

                            //rules={ rules.min_times }
                            >
                              <InputTextArea placeholder={ trans(`${langKey}.description`) } disabled />
                            </Form.Item>
                          </td>

                          <td>
                            <Space>
                              <DeleteOutlined className="formlist-remove" onClick={ () => {
                                remove(name)
                                props.setRows(key, 'Deleted')
                              } } />
                              <EditOutlined className="formlist-remove" onClick={ () => {
                                props.onEdit(key)

                              } } />
                              {/* <EyeOutlined onClick={ () => {
                            props.onView(key)

                          } } /> */}
                            </Space>
                          </td>

                        </tr>
                      </>
                    )) }
                  </tbody>
                </table>
              </div>
            </>
          ) }
        </Form.List>
      </Col>
    </Row>
  )
}
export default ViewWorkSheetTasks

const rules = {
  title: [
    { required: true, message: trans(`${langKey}.title`) }, { title: 30 }
  ],


}