import React, { useEffect, useState } from "react";
import { Divider, Form, message } from "antd";
import { SaveButton, CancelButton, ModalComponent, trans, InputDate } from "@comps/components"
import { makeRequest, getErrorProps } from "@utils/helpers";
import { updateDataReq } from "../requests";
import { Commons, Const } from "../../constants";
import moment from "moment/moment";
import { SelectWithSearch } from '../../../../wrappers/components';

const langKey = Const["EOEmployment"].lngKey
const formName = "updateEndOfEmployment";

const CreateEndOfEmployment = (props) => {
  const [loader, setLoader] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [employee, setEmployee] = useState([]);
  const [interviewData,setInterviewData]=useState([])
  const [form] = Form.useForm();
  useEffect(() => {
    setData()
    // eslint-disable-next-line
  }, []);
  const setData = () => {
    
    setInterviewData(props.record.interview_answers)
    setEmployee([
      {
        value: props.record.employment_id,
        label: props.record.requestor.full_name_code,
      }])
    form.setFieldsValue({
      last_working_date: props.record.last_working_date ? moment(props.record.last_working_date) : null,
      //joining_date: props.record.requestor.joining_date ? moment(props.record.requestor.joining_date) : null
    })

  }

  const onSubmit = (data) => {
    data.employment_id = props.record.employment_id;
    data.id = props.record.id;
    const payload = { end_of_employment: data };
    makeRequest(setLoader, updateDataReq, payload, onSuccess, onError);
  }

  const onSuccess = (data, res) => {
    setSaveLoader(true)
    message.success("Saved successfully");
    props.onCreated(data.end_of_employments.data);
  }

  const onError = (err, res) => {
    let errorList = [];
    message.error(res.response.data.message);
  }



  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [
    !props.disabled &&
    <SaveButton disabled={ saveLoader } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCreated(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } width={ 1200 } maskClosable={ false }
      mainTitle={ "Rehire" } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCreated(false) }>
      <Form { ...formLayout } form={ form } name={ formName } onFinish={ onSubmit } disabled={ props.disabled } scrollToFirstError>

        <Form.Item name="employment_id" rules={ rules.employment_id } label={ trans(`${langKey}.employment_id`) }
          className="da-mb-16"
          { ...getErrorProps(errors['name']) } size="small"
        >
          <SelectWithSearch
            disabled
            defaultValue={ props.record.employment_id }
            value={ props.record.employment_id }
            placeholder={ trans(`${langKey}.employment_id`) }
            options={ employee }
          />
        </Form.Item>

        <Form.Item name="last_working_date" rules={ rules.last_working_date } label={ trans(`${langKey}.last_working_date`) }
          className="da-mb-16"
          { ...getErrorProps(errors['last_working_date']) } size="small"

        >
          <InputDate />
        </Form.Item>
        { !props.disabled && <Form.Item name="joining_date" rules={ rules.joining_date } label={ trans(`${langKey}.joining_date`) }
          className="da-mb-16"
          { ...getErrorProps(errors['joining_date']) } size="small"
        >
          <InputDate />
        </Form.Item> }

       {interviewData.length>0 && 
       <>
        <div className="h4 text-center">Exit Interview</div>
       
        
       <table className="border w-100">
     <thead>
       <tr>
         <th>Question</th>
         <th>Answer</th>
       </tr>
     </thead>
     <tbody>
{
 interviewData.map(each=>(
  
<tr>
<td>{each.question_name}</td>
   <td>{each.answer}</td>
</tr>
    
 ))
}
</tbody>
   </table>
       </>
      
}
      </Form>

    </ModalComponent>
  )
}

export default CreateEndOfEmployment

const rules = {
  joining_date: [
    { required: true, message: trans(`${langKey}.joining_date.req`) },
  ],


};

const formLayout = {
  // layout: "horizontal",
  labelCol: { span: 6 },
  wrapperCol: { span: 14, offset: 2 },
  labelAlign: "left"
}