import { useState, useEffect } from "react";
import {
  BodyComponent,
  TableComponent,
  ActionComponent,
  trans,
  HeaderComponent,
  ButtonComponent, InputTime
} from "@comps/components";
import { makeRequest ,removeById} from "@utils/helpers";
import { Commons, Const, colors } from "../constants";
import { Badge, message, Tooltip, Space ,Popconfirm} from 'antd';
import { ExperimentOutlined, ClockCircleOutlined, PieChartOutlined, WarningOutlined,CloseOutlined } from "@ant-design/icons"
import { FilterComponent, FilterButton, InputCheck } from "@comps/components";
import { deleteShiftAttendanceReq, getAllDataReq, getFilterReq, updateDataReq } from "./requests";
import { useSelector } from "react-redux";
import AttendanceSummary from "@mods/common/AttendanceSummary";
import CreateCompanyAttendanceRequest from "../attendances/companyAttendances/components/CreateCompanyAttendanceRequest";
import CreateRelaxationRequest from "../../leaveManagement/relaxation/companyRelaxation/components/CreateRelaxationRequest";
import { convertMinutesToHours, convertTimeTotz } from '@comps/commonFunctions'
import { getDatesFilters, getOrganizationFilters } from "../../common/commonFilterFields";
import UpdateCompanyAttendance from "./components/UpdateCompanyAttendance";
import CalculateAttendance from "./components/CalculateAttendance";
import dayjs from "dayjs";
import CreateLeaveRequest from '../../leaveManagement/leave/companyLeaves/components/CreateLeaveRequest';
import { DeleteOutline, RemoveOutlined } from "@mui/icons-material";
import { checkPermissions } from "@comps/commonFunctions";

const langKey = Const["myAttendance"].lngKey;
const pageConfig = {
  headers: {
    title: trans(`${langKey}.companyAttendanceModule`),
    breadcrumb: [
      {
        name: trans(`${langKey}.companyAttendanceModule`),
        path: Const["myAttendance"].route
      }
    ]
  }
}

const IndexCompanyTimeSheet = (props) => {
  const authUser = useSelector((state) => state.auth.authUser);
  const [totalRecords, setTotalRecords] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [loader, setLoader] = useState(false);
  const [childComponent, setChildComponent] = useState(null);
  const [filerComponent, setFilterComponent] = useState(null);
  const [attendanceSummary, setAttendanceSummary] = useState(null)
  const [filters, setFilters] = useState('');
  const [dates, setDates] = useState()
  const [countManuallyUpdated, setCountManuallyUpdated] = useState(0)
  const [editMode, setEditMode] = useState(false);
  const [currentEmploymentId, setEmploymentId] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 32,
    sortName: "id",
    sortType: "desc",
  });

  // ==================================
  //   Use States End
  // ==================================

  // ==================================
  //   Table Data End
  // ==================================
  const getStatusClass = (status) => {
    if (status === 'On Time') {
      return 'bg-success'
    }
    if (status === 'Day Off') {
      return 'bg-off'
    }
    if (status === 'Late' || status === 'Early') {
      return 'bg-warning'
    }
    if (status === 'Travel') {
      return 'bg-info'
    }
    if (status === 'Leave') {
      return 'bg-leave'
    }
    if (status === 'Absent') {
      return 'bg-absent'
    }
  }

  const columns = [
   
    // {
    //   key: 'delete_action',
    //   title: '',
    //   render: (record) => {
    //    return <Popconfirm title="Are you sure to delete?" icon={ <WarningOutlined /> } onConfirm={ () => onDelete(record) }>
    //     {props.module==='company-attendance' && record.time_slot_name && checkPermissions(null, 'delete') ?<CloseOutlined className="text-danger" />:''}
    //       </Popconfirm>
    //   }
    // },
    {
      key: 'date',
      title: trans(`${langKey}.date`),
      render: (record) => {
        return (
<div style={{
  display:'flex'
}}>
<div className="me-2">
<Popconfirm title="Are you sure to delete?" icon={ <WarningOutlined /> } onConfirm={ () => onDelete(record) }>
        {props.module==='company-attendance' && checkPermissions('companyAttendance', 'delete') ?<CloseOutlined className="text-danger" />:''}
          </Popconfirm>
</div>
<div>
{convertTimeTotz(record.date).format('dd, MMMM D, YYYY')}
</div>
</div>
        )

      
      }
    },
    {
      key: 'time_slot_name',
      title: trans(`${langKey}.time_slot_name`),
      render: (record) => {
        return record.time_slot_name ? record.time_slot_name : '--'
      }
    },

    {
      key: 'checkin_punch_time',
      title: trans(`${langKey}.checkin_punch_time`),
      render: (record) => {
        const time = record.checkin_punch_time ? convertTimeTotz(record.checkin_punch_time) : null
        return record.time_slot_name ?
          <div>
            <InputTime defaultValue={ time } onChange={ (value) => onEditTime(record, value, 'checkin') } disabled={ !editMode } />
            <br />
            <small>{ trans(`${langKey}.expected`) } { convertTimeTotz(record.checkin_expected_time).format('hh:mm a') }</small>
          </div>
          : '--'
      }
    },

    {
      key: 'checkin_status',
      title: trans(`${langKey}.checkin_status`),
      render: (record) => {
        const status_class = getStatusClass(record.checkin_status)
        return <Badge
          count={ record.checkin_status ? record.checkin_status : 'none' }
          style={ { background: colors[status_class], color: colors[status_class + '-color'] } }
        />
      }
    },

    {
      key: 'checkout_punch_time',
      title: trans(`${langKey}.checkout_punch_time`),
      render: (record) => {
        const time = record.checkout_punch_time ? convertTimeTotz(record.checkout_punch_time) : null
        return record.time_slot_name ?
          <div>
            <InputTime defaultValue={ time } onChange={ (value) => onEditTime(record, value, 'checkout') } disabled={ !editMode } />
            <br />
            <small>{ trans(`${langKey}.expected`) } { convertTimeTotz(record.checkout_expected_time).format('hh:mm a') }</small>
          </div>
          : '--'

      }
    },
    {
      key: 'checkout_status',
      title: trans(`${langKey}.checkout_status`),
      render: (record) => {
        const status_class = getStatusClass(record.checkout_status)
        return <Badge
          count={ record.checkout_status ? record.checkout_status : 'none' }
          style={ { background: colors[status_class], color: colors[status_class + '-color'] } }
        />
      }
    },
    {
      key: 'expected_minutes',
      title: trans(`${langKey}.expected_minutes`),
      render: (record) => {
        return record.expected_minutes / 60 ? convertMinutesToHours(record.expected_minutes) : '--'
      }
    },
    {
      key: 'worked_minutes',
      title: trans(`${langKey}.worked_minutes`),
      render: (record) => {
        return record.worked_minutes / 60 ? convertMinutesToHours(record.worked_minutes) : '--'
      }
    },
    {
      key: 'break_minutes',
      title: trans(`${langKey}.break_minutes`),
      render: (record) => {
        return record.break_minutes / 60 ? convertMinutesToHours(record.break_minutes) : '--'
      }
    },
    {
      key: 'is_manually_updated',
      title: trans(`${langKey}.is_manually_updated`),
      render: (record) => {
        return <InputCheck checked={ record.is_manually_updated } disabled />
      }
    },
    {
      key: trans(Commons.actions),
      title: trans(Commons.actions),
      render: (record) => (
      <>
        <ActionComponent
          each={ record }
          onEdit={ record.time_slot_name ? onEdit : '' }
          onView={ record.time_slot_name ? onView : '' }
        >
          {
            record.time_slot_name ?
              <>
                <Space style={ { marginTop: 10 } }>
                  {
                    record.is_absent || record.is_missing ?
                      <>
                        <Tooltip title="Relaxation Request"> <ExperimentOutlined className="icon-style da-text-color-warning-1" style={ { color: "#1677ff", marginLeft: 8 } } onClick={ () => onRelaxationRequest(record) } />
                        </Tooltip>
                        <Tooltip title="Attendance Request"> <ClockCircleOutlined className="icon-style da-text-color-info-1" style={ { color: "#1677ff", marginLeft: 15 } } onClick={ () => onAttendanceRequest(record) } />
                        </Tooltip>
                      </>
                      : ''
                  }
                  {
                    record.is_absent && <Tooltip title="Leave Request"> <PieChartOutlined className="icon-style da-text-color-warning-1" style={ { color: "#1677ff", marginLeft: 15 } } onClick={ () => onLeaveRequest(record) } />
                    </Tooltip>
                  }
                </Space>
              </>
              : ''
          }
        </ActionComponent >
     
        
      </>
      ),
    },
  ];

  // ==================================
  //   Table Data End
  // ==================================

  useEffect(() => {
    //getAllData();
    getAllRecord()
    // eslint-disable-next-line
  }, [props]);


  const getAllRecord = () => {
    setFilters('')
    setAttendanceSummary(null)
    setDataSource([])

    if (props.module !== 'my-attendance' && !filters) {
      onOpenFilter()
    }
    else {
      getAllData(filters)
    }
  }
  const getAllData = (fil) => {
    
    //setAttendanceSummary(null)
    setDataSource([])
    setChildComponent(null)
    const payload = {
      page: 1,
      filters: `${props.filters};${fil}`
    }

    if (props.module !== 'my-attendance' && !fil) {
      onOpenFilter()
    }
    else{
      makeRequest(setLoader, getAllDataReq, payload, onSuccess, onGetAllDataError);
    }
  
  };

  const onSuccess = (response) => {
    let employee = null
    let dates = {}
    let manullayCount = [];
    if (response.attendances.length > 0) {
      setEmploymentId(response.attendances[0].employment_id)
      manullayCount = response.attendances.filter(attendance => attendance.is_manually_updated)
      employee = response.attendances[0].employee_name
      dates = {
        from: response.attendances[0].date,
        end: response.attendances[response.attendances.length - 1].date
      }
    }
    setCountManuallyUpdated(manullayCount.length)
    setDataSource(response.attendances);

    setDates(dates)
    setAttendanceSummary(<AttendanceSummary attendanceSummary={ attendanceSummary } manuallyUpdatedCount={ manullayCount.length } record={ response.summary } employee={ employee } dates={ dates } />)
    onCloseFilter()
  };

  const onGetAllDataError = (err, res) => {
    message.error(res.response.data.message);
  }
  const onEdit = (record) => {
    setChildComponent(
      <UpdateCompanyAttendance onCompleted={ onCompleted } record={ record } disabled={ false } getAllData={ getAllData } authUser={ authUser } filters={ filters } />
    );
  };
  const onEditTime = (record, value, col) => {

    let checkin_time = record.checkin_punch_time;
    let checkout_time = record.checkout_punch_time;
    if (col === 'checkin') {
      checkin_time = value ? value.second(0) : null;
      checkout_time = record.checkout_punch_time ? convertTimeTotz(record.checkout_punch_time).second(0) : null;
    }
    if (col === 'checkout') {
      checkin_time = record.checkin_punch_time ? convertTimeTotz(record.checkin_punch_time).second(0) : null;
      checkout_time =  value ? value.second(0) : null;
    }
    const payload = {
      filters: `${props.filters};${filters}`,
      id: record.id,
      attendance: {
        ...record,
        checkin_time: checkin_time,
        checkout_time: checkout_time,
        checkin_date:checkin_time? dayjs(record.checkin_expected_time).format('YYYY-MM-DD'):null,
        checkout_date:checkout_time? dayjs(record.checkout_expected_time).format('YYYY-MM-DD'):null,
       checkin_punch_time:checkin_time,
       checkout_punch_time:checkout_time,
        is_manually_updated: true,
      }
    }
    makeRequest(setLoader, updateDataReq, payload, onSuccess, onError);
  }
  const onError = (err, res) => {
    message.error(res.response.data.message);
  }
  const onView = (record) => {
    setChildComponent(
      <UpdateCompanyAttendance onCompleted={ onCompleted } record={ record } disabled={ true } getAllData={ getAllData } authUser={ authUser } filters={ filters } />
    );
  };
  const onAttendanceRequest = (record) => {
    setChildComponent(
      <CreateCompanyAttendanceRequest onCompleted={ onCompleted } record={ record } employment_id={ currentEmploymentId } authUser={ authUser } source={ 'timeSheet' } />
    );
  };
  const onRelaxationRequest = (record) => {
    setChildComponent(
      <CreateRelaxationRequest onCompleted={ onCompleted } record={ record } employment_id={ currentEmploymentId } authUser={ authUser } source={ 'timeSheet' } />
    );
  };
  const onLeaveRequest = (record) => {
    setChildComponent(
      <CreateLeaveRequest onCompleted={ onCompleted } record={ record } employment_id={ currentEmploymentId } authUser={ authUser } source={ 'timeSheet' } />
    );
  };

  const onDelete=(record)=>{
    let payload={
      id:record.id
    }
    makeRequest(setLoader, deleteShiftAttendanceReq, payload, onDeleted, null);
  }
const onDeleted=(res)=>{

const data=removeById(dataSource,res.id);
  setDataSource(data);
}
  const onCompleted = (response) => {
    if (response.attendances !== undefined) {
      getAllData(filters)
      // setDataSource(response.attendances);
      // const dates = {
      //   from: response.attendances[0].date,
      //   end: response.attendances[response.attendances.length - 1].date
      // }
      // setAttendanceSummary(null)
      // setAttendanceSummary(
      //   <AttendanceSummary record={ response.summary } employee={ authUser } dates={ dates } />
      // )
    }
    setChildComponent(null);
  };

  const handleTableChange = (page, fil, sorter) => {
    let payload = {
      ...pagination,
      current: page.current,
      pageSize: page.pageSize,
      sortName: sorter.field || "id",
      sortType: sorter.order === "ascend" ? "asc" : "desc",
    };
    setPagination(payload);
  };

  //---------------filters-----------------------
  const onOpenFilter = () => {
    setFilterComponent(<FilterComponent filters={ filterFields } modkey={props.modkey} selected={ filters } api={ getFilterReq } onFilter={ onApplyFilter } onCloseFilter={ onCloseFilter }
    />
    )
  }

  const onCloseFilter = () => {
    setFilterComponent(null);
  }
  const onApplyFilter = (filters) => {
    setFilters(filters)
    getAllData(filters)
    setFilterComponent(null);
  }

  //----end filter--------------------
  //calculate attendance by date ,employee,department
  const onCalculateAttendance = (data) => {
    setChildComponent(<CalculateAttendance onCompleted={ onCompleted } />)
  }

  //filter fields

  const filterArray = getOrganizationFilters('')
  if (props.module !== 'my-attendance') {
    filterArray.unshift({
      type: "select",
      placeholder: 'Employee',
      key: "employee",
      data_key: "supporting_data.employments",
      required: true,
      employeeType: props.module === 'subordinate-attendance' ? 'subordinate' : 'all',
      subordinates: props.module === 'subordinate-attendance' ? true : false,
      resource_type: 'employment'
      //is_resource: true
    })
  }
  const filterFields = filterArray.concat(getDatesFilters(''))

  return (
    <>
      { filerComponent }
      { childComponent }
      <BodyComponent>
      <HeaderComponent headers={ pageConfig.headers } modkey={props.modkey}>
          { props.filters === '' && <ButtonComponent text='Calculate' type='primary' onClick={ () => onCalculateAttendance() } /> }
          <ButtonComponent text='Refresh' type='primary' onClick={ () => getAllData(filters) } />
          <FilterButton onClick={ () => onOpenFilter() } />
        </HeaderComponent>
        { attendanceSummary }

        { dataSource.length > 0 && props.module !== 'my-attendance' && props.module!=="subordinate-attendance" && checkPermissions('companyAttendance', 'update') && <ButtonComponent text={ editMode ? "Disable Edit" : "Enable Edit" } type='primary' onClick={ () => setEditMode(!editMode) } /> }

        <TableComponent modkey={props.modkey}
          loader={ loader }
          columns={ columns }
          dataSource={ dataSource }
          pagination={ { ...pagination, total: totalRecords } }
          onChange={ handleTableChange }
          key={ editMode }
        />


      </BodyComponent>
    </>
  );


};





export default IndexCompanyTimeSheet;


