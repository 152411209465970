import { get, del, post, put } from "@utils/axios";

const api = "relaxationRequest";
export const getAllDataReq = (payload) => {
  return get(`${api}?page=${payload.page}&filters=${payload.filters}`) //filters=employee_|id
};

export const createDataReq = (payload) => {
  return post(`${api}?`, payload);
};

export const updateDataReq = (payload) => {
  return put(`${api}/${payload.id}`, payload);
};
export const cancelRequestReq = (payload) => {
  return post('cancelRequest', payload);
};

export const deleteDataReq = (payload) => {
  return del(`${api}/${payload.id}?page=1&filters=${payload.filters}`);
};

export const getRosterReq = (payload) => {
  return post(`${api}/getRoster`, payload);
};

export const getFilterReq = (payload) => {
  return get(`${api}?page=1&filters?company_id=1&fields=page=1&filters=organization_structure_fields_|company;preset_|grade;`)
}
export const onDateChange = (payload) => {
  return post(`${api}/dateChange?&`, payload)
}
export const onShiftSelectionChangeReq = (payload) => {
  return post(`${api}/shiftSelectionChange?&`, payload)
}

export const getRelatedDataByReqIdReq = (payload) => {
  return get(`${api}/${payload}`)
};