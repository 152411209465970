import React from "react";
import { Route, Routes } from "react-router-dom";
import IndexProfessionalAttributes from "./settings/professionalAttributes/IndexProfessionalAttributes";
import IndexEvaluationScales from "./settings/evaluationScale/IndexEvaluationScale";
import IndexProbations from "./probationers/probation/IndexProbation";
import IndexEvaluations from "./probationers/evaluation/IndexEvaluations";

const AssessmentModules = () => {
  return (
    <Routes>
      <Route path="settings/professional-attributes" element={ <IndexProfessionalAttributes modkey="professionalAttributesProbation"/> } />
      <Route path="settings/evaluation-scales" element={ <IndexEvaluationScales modkey="evaluationScales"/> } />
      <Route path="probations/requests" element={ <IndexProbations modkey="probationRequests"/> } />
      <Route path="probations/approvals" element={ <IndexEvaluations modkey="probationApprovals"/> } />
    </Routes>
  );
}

export default AssessmentModules;
