import { Button, Tooltip } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons"

const BaseAddNew = (props) => {
  let text = 'Create New'

  let icon = <PlusCircleOutlined />;
  let notify = props.notify;
  const addNewStyle = {
    float: 'right',
    marginTop: "38px",
    marginLeft: '2px',
    borderRadius: '50px',
    color: 'blue'
  }

  return (
    <Tooltip { ...notify } title={ text } className="text-primary">
      <Button icon={ icon } style={ addNewStyle }  { ...props } >
      </Button>
    </Tooltip>
  );
};

export default BaseAddNew;
