import React, { useEffect, useState } from "react";
import { Row, Col, Space } from 'antd';
import { ButtonComponent, SelectWithSearch } from "@comps/components";
import { LeftOutlined } from '@ant-design/icons';
import { NextButton } from '../components';
import { checkPermissions } from '@comps/commonFunctions';

const BasePagination = (props) => {

  const [totalPages, setTotalPages] = useState([])
  const [previousCount, setPreviousCount] = useState(props.pagination?props.pagination.count:0);
  const [currentCount, setCurrentCount] = useState(0)
  const [pagination, setPagination] = useState({})
  useEffect(() => {
    setData()
    // eslint-disable-next-line
  }, [props]);

  const setData = () => {
    if (props.pagination !== undefined) {
      const total_pages = props.pagination.total_pages !== undefined ? props.pagination.total_pages : props.pagination.last_page;
      const pages = []
      for (let index = 1; index <= total_pages; index++) {
        pages.push(
          {
            label: 'Page ' + index + ' of ' + total_pages,
            value: index
          }
        )
      }
      setTotalPages(pages)
      if (props.pagination.count !== undefined) {
        setCurrentCount(props.pagination.count === undefined ? props.pagination.to : props.pagination.count); // Store current count
      } else {
        setCurrentCount(props.pagination.from && props.pagination.count === undefined ? props.pagination.from + '-' + props.pagination.to : props.pagination.count); // Store current count
      }
      setPagination(props.pagination)
    }
  }
  // Function to handle pagination and update data
  const handlePageChange = (current, action) => {
    if (action === 'next') {
      setPreviousCount(previousCount + currentCount)
    }
    if (action === 'back') {
      setPreviousCount(previousCount - current)
    }
  };



  return (
  
    <div className="site-page-wrapper da-px-32">
      {
       checkPermissions(props.modkey,'view') && props.pagination !== undefined &&
        <Row className="mt-4">
          <Col span={ 8 }>
            <span>Showing { currentCount ? currentCount : 0 } of { props.pagination.total } Entries </span>
          </Col>
          <Col span={ 8 } >
            <Space>
              <ButtonComponent className="previous-button" text='Previous Page'
                onClick={
                  () => {
                    //handlePageChange(currentCount, 'back')
                    props.setCurrentPage(props.currentPage - 1)
                  }
                }
                disabled={ props.currentPage === 1 } type='primary' icon={ <LeftOutlined /> }
              />
              <NextButton
                onClick={ () => {
                  //handlePageChange(currentCount, 'next')
                  props.setCurrentPage(props.currentPage + 1)
                }
                } disabled={ props.currentPage === totalPages.length || !currentCount } type='primary'
              />
            </Space>
          </Col>
          {
            props.numbering!==false ? 
            <Col span={ 8 }>
            <SelectWithSearch
              style={ { width: '150px', float: 'right' } }
              value={ props.currentPage }
              options={ totalPages }
              onChange={ (page) => props.setCurrentPage(page) }
              allowClear={ false }
            />
          </Col>
          :''
          }
          
        
        </Row>
      }
    </div>
  );
};

export default BasePagination;
