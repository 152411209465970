import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Spin } from 'antd';
import { makeRequest } from '@utils/helpers';
import { getAttendanceMonthReportReq } from '../requests';
import "../dashboard.css"
import ReactApexChart from 'react-apexcharts';
import { InputDate } from '@comps/components';
import dayjs from 'dayjs';
import useInViewport from '../useInViewport';
const moment = require('moment');

const HeadCountByMonthlyReport = () => {
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState(moment().startOf('year'));
  const [endDate, setEndDate] = useState(moment().endOf('year'));
  const viewRef = useRef(null);
  const viewport = useInViewport(viewRef);
  useEffect(() => {
    let payload = {
      "start_date": startDate ? startDate.format('YYYY-MM-DD') : moment().startOf('month').format('YYYY-MM-DD'),
      "end_date": endDate ? endDate.format('YYYY-MM-DD') : moment().endOf('month').format('YYYY-MM-DD'),
    }
    if (viewport && data.length === 0) {
      makeRequest(setLoader, getAttendanceMonthReportReq, payload, onSuccess, onError)
    }
  }, [viewport, startDate, endDate]);

  const onSuccess = (data) => {
    setData(data.daily_early_arrivals);
  }

  const onError = (error) => {
  }

  const chartOptions = {
    // Define your chart options here
    series: [{
      name: 'Absents',
      data: data.absents
    }, {
      name: 'Presents',
      data: data.presents
    }, {
      name: 'Leaves',
      data: data.leaves
    }],
    labels: data.labels,
    colors: ['#FF4560', '#248C46', '#FEB019'],
    options: {
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        stackType: '100%'
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
    },
  };

  return (
    <div className='widgetbox' ref={ viewRef }>
      <Row >
        <Col span={ 12 }>
          <h3>Monthly Attendance Report</h3>
        </Col>
        <Col span={ 12 }>
          <div className='changeDate'>
            <Row gutter={ 10 }>
              <Col span={ 12 }>
                <InputDate picker="month" onChange={ setStartDate } defaultValue={ dayjs(startDate) } placeholder="Start Date" />
              </Col>
              <Col span={ 12 }>
                <InputDate picker="month" onChange={ setEndDate } defaultValue={ dayjs(endDate) } placeholder="End Date" />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <div className='gendermain'>
        <Spin spinning={ loader } tip="Loading...">
          { data && data.absents && data.leaves && (
            <ReactApexChart
              options={ chartOptions }
              series={ chartOptions.series }
              type="bar"
              height={ 370 }
            />
          ) }
        </Spin>
      </div>
    </div>
  );
}

export default HeadCountByMonthlyReport;