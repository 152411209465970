import React, { useEffect, useState } from 'react';
import { Row, Col, Spin, message, Popconfirm } from 'antd';
import ClockBlueImage from '@assets/icons/clockicon.svg';
import ClockOutImage from '@assets/icons/ClockOut.svg';
import BreakImage from '@assets/icons/Break.svg';
import { ButtonComponent } from '@comps/components';
import { makeRequest, } from '@utils/helpers';
import { getMarkAttendanceReq, getMarkAttendanceCheckinReq } from '../requests';
import { convertTimeTotz } from '@comps/commonFunctions';
import { WarningOutlined } from '@ant-design/icons';
const moment = require('moment');

const MarkCheckinCheckout = () => {
  const [loader, setLoader] = React.useState(false);
  const [data, setData] = React.useState(null);
  const [checkinStartTime, setCheckinStartTime] = useState(null);
  const [breakStartTime, setBreakStartTime] = useState(null);
  const [breakStart, setBreakStart] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(moment.duration());

  useEffect(() => {
    if (checkinStartTime !== null) {
      const timerInterval = setInterval(() => {
        const currentTime = moment();
        const duration = moment.duration(currentTime.diff(!breakStart ? checkinStartTime : breakStartTime));
        setElapsedTime(duration);
      }, 1000);
      // Cleanup the interval on component unmount
      return () => clearInterval(timerInterval);
    }
  }, [checkinStartTime, breakStartTime]);

  // Format the elapsed time
  const formattedElapsedTime = `${elapsedTime.hours().toString().padStart(2, '0')}:${elapsedTime.minutes().toString().padStart(2, '0')}:${elapsedTime.seconds().toString().padStart(2, '0')}`;

  useEffect(() => {
    makeRequest(setLoader, getMarkAttendanceReq, null, onSuccess, Function);
  }, []);

  const onSuccess = (response) => {
    if (response.is_checkin_time && response.checkin_time !== '' && !response.is_break) {
      setCheckinStartTime(moment(convertTimeTotz(response.checkin_time).format('hh:mm A'), 'hh:mm A'));
    } else if (response.is_checkin_time && response.checkin_time !== '' && response.is_break) {
      setCheckinStartTime(moment(convertTimeTotz(response.checkin_time).format('hh:mm A'), 'hh:mm A'));
      setBreakStart(response.is_break);
      setBreakStartTime(moment(convertTimeTotz(response.break_time).format('hh:mm A'), 'hh:mm A'));
    }

    setData(response);
  }

  const markAttendance = () => {
    let payload = {
      mark_attendance: {
        mark_checkin: true
      }
    }
    makeRequest(setLoader, getMarkAttendanceCheckinReq, payload, onAttendanceSuccess, onAttendanceError);
  }

  const onAttendanceSuccess = (data) => {
    setData(data);
    setCheckinStartTime(moment(convertTimeTotz(data.checkin_time).format('hh:mm A'), 'hh:mm A'));
  }

  const onAttendanceError = (error, res) => {
    message.error(res.response.data.message);
  }

  const startBreak = () => {
    if (breakStart) {
      stopBreak()
    } else {
      let payload = {
        mark_attendance: {
          mark_breakout: true
        }
      }
      makeRequest(setLoader, getMarkAttendanceCheckinReq, payload, onBreakOutSuccess, onAttendanceError);
    }
  }

  const onBreakOutSuccess = (data) => {
    setBreakStart(true);
    setBreakStartTime(moment(convertTimeTotz(data.break_time).format('hh:mm A'), 'hh:mm A'));
  }

  const stopBreak = () => {
    let payload = {
      mark_attendance: {
        mark_breakin: true
      }
    }
    makeRequest(setLoader, getMarkAttendanceCheckinReq, payload, onBreakInSuccess, onAttendanceError);
  }

  const onBreakInSuccess = (data) => {
    setBreakStart(false);
    setCheckinStartTime(moment(convertTimeTotz(data.checkin_time).format('hh:mm A'), 'hh:mm A'));
    setBreakStart(null);
  }

  const markCheckOut = () => {
    let payload = {
      mark_attendance: {
        mark_checkout: true
      }
    }
    makeRequest(setLoader, getMarkAttendanceCheckinReq, payload, onCheckOutSuccess, onAttendanceError);
  }

  const onCheckOutSuccess = (data) => {
    setData(data);
    setCheckinStartTime(null);
    setBreakStart(null);
    setBreakStartTime(null);
  }

  const calculateShiftTime = (checkinTime, checkoutTime) => {
    const checkinMoment = moment(checkinTime, "YYYY-MM-DD HH:mm:ss");
    const checkoutMoment = moment(checkoutTime, "YYYY-MM-DD HH:mm:ss");

    // Calculate the time difference
    const duration = moment.duration(checkoutMoment.diff(checkinMoment));

    // Format the time difference in HH:MM:SS
    const shiftTimeFormatted = moment.utc(duration.asMilliseconds()).format("HH:mm:ss");

    return shiftTimeFormatted;
    // You can set the formatted time to state or use it as needed in your application
  };


  return (
    <div className='widgetbox widgetboxminH'>
      <Row>
        <Col span={ 24 } >
          <h3> Mark Clock In/Clock Out</h3>
        </Col>
      </Row>
      <div className='widgetcontent'>
        { data && (
          <Spin spinning={ loader } tip="Loading...">
            <Row>
              <Col span={ 12 }>
                <div className='markclockin'>
                  <h4>Clock In</h4>
                  <p>{ data.is_checkin_time && data.checkin_time !== '' ? convertTimeTotz(data.checkin_time).format('hh:mm A') : "-" }</p>
                </div>
              </Col>
              <Col span={ 12 }>
                <div className='markclockout'>
                  <h4>Clock Out</h4>
                  <p>{ data.is_checkout_time && data.checkout_time !== '' ? convertTimeTotz(data.checkout_time).format('hh:mm A') : "-" }</p>
                </div>
              </Col>
              <Col span={ 24 }>
                <div className='markclockinout'>
                  { breakStart ? <img src={ BreakImage } style={ {
                    width: '60px',
                  } } alt='clock' /> : <img src={ ClockBlueImage } alt='clock' /> }
                  <h4 className='clockTime' style={ {
                    color: breakStart ? '#F0AD4E' : '#1E2022'
                  } }>{ data.is_checkin_time && data.checkin_time !== '' && !data.is_checkout_time ? <>{ formattedElapsedTime }</> : <>{ data.is_checkout_time ? calculateShiftTime(data.checkin_time, data.checkout_time) : "00:00:00" } </> }</h4>
                </div>
                <div className='checkinoutBtn'>
                  <Row>

                    { data.is_checkin_time && !data.is_checkout_time ?
                      <>
                        <Col span={ 12 }>
                          <Popconfirm title="Are you sure?" icon={ <WarningOutlined /> } onConfirm={ markCheckOut }>
                            <ButtonComponent className='checkoutbtn' text={ <>Check Out <img src={ ClockOutImage } alt='checkout' /></> } />
                          </Popconfirm>
                        </Col>
                        <Col span={ 12 }>
                          <ButtonComponent style={ {
                            background: breakStart ? '#F0AD4E' : 'rgba(64, 121, 252, 0.10)',
                            color: breakStart ? '#fff' : '#4079FC',
                            width: '100%',
                          } } onClick={ startBreak } className='breakBtn' text={ <>{ breakStart ? "Stop Break" : "Start Break" } < img src={ BreakImage } alt='checkout' /></> } />
                        </Col>
                      </>
                      :

                      <Col span={ 24 }>
                        { !data.is_checkout_time ?
                          <>
                            { data && !data.shift ?
                              <div className='dayoff' >
                                Day off
                              </div>
                              :
                              <ButtonComponent onClick={ markAttendance } className='checkoutbtn' text={ <>Check In <img src={ ClockOutImage } alt='checkout' /></> } />
                            }
                          </>
                          :
                          <ButtonComponent style={ {
                            background: '#F5791A',
                            color: '#fff',
                          } } disabled={ true } className='checkoutbtn' text={ <>Shift Complete</> } />
                        }
                      </Col>
                    }
                  </Row>
                </div>
              </Col>
            </Row>
          </Spin>
        ) }
      </div>
    </div>
  )
}

export default MarkCheckinCheckout;