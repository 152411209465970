import { get, del, post, postFd } from "@utils/axios";

const api = "endOfEmployments";

export const getAllDataReq = (payload) => {
  return get(`${api}?page=${payload.page}&filters=${payload.filters}`)
};

export const createDataReq = (payload) => {
  return postFd(`${api}?`, payload);
};
export const conductEoeInterviewReq = (payload) => {
  return post(`${api}/conduct-interview?`, payload);
};

export const updateDataReq = (payload) => {
  return post(`${api}/rehire`, payload);
};

export const deleteDataReq = (id) => {
  return del(`${api}/${id}`);
};

export const getQuestionsReq = () => {
  return get(`eoe-questions`);
};
export const getRelatedReq = () => {
  return get(`resources/employment?page=1`);
};

export const getFilterReq = (payload) => {
  return get(`filters?company_id=1&fields=page=1&filters=supporting_fields_|is_completed,employees,designation;organization_structure_fields_|location,branch,department;preset_|end_of_employments;`)
}

export const getEmployeeInfoByIdReq = (id) => {
  return get(`${api}/relatedData?id=${id}`);
}