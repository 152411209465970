import { Select, Tag } from "antd";

const tagRender = (props) => {
  const { label, value, closable, onClose } = props;
  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      color={ 'green' }
      onMouseDown={ onPreventMouseDown }
      closable={ closable }
      onClose={ onClose }
      style={ {
        marginRight: 3,
      } }
    >
      { label }
    </Tag>
  );
};
const BaseMultipleSelectWithSearch = (props) => {


  return (


    <Select
      style={ {
        width: '100%',
        height: '48px',
      } }
      mode="multiple"
      { ...props }
      showSearch={ true }
      placeholder={ props.placeholder }
      filterOption={ (input, option) => option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      options={ props.options }
      tagRender={ tagRender }
      virtual={ false }
      allowClear
      maxTagCount={ 'responsive' }
    >
    </Select>
  )
}

export default BaseMultipleSelectWithSearch