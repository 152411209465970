
import { Form, Switch } from "antd"
import { useEffect, useState } from "react"
import { Commons, Const } from "../../constants";
import { SaveButton, CancelButton, ModalComponent, trans, SelectMultiWithSearch } from "@comps/components"
import { createSelectList } from '@comps/commonFunctions';
const langKey = Const["privilege"].lngKey

const CreatePrivilegeFilters = (props) => {
  const [type, setType] = useState(false);
  const [departments, setDepartments] = useState([])
  const [areas, setAreas] = useState([])
  const [locations, setLocations] = useState([])
  const [branches, setBranches] = useState([])
  const [employments, setEmployments] = useState([])
  useEffect(() => {
    getRelatedData()
    // eslint-disable-next-line
  }, []);

  const getRelatedData = () => {
    setDepartments(createSelectList(props.allData.departments))
    setAreas(createSelectList(props.allData.areas))
    setLocations(createSelectList(props.allData.locations))
    setBranches(createSelectList(props.allData.branches))
    setEmployments(props.employments)
  }

  const onSubmit = (data) => {

    const type_name = type ? 'Employees' : "Organization"

    props.onCompleted({ ...data, type: type, type_name: type_name }, 'Added')
  }

  const footer = [
    <SaveButton form={ 'createPrivilegeFilters' } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];
  return (

    <ModalComponent top={ 20 } width={ 1000 } maskClosable={ false }
      mainTitle={ trans(Commons.create) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Form { ...formLayout } name={ 'createPrivilegeFilters' } onFinish={ onSubmit }>

        <Form.Item name="type" rules={ rules.type } label={ trans(`${langKey}.type`) }

          valuePropName="checked"
        >
          <label>{ trans(`${langKey}.organization`) }</label>
          <Switch style={ { margin: 10 } } onChange={ (value) => setType(value) } />
          <label>{ trans(`${langKey}.employees`) }</label>
        </Form.Item>

        {
          !type ?
            <>
              <Form.Item name='department_ids' rules={ rules.department_ids } label={ trans(`${langKey}.department_ids`) }
              >
                <SelectMultiWithSearch placeholder={ trans(`${langKey}.department_ids`) } options={ departments } />
              </Form.Item>
              <Form.Item name='location_ids' rules={ rules.location_ids } label={ trans(`${langKey}.location_ids`) }
              >
                <SelectMultiWithSearch placeholder={ trans(`${langKey}.location_ids`) } options={ locations } />
              </Form.Item>
              <Form.Item name='area_ids' rules={ rules.area_ids } label={ trans(`${langKey}.area_ids`) }
              >
                <SelectMultiWithSearch placeholder={ trans(`${langKey}.area_ids`) } options={ areas } />
              </Form.Item>
              <Form.Item name='branch_ids' rules={ rules.branch_ids } label={ trans(`${langKey}.branch_ids`) }
              >
                <SelectMultiWithSearch placeholder={ trans(`${langKey}.branch_ids`) } options={ branches } />
              </Form.Item>
            </>
            :
            <Form.Item name='employment_ids' rules={ rules.employment_ids } label={ trans(`${langKey}.employment_ids`) }
            >
              <SelectMultiWithSearch placeholder={ trans(`${langKey}.employment_ids`) } options={ employments } />
            </Form.Item>
        }

      </Form>
    </ModalComponent>
  )
}
export default CreatePrivilegeFilters

const formLayout = {
  // layout: "horizontal",
  labelCol: { span: 6 },
  wrapperCol: { span: 14, offset: 2 },
  labelAlign: "left"
}
const rules = {
  employment_ids: [
    { required: true, message: trans(`${langKey}.employment_ids`) },
  ],

}
