import { get, del, post, put } from "@utils/axios";

const api = "travelApprovals";

export const getAllDataReq = (payload) => {
  return post(`${payload.api}?page=${payload.page}&filters=${payload.filters}`, payload.data)
};
export const approveRequest = (payload) => {
  return post(`${payload.api}/approveRequest?page=1`, payload);
}
export const getFilterReq = (payload) => {
  return get(`filters?company_id=1&fields=page=1&filters=supporting_fields_|employments,request_status,delegator;organization_structure_fields_|location,branch,department;delegator_|travel_request;`)
}
export const createDataReq = (payload) => {
  return post(`${api}?`, payload);
};

export const updateDataReq = (payload) => {
  return put(`${api}/${payload.id}`, payload);
};

export const deleteDataReq = (id) => {
  return del(`${api}/${id}`);
};
export const getRelatedDatareq = (id) => {
  return get(`${api}/relatedData`);
};
export const getEmploymentExcludeId = (id) => {
  return get(`resources/employment?page=1&filters=exclude_ids_|${id}`);
};

export const getEmploymentsReq = (payload) => {
  return get(`resources/employment?page=1&filters=privilege_|false;`);
};
export const createConsentReq = (payload) => {
  return post(`${api}/createConsent`, payload);
};
export const createMessageConsentReq = (payload) => {
  return post(`${api}/messageConsent`, payload);
};


export const getLeaveRequestReq = (payload) => {
  return get(`companyLeaveRequests/${payload}`)
};
export const approveExpenseRequestReq = (payload) => {
  return put(`${api}/approve?page=1&filters=${payload.filters}`, payload)
};
export const approveCancelLeaveRequestReq = (payload) => {
  return post(`cancelRequestApprovals/approveRequest?page=1&filters=${payload.filters}`, payload)
};

