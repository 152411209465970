import React, { useEffect, useState } from 'react';
import { Collapse, Form } from 'antd';
import { Commons, Const } from "../../constants";
import { CancelButton, ModalComponent, trans } from "@comps/components"
import dayjs from 'dayjs'
const langKey = Const["taxAdjustments"].lngKey


const ViewTaxAdjustment = (props) => {

  const [taxes, setTaxes] = useState({})
  const [items, setItems] = useState([])

  useEffect(() => {
    setData()
    // eslint-disable-next-line
  }, []);

  const setData = () => {

    setTaxes(props.record.taxes)
    let data = props.record.taxes
    for (const key in data) {
      items.push(
        {
          key: key,
          label: key,
          children:
            <table className='table'>
              <thead>
                <tr>
                  <th>{ trans(`${langKey}.month`) }</th>
                  <th>{ trans(`${langKey}.amount`) }</th>
                </tr>

              </thead>
              <tbody>
                {
                  data[key].map(each => (
                    <tr>
                      <td>{ dayjs(each['month']).format('YYYY MMMM') }</td>
                      <td>{ each['amount'] }</td>
                    </tr>
                  ))
                }
              </tbody>
            </table>,
        }
      )
    }
  }
  //footer
  const footer = [
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];
  return (
    <>
      <ModalComponent top={ 20 } width={ 1000 } maskClosable={ false }
        mainTitle={ trans(Commons.create) } subTitle={ trans(`${langKey}.moduleName`) }
        visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
        <Form.Item name="full_name_code" >
          { `${props.record.full_name_code}` }
        </Form.Item>
        <Collapse accordion items={ items } />

      </ModalComponent>
    </>
  )
}


export default ViewTaxAdjustment;