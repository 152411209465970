import { Layout, Space, Tabs, Spin, message, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import UpdateEmployeeInfo from './UpdateEmployeeInfo';
import UpdateEmploymentInfo from './UpdateEmploymentInfo';
import UpdateUserInfo from './UpdateUserInfo';
import { getProfileCardByEmploymentIdReq, uploadPhotoReq } from '../requests';
import { makeRequestStateless } from "@utils/helpers";
import IndexContacts from '@mods/employeeManagement/contacts/IndexContacts';
import UpdatePayrollPolicy from './UpdatePayrollPolicy';
import { ModalComponent } from "@comps/components"
//import IndexAssets from '../../assets/IndexAssets';
import IndexRelatives from '../../relatives/IndexRelatives';
import IndexExperience from '../../experiences/IndexExperience';
import IndexAddresses from '../../addresses/IndexAddresses';
import IndexEducations from '../../educations/IndexEducations';
import IndexBanks from '../../bank/IndexBanks';

const { Content } = Layout;

const ViewEmployee = (props) => {

  //console.log(props);
  //------------use states start----------------
  const [defaultProfileCard, setDefaultProfileCard] = useState();
  const [loading, setLoading] = useState(true)
  const [content, setContent] = useState(null)
  const employeeId = props.employee_id
  //-----------end use states--------------------
  useEffect(() => {
    getProfileCardByEmploymentId(props.employment_id);
    // eslint-disable-next-line
  }, [props]);

  const getProfileCardByEmploymentId = (id) => {
    makeRequestStateless(getProfileCardByEmploymentIdReq, id, getProfileCardByEmploymentIdSuccess, null)
    setLoading(true)
    setContent(null)
  }
  const getProfileCardByEmploymentIdSuccess = (response) => {
    setFileList(response.profile_card.profile_pic_url === "images/arnold-avatar.jpg" ? [] : [{
      uid: '-1',
      status: 'done',
      url: response.profile_card.profile_pic_url
    }])
    setDefaultProfileCard(response.profile_card)
    setData()
    setLoading(false)
  }
  const TabContentStyle = { background: 'white' }
  const setData = () => {

    setContent(
      <Content style={ TabContentStyle } >
        <Tabs
          tabPosition='top'
          animated={true}
          closable={true}
         
       
        >
          <Tabs.TabPane tab="Employee" key={ 'employeeinfo' }>
            <UpdateEmployeeInfo id={ employeeId } setDefaultProfileCard={ setDefaultProfileCard } />
          </Tabs.TabPane>

          <Tabs.TabPane tab="Employment" key={ 'employmentinfo' }>
            <UpdateEmploymentInfo id={ props.employment_id } setDefaultProfileCard={ setDefaultProfileCard } />
          </Tabs.TabPane>
          <Tabs.TabPane tab="User" key={ 'user' }>
            <UpdateUserInfo id={ employeeId } disabled={ false } setDefaultProfileCard={ setDefaultProfileCard } />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Payroll & Policy" key={ 'payroll' }>
            <UpdatePayrollPolicy id={ props.employment_id } employeeId={employeeId} disabled={ false } />
          </Tabs.TabPane>
       <Tabs.TabPane tab="Bank" key={ 'bank' }>
            <IndexBanks filters={ `employments_|${employeeId}` } />
          </Tabs.TabPane>
            <Tabs.TabPane tab="Address" key={ 'address' }>
            <IndexAddresses filters={ `employments_|${employeeId}` } employee_id={ employeeId } />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Relatives" key={ 'relatives' }>
            <IndexRelatives filters={ `employments_|${employeeId}` } />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Contacts" key={ 'contact' }>
            <IndexContacts filters={ `employments_|${props.employee_id}` } />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Education" key={ 'education' }>
            <IndexEducations filters={ `employments_|${employeeId}` } />
          </Tabs.TabPane> 
          <Tabs.TabPane tab="Experience" key={ 'qualification&experiences' }>
            <IndexExperience filters={ `employments_|${employeeId}` } />
          </Tabs.TabPane>
          {/* <Tabs.TabPane tab="Asset" key={ 'assetsdetails' } >
            <IndexAssets filters={ `employments_|${props.employment_id}` } />
          </Tabs.TabPane>  */}
        </Tabs>
      </Content>
    )
  }

  //-----------styling------------

  const [fileList, setFileList] = useState([])

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const handleCancel = () => setPreviewOpen(false);
  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });


  const onChange = ({ fileList: newFileList }) => {

    // Update the status of the uploaded file based on the response from your backend
    setFileList(newFileList.map(file => {
      if (file.response) {
        // Check if your backend returned a success response
        if (file.response.status === 'success') {
          message.info('Updated Successfully')
          return { ...file, status: 'done' };
        } else {
          // If your backend returned an error response
          message.error(`File upload failed: ${file.response.message}`);
          return { ...file, status: 'error' };
        }
      }
      return file;
    }));
  };

  const beforeUpload = (file) => {
    // Perform any validation or checks before uploading
    return true;
  };

  const uploadPhoto = async ({ file, onSuccess, onError }) => {
    const payload = {
      file: file,
      employeeId: employeeId
    }
    await makeRequestStateless(uploadPhotoReq, payload, () => {
      onSuccess()
      onUpdatedPhoto()
    }, onUpdatedPhoto);
  }
  const removePhoto = async ({ file }) => {
    const payload = {
      file: '',
      employeeId: employeeId
    }
    await makeRequestStateless(uploadPhotoReq, payload, onUpdatedPhoto, onUpdatedPhoto);
  }
  const onUpdatedPhoto = (data, res) => {
    props.onCompleted()
    message.info('Updated Successfully')
  }
  const onPreview = async (file) => {

    if ((file.url !== undefined) && (file.extension === 'pdf' || file.type === 'application/pdf')) {
      // Open the PDF file in a new tab
      window.open(file.url, '_blank');
    }
    else {
      if (file.type !== 'application/pdf') {
        // Handle preview for other file types (e.g., images)
        if (!file.url && !file.preview) {
          file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
      }
    }
  };
  //----------end styling-----------
  return (
    <>
      <Space
        style={ {
          marginBottom: 24,
        } }
      >
      </Space>
      <Spin spinning={ loading }>
        <div style={ { marginTop: -50 } } >
          <Upload
            className=''
            customRequest={ uploadPhoto }
            listType="picture-circle"
            fileList={ fileList }
            accept=".jpg,.jpeg,.png"
            onChange={ onChange }
            onPreview={ onPreview }
            beforeUpload={ beforeUpload }
            onRemove={ removePhoto }
            multiple={ false }
          >
            { fileList.length === 1 ? null : <span>Upload</span> }
          </Upload>
        </div>
        {/* <div style={ { marginTop: -60 } } >
          <img src={ defaultProfileCard ? defaultProfileCard.profile_pic_url : '' } height='95px' style={ { borderRadius: '60px', width: 95, position: "relative", top: 20 } } alt='' />
        </div> */}
        <div style={ { marginTop: -90 } }>
          <span className='offical-email'>{ defaultProfileCard ? defaultProfileCard.official_email : '' }</span>
          <p className='full-name'>{ defaultProfileCard ? defaultProfileCard.full_name : '' }</p>
          <span className='designation-name'>{ defaultProfileCard ? defaultProfileCard.designation_name : '' }</span>
          <p className='mobile-no'>{ defaultProfileCard ? defaultProfileCard.mobile_no : '' }</p>
          <img src={ require("@assets/images/Line 19.png") } alt="Employee" style={ { position: 'relative', left: 115, top: -10 } } />
          <p><span className="age">Age:</span><span className="age-name" >{ defaultProfileCard ? defaultProfileCard.age : '' }</span></p>

          <p><span className="profile">Profile Score:</span><span className="profile-score">{ defaultProfileCard ? defaultProfileCard.profile_score + ' %' : '' }</span></p>
          <p className='code'><span className="codes ps-5">Code:</span><span className="code-id">{ defaultProfileCard ? defaultProfileCard.code : '' }</span></p>
        </div>
      </Spin>


      <Layout style={ { marginTop: -20 } }>

        <Layout hasSider>
          { content }
        </Layout>
      </Layout>

      <ModalComponent width={ 1000 } open={ previewOpen } title={ defaultProfileCard ? defaultProfileCard.full_name : '' } footer={ null } onCancel={ handleCancel }>
        <div style={ {
          textAlign: 'center',
          padding: 100,
          paddingTop: 0
        } }>
          <img
            alt=""
            style={ {
              width: '50%',
            } }
            src={ previewImage }
          />
        </div>
      </ModalComponent>
    </>
  );

};
export default ViewEmployee;
