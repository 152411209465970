import { Form, Button, Space, Row, Col } from "antd"
import { useEffect, useState } from "react"
import { Commons, Const } from "../../constants";
import { SaveButton, CancelButton, ModalComponent, trans, SelectWithSearch, InputText, InputTextArea } from "@comps/components"
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons'
import CreateTaskData from "./CreateTaskData";
const langKey = Const["kpi"].lngKey

const UpdateKpiTasks = (props) => {
  const [employments, setEmployments] = useState([])
  const [childComponent, setChildComponent] = useState(null)
  const [form] = Form.useForm()
  useEffect(() => {
    getRelatedData()
    // eslint-disable-next-line
  }, []);

  const getRelatedData = () => {

    let fields = []
    setEmployments(props.employments)
    form.setFieldsValue(props.record)
    fields = props.record.fields !== undefined ? JSON.parse(props.record.fields) : []
    if (props.record.kpi_task_fields !== undefined) {
      fields = props.record.kpi_task_fields
    }

    form.setFieldValue('kpi_task_fields', fields)

  }

  const onSubmit = (data) => {
    const obj = { ...props.record, ...data }
    props.onCompleted(obj, 'Updated')
  }
  const onCreateTaskData = () => {
    setChildComponent(<CreateTaskData onCompleted={ onCompleted } />)
  }
  const onCompleted = (data, action) => {
    if (data) {
      let listArray = form.getFieldValue('kpi_task_fields');
      if (!listArray || listArray === undefined) {
        listArray = []
      }
      if (data && action === 'Added') {
        listArray.push(data)
        form.setFieldValue('kpi_task_fields', listArray)
        // setPrivilegeFilters(listArray)
      }
      if (data && action === 'Updated') {
        listArray[data.key] = data
        form.setFieldValue('kpi_task_fields', listArray)
        // setPrivilegeFilters(listArray)
      }
    }
    setChildComponent(null)

  }

  const footer = [
    <SaveButton form={ 'createWorkSheetTasks' } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];
  return (

    <ModalComponent top={ 20 } width={ 1000 } maskClosable={ false }
      mainTitle={ trans(Commons.update) } subTitle={ trans(`${langKey}.tasks`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>

      <Form { ...formLayout } name={ 'createWorkSheetTasks' } onFinish={ onSubmit } form={ form } scrollToFirstError>
        <Row gutter={ 30 }>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="title" rules={ rules.title } label={ trans(`${langKey}.title`) }
              className="da-mb-16"
            >
              <InputTextArea placeholder={ trans(`${langKey}.title`) } disabled />
            </Form.Item>
          </Col>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="description" rules={ rules.description } label={ trans(`${langKey}.description`) }
              className="da-mb-16"
            >
              <InputTextArea placeholder={ trans(`${langKey}.description`) } disabled />
            </Form.Item>
          </Col>
        </Row>

        { childComponent }
        <div className="formlist-container">
          <h5 className="formlist-title">Data</h5>

          <Form.List name="kpi_task_fields" >
            { (fields, { add, remove }) => (
              <>
                <Button onClick={ () => {
                  add()
                } } icon={ <PlusOutlined /> } type="primary" className="add-node-button">{ trans(`${langKey}.data`) } </Button>

                <div className="table-container">
                  <span className="text-info">Options should be Seperated By Commas(ok,Not ok,etc)</span>
                  <table className="table">
                    {
                      fields.length !== 0 && <thead>
                        <tr>
                          <th>{ trans(`${langKey}.type`) }</th>
                          <th>{ trans(`${langKey}.name`) }</th>
                          <th>{ trans(Commons.actions) }</th>
                        </tr>
                      </thead>
                    }
                    <tbody>
                      { fields.map(({ key, name, ...restField }) => (
                        <>
                          <tr>
                            <td style={ { minWidth: '200px' } }>
                              <Form.Item
                                { ...restField }
                                name={ [name, 'type'] }
                              >
                                <SelectWithSearch options={
                                  [
                                    {
                                      label: 'Text',
                                      value: 'text'
                                    },

                                    {
                                      label: 'Options',
                                      value: 'options'
                                    },
                                    {
                                      label: 'Date',
                                      value: 'date'
                                    },

                                  ]
                                }
                                  placeholder={ trans(`${langKey}.type`) }
                                //onChange={ (value) => setType(value) }
                                />

                              </Form.Item>
                            </td>
                            <td style={ { minWidth: '200px' } }>
                              <Form.Item
                                { ...restField }
                                name={ [name, 'name'] }
                              >
                                <InputText placeholder={ trans(`${langKey}.name`) } />
                              </Form.Item>
                            </td>

                            <td>
                              <Space>
                                <DeleteOutlined className="formlist-remove" onClick={ () => {
                                  remove(name)
                                  //props.setRows(key, 'Deleted')
                                } } />
                                {/* <EditOutlined onClick={ () => {
                                props.onEdit(key)

                              } } />
                              <EyeOutlined onClick={ () => {
                                props.onView(key)

                              } } /> */}
                              </Space>
                            </td>
                          </tr>
                        </>
                      )) }
                    </tbody>
                  </table>
                </div>
              </>
            ) }
          </Form.List>
        </div>

      </Form>
    </ModalComponent>
  )
}
export default UpdateKpiTasks

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}
const rules = {
  title: [
    { required: true, message: trans(`${langKey}.title`) },
  ],
  type: [
    { required: true, message: trans(`${langKey}.type`) },
  ],
  name: [
    { required: true, message: trans(`${langKey}.name`) },
  ],

}
