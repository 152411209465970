const key = "lendManagement";
const baseRoute = "/lendManagement";

export const Const = {

  lendCategory: {
    lngKey: `${key}.lendCategory`,
    route: ``,
  },
  lendType: {
    lngKey: `${key}.lendType`,
    route: ``,
  },
  companyLends: {
    lngKey: `${key}.companyLends`,
    route: ``,
  },
  lendApproval: {
    lngKey: `${key}.lendApproval`,
    route: ``,
  },
  lendRepayment: {
    lngKey: `${key}.lendRepayment`,
    route: ``,
  },




};

const cmn = "common";
export const Commons = {
  create: `${cmn}.create`,
  update: `${cmn}.update`,
  view: `${cmn}.view`,
  name: `${cmn}.name`,
  code: `${cmn}.code`,
  location: `${cmn}.location`,
  branch: `${cmn}.branch`,
  department: `${cmn}.department`,
  designation: `${cmn}.designation`,
  joiningDate: `${cmn}.joiningDate`,
  grade: `${cmn}.grade`,
  ProfileCompletion: `${cmn}.ProfileCompletion`,
  actions: `${cmn}.actions`,
  is_active: `${cmn}.is_active`,
  status: `${cmn}.status`,
  description: `${cmn}.description`,
  shortname: `${cmn}.shortname`,
  country: `${cmn}.country`,
  history: `${cmn}.history`,
  flow: `${cmn}.flow`,
};
export const colors = {
  "bg-warning": "#faad14",
  "bg-warning-color": "white",
  "bg-success": "green",
  "bg-success-color": "white",
  "bg-darkgray": "#333",
  "bg-danger": "#d9534f"
}


