import { useState, useEffect } from "react";
import {
  BodyComponent,
  TableComponent,
  ActionComponent,
  CreateButton,RefreshButton,
  trans,
  HeaderComponent
} from "@comps/components";
import { makeRequest } from "@utils/helpers";
import { Commons, Const } from "../../constants";
import { message } from 'antd';
import { deleteDataReq, getAllDataReq } from "./requests";
import CreateQuestion from "./components/CreateQuestion";
import UpdateQuestion from "./components/UpdateQuestion";


const langKey = Const["questions"].lngKey;

const pageConfig = {
  headers: {
    title: trans(`${langKey}.moduleName`),
    breadcrumb: [
      {
        name: trans(`${langKey}.moduleName`),
        path: Const["questions"].route
      }
    ]
  }
}
//========================================
const IndexQuestions = (props) => {

  const [dataSource, setDataSource] = useState([]);
  const [loader, setLoader] = useState(false);
  const [childComponent, setChildComponent] = useState(null);
  const [allData,setAllData]=useState()
  // ==================================
  //   Use States End
  // ==================================

  // ================================== UpdateLocation
  //   Table Data End
  // ==================================

  const columns = [
    {
      key: 'question_no',
      title: trans(`${langKey}.question_no`),
      dataIndex: "question_no",
    },
    // {
    //   key: 'category',
    //   title: trans(`${langKey}.category`),
    //   dataIndex: "category",
    // },
    {
      key: 'name',
      title: trans(`${langKey}.name`),
      dataIndex: "name",
    },
    {
      key: 'type',
      title: trans(`${langKey}.type`),
      dataIndex: "type",
    },
    {
      key: 'data',
      title: trans(`${langKey}.data`),
      dataIndex: "data",
    },
    {
      key: 'is_active',
      title: trans(`${langKey}.is_active`),
      render: (record)=>(
        record.is_active?'Yes':'No'
      ),
    },
    // {
    //   key: 'description',
    //   title: trans(`${langKey}.description`),
    //   dataIndex: "description",
    // },
   

    {
      key: trans(Commons.actions),
      title: trans(Commons.actions),
      render: (record) => (
        <ActionComponent
          each={ record } modkey={props.modkey}
          onDelete={ onDelete }
          onEdit={ onEdit }
          onView={ onView }
        ></ActionComponent>
      ),
    },
  ];

  // ==================================
  //   Table Data End
  // ==================================

  useEffect(() => {
    getAllData();
    // eslint-disable-next-line
  }, []);

  const getAllData = () => {
    const payload = {
      page: 1,
      filters: ''
    }
    makeRequest(setLoader, getAllDataReq, payload, onSuccess, null);
  };

  const onSuccess = (response) => {

setAllData(response);
    setDataSource(response.questions);

  };

  const onEdit = (record) => {
    setChildComponent(
      <UpdateQuestion onCompleted={ onCompleted } record={ record } disabled={ false } allData={allData}/>
    );
  };

  const onView = (record) => {
    setChildComponent(
      <UpdateQuestion onCompleted={ onCompleted } record={ record } disabled={ true } allData={allData}/>
    );
  };

  const onDelete = (record) => {
    makeRequest(setLoader, deleteDataReq, record.id, onDeleted, onError);
  };
  const onDeleted = (res) => {
    getAllData()
    message.success("Record Deleted")
  };

  const onError = (err, res) => {
    message.error(res.response.data.message);
    //setErrors(errorList);
  }

  // Create component modal
  const onCreate = () => {
    setChildComponent(<CreateQuestion onCompleted={ onCompleted } onSuccess={ onSuccess } allData={allData}/>);
  };

  const onCompleted = (data) => {
    if(data){
      getAllData()
    }
    setChildComponent(null);
  };


  return (
    <>

      { childComponent }
      <BodyComponent>
        <HeaderComponent headers={ pageConfig.headers } modkey={props.modkey}>
          <RefreshButton onClick={ getAllData } /> <CreateButton onClick={ onCreate } />
        </HeaderComponent>
        <TableComponent modkey={props.modkey}
          loader={ loader }
          columns={ columns }
          dataSource={ dataSource }
        />
      </BodyComponent>

    </>
  );
};

export default IndexQuestions;
