import React, { useEffect, useState } from "react";
import { Form, message, Skeleton, Row, Col } from "antd";
import { SaveButton, CancelButton, ModalComponent, trans, InputText, InputTextArea } from "@comps/components"
import { makeRequest, makeRequestStateless, } from "@utils/helpers";
import { createDataReq, getRelatedDataReq } from "../requests";
import { Commons, Const } from "../../constants";
import { createSelectList, getPlaceholder } from '@comps/commonFunctions';
import { getEmploymentsDataReq } from "@mods/common/requests";
import ViewWorkSheetTasks from "./ViewWorkSheetTasks";
import CreateKpiTasks from "./CreateKpiTasks";
import { useSelector } from "react-redux";
import UpdateKpiTasks from "./UpdateKpiTasks";
//import CreateKpiTasks from "./CreateKpiTasks";
//import UpdatePrivilegeFilters from "./UpdatePrivilegeFilters";

const langKey = Const["kpi"].lngKey
const formName = "CreateKpi";

const CreateKpi = (props) => {
  const [loader, setLoader] = useState(false);
  const [saving, setsaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const [employments, setEmployments] = useState([])
  const [relatedData, setRelatedData] = useState('')
  const [childComponent, setChildComponent] = useState(null)
  const authUser = useSelector((state) => state.auth.authUser);
  const [form] = Form.useForm()
  useEffect(() => {
    getData()
    // eslint-disable-next-line
  }, []);

  const getData = () => {
    getEmployments()
    // getRelatedData()
  }
  const getRelatedData = () => {
    makeRequestStateless(getRelatedDataReq, null, onGetRelated, false)
  }
  const onGetRelated = (response, data) => {
    setRelatedData(response)
  }
  const getEmployments = () => {
    const payload = { filters: null }
    makeRequestStateless(getEmploymentsDataReq, payload, onGetEmployments, false)

  }
  const onGetEmployments = (response) => {
    setLoading(false)
    setEmployments(createSelectList(response.resources.data))
  }

  const onSubmit = (data) => {
    let tasks = []
    data.kpi_tasks !== undefined && data.kpi_tasks.forEach(task => {

      const obj = {
        title: task.title,
        description: task.description,
        fields: JSON.stringify(task.kpi_task_fields)
      }
      tasks.push(obj)
    })

    const payload = { kpi: { title: data.title, short_title: data.short_title, description: data.description, assignby_id: authUser.employment_id, assignto_id: null, verifiedby_id: null }, kpi_tasks: tasks };
    setsaving(true)
    makeRequest(setLoader, createDataReq, payload, onSuccess, onError);
  }

  const onSuccess = (data, res) => {
    message.success(res.message);
    props.onCompleted(data);
  }

  const onError = (err, res) => {
    setsaving(false)
    message.error(res.response.data.message);
  }
  // ==================================slabs actions =================================
  const onCreate = () => {
    setChildComponent(<CreateKpiTasks onCompleted={ onCompleted } allData={ relatedData } employments={ employments } />)
  }

  //edit slab
  const onEdit = (key) => {
    const record = form.getFieldValue(`kpi_tasks`)[key];
    setChildComponent(<UpdateKpiTasks onCompleted={ onCompleted } allData={ relatedData } record={ { ...record, key: key } } disabled={ false } employments={ employments } />)
  };
  const onView = (key) => {
    const record = form.getFieldValue(`kpi_tasks`)[key];
    setChildComponent(<UpdateKpiTasks onCompleted={ onCompleted } allData={ relatedData } record={ { ...record, key: key } } disabled={ true } employments={ employments } />)
  };
  //set deleted rows
  const setRows = (key, state) => {

  }

  const onCompleted = (data, action) => {
    let listArray = form.getFieldValue('kpi_tasks');
    if (listArray === undefined) {
      listArray = []
    }
    if (data && action === 'Added') {
      listArray.push(data)
      form.setFieldValue('kpi_tasks', listArray)

    }
    if (data && action === 'Updated') {
      listArray[data.key] = data
      form.setFieldValue('kpi_tasks', listArray)

    }
    setChildComponent(null)
  }
  //-----------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------
  const footer = [
    <SaveButton loading={ saving } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];
  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } width={ 1200 } maskClosable={ false }
      mainTitle={ trans(Commons.create) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) } >
      { childComponent }
      <Skeleton loading={ loading }>
        <Form { ...formLayout } name={ formName } onFinish={ onSubmit } form={ form } scrollToFirstError>
          <Row gutter={ 30 }>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="title" rules={ rules.title } label={ trans(`${langKey}.title`) }
                className="da-mb-16"
              >
                <InputTextArea placeholder={ getPlaceholder(trans(`${langKey}.title`)) } />
              </Form.Item>
            </Col>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="short_title" rules={ rules.short_title } label={ trans(`${langKey}.short_title`) }
                className="da-mb-16"
              >
                <InputText placeholder={ getPlaceholder(trans(`${langKey}.short_title`)) } />
              </Form.Item>
            </Col>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="description" rules={ rules.description } label={ trans(Commons.description) }
                className="da-mb-16"
              >
                <InputTextArea placeholder={ getPlaceholder(trans(Commons.description)) } />
              </Form.Item>
            </Col>
          </Row>

          <div className="formlist-container">
            <h5 className="formlist-title">{ trans(`${langKey}.tasks`) }</h5>
            <ViewWorkSheetTasks setRows={ setRows } onCreate={ onCreate } onEdit={ onEdit } onView={ onView } employments={ employments } />
          </div>

        </Form>
      </Skeleton>

    </ModalComponent>
  )
}

export default CreateKpi

const rules = {

  title: [
    { required: true, message: trans(`${langKey}.title`) },
  ],
  short_title: [
    { required: false, message: trans(`${langKey}.short_title`) },
  ],
  assignby_id: [
    { required: false, message: trans(`${langKey}.assignby_id`) },
  ],
  assignto_id: [
    { required: true, message: trans(`${langKey}.assignto_id`) },
  ],
  verifiedby_id: [
    { required: true, message: trans(`${langKey}.verifiedby_id`) },
  ],


};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}
