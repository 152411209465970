import { Table } from "antd";
import React, { useMemo } from "react";
import { checkPermissions } from '@comps/commonFunctions';

const BaseTable = (props) => {
  let { columns, loader, ...otherProps } = props;

  const tab = useMemo(() =>
    <div className="basetable-container" style={ {
      /* Set a minimum width for cells */
     
    } }>
      {checkPermissions(props.modkey,'view') ? <Table rowKey="id" size="small" { ...otherProps } columns={ columns } showSorterTooltip={ false } tableLayout="fixed" loading={ loader } scroll={ { x: 'max-content' } } pagination={ false } />:<div className="text-danger text-center">No You have no Permission to view data!</div>}
      </div>, [otherProps.dataSource, loader]);// eslint-disable-line react-hooks/exhaustive-deps

  return tab;
};

export default BaseTable;