import React, { useState } from "react";
import { Form, message } from "antd";
import { SaveButton, InputText, CancelButton, ModalComponent, trans } from "@comps/components"
import { makeRequest, getErrorProps } from "@utils/helpers";

import { createJobTitleReq } from "../requests";

import { Commons, Const } from "../../constants";

const langKey = Const["jobTitle"].lngKey

const formName = "createJobTitle";

const CreateJobTitle = (props) => {
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [saving, setsaving] = useState(false);
  const onSubmit = (data) => {
    setsaving(true)
    const payload = { name: data.name }
    makeRequest(setLoader, createJobTitleReq, payload, onSuccess, onError);
  }

  const onSuccess = (data, res) => {
    message.success("JobTitle Saved successfully");
    setLoader(true)
    props.onCompleted(data.job_titles.data);
    props.onSuccess(data)

  }

  const onError = (err, res) => {
    setsaving(false)
    message.error(res.response.data.message);
    //setErrors(errorList);
  }
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [
    <SaveButton loading={ saving } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } maskClosable={ false }
      mainTitle={ trans(Commons.create) } subTitle={ trans(`${langKey}.JobTitle`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>

      <Form { ...formLayout } name={ formName } onFinish={ onSubmit }>
        <Form.Item name="name" rules={ rules.name } label={ trans(`${langKey}.name`) }
          className="da-mb-16"
          { ...getErrorProps(errors['name']) } size="small"
        >
          <InputText placeholder={ trans(`${langKey}.name`) } />
        </Form.Item>
      </Form>
    </ModalComponent>
  )
}

export default CreateJobTitle

const rules = {
  name: [
    { required: true, message: trans(`${langKey}.name.required`) },
  ],
};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  // wrapperCol: { span: 14, offset: 2 },
  labelAlign: "left"
}