import { useState, useEffect } from "react";
import {
  HeaderComponent,
  BodyComponent,
  TableComponent,
  ActionComponent,
  CreateButton,RefreshButton,
  trans,
  PaginationComponent
} from "@comps/components";
import { makeRequest } from "@utils/helpers";
import { getAllLocationsReq, deleteLocationReq } from "./requests";
import CreateLocation from "./components/CreateLocation";
import UpdateLocation from "./components/UpdateLocation";
import { Commons, Const } from "../constants";
import { message } from 'antd';
import { FilterComponent, FilterButton } from "@comps/components";
// ==================================
//   Page Breadcrumbs Start
// ==================================
const langKey = Const["location"].lngKey;

const pageConfig = {
  headers: {
    title: trans(`${langKey}.moduleName`),
    breadcrumb: [
      {
        name: trans(`${langKey}.moduleName`),
        path: Const["location"].route,
      },
    ],
  },
};
// ==================================
//   Page Breadcrumbs End
// ==================================
const IndexLocations = (props) => {
  const [totalRecords, setTotalRecords] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [loader, setLoader] = useState(false);
  const [childComponent, setChildComponent] = useState(null);
  const [filerComponent, setFilterComponent] = useState(null);
  const [filters, setFilters] = useState();
  //start pagination states
  const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState({
    "total": 0,
    "count": 0,
    "per_page": 0,
    "current_page": 0,
    "total_pages": 0,
    "links": {}
  });
  //end pagination states
  // ==================================
  //   Use States End
  // ==================================
  // ==================================
  //   Table Data End
  // ==================================
  const columns = [
    {
      key: trans(Commons.code),
      title: trans(Commons.code),
      dataIndex: "code",
    },
    {
      key: trans(Commons.name),
      title: trans(Commons.name),
      dataIndex: "name",
    },
    {
      key: trans(Commons.companyName),
      title: trans(Commons.companyName),
      dataIndex: "company_name",
    },
    {
      key: trans(Commons.actions),
      title: trans(Commons.actions),
      render: (record) => (
        <ActionComponent
          each={ record } modkey={props.modkey}
          onDelete={ onDelete }
          onEdit={ onEdit }
        ></ActionComponent>
      ),
    },
  ];
  // ==================================
  //   Table Data End
  // ==================================
  useEffect(() => {
    getAllData();
    // eslint-disable-next-line
  }, [filters, currentPage]);

  const getAllData = () => {
    const payload = {
      page: currentPage,
      filters: filters
    }
    makeRequest(setLoader, getAllLocationsReq, payload, onSuccess, onSuccess);
  };

  const onSuccess = (response) => {
    setPagination(response.locations);
    setDataSource(response.locations.data);
  };

  const onEdit = (record) => {
    setChildComponent(
      <UpdateLocation onCompleted={ onCompleted } record={ record } disabled={ false } />
    );
  };
  const onDelete = (record) => {
    makeRequest(setLoader, deleteLocationReq, record.id, onDeleted, onError);
  };
  const onDeleted = (res) => {
    onSuccess(res)
    setDataSource(res.locations.data);
    message.success("Location Deleted Successfully!!!")
  };
  const onError = (err, res) => {
    let errorList = [];
    message.error(res.response.data.message);
    //setErrors(errorList);
  }
  // Create component modal
  const onCreate = () => {
    setChildComponent(<CreateLocation onCompleted={ onCompleted } onSuccess={ onSuccess } />);
  };

  const onCompleted = (data) => {
    if (data.length) {
      setDataSource(data);
    }
    setChildComponent(null);
  }
  //---------------filters-----------------------
  const onOpenFilter = () => {
    setFilterComponent(<FilterComponent filters={ filterFields } modkey={props.modkey} selected={ filters } api={ null } setCurrentPage={setCurrentPage} onFilter={ setFilters } onCloseFilter={ onCloseFilter }
    />
    )
  }

  const onCloseFilter = () => {
    setFilterComponent(null);
  }
  //----end filter--------------------
  const handleTableChange = (page, fil, sorter) => {
    let payload = {
      ...pagination,
      current: page.current,
      pageSize: page.pageSize,
      sortName: sorter.field || "id",
      sortType: sorter.order === "ascend" ? "asc" : "desc",
    };
    setPagination(payload);
  };

  return (
    <>
      { childComponent }
      { filerComponent }
      <HeaderComponent headers={ pageConfig.headers } modkey={props.modkey}>
        <RefreshButton onClick={ getAllData } /> <CreateButton onClick={ onCreate } />
        <FilterButton onClick={ onOpenFilter } />
      </HeaderComponent>
      <BodyComponent>
        <TableComponent modkey={props.modkey}
          loader={ loader }
          columns={ columns }
          dataSource={ dataSource }
          pagination={ { ...pagination, total: totalRecords } }
          onChange={ handleTableChange }
        />
      </BodyComponent>
      <PaginationComponent pagination={ pagination } modkey={props.modkey} setCurrentPage={ setCurrentPage } currentPage={ currentPage } />
    </>
  );
};

export default IndexLocations;

const filterFields = [
  {
    type: "text",
    placeholder: "Code",
    key: "code"
  },
  {
    type: "text",
    placeholder: "Name",
    key: "name"
  },
]