import React, { useEffect, useState } from "react";
import { Form, message } from "antd";
import { SaveButton, CancelButton, ModalComponent, trans, FormItem } from "@comps/components";
import { makeRequest } from "@utils/helpers";
import { createLocationReq } from "../requests";
import { Commons, Const } from "../../constants";
const langKey = Const["location"].lngKey
const formName = "createLocation";
const CreateLocation = (props) => {
  const [loader, setLoader] = useState(false);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line
  }, []);

  const onSubmit = (data) => {
    setSaving(true)
    const payload = { name: data.name }
    makeRequest(setLoader, createLocationReq, payload, onSuccess, onError);
  }
  const onSuccess = (data, res) => {
    message.success("Location Saved successfully");
    props.onCompleted(data.locations.data);
    props.onSuccess(data)
    setSaving(false)
  }
  const onError = (err, res) => {
    setSaving(false)
    message.error(res.response.data.message);
    //setErrors(errorList);
  }
  // -----------------------------------
  // Start footer buttons array
  // ------------------------------------
  const footer = [
    <SaveButton loading={ saving } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];
  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------
  return (
    <ModalComponent top={ 20 } maskClosable={ false } mainTitle={ trans(Commons.create) } subTitle={ trans(`${langKey}.location`) } open={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Form
        layout="vertical"
        name={ formName }
        onFinish={ onSubmit }
      >
        <FormItem name="name" type="text" label="Name" rule={ rules.name } />
      </Form>
    </ModalComponent>
  )
}

export default CreateLocation
const rules = {
  name: [
    { required: true, message: trans(`${langKey}.name.required`) },
  ],
  companies: [
    { required: true, message: trans(`${langKey}.selectCompany.required`) },
  ],
  description: [
    { required: true, message: trans(`${langKey}.description.required`) },
  ]
};