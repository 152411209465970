import React, { useState } from "react";
import { Form, message, Divider, Upload } from "antd";
import {
  SaveButton, CancelButton, ModalComponent, trans,
  TableComponent, ButtonComponent

} from "@comps/components"
import { makeRequestStateless } from "@utils/helpers";
import { importEmployeeDataReq } from "../requests";
import { Commons, Const } from "../../constants";
import { getErrorColumns } from "@comps/commonFunctions";
const langKey = Const["employee"].lngKey
const formName = "ImportEmployees";

const ImportEmployees = (props) => {

  const [saving, setSaving] = useState(false)
  const [childComponent, setChildComponent] = useState(null)

  const onSubmit = (data) => {
    let file = null
    if (data.file_attachment === undefined) {
      message.info("Please Select File To Import!")
      return
    }
    if (data.file_attachment !== undefined) {
      file = data.file_attachment.fileList.length > 0 ? data.file_attachment.file : null
    }
    const payload = { file_attachment: file, employees_import: { attachment: { removed: false, org_name: file.name, key: "attachment" } } };
    makeRequestStateless(importEmployeeDataReq, payload, onSuccess, onError);
    setSaving(true);
  }


  const onSuccess = (res, response) => {
    setSaving(false);
    if (response.code === "ERR_BAD_RESPONSE") {
      message.error(response.response.data.message);
      setChildComponent(null)
      setFileList([])
      return;
    }
    let errors = [];
    if (!response.success) {
      errors = response.errors;
    }
    if (response.success === true) {
      message.success('Imported Successfully')
      props.onCompleted(true);
      setChildComponent(null)
      return;
    }
    if (errors && errors.length === 0) {
      message.success(response.message);
      props.onCompleted(true)
      setChildComponent(null)
    }
    if (errors !== undefined && errors.length > 0) {
      setFileList([]);
      setChildComponent(
        <>
          <Divider className="text-danger">Importing Errors</Divider>
          <TableComponent
            scroll={ { x: 'max-content' } }
            columns={ getErrorColumns() }
            dataSource={ errors }
            pagination={ false }
          />
        </>
      )
      return;
    }
    props.onCompleted(true)

  }

  const onError = (response, err) => {
    
    setSaving(false)
    let errors = [];
    if (!response.success) {
      errors = response.errors;
    }
    if (errors !== undefined && errors.length > 0) {
      setFileList([]);
      setChildComponent(
        <>
          <Divider className="text-danger">Importing Errors</Divider>
          <TableComponent
            scroll={ { x: 'max-content' } }
            columns={ getErrorColumns() }
            dataSource={ errors }
            pagination={ false }
          />
        </>
      )
      return;
    }

    if (response.success === true) {
      setChildComponent(null);
      props.onCompleted(false);
      message.success('Imported Successfully')
    }

  }

  //============uploading file============
  const [fileList, setFileList] = useState([]);

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  //=========================================

  const footer = [
    fileList.length > 0 &&
    <SaveButton form={ formName } key="create_button" htmlType="submit" loading={ saving } />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 50 } width={ 900 } maskClosable={ false }
      mainTitle={ trans(Commons.view) } subTitle={ trans(`${langKey}.import`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>

      <Form name={ formName } onFinish={ onSubmit }>

        <Form.Item name="file_attachment" rules={ rules.file_attachment } label={ trans(`${langKey}.file_attachment`) }
          className="da-mb-16"
          size="small"
        >
          <Upload
            accept=".xlsx"
            listType="picture"
            fileList={ fileList }
            onChange={ onChange }
            //onPreview={ onPreview }
            multiple={ false }
            beforeUpload={ (file) => {
              return false;
            } }
          >
            { fileList.length === 1 ? null : <ButtonComponent text="Upload" /> }
          </Upload>
        </Form.Item>
      </Form>

      { childComponent }
    </ModalComponent>
  )
}

export default ImportEmployees


const rules = {
  file_attachment: [
    { required: true, message: trans(`${langKey}.file_attachment`) },
  ],

};