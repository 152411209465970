import { get, del, post, put } from "@utils/axios";

const api = "employee";

export const getAllContactsReq = (payload) => {
  return get(`${api}/contacts?page=${payload.page}&filters=${payload.filters}`);
};

export const createContactReq = (payload) => {
  return post(`${api}/contacts?&filters=${payload.filters}`, payload);
};

export const updateContactReq = (payload) => {
  return put(`${api}/contacts/${payload.id}?&filters=${payload.filters}`, payload);
};

export const deleteContactReq = (id) => {
  return del(`${api}/contacts/${id}`);
};

//-----------on create
export const getAllEmployeesReq = () => {
  return get("resources/employee?page=1");
};

export const getRelatedDataReq = () => {
  return get(`${api}/contacts/get-related-data?`)
}
export const getFilterReq = () => {
  return get(`filters?company_id=1&fields=page=1&filters=supporting_fields_|contact_type,employees;preset_|employee_contacts;`)
}


