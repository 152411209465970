

import React, { useEffect, useState } from "react";
import { Form, message } from "antd";
import { SaveButton, CancelButton, ModalComponent, trans, TableComponent } from "@comps/components"
import { makeRequest } from "@utils/helpers";
import { Commons, Const } from "./constants";
import TextArea from "antd/es/input/TextArea";
import moment from "moment";
import { createMessageConsentReq } from "./requests";

const langKey = Const["consents"].lngKey
const formName = "createConsentChatForm";

const CreateConsentChat = (props) => {
  const [loader, setLoader] = useState(false);
  const [dataSource, setDataSource] = useState([])
  const [saveLoader, setSaveLoader] = useState(false)
  useEffect(() => {
    setData()
  }, []);

  const setData = () => {
    setDataSource(props.record.messages)
  }
  const onSubmit = (data) => {
    const payload = { consent: { request_as: props.request_as, consent_id: props.record.id, message: data.message }, api: props.api }
    makeRequest(setLoader, createMessageConsentReq, payload, onSuccess, onError);
    setSaveLoader(true)
  }
  const onSuccess = (data) => {
    setSaveLoader(false)
    props.onCreatedConsent(data)
  }
  const onError = (err, res) => {
    message.error(res.response.data.message);
    setSaveLoader(false)
  }

  const consentChatColumns = [
    {
      key: 'consentor_name',
      title: trans(`${langKey}.consentor_name`),
      render: () => {
        return props.record.consentor_name
      }
    },
    {
      key: 'message',
      title: trans(`${langKey}.message`),
      render: (record) => {
        return record.message
      }
    },
    {
      key: 'date',
      title: trans(`${langKey}.date`),
      render: (record) => {
        return record.created_at ? moment(record.created_at).format('DD-MM-YYYY h:mm:ss a') : null
      }
    },
  ]

  const footer = [
    <SaveButton loading={ saveLoader } type="primary" htmlType="submit" form={ formName } key="create_button" />,
    <CancelButton key="back" onClick={ () => props.onCreatedConsent(null) } />
  ]

  return (
    <>
      <ModalComponent mainTitle={ trans(Commons.view) } subTitle={ "Consents" } open={ true } footer={ footer } onCancel={ () => props.onCreatedConsent(false) }>
        <TableComponent
          columns={ consentChatColumns }
          dataSource={ dataSource }
        />
        <Form { ...formLayout } onFinish={ onSubmit } name={ formName } scrollToFirstError>

          <Form.Item style={ { paddingTop: 20 } } name="message" rules={ rules.message } label={ trans(`${langKey}.consent_message`) }
            className="da-mb-16"

          >
            <TextArea
              placeholder={ 'Type Message' }
            />
          </Form.Item>

        </Form>
      </ModalComponent>

    </>
  )

}

export default CreateConsentChat

const rules = {
  employment_id: [
    { required: true, message: trans(`${langKey}.employment_id`) },
  ],
  message: [
    { required: true, message: trans(`${langKey}.consent_message`) },
  ],

};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}
