import { get, del, put, postFd, putFd } from "@utils/axios";

const api = "companies";
const apiTimeZones = "common/getTimezones"
const apiCurrencies = "common/getCurrencies"
export const getCompanies = (payload) => {
  return get(`${api}?page=1&filters=${payload}`)
}

export const createCompanyReq = (payload) => {
  return postFd(`${api}?`, payload);
}

export const updateCompanyReq = (payload) => {
  return postFd(`${api}/update/`, payload);
}

export const getAllTimeZonesReq = () => {
  return get(apiTimeZones)
};

export const getAllCurrenciesReq = () => {
  return get(apiCurrencies)
};
export const getCompanyReq = () => {
  return get('common/getCompany')
}

export const getFilterReq = (payload) => {
  return get(`${api}?page=1&filters?company_id=1&fields=page=1&filters=preset_|companies;`)
}



