import React, { useEffect, useState } from "react";
import { Button, Form, Skeleton, message, Row, Col } from "antd";
import { SaveButton, CancelButton, ModalComponent, trans, InputDate, InputTime, TableComponent, ActionComponent, FlowHistoryButton, InputTextArea } from "@comps/components"
import { makeRequest, getErrorProps } from "@utils/helpers";
import { Commons, Const } from "../../../constants";
import moment from "moment/moment";
import RoasterView from "@mods/common/RoasterView";
import { getRosterReq } from "@mods/common/requests";
import dayjs from 'dayjs';
import { approveRequest } from "../requests";
import CreateConsent from "@mods/common/CreateConsent";
import CreateConsentChat from "@mods/common/CreateConsentChat";
import EmployeeInfo from '@mods/common/EmployeeInfo';
const langKey = Const["officialDutyApproval"].lngKey
const formName = "updateofficialDutyApproval";

const UpdateOfficialDutyApproval = (props) => {
  const [loader, setLoader] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [roasterView, setRoasterView] = useState(null)
  const [preEdit, setPreEdit] = useState(true)
  const [remarks, setRemarks] = useState('');
  const [consents, setConsents] = useState([]);
  const [creatConsentModal, setCreateConsentModal] = useState(null);
  const [childComponent, setChildComponent] = useState(null)

  const [loading, setLoading] = useState(true)
  const [form] = Form.useForm()
  useEffect(() => {
    setData();
    // eslint-disable-next-line
  }, []);
  const setData = () => {
    setConsents(props.record.consents)
    let date = props.record.date ? dayjs(props.record.date) : null
    getRoster(date)
    props.record.date = date
    props.record.start_time = props.record.start_time ? dayjs(props.record.start_time) : null
    props.record.end_time = props.record.end_time ? dayjs(props.record.end_time) : null
    form.setFieldsValue(props.record);
  }
  const getRoster = (date) => {
    const payload = { official_duty: { employment_id: props.record.employment_id, date: date.format('YYYY-MM-DD') }, api: 'companyOfficialDuty' }
    makeRequest(setLoader, getRosterReq, payload, onGetRosterSuccess, onGetRosterSuccess);
  }
  const onGetRosterSuccess = (response, data) => {
    if (data.shifts) {
      setRoasterView(<RoasterView record={ data } />)
      let start_time = data.shifts[moment(data.from_date).format('YYYYMMDD') + '-' + data.employments[0].id][0]['start']
      let end_time = data.shifts[moment(data.from_date).format('YYYYMMDD') + '-' + data.employments[0].id][0]['end']

      if (preEdit === false) {
        form.setFieldsValue({
          start_time: dayjs(start_time),
          end_time: dayjs(end_time),
        })
      }
    }
    else {
      message.error('No Shift Found For Given Date!')
      return
    }
    setLoading(false)
  }

  //===========constents =============
  const onCreateConsent = () => {
    setCreateConsentModal(<CreateConsent onCreatedConsent={ onCreatedConsent } employment_id={ props.record.employment_id } request_id={ props.record.id } request_as={ props.requestAsId } api="officialDutyApprovals/createConsent" />)
  }
  const onCreatedConsent = (response) => {
    if (response) {
      message.success("Consent created successfully");
      props.onCompleted(response.official_duties.data);
    }

    setCreateConsentModal(null)
  }
  const onConsentChat = (record) => {
    setCreateConsentModal(<CreateConsentChat record={ record } onCreatedConsent={ onCreatedConsent } request_as={ props.requestAsId } api="officialDutyApprovals/messageConsent" />)
  }
  //============end consents================================
  const onSubmit = (isApproval) => {
    setSaveLoader(true)
    const last_remarks = form.getFieldValue('last_remarks');
    let requestObj = {
      request: {
        ...props.record, request_as: props.requestAsId,
        is_approval: isApproval,
        last_remarks: last_remarks,
        remarks: last_remarks
      }, delegator_id: props.delegatorId, api: props.api, filters: props.filters
    }

    const payload = requestObj
    makeRequest(setLoader, approveRequest, payload, onSuccess, onError);
  }
  const onSuccess = (data, res) => {
    setSaveLoader(false)
    message.success("Saved successfully");
    props.onCompleted(data.official_duties.data);
  }
  const onError = (err, res) => {
    setSaveLoader(false)
    message.error(res.response.data.message);
  }
  //----------------------
  const consentColumns = [
    {
      key: 'consentor_name',
      title: trans(`${langKey}.consentor_name`),
      dataIndex: 'consentor_name'
    },
    {
      key: 'consentee_name',
      title: trans(`${langKey}.consentee_name`),
      dataIndex: 'consentee_name'
    },
    {
      key: 'consent_date',
      title: trans(`${langKey}.consent_date`),
      render: (record) => {
        return record.created_at ? moment(record.created_at).format('DD-MM-YYYY h:mm:ss a') : null
      }
    },
    {
      key: trans(Commons.actions),
      title: trans(Commons.actions),
      render: (record) => (
        <ActionComponent
          each={ record } modkey={props.modkey}
          onView={ onConsentChat }
        ></ActionComponent>
      ),
    },
  ]
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------
  const footer = [
    childComponent,
    <div style={ { float: 'left' } }>
      < FlowHistoryButton record={ props.record } setChildComponent={ setChildComponent } />
    </div>,
    <SaveButton disabled={ props.disabled } loading={ saveLoader } form={ formName } key="create_button" onClick={ () => onSubmit(true) } text='Approve' />,
    <CancelButton disabled={ props.disabled } loading={ saveLoader } key="reject_button" form={ formName } htmlType='submit' text='Reject' onClick={ () => onSubmit(false) } />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];
  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------
  return (
    <ModalComponent top={ 20 } width={ 1200 } maskClosable={ false }
      mainTitle={ trans(props.disabled ? Commons.view : Commons.update) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Skeleton loading={ loading }>
        <EmployeeInfo record={ props.record.requestor && props.record.requestor } />
        <Form { ...formLayout } name={ formName } onFinish={ onSubmit } form={ form } disabled={ true } >
          <h5 className="formlist-title">Request</h5>
          <Row gutter={ 30 }>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="date" rules={ rules.date } label={ trans(`${langKey}.date`) }
                className="da-mb-16"
                { ...getErrorProps(errors['date']) } size="small"
              >
                <InputDate />
              </Form.Item>
            </Col>
          </Row>
          { roasterView }
          <Row gutter={ 30 }>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="start_time" rules={ rules.start_time } label={ trans(`${langKey}.start_time`) }
                className="da-mb-16"
                { ...getErrorProps(errors['start_time']) } size="small"
              >
                <InputTime placeholder={ trans(`${langKey}.start_time`) } />
              </Form.Item>
            </Col>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="end_time" rules={ rules.end_time } label={ trans(`${langKey}.end_time`) }
                className="da-mb-16"
                { ...getErrorProps(errors['end_time']) } size="small"
              >
                <InputTime placeholder={ trans(`${langKey}.end_time`) } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="reason" rules={ rules.reason } label={ trans(`${langKey}.reason`) }
                className="da-mb-16"
                { ...getErrorProps(errors['reason']) } size="small"
              >
                <InputTextArea placeholder={ trans(`${langKey}.reason`) } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="last_remarks" rules={ rules.last_remarks } label={ trans(`${langKey}.last_remarks`) }
                className="da-mb-16"
                { ...getErrorProps(errors['last_remarks']) } size="small"
              >
                <InputTextArea placeholder={ trans(`${langKey}.last_remarks`) } disabled={ props.disabled } onChange={ (e) => setRemarks(e.target.value) } />
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <h5 className="formlist-title">Consents <Button type="primary" title='Create' onClick={ onCreateConsent } className="float-end me-5">Create</Button></h5>
        { creatConsentModal }
        <TableComponent dataSource={ consents } columns={ consentColumns } />
      </Skeleton>
    </ModalComponent>
  )
}

export default UpdateOfficialDutyApproval

const rules = {
  name: [
    { required: true, message: '' },
  ],

};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}