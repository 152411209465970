import { useState, useEffect } from "react";
import {
  BodyComponent,
  InputText,
  trans,
  HeaderComponent,
  SelectWithSearch,
  SaveButton
} from "@comps/components";
import { Checkbox, Col, Form, Row, Skeleton, message } from "antd";
import { makeRequest } from "@utils/helpers";
import { Const } from "../../constants";
import { getAllDataReq, createDataReq } from "./requests";
import { createSelectList } from '@comps/commonFunctions';

const langKey = Const["leaveConfiguration"].lngKey;
const pageConfig = {
  headers: {
    title: trans(`${langKey}.moduleName`),
    breadcrumb: [
      {
        name: trans(`${langKey}.moduleName`),
        path: Const["leaveConfiguration"].route
      }
    ]
  }
}

const IndexLeaveConfiguration = (props) => {
  const [loader, setLoader] = useState(false);
  const [leaveTypes, setLeaveTypes] = useState([]);
  const [leaveTypesAll, setLeaveTypesAll] = useState([]);
  const [configuration, setConfiguraion] = useState({});
  const [leaveTypeSort, setLeaveTypeSort] = useState([]);
  const [filters, setFilters] = useState();
  const [is_applicable_start, setis_applicable_start] = useState(null)
  const [is_applicable_end, setis_applicable_end] = useState(null)
  const [saving, setSaving] = useState(false)
  const [loading, setLoading] = useState(true)

  const [form] = Form.useForm()


  useEffect(() => {
    getAllData();
    // eslint-disable-next-line
  }, []);

  const getAllData = () => {
    makeRequest(setLoader, getAllDataReq, filters, onSuccess, null);
  };

  const onSuccess = (response) => {

    setLeaveTypesAll(response.leave_types)
    setLeaveTypes(createSelectList(response.leave_types))
    setConfiguraion(response.configurations)
    setLeaveTypeSort(response.leave_type_sort)
    const isLeaveTypeExist=response.leave_types.filter((each)=>each.id===response.configurations.general.leave_type_id);
    if(isLeaveTypeExist.length<1){
      response.configurations.general.leave_type_id=null
    }
    form.setFieldsValue(response.configurations.general)
    setis_applicable_start(response.configurations.general.is_applicable_start)
    setis_applicable_end(response.configurations.general.is_applicable_end)
    setLoading(false)
  };

  const updateLeaveTypes = (active, id) => {
    leaveTypesAll.forEach(each => (
      each.is_active = each.id === id ? active : each.is_active
    ))
  }
  const onSubmit = (data) => {
    const payload = { configs: { ...data, leave_types: leaveTypesAll } }
    makeRequest(setLoader, createDataReq, payload, onCreated, onError);
    setSaving(true)
  }
  const onCreated = (response) => {
    setSaving(false)
    message.success('Configurations Set Successfully!')
    setLeaveTypesAll(response.leave_types)
    setLeaveTypes(createSelectList(response.leave_types))
    setConfiguraion(response.configurations)
    setLeaveTypeSort(response.leave_type_sort)
    form.setFieldsValue(response.configurations.general)
    setis_applicable_start(response.configurations.general.is_applicable_start)
    setis_applicable_end(response.configurations.general.is_applicable_end)
  };
  const onError = (err, res) => {
    setSaving(false)
    message.error(res.response.data.message);
  }
  return (
    <>
      <BodyComponent>
        <HeaderComponent headers={ pageConfig.headers } modkey={props.modkey}>

        </HeaderComponent>
        <Skeleton loading={ loading } >
          <div style={ { background: "white", borderRadius: 10 } }>
            <SaveButton key="create_button" htmlType="submit" form="leaveConfigurationForm" loading={ saving } style={ { float: 'right', marginRight: 20, marginTop: 18 } } />
            <Form { ...formLayout } onFinish={ onSubmit } form={ form } name="leaveConfigurationForm" style={ { paddingLeft: 20, paddingRight: 20 } } scrollToFirstError>
              <h5 className="formlist-title" style={ { borderBottom: "1px solid #C9D6DF", height: 80, paddingTop: 30 } }>{ trans(`${langKey}.relaxation`) }</h5>
              <Row gutter={ 30 }>
                <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                  <Form.Item name="is_applicable_start" label={ trans(`${langKey}.is_applicable_start`) }
                    className="da-mb-16"
                    valuePropName="checked"
                  >
                    <Checkbox onChange={ (e) => setis_applicable_start(e.target.checked) } />
                  </Form.Item>
                </Col>
                {
                  is_applicable_start ?
                    <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                      <Form.Item name="start_duration_limit" label={ trans(`${langKey}.start_duration_limit`) }
                        className="da-mb-16"

                      >
                        <InputText type="number" placeholder="Maximum Checkin Minutes to Apply" />
                      </Form.Item>
                    </Col>
                    : ''
                }

                <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                  <Form.Item name="is_applicable_end" label={ trans(`${langKey}.is_applicable_end`) }
                    className="da-mb-16"
                    valuePropName="checked"
                  >
                    <Checkbox onChange={ (e) => setis_applicable_end(e.target.checked) } />
                  </Form.Item>
                </Col>
                { is_applicable_end ?
                  <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                    <Form.Item name="end_duration_limit" label={ trans(`${langKey}.end_duration_limit`) }
                      className="da-mb-16"
                    >
                      <InputText type="number" placeholder="Maximum Checkout Minutes to Apply" />
                    </Form.Item>
                  </Col>
                  : '' }
              </Row>

              <div className="formlist-container" style={ { paddingLeft: 20, paddingRight: 20 } }>
                <h5 className="formlist-title">{ trans(`${langKey}.cpl`) }</h5>
                <Row gutter={ 30 }>
                  <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                    <Form.Item name="leave_type_id" label={ trans(`${langKey}.leave_type_id`) } rules={ rules.leave_type_id }
                      className="da-mb-16"
                    >
                      <SelectWithSearch
                        placeholder={ trans(`${langKey}.leave_type_id`) }
                        options={ leaveTypes }
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                    <Form.Item name="cpl_limit" label={ trans(`${langKey}.cpl_limit`) }
                      className="da-mb-16"
                    >
                      <InputText type="number" />
                    </Form.Item>
                  </Col>
                </Row>
              </div>


              <div className="formlist-container" style={ { paddingLeft: 20, paddingRight: 20 } }>
                <h5 className="formlist-title">{ trans(`${langKey}.deduction`) }</h5>
                <Row>
                  {
                    leaveTypesAll && leaveTypesAll.length > 0 && leaveTypesAll.map((each, key) => (
                      <Col span={ 10 }>
                        <Form.Item
                          label={ each.name }
                          name={ 'leave_type|' + each.id }
                          valuePropName="checked"
                        >
                          <Checkbox defaultChecked={ each.is_active ? true : false } onChange={ (e) => updateLeaveTypes(e.target.checked, each.id) } />
                        </Form.Item>
                      </Col>
                    ))
                  }
                </Row>
              </div>
            </Form>
          </div>
        </Skeleton>
      </BodyComponent>
    </>
  )

}

export default IndexLeaveConfiguration


const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}

const rules = {
  leave_type_id: [
    { required: true, message: 'Select Leave type for CPL Leaves' },
  ],

};
