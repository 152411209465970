import React, { useEffect, useState } from "react";
import { Button, Divider, Form, Skeleton, message, Row, Col } from "antd";
import { SaveButton, InputText, CancelButton, ModalComponent, trans, InputDate, InputTextArea, TableComponent, ActionComponent, SelectWithSearch, FlowHistoryButton } from "@comps/components"
import { makeRequest, getErrorProps } from "@utils/helpers";
import { Commons, Const } from "../../constants";
import moment from "moment/moment";
import { approveRequest } from "../requests";
import { DeleteOutlined } from "@ant-design/icons";
import CreateConsent from "@mods/common/CreateConsent";
import CreateConsentChat from "@mods/common/CreateConsentChat";
import { createSelectList, createFormattedCurrencies } from '@comps/commonFunctions';
import EmployeeInfo from '@mods/common/EmployeeInfo';
import dayjs from 'dayjs';
const langKey = Const["expenseApproval"].lngKey
const formName = "updateExpenseApproval";
var totalAmount = 0;
const UpdateExpenseApproval = (props) => {
  const [loader, setLoader] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [remarks, setRemarks] = useState('')
  const [form] = Form.useForm();
  const [consents, setConsents] = useState([]);
  const [creatConsentModal, setCreateConsentModal] = useState(null);
  const [expenseTypes, setExpenseTypes] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [loading, setLoading] = useState(true)
  const [details, setDetails] = useState([])
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [processed, setProcessed] = useState(false);
  const [paymentmethodId, setPaymentMethodId] = useState(null);
  const [approvedAmount, setApprovedAmount] = useState()
  const [childComponent, setChildComponent] = useState(null)

  useEffect(() => {
    setData()
  }, []);

  const setData = () => {
    setConsents(props.record.consents)
    setExpenseTypes(createSelectList(props.allData.expense_types));
    setCurrencies(createFormattedCurrencies(props.allData.currencies));
    setPaymentMethods(createSelectList(props.allData.payment_methods));
    setPaymentMethodId(props.record.paymentmethod_id);
    setApprovedAmount(props.record.approved_amount)
    setProcessed(props.record.processed)
    totalAmount = 0
    props.record.details.forEach((each) => {

      each.date = each.date === "0000-00-00 00:00:00" ? null : dayjs(each.date)
      totalAmount += each.amount
      details.push(each)
    }
    )
    props.record.details = details;
    form.setFieldsValue({})
    form.setFieldsValue(props.record)
    setLoading(false)
  }

  //===========constents =============
  const onCreateConsent = () => {
    setCreateConsentModal(<CreateConsent onCreatedConsent={ onCreatedConsent } employment_id={ props.record.employment_id } request_id={ props.record.id } request_as={ props.requestAsId } api="expenseApprovals/createConsent" />)
  }
  const onCreatedConsent = (response) => {
    if (response) {
      message.success("Consent created successfully");
      props.onCompleted(response.expenses.data);
    }
    setCreateConsentModal(null)
  }
  const onConsentChat = (record) => {
    setCreateConsentModal(<CreateConsentChat record={ record } onCreatedConsent={ onCreatedConsent } request_as={ props.requestAsId } api="expenseApprovals/messageConsent" />)
  }
  //============end consents================================
  const onSubmit = (isApproval) => {
    const last_remarks = form.getFieldValue('last_remarks');
    const approved_amount = form.getFieldValue('approved_amount')
    let obj = {
      ...props.record, request_as: props.requestAsId, is_approval: isApproval,
      last_remarks: last_remarks,
      paymentmethod_id: paymentmethodId,
      processed: processed,
      approved_amount: approved_amount,
      current_history: { approved_amount: approved_amount, last_remarks: last_remarks },
      remarks: last_remarks
    }

    const payload = { request: obj, delegator_id: props.delegatorId, api: props.api, filters: props.filters }

    makeRequest(setLoader, approveRequest, payload, onSuccess, onError);
    setSaveLoader(true)
  }

  const onSuccess = (data, res) => {
    message.success("Record Updated");
    props.onCompleted(data.expenses.data);
  }

  const onError = (err, res) => {
    setSaveLoader(false)
    message.error(res.response.data.message);
  }

  //-----------------------

  const consentColumns = [
    {
      key: 'consentor_name',
      title: trans(`${langKey}.consentor_name`),
      dataIndex: 'consentor_name'
    },
    {
      key: 'consentee_name',
      title: trans(`${langKey}.consentee_name`),
      dataIndex: 'consentee_name'
    },
    {
      key: 'consent_date',
      title: trans(`${langKey}.consent_date`),
      render: (record) => {
        return record.created_at ? moment(record.created_at).format('DD-MM-YYYY h:mm:ss a') : null
      }
    },
    {
      key: trans(Commons.actions),
      title: trans(Commons.actions),
      render: (record) => (
        <ActionComponent
          each={ record } modkey={props.modkey}
          onView={ onConsentChat }
        ></ActionComponent>
      ),
    },
  ]

  //============uploading file============
  const [fileList, setFileList] = useState([]);

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [
    childComponent,
    <div style={ { float: 'left' } }>
      < FlowHistoryButton record={ props.record } setChildComponent={ setChildComponent } />
    </div>,
    <SaveButton disabled={ props.disabled } loading={ saveLoader } form={ formName } key="create_button" onClick={ () => onSubmit(true) } text='Approve' />,
    <CancelButton disabled={ props.disabled } loading={ saveLoader } key="reject_button" form={ formName } htmlType='submit' text='Reject' onClick={ () => onSubmit(false) } />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } width={ 1200 } maskClosable={ false }
      mainTitle={ trans(props.disabled ? Commons.view : Commons.update) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Skeleton loading={ loading }>
        <EmployeeInfo record={ props.record.requestor && props.record.requestor } />
        <Form { ...formLayout } name={ formName } onFinish={ onSubmit } form={ form } disabled={ true } scrollToFirstError>

          <div className="formlist-container" style={ { paddingLeft: 20, paddingRight: 20 } }>
            <h5 className="formlist-title">Expense Request</h5>
            <Row gutter={ 30 }>
              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="extension_no" rules={ rules.extension_no } label={ trans(`${langKey}.extension_no`) }
                  className="da-mb-16"
                >
                  <InputTextArea placeholder={ trans(`${langKey}.extension_no`) } />
                </Form.Item>
              </Col>
              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="description" rules={ rules.reason } label={ trans(`${langKey}.reason`) }
                  className="da-mb-16"
                >
                  <InputTextArea placeholder={ trans(`${langKey}.reason`) } />
                </Form.Item>
              </Col>
            </Row>
          </div>

          <div className="formlist-container">
            <h5 className="formlist-title">Details</h5>

            <Form.List name="details">
              { (fields, { add, remove }) => (
                <>
                  <div className="table-container">
                    <table className="table">
                      {
                        fields.length !== 0 && <thead>
                          <tr>
                            <th style={ { paddingLeft: 20 } }>{ trans(`${langKey}.date`) }</th>
                            <th>{ trans(`${langKey}.expensetype_id`) }</th>
                            <th>{ trans(`${langKey}.currency_id`) }</th>
                            <th>{ trans(`${langKey}.amount`) }</th>
                            <th>{ trans(`${langKey}.description`) }</th>
                            <th>{ trans(`${langKey}.action`) }</th>
                          </tr>
                        </thead>
                      }
                      { fields.map(({ key, name, ...restField }) => (
                        <>
                          <tbody>
                            <tr>
                              <td style={ { minWidth: '200px', paddingLeft: 20 } }>
                                <Form.Item
                                  { ...restField }
                                  name={ [name, 'date'] }
                                  rules={ rules.date }
                                >
                                  <InputDate />
                                </Form.Item>
                              </td>
                              <td >
                                <Form.Item

                                  { ...restField }
                                  name={ [name, 'expensetype_id'] }
                                  rules={ rules.expensetype_id }
                                >
                                  <SelectWithSearch placeholder={ trans(`${langKey}.expensetype_id`) } options={ expenseTypes } />
                                </Form.Item>
                              </td>
                              <td width={ 300 }>
                                <Form.Item
                                  { ...restField }
                                  name={ [name, 'currency_id'] }
                                  rules={ rules.currency_id }
                                >
                                  <SelectWithSearch placeholder={ trans(`${langKey}.currency_id`) } options={ currencies } />
                                </Form.Item>
                              </td>
                              <td width={ 100 }>
                                <Form.Item
                                  { ...restField }
                                  name={ [name, 'amount'] }
                                  rules={ rules.amount }
                                >
                                  <InputText placeholder={ trans(`${langKey}.amount`) } type="number" />
                                </Form.Item>
                              </td>
                              <td width={ 300 }>
                                <Form.Item
                                  { ...restField }
                                  name={ [name, 'description'] }
                                  rules={ rules.description }
                                >
                                  <InputTextArea placeholder={ trans(`${langKey}.description`) } />
                                </Form.Item>
                              </td>

                              {/* <td width={ 100 }>
                            <Form.Item name="attachment" >
                              <UploadFile accept=".jpg,.png,.jpeg"
                                listType="picture-card"
                                fileList={ fileList }
                                onChange={ onChange }

                                multiple={ false }
                                beforeUpload={ (file) => {
                                  return false;
                                } }
                              >
                                { fileList.length === 1 ? null : 'Upload' }
                              </UploadFile>
                            </Form.Item>
                          </td> */}
                              <td>
                                <DeleteOutlined className="formlist-remove" onClick={ () => remove(name) } />
                              </td>
                            </tr>
                            <tr>

                              <th>Total Amount</th>
                              <th>{ totalAmount }</th>

                            </tr>

                          </tbody>
                        </>
                      )) }
                    </table>
                  </div>
                </>
              ) }
            </Form.List>
          </div>


          { props.record.hasOwnProperty('requestable') ?
            <>
              <Divider>Cancel Request</Divider>
              <Form.Item name="reasons" rules={ rules.reason } label={ trans(`${langKey}.reason`) }
                className="da-mb-16"
                { ...getErrorProps(errors['reason']) } size="small"
              >
                <InputTextArea placeholder={ trans(`${langKey}.reason`) } />
              </Form.Item>
              <Divider>Approval</Divider>
              <Form.Item name="description" rules={ rules.last_remarks } label={ trans(`${langKey}.last_remarks`) }
                className="da-mb-16"
                { ...getErrorProps(errors['last_remarks']) } size="small"
              >
                <InputTextArea placeholder={ trans(`${langKey}.last_remarks`) } disabled={ props.disabled } onChange={ (e) => setRemarks(e.target.value) } />
              </Form.Item>
            </>
            :
            <>

              <div className="formlist-container" style={ { paddingLeft: 20, paddingRight: 20 } }>
                <h5 className="formlist-title">Approval</h5>
                <Row gutter={ 30 }>
                  <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                    <Form.Item
                      name='processed'
                      //options={ currencies }
                      label="Disbursement Mode"
                    >
                      <SelectWithSearch placeholder={ trans(`${langKey}.processed`) } defaultValue={ false } options={ [
                        {
                          label: 'Payroll',
                          value: false
                        },
                        {
                          label: 'Manually',
                          value: true
                        }
                      ] } disabled={ false } onChange={ (value) => setProcessed(value) } />
                      <label className="d-none"></label>
                    </Form.Item>
                  </Col>
                  { processed ?
                    <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                      <Form.Item
                        name='paymentmethod_id'
                        rules={ rules.paymentmethod_id }
                        label={ trans(`${langKey}.paymentmethod_id`) }
                      >
                        <SelectWithSearch placeholder={ trans(`${langKey}.paymentmethod_id`) } options={ paymentMethods } disabled={ false } onChange={ (id) => setPaymentMethodId(id) } />
                      </Form.Item>
                    </Col>
                    : ''
                  }
                  <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                    <Form.Item name="approved_amount" rules={ rules.approved_amount } label={ trans(`${langKey}.approved_amount`) }
                      className="da-mb-16"
                      { ...getErrorProps(errors['approved_amount']) } size="small"
                    >
                      <InputText placeholder={ trans(`${langKey}.approved_amount`) } type="number" disabled={ false } onChange={ (e) => e.target.value > totalAmount ? message.info('Maximum Amount only ' + totalAmount) && form.setFieldValue('approved_amount', null) : '' } />
                    </Form.Item>
                  </Col>

                  <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                    <Form.Item name="last_remarks" rules={ rules.last_remarks } label={ trans(`${langKey}.last_remarks`) }
                      className="da-mb-16"
                      { ...getErrorProps(errors['last_remarks']) } size="small"
                    >
                      <InputTextArea placeholder={ trans(`${langKey}.last_remarks`) } disabled={ props.disabled } onChange={ (e) => setRemarks(e.target.value) } />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </>
          }

        </Form>
        <h5 className="formlist-title">Consents <Button type="primary" title='Create' onClick={ onCreateConsent } className="float-end me-5">Create</Button></h5>

        { creatConsentModal }
        <TableComponent dataSource={ consents } columns={ consentColumns } />

      </Skeleton>

    </ModalComponent >
  )
}

export default UpdateExpenseApproval

const rules = {
  name: [
    { required: true, message: '' },
  ],
  approved_amount: [
    { required: true, message: '' },
  ],

};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}