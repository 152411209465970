import React, { useEffect, useState } from "react";
import { Form, message } from "antd";
import { SaveButton, InputText, CancelButton, ModalComponent, trans, SelectWithSearch } from "@comps/components"
import { makeRequest, getErrorProps } from "@utils/helpers";
import { updateDataReq } from "../requests";
import { Commons, Const } from "../../../constants";
import { createSelectList } from '@comps/commonFunctions';
const langKey = Const["state"].lngKey
const formName = "updateState";

const UpdateState = (props) => {
  const [loader, setLoader] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [form] = Form.useForm();
  useEffect(() => {
    setFormData()
    // eslint-disable-next-line
  }, []);

  const setFormData = () => {
    if (!props) {
      return;
    }
    form.setFieldsValue(props.record);
  }

  const onSubmit = (data) => {
    setSaveLoader(true)
    data.id = props.record.id;
    const payload = { state: data };
    //payload.id = props.record.id;
    makeRequest(setLoader, updateDataReq, payload, onSuccess, onError);
  }

  const onSuccess = (data, res) => {
    setSaveLoader(false)
    message.success("Saved successfully");
    props.onCompleted(data.states.data);
  }

  const onError = (err, res) => {
    setSaveLoader(false)
    message.error(res.response.data.message);
  }

  //-----------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [
    <SaveButton loading={ saveLoader } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } width={ 600 } maskClosable={ false }
      mainTitle={ trans(Commons[props.disabled ? 'view' : 'update']) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Form { ...formLayout } form={ form } name={ formName } onFinish={ onSubmit }>
        <Form.Item name="name" rules={ rules.name } label={ trans(`${langKey}.name`) }
          className="da-mb-16"
          { ...getErrorProps(errors['name']) } size="small"
        >
          <InputText placeholder={ trans(`${langKey}.name`) } />
        </Form.Item>

        <Form.Item name="country_id" label={ trans(Commons.country) } rules={ rules.country_id } className="da-mb-16"
          { ...getErrorProps(errors['country_id']) }
        >
          <SelectWithSearch
            placeholder={ trans(Commons.country) }
            options={ createSelectList(props.countries) }
          />
        </Form.Item>
      </Form>
    </ModalComponent>
  )
}

export default UpdateState

const rules = {
  name: [
    { required: true, message: trans(`${langKey}.name.required`) },
  ],
  country_id: [
    { required: true, message: trans(Commons.country) },
  ],
};

const formLayout = {
  // layout: "horizontal",
  labelCol: { span: 6 },
  wrapperCol: { span: 14, offset: 2 },
  labelAlign: "left"
}