import { useState, useEffect } from "react";
import {
  HeaderComponent,
  BodyComponent,
  TableComponent,
  ActionComponent,
  CreateButton,RefreshButton,
  trans,
  EmployeeWithProfile,
  PaginationComponent
} from "@comps/components";
import { makeRequest } from "@utils/helpers";
import { getAllAddressesReq, deleteAddressReq, getFilterReq } from "./requests";
import { FilterComponent, FilterButton } from "@comps/components";
import { checkPermissions } from '@comps/commonFunctions';
import { Commons, Const } from "../constants";
import CreateAddress from './components/CreateAddress';
import UpdateAddress from "./components/UpdateAddress";
import { message } from "antd";


// ==================================
//   Page Breadcrumbs Start
// ==================================

const langKey = Const["address"].lngKey

const pageConfig = {
  headers: {
    title: trans(`${langKey}.moduleName`),
    breadcrumb: [
      {
        name: trans(`${langKey}.moduleName`),
        path: Const["address"].route,
      },
    ],
  },
};

// ==================================
//   Page Breadcrumbs End
// ==================================

const IndexAddresses = (props) => {
  const [totalRecords, setTotalRecords] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [loader, setLoader] = useState(false);
  const [childComponent, setChildComponent] = useState(null);
  const [filerComponent, setFilterComponent] = useState(null);
  const [filters, setFilters] = useState(props.filters);

  //start pagination states
  const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState({
    "total": 0,
    "count": 0,
    "per_page": 0,
    "current_page": 0,
    "total_pages": 0,
    "links": {}
  });

  // ==================================
  //   Use States End
  // ==================================

  // ================================== UpdateLocation
  //   Table Data End
  // ==================================

  const columns = [
    {
      key: "full_name",
      title: trans(`${langKey}.employeeName`),
      render: (record) => {
        return <EmployeeWithProfile record={ record } />
      },
    },
    {
      key: "complete_address",
      title: trans(`${langKey}.completeAddress`),
      dataIndex: "complete_address",
    },
    {
      key: "country_name",
      title: trans(`${langKey}.country`),
      dataIndex: "country_name",
    },
    {
      key: "state_name",
      title: trans(`${langKey}.state`),
      dataIndex: "state_name",
    },

    {
      key: "city_name",
      title: trans(`${langKey}.city`),
      dataIndex: "city_name",
    },
    {
      key: "address_type",
      title: trans(`${langKey}.addressType`),
      dataIndex: "address_type",
    },

    {
      key: trans(Commons.actions),
      title: trans(Commons.actions),
      render: (record) => (
        <ActionComponent
          each={ record } modkey={props.modkey}
          onDelete={ onDelete }
          onEdit={ onEdit }

        ></ActionComponent>
      ),
    },
  ];

  // ==================================
  //   Table Data End
  // ==================================

  useEffect(() => {
    getAllData();
    // eslint-disable-next-line
  }, [filters, currentPage]);

  const getAllData = () => {
    const payload = {
      page: currentPage,
      filters: filters
    }
    makeRequest(setLoader, getAllAddressesReq, payload, onSuccess, null);
  };

  const onSuccess = (response) => {

    setPagination(response.employee_addresses.meta.pagination);
    setDataSource(response.employee_addresses.data);
  };

  const onEdit = (record) => {
    setChildComponent(
      <UpdateAddress onCompleted={ onCompleted } record={ record } filters={ props.filters } />
    );
  };

  const onDelete = (record) => {
    makeRequest(setLoader, deleteAddressReq, record.id, onDeleted, onError);
  };
  const onDeleted = (data, res) => {
    onSuccess(data)
    message.success("Address Deleted successfully");
    setDataSource(data.employee_addresses.data);
  };

  const onError = (err, res) => {
    message.error(res.response.data.message);
  }

  // Create component modal
  const onCreate = () => {
    setChildComponent(<CreateAddress onCompleted={ onCompleted } onSuccess={ onSuccess } filters={ props.filters } />);
  };

  const onCompleted = (data) => {
    if (data.length) {
      setDataSource(data);
    }
    setChildComponent(null);
  };

  const handleTableChange = (page, fil, sorter) => {
    let payload = {
      ...pagination,
      current: page.current,
      pageSize: page.pageSize,
      sortName: sorter.field || "id",
      sortType: sorter.order === "ascend" ? "asc" : "desc",
    };
    setPagination(payload);
  };
  //---------------filters-----------------------
  const onOpenFilter = () => {

    setFilterComponent(<FilterComponent filters={ filterFields } modkey={props.modkey} selected={ filters } api={ getFilterReq } setCurrentPage={ setCurrentPage } onFilter={ setFilters } onCloseFilter={ onCloseFilter } moduleName="certification"
    />
    )
  }

  const onCloseFilter = () => {
    setFilterComponent(null);

  }


  //----end filter--------------------
  return (
    <>
      { filerComponent }
      { childComponent }
      <HeaderComponent headers={ pageConfig.headers } modkey={props.modkey}>
        { checkPermissions('profile_employeeAddress') &&
          <>
          <RefreshButton onClick={ getAllData } />
          <CreateButton onClick={ onCreate } />
          </>
        }
        { !props.filters && <FilterButton onClick={ onOpenFilter } /> }
      </HeaderComponent>
      <BodyComponent>
        <TableComponent modkey={props.modkey}
          loader={ loader }
          columns={ columns }
          dataSource={ dataSource }
          pagination={ { ...pagination, total: totalRecords } }
          onChange={ handleTableChange }
        />
      </BodyComponent>
      <PaginationComponent pagination={ !props.filters ? pagination : undefined } setCurrentPage={ setCurrentPage } currentPage={ currentPage } display={ !props.filters } />
    </>
  );
};

export default IndexAddresses;


const filterFields = [
  {
    type: "select",
    placeholder: trans(`${langKey}.employeeName`),
    key: "employments",
    data_key: "supporting_data.employees",
    resource_type: 'employee'
    //is_resource: true
  },
  {
    type: "select",
    placeholder: trans(`${langKey}.country`),
    key: "country_id",
    data_key: "supporting_data.country",
    //is_resource: true
  },
  {
    type: "select",
    placeholder: trans(`${langKey}.state`),
    key: "state_id",
    data_key: "supporting_data.state",
    //is_resource: true
  },
  {
    type: "select",
    placeholder: trans(`${langKey}.city`),
    key: "city_id",
    data_key: "supporting_data.city",
    //is_resource: true
  },
  {
    type: "select",
    placeholder: trans(`${langKey}.addressType`),
    key: "address_type_id",
    data_key: "supporting_data.address_type",
    //is_resource: true
  },

]