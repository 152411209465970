import { get, del, post, put } from "@utils/axios";

const api = "time-slot";

export const getAllDataReq = (payload) => {
  return get(`${api}/get-related-data?page=${payload.page}&filters=${payload.filters}`)
};

export const createDataReq = (payload) => {
  return post(`${api}?`, payload);
};

export const updateDataReq = (payload) => {
  return put(`${api}/${payload.id}`, payload);
};

export const deleteDataReq = (id) => {
  return del(`${api}/${id}`);
};

export const getFilterReq = (payload) => {
  return get(`filters?company_id=1&fields=page=1&filters=supporting_fields_|short_overtime,full_overtime,is_flexible;preset_|time_slot;`)
}