import React, { useEffect, useState } from "react";
import { Form, message, Checkbox, InputNumber, Skeleton, Row, Col } from "antd";
import { SelectWithSearch, SaveButton, InputText, CancelButton, ModalComponent, trans } from "@comps/components"
import { makeRequest, makeRequestStateless, getErrorProps } from "@utils/helpers";
import { createSelectList } from "@comps/commonFunctions";
import { createDepartmentReq, getHodsByCompanyIdReq, getIdentifierReq } from "../requests";
import { Commons, Const } from "../../constants";

const langKey = Const["department"].lngKey
const formName = "createDepartment";

const CreateDepartment = (props) => {

  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [identifiers, setIdentifiers] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [limitHeadCount, setLimitHeadCount] = useState(false)
  const [hods, setHods] = useState([])
  const [saving, setsaving] = useState(false);
  const [loading, setloading] = useState(true);

  useEffect(() => {
    setCompaniesAndIdentifiers()
    //setCompaniesAndIdentifiers()
    getHodByCompanyId(1)
    // eslint-disable-next-line
  }, []);

  const setCompaniesAndIdentifiers = () => {
    if (!props.departments) {
      return
    }
    makeRequestStateless(getIdentifierReq, false, onIdentifierSuccess, onIdentifierError)
    setDepartments(createSelectList(props.departments))
  }
  const onIdentifierSuccess = (data) => {
    setloading(false)
    setIdentifiers(createSelectList(data))
  }

  const onIdentifierError = () => {
    message.error("Error while getting Identifiers")
  }

  const getHodByCompanyId = (companyId) => {
    if (!companyId) {
      return
    }
    const payload = new FormData()
    payload.append("company_id", companyId)
    makeRequestStateless(getHodsByCompanyIdReq, payload, onHodsSuccess, onHodsError)
  }

  const onHodsSuccess = (res) => {
    setHods(createSelectList(res.hods))
    setloading(false)
  }

  const onHodsError = () => {
    message.error("Error while getting Hods By Company Id")
  }

  const handleLimitHeadCount = (e) => {
    setLimitHeadCount(e.target.checked)
  }

  const onSubmit = (data) => {
    setsaving(true)
    const payload = { name: data.name, has_limit: limitHeadCount, max_head_count: data.maxHeadCount, parent_id: data.parentDept, identifier_id: data.identifier, hod_id: data.hod, }
    makeRequest(setLoader, createDepartmentReq, payload, onSuccess, onError);
  }

  const onSuccess = (data, res) => {
    message.success("Department Saved successfully");
    props.onCompleted(data.departments.data);
    props.onSuccess(data)

  }

  const onError = (err, res) => {
    setsaving(false)
    message.error(res.response.data.message);
    //setErrors(errorList);
  }
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------
  const footer = [

    <SaveButton loading={ saving } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];
  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent width={ 1000 } top={ 20 } maskClosable={ false }
      mainTitle={ trans(Commons.create) } subTitle={ trans(`${langKey}.department`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>

      <Skeleton loading={ loading }>
        <Form { ...formLayout } layout="vertical" name={ formName } onFinish={ onSubmit }>

          <Row gutter={ 30 }>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="name" rules={ rules.name } label={ trans(`${langKey}.name`) }
                className="da-mb-16"
                { ...getErrorProps(errors['name']) } size="small"
              >
                <InputText placeholder={ trans(`${langKey}.name`) } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="parentDept" label={ trans(`${langKey}.parentDepartment`) }
                { ...getErrorProps(errors['parentDept']) }>
                <SelectWithSearch
                  className="da-mb-16"
                  placeholder={ trans(`${langKey}.selectParentDepartment`) }
                  options={ departments } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="identifier" label={ trans(`${langKey}.identifier`) }
                className="da-mb-16"
                { ...getErrorProps(errors['identifier']) }>
                <SelectWithSearch
                  className="da-mb-16"
                  placeholder={ trans(`${langKey}.selectIdentifier`) }
                  options={ identifiers } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="hasLimit" label={ trans(`${langKey}.hasLimit`) }
                className="da-mb-16" size="small"
              >
                <Checkbox value={ limitHeadCount } onChange={ handleLimitHeadCount } />
              </Form.Item>

              {
                limitHeadCount ? <Form.Item name="maxHeadCount" rules={ rules.maxHeadCount } label={ trans(`${langKey}.maxHeadCount`) }
                  className="da-mb-16"
                  { ...getErrorProps(errors['maxHeadCount']) } size="small">
                  < InputNumber />
                </Form.Item> : null
              }
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="hod" label={ trans(`${langKey}.hod`) }
                className="da-mb-16"
                { ...getErrorProps(errors['hod']) }>
                <SelectWithSearch
                  className="da-mb-16"
                  placeholder={ trans(`${langKey}.selectHod`) }
                  options={ hods } />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Skeleton>

    </ModalComponent>
  )
}

export default CreateDepartment

const rules = {
  name: [
    { required: true, message: trans(`${langKey}.name.required`) },
  ],
  maxHeadCount: [
    { required: true, message: trans(`${langKey}.maxHeadCount.required`) },
  ],
};

const formLayout = {
  labelCol: { span: 6 },
  // wrapperCol: { span: 14,},
  labelAlign: "left"
}