import React, { useEffect, useState } from "react";
import { Form, Skeleton, message, Row, Col } from "antd";
import { SelectWithSearch, SaveButton, InputText, CancelButton, ModalComponent, trans, AddNewButton } from "@comps/components";
import { makeRequest, makeRequestStateless, getErrorProps } from "@utils/helpers";
import { createSelectList } from '@comps/commonFunctions';
import { updateBranch, getLocationsManagersAndAreasByCompanyId, getAllCountriesReq } from "../requests";
import { Commons, Const } from "../../constants";
import CreateLocation from "../../locations/components/CreateLocation";

const langKey = Const["branch"].lngKey
const formName = "updateBranch";
const UpdateBranch = (props) => {
  const [form] = Form.useForm()
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [managers, setManagers] = useState([]);
  const [locations, setLocations] = useState([]);
  const [areas, setAreas] = useState([]);
  const [countries, setCountries] = useState([]);
  const [saving, setsaving] = useState(false);
  const [loading, setloading] = useState(true);
  const [childComponent, setChildComponent] = useState(null);

  useEffect(() => {
    getAllCountries()
    setFormDataFromProps()
    getRemoteData(props.record.company_id);
    // eslint-disable-next-line
  }, []);

  const setFormDataFromProps = () => {
    if (!props.record) {
      return
    }
    form.setFieldsValue({
      "name": props.record.name,
      "country": props.record.country_id,
      "location": props.record.location_id,
      "manager": props.record.manager_id,
      "area": props.record.area_id
    })
  }

  const getAllCountries = () => {
    makeRequestStateless(getAllCountriesReq, false, onCountriesSuccess, onCountriesError)
  }

  const onCountriesSuccess = (response) => {
    setCountries(createSelectList(response))
  }

  const onCountriesError = (error) => {
    message.error("Error fetching countries...")
  }

  const getRemoteData = (companyId) => {
    makeRequestStateless(getLocationsManagersAndAreasByCompanyId, companyId, onRemoteSuccess, onRemoteError)
  }

  const onRemoteSuccess = (response) => {
    setLocations(createSelectList(response.locations))
    setAreas(createSelectList(response.areas))
    setManagers(createSelectList(response.employments))
    setloading(false)
  }

  const onRemoteError = (err, res) => {
    console.error(err)
    message.error("Error fetching Remote Data")
  }

  const onSubmit = (data) => {
    setsaving(true)
    const payload = { id: props.record.id, name: data.name, country_id: data.country, location_id: data.location, area_id: data.area, manager_id: data.manager }
    makeRequest(setLoader, updateBranch, payload, onSuccess, onError);
  }

  const onSuccess = (data, res) => {
    message.success("Branch updated successfully");
    props.onCompleted(data.branchs.data);
  }

  const onError = (err, res) => {
    setsaving(false)
    message.error(res.response.data.message);
    //setErrors(errorList);
  }

  const onLocationCreated = (data) => {
    if (data.length) {

      setLocations(createSelectList(data))
      form.setFieldValue('location', data[0].id)
    }
    setChildComponent(null);
  };
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------
  const footer = [
    props.disabled ? '' :
      <SaveButton loading={ saving } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];
  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------
  return (
    <ModalComponent width={ 1000 } top={ 20 } maskClosable={ false } mainTitle={ trans(props.disabled ? Commons.view : Commons.update) } subTitle={ trans(`${langKey}.moduleName`) } visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Skeleton loading={ loading }>
        <Form
          form={ form }
          layout="vertical"
          name={ formName }
          onFinish={ onSubmit }
          disabled={ props.disabled }
        >
          <Row gutter={ 30 }>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="name" rules={ rules.name } label={ trans(`${langKey}.name`) }
                className="da-mb-16"
                { ...getErrorProps(errors['name']) } size="small"
              >
                <InputText placeholder={ trans(`${langKey}.name`) } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <AddNewButton text="Location" onClick={ () => setChildComponent(<CreateLocation onCompleted={ onLocationCreated } />) } />
              <Form.Item name="location" label={ trans(`${Const.location.lngKey}.moduleName`) }
                rules={ rules.location } className="da-mb-16"
                { ...getErrorProps(errors['location']) }>
                <SelectWithSearch
                  className="da-mb-16"
                  placeholder={ trans(`${langKey}.selectLocation`) }
                  options={ locations } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="area" label={ trans(`${Const.area.lngKey}.moduleName`) }
                className="da-mb-16"
                { ...getErrorProps(errors['area']) }>

                <SelectWithSearch
                  className="da-mb-16"
                  disabled={ areas.length > 0 ? false : true }
                  placeholder={ trans(`${langKey}.selectArea`) }
                  options={ areas } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="manager" label={ trans(`${langKey}.manager`) }
                className="da-mb-16"
                { ...getErrorProps(errors['manager']) }>

                <SelectWithSearch
                  disabled={ managers.length > 0 ? false : true }
                  placeholder={ trans(`${langKey}.selectManager`) }
                  options={ managers } />
              </Form.Item>
            </Col>

            {/* <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="country" label={ trans(`${langKey}.country`) }
                  className="da-mb-16"
                  { ...getErrorProps(errors['country']) }>
                  <SelectWithSearch
                    placeholder={ trans(`${langKey}.selectCountry`) }
                    options={ countries } />
                </Form.Item>  
              </Col>                                */}
          </Row>
        </Form>
      </Skeleton>
    </ModalComponent>
  )
}

export default UpdateBranch

const rules = {
  name: [
    { required: true, message: trans(`${langKey}.name.required`) },
  ],
  location: [
    { required: true, message: trans(`${langKey}.location.required`) },
  ]
};