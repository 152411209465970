import { useState, useEffect } from "react";
import {
  BodyComponent,
  TableComponent,
  ActionComponent,
  CreateButton,RefreshButton,
  trans,
  PaginationComponent
} from "@comps/components";
import { makeRequest } from "@utils/helpers";
import { Commons, Const } from "../../constants";
import { Space, message } from 'antd';
import { FilterComponent, FilterButton } from "@comps/components";
import { deleteDataReq, getAllDataReq, getFilterReq } from "./requests";
import { Header } from "antd/es/layout/layout";
import CreateAccolade from "./components/CreateAccolade";
import UpdateAccolade from "./components/UpdateAccolade";
import moment from "moment";

const langKey = Const["accolade"].lngKey;
const headerStyle = { background: 'white', textAlign: "right" }
const IndexAccolades = (props) => {
  const [totalRecords, setTotalRecords] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [loader, setLoader] = useState(false);
  const [childComponent, setChildComponent] = useState(null);
  const [filerComponent, setFilterComponent] = useState(null);
  const [filters, setFilters] = useState();
  //start pagination states
  const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState({
    "total": 0,
    "count": 0,
    "per_page": 0,
    "current_page": 0,
    "total_pages": 0,
    "links": {}
  });
  //end pagination states

  // ==================================
  //   Use States End
  // ==================================

  // ================================== UpdateLocation
  //   Table Data End
  // ==================================

  const columns = [
    {
      key: 'title',
      title: trans(`${langKey}.title`),
      dataIndex: "title",
    },
    {
      key: 'accolade_date',
      title: trans(`${langKey}.accolade_date`),
      //dataIndex: "accolade_date",
      render: (record) => {
        return moment(record.accolade_date).format('YYYY-MM-DD')
      }
    },
    {
      key: 'description',
      title: trans(Commons.description),
      dataIndex: "description",
    },

    {
      key: trans(Commons.actions),
      title: trans(Commons.actions),
      render: (record) => (
        <ActionComponent
          each={ record } modkey={props.modkey}
          onDelete={ onDelete }
          onEdit={ onEdit }
          onView={ onView }
        ></ActionComponent>
      ),
    },
  ];

  // ==================================
  //   Table Data End
  // ==================================

  useEffect(() => {
    getAllData();

    // eslint-disable-next-line
  }, [filters, currentPage]);

  const getAllData = () => {
    const payload = {
      page: currentPage,
      filters: filters
    }
    makeRequest(setLoader, getAllDataReq, payload, onSuccess, null);
  };

  const onSuccess = (response) => {
    setPagination(response.accolades);
    setDataSource(response.accolades.data);
  };

  const onEdit = (record) => {
    setChildComponent(
      <UpdateAccolade onCompleted={ onCompleted } record={ record } disabled={ false } />
    );
  };

  const onView = (record) => {
    setChildComponent(
      <UpdateAccolade onCompleted={ onCompleted } record={ record } disabled={ true } />
    );
  };

  const onDelete = (record) => {
    makeRequest(setLoader, deleteDataReq, record.id, onDeleted, onError);
  };
  const onDeleted = (res) => {
    onSuccess(res)
    setDataSource(res.accolades.data);
    message.success("Record Deleted")
  };

  const onError = (err, res) => {
    let errorList = [];
    message.error(res.response.data.message);
    //setErrors(errorList);
  }

  // Create component modal
  const onCreate = () => {
    setChildComponent(<CreateAccolade onCompleted={ onCompleted } onSuccess={ onSuccess } />);
  };

  const onCompleted = (data) => {
    if (data.length) {
      setDataSource(data);
    }
    setChildComponent(null);
  };

  const handleTableChange = (page, fil, sorter) => {
    let payload = {
      ...pagination,
      current: page.current,
      pageSize: page.pageSize,
      sortName: sorter.field || "id",
      sortType: sorter.order === "ascend" ? "asc" : "desc",
    };
    setPagination(payload);
  };

  //---------------filters-----------------------
  const onOpenFilter = () => {
    setFilterComponent(<FilterComponent filters={ filterFields } modkey={props.modkey} selected={ filters } api={ getFilterReq } setCurrentPage={setCurrentPage} onFilter={ setFilters } onCloseFilter={ onCloseFilter }
    />
    )
  }

  const onCloseFilter = () => {
    setFilterComponent(null);

  }

  //----end filter--------------------

  return (
    <>
      { filerComponent }
      { childComponent }
      <BodyComponent>
        <Header style={ headerStyle }>
          <Space>
            <RefreshButton onClick={ getAllData } /> <CreateButton onClick={ onCreate } />
            <FilterButton onClick={ onOpenFilter } />
          </Space>

        </Header>
        <TableComponent modkey={props.modkey}
          loader={ loader }
          columns={ columns }
          dataSource={ dataSource }
          pagination={ { ...pagination, total: totalRecords } }
          onChange={ handleTableChange }
        />
      </BodyComponent>
      <PaginationComponent pagination={ pagination } modkey={props.modkey} setCurrentPage={ setCurrentPage } currentPage={ currentPage } />
    </>
  );
};

export default IndexAccolades;
const filterFields = [
  {
    type: "text",
    placeholder: "Title",
    key: "title"
  },
]

