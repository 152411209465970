import { get, post } from "@utils/axios";

const api = "transferConfigurations";

export const getAllDataReq = (payload) => {
  return get(`${api}`)
};

export const createDataReq = (payload) => {
  return post(`${api}`, payload);
};