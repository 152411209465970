
import React, { useEffect, useState } from "react";
import { Form, Skeleton } from "antd";
import { SaveButton, InputText, CancelButton, ModalComponent, trans, SelectWithSearch, UploadFile, UploadButton, InputDate } from "@comps/components"
import { Commons, Const } from "../../../constants";
const langKey = Const["companyLends"].lngKey
const formName = "AddGrauntor";

const AddGrauntor = (props) => {

  const [saving, setSaving] = useState(false)
  const [loading, setloading] = useState(false);
  const [hasCondition, sethasCondition] = useState(false)
  const [isNode, setIsNode] = useState(null)
  const [form] = Form.useForm()
  useEffect(() => {
    setData()
    // eslint-disable-next-line
  }, []);


  const setData = () => {
    if (props.record) {
      sethasCondition(props.record.has_condition)
      setIsNode(props.record.request_as_id)
      form.setFieldsValue(props.record)
    }
  }

  const onSubmit = (data) => {
    let obj = { ...data }
    let rowState = 'Added'
    if (props.record) {
      rowState = 'Updated'
      obj = { ...props.record, ...data }
    }

    props.onCompleted(obj, rowState)
  }



  //-----------------------
  //============uploading file============
  const [fileList, setFileList] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const handleCancel = () => setPreviewOpen(false);
  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };
  const onPreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    //setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };
  //-----------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [
    <SaveButton loading={ saving } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } width={ 1000 } maskClosable={ false }
      mainTitle={ trans(Commons.create) } subTitle={ trans(`${langKey}.gaurantor`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Skeleton loading={ loading }>
        <Form { ...formLayout } name={ formName } onFinish={ onSubmit } form={ form } scrollToFirstError>

          <Form.Item name="name" rules={ rules.name } label={ trans(`${langKey}.name`) }
            className="da-mb-16"

          >
            <InputText
            />
          </Form.Item>
          <Form.Item name="cnic" rules={ rules.cnic } label={ trans(`${langKey}.cnic`) }
            className="da-mb-16"

          >
            <InputText
            />
          </Form.Item>
          <Form.Item name="known_since" rules={ rules.known_since } label={ trans(`${langKey}.known_since`) }
            className="da-mb-16"

          >
            <InputDate
            />
          </Form.Item>


          <Form.Item name="relationship_id" rules={ rules.relationship_id } label={ trans(`${langKey}.relationship_id`) }
            className="da-mb-16"
            size="small"
          >
            <SelectWithSearch
              placeholder={ trans(`${langKey}.relationship_id`) }
              options={ props.relatedData.relationShips }

            />
          </Form.Item>
          <Form.Item name="guarantor_type" rules={ rules.guarantor_type } label={ trans(`${langKey}.guarantor_type`) }
            className="da-mb-16"
            size="small"
          >
            <SelectWithSearch
              placeholder={ trans(`${langKey}.guarantor_type`) }
              options={ [
                {
                  label: 'Primary',
                  value: 'primary'
                },
                {
                  label: 'Secondary',
                  value: 'secondary'
                },
              ] }

            />
          </Form.Item>

          <Form.Item name="extension_no" rules={ rules.extension_no } label={ trans(`${langKey}.extension_no`) }
            className="da-mb-16"
          >
            <InputText type='number'
            />
          </Form.Item>

          <Form.Item label={ trans(`${langKey}.attachment`) } name="experience_letter" >
            <UploadFile
              accept=".jpg,.png,.jpeg" listType="picture-card"
              fileList={ fileList }
              onChange={ onChange }
              onPreview={ onPreview }
              multiple={ false }
              beforeUpload={ (file) => {
                return false;
              } }
            >
              { fileList.length === 1 ? null : <UploadButton /> }
            </UploadFile>
          </Form.Item>

        </Form>
      </Skeleton>

    </ModalComponent>
  )
}

export default AddGrauntor

const rules = {
  relationship_id: [
    { required: true, message: trans(`${langKey}.relationship_id`) },
  ],
  known_since: [
    { required: true, message: trans(`${langKey}.known_since`) },
  ],
  guarantor_type: [
    { required: true, message: trans(`${langKey}.guarantor_type`) },
  ],
  name: [
    { required: true, message: trans(`${langKey}.name`) },
  ],
  cnic: [
    { required: true, message: trans(`${langKey}.cnic`) },
  ],

};

const formLayout = {
  // layout: "horizontal",
  labelCol: { span: 6 },
  wrapperCol: { span: 14, offset: 2 },
  labelAlign: "left"

}