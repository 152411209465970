import { useState, useEffect } from "react";
import {
  BodyComponent,
  TableComponent,
  ActionComponent,
  trans,
  HeaderComponent, PaginationComponent, CustomButton
} from "@comps/components";
import { makeRequest } from "@utils/helpers";
import { Commons, Const } from "../constants";
import { message } from 'antd';
import { deleteAllocationReq, getAllocationsReq } from "./requests";
import CreateKpiAllocation from "./components/CreateKpiAllocation";
import UpdateKpiAllocation from "./components/UpdateKpiAllocation";
import { checkPermissions } from '@comps/commonFunctions';

const langKey = Const["kpi"].lngKey;


//========================================
const IndexKpiAllocation = (props) => {
  const [totalRecords, setTotalRecords] = useState(0);
  const [allData, setAllData] = useState({});
  const [dataSource, setDataSource] = useState([]);
  const [loader, setLoader] = useState(false);
  const [childComponent, setChildComponent] = useState(null);
  const [filters, setFilters] = useState();
  const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState({
    "total": 0,
    "count": 0,
    "per_page": 0,
    "current_page": 0,
    "total_pages": 0,
    "links": {}
  });

  // ==================================
  //   Use States End
  // ==================================

  // ==================================
  //   Table Data End
  // ==================================

  const columns = [
    {
      key: 'kpi_ids',
      title: trans(`${langKey}.kpi_id`),
      render: (record) => (
        record.kpi ? record.kpi.title : ''
      )
    },
    {
      key: 'branch_id',
      title: 'Branch',
      render: (record) => (
        record.branch.name
      )
    },
    {
      key: 'tasks',
      title: "Tasks",
      render: (record) => (
        record.kpi && record.kpi.kpi_tasks ? record.kpi.kpi_tasks.length : 0
      )
    },
    {
      key: 'assign_to',
      title: trans(`${langKey}.assignto_id`),
      render: (record) => (
        record.assign_to.full_name_code
      )
    },
    {
      key: 'verified_by',
      title: trans(`${langKey}.verifiedby_id`),
      render: (record) => (
        record.verified_by.full_name_code
      )
    },
    {
      key: 'recursion_type',
      title: trans(`${langKey}.recursion_type`),
      render: (record) => (
        record.custom_date ? record.custom_date : record.recursion_type
      )
    },

    {
      key: trans(Commons.actions),
      title: trans(Commons.actions),
      render: (record) => (
        <ActionComponent
          each={ record } modkey={props.modkey}
          onDelete={ checkPermissions('kpiAllocation', 'delete') && onDelete }
          // onView={ onView }
          onEdit={ checkPermissions('kpiAllocation', 'update') && onEdit }
        >
        </ActionComponent>
      ),
    },
  ];

  // ==================================
  //   Table Data End
  // ==================================

  useEffect(() => {
    getAllData();
    // eslint-disable-next-line
  }, [filters, currentPage]);

  const getAllData = () => {
    const payload = {
      page: currentPage,
      filters: filters
    }
    makeRequest(setLoader, getAllocationsReq, payload, onSuccess, null);
  };

  const onSuccess = (data, response) => {
    setDataSource(data.data);
    setPagination(data)
    //message.info(response.message)
  };

  const onEdit = (record) => {
    setChildComponent(
      <UpdateKpiAllocation onCompleted={ onCompleted } record={ record } allData={ allData } disabled={ false } />
    );
  };

  const onDelete = (record) => {
    makeRequest(setLoader, deleteAllocationReq, record.id, onDeleted, onError);
  };
  const onDeleted = (id) => {
    getAllData()
    message.success("Record Deleted")
  };

  const onError = (err, res) => {
    message.error(res.response.data.message);
  }

  const onAllocate = () => {
    setChildComponent(<CreateKpiAllocation onCompleted={ onCompleted } allData={ allData } onSuccess={ onSuccess } />);
  }

  const onCompleted = (data) => {
    if (data) {
      getAllData()
    }
    setChildComponent(null);
  };


  const handleTableChange = (page, fil, sorter) => {
    let payload = {
      ...pagination,
      current: page.current,
      pageSize: page.pageSize,
      sortName: sorter.field || "id",
      sortType: sorter.order === "ascend" ? "asc" : "desc",
    };
    setPagination(payload);
  };

  //============allocation==============================
  return (
    <>
      { childComponent }
      <BodyComponent>
        <HeaderComponent modkey={props.modkey}>
          <CustomButton onClick={ getAllData } text="Refresh" />
          { checkPermissions('kpiAllocation', 'create') && <CustomButton onClick={ onAllocate } text="Allocate" /> }

        </HeaderComponent>
        <TableComponent modkey={props.modkey}
          loader={ loader }
          columns={ columns }
          dataSource={ dataSource }
          pagination={ { ...pagination, total: totalRecords } }
          onChange={ handleTableChange }
        />
      </BodyComponent>
      <PaginationComponent pagination={ pagination } modkey={props.modkey} setCurrentPage={ setCurrentPage } currentPage={ currentPage } />
    </>
  );
};

export default IndexKpiAllocation;
