import { get, del, post, put } from "@utils/axios";

const api = "lendTypes";

export const getAllDataReq = (payload) => {
  return get(`${api}?page=${payload.page}&filters=${payload.filters}`)
};

export const createDataReq = (payload) => {
  return post(`${api}?`, payload);
};

export const updateDataReq = (payload) => {
  return put(`${api}/${payload.id}`, payload);
};

export const deleteDataReq = (id) => {
  return del(`${api}/${id}`);
};

export const getFilterReq = (payload) => {
  return get(`filters?company_id=1&fields=page=${payload.page}&filters=supporting_fields_|is_active;preset_|lend_types;`)
}