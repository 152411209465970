import { useEffect, useState } from "react";
import { Button, Skeleton, Row, Col } from 'antd';
import { EmployeeWithProfile } from "@comps/components"
import { convertTimeTotz } from '@comps/commonFunctions'
// import '@assets/roaster.css'
import "./Roster.css"
const moment = require('moment')
const RoasterView = (props) => {

  //use states===========================
  const [employments] = useState(props.record.employments);
  const [dateTotalSource, setDateTotalSource] = useState([]);
  //==========================Generate Calendar from to end===============================

  useEffect(() => {
    setData()
    // eslint-disable-next-line
  }, [props.record]);
  const setData = () => {
    generateCalender()
  }

  const generateCalender = () => {
    let start = moment(new Date(props.record.from_date))
    let end = moment(new Date(props.record.to_date))
    let date_total = [];
    if (start === end) {
      date_total.push(start)
      setDateTotalSource(date_total)
    }
    else {
      while (start <= end) {
        date_total.push(start.format('YYYYMMDD'));
        start = start.add(1, 'days');
      }
      setDateTotalSource(date_total)
    }
  }
  //=========================================================
  return (
    <>
      <div style={ { marginBottom: 30 } }>
        <Skeleton loading={ false }>
          <Row>
            <Col span={ 6 }>
              <table border={ 1 } className="table">
                <thead>
                  <tr>
                    <th style={ { overflow: "hidden", padding: 14 } } >
                      <span className="Employee-Name">Employee</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    employments.map((employee) => (
                      <tr>
                        <td style={ { width: 250, overflowX: "hidden" } } >
                          <div style={ {
                            overflow: "hidden"
                          } }>
                            <EmployeeWithProfile record={ employee } />
                          </div>
                        </td>
                      </tr>
                    )) }
                </tbody>
              </table>
            </Col>
            <Col span={ 18 } style={ {
              overflowX: "scroll",
            } }>
              <table border={ 1 } className="table" >
                <thead>
                  <tr>
                    { Object.keys(dateTotalSource).map((date, mins) => (
                      <th style={ { border: '1px solid #C9D6DF', padding: 14, } }>
                        <div style={ { marginLeft: 40, color: "#4079FC" } }>
                          <span >{ convertTimeTotz(dateTotalSource[date]).format('dddd').substring(0, 10) }</span>
                          <span>{ "-" + convertTimeTotz(dateTotalSource[date]).format('DD') }</span>
                        </div>
                      </th>
                    )) }
                  </tr>
                </thead>
                <tbody>
                  {
                    employments.map((employee) => (
                      <tr>
                        {
                          Object.keys(dateTotalSource).map((date, mins) => (
                            <>
                              <td style={ { padding: 0, margin: 0, border: '1px solid #C9D6DF' } }>
                                <div style={ {
                                  height: 76,
                                  overflow: "hidden",
                                  width: 200,
                                  paddingLeft: 20
                                } }>
                                  <div style={ {
                                    margin: 'auto',
                                    textAlign: 'center',
                                  } }>
                                    <Button className="roster-time-button"
                                      style={ {
                                        backgroundColor: dateTotalSource[date] === 0 || props.record.shifts.hasOwnProperty(dateTotalSource[date] + '-' + employee.id) ? 'white' : 'rgba(234, 67, 53, 0.10)',
                                        color: dateTotalSource[date] === 0 || props.record.shifts.hasOwnProperty(dateTotalSource[date] + '-' + employee.id) ? '' : '#687980',
                                        backgroundImage: dateTotalSource[date] === 0 || props.record.shifts.hasOwnProperty(dateTotalSource[date] + '-' + employee.id) ? 'none' : 'url(../../sidebaricon/Clipboard.svg)',
                                        marginLeft: 30
                                      } }
                                    >
                                      {
                                        props.record.shifts.hasOwnProperty(dateTotalSource[date] + '-' + employee.id) ? (
                                          <span style={ { border: '1px solid gray', padding: '2px', marginLeft: -18, backgroundColor: props.record.shifts[dateTotalSource[date] + '-' + employee.id][0]['bg_colour'], color: props.record.shifts[dateTotalSource[date] + '-' + employee.id][0]['font_colour'] } }>
                                            { convertTimeTotz(props.record.shifts[dateTotalSource[date] + '-' + employee.id][0]['start']).format('hh:mm A') }-
                                            { convertTimeTotz(props.record.shifts[dateTotalSource[date] + '-' + employee.id][0]['end']).format('hh:mm A') }
                                          </span>
                                        ) : (<span style={ { color: "#EA4335" } }>Day Off</span>
                                        ) }
                                    </Button>
                                  </div>
                                </div>
                              </td>
                            </>
                          ))
                        }
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </Col>
          </Row>
        </Skeleton>
      </div>
    </>
  )
}

export default RoasterView

