import { get, del, put, postFd, putFd } from "@utils/axios"

const api = "groups"

export const getAllGroups = (payload) => {
  // return get(`${api}?page=1`)
  return get(`${api}?page=${payload.page}&filters=${payload.filters}`)
}

export const createGroups = (payload) => {
  return postFd(`${api}?`,payload)
}

export const updateGroups = (payload) => {
  return postFd(`${api}/update/`,payload)
}

export const deleteLocation = (id) => {
  return del(`${api}/id`)
}
