import React, { useEffect, useState } from "react";
import { Form, Skeleton, message } from "antd";
import { SaveButton, CancelButton, ModalComponent, trans, InputTime, InputDate, InputCheck, InputTextArea } from "@comps/components"
import { makeRequest, getErrorProps } from "@utils/helpers";
import { getDataById, updateDataReq } from "../requests";
import { Commons, Const } from "../../constants";
import EmployeeInfo from "../../../common/EmployeeInfo";
import { convertTimeTotz } from '@comps/commonFunctions'

const langKey = Const["myAttendance"].lngKey
const formName = "createAttendanceRequest";
const UpdateCompanyAttendance = (props) => {
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(true);
  const [saveLoader, setSaveLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [requestor, setRequestor] = useState(null)
  const [attendance, setAttendance] = useState({})
  const [markedAbsent, setMarkedAbsent] = useState(false)
  const [form] = Form.useForm()
  useEffect(() => {
    getData()
    // eslint-disable-next-line
  }, []);

  const getData = () => {
    const payload = { id: props.record.id }
    makeRequest(setLoader, getDataById, payload, onGetData, onGetData);
  }
  const onGetData = (response) => {

    setAttendance(response.attendance);
    setMarkedAbsent(response.attendance.marked_absent)
    setRequestor(response.requestor)

    setLoading(false)

    //form data
    form.setFieldsValue({
      date: response.attendance.date ? convertTimeTotz(response.attendance.date) : null,
      checkout_expected_time: response.attendance.checkout_expected_time ? convertTimeTotz(response.attendance.checkout_expected_time) : null,
      checkout_punch_time: response.attendance.checkout_punch_time ? convertTimeTotz(response.attendance.checkout_punch_time) : convertTimeTotz(response.attendance.checkout_expected_time),
      checkin_expected_time: response.attendance.checkin_expected_time ? convertTimeTotz(response.attendance.checkin_expected_time) : null,
      checkin_punch_time: response.attendance.checkin_punch_time ? convertTimeTotz(response.attendance.checkin_punch_time) : convertTimeTotz(response.attendance.checkin_expected_time),
      is_manually_updated: response.attendance.is_manually_updated,
      marked_absent: response.attendance.marked_absent
    })
  }
  //save data
  const onSubmit = (data) => {
    const payload = {
      filters: props.filters,
      id: props.record.id,
      attendance: {
        ...attendance,
        checkin_time: !markedAbsent ? data.checkin_punch_time.second(0) : attendance.checkin_punch_time,
        checkout_time: !markedAbsent ? data.checkout_punch_time.second(0) : attendance.checkout_punch_time,
        checkin_date: !markedAbsent ? data.date : attendance.date,
        checkout_date: !markedAbsent ? data.date : attendance.date,
        marked_absent: markedAbsent,
        reason: data.reason
      }
    }
    makeRequest(setLoader, updateDataReq, payload, onSuccess, onError);
    setSaveLoader(true)
  }

  const onSuccess = (data, res) => {

    setSaveLoader(false)
    message.success("Saved successfully");
    props.onCompleted(res.data);
  }

  const onError = (err, res) => {

    setSaveLoader(false)
    message.error(res.response.data.message);
  }
  //-----------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------
  const footer = [
    props.disabled ? '' :
      <SaveButton loading={ saveLoader } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];
  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------
  return (
    <ModalComponent top={ 20 } width={ 1000 } maskClosable={ false }
      mainTitle={ trans(Commons.create) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Skeleton loading={ loading }>

        <Form { ...formLayout } form={ form } name={ formName } onFinish={ onSubmit } disabled={ props.disabled } scrollToFirstError>

          <EmployeeInfo record={ requestor } />

          <Form.Item name="date" rules={ rules.date } label={ trans(`${langKey}.date`) }
            className="da-mb-16"
            { ...getErrorProps(errors['date']) } size="small"
          >
            <InputDate disabled />
          </Form.Item>
          <Form.Item name="marked_absent" rules={ rules.marked_absent } label={ trans(`${langKey}.marked_absent`) }
            className="da-mb-16"
            { ...getErrorProps(errors['marked_absent']) } size="small"
            valuePropName="checked"
          >
            <InputCheck onChange={ (e) => setMarkedAbsent(e.target.checked) } />
          </Form.Item>
          {
            !markedAbsent &&
            <>
              <Form.Item name="checkin_expected_time" rules={ rules.checkin_expected_time } label={ trans(`${langKey}.checkin_expected_time`) }
                className="da-mb-16"
                { ...getErrorProps(errors['checkin_expected_time']) } size="small"
              >
                <InputTime disabled />
              </Form.Item>
              <Form.Item name="checkin_punch_time" rules={ rules.checkin_punch_time } label={ trans(`${langKey}.checkin_punch_time`) }
                className="da-mb-16"
                { ...getErrorProps(errors['checkin_punch_time']) } size="small"
              >
                <InputTime />
              </Form.Item>

              <Form.Item name="checkout_expected_time" rules={ rules.checkout_expected_time } label={ trans(`${langKey}.checkout_expected_time`) }
                className="da-mb-16"
                { ...getErrorProps(errors['checkout_expected_time']) } size="small"
              >
                <InputTime disabled />
              </Form.Item>
              <Form.Item name="checkout_punch_time" rules={ rules.checkout_punch_time } label={ trans(`${langKey}.checkout_punch_time`) }
                className="da-mb-16"
                { ...getErrorProps(errors['checkout_punch_time']) } size="small"
              >
                <InputTime />
              </Form.Item>
            </>
          }


          <Form.Item name="is_manually_updated" rules={ rules.is_manually_updated } label={ trans(`${langKey}.is_manually_updated`) }
            className="da-mb-16"
            { ...getErrorProps(errors['is_manually_updated']) } size="small"
            valuePropName="checked"
          >
            <InputCheck disabled />
          </Form.Item>
          <Form.Item name="reason" rules={ rules.reason } label={ trans(`${langKey}.reason`) }
            className="da-mb-16"
            { ...getErrorProps(errors['reason']) } size="small"

          >
            <InputTextArea />
          </Form.Item>

        </Form>
      </Skeleton>
    </ModalComponent>
  )
}

export default UpdateCompanyAttendance

const rules = {
  date: [
    { required: true, message: trans(`${langKey}.date`) },
  ],
  checkin_punch_time: [
    { required: true, message: trans(`${langKey}.checkin_punch_time`) },
  ],
  checkout_punch_time: [
    { required: true, message: trans(`${langKey}.checkout_punch_time`) },
  ],
  // reason: [
  //   { required: true, message: trans(`${langKey}.reason`) },
  // ],

};

const formLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14, offset: 2 },
  labelAlign: "left"
}

