

import { CancelButton, ModalComponent, trans, InputText } from "@comps/components"
import { Commons, Const } from "../../../constants";
import { Col, Divider, Form, Row, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useEffect, useState } from "react";

import { createSelectList } from '@comps/commonFunctions';
import { makeRequestStateless } from "@utils/helpers";
import { getRelatedDatareq } from "../requests";
const langKey = Const["probation"].lngKey
const ViewDetailHistory = (props) => {
  const [attributes, setAttributes] = useState([]);
  const [scales, setScales] = useState([]);
  const [durations, setDurations] = useState({})
  const [form] = Form.useForm();
  useEffect(() => {
    setData();
    makeRequestStateless(getRelatedDatareq, false, onGetRealated, false)
  }, []);

  const onGetRealated = (response) => {

    const attr=response.professional_attributes;
    setAttributes(attr)
    setScales(createSelectList(response.evaluation_scales))

    formatEvaluation();
  }

const formatEvaluation=()=>{
let evaluation=[];
let currentHistory=props.allRecord.current_history.evaluation;
const histories=props.histories;
let newCurrentHistory=[];
if(typeof currentHistory==='object' && currentHistory.length===undefined){
  newCurrentHistory.push(currentHistory);
}
else{
  newCurrentHistory=currentHistory;
}
histories.forEach((allHist,ahk)=>{
let evaluationHistory=allHist.evaluation;
  if (typeof evaluationHistory === 'object' && currentHistory.length===undefined) {
    evaluationHistory=convertToDesiredFormat(evaluationHistory);
}
evaluationHistory.forEach((ev,evKey)=>{
  newCurrentHistory.forEach((currentHist,chk)=>{
        if(evKey===chk){
          ev[evKey+1].push(currentHist[evKey+1][0]);
        }
      })
      evaluation.push(ev);
    })
    allHist.evaluation=evaluation;
})
  }

function convertToDesiredFormat(input) {
  const output = [];
    for (const key in input) {
        const value = input[key];
        const entries = Object.entries(value);
        const obj = { [key]: [] };
        entries.forEach(([subKey, subValue]) => {
            obj[key].push({ [subKey]: subValue });
        });
        output.push(obj);
    }
    return output;
}

  const setData = () => {
    if (!props) {
      return;
    }

    setDurations(props.allRecord.durations ? props.allRecord.durations : {})
    //setDurations(props.probations.current_history.durations ? props.probations.current_history.durations : {})
    form.setFieldsValue(props.record);
  }
  const footer = [
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  return (
    <ModalComponent top={ 20 } width={ 1000 } maskClosable={ false }
      mainTitle={ trans(Commons.view) } subTitle={ trans(`${langKey}.history`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) } disabled={ props.disabled }>

      <Form form={ form } disabled={ true }>
        <Divider>Approval</Divider>
        <Form.Item name="remarks" label={ trans(`${langKey}.remarks`) }
          className="da-mb-16"
          size="small"
        >
          <TextArea />
        </Form.Item>

        {/* <Divider>Major Responsibilities Assigned During Probation Period</Divider>
        <Form.List name="job_objective">
          { (fields, { add, remove }) => (
            <>
              { fields.map(({ key, name, ...restField }) => (
                <Row>
                  <Col span={ 10 }>
                    <Form.Item
                      { ...restField }
                      name={ [name, 'objective'] }
                    >
                      <InputText placeholder="Job Objectives/Assignments" />
                    </Form.Item>
                  </Col>
                  <Col span={ 10 }>
                    <Form.Item
                      { ...restField }
                      name={ [name, 'evaluation'] }
                    >
                      <Select
                        options={ scales }
                        placeholder="Evaluation Rating" />
                    </Form.Item>
                  </Col>
                </Row>
              )) }
            </>
          ) }
        </Form.List> */}

        <Divider>Evaluation</Divider>
        <div className="evaluation-container">
        <table className="w-100 table-bordered">
                <tr>
                  <th className="p-2">Attributes</th>
                  { Object.values(durations).map((each, key) => (
                    <th className="p-2 ps-5" span={ 2 } key={ each }>{ each } Days ({each/30} m )</th>
                  )) }
                </tr>
                <Form.List
                  name="obj_attributes"
                  initialValue={ [
                    {},
                  ] }
                >
                  { (fields, { add, remove }) => (
                    <>
                      {
                        attributes.map((attribute, atributekey) => (
                          <tr>
                            <td className="p-2">
                              <p style={ { width: 250 } }>{ attribute.name }</p>
                            </td>
                            { Object.values(durations).map((each, durationKey) => (
                              <td className="p-2 ">
                                <>
                                  { fields.map((field, index) => (
                                    <Form.Item
                                      { ...field }
                                      name={ [field.name, "evaluation." + (attribute.id) + "." + each] }
                                      
                                    >
                                      <Select
                                        disabled={true}
                                        style={ { width: 250 } }
                                       
                                         defaultValue={ props.histories.length !== 0 ? props.histories[0].evaluation[atributekey][attribute.id].hasOwnProperty(durationKey) ? props.histories[0].evaluation[atributekey][attribute.id][durationKey][each] :'' : '' }
                                        options={ scales }
                                      />
                                    </Form.Item>
                                  )) }
                                </>
                              </td>
                            )) }
                          </tr>
                        ))
                      }
                    </>
                  ) }
                </Form.List>
              </table>
        </div>
      </Form>

    </ModalComponent>
  )
}

export default ViewDetailHistory