import React, { useEffect, useState } from "react";
import { Button, Form, Checkbox, Steps, message, Modal, Row, Col, Input, Upload, Divider } from "antd";
import { trans, InputText, InputDate, AddNewButton, InputCheck, SelectResourceList, InputTextArea } from "@comps/components";
import { makeRequest, makeRequestStateless, getErrorProps } from "@utils/helpers";
import { createSelectList, createRelationshipsList } from '@comps/commonFunctions';
import { ModalComponent, SelectWithSearch } from "@comps/components"
import { Commons } from "../../constants";

import {
  createEmployeeReq,
  getEmployeeRelatedDataReq,
  getCompanyRelatedDataReq,
  getBranchsByLocationIdReq,
  matchCnicReq
} from "../requests";

import { Const } from '../../constants';
import { UploadFile } from "@comps/components";
import CreateGrade from "../../../organizationManagement/grades/components/CreateGrade";
import CreateLocation from '../../../organizationManagement/locations/components/CreateLocation';
import CreateDepartment from '../../../organizationManagement/departments/components/CreateDepartment';
import CreateBranch from '../../../organizationManagement/branches/components/CreateBranch';
import CreateDesignation from '../../../organizationManagement/designations/components/CreateDesignation';
import CreateJobTitle from '../../../organizationManagement/jobTitles/components/CreateJobTitle';
import "./Employee.css"
import CreatePayroll from "../../../payrollManagement/payrolls/CreatePayroll";
import { getAllcountriesReq, getStatesByCountryReq, getCitiesByStateReq } from "@mods/common/requests"
import { getRelationShips } from "../../../common/requests";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
const langKey = Const["employee"].lngKey
const createEmployee = "createEmployee";
const createEmployment = "createEmployment";
const createUser = "createUser";
const AddEmployee = (props) => {

  const [employmentForm] = Form.useForm()
  const [salaryForm] = Form.useForm()
  const [addressForm] = Form.useForm()
  const [relativesForm] = Form.useForm()
  const [loader, setLoader] = useState(false);
  const [selectedNationality, setSelectedNationality] = useState(166)
  const [isFinalAuthority, setIsFinalAuthority] = useState(false)
  const [isProbation, setIsProbation] = useState(false)

  const [errors, setErrors] = useState([]);
  const [timezone, setTimezones] = useState([]);
  const [salutations, setSalutations] = useState([]);
  const [martialstatuses, setMartialstatuses] = useState([]);
  const [religions, setReligions] = useState([]);
  const [genders, setGenders] = useState([]);
  const [bloodgroups, setBloodgroups] = useState([]);
  const [nationalities, setNationalities] = useState([]);
  const [employeeTypes, setEmployeeTypes] = useState([]);
  const [employmentTypes, setEmploymentTypes] = useState([]);

  const [designations, setDesignations] = useState([]);
  const [locations, setLocations] = useState([]);
  const [branches, setBranches] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [grades, setGrades] = useState([]);
  const [lineManagers, setLineManagers] = useState([]);
  const [finalAuthorities, setFinalAuthorities] = useState([]);
  const [jobTitles, setJobTitles] = useState([]);
  const [roles, setRoles] = useState([]);
  const [isEmploymentType, setIsEmploymentType] = useState();
  const [saveloader, setsaveloader] = useState(false)
  const [payrolls, setPayrolls] = useState([]);
  const [paymentmethods, setPaymentmethods] = useState([]);
  const [policies, setPolicies] = useState([])
  const [ispaymentMethod, setisPaymentMethod] = useState()
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [addressInfo, setAddressInfo] = useState()
  const [contactInfo, setContactInfo] = useState()
  const [educationInfo, setEducationInfo] = useState();
  const [relativesInfo, setRelativesInfo] = useState([]);
  const [relationships, setRelationships] = useState([]);
  const [isDegreeCompleted, setIsDegreeCompleted] = useState(false);
  const [hasAddress, setHasAddress] = useState(false);
  const [hasContact, setHasContact] = useState(false);
  const [hasEducation, setHasEducation] = useState(false);
  const [hasRelative, setHasRelative] = useState(false);
  const [skipSalary, setSkipSalary] = useState(false);

  const salaryTypes = [
    {
      value: 'monthly',
      label: 'Monthly',
    },
    {
      value: 'daily',
      label: 'Daily Wages',
    },
    {
      value: 'hourly',
      label: 'Hourly',
    },
  ]
  const [childComponent, setChildComponent] = useState(null);
  useEffect(() => {
    getRemoteData();
    getCompanyRelatedData();

  }, []);

  const getRemoteData = () => {
    setsaveloader(false)
    makeRequestStateless(getEmployeeRelatedDataReq, false, onEmployeeRelatedDataSuccess, onEmployeeRelatedDataError)
    makeRequestStateless(getAllcountriesReq, false, onGetCountries, false)
    makeRequestStateless(getRelationShips, false, onGetRelationShips, false)
  }
  const onGetRelationShips = (response) => {
    setRelationships(createRelationshipsList(response.relationships))
    //setContactTypes(createSelectList(response.contact_types))
  }
  const onGetCountries = (response) => {
    setCountries(createSelectList(response.countries))
  }
  const onEmployeeRelatedDataSuccess = (data, res) => {
    setSalutations(createSelectList(data.salutations))
    setNationalities(createSelectList(data.countries))
    setBloodgroups(createSelectList(data.bloodgroups))
    setGenders(createSelectList(data.genders))
    setReligions(createSelectList(data.religions))
    setBloodgroups(createSelectList(data.bloodgroups))
    setMartialstatuses(createSelectList(data.maritalstatuses
    ))
    setPayrolls(createSelectList(data.payrolls
    ))
    setPaymentmethods(createSelectList(data.payment_methods
    ))
    setPolicies(createSelectList(data.policies
    ))

  }

  const onEmployeeRelatedDataError = (err) => {
    console.error(err)
  }

  const getCompanyRelatedData = () => {
    makeRequestStateless(getCompanyRelatedDataReq, false, onCompanyRelatedDataSuccess, onCompanyRelatedDataError);
  }

  const onCompanyRelatedDataSuccess = (data, res) => {
    setGrades(createSelectList(data.grades));
    setLocations(createSelectList(data.locations));
    setDepartments(createSelectList(data.departments));
    setDesignations(createSelectList(data.designations));
    setLineManagers(createSelectList(data.line_managers));
    setFinalAuthorities(createSelectList(data.final_authorities));
    setRoles(createSelectList(data.roles));
    setEmployeeTypes(createSelectList(data.employee_types))
    setEmploymentTypes(createSelectList(data.contract_types))
    setJobTitles(createSelectList(data.job_titles))
    setTimezones(createSelectList(data.time_zones))
  }
  const onCompanyRelatedDataError = (error, res) => {
  }
  //------------on submit forms----------------
  const onSubmitFirstStep = (data) => {
    setCurrent(currentStep + 1)
   if(data){
    data.d_o_b=data.d_o_b.format('YYYY-MM-DD');
   }
    setEmployeeInfo(data)
  }

  const onSubmitEmployment = (data) => {
    if (isFinalAuthority) {
      data.is_hod = isFinalAuthority;
      data.is_manager = isFinalAuthority;
    }
    if(data){
      data.joining_date=data.joining_date.format('YYYY-MM-DD');
    }
    setEmploymentInfo(data)
    setCurrent(currentStep + 1)
  }

  const onSubmitSalary = (data) => {
    setSalaryInfo(data)
    if (data) {
      setCurrent(currentStep + 1)
    }

  }

  const onSubmitUser = (data) => {
    setUserInfo(data)
    if (data) {
      setCurrent(currentStep + 1);
    }
  }


  const isDisabled = (data) => {
    let res = false;
    const address = addressForm.getFieldsValue();
    const contact = contactForm.getFieldsValue();
    const education = educationForm.getFieldsValue();
    const relatives = relativesForm.getFieldValue('relatives');

    if (hasAddress) {
      if (!address.address_type || !address.complete_address) {
        message.error('please fill the mandatory address info!')
        res = true;
      }
    }

    if (hasContact) {
      if (!contact.contact_type || !contact.name || !contact.relationship || !contact.mobile_no) {
        message.error('please fill the mandatory contact info!')
        res = true;
      }
    }

    if (hasEducation) {
      if (!education.institute_name || !education.degree) {
        message.error('please fill the mandatory education info!')
        res = true;
      }
      if (education.is_degree_completed) {
        message.error('please fill the mandatory education info!')
        res = true;
      }
    }


    return res;
  }


  const onSaveAllData = (data) => {
    let disabled = isDisabled(data);
    if (disabled) {
      return;
    }

    const address = addressForm.getFieldsValue();
    const contact = contactForm.getFieldsValue();
    const education = educationForm.getFieldsValue();

    const relatives = relativesForm.getFieldValue('relatives');
    setRelativesInfo(relatives)

    //relatives
    const relativesData = [];
    if (hasRelative && relatives.length > 0) {
      relatives.forEach(each => {
        if (each) {
          if (!each.relationship || !each.name || !each.phone_no) {
            message.error('please fill the mandatory Relatives info!')
            disabled = true;
          }
          relativesData.push(each);
        }
      })
    }

    if (disabled) {
      return;
    }


    setAddressInfo(address);
    setContactInfo(contact);
    setEducationInfo(education);
    
    let payload = { employee_info: employeeInfo, employment_info: employmentInfo, user_info: userInfo, payroll_info: !skipSalary ? salaryInfo : null, address_info: hasAddress ? address : null, contact_info: hasContact ? contact : null, education_info: hasEducation ? education : null, relatives_info: relativesData }
    makeRequest(setLoader, createEmployeeReq, payload, onEmployeeCreateSuccess, onEmployeeCreateError);
    setsaveloader(true)
  }

  const onEmployeeCreateSuccess = (response) => {
    setsaveloader(false)
    message.success('Employee Created Successfully!')
    props.onCompleted(response)
  }
  const onEmployeeCreateError = (error, res) => {
    setsaveloader(false)
    if (res.response.data.message.email) {
      setCurrent(currentStep - 1)
      setErrors({
        username: res.response.data.message.email
      })
      message.error(res.response.data.message.email)
    } else {
      message.error(res.response.data.message)
    }
  }
  //-----------------onChange--------------------
  const onChangeLocation = (locationId) => {
    if (locationId) {
      makeRequestStateless(getBranchsByLocationIdReq, locationId, onBranchsByLocationIdSuccess, onCompanyRelatedDataError);
    }
    else {
      setBranches([]);
    }
  }
  const onBranchsByLocationIdSuccess = (data) => {
    setBranches(createSelectList(data));
  }

  const onChangeNationality = (data) => {
    setSelectedNationality(data)
  }

  const onChangeIsFinalAuthority = (e) => {
    setIsFinalAuthority(e.target.checked);
  };
  const onChangeProbation = (e) => {
    setIsProbation(e.target.checked);
  }
  const onChangeCnic = (e) => {
    let count = e.target.value.toString().length;
    if (count > 12) {
      makeRequestStateless(matchCnicReq, e.target.value, onMatchCnicSuccess, onMatchCnicError);
    }

  }
  const onMatchCnicSuccess = (response) => {
    if (response.result) {
      message.error('Cnic matched with another employee in the system.!')
    }
  }
  const onMatchCnicError = (response) => {

  }
  const onChangeEmploymentType = (id) => {
    setIsEmploymentType(id);
  }
  //-------------------end--------------------
  //---------------------form layout------------

  //============uploading file============
  //const [fileList, setFileList] = useState([]);
  const [profilePicFileList, setprofilePicFileList] = useState([]);
  const [cnicFileList, setcnicFileList] = useState([]);
  const [passportFileList, setpassportFileList] = useState([]);
  const [drivingLicenseFileList, setdrivingLicenseFileList] = useState([]);

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const handleCancel = () => setPreviewOpen(false);
  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const onChangeProfilePic = ({ fileList: newFileList }) => {
    setprofilePicFileList(newFileList);
  };
  const onChangeCnicAttachment = ({ fileList: newFileList }) => {
    setcnicFileList(newFileList);
  };
  const onChangePassportAttachment = ({ fileList: newFileList }) => {
    setpassportFileList(newFileList);
  };
  const onChangeDrivingLicenseAttachment = ({ fileList: newFileList }) => {
    setdrivingLicenseFileList(newFileList);
  };
  const onPreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    //setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };
  //=========================================
  //------------------------------------
  //------------current step---------
  const [employeeInfo, setEmployeeInfo] = useState();
  const [employmentInfo, setEmploymentInfo] = useState();
  const [salaryInfo, setSalaryInfo] = useState();
  const [userInfo, setUserInfo] = useState();
  const [currentStep, setCurrent] = useState(0);


  const employeeForm = () => {
    return (
      <>
        <Form
          // { ...formLayout }
          layout="vertical"
          name={ createEmployee }
          initialValues={ employeeInfo }
          onFinish={ onSubmitFirstStep }
          scrollToFirstError
        >
          <div className="employee-data"><h5 className="employee-data-style">Personal Information</h5></div>
          <div style={ { paddingTop: 25 } }>
            <Row gutter={ 20 }>


              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="salutation_id" label={ trans(`${langKey}.selectSalutation`) } rules={ rules.salutation_id } className="da-mb-16"
                  { ...getErrorProps(errors['salutation_id']) }
                >
                  <SelectWithSearch
                    placeholder={ trans(`${langKey}.selectSalutation`) }
                    options={ salutations }
                  />
                </Form.Item>
              </Col>

              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="first_name" rules={ rules.first_name } label={ trans(`${langKey}.firstName`) } className="da-mb-16"
                  { ...getErrorProps(errors['first_name']) } size="small"
                >
                  <InputText placeholder={ trans(`${langKey}.firstName`) } />
                </Form.Item>
              </Col>

              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="middle_name" rules={ rules.middle_name } label={ trans(`${langKey}.middleName`) } className="da-mb-16"
                  { ...getErrorProps(errors['middle_name']) } size="small"
                >
                  <InputText placeholder={ trans(`${langKey}.middleName`) } />
                </Form.Item>
              </Col>

              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="last_name" rules={ rules.last_name } label={ trans(`${langKey}.lastName`) } className="da-mb-16"
                  { ...getErrorProps(errors['last_name']) } size="small"
                >
                  <InputText placeholder={ trans(`${langKey}.lastName`) } />
                </Form.Item>
              </Col>

              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="father_or_husband_name" rules={ rules.father_or_husband_name } label="Father/Husband Name" className="da-mb-16"
                  { ...getErrorProps(errors['name']) } size="small"
                >
                  <InputText placeholder={ trans(`${langKey}.Father/Husband Name`) } />
                </Form.Item>
              </Col>

              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="gender_id" label={ trans(`${langKey}.selectGender`) } rules={ rules.gender_id } className="da-mb-16"
                  { ...getErrorProps(errors['gender_id']) }
                >
                  <SelectWithSearch
                    placeholder={ trans(`${langKey}.selectGender`) }
                    options={ genders }
                  />
                </Form.Item>
              </Col>

              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="d_o_b" rules={ rules.d_o_b } label={ trans(`${langKey}.dob`) } className="da-mb-16"
                  { ...getErrorProps(errors['d_o_b']) } size="small"
                >
                  <InputDate
                    format="DD-MMM-YYYY" />
                </Form.Item>
              </Col>

              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item
                  name="marital_status_id" label={ trans(`${langKey}.selectMartialStatus`) } rules={ rules.marital_status_id } className="da-mb-16"
                  { ...getErrorProps(errors['marital_status_id']) }
                >
                  <SelectWithSearch
                    placeholder={ trans(`${langKey}.selectMartialStatus`) }
                    options={ martialstatuses }
                  />
                </Form.Item>
              </Col>

              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="religion_id" label={ trans(`${langKey}.selectReligion`) } rules={ rules.religion_id } className="da-mb-16"
                  { ...getErrorProps(errors['religion']) }
                >
                  <SelectWithSearch
                    placeholder={ trans(`${langKey}.selectReligion`) }
                    options={ religions }
                  />
                </Form.Item>
              </Col>

              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item
                  name="nationality_id" label={ trans(`${langKey}.selectNationality`) } rules={ rules.nationality_id } className="da-mb-16"
                  { ...getErrorProps(errors['nationality_id']) }
                >
                  <SelectWithSearch
                    onChange={ onChangeNationality }
                    placeholder={ trans(`${langKey}.selectNationality`) }
                    options={ nationalities }
                  />
                </Form.Item>
              </Col>

              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="blood_group_id" label={ trans(`${langKey}.selectBloodGroup`) } rules={ rules.blood_group } className="da-mb-16"
                  { ...getErrorProps(errors['blood_group_id']) }
                >
                  <SelectWithSearch
                    placeholder={ trans(`${langKey}.selectBloodGroup`) }
                    options={ bloodgroups }
                  />
                </Form.Item>
              </Col>

              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="mobile_no" label={ trans(`${langKey}.mobile`) } className="da-mb-16"
                  { ...getErrorProps(errors['mobile_no']) } size="small"
                >
                  <InputText placeholder={ trans(`${langKey}.mobile`) } />
                </Form.Item>
              </Col>

              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="landline_no" rules={ rules.landline_no } label={ trans(`${langKey}.landline`) } className="da-mb-16"
                  { ...getErrorProps(errors['landline_no']) } size="small"
                >
                  <InputText placeholder={ trans(`${langKey}.landline`) } />
                </Form.Item>
              </Col>

              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="email" label={ trans(`${langKey}.email`) } className="da-mb-16"
                  { ...getErrorProps(errors['email']) } size="small"
                >
                  <InputText placeholder={ trans(`${langKey}.email`) } />
                </Form.Item>
              </Col>

              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item
                  name="cnic_no" rules={ selectedNationality === 166 ? rules.cnic_no : [] } label={ trans(`${langKey}.cnic`) } className="da-mb-16"
                  { ...getErrorProps(errors['cnic_no']) } size="small"
                >
                  <InputText placeholder={ trans(`${langKey}.cnic`) } onChange={ onChangeCnic } />
                </Form.Item>
              </Col>



              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="cnic_expiry_date" rules={ rules.cnic_expiry_date } label={ trans(`${langKey}.cnicEpiry`) } className="da-mb-16"
                  { ...getErrorProps(errors['cnic_expiry_date']) } size="small"
                >
                  <InputDate format="DD-MMM-YYYY" />
                </Form.Item>
              </Col>

              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="passport_no" rules={ selectedNationality !== 166 ? rules.passport_no : [] } label={ trans(`${langKey}.passport`) } className="da-mb-16"
                  { ...getErrorProps(errors['passport_no']) } size="small"
                >
                  <InputText placeholder={ trans(`${langKey}.passport`) } />
                </Form.Item>
              </Col>



              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="passport_expiry_date" rules={ rules.passport_expiry_date } label={ trans(`${langKey}.passportEpiry`) } className="da-mb-16"
                  { ...getErrorProps(errors['passport_expiry_date']) } size="small"
                >
                  <InputDate format="DD-MMM-YYYY" />
                </Form.Item>
              </Col>

              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="driving_license_no" rules={ rules.driving_license_no } label={ trans(`${langKey}.driving`) } className="da-mb-16"
                  { ...getErrorProps(errors['driving_license_no']) } size="small"
                >
                  <InputText placeholder={ trans(`${langKey}.driving`) } />
                </Form.Item>
              </Col>


              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item
                  name="driving_license_expiry_date"
                  rules={ rules.driving_license_expiry_date } label={ trans(`${langKey}.drivingEpiry`) } className="da-mb-16"
                  { ...getErrorProps(errors['driving_license_expiry_date']) } size="small"
                >
                  <InputDate format="DD-MMM-YYYY" />
                </Form.Item>
              </Col>
              <Divider>
                Attachemnts
              </Divider>
              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item label={ trans(`${langKey}.uploadPhoto`) } name="profile_pic" >
                  <Upload accept=".jpg,.png,.jpeg"
                    listType="picture-card"
                    fileList={ profilePicFileList }
                    onChange={ onChangeProfilePic }
                    onPreview={ onPreview }
                    multiple={ false }
                    beforeUpload={ (file) => {
                      return false;
                    } }
                  >
                    { profilePicFileList.length === 1 ? null : 'Upload' }
                  </Upload>
                </Form.Item>
              </Col>
              {/* <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item label={ trans(`${langKey}.cnicAttach`) } name="cnic_attachment" >
                  <div className="logo-style" style={ { width: "100%" } }>
                    <UploadFile
                      fileList={ cnicFileList }
                      setFileList={ setcnicFileList } />
                  </div>
                </Form.Item>
              </Col>

              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item label={ trans(`${langKey}.passportAttach`) } name="passport_attachment" >
                  <div className="logo-style" style={ { width: "100%" } }>
                    <UploadFile
                      fileList={ passportFileList }
                      setFileList={ setpassportFileList } />
                  </div>
                </Form.Item>
              </Col>

              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item label={ trans(`${langKey}.drivingAttach`) } name="driving_license_attachment" >
                  <div className="logo-style" style={ { width: "100%" } }>
                    <UploadFile fileList={ drivingLicenseFileList } setFileList={ setdrivingLicenseFileList } />
                  </div>
                </Form.Item>
              </Col> */}

            </Row>
          </div>

          <div className="employee-button">
            <Form.Item style={ { float: 'right' } }><Button className="employee-next-button" style={ { fontWeight: 600, } }
              type="primary" htmlType="submit" >
              Next
            </Button>
            </Form.Item>
            <Button className="employee-back-button" style={ { fontWeight: 600 } }
              type="primary" onClick={ () => setCurrent(currentStep - 1) } disabled="null">
              Back
            </Button>
          </div>
        </Form>


        <Modal open={ previewOpen } title={ previewTitle } footer={ null } onCancel={ handleCancel }>
          <img
            alt="example"
            style={ {
              width: '100%',
            } }
            src={ previewImage }
          />
        </Modal>
      </>
    )
  }


  //------------------step 2 form---------------------------
  // Create component modal
  //-----new grade------
  const onCreateGrade = () => {
    setChildComponent(<CreateGrade onCompleted={ onGradeCreated } />);
  };
  const onGradeCreated = (data) => {
    if (data.length) {
      employmentForm.setFieldValue('grade_id', data[0].id)
      setGrades(createSelectList(data))
    }
    setChildComponent(null);
  };

  const onChangeGrade=(id)=>{
    
  }
  //-------onCreateLocation
  const onCreateLocation = () => {
    setChildComponent(<CreateLocation onCompleted={ onLocationCreated } />);
  };
  const onLocationCreated = (data) => {
    if (data.length) {
      employmentForm.setFieldValue('location_id', data[0].id)
      setLocations(createSelectList(data))
      onChangeLocation(data[0].id)
      employmentForm.setFieldValue("branch_id", null)
    }
    setChildComponent(null);
  };

  //--------new department------
  const onCreateDepartment = () => {
    setChildComponent(<CreateDepartment onCompleted={ onDepartmentCreated } />);
  };
  const onDepartmentCreated = (data) => {
    if (data.length) {
      employmentForm.setFieldValue('department_id', data[0].id)
      setDepartments(createSelectList(data))
    }
    setChildComponent(null);
  };
  //--------new branch------
  const onCreateBranch = () => {
    setChildComponent(<CreateBranch onCompleted={ onBranchCreated } />);
  };
  const onBranchCreated = (data) => {
    if (data.length) {
      employmentForm.setFieldValue('branch_id', data[0].id)
      setBranches(createSelectList(data))
    }
    setChildComponent(null);
  };
  //--------new Designation------
  const onCreateDesignation = () => {
    setChildComponent(<CreateDesignation onCompleted={ onDesignationCreated } />);
  };
  const onDesignationCreated = (data) => {
    if (data.length) {
      employmentForm.setFieldValue('designation_id', data[0].id)
      setDesignations(createSelectList(data))
    }
    setChildComponent(null);
  };
  //--------new job title------
  const onCreateJobTitle = () => {
    setChildComponent(<CreateJobTitle onCompleted={ onJobTitlesCreated } />);
  };
  const onJobTitlesCreated = (data) => {
    if (data.length) {
      employmentForm.setFieldValue('jobtitle_id', data[0].id)
      setJobTitles(createSelectList(data))
    }
    setChildComponent(null);
  };
  //==========new payroll creation=====
  const onPayrollCreated = (data) => {
    if (data.length) {
      salaryForm.setFieldValue('payroll_id', data[0].id)
      setPayrolls(createSelectList(data))
    }
    setChildComponent(null);
  };

  const onChangeCountry = (id) => {
    makeRequestStateless(getStatesByCountryReq, id, onGetStates, false)
  }

  const onGetStates = (data, response) => {
    setStates(createSelectList(data.all_states))
  }

  const onChangeState = (id) => {
    makeRequestStateless(getCitiesByStateReq, id, onGetCities, false)
  }

  const onGetCities = (data, response) => {
    setCities(createSelectList(data.cities))

  }

  const onSubmitAddress = (data) => {
    setAddressInfo(data)
    if (data) {
      setCurrent(currentStep + 1)
    }
  }
  const onSubmitContact = (data) => {
    setContactInfo(data)
    if (data) {
      setCurrent(currentStep + 1)
    }
  }
  const onSubmitEducation = (data) => {
    setEducationInfo(data)
    if (data) {
      setCurrent(currentStep + 1)
    }
  }
  const onChangeIsDegree = (e) => {
    setIsDegreeCompleted(e.target.checked)
  }

  const skipInfo = (e, step) => {
    const value = e.target.checked;

    if (step === 'address') {
      setHasAddress(value);
    }
    if (step === 'contact') {
      setHasContact(value);
    }
    if (step === 'education') {
      setHasEducation(value);
    }
    if (step === 'relative') {
      setHasRelative(value);
    }
    if (step === 'salary') {
      setSkipSalary(value);
      setCurrent(currentStep + 1)
    }

    if (!value) {
      return
    }

  }
  //--- Address form start-----------
  const employeeAddressForm = () => {
    return (
      <>
        <div className="employee-data"><h5 className="employee-data-style" style={ { fontWeight: 600 } }>Address Information</h5></div>
        <label className="m-4">Skip This Step ? <InputCheck onChange={ (e) => skipInfo(e, 'address') } checked={ hasAddress } /></label>
        <Form
          layout="vertical"
          name={ 'addressForm' }
          onFinish={ onSubmitAddress }
          initialValues={ addressInfo ? addressInfo : {} }
          form={ addressForm }
          scrollToFirstError>
          <div style={ { paddingTop: 25 } }>
            <Row gutter={ 30 }>

              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="address_type" label={ trans(`${langKey}.select.addresstype`) } rules={ hasAddress ? rules.address_type : []} className="da-mb-16"
                  { ...getErrorProps(errors['address_type']) }
                >
                  <SelectWithSearch
                    placeholder={ trans(`${langKey}.select.addresstype`) }
                    options={ [
                      {
                        value: 'Permanent',
                        label: 'Permanent',
                      },
                      {
                        value: 'Present',
                        label: 'Present',
                      },
                    ] }
                  />
                </Form.Item>
              </Col>

              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="complete_address" rules={ hasAddress ? rules.complete_address : [] } label={ trans(`${langKey}.completeAddress`) }
                  className="da-mb-16"
                  { ...getErrorProps(errors['name']) } size="small"
                >
                  <InputText placeholder={ trans(`${langKey}.completeAddress`) } />
                </Form.Item>
              </Col>

              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="country_id" label={ trans(`${langKey}.select.country`) } rules={ rules.country_id } className="da-mb-16"
                  { ...getErrorProps(errors['country_id']) }
                >
                  <SelectWithSearch
                    onChange={ onChangeCountry }
                    placeholder={ trans(`${langKey}.select.country`) }
                    options={ countries }
                  />
                </Form.Item>
              </Col>

              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="state_id" label={ trans(`${langKey}.select.state`) } rules={ rules.state_id } className="da-mb-16"
                  { ...getErrorProps(errors['state_id']) }
                  disabled={ true }
                >
                  <SelectWithSearch
                    onChange={ onChangeState }
                    placeholder={ trans(`${langKey}.select.state`) }
                    options={ states }
                  />
                </Form.Item>
              </Col>

              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="city_id" label={ trans(`${langKey}.select.city`) } rules={ rules.city_id } className="da-mb-16"
                  { ...getErrorProps(errors['city_id']) }
                  disabled={ true }
                >
                  <SelectWithSearch
                    placeholder={ trans(`${langKey}.select.city`) }
                    options={ cities }
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className="employee-button" >
            <Form.Item style={ { float: 'right' } }>
              <Button className="employee-next-button" style={ { fontWeight: 600 } } type="primary" htmlType="submit" >
                Next
              </Button>
            </Form.Item>
            <Button className="employee-backs-button" style={ { fontWeight: 600 } } type="primary" onClick={ () => setCurrent(currentStep - 1) }>
              <span style={ { color: "#4079FC" } }>Back</span>
            </Button>
          </div>
        </Form>
      </>
    )
  }
  const employeeContactForm = () => {
    return (
      <>
        <div className="employee-data"><h5 className="employee-data-style" style={ { fontWeight: 600 } }>Contact Information</h5></div>
        <label className="m-4">Skip This Step ? <InputCheck onChange={ (e) => skipInfo(e, 'contact') } checked={ hasContact } /></label>
        <div style={ { paddingTop: 25 } }>
          <Form layout="vertical" initialValues={ contactInfo ? contactInfo : {} } name={ 'contactForm' } onFinish={ onSubmitContact } scrollToFirstError>
            <Row gutter={ 30 }>

              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="contact_type" label={ trans(`${langKey}.select.contact_type`) } rules={ hasContact ? rules.contact_type: [] } className="da-mb-16"
                  { ...getErrorProps(errors['contact_type']) }
                >
                  <SelectWithSearch
                    placeholder={ trans(`${langKey}.select.contact_type`) }
                    options={ [{
                      'value': "Primary",
                      "label": "Primary"
                    },
                    {
                      "value": "Secondary",
                      "label": "Secondary"
                    }

                    ] }
                  />
                </Form.Item>
              </Col>

              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="name" rules={ hasContact ? rules.name : [] } label={ trans(`${langKey}.name`) }
                  className="da-mb-16"
                  { ...getErrorProps(errors['name']) } size="small"

                >
                  <InputText placeholder={ trans(`${langKey}.name`) } />
                </Form.Item>
              </Col>

              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="relationship" label={ trans(`${langKey}.select.relationship`) } rules={ hasContact ? rules.relationship : [] } className="da-mb-16"
                  { ...getErrorProps(errors['relationship']) }
                >
                  <SelectWithSearch

                    placeholder={ trans(`${langKey}.select.relationship`) }
                    options={ relationships }
                  />
                </Form.Item>
              </Col>

              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="landline_no" rules={ rules.landline_no } label={ trans(`${langKey}.landline_no`) }
                  className="da-mb-16"
                  { ...getErrorProps(errors['landline_no']) } size="small"

                >
                  <InputText placeholder={ trans(`${langKey}.landline_no`) } />
                </Form.Item>
              </Col>

              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="mobile_no" rules={ hasContact ? rules.mobile_no : [] } label={ trans(`${langKey}.mobile_no`) }
                  className="da-mb-16"
                  { ...getErrorProps(errors['mobile_no']) } size="small"

                >
                  <InputText placeholder={ trans(`${langKey}.mobile_no`) } />
                </Form.Item>
              </Col>

              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="email" label={ trans(`${langKey}.email`) }
                  className="da-mb-16"
                  { ...getErrorProps(errors['email']) } size="small"

                >
                  <InputText placeholder={ trans(`${langKey}.email`) } type="email" />
                </Form.Item>
              </Col>

              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="address" label={ trans(`${langKey}.address`) }
                  className="da-mb-16"
                  { ...getErrorProps(errors['address']) } size="small"

                >
                  <InputText placeholder={ trans(`${langKey}.address`) } />
                </Form.Item>
              </Col>
            </Row>

            <div className="employee-button" >
              <Form.Item style={ { float: 'right' } }>
                <Button className="employee-next-button" style={ { fontWeight: 600 } } type="primary" htmlType="submit" >
                  Next
                </Button>
              </Form.Item>
              <Button className="employee-backs-button" style={ { fontWeight: 600 } } type="primary" onClick={ () => setCurrent(currentStep - 1) }>
                <span style={ { color: "#4079FC" } }>Back</span>
              </Button>
            </div>
          </Form>
        </div>
      </>
    )
  }
  const employeeEducationForm = () => {
    return (
      <>
        <div className="employee-data"><h5 className="employee-data-style" style={ { fontWeight: 600 } }>Education Information</h5></div>
        <label className="m-4">Skip this step ? <InputCheck onChange={ (e) => skipInfo(e, 'education') } checked={ hasEducation } /></label>
        <div style={ { paddingTop: 25 } }>
          <Form layout="vertical" initialValues={ educationInfo ? educationInfo : {} }
            name={ 'educationForm' } onFinish={ onSubmitEducation } scrollToFirstError>
            <Row gutter={ 30 }>

              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="institute_name" rules={ hasEducation ? rules.institute_name : []} label={ trans(`${langKey}.institute_name`) }
                  className="da-mb-16"
                  { ...getErrorProps(errors['institute_name']) } size="small"
                >
                  <InputText placeholder={ trans(`${langKey}.institute_name`) } />
                </Form.Item>
              </Col>

              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="degree" rules={ hasEducation ? rules.degree : []} label={ trans(`${langKey}.degree`) }
                  className="da-mb-16"
                  { ...getErrorProps(errors['degree']) } size="small"
                >
                  <InputText placeholder={ trans(`${langKey}.degree`) } />
                </Form.Item>
              </Col>

              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item valuePropName="checked" name="is_degree_completed" label={ trans(`${langKey}.is_degree_completed`) } className="da-mb-16">
                  <Checkbox onChange={ onChangeIsDegree }></Checkbox>
                </Form.Item>
              </Col>

              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="from_date" label={ trans(`${langKey}.from_date`) }
                  className="da-mb-16"
                  { ...getErrorProps(errors['from_date']) } size="small"

                >
                  <InputDate format='YYYY-MM-DD' />
                </Form.Item>
              </Col>
              { isDegreeCompleted && <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="to_date" rules={ isDegreeCompleted ? rules.to_date : []} label={ trans(`${langKey}.to_date`) }
                  className="da-mb-16"
                  { ...getErrorProps(errors['to_date']) } size="small"

                >
                  <InputDate format='YYYY-MM-DD' />
                </Form.Item>
              </Col> }


              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="country" rules={ rules.country } label={ trans(`${langKey}.country`) }
                  className="da-mb-16"
                  { ...getErrorProps(errors['country']) } size="small"
                >
                  <InputText placeholder={ trans(`${langKey}.country`) } />
                </Form.Item>
              </Col>

              {/* <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item label={ trans(`${langKey}.attachment`) } name="degree_attachment" >
                <UploadFile
                  accept=".jpg,.png,.jpeg" listType="picture-card"
                  fileList={ fileList }
                  onChange={ onChange }
                  onPreview={ onPreview }
                  multiple={ false }
                  beforeUpload={ (file) => {
                    return false;
                  } }
                >
                  { fileList.length === 1 ? null : <UploadButton /> }
                </UploadFile>
              </Form.Item>
            </Col> */}

              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="city" rules={ rules.city } label={ trans(`${langKey}.city`) }
                  className="da-mb-16"
                  { ...getErrorProps(errors['city']) } size="small"

                >
                  <InputText placeholder={ trans(`${langKey}.city`) } />
                </Form.Item>
              </Col>

            </Row>

            <div className="employee-button">
              <Form.Item style={ { float: 'right' } }>
                <Button className="employee-next-button" style={ { fontWeight: 600 } } type="primary" htmlType="submit" disabled={ employeeInfo ? false : true } >
                  Next
                </Button>
              </Form.Item>
              <Button className="employee-backs-button" style={ { fontWeight: 600 } } type="primary" onClick={ () => setCurrent(currentStep - 1) }>
                <span style={ { color: "#4079FC" } }>Back</span>
              </Button>
            </div>
          </Form>
        </div>
      </>
    )
  }

  //---employment form start-----------
  const employeeEmploymentForm = () => {
    return (<>
      { childComponent }
      <div className="employee-data"><h5 className="employee-data-style" style={ { fontWeight: 600 } }>Employment Details</h5></div>

      <Form
        // { ...formLayout }
        layout="vertical"
        name={ createEmployment }
        onFinish={ onSubmitEmployment }
        initialValues={ employmentInfo }
        form={ employmentForm }
        scrollToFirstError
      >
        <div style={ { paddingTop: 25 } }>
          <Row gutter={ 20 }>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="prev_code" rules={ rules.user_defined_Code } label={ trans(`${langKey}.userDefinedCode`) } className="da-mb-16"
                { ...getErrorProps(errors['user_defined_Code']) } size="small"
              >
                <InputText
                  placeholder={ trans(`${langKey}.userDefinedCode`) } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="joining_date" rules={ rules.joining_date } label={ trans(`${langKey}.joiningdate`) } className="da-mb-16"
                { ...getErrorProps(errors['joining_date']) } size="small"
              >
                <InputDate format="DD-MMM-YYYY" />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <AddNewButton onClick={ onCreateGrade } />
              <Form.Item name="grade_id" label={ trans(`${langKey}.selectgrades`) } rules={ rules.grade_id } className="da-mb-16"
                { ...getErrorProps(errors['grade_id']) }
              >
                <SelectWithSearch
                  placeholder={ trans(`${langKey}.selectgrades`) }
                  options={ grades }
                  onChange={ onChangeGrade }
                />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <AddNewButton onClick={ onCreateLocation } />
              <Form.Item name="location_id" label={ trans(`${langKey}.selectlocation`) } rules={ rules.location_id } className="da-mb-16"
                { ...getErrorProps(errors['location_id']) }
              >
                <SelectWithSearch
                  placeholder={ trans(`${langKey}.selectlocation`) }
                  options={ locations }
                  onChange={ onChangeLocation }
                />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <AddNewButton onClick={ onCreateDepartment } />
              <Form.Item name="department_id" label={ trans(`${langKey}.selectdepartment`) } rules={ rules.department_id }
                { ...getErrorProps(errors['department_id']) }
              >
                <SelectWithSearch
                  placeholder={ trans(`${langKey}.selectdepartment`) }
                  options={ departments }
                />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <AddNewButton onClick={ onCreateBranch } />
              <Form.Item name="branch_id" label={ trans(`${langKey}.selectbranch`) } rules={ rules.branch_id }
                { ...getErrorProps(errors['branch_id']) }
              >
                <SelectWithSearch
                  placeholder={ trans(`${langKey}.selectbranch`) }
                  options={ branches }
                />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <AddNewButton onClick={ onCreateDesignation } />
              <Form.Item name="designation_id" label={ trans(`${langKey}.selectdesignation`) } rules={ rules.designation_id }
              >
                <SelectWithSearch
                  placeholder={ trans(`${langKey}.selectdesignation`) }
                  options={ designations }
                />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="is_on_probation" valuePropName="checked" label={ trans(`${langKey}.probation`) } >
                <Checkbox onChange={ onChangeProbation }></Checkbox>
              </Form.Item>

              { isProbation ?
                <Form.Item style={ { marginTop: 35 } } name="probation_period_month" rules={ isProbation ? rules.probation_end_date : [] } label={ trans(`${langKey}.probationEndDate`) } className="da-mb-16"
                  { ...getErrorProps(errors['probation_end_date']) } size="small"
                >
                  <InputText placeholder={ trans(`${langKey}.probationEndDate`) } type="number" disabled={ isProbation ? false : true } />
                </Form.Item> : ''
              }
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <AddNewButton onClick={ onCreateJobTitle } />
              <Form.Item name="jobtitle_id" label={ trans(`${langKey}.selectjobtitle`) } rules={ rules.jobtitle_id }
                { ...getErrorProps(errors['jobtitle_id']) }
              >
                <SelectWithSearch
                  placeholder={ trans(`${langKey}.selectjobtitle`) }
                  options={ jobTitles }
                />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="official_email" rules={ rules.official_email } label={ trans(`${langKey}.officialEmail`) }
              >
                <InputText
                  placeholder={ trans(`${langKey}.officialEmail`) } type="email" />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item style={ { paddingTop: 0 } } name="employee_type_id" label={ trans(`${langKey}.selectemployeetype`) } rules={ rules.employee_type_id }
                { ...getErrorProps(errors['employee_type_id']) }
              >
                <SelectWithSearch
                  placeholder={ trans(`${langKey}.selectemployeetype`) }
                  options={ employeeTypes }
                />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item
                name="contracttype_id" label={ trans(`${langKey}.selectemploymenttype`) } rules={ rules.contracttype_id } className="da-mb-16"
                { ...getErrorProps(errors['contracttype_id']) }
              >
                <SelectWithSearch
                  onChange={ onChangeEmploymentType }
                  placeholder={ trans(`${langKey}.selectemploymenttype`) }
                  options={ employmentTypes }
                />
              </Form.Item>
            </Col>
            { isEmploymentType === 1 ?
              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="contract_expiry_date" rules={ rules.contract_expiry_date } label={ trans(`${langKey}.contract_expiry_date`) } className="da-mb-16"
                  { ...getErrorProps(errors['contract_expiry_date']) }
                >
                  <InputDate />
                </Form.Item>
              </Col>
              : '' }
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item valuePropName="checked" name="exempt_attendance" label={ trans(`${langKey}.isattendanceexcempt`) } className="da-mb-16">
                <Checkbox></Checkbox>
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item valuePropName="checked" name="is_retail" label={ trans(`${langKey}.isretailemployee`) } className="da-mb-16">
                <div style={ { display: "flex", marginLeft: -0, color: '#687980' } }>

                  <Form.Item valuePropName="checked" name="is_final_authority">
                    <Checkbox onChange={ onChangeIsFinalAuthority }>Is Final Authority</Checkbox>
                  </Form.Item>

                  <Form.Item valuePropName="checked" name="is_hod">
                    <Checkbox checked={ isFinalAuthority } disabled={ isFinalAuthority }>Is HOD</Checkbox>
                  </Form.Item>

                  <Form.Item valuePropName="checked" name="is_manager">
                    <Checkbox checked={ isFinalAuthority } disabled={ isFinalAuthority }>Is Line Manager</Checkbox>
                  </Form.Item>
                </div>
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item
                name="linemanager_id"
                label={ trans(`${langKey}.selectlinemanager`) } rules={ !isFinalAuthority ? rules.linemanager_id : [] } className="da-mb-16"
                { ...getErrorProps(errors['linemanager_id']) }
                style={ { display: isFinalAuthority ? 'none' : 'block' } }
              >
                <SelectWithSearch
                  placeholder={ trans(`${langKey}.selectlinemanager`) }
                  options={ lineManagers }
                />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item
                name="final_authority_id"
                label={ trans(`${langKey}.selectfinalauthority`) } rules={ !isFinalAuthority ? rules.finalauthority : [] } className="da-mb-16"
                { ...getErrorProps(errors['finalauthority']) }
                style={ { display: isFinalAuthority ? 'none' : 'block', marginTop: 0 } }
              >
                <SelectWithSearch
                  placeholder={ trans(`${langKey}.selectfinalauthority`) }
                  options={ finalAuthorities }
                />
              </Form.Item>
            </Col>
          </Row>
        </div>

        <div className="employee-button">
          <Form.Item style={ { float: 'right' } }>
            <Button className="employee-next-button" style={ { fontWeight: 600 } } type="primary" htmlType="submit" disabled={ employeeInfo ? false : true } >
              Next
            </Button>
          </Form.Item>
          <Button className="employee-backs-button" style={ { fontWeight: 600 } } type="primary" onClick={ () => setCurrent(currentStep - 1) }>
            <span style={ { color: "#4079FC" } }>Back</span>
          </Button>
        </div>
      </Form>
    </>)
  }
  //-----------------
  //------------------step 3 form---------------------------
  const payrollSalaryForm = () => {
    return (
      <>
        <div className="employee-data"><h5 className="employee-data-style" style={ { fontWeight: 600 } }>Payroll & Salary</h5></div>
        <label className="m-4">Skip This Step ? <InputCheck onChange={ (e) => skipInfo(e, 'salary') } checked={ skipSalary } /></label>
        { childComponent }
        <Form
          // { ...formLayout }
          layout="vertical"
          name={ 'createPayroll' }
          onFinish={ onSubmitSalary }
          initialValues={ salaryInfo ? salaryInfo : {} }
          form={ salaryForm }
          scrollToFirstError
        >
          <div style={ { paddingTop: 25 } }>
            <Row gutter={ 20 }>
              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <AddNewButton onClick={ () => setChildComponent(<CreatePayroll onCompleted={ onPayrollCreated } />) } />
                <Form.Item name="payroll_id" rules={ !skipSalary ? rules.payroll:[] } label={ trans(`${langKey}.payroll`) } className="da-mb-16"
                  { ...getErrorProps(errors['payroll']) } size="small"
                >
                  <SelectWithSearch options={ payrolls } placeholder={ "Payroll" } />
                </Form.Item>
              </Col>

              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="type" label={ trans(`${langKey}.payrollType`) } rules={ !skipSalary ? rules.payrollType:[] } className="da-mb-16"
                  { ...getErrorProps(errors['payrollType']) }
                >
                  <SelectWithSearch
                    options={ salaryTypes }
                    placeholder={ trans(`${langKey}.payrollType`) }
                  />
                </Form.Item>
              </Col>

              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="payment_method_id" rules={ !skipSalary ? rules.payrollMethod:[] } label={ trans(`${langKey}.payrollMethod`) } className="da-mb-16"
                  { ...getErrorProps(errors['payrollMethod']) } size="small"
                >
                  <SelectWithSearch options={ paymentmethods } placeholder={ "Payroll Method" } />
                </Form.Item>
              </Col>
              { ispaymentMethod === 1 && <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                {/* <AddNewButton type="primary" onClick={ () => setChildComponent(<CreateBank onCompleted={ onCompleted } />) } /> */ }

                <Form.Item name="bank_id" label={ trans(`${langKey}.bank_id`) } rules={ rules.bank_id } className="da-mb-16"
                  { ...getErrorProps(errors['bank_id']) }

                >
                  <SelectWithSearch options={ [] } placeholder={ trans(`${langKey}.bank_id`) } />

                </Form.Item>
              </Col> }
              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="gross_salary" rules={ !skipSalary ? rules.grossSalary:[] } label={ trans(`${langKey}.grossSalary`) } className="da-mb-16"
                  { ...getErrorProps(errors['grossSalary']) } size="small"

                >
                  <InputText type="number" placeholder={ trans(`${langKey}.grossSalary`) } />
                </Form.Item>
              </Col>

              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="policy_id" rules={ []} label={ trans(`${langKey}.policy`) } className="da-mb-16"
                  { ...getErrorProps(errors['policy']) } size="small"
                >
                  <SelectWithSearch options={ policies } placeholder={ "Policy" } />
                </Form.Item>
              </Col>
            </Row>
          </div>

          <div className="employee-button" >
            <Form.Item style={ { float: 'right' } }>
              <Button className="employee-next-button" style={ { fontWeight: 600 } } type="primary" htmlType="submit" >
                Next
              </Button>
            </Form.Item>
            <Button className="employee-backs-button" style={ { fontWeight: 600 } } type="primary" onClick={ () => setCurrent(currentStep - 1) }>
              <span style={ { color: "#4079FC" } }>Back</span>
            </Button>
          </div>
        </Form>
      </>
    )
  }

  //-----------------
  //------------------step 4 form---------------------------
  const userForm = () => {
    return (
      <>
        <div className="employee-data"><h5 className="employee-data-style" style={ { fontWeight: 600 } }>Account Setting</h5></div>

        <Form
          // { ...formLayout }
          layout="vertical"
          name={ createUser }
          onFinish={ onSubmitUser }
          initialValues={ userInfo }
          autoComplete="off"
          scrollToFirstError
        >
          <div style={ { paddingTop: 25 } }>
            <Row gutter={ 20 }>
              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="email" rules={ rules.user_name } label={ trans(`${langKey}.username`) } className="da-mb-16"
                  { ...getErrorProps(errors['username']) } size="small"
                >
                  <InputText placeholder={ trans(`${langKey}.username`) } />
                </Form.Item>
              </Col>

              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="role_id" label={ trans(`${langKey}.selectrole`) } rules={ rules.role_id } className="da-mb-16"
                  { ...getErrorProps(errors['role_id']) }
                >
                  <SelectWithSearch
                    placeholder={ trans(`${langKey}.selectrole`) }
                    options={ roles }
                  />
                </Form.Item>
              </Col>

              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="password" rules={ rules.password } label={ trans(`${langKey}.password`) } className="da-mb-16"
                  { ...getErrorProps(errors['password']) } size="small"
                >
                  <Input.Password placeholder={ "Password" } type="password" />
                </Form.Item>
              </Col>
            </Row>
          </div >

          <div className="employee-button" >
            <Form.Item style={ { float: 'right' } }>
              <Button className="employee-next-button" style={ { fontWeight: 600 } } type="primary" htmlType="submit">
                Next
              </Button>
            </Form.Item>
            <Button className="employee-backs-button" style={ { fontWeight: 600 } } type="primary" onClick={ () => setCurrent(currentStep - 1) }>
              <span style={ { color: "#4079FC" } }>Back</span>
            </Button>
          </div>
        </Form >
      </>
    )
  }
  const [othersForm] = Form.useForm();
  const [contactForm] = Form.useForm();
  const [educationForm] = Form.useForm();



  const employeeOthersInfo = () => {
    return (
      <>
        <Form
          layout="vertical"
          name={ 'othersForm' }
          onFinish={ onSaveAllData }
          form={ othersForm }
          scrollToFirstError>
          <div className="employee-data"><h5 className="employee-data-style" style={ { fontWeight: 600 } }>Address Information <InputCheck onChange={ (e) => skipInfo(e, 'address') } checked={ hasAddress } /></h5> </div>

          { hasAddress && <Form
            layout="vertical"
            name={ 'addressForm' }
            onFinish={ onSubmitAddress }
            initialValues={ addressInfo ? addressInfo : {} }
            form={ addressForm }
            scrollToFirstError>
            <div style={ { paddingTop: 25 } }>

              <Row gutter={ 30 }>

                <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                  <Form.Item name="address_type" label={ trans(`${langKey}.select.addresstype`) } rules={ hasAddress ? rules.address_type : [] } className="da-mb-16"
                    { ...getErrorProps(errors['address_type']) }
                  >
                    <SelectWithSearch
                      placeholder={ trans(`${langKey}.select.addresstype`) }
                      options={ [
                        {
                          value: 'Permanent',
                          label: 'Permanent',
                        },
                        {
                          value: 'Present',
                          label: 'Present',
                        },
                      ] }
                    />
                  </Form.Item>
                </Col>

                <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                  <Form.Item name="complete_address" rules={ hasAddress ? rules.complete_address : [] } label={ trans(`${langKey}.completeAddress`) }
                    className="da-mb-16"
                    { ...getErrorProps(errors['name']) } size="small"
                  >
                    <InputText placeholder={ trans(`${langKey}.completeAddress`) } />
                  </Form.Item>
                </Col>

                <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                  <Form.Item name="country_id" label={ trans(`${langKey}.select.country`) } rules={ rules.country_id } className="da-mb-16"
                    { ...getErrorProps(errors['country_id']) }
                  >
                    <SelectWithSearch
                      onChange={ onChangeCountry }
                      placeholder={ trans(`${langKey}.select.country`) }
                      options={ countries }
                    />
                  </Form.Item>
                </Col>

                <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                  <Form.Item name="state_id" label={ trans(`${langKey}.select.state`) } rules={ rules.state_id } className="da-mb-16"
                    { ...getErrorProps(errors['state_id']) }
                    disabled={ true }
                  >
                    <SelectWithSearch
                      onChange={ onChangeState }
                      placeholder={ trans(`${langKey}.select.state`) }
                      options={ states }
                    />
                  </Form.Item>
                </Col>

                <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                  <Form.Item name="city_id" label={ trans(`${langKey}.select.city`) } rules={ rules.city_id } className="da-mb-16"
                    { ...getErrorProps(errors['city_id']) }
                    disabled={ true }
                  >
                    <SelectWithSearch
                      placeholder={ trans(`${langKey}.select.city`) }
                      options={ cities }
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
            {/* <div className="employee-button" >
             <Form.Item style={ { float: 'right' } }>
               <Button className="employee-next-button" style={ { fontWeight: 600 } } type="primary" htmlType="submit" >
                 Next
               </Button>
             </Form.Item>
             <Button className="employee-backs-button" style={ { fontWeight: 600 } } type="primary" onClick={ () => setCurrent(currentStep - 1) }>
               <span style={ { color: "#4079FC" } }>Back</span>
             </Button>
           </div> */}
          </Form> }

          <>
            <div className="employee-data"><h5 className="employee-data-style" style={ { fontWeight: 600 } }>Contact Information <InputCheck onChange={ (e) => skipInfo(e, 'contact') } checked={ hasContact } /></h5></div>

            { hasContact && <div style={ { paddingTop: 25 } }>
              <Form layout="vertical" initialValues={ contactInfo ? contactInfo : {} } name={ 'contactForm' } onFinish={ onSubmitContact } form={ contactForm } scrollToFirstError>
                <Row gutter={ 30 }>

                  <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                    <Form.Item name="contact_type" label={ trans(`${langKey}.select.contact_type`) } rules={ hasContact ? rules.contact_type : [] } className="da-mb-16"
                      { ...getErrorProps(errors['contact_type']) }
                    >
                      <SelectWithSearch
                        placeholder={ trans(`${langKey}.select.contact_type`) }
                        options={ [{
                          'value': "Primary",
                          "label": "Primary"
                        },
                        {
                          "value": "Secondary",
                          "label": "Secondary"
                        }

                        ] }
                      />
                    </Form.Item>
                  </Col>

                  <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                    <Form.Item name="name" rules={ hasContact ? rules.name : [] } label={ trans(`${langKey}.name`) }
                      className="da-mb-16"
                      { ...getErrorProps(errors['name']) } size="small"

                    >
                      <InputText placeholder={ trans(`${langKey}.name`) } />
                    </Form.Item>
                  </Col>

                  <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                    <Form.Item name="relationship" label={ trans(`${langKey}.select.relationship`) } rules={ hasContact ? rules.relationship : [] } className="da-mb-16"
                      { ...getErrorProps(errors['relationship']) }
                    >
                      <SelectWithSearch

                        placeholder={ trans(`${langKey}.select.relationship`) }
                        options={ relationships }
                      />
                    </Form.Item>
                  </Col>

                  <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                    <Form.Item name="landline_no" rules={ rules.landline_no } label={ trans(`${langKey}.landline_no`) }
                      className="da-mb-16"
                      { ...getErrorProps(errors['landline_no']) } size="small"

                    >
                      <InputText placeholder={ trans(`${langKey}.landline_no`) } />
                    </Form.Item>
                  </Col>

                  <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                    <Form.Item name="mobile_no" rules={ hasContact ? rules.mobile_no : [] } label={ trans(`${langKey}.mobile_no`) }
                      className="da-mb-16"
                      { ...getErrorProps(errors['mobile_no']) } size="small"

                    >
                      <InputText placeholder={ trans(`${langKey}.mobile_no`) } />
                    </Form.Item>
                  </Col>

                  <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                    <Form.Item name="email" label={ trans(`${langKey}.email`) }
                      className="da-mb-16"
                      { ...getErrorProps(errors['email']) } size="small"

                    >
                      <InputText placeholder={ trans(`${langKey}.email`) } type="email" />
                    </Form.Item>
                  </Col>

                  <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                    <Form.Item name="address" label={ trans(`${langKey}.address`) }
                      className="da-mb-16"
                      { ...getErrorProps(errors['address']) } size="small"

                    >
                      <InputText placeholder={ trans(`${langKey}.address`) } />
                    </Form.Item>
                  </Col>
                </Row>

                {/* <div className="employee-button" >
            <Form.Item style={ { float: 'right' } }>
              <Button className="employee-next-button" style={ { fontWeight: 600 } } type="primary" htmlType="submit" >
                Next
              </Button>
            </Form.Item>
            <Button className="employee-backs-button" style={ { fontWeight: 600 } } type="primary" onClick={ () => setCurrent(currentStep - 1) }>
              <span style={ { color: "#4079FC" } }>Back</span>
            </Button>
          </div> */}
              </Form>
            </div> }

            <>
              <div className="employee-data"><h5 className="employee-data-style" style={ { fontWeight: 600 } }>Education Information <InputCheck onChange={ (e) => skipInfo(e, 'education') } checked={ hasEducation } /></h5></div>
              {
                hasEducation && <div style={ { paddingTop: 25 } }>
                  <Form layout="vertical" initialValues={ educationInfo ? educationInfo : {} }
                    name={ 'educationForm' } onFinish={ onSubmitEducation } form={ educationForm } scrollToFirstError>
                    <Row gutter={ 30 }>

                      <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                        <Form.Item name="institute_name" rules={ hasEducation ? rules.institute_name : [] } label={ trans(`${langKey}.institute_name`) }
                          className="da-mb-16"
                          { ...getErrorProps(errors['institute_name']) } size="small"
                        >
                          <InputText placeholder={ trans(`${langKey}.institute_name`) } />
                        </Form.Item>
                      </Col>

                      <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                        <Form.Item name="degree" rules={ hasEducation ? rules.degree : [] } label={ trans(`${langKey}.degree`) }
                          className="da-mb-16"
                          { ...getErrorProps(errors['degree']) } size="small"
                        >
                          <InputText placeholder={ trans(`${langKey}.degree`) } />
                        </Form.Item>
                      </Col>

                      <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                        <Form.Item valuePropName="checked" name="is_degree_completed" label={ trans(`${langKey}.is_degree_completed`) } className="da-mb-16">
                          <Checkbox onChange={ onChangeIsDegree }></Checkbox>
                        </Form.Item>
                      </Col>

                      <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                        <Form.Item name="from_date" label={ trans(`${langKey}.from_date`) }
                          className="da-mb-16"
                          { ...getErrorProps(errors['from_date']) } size="small"

                        >
                          <InputDate format='YYYY-MM-DD' />
                        </Form.Item>
                      </Col>

                      <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                        <Form.Item name="to_date" label={ trans(`${langKey}.to_date`) }
                          className="da-mb-16"
                          { ...getErrorProps(errors['to_date']) } size="small"

                        >
                          <InputDate format='YYYY-MM-DD' />
                        </Form.Item>
                      </Col>

                      <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                        <Form.Item name="country" rules={ rules.country } label={ trans(`${langKey}.country`) }
                          className="da-mb-16"
                          { ...getErrorProps(errors['country']) } size="small"
                        >
                          <InputText placeholder={ trans(`${langKey}.country`) } />
                        </Form.Item>
                      </Col>

                      {/* <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item label={ trans(`${langKey}.attachment`) } name="degree_attachment" >
                  <UploadFile
                    accept=".jpg,.png,.jpeg" listType="picture-card"
                    fileList={ fileList }
                    onChange={ onChange }
                    onPreview={ onPreview }
                    multiple={ false }
                    beforeUpload={ (file) => {
                      return false;
                    } }
                  >
                    { fileList.length === 1 ? null : <UploadButton /> }
                  </UploadFile>
                </Form.Item>
              </Col> */}

                      <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                        <Form.Item name="city" rules={ rules.city } label={ trans(`${langKey}.city`) }
                          className="da-mb-16"
                          { ...getErrorProps(errors['city']) } size="small"

                        >
                          <InputText placeholder={ trans(`${langKey}.city`) } />
                        </Form.Item>
                      </Col>

                    </Row>

                    {/* <div className="employee-button">
            <Form.Item style={ { float: 'right' } }>
              <Button className="employee-next-button" style={ { fontWeight: 600 } } type="primary" htmlType="submit" disabled={ employeeInfo ? false : true } >
                Next
              </Button>
            </Form.Item>
            <Button className="employee-backs-button" style={ { fontWeight: 600 } } type="primary" onClick={ () => setCurrent(currentStep - 1) }>
              <span style={ { color: "#4079FC" } }>Back</span>
            </Button>
          </div> */}
                  </Form>
                </div>
              }

            </>
            <div className="employee-data"><h5 className="employee-data-style" style={ { fontWeight: 600 } }>Relative Information<InputCheck onChange={ (e) => skipInfo(e, 'relative') } checked={ hasRelative } /></h5></div>
          </>

          { hasRelative &&


            <div style={ { paddingTop: 25 } }>
              <Form form={ relativesForm } name="relativesForm" { ...formLayout }>

                <div className="formlist-container">
                  <h5 className="formlist-title">{ trans(`${langKey}.relatives`) }</h5>
                  <Form.List name="relatives" initialValue={ relativesInfo } >
                    { (fields, { add, remove }) => (
                      <>
                        <Button onClick={ () => {
                          add()

                        } } icon={ <PlusOutlined /> } type="primary" className="add-node-button">Add </Button>
                        <div className="table-container">
                          <table className="table">
                            {
                              fields.length !== 0 && <thead>
                                <tr>
                                  {/* <th style={ { paddingLeft: 20 } }>{ trans(`${langKey}.employee`) }<small className="text-danger"> *</small></th> */ }
                                  <th>{ trans(`${langKey}.relationship`) }<small className="text-danger"> *</small></th>
                                  <th>{ trans(`${langKey}.name`) }<small className="text-danger"> *</small></th>
                                  <th>{ trans(`${langKey}.phone_no`) }<small className="text-danger"> *</small></th>
                                  <th>{ trans(`${langKey}.email`) }</th>
                                  <th>{ trans(`${langKey}.d_o_b`) }</th>

                                  <th>{ trans(`${langKey}.cnic_no`) }</th>
                                  <th>{ trans(`${langKey}.address`) }</th>
                                  <th>{ trans(`${langKey}.is_dependant`) }</th>
                                  <th>{ trans(Commons.actions) }</th>
                                </tr>
                              </thead>
                            }
                            <tbody>
                              { fields.map(({ key, name, ...restField }) => (
                                <>
                                  <tr style={ { marginTop: '5px', marginBottom: '5px' } }>

                                    {/* <td style={ { minWidth: '200px', paddingLeft: 20 } }>
                                  <Form.Item
                                    { ...restField }
                                    name={ [name, 'employee_id'] }
                                    rules={ rules.employee_id }
                                  >
                                     <SelectResourceList type='employee' />
                                  </Form.Item>
                                </td> */}
                                    <td style={ { minWidth: '200px', paddingLeft: 20 } }>
                                      <Form.Item
                                        { ...restField }
                                        name={ [name, 'relationship'] }
                                        rules={ rules.relationship }
                                      >
                                        <SelectWithSearch
                                          options={ relationships }
                                          placeholder={ trans(`${langKey}.relationship`) } />
                                      </Form.Item>
                                    </td>

                                    <td style={ { minWidth: '200px' } }>
                                      <Form.Item
                                        { ...restField }
                                        name={ [name, 'name'] }
                                        rules={ rules.name }
                                      >
                                        <InputText />
                                      </Form.Item>
                                    </td>
                                    <td style={ { minWidth: '200px' } }>
                                      <Form.Item
                                        { ...restField }
                                        name={ [name, 'phone_no'] }
                                        rules={ rules.rel_phone_no }
                                      >
                                        <InputText type="number" />
                                      </Form.Item>
                                    </td>
                                    <td style={ { minWidth: '200px' } }>
                                      <Form.Item
                                        { ...restField }
                                        name={ [name, 'email'] }
                                        rules={ rules.email }
                                      >
                                        <InputText />
                                      </Form.Item>
                                    </td>
                                    <td style={ { minWidth: '200px' } }>
                                      <Form.Item
                                        { ...restField }
                                        name={ [name, 'd_o_b'] }
                                        rules={ rules.d_o_b }
                                      >
                                        <InputDate />
                                      </Form.Item>
                                    </td>
                                    <td style={ { minWidth: '200px' } }>
                                      <Form.Item
                                        { ...restField }
                                        name={ [name, 'cnic_no'] }
                                        rules={ rules.cnic_no }
                                      >
                                        <InputText type="number" />
                                      </Form.Item>
                                    </td>
                                    <td style={ { minWidth: '200px' } }>
                                      <Form.Item
                                        { ...restField }
                                        name={ [name, 'address'] }
                                        rules={ rules.address }
                                      >
                                        <InputTextArea />
                                      </Form.Item>
                                    </td>

                                    <td style={ { minWidth: '200px' } }>
                                      <Form.Item
                                        { ...restField }
                                        name={ [name, 'is_dependant'] }
                                        rules={ rules.is_dependant }
                                        valuePropName="checked"
                                      >
                                        <InputCheck />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <DeleteOutlined className="formlist-remove" onClick={ () => {
                                        remove(name)
                                      } } />
                                    </td>
                                  </tr>
                                </>
                              )) }
                            </tbody>
                          </table>
                        </div>
                      </>
                    ) }
                  </Form.List>
                </div>
              </Form>
            </div>

          }



          <div className="employee-button">
            <Form.Item style={ { float: 'right' } }>
              <Button className="employee-next-button" style={ { fontWeight: 600 } } type="primary" htmlType="submit" form="otherForm" onClick={ onSaveAllData } loading={ saveloader }>
                Save
              </Button>
            </Form.Item>
            <Button className="employee-backs-button" style={ { fontWeight: 600 } } type="primary" onClick={ () => setCurrent(currentStep - 1) }>
              <span style={ { color: "#4079FC" } }>Back</span>
            </Button>
          </div>
        </Form>


      </>

    )
  }
  //-----------------
  return (
    <>
      <ModalComponent top={ 20 } width={ 1600 } maskClosable={ false }
        mainTitle={ trans(Commons.create) } subTitle={ <>

          <p style={ { paddingLeft: 75, marginTop: -30 } }>{ trans(`${langKey}.moduleName`) }</p>
          <Steps
            current={ currentStep }
            onChange={ setCurrent }
          >
            <Steps.Step className={ currentStep === 0 && "bg-primary" } icon={ <img src={ "../../Steps/employeeStep.svg" } alt="Step 0" className="step1" /> } />

            {/* <Steps.Step className={currentStep===2 && "bg-primary"} icon={ <img src={  "../../Steps/employeeContactStep.svg" } alt="Step 2" className="step1" /> } disabled={ !employeeInfo }/>
            <Steps.Step className={currentStep===3 && "bg-primary"} icon={ <img src={  "../../Steps/employeeEducationStep.svg" } alt="Step 3" className="step1" /> } disabled={ !employeeInfo }/> */}
            <Steps.Step className={ currentStep === 1 && "bg-primary" } icon={ <img src={ "../../Steps/employmentStep.svg" } alt="Step 4" className="step1" /> } disabled={ !employeeInfo } />
            <Steps.Step className={ currentStep === 2 && "bg-primary" } icon={ <img src={ "../../Steps/salaryStep.svg" } alt="Step 5" className="step1" /> } disabled={ !employmentInfo } />
            <Steps.Step className={ currentStep === 3 && "bg-primary" } icon={ <img src={ "../../Steps/userStep.svg" } alt="Step 6" className="step1" /> } disabled={ !employmentInfo } />
            <Steps.Step className={ currentStep === 4 && "bg-primary" } icon={ <img src={ "../../Steps/employeeOtherInfoStep.svg" } alt="Step 1" className="step1" /> } disabled={ !userInfo } />
          </Steps>
        </> }
        visible={ true } onCancel={ () => props.onCompleted(false) } footer={ null }>

        { currentStep === 0 ? employeeForm() : currentStep === 1 ? employeeEmploymentForm() : currentStep === 2 ? payrollSalaryForm() : currentStep === 3 ? userForm() : currentStep === 4 ? employeeOthersInfo() : ''}
      </ModalComponent>

    </>
  )
}

export default AddEmployee
const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}
const rules = {
  uploadPhoto: [
    { required: true, message: trans(`${langKey}.uploadPhoto.required`), },
  ],
  salutation_id: [
    { required: true, message: trans(`${langKey}.salutation.required`), },
  ],
  first_name: [
    { required: true, message: trans(`${langKey}.firstName.required`), },
  ],
  last_name: [
    { required: true, message: trans(`${langKey}.lastName.required`), },
  ],
  father_or_husband_name: [
    { required: true, message: trans(`${langKey}.fatherName.required`), },
  ],
  gender_id: [
    { required: true, message: trans(`${langKey}.gender.required`), },
  ],
  d_o_b: [
    { required: true, message: trans(`${langKey}.dob.required`), },
  ],
  marital_status_id: [
    { required: true, message: trans(`${langKey}.dob.required`), },
  ],
  nationality_id: [
    { required: true, message: trans(`${langKey}.nationality.required`), },
  ],
  mobile_no: [
    { required: true, message: trans(`${langKey}.mobileNo.required`), },
  ],
  // email: [
  //   { required: true, message: trans(`${langKey}.email.required`) },
  // ],
  cnic_no: [
    { required: true, message: trans(`${langKey}.cnicNo.required`), }, { min: 13 },
  ],
  passport_no: [
    { required: true, message: trans(`${langKey}.passportNo.required`), },
  ],
  joining_date: [
    { required: true, message: trans(`${langKey}.joiningDate.required`), },
  ],
  grade_id: [
    { required: true, message: trans(`${langKey}.grade.required`), },
  ],
  location_id: [
    { required: true, message: trans(`${langKey}.location.required`), },
  ],
  department_id: [
    { required: true, message: trans(`${langKey}.department.required`), },
  ],
  branch_id: [
    { required: true, message: trans(`${langKey}.branch.required`), },
  ],
  designation_id: [
    { required: true, message: trans(`${langKey}.designation.required`), },
  ],
  jobtitle_id: [
    { required: true, message: trans(`${langKey}.jobtitle.required`), },
  ],
  linemanager_id: [
    { required: true, message: trans(`${langKey}.lineManager.required`), },
  ],
  finalauthority: [
    { required: true, message: trans(`${langKey}.finalAuthority.required`), },
  ],
  official_email: [
    { required: true, message: trans(`${langKey}.officialEmail.required`), },
  ],
  employee_type_id: [
    { required: true, message: trans(`${langKey}.employeeType.required`), },
  ],
  contracttype_id: [
    { required: true, message: trans(`${langKey}.employmentType.required`), },
  ],
  user_name: [
    { required: true, message: trans(`${langKey}.userName.required`), },
  ],
  password: [
    { required: true, message: trans(`${langKey}.password.required`), },
  ],
  passwordconfirmation: [
    { required: true, message: trans(`${langKey}.passwordConfirmation.required`), },
  ],
  timezone_id: [
    { required: true, message: trans(`${langKey}.timezone.required`), },
  ],
  role_id: [
    { required: true, message: trans(`${langKey}.role.required`), },
  ],
  probation_end_date: [
    { required: true, message: trans(`${langKey}.probationEndDate.required`), },
  ],
  contract_expiry_date: [
    { required: true, message: trans(`${langKey}.contract_expiry_date.required`), },
  ],

  payroll: [
    { required: true, message: trans(`${langKey}.payroll.required`), },
  ],
  payrollType: [
    { required: true, message: trans(`${langKey}.payrollType.required`), },
  ],
  payrollMethod: [
    { required: true, message: trans(`${langKey}.payrollMethod.required`), },
  ],
  grossSalary: [
    { required: true, message: trans(`${langKey}.grossSalary.required`), },
  ],
  policy: [
    { required: false, message: trans(`${langKey}.policy.required`), },
  ],
  complete_address: [
    { required: true, message: trans(`${langKey}.address.req`) },
  ],
  employee_id: [
    { required: true, message: trans(`${langKey}.employee.req`) },
  ],
  address_type: [
    { required: true, message: trans(`${langKey}.addresstype.req`) },
  ],

  name: [
    { required: true, message: trans(`${langKey}.name`) },
  ],

  relationship: [
    { required: true, message: trans(`${langKey}.select.relationship`) },
  ],




  contact_type: [
    { required: true, message: trans(`${langKey}.select.contact_type`) },
  ],
  address: [
    { required: true, message: trans(`${langKey}.address.req`) },
    {
      max: 1000, // Adjust the maximum number of characters as needed
      message: 'Max characters limit 1000 exceeded',
    },
  ],

  degree: [
    { required: true, message: trans(`${langKey}.degree.req`) },
  ],

  institute_name: [
    { required: true, message: trans(`${langKey}.institute_name.req`) },
  ],
  from_date: [
    { required: true, message: trans(`${langKey}.from_date.req`) },
  ],
  to_date: [
    { required: true, message: trans(`${langKey}.to_date.req`) },
  ],


  rel_phone_no: [
    { required: true, message: trans(`${langKey}.phone_no.req`) },
  ],

  employee_relative_id: [
    { required: true, message: trans(`${langKey}.employee_relative_id.req`) },
  ],
  undefined: [
    
  ],


};
