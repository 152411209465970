import React from "react";
import { Route, Routes } from "react-router-dom";
import IndexLendCategory from "./settings/lendCategories/IndexLendCategory";
import IndexLendType from "./settings/lendTypes/IndexLendType";
import IndexCompanyLends from "./lends/companyLends/IndexCompanyLends";
import IndexLendApprovals from "./lends/lendApprovals/IndexLendApprovals";
import IndexLendRepayments from "./lendRepayments/IndexLendRepayments";
import { useSelector } from "react-redux";

const LendManagementModuls = () => {
  const authUser = useSelector((state) => state.auth.authUser);
  return (
    <Routes>
      <Route path="settings/categories" element={ <IndexLendCategory modkey="lendCategories"/> } />
      <Route path="settings/types" element={ <IndexLendType modkey="lendTypes"/> } />
      <Route path="lends/company-requests" element={ <IndexCompanyLends filters={ '' } module="company-requests" modkey="companyLends"/> } />
      <Route path="lends/requests" element={ <IndexCompanyLends user={ authUser } filters={ `employee_|${authUser.employment_id}` } module="my-requests" modkey="lends"/> } />
      <Route path="lends/approvals" element={ <IndexLendApprovals api='lendApprovals' modkey="lendApprovals"/> } />
      <Route path="lends/delegates" element={ <IndexLendApprovals api='lendDelegates' modkey="lendDelegates"/> } />
      <Route path="repayments" element={ <IndexLendRepayments modkey="lendRepayments"/> } />
    </Routes>
  );
}

export default LendManagementModuls;