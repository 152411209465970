import { get, del, post, put } from "@utils/axios";

const api = "employee";

export const getAllAddressesReq = (payload) => {
  return get(`${api}/addresses?page=${payload.page}&filters=${payload.filters}`);
};

export const createAddressReq = (payload) => {
  return post(`${api}/addresses?&filters=${payload.filters}`, payload);
};

export const updateAddressReq = (payload) => {
  return put(`${api}/addresses/${payload.id}?&filters=${payload.filters}`, payload);
};

export const deleteAddressReq = (id) => {
  return del(`${api}/addresses/${id}`);
};

//-----------on create
export const getAllEmployeesReq = () => {
  return get("resources/employee?page=1");
};

export const getStatesByCountryReq = (id) => {
  return get(`states/all?filters=country_|${id}`)
}

export const getCitiesByStateReq = (id) => {
  return get(`cities/all?filters=state_|${id}`)
}

export const getFilterReq = (payload) => {
  return get(`filters?company_id=1&fields=page=1&filters=supporting_fields_|address_type,country,city,state,employees;preset_|employee_addresses;`)
}



