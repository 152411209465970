import { post, get } from "@utils/axios";

export const login = (payload) => {
    return post("oauth/webLogin", payload, false);
}

export const forget = (payload) => {
    return post("forgot-password", payload, false);
}

export const reset = (payload) => {
    return post("reset-password", payload, false);
}

export const register = (payload) => {
    return post("register", payload, false);
}
export const getNavigationReq = (payload) => {
    return get("nav");
}

