import { useState, useEffect } from "react";
import {
  BodyComponent,
  TableComponent,
  trans,
  PaginationComponent
} from "@comps/components";
import { makeRequest } from "@utils/helpers";
import { Const } from "../../constants.js";
import { Space, message } from 'antd';
import { FilterComponent, FilterButton } from "@comps/components";
import { getAllDataReq, getFilterReq } from "./requests";
import { Header } from "antd/es/layout/layout";
import dayjs from "dayjs";
const langKey = Const["logs"].lngKey;
const headerStyle = { background: 'white', textAlign: "right" }
const IndexLogs = (props) => {
  const [totalRecords, setTotalRecords] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [loader, setLoader] = useState(false);
  const [childComponent, setChildComponent] = useState(null);
  const [filerComponent, setFilterComponent] = useState(null);
  const [filters, setFilters] = useState();
  //start pagination states
  const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState({
    "total": 0,
    "count": 0,
    "per_page": 0,
    "current_page": 0,
    "total_pages": 0,
    "links": {}
  });
  //end pagination states

  // ==================================
  //   Use States End
  // ==================================

  // ================================== UpdateLocation
  //   Table Data End
  // ==================================

  const columns = [
    {
      key: 'user_name',
      title: trans(`${langKey}.user_name`),
      dataIndex: "user_name",
    },
    {
      key: 'full_name_code',
      title: trans(`${langKey}.full_name_code`),
      dataIndex: "full_name_code",
    },
    {
      key: 'controller',
      title: trans(`${langKey}.controller`),
      dataIndex: "controller",
    },
    {
      key: 'function',
      title: trans(`${langKey}.function`),
      dataIndex: "function",
    },
    {
      key: 'method',
      title: trans(`${langKey}.method`),
      dataIndex: "method",
    },
    {
      key: 'ip_address',
      title: trans(`${langKey}.ip_address`),
      dataIndex: "ip_address",
    },
    {
      key: 'time',
      title: trans(`${langKey}.time`),
      render: (record) => (
        dayjs(record.created_at).format("DD/MM/YYYY hh:mm a")
      )
    },
    {
      key: 'duration',
      title: trans(`${langKey}.duration`),
      dataIndex: "duration"
    },



  ];

  // ==================================
  //   Table Data End
  // ==================================

  useEffect(() => {
    getAllData();
    // eslint-disable-next-line
  }, [filters, currentPage]);

  const getAllData = () => {
    const payload = {
      page: currentPage,
      filters: filters
    }
    makeRequest(setLoader, getAllDataReq, payload, onSuccess, onError);
  };

  const onSuccess = (response) => {
    setPagination(response.logs);
    setDataSource(response.logs.data);
  };


  const onError = (err, res) => {
    message.error(res.response.data.message);
    //setErrors(errorList);
  }


  const onCompleted = (data) => {
    if (data.length) {
      setDataSource(data);
    }
    setChildComponent(null);
  };

  const handleTableChange = (page, fil, sorter) => {
    let payload = {
      ...pagination,
      current: page.current,
      pageSize: page.pageSize,
      sortName: sorter.field || "id",
      sortType: sorter.order === "ascend" ? "asc" : "desc",
    };
    setPagination(payload);
  };

  //---------------filters-----------------------
  const onOpenFilter = () => {
    setFilterComponent(<FilterComponent filters={ filterFields } modkey={props.modkey} selected={ filters } api={ getFilterReq } setCurrentPage={setCurrentPage} onFilter={ setFilters } onCloseFilter={ onCloseFilter }
    />
    )
  }

  const onCloseFilter = () => {
    setFilterComponent(null);

  }

  //----end filter--------------------

  return (
    <>
      { filerComponent }
      { childComponent }
      <BodyComponent>
        <Header style={ headerStyle }>
          <Space>
            <FilterButton onClick={ onOpenFilter } />
          </Space>

        </Header>
        <TableComponent modkey={props.modkey}
          loader={ loader }
          columns={ columns }
          dataSource={ dataSource }
          pagination={ { ...pagination, total: totalRecords } }
          onChange={ handleTableChange }
        />
      </BodyComponent>
      <PaginationComponent pagination={ pagination } modkey={props.modkey} setCurrentPage={ setCurrentPage } currentPage={ currentPage } />
    </>
  );
};

export default IndexLogs;
const filterFields = [
  {
    type: "select",
    placeholder: trans(`${langKey}.select.employees`),
    key: "employees",
    data_key: "supporting_data.employments",
    resource_type:'employment'
  },
  {
    type: "select",
    placeholder: trans(`${langKey}.select.method`),
    key: "method",
    data_key: "supporting_data.method",
    //is_resource: true
  },
  {
    type: "text",
    placeholder: "User Name",
    key: "user_name"
  },
  {
    type: "text",
    placeholder: "IP Address",
    key: "ip_address"
  },
  {
    type: "text",
    placeholder: "Controller",
    key: "controller"
  },
  {
    type: "text",
    placeholder: "Action",
    key: "action"
  },
  {
    type: "date",
    placeholder: "From",
    key: "from"
  },
  {
    type: "date",
    placeholder: "To",
    key: "to"
  },
]

