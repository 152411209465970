import React from "react";
import { Route, Routes } from "react-router-dom";
import IndexLeaveTypes from "./settings/leaveType/IndexLeaveTypes";
import IndexLeaveRequests from "./leave/myLeaves/IndexLeaveRequests";
import IndexQuotaAdjusts from "./quotaAdjustment/IndexQuotaAdjust";
import IndexLeaveApprovals from "./leave/approvals/IndexLeaveApprovals";
import IndexCompanyLeaveRequests from "./leave/companyLeaves/IndexCompanyLeaveRequests";
import IndexSubordinateLeaveRequests from "./leave/subordinateLeaves/IndexSubordinateLeaveRequests";
import IndexRelaxationRequests from "./relaxation/myRelaxation/IndexRelaxationRequests";
import IndexRelaxationApprovals from "./relaxation/relaxationApproval/IndexRelaxationApprovals";
import IndexCompanyRelaxationRequests from "./relaxation/companyRelaxation/IndexCompanyRelaxationRequests";
import IndexCancelLeaveApprovals from "./leave/cancelApprovals/IndexCancelLeaveApprovals";
import IndexCancelRelaxationApprovals from "./relaxation/cancelRelaxationApproval/IndexCancelRelaxationApprovals";
import IndexLeaveConfiguration from "./settings/configurations/IndexLeaveConfiguration";
import { useSelector } from "react-redux";

const LeaveModules = () => {
  const authUser = useSelector((state) => state.auth.authUser);
  return (
    <Routes>
      <Route path="settings/leave-types" element={ <IndexLeaveTypes modkey="leaveTypes"/> } />
      <Route path="settings/configurations" element={ <IndexLeaveConfiguration modkey="leaveConfigurations"/> } />
      <Route path="leave/requests" element={ <IndexCompanyLeaveRequests module='myLeaveRequest' filters={`employee_|${authUser.employment_id}`} modkey="leaveRequest"/> } />
      <Route path="leave/company-requests" element={ <IndexCompanyLeaveRequests filters="" modkey="companyLeaveRequest"/> } />
      <Route path="leave/subordinate-requests" element={ <IndexCompanyLeaveRequests module="subordinates" filters={ `subordinate_|${authUser.employment_id}` } modkey="subordinateLeaves"/> } />
      <Route path="leave/approvals" element={ <IndexLeaveApprovals api="leaveApprovalRequests" modkey="leaveApprovals"/> } />
      <Route path="leave/delegates" element={ <IndexLeaveApprovals api='leaveDelegateApprovals' modkey="leaveDelegation"/> } />
      <Route path="quota-adjustments" element={ <IndexQuotaAdjusts modkey="leaveQuotaAdjustment"/> } />
      <Route path="relaxation/requests" element={ < IndexCompanyRelaxationRequests module='myRelaxation' api="relaxationRequest" filters={`employee_|${authUser.employment_id}`} modkey="relaxation"/> } />
      <Route path="relaxation/company-requests" element={ < IndexCompanyRelaxationRequests relaxationof='company' api="companyRelaxation" filters="" modkey="companyRelaxation"/> } />
      <Route path="relaxation/approvals" element={ < IndexRelaxationApprovals api='relaxationApprovals' modkey="relaxationApprovals"/> } />
      <Route path="relaxation/delegates" element={ < IndexRelaxationApprovals api='relaxationDelegates' modkey="relaxationDelegates" /> } />
      <Route path="leave/cancel-approvals" element={ <IndexCancelLeaveApprovals modkey="leaveCancelApprovals"/> } />
      <Route path="relaxation/cancel-approvals" element={ <IndexCancelRelaxationApprovals modkey="relaxationCancelApprovals"/> } />
    </Routes>
  );
}

export default LeaveModules;
