import React, { useEffect, useState, useRef } from "react";

// Custom hook to check if an element is in the viewport
const useInViewport = (ref) => {
  const [inViewport, setInViewport] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setInViewport(entry.isIntersecting);
      },
      { root: null, rootMargin: "0px", threshold: 0.1 } // Adjust these options as needed
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref]);

  return inViewport;
};

export default useInViewport;
// // Example component using the custom hook
// const MyComponent = ({ outletList, data, setData, setOutlet, setLoader, setStateSuccess }) => {
//   const viewRef = useRef(null);
//   const viewport = useInViewport(viewRef);

//   useEffect(() => {
//     if (viewport && data.length === 0) {
//       setData(outletList);
//       if (outletList.length > 0) {
//         setOutlet(outletList[0].id);
//       }
//       setStateSuccess(setLoader);
//     }
//   }, [viewport, data.length, outletList, setData, setOutlet, setStateSuccess, setLoader]);

//   return (
//     <div ref={viewRef}>
//       {/* Your component content */}
//     </div>
//   );
// };

// export default MyComponent;
