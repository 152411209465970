import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Spin } from 'antd';
import { makeRequest } from '@utils/helpers';
import { getEoeEmployeeReq } from '../requests';
import "../dashboard.css"
import { Icon } from '@iconify/react';
import useInViewport from '../useInViewport';

const EoeEmployees = () => {
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const viewRef = useRef(null);
  const viewport = useInViewport(viewRef);
  useEffect(() => {
    if (viewport && data.length === 0) {
      makeRequest(setLoader, getEoeEmployeeReq, null, onSuccess, Function)
    }
  }, [viewport]);

  const onSuccess = (data) => {
    setData(data);
  }

  return (
    <div className='widgetbox' ref={ viewRef } style={ {
      backgroundColor: '#3AB1CC',
      paddingBottom: '0px',
    } }>
      <Spin spinning={ loader } tip="Loading...">
        <Row style={ {
          color: '#fff',
        } }>
          <Col span={ 7 } style={ {
            textAlign: 'left',
            paddingLeft: '20px',
            paddingTop: '20px',
          } }>
            <Icon style={ {
              fontSize: '50px',
            } } icon="uil:exit" />
          </Col>
          <Col span={ 17 } style={ {
            textAlign: 'right',
            paddingTop: '10px',
            paddingRight: '20px',
          } }>
            <h2 style={ {
              color: '#fff',
            } }>{ data.eoe }</h2>
            <h6 style={ {
              color: '#fff',
              marginTop: '30px',
              borderBottom: '1px solid #fff',
              paddingBottom: '20px',
              fontSize: '14px',
            } }>Employees Separating in this month</h6>
          </Col>
        </Row>
      </Spin>
    </div>
  );
}

export default EoeEmployees;