import React from "react";
import { Route, Routes } from "react-router-dom";
import IndexAllReports from "./allReports/IndexAllReports";
import GenerateReport from "./allReports/components/GenerateReport";

const ReportsModules = () => {
  return (
    <Routes>
      <Route path="all-reports" element={ <IndexAllReports modkey="reportsAll"/> } />
      <Route path="all-reports/generate/:id" element={ <GenerateReport /> } />

    </Routes>
  );
}

export default ReportsModules;
