import React, { useEffect, useState } from "react";
import { Form, message, Row, Col } from "antd";
import { SaveButton, InputText, CancelButton, ModalComponent, trans } from "@comps/components"
import { makeRequest, } from "@utils/helpers";
import { updateDataReq } from "../requests";
import { Commons, Const } from "../../constants";
import EmployeeInfo from '@mods/common/EmployeeInfo';
const langKey = Const["quotaAdjust"].lngKey
const formName = "updateQuotaAdjust";

const UpdateQuotaAdjust = (props) => {
  const [loader, setLoader] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [form] = Form.useForm();
  const [updateForm] = Form.useForm();
  useEffect(() => {
    setFormData();
  }, []);

  const setFormData = () => {
    if (!props) {
      return;
    }
    form.setFieldsValue(props.record);
    updateForm.setFieldsValue(props.record);
  }
  const onSubmit = (data) => {
    data.leave_quota_id = props.record.id;
    const payload = { quota_change: data };
    payload.id = props.record.id;
    makeRequest(setLoader, updateDataReq, payload, onSuccess, onError);
    setSaveLoader(true)
  }

  const onSuccess = (data, res) => {
    setSaveLoader(false)
    message.success("Record Updated");
    props.onCompleted(data.quota_adjustments.data);
  }

  const onError = (err, res) => {
    setSaveLoader(false)
    message.error(res.response.data.message);
  }

  //-----------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [
    <SaveButton loading={ saveLoader } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } width={ 1200 } maskClosable={ false }
      mainTitle={ trans(Commons[props.disabled ? 'view' : 'update']) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) } disabled={ props.disabled }>
      <EmployeeInfo record={ props.record.requestor && props.record.requestor } />

      <Form { ...formLayout } form={ form } disabled={ true } scrollToFirstError>
        <Row gutter={ 30 }>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="leave_type_name" label={ trans(`${langKey}.leave_type_id`) }
              className="da-mb-16"
            >
              <InputText placeholder={ trans(`${langKey}.leave_type_id`) } />
            </Form.Item>
          </Col>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="fiscal_year_start" label={ trans(`${langKey}.fiscal_year_start`) }
              className="da-mb-16"
            >
              <InputText placeholder={ trans(`${langKey}.fiscal_year_start`) } />
            </Form.Item>
          </Col>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="fiscal_year_end" label={ trans(`${langKey}.fiscal_year_end`) }
              className="da-mb-16"
            >
              <InputText placeholder={ trans(`${langKey}.fiscal_year_end`) } />
            </Form.Item>
          </Col>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="total_quota" label={ trans(`${langKey}.quota`) }
              className="da-mb-16"
            >
              <InputText placeholder={ trans(`${langKey}.quota`) } />
            </Form.Item>
          </Col>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="availed" label={ trans(`${langKey}.availed`) }
              className="da-mb-16"
            >
              <InputText placeholder={ trans(`${langKey}.availed`) } />
            </Form.Item>
          </Col>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="available" label={ trans(`${langKey}.available`) }
              className="da-mb-16"
            >
              <InputText placeholder={ trans(`${langKey}.available`) } />
            </Form.Item>
          </Col>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="carry_forward_quota" label={ trans(`${langKey}.carry_forward_quota`) }
              className="da-mb-16"
            >
              <InputText placeholder={ trans(`${langKey}.carry_forward_quota`) } />
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <div className="formlist-container" style={ { paddingLeft: 20, paddingRight: 20 } }>
        <h5 className="formlist-title">{ trans(`${langKey}.moduleName`) }</h5>

        <Form { ...formLayout } disabled={ props.disabled } onFinish={ onSubmit } form={ updateForm } name={ formName }>
          <Row gutter={ 30 }>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="quota" label={ trans(`${langKey}.quota`) }
                className="da-mb-16"
              >
                <InputText placeholder={ trans(`${langKey}.quota`) } />
              </Form.Item>
            </Col>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="availed" label={ trans(`${langKey}.availed`) }
                className="da-mb-16"
              >
                <InputText placeholder={ trans(`${langKey}.availed`) } />
              </Form.Item>
            </Col>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="carry_forward_quota" label={ trans(`${langKey}.carry_forward_quota`) }
                className="da-mb-16"
              >
                <InputText placeholder={ trans(`${langKey}.carry_forward_quota`) } type="number" />
              </Form.Item>
            </Col>
          </Row>

        </Form>
      </div>

    </ModalComponent>
  )
}

export default UpdateQuotaAdjust

const rules = {
  name: [
    { required: true, message: '' },
  ],

};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}