import React from "react";
import { Route, Routes } from "react-router-dom";
import IndexEncashmentType from "./settings/encashmentTypes/IndexEncashmentType";
import IndexDeductionType from "./settings/deductionTypes/IndexDeductionType";
import IndexAbsentPolicy from "./absentPolicy/IndexAbsentPolicy";
import IndexMissingPolicy from "./missingPolicy/IndexMissingPolicy";
import IndexLateArrivalPolicy from "./lateArrivalPolicy/IndexLateArrivalPolicy";
import IndexEarlyLeftPolicy from "./earlyLeftPolicy/IndexEarlyLeftPolicy";
import IndexShortDurationPolicy from "./shortDurationPolicy/IndexShortDurationPolicy";
import IndexRelaxationPolicy from "./relaxationPolicy/IndexRelaxationPolicy";
import IndexLeavePolicy from "./leavePolicy/IndexLeavePolicy";
import IndexOvertimePolicy from "./overtimePolicy /IndexOvertimePolicy";
import IndexCplPolicy from "./cplPolicy/IndexCplPolicy";
import IndexTravelPolicy from "./travelPolicy/IndexTravelPolicy";
import IndexRequestPolicyPlanner from "./requestPolicyPlanner/IndexRequestPolicyPlanner";
import IndexAttendancePolicyPlanner from "./attendancePolicyPlanner/IndexAttendancePolicyPlanner";


const PolicyManagementModules = () => {
  return (
    <Routes>
      <Route path="settings/encashment-types" element={ <IndexEncashmentType modkey="encashmentType"/> } />
      <Route path="settings/deduction-types" element={ <IndexDeductionType modkey="deductionType"/> } />
      <Route path="policies/absent-policy" element={ <IndexAbsentPolicy modkey="absentPolicy"/> } />
      <Route path="policies/missing-policy" element={ <IndexMissingPolicy modkey="missingPolicy"/> } />
      <Route path="policies/late-arrival-policy" element={ <IndexLateArrivalPolicy modkey="lateArrivalPolicy"/> } />
      <Route path="policies/early-left-policy" element={ <IndexEarlyLeftPolicy modkey="earlyLeftPolicy"/> } />
      <Route path="policies/short-duration-policy" element={ <IndexShortDurationPolicy modkey="shortDurationPolicy"/> } />
      <Route path="policies/relaxation-policy" element={ <IndexRelaxationPolicy modkey="relaxationPolicy"/> } />
      <Route path="policies/leave-policy" element={ <IndexLeavePolicy modkey="leavePolicy"/> } />
      <Route path="policies/overtime-policy" element={ <IndexOvertimePolicy modkey="overtimePolicy"/> } />
      <Route path="policies/cpl-policy" element={ <IndexCplPolicy modkey="cplPolicy"/> } />
      <Route path="request-policies/travel-policy" element={ <IndexTravelPolicy modkey="travelPolicy"/> } />
      <Route path="request-policy-planner" element={ <IndexRequestPolicyPlanner modkey="requestPolicyPlanners"/> } />
      <Route path="policy-planer" element={ <IndexAttendancePolicyPlanner modkey="requestPolicies"/> } />
     
    </Routes>
  );
}

export default PolicyManagementModules;
