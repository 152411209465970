
import { useState, useEffect } from "react";
import {
  BodyComponent,
  trans,
  HeaderComponent,
  SaveButton
} from "@comps/components";
import { Checkbox, Form, Skeleton, message } from "antd";
import { makeRequest } from "@utils/helpers";
import { Const } from "../../constants";
import { getAllDataReq, createDataReq } from "./requests";
import TextArea from "antd/es/input/TextArea";


const langKey = Const["payrollConfiguration"].lngKey;
const pageConfig = {
  headers: {
    title: trans(`${langKey}.moduleName`),
    breadcrumb: [
      {
        name: trans(`${langKey}.moduleName`),
        path: Const["payrollConfiguration"].route
      }
    ]
  }
}

const IndexPayrollConfigurations = (props) => {
  const [loader, setLoader] = useState(false);
  const [configuration, setConfiguraion] = useState({});
  const [saving, setSaving] = useState(false)
  const [loading, setLoading] = useState(true)

  const [form] = Form.useForm()


  useEffect(() => {
    getAllData();
    // eslint-disable-next-line
  }, []);

  const getAllData = () => {
    makeRequest(setLoader, getAllDataReq, null, onSuccess, onSuccess);
  };

  const onSuccess = (response, data) => {

    setConfiguraion(data.general)
    form.setFieldsValue(data.general)
    setLoading(false)
  };

  const onSubmit = (data) => {
    setSaving(true)
    const payload = { configurations: data }
    makeRequest(setLoader, createDataReq, payload, onCreated, onError);
  }
  const onCreated = (response, data) => {

    setSaving(false)
    getAllData()
    message.success('Configurations Set Successfully!')
  };
  const onError = (err, res) => {
    message.success('Configurations Set Successfully!')
    getAllData()
    setSaving(false)
    message.error(res.response.data.message);
  }
  return (
    <>
      <BodyComponent>
        <HeaderComponent headers={ pageConfig.headers } modkey={props.modkey}>

        </HeaderComponent>
        <Skeleton loading={ loading } >
          <div style={ { background: "white", borderRadius: 10 } }>
            <SaveButton key="create_button" htmlType="submit" form="payrollConfigurationForm" loading={ saving } style={ { float: 'right', marginRight: 20, marginTop: 18 } } />

            <Form { ...formLayout } onFinish={ onSubmit } form={ form } name="payrollConfigurationForm" style={ { paddingLeft: 20, paddingRight: 20 } }>

              <h5 className="formlist-title" style={ { borderBottom: "1px solid #C9D6DF", height: 80, paddingTop: 30 } }>{ trans(`${langKey}.general`) } </h5>

              <table className="table-configurations table table-bordered table-striped table-condensed" >
                <tbody>
                  <tr>
                    <td>
                      <Form.Item style={ { paddingTop: 15 } } name="payroll_without_final_settlement" label={ trans(`${langKey}.payroll_without_final_settlement`) }
                        className="da-mb-16"
                        valuePropName="checked"
                      >
                        <Checkbox />
                      </Form.Item>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <Form.Item style={ { paddingTop: 15 } } name="final_settlement_note_in_english" label={ trans(`${langKey}.final_settlement_note_in_english`) }
                        className="da-mb-16"
                      >
                        <TextArea />
                      </Form.Item>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <Form.Item style={ { paddingTop: 15 } } name="final_settlement_note_in_other_language" label={ trans(`${langKey}.final_settlement_note_in_other_language`) }
                        className="da-mb-16"
                      >
                        <TextArea />
                      </Form.Item>
                    </td>
                  </tr>
                </tbody>
              </table>

            </Form>
          </div>
        </Skeleton>
      </BodyComponent>
    </>
  )

}

export default IndexPayrollConfigurations


const formLayout = {
  // layout: "horizontal",
  labelCol: { span: 6 },
  wrapperCol: { span: 14, offset: 2 },
  labelAlign: "left"
}
