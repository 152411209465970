
import { useState, useEffect } from "react";
import {
  BodyComponent,
  trans,
  HeaderComponent,
  SaveButton
} from "@comps/components";
import { Checkbox, Form, Skeleton, message } from "antd";
import { makeRequest } from "@utils/helpers";
import { Const } from "../../../constants"
import { getAllDataReq, createDataReq } from "./requests";

const langKey = Const["transferConfiguration"].lngKey;
const pageConfig = {
  headers: {
    title: trans(`${langKey}.moduleName`),
    breadcrumb: [
      {
        name: trans(`${langKey}.moduleName`),
        path: Const["transferConfiguration"].route
      }
    ]
  }
}

const IndexTranferConfigurations = (props) => {
  const [loader, setLoader] = useState(false);
  const [configuration, setConfiguraion] = useState({});
  const [saving, setSaving] = useState(false)
  const [loading, setLoading] = useState(true)

  const [form] = Form.useForm()


  useEffect(() => {
    getAllData();
    // eslint-disable-next-line
  }, []);

  const getAllData = () => {
    makeRequest(setLoader, getAllDataReq, null, onSuccess, onSuccess);
  };

  const onSuccess = (response, data) => {
    setConfiguraion(response)
    form.setFieldsValue(response[0])
    setLoading(false)
  };

  const onSubmit = (data) => {
    setSaving(true)
    const payload = { configuration: data }
    makeRequest(setLoader, createDataReq, payload, onCreated, onError);
  }
  const onCreated = (response, data) => {

    setSaving(false)
    getAllData()
    message.success('Configurations Set Successfully!')
  };
  const onError = (err, res) => {
    message.success('Configurations Set Successfully!')
    getAllData()
    setSaving(false)
    message.error(res.response.data.message);
  }
  return (
    <>
      <BodyComponent>
        <HeaderComponent headers={ pageConfig.headers } modkey={props.modkey}>
        </HeaderComponent>
        <Skeleton loading={ loading } >
          <div style={ { background: "white", borderRadius: 10 } }>
            <SaveButton key="create_button" htmlType="submit" form="attendanceConfigurationForm" loading={ saving } style={ { float: 'right', marginRight: 20, marginTop: 18 } } />

            <Form { ...formLayout } onFinish={ onSubmit } form={ form } name="attendanceConfigurationForm" style={ { paddingLeft: 20, paddingRight: 20 } } scrollToFirstError>

              <h5 className="formlist-title" style={ {  height: 80, paddingTop: 30 } }></h5>

              <table class="table-configurations table table-bordered table-striped table-condensed" >
                <tbody>
                  <tr>
                    <td>
                      <Form.Item name="remove_machine_employee" label={ trans(`${langKey}.remove_machine_employee`) }
                        className="da-mb-16"
                        valuePropName="checked"
                      >
                        <Checkbox />
                      </Form.Item>
                    </td>
                  </tr>

                </tbody>
              </table>
              <h5 className="formlist-title" style={ {  height: 80, paddingTop: 30 } }></h5>
            </Form>
          </div>
        </Skeleton>
      </BodyComponent>
    </>
  )

}

export default IndexTranferConfigurations


const formLayout = {
  // layout: "horizontal",
  labelCol: { span: 6 },
  wrapperCol: { span: 14, offset: 6 },
  labelAlign: "left"
}
