import React from "react";
import { FormattedMessage, injectIntl, useIntl } from "react-intl";

const InjectMassage = (props) => {
  return React.createElement(FormattedMessage, props);
}

export const Translate = (key) => {
  const intl = useIntl();
  const stringExists = !!intl.messages[key];

  return stringExists ? intl.formatMessage({ id: key }) : key;
}


export const TranslateWithoutHook = (key) => {
  let element = injectIntl(InjectMassage, {
    forwardRef: false
  });

  return React.createElement(element, { id: key });
}


export default injectIntl(InjectMassage, {
  forwardRef: false
});

