const employeeKey = "employee";
const baseRoute = "/employees";

export const Const = {
  employee: {
    lngKey: `${employeeKey}.employee`,
    route: `${baseRoute}/company`,
  },
  address: {
    lngKey: `${employeeKey}.address`,
    route: `${baseRoute}/addresses`,
  },
  contact: {
    lngKey: `${employeeKey}.contact`,
    route: `${baseRoute}/contacts`,
  },
  certification: {
    lngKey: `${employeeKey}.certification`,
    route: `${baseRoute}/certifications`,
  },
  education: {
    lngKey: `${employeeKey}.education`,
    route: `${baseRoute}/educations`,
  },
  experience: {
    lngKey: `${employeeKey}.experience`,
    route: `${baseRoute}/experiences`,
  },
  relative: {
    lngKey: `${employeeKey}.relative`,
    route: `${baseRoute}/relatives`,
  },
  bank: {
    lngKey: `${employeeKey}.bank`,
    route: `${baseRoute}/banks`,
  },
  asset: {
    lngKey: `${employeeKey}.asset`,
    route: `${baseRoute}/assets`,
  },
  salary: {
    lngKey: `${employeeKey}.salary`,
    route: `${baseRoute}/saleries`,
  }
};

const cmn = "common";
export const Commons = {
  create: `${cmn}.create`,
  update: `${cmn}.update`,
  view: `${cmn}.view`,
  name: `${cmn}.name`,
  code: `${cmn}.code`,
  location: `${cmn}.location`,
  branch: `${cmn}.branch`,
  department: `${cmn}.department`,
  designation: `${cmn}.designation`,
  joiningDate: `${cmn}.joiningDate`,
  grade: `${cmn}.grade`,
  ProfileCompletion: `${cmn}.ProfileCompletion`,
  actions: `${cmn}.actions`,
  city: `${cmn}.city`,
  country: `${cmn}.country`,
  state: `${cmn}.state`,
  employee: `${cmn}.employee`,
  status: `${cmn}.status`
};

export const colors = {
  "bg-warning": "#faad14",
  "bg-warning-color": "white",
  "bg-success": "green",
  "bg-success-color": "white",
  "bg-darkgray": "#333",
  "bg-danger": "#d9534f",
  "danger": "#d9534f",
  "warning": "#f0ad4e",
  "success": "green",
  "info": '#4079FC',
  "danger": "#EA4335"
}

