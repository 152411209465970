import { get, del, post, put } from "@utils/axios";

const apiMy = "companyRelaxation";
export const getAllDataReq = (payload) => {
  return get(`${payload.api}?page=${payload.page}&filters=${payload.filters}`) //filters=employee_|id
};

export const createDataReq = (payload) => {
  return post(`${apiMy}?page=1&filters=${payload.filters}`, payload);
};

export const updateDataReq = (payload) => {
  return put(`${apiMy}/${payload.id}?page=1&filters=${payload.filters}`, payload);
};
export const cancelRequestReq = (payload) => {
  return post('cancelRequest', payload);
};

export const deleteDataReq = (payload) => {
  return del(`${apiMy}/${payload.id}?page=1&filters=${payload.filters}`);
};

export const getRosterReq = (payload) => {
  return post(`${apiMy}/getRoster`, payload); //relaxation[employment_id]: d46dfabc-2966-42ca-a990-c7bfa0ef4343
  //relaxation[date]: 2023 -09 - 13T00:00:00.000Z
};

export const getFilterReq = (payload) => {
  return get(`${apiMy}?page=1&filters?company_id=1&fields=page=1&filters=organization_structure_fields_|company;preset_|grade;`)
}
export const onDateChange = (payload) => {
  return post(`${apiMy}/dateChange?&`, payload)
}
export const onShiftSelectionChangeReq = (payload) => {
  return post(`${apiMy}/shiftSelectionChange?&`, payload)
}

export const getRelatedDataByReqIdReq = (payload) => {
  return get(`${apiMy}/${payload}`)
};

