import React, { useEffect, useState } from "react";
import { Form, Skeleton, message, Row, Col } from "antd";
import { SaveButton, InputText, CancelButton, ModalComponent, trans, SelectWithSearch, SelectResourceList } from "@comps/components"
import { makeRequest, makeRequestStateless, getErrorProps } from "@utils/helpers";
import { createContactReq, getRelatedDataReq } from "../requests";
import { Commons, Const } from "../../constants";
import { createRelationshipsList, getResourceDataReq } from '@comps/commonFunctions';

const langKey = Const["contact"].lngKey
const formName = "createContact";

const CreateContact = (props) => {

  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [relationships, setRelationships] = useState([]);
  const [saving, setsaving] = useState(false);
  const [loading, setloading] = useState(true);
  const [form] = Form.useForm()

  useEffect(() => {
    makeRequestStateless(getRelatedDataReq, false, ongetRelatedData, false)
    getEmployments()
    form.setFieldValue('employee_id', props.filters !== undefined ? props.filters.replace('employments_|', '') : null)
    // eslint-disable-next-line
  }, []);

  const ongetRelatedData = (response) => {
    setRelationships(createRelationshipsList(response.relationships))
    //setContactTypes(createSelectList(response.contact_types))
  }
  const ongetAllEmployees = (response) => {
    setloading(false)
  }

  const onSubmit = (data) => {
    setsaving(true)
    const payload = { employee_contact: data, filters: props.filters }
    makeRequest(setLoader, createContactReq, payload, onSuccess, onError);
  }
  const getEmployments = (id) => {
    let payload = {
      page: 1,
      type: 'employment',
      filters: `search_|${id}`
    }
    makeRequest(setLoader, getResourceDataReq, payload, ongetAllEmployees, onError);
  };

  const onSuccess = (data, res) => {
    message.success("Contact Saved successfully");
    props.onCompleted(data.employee_contacts.data);
    props.onSuccess(data)

  }

  const onError = (err, res) => {
    setsaving(false)
    message.error(res.response.data.message);
    //setErrors(errorList);
  }
  //-----------on changes------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------
  const footer = [
    <SaveButton loading={ saving } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];
  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------
  return (
    <ModalComponent top={ 20 } width={ 1000 } maskClosable={ false }
      mainTitle={ trans(Commons.create) } subTitle={ trans(`${langKey}.contact`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Skeleton loading={ loading }>
        <Form { ...formLayout } name={ formName } onFinish={ onSubmit } form={ form } scrollToFirstError>
          <Row gutter={ 30 }>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="employee_id" label={ trans(`${langKey}.select.employee`) } rules={ rules.employee_id } className="da-mb-16"
                { ...getErrorProps(errors['employee_id']) }
              >
                <SelectResourceList type='employee'
                  onSearch={ getEmployments }
                  disabled={ props.filters !== undefined }
                />
              </Form.Item>
            </Col>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="contact_type" label={ trans(`${langKey}.select.contact_type`) } rules={ rules.contact_type } className="da-mb-16"
                { ...getErrorProps(errors['contact_type']) }
              >
                <SelectWithSearch
                  placeholder={ trans(`${langKey}.select.contact_type`) }
                  options={ [{
                    'value': "Primary",
                    "label": "Primary"
                  },
                  {
                    "value": "Secondary",
                    "label": "Secondary"
                  }

                  ] }
                />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="name" rules={ rules.name } label={ trans(`${langKey}.name`) }
                className="da-mb-16"
                { ...getErrorProps(errors['name']) } size="small"

              >
                <InputText placeholder={ trans(`${langKey}.name`) } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="relationship" label={ trans(`${langKey}.select.relationship`) } rules={ rules.relationship } className="da-mb-16"
                { ...getErrorProps(errors['relationship']) }
              >
                <SelectWithSearch

                  placeholder={ trans(`${langKey}.select.relationship`) }
                  options={ relationships }
                />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="landline_no" rules={ rules.landline_no } label={ trans(`${langKey}.landline_no`) }
                className="da-mb-16"
                { ...getErrorProps(errors['landline_no']) } size="small"

              >
                <InputText placeholder={ trans(`${langKey}.landline_no`) } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="mobile_no" rules={ rules.mobile_no } label={ trans(`${langKey}.mobile_no`) }
                className="da-mb-16"
                { ...getErrorProps(errors['mobile_no']) } size="small"

              >
                <InputText placeholder={ trans(`${langKey}.mobile_no`) } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="email" rules={ rules.email } label={ trans(`${langKey}.email`) }
                className="da-mb-16"
                { ...getErrorProps(errors['email']) } size="small"

              >
                <InputText placeholder={ trans(`${langKey}.email`) } type="email" />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="address" rules={ rules.address } label={ trans(`${langKey}.address`) }
                className="da-mb-16"
                { ...getErrorProps(errors['address']) } size="small"

              >
                <InputText placeholder={ trans(`${langKey}.address`) } />
              </Form.Item>
            </Col>
          </Row>

        </Form>
      </Skeleton>

    </ModalComponent>
  )
}

export default CreateContact

const rules = {
  name: [
    { required: true, message: trans(`${langKey}.name`) },
  ],
  employee_id: [
    { required: true, message: trans(`${langKey}.select.employee`) },
  ],
  relationship: [
    { required: true, message: trans(`${langKey}.select.relationship`) },
  ],

  mobile_no: [
    { required: true, message: trans(`${langKey}.mobile_no`) }, { max: 13, min: 10 },
  ],

  contact_type: [
    { required: true, message: trans(`${langKey}.select.contact_type`) },
  ],
  address: [
    { required: true, message: trans(`${langKey}.address.req`) },
    {
      max: 1000, // Adjust the maximum number of characters as needed
      message: 'Max characters limit 1000 exceeded',
    },

  ],
};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}