import React, { useEffect, useState } from "react";
import { Form, Input, message } from "antd";
import { SaveButton, InputText, CancelButton, ModalComponent, trans } from "@comps/components";
import { makeRequest, getErrorProps } from "@utils/helpers";

import { updateDesignation } from "../requests";
import { Commons, Const } from "../../constants";

const langKey = Const["designation"].lngKey
const formName = "updateDesignation";
const UpdateDesignation = (props) => {
  const [form] = Form.useForm()
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [saving, setsaving] = useState(false);
  useEffect(() => {
    setFormDataFromProps()
    // eslint-disable-next-line
  }, []);

  const setFormDataFromProps = () => {
    form.setFieldsValue({
      "name": props.record.name
    })
  }


  const onSubmit = (data) => {
    setsaving(true)
    const payload = { id: props.record.id, name: data.name }
    makeRequest(setLoader, updateDesignation, payload, onSuccess, onError);
  }

  const onSuccess = (data, res) => {
    message.success("Designation updated successfully");
    props.onCompleted(data.designations.data);
  }

  const onError = (err, res) => {
    setsaving(false)
    message.error(res.response.data.message);
    //setErrors(errorList);
  }

  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [
    props.disabled ? '' :
      <SaveButton loading={ saving } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } maskClosable={ false } mainTitle={ trans(Commons[props.disabled ? 'view' : 'update']) } subTitle={ trans(`${langKey}.moduleName`) } open={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>

      <Form
        form={ form }
        layout="vertical"
        name={ formName }
        onFinish={ onSubmit }
        disabled={ props.disabled }
      >
        <Form.Item name="name" rules={ rules.name } label={ trans(`${langKey}.name`) }
          className="da-mb-16"
          { ...getErrorProps(errors['name']) } size="small"
        >
          <InputText placeholder={ trans(`${langKey}.name`) } />
        </Form.Item>
      </Form>

    </ModalComponent>
  )
}

export default UpdateDesignation

const rules = {
  name: [
    { required: true, message: trans(`${langKey}.name.required`) },
  ],
};