import React from "react";
import { Route, Routes } from "react-router-dom";
import IndexTravelModes from "./settings/travelModes/IndexTravelModes";
import IndexTravelTypes from "./settings/travelTypes/IndexTravelTypes";
import IndexStayTypes from "./settings/stayTypes/IndexStayTypes";
import IndexCompanyOfficialDuty from "./officialDuty/companyOfficialDuty/IndexCompanyOfficialDuty";
import IndexOfficialDutyApprovals from "./officialDuty/officialDutyApprovals/IndexOfficialDutyApprovals";
import IndexCompanyTravelReq from "./travel/companyTravelRequests/IndexCompanyTravelReq";
import IndexTravelApprovals from "./travel/travelApprovals/IndexTravelApprovals";
import { useSelector } from "react-redux";

const TravelManagementModules = () => {
  const authUser = useSelector((state) => state.auth.authUser);
  return (
    <Routes>
      <Route path="travel-settings/modes" element={ <IndexTravelModes modkey="travelModes"/> } />
      <Route path="travel-settings/types" element={ <IndexTravelTypes modkey="travelTypes"/> } />
      <Route path="travel-settings/stay-types" element={ <IndexStayTypes modkey="travelStayTypes"/> } />
      <Route path="official-duty/company-requests" element={ <IndexCompanyOfficialDuty filters='' module="companyOfficialDuty" modkey="companyOfficialDuty"/> } />
      <Route path="official-duty/requests" element={ <IndexCompanyOfficialDuty filters={ `employee_|${authUser.employment_id}` } module="myOfficialDuty" modkey=""/> } />
      <Route path="official-duty/approvals" element={ <IndexOfficialDutyApprovals api="officialDutyApprovals" modkey="officialDutyApprovals"/> } />
      <Route path="official-duty/delegates" element={ <IndexOfficialDutyApprovals api='officialDutyDelegates' modkey="officialDutyDelegates"/> } />
      <Route path="travels/company-requests" element={ <IndexCompanyTravelReq filters="" module="companyTravel" modkey="companyTravels"/> } />
      <Route path="travels/requests" element={ <IndexCompanyTravelReq filters={ `employee_|${authUser.employment_id}` } module="myTravel" modkey="travels"/> } />
      <Route path="travels/approvals" element={ <IndexTravelApprovals api='travelApprovals' modkey="travelApprovals"/> } />
      <Route path="travels/delegates" element={ <IndexTravelApprovals api='travelDelegates' modkey="travelDelegates"/> } />
    </Routes>
  );
}

export default TravelManagementModules;
