import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './containers/App';
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import store from './redux/store';
import './assets/css/switzer.css'
import './assets/style.css'
store.subscribe(() => { store.getState() })
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={ store }>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);