const orgKey = "organization";
const baseRoute = "/organization";

export const Const = {
  companies: {
    lngKey: `${orgKey}.companies`,
    route: `${baseRoute}/companies`,
  },
  location: {
    lngKey: `${orgKey}.location`,
    route: `${baseRoute}/locations`,
  },
  area: {
    lngKey: `${orgKey}.area`,
    route: `${baseRoute}/areas`,
  },
  jobTitle: {
    lngKey: `${orgKey}.jobTitle`,
    route: `${baseRoute}/jobTitles`,
  },
  department: {
    lngKey: `${orgKey}.department`,
    route: `${baseRoute}/departments`,
  },
  designation: {
    lngKey: `${orgKey}.designation`,
    route: `${baseRoute}/designations`,
  },
  grade: {
    lngKey: `${orgKey}.grade`,
    route: `${baseRoute}/grades`,
  },
  branch: {
    lngKey: `${orgKey}.branch`,
    route: `${baseRoute}/branches`,
  },
};

const cmn = "common";
export const Commons = {
  create: `${cmn}.create`,
  update: `${cmn}.update`,
  view: `${cmn}.view`,
  name: `${cmn}.name`,
  count: `${cmn}.currentHeadCount`,
  identifier: `${cmn}.identifier`,
  code: `${cmn}.code`,
  companyName: `${cmn}.companyName`,
  actions: `${cmn}.actions`,
  updated: `${cmn}.updated`,
  saved: `${cmn}.saved`,
  deleted: `${cmn}.deleted`,
};
