import React, { useEffect, useState } from "react";
import { Form, message } from "antd";
import { SaveButton, InputText, InputTextArea, CancelButton, ModalComponent, trans } from "@comps/components"
import { makeRequest, getErrorProps } from "@utils/helpers";
import { createDataReq } from "../requests";
import { Commons, Const } from "../../../constants";
const langKey = Const["professionalAttribute"].lngKey
const formName = "createProfessionalAttribute";

const CreateProfessionalAttribute = (props) => {
  const [loader, setLoader] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    // eslint-disable-next-line
  }, []);

  const onSubmit = (data) => {
    const payload = { professional_attribute: data };
    makeRequest(setLoader, createDataReq, payload, onSuccess, onError);
    setSaveLoader(true)
  }

  const onSuccess = (data, res) => {
    setSaveLoader(false)
    message.success("Saved successfully");
    props.onCompleted(data.professional_attributes.data);
    props.onSuccess(data)
  }

  const onError = (err, res) => {
    setSaveLoader(false)
    message.error(res.response.data.message);
  }

  //-----------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [
    <SaveButton loading={ saveLoader } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } width={ 600 } maskClosable={ false }
      mainTitle={ trans(Commons.create) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Form { ...formLayout } name={ formName } onFinish={ onSubmit } scrollToFirstError>

        <Form.Item name="name" rules={ rules.name } label={ trans(Commons.name) }
          className="da-mb-16"
          { ...getErrorProps(errors['name']) } size="small"
        >
          <InputText placeholder={ trans(`${langKey}.name`) } />
        </Form.Item>

        <Form.Item name="description" rules={ rules.description } label={ trans(Commons.description) }
          className="da-mb-16"
          { ...getErrorProps(errors['description']) } size="small"
        >
          <InputTextArea placeholder={ trans(`${langKey}.description`) } />
        </Form.Item>

      </Form>
    </ModalComponent>
  )
}

export default CreateProfessionalAttribute

const rules = {
  name: [
    { required: true, message: '' },
  ],

};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}