import { get } from "@utils/axios";

const api = "logs";

export const getAllDataReq = (payload) => {
  return get(`${api}?page=${payload.page}&filters=${payload.filters}`)
};

export const getFilterReq = (payload) => {
  return get(`filters?company_id=1&fields=?page=1&filters?company_id=1&fields=page=1&filters=supporting_fields_|employments,method;preset_|logs;`)
}