import React, { useEffect, useState } from "react";
import { Button, Divider, Form, Input, message, Popconfirm, Space } from "antd";
import { SaveButton, InputText, CancelButton, ModalComponent, trans, InputDate, InputTime, TableComponent, ActionComponent } from "@comps/components"
import { makeRequest, getErrorProps } from "@utils/helpers";
//import { updateDataReq } from "../requests";
import { Commons, Const } from "../constants";
import moment from "moment/moment";
import RoasterView from "@mods/common/RoasterView";
import { getRosterReq } from "../relaxation/myRelaxation/requests";
import dayjs from 'dayjs';
import { approveRequest } from "../relaxation/relaxationApproval/requests";
import CreateConsent from "./CreateConsent";
import CreateConsentChat from "./CreateConsentChat";
import { PlusOutlined } from "@ant-design/icons";
import { approveCancelRequestReq } from "./requests";
const { TextArea } = Input;
const langKey = Const["relaxationApproval"].lngKey
const formName = "updateRelaxationApproval";

const ApproveCanceRelaxationRequest = (props) => {
  const [loader, setLoader] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [roasterView, setRoasterView] = useState(null)
  const [preEdit, setPreEdit] = useState(true)
  const [remarks, setRemarks] = useState('');
  const [consents, setConsents] = useState([]);
  const [creatConsentModal, setCreateConsentModal] = useState(null);
  const [loading, setLoading] = useState(true)
  const [form] = Form.useForm()
  useEffect(() => {
    setData();
    // eslint-disable-next-line
  }, []);
  const setData = () => {

    setConsents(props.record.consents)
    let date = props.record.requestable.date ? dayjs(props.record.requestable.date) : null
    getRoster(date)
    props.record.requestable.date = date
    props.record.requestable.start_time = props.record.requestable.start_time ? dayjs(props.record.requestable.start_time) : null
    props.record.requestable.end_time = props.record.requestable.end_time ? dayjs(props.record.requestable.end_time) : null
    form.setFieldsValue(props.record.requestable);
  }
  const getRoster = (date) => {
    const payload = { relaxation: { employment_id: props.record.employment_id, date: date.format('YYYY-MM-DD') } }
    makeRequest(setLoader, getRosterReq, payload, onGetRosterSuccess, onGetRosterSuccess);
  }
  const onGetRosterSuccess = (response, data) => {
    if (data.shifts) {
      setRoasterView(<RoasterView record={ data } />)
      let start_time = data.shifts[moment(data.from_date).format('YYYYMMDD') + '-' + data.employments[0].id][0]['start']
      let end_time = data.shifts[moment(data.from_date).format('YYYYMMDD') + '-' + data.employments[0].id][0]['end']

      // form.setFieldsValue({
      //   start_time: dayjs(start_time),
      //   end_time: dayjs(end_time),
      // })

    }
    else {
      message.error('No Shift Found For Given Date!')
      return
    }
  }

  //===========constents =============
  const onCreateConsent = () => {
    setCreateConsentModal(<CreateConsent onCreatedConsent={ onCreatedConsent } employment_id={ props.record.employment_id } request_id={ props.record.id } request_as={ props.requestAsId } api="relaxationApprovals/createConsent" />)
  }
  const onCreatedConsent = (response) => {
    if (response) {
      message.success("Consent created successfully");
      props.onCompleted(response.relaxations.data);
    }

    setCreateConsentModal(null)
  }
  const onConsentChat = (record) => {
    setCreateConsentModal(<CreateConsentChat record={ record } onCreatedConsent={ onCreatedConsent } request_as={ props.requestAsId } api="relaxationApprovals/messageConsent" />)
  }
  //============end consents================================
  const onSubmit = (isApproval) => {
    setSaveLoader(true)
    let obj = props.record;
    obj.is_approval = isApproval;
    obj.delegator_id = null;
    obj.request_as = props.requestAsId;
    obj.remarks = remarks;
    const payload = { request: obj, filters: "cancel_request_type_|relaxation_request;" }
    makeRequest(setLoader, approveCancelRequestReq, payload, onSuccess, onError);
  }
  const onSuccess = (data, res) => {
    setSaveLoader(false)
    message.success("Saved successfully");
    props.onCompleted(data.cancel_requests.data);
  }
  const onError = (err, res) => {
    setSaveLoader(false)
    message.error(res.response.data.message);
  }
  //----------------------

  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------
  const footer = [
    <Space>
      <Popconfirm onConfirm={ () => onSubmit(true) }
        description="Are you sure to perform this Action?">
        <SaveButton disabled={ props.disabled } loading={ saveLoader } form={ formName } key="create_button" text='Approve' />
      </Popconfirm>

      <Popconfirm onConfirm={ () => onSubmit(false) }
        description="Are you sure to perform this Action?">
        <CancelButton disabled={ props.disabled } loading={ saveLoader } key="reject_button" form={ formName } htmlType='submit' text='Reject' /> </Popconfirm>
      <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
    </Space>

  ];
  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------
  return (
    <ModalComponent top={ 20 } width={ 1200 } maskClosable={ false }
      mainTitle={ trans(props.disabled ? Commons.view : Commons.update) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Form { ...formLayout } name={ formName } onFinish={ onSubmit } form={ form } disabled={ true } scrollToFirstError>
        <Divider>Relaxation Request</Divider>
        <Form.Item name="date" rules={ rules.date } label={ trans(`${langKey}.date`) }
          className="da-mb-16"
          { ...getErrorProps(errors['date']) } size="small"
        >
          <InputDate />
        </Form.Item>
        { roasterView }
        <Form.Item name="start_time" rules={ rules.start_time } label={ trans(`${langKey}.start_time`) }
          className="da-mb-16"
          { ...getErrorProps(errors['start_time']) } size="small"
        >
          <InputTime />
        </Form.Item>
        <Form.Item name="end_time" rules={ rules.end_time } label={ trans(`${langKey}.end_time`) }
          className="da-mb-16"
          { ...getErrorProps(errors['end_time']) } size="small"
        >
          <InputTime />
        </Form.Item>
        <Divider>Cancel Request</Divider>

        <Form.Item name="reason" rules={ rules.reason } label={ trans(`${langKey}.reason`) }
          className="da-mb-16"
          { ...getErrorProps(errors['reason']) } size="small"
        >
          <TextArea placeholder="Reason" />
        </Form.Item>
        <Divider>Approval</Divider>
        <Form.Item name="remarks" rules={ rules.last_remarks } label={ trans(`${langKey}.last_remarks`) }
          className="da-mb-16"
          { ...getErrorProps(errors['last_remarks']) } size="small"
        >
          <TextArea disabled={ props.disabled } onChange={ (e) => setRemarks(e.target.value) } />
        </Form.Item>
      </Form>


    </ModalComponent>
  )
}

export default ApproveCanceRelaxationRequest

const rules = {
  name: [
    { required: true, message: '' },
  ],

};

const formLayout = {
  // layout: "horizontal",
  labelCol: { span: 6 },
  wrapperCol: { span: 14, offset: 2 },
  labelAlign: "left"
}