import React, { useState, useEffect } from 'react';
import "../dashboard.css"
import BaseClock from '@comps/others/BaseClock';
import { Row, Col } from 'antd';


const ClockWidget = () => {

  return (
    <div className='widgetbox widgetboxminH'>
      <Row>
        <Col span={ 24 } >
          <h3>Clock</h3>
        </Col>
      </Row>
      <div className='widgetcontent' >
        <BaseClock />
      </div>
    </div>
  );
}

export default ClockWidget;