import axios from "axios";
import Cookies from "js-cookie";
import { getApiBaseUrl } from "@comps/commonFunctions";
import { message ,Modal} from 'antd';
import { notify } from "./helpers";
import { ModalComponent } from '@comps/components';

let defaultHeader = {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
}
function getAxios(tocInstance, header) {
    const baseApi = getApiBaseUrl()
    const instance = axios.create({
        baseURL: process.env.REACT_APP_ENV === "local" ? process.env.REACT_APP_API_BASE_URL : baseApi,
        headers: header ?
            header : defaultHeader
    });
    // Hooking a request interceptor
    // hookRequestInterceptorsWithAxiosInstance(instance, req);

    // Hooking a response interceptor
    // hookResponseInterceptorWithAxiosInstance(instance);

    if (tocInstance) {
        const bearer = Cookies.get("token");
        if (bearer) {
            instance.defaults.headers.common.Authorization = `Bearer ${bearer}`;
        } else {
            localStorage.clear();
            Cookies.remove("token");
            window.location.href = "/";
            // push toast message
            return;
        }
    }
    return instance;
}

function onAxiosRejected(error) {

    if(error.code==="ERR_BAD_REQUEST" && error.response && error.response.data && error.response.data.message){
       message.warning(error.response.data.message);
    }
    if(error.code==="ERR_NETWORK"){
        message.warning(error.message);
        //logOut(error);
       return [];
    }
    
    if (error.response.status === 404 && error.response.data.blocked) {
        message.warning(error.response.data.error);
        return [];
    }
    if (error.response.status === 401) {
        logOut(error)
        // push toast message
        return [];
    }

    // handle exception failure.
    
    return error;
}
//logout if unauthenticated
const logOut = (res) => {
    
    localStorage.clear();
    Cookies.remove("token");
    window.location.href = "/";
    // push toast message
    return;
}
// Start
// Requests that accepts application/json

export const get = (uri, payload = null, tocInstance = true, req = false) =>
    getAxios(tocInstance, req)
        .get(uri, { params: payload })
        .then((res) => {
            if (res.data && res.data.code === 401) {
                logOut()
                return;
            }
            return res.data;
        }, onAxiosRejected);

export const download = (uri, payload = null, tocInstance = true, req = false) =>
    getAxios(tocInstance, req)
        .get(uri, { responseType: 'blob', params: payload })
        .then((res) => {
            if (res.data && res.data.code === 401) {
                logOut()
                return;
            }
            return res.data;
        }, onAxiosRejected);


export const post = (uri, payload, tocInstance = true, req = false) =>
    getAxios(tocInstance, req)
        .post(uri, payload)
        .then((res) => {
            
            if (res.data && res.data.code === 401) {
                logOut()
                return;
            }
            return res.data;
        }, onAxiosRejected);



export const put = (uri, payload, tocInstance = true, req = false) =>
    getAxios(tocInstance, req)
        .put(uri, payload)
        .then((res) => {
            if (res.data && res.data.code === 401) {
                logOut()
                return;
            }
            return res.data;
        }, onAxiosRejected);


export const del = (uri, tocInstance = true, req = false) =>
    getAxios(tocInstance, req)
        .delete(uri)
        .then((res) => {
            if (res.data && res.data.code === 401) {
                logOut()
                return;
            }
            return res.data;
        }, onAxiosRejected);


export const delWithPayload = (uri, payload, tocInstance = true, req = false) =>
    getAxios(tocInstance, req)
        .delete(uri, { data: payload })
        .then((res) => {
            if (res.data && res.data.code === 401) {
                logOut()
                return;
            }
            return res.data;
        }, onAxiosRejected);


// End
// Requests that accepts application/json


// Start
// Requests that accepts multipart/form-data

const headerWithFormData = {
    ...defaultHeader,
    "Content-Type": "multipart/form-data",
};

export const postFd = (uri, payload, tocInstance = true) => {
    return getAxios(tocInstance, headerWithFormData)
        .post(uri, payload)
        .then((res) => {
            if (res.data && res.data.code === 401) {
                logOut()
                return;
            }
            return res.data;
        }, onAxiosRejected);
};

export const putFd = (uri, payload, tocInstance = true) =>

    getAxios(tocInstance, headerWithFormData)
        .put(uri, payload)
        .then((res) => {
            if (res.data && res.data.code === 401) {
                logOut()
                return;
            }
            return res.data;
        }, onAxiosRejected);




// End
// Requests that accepts multipart/form-data