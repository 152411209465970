import { ModalComponent, trans } from "@comps/components"
import { Const } from "../../constants";



const langKey = Const["leaveEncashment"].lngKey
const ResponseErrors = (props) => {

  return (
    <ModalComponent visible={ true } mainTitle={ trans(`${langKey}.errors`) } subTitle={ trans(`${langKey}.moduleName`) } width={ 800 } onCancel={ () => props.closeErrors(false) } onOk={ () => props.closeErrors(false) }>
      <table className="table-configurations table table-bordered table-striped table-condensed">
        <thead>
          <tr>
            <th>{ trans(`${langKey}.employment_id`) }</th>
            <th>{ trans(`${langKey}.message`) }</th>
          </tr>
        </thead>
        <tbody>
          { props.errors.map(error => (
            <tr>
              <td>{ error.emp_name }</td>
              <td>{ error.message }</td>
            </tr>
          )) }

        </tbody>
      </table>
    </ModalComponent>
  )


}

export default ResponseErrors