import { Input } from "antd";
import { getPlaceholder } from '@comps/commonFunctions';
const BaseInput = (props) => {
  return (
    <Input
      style={ {
        width: '100%',
        height: '48px',
      } }
      { ...props }
      placeholder={ getPlaceholder(props.placeholder) }
    />
  )
}

export default BaseInput