
import { get } from '@utils/axios';

const apiTimeZones = "common/getTimezones"
const apiCurrencies = "common/getCurrencies"

export const getCompanyReq = () => {
  return get('common/getCompany')
}

export const getAllTimeZonesReq = () => {
  return get(apiTimeZones)
};

export const getAllCurrenciesReq = () => {
  return get(apiCurrencies)
};

export const getAllcountriesReq = () => {
  return get("countries/all?")
}
export const getAllEmploymentsReq = () => {
  return get("resources/employment?page=1&filters=")
}

