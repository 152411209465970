import React, { useEffect, useState } from "react";
import { Button, Form, Space, Row, Checkbox, Col } from "antd";
import { SelectWithSearch, InputText, trans, InputDate } from "@comps/components"

import { Const } from "../../constants";
const CreateStepOne = (props) => {
  const langKey = Const["requestPolicyPlanner"].lngKey
  const formName = "createRequestPolicyPlanner";
  //========================use states start========================
  const [errors, setErrors] = useState([]);

  //------------intitial steps state---------

  const [deleteAll, setForAllEmployee] = useState(false);
  //=======================================
  useEffect(() => {
    setData();
    // eslint-disable-next-line
  }, [props]);

  //set props data
  //=================Submit First Step 1==============================================
  const setData = () => {
    if (props.initialValues) {


    }
  }


  //=====================On Submit Second Step===========================
  const onSubmitFirstStep = (data) => {
    props.response(data);
  }

  //-----------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------
  return (
    <>
      <Form { ...formLayout } name={ formName } onFinish={ onSubmitFirstStep } initialValues={ props.initialValues ? props.initialValues : null }>
        <Row gutter={ 30 }>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="name" rules={ rules.name } label={ trans(`${langKey}.name`) }
              className="da-mb-16"

            >
              <InputText placeholder={ trans(`${langKey}.name`) } />
            </Form.Item>
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="date" rules={ rules.date } label={ trans(`${langKey}.date`) }
              className="da-mb-16"

            >
              <InputDate />
            </Form.Item>
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="for_all" rules={ rules.for_all } label={ trans(`${langKey}.for_all`) }
              className="da-mb-10"

              valuePropName="checked"
            >
              <Checkbox onChange={ (e) => setForAllEmployee(e.target.checked) } />
            </Form.Item>
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="travel_policy_id" rules={ rules.travel_policy_id } label={ trans(`${langKey}.travel_policy_id`) }
              className="da-mb-10"
            >
              <SelectWithSearch
                placeholder={ trans(`${langKey}.travel_policy_id`) }
                options={ props.travelPolicies }
              />
            </Form.Item>
          </Col>
        </Row>

        <Row justify="end">
          <Space>
            <Button className="button-next" type="primary" htmlType="submit">
              Next
            </Button>
          </Space>
        </Row>
      </Form>
    </>
  )
}
export default CreateStepOne
const rules = {
  date: [
    { required: true, message: 'Required' },
  ],
  name: [
    { required: true, message: 'Required' },
  ],

  travel_policy_id: [
    { required: true, message: 'Required' },
  ],

};
const formLayout = {
  layout: 'vertical',
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}
