import { useState, useEffect } from "react";
import {
  BodyComponent,
  TableComponent,
  ActionComponent,
  CreateButton,RefreshButton,
  trans,
  HeaderComponent,
  PaginationComponent
} from "@comps/components";
import { makeRequest } from "@utils/helpers";
import { Commons, Const } from "../constants";
import { Popconfirm, message } from 'antd';
import { FilterComponent, ButtonComponent } from "@comps/components";
import { closePayrollDataReq, deleteDataReq, getAllDataReq, getFilterReq } from "./requests";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
//import RunPayroll from "./RunPayroll";
import { WarningOutlined } from "@ant-design/icons";
const langKey = Const["dailyWagePayroll"].lngKey;
const pageConfig = {
  headers: {
    title: trans(`${langKey}.moduleName`),
    breadcrumb: [
      {
        name: trans(`${langKey}.moduleName`),
        path: Const["dailyWagePayroll"].route
      }
    ]
  }
}

const IndexDailyWagePayrolls = (props) => {
  const navigate = useNavigate()
  const authUser = useSelector((state) => state.auth.authUser);
  const [totalRecords, setTotalRecords] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [loader, setLoader] = useState(false);
  const [childComponent, setChildComponent] = useState(null);
  const [filerComponent, setFilterComponent] = useState(null);
  const [allData, setAllData] = useState({});
  const [filters, setFilters] = useState(props.attendanceof === 'my' ? `employee_|${authUser.employment_id}` : '');
  //start pagination states
  const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState({
    "total": 0,
    "count": 0,
    "per_page": 0,
    "current_page": 0,
    "total_pages": 0,
    "links": {}
  });
  //end pagination states

  // ==================================
  //   Use States End
  // ==================================

  // ==================================
  //   Table Data End
  // ==================================

  const columns = [

    {
      key: trans(Commons.code),
      title: trans(Commons.code),
      dataIndex: 'code'
    },
    {
      key: trans(Commons.name),
      title: trans(Commons.name),
      dataIndex: 'name'
    },
    {
      key: trans(Commons.actions),
      title: trans(Commons.actions),
      render: (record) => (
        <ActionComponent
          each={ record } modkey={props.modkey}
          onEdit={ onEdit }
          onView={ onView }
          onDelete={ !record.history && onDelete }
        >
          <ButtonComponent onClick={ () => onRun(record) }>Run</ButtonComponent>
          <Popconfirm title="Are you sure?" icon={ <WarningOutlined /> } onConfirm={ () => onClose(record) }>
            { record.history && record.history.status === 'Run' && <ButtonComponent text='Close'></ButtonComponent> }
          </Popconfirm>

        </ActionComponent>
      ),
    },
  ];

  // ==================================
  //   Table Data End
  // ==================================

  useEffect(() => {
    getAllData();
    // eslint-disable-next-line
  }, [filters, currentPage]);
  const getAllData = () => {
    const payload = {
      page: currentPage,
      filters: filters
    }
    makeRequest(setLoader, getAllDataReq, payload, onSuccess, onGetAllDataError);
  };
  const onSuccess = (response) => {
    setAllData(response);
    setPagination(response.daily_wage_payrolls);
    setDataSource(response.daily_wage_payrolls.data);
  };
  const onGetAllDataError = (err, res) => {
    message.error(res.response.data.message);
  }

  const onEdit = (record) => {
    //navigate('edit/' + record.id)
  };
  const onView = (record) => {
    //navigate('view/' + record.id)
  };
  const onRun = (record) => {
    // setChildComponent(<RunPayroll onCompleted={ onCompleted } record={ record } authUser={ authUser } allData={ allData } disabled={ false } />);
  };

  const onClose = (record) => {
    const payload = {
      payroll: record.id
    }
    makeRequest(setLoader, closePayrollDataReq, payload, onDeleted, onError);
  };

  const onDelete = (record) => {
    const payload = {
      filters: `employee_|${record.employment_id}`, id: record.id
    }
    makeRequest(setLoader, deleteDataReq, payload, onDeleted, onError);
  };

  const onDeleted = (res) => {
    onSuccess(res)
    setDataSource(res.daily_wage_payrolls.data);
    message.success("Record Deleted")
  };

  const onError = (err, res) => {
    message.error(res.response.data.message);
    //setErrors(errorList);
  }

  // Create component modal
  const onCreate = () => {

    //navigate('create')
  };

  const onCompleted = (data) => {
    if (data.length) {
      setDataSource(data);
    }
    setChildComponent(null);
  };

  const handleTableChange = (page, fil, sorter) => {
    let payload = {
      ...pagination,
      current: page.current,
      pageSize: page.pageSize,
      sortName: sorter.field || "id",
      sortType: sorter.order === "ascend" ? "asc" : "desc",
    };
    setPagination(payload);
  };

  //---------------filters-----------------------
  const onOpenFilter = () => {
    setFilterComponent(<FilterComponent filters={ filterFields } modkey={props.modkey} selected={ filters } api={ getFilterReq } setCurrentPage={setCurrentPage} onFilter={ setFilters } onCloseFilter={ onCloseFilter }
    />
    )
  }

  const onCloseFilter = () => {
    setFilterComponent(null);

  }

  //----end filter--------------------
  return (
    <>
      { filerComponent }
      { childComponent }
      <BodyComponent>
        <HeaderComponent headers={ pageConfig.headers } modkey={props.modkey}>
          <RefreshButton onClick={ getAllData } /> <CreateButton onClick={ onCreate } />
          {/* <FilterButton onClick={ onOpenFilter } /> */ }
        </HeaderComponent>
        <TableComponent
          loader={ loader }
          columns={ dataSource.length > 0 ? columns : [] }
          dataSource={ dataSource }
          pagination={ { ...pagination, total: totalRecords } }
          onChange={ handleTableChange }
        />
      </BodyComponent>
      <PaginationComponent pagination={ pagination } modkey={props.modkey} setCurrentPage={ setCurrentPage } currentPage={ currentPage } />
    </>
  );
};

export default IndexDailyWagePayrolls;
const filterFields = [
  {
    type: "text",
    placeholder: "Name",
    key: "name"
  },
  {
    type: "text",
    placeholder: "Code",
    key: "code"
  },
]

