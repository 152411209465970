import React, { useState } from "react";
import { Form, message } from "antd";
import { SaveButton, InputDate, CancelButton, ModalComponent, trans } from "@comps/components"
import { makeRequestStateless } from "@utils/helpers";
import { downloadSampleLogByDateReq } from "../requests";
import { Const } from "../../constants";
import { handleDownloadFile } from "@utils/helpers";

const langKey = Const["attendanceLogs"].lngKey
const formName = "createAttendanceRequest";
const DownloadSampleLogByDate = (props) => {
  const [saving, setsaving] = useState(false);

  const [form] = Form.useForm()

  const onSubmit = (data) => {
    setsaving(true)
    makeRequestStateless(downloadSampleLogByDateReq, data.date.format('YYYY-MM-DD'), onSuccessDownloadSampleByDate, onSuccessDownloadSampleByDate);
  }

  const onSuccessDownloadSampleByDate = (res, err) => {
    setsaving(false)
    if (res.code === undefined) {
      // Extract file extension
      const fileExtension = res.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ? 'xlsx' : 'pdf'
      const fileName = `day_sample_attendance_import_sheet.${fileExtension}`
      handleDownloadFile(fileName, res)
    }
    else {
      message.error(res.message)
    }
  }
  //-----------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------
  const footer = [
    <SaveButton loading={ saving } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];
  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------
  return (
    <ModalComponent top={ 20 } width={ 600 } maskClosable={ false }
      mainTitle={ trans(`${langKey}.daySample`) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>

      <Form { ...formLayout } form={ form } name={ formName } onFinish={ onSubmit } scrollToFirstError>

        <Form.Item name={ 'date' }
          rules={ rules.date }
          className="da-mb-16"
          label={ trans(`${langKey}.date`) }
        >
          <InputDate
          />
        </Form.Item>
      </Form>

    </ModalComponent>
  )
}

export default DownloadSampleLogByDate

const rules = {
  date: [
    { required: true, message: trans(`${langKey}.date`) },
  ]
};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}

