import React, { useEffect, useState } from "react";
import { Button, Checkbox, Form, message, Row, Col } from "antd";
import { SaveButton, InputText, InputTextArea, CancelButton, ModalComponent, trans, InputDate } from "@comps/components"
import { makeRequest, makeRequestStateless, getErrorProps } from "@utils/helpers";
import { createDataReq, getRelatedDataReq } from "../requests";
import { Commons, Const } from "../../constants";
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';

import CreateHolidayRule from "./CreateHolidayRule";
import UpdateHolidayRule from "./UpdateHolidayRule";
import moment from "moment/moment";
import dayjs from "dayjs";
const langKey = Const["holiday"].lngKey
const formName = "createHoliday";

const CreateHoliday = (props) => {
  const [loader, setLoader] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [errors, setErrors] = useState([]);

  const [holidayRules, setRules] = useState([])
  const [childComponent, setChildComponent] = useState(null)
  const [relatedData, setRelatedData] = useState(null)
  const [form] = Form.useForm()
  useEffect(() => {
    getRelated();
    // eslint-disable-next-line
  }, []);

  const getRelated = async () => {
    await makeRequestStateless(getRelatedDataReq, null, ongetRelatedDataReq, null);
  }
  const ongetRelatedDataReq = (response) => {
    setRelatedData(response)
  }

  // ==================================holiday_rules actions =================================
  const onCreate = () => {
    setChildComponent(<CreateHolidayRule onCompleted={ onCompleted } allData={ props.allData } relatedData={ relatedData } />)
  }

  //edit slab
  const onEdit = (key) => {
    const record = form.getFieldValue(`holiday_rules`)[key];
    setChildComponent(<UpdateHolidayRule onCompleted={ onCompleted } allData={ props.allData } relatedData={ relatedData } record={ { ...record, key: key } } disabled={ props.disabled } />)
  };

  //on submit holiday_rules
  const onCompleted = (data, action) => {
    let listArray = form.getFieldValue('holiday_rules');
    if (listArray === undefined) {
      listArray = []
    }
    if (data && action === 'Added') {

      listArray.push(data)
      form.setFieldValue('holiday_rules', listArray)
      setRules(listArray)
    }
    if (data && action === 'Updated') {

      listArray[data.key] = data
      form.setFieldValue('holiday_rules', listArray)
      setRules(listArray)
    }
    setChildComponent(null)
  }


  const onSubmit = (data) => {
    data.start=dayjs(data.start).format('YYYY-MM-DD')
    data.end=dayjs(data.end).format('YYYY-MM-DD')
    const payload = { holiday: data };
    makeRequest(setLoader, createDataReq, payload, onSuccess, onError);
    setSaveLoader(true)
  }

  const onSuccess = (data, res) => {
    setSaveLoader(false)
    message.success("Saved successfully");
    props.onCreated(data.holidays.data);
    props.onSuccess(data)
  }

  const onError = (err, res) => {
    setSaveLoader(false)
    message.error(res.response.data.message);
  }

  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [
    <SaveButton loading={ saveLoader } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCreated(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <>
      <ModalComponent top={ 20 } width={ 1000 } maskClosable={ false }
        mainTitle={ trans(Commons.create) } subTitle={ trans(`${langKey}.moduleName`) }
        visible={ true } footer={ footer } onCancel={ () => props.onCreated(false) }>
        { childComponent }
        <Form { ...formLayout } name={ formName } onFinish={ onSubmit } form={ form } scrollToFirstError>
          <Row gutter={ 30 }>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="name" rules={ rules.name } label={ trans(Commons.name) }
                className="da-mb-16"
                { ...getErrorProps(errors['name']) } size="small"
              >
                <InputText placeholder={ trans(`${langKey}.name`) } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="start" rules={ rules.start } label={ trans(`${langKey}.start`) }
                className="da-mb-16"
                { ...getErrorProps(errors['start']) } size="small"
              >
                <InputDate />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="end" rules={ rules.end } label={ trans(`${langKey}.end`) }
                className="da-mb-16"
                { ...getErrorProps(errors['end']) } size="small"
              >
                <InputDate />
              </Form.Item>
            </Col>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="description" rules={ rules.description } label={ trans(`${langKey}.description`) }
                className="da-mb-16"
                { ...getErrorProps(errors['description']) } size="small"
              >
                <InputTextArea placeholder={ trans(`${langKey}.description`) } />
              </Form.Item>
            </Col>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="is_attendance_exempt" rules={ rules.is_attendance_exempt } label={ trans(`${langKey}.is_attendance_exempt`) }
                className="da-mb-16"
                { ...getErrorProps(errors['is_attendance_exempt']) } size="small"
                valuePropName="checked"
              >
                <Checkbox />
              </Form.Item>
            </Col>
          </Row>
          <div className="formlist-container">
            <h5 className="formlist-title">Rules</h5>
            <Form.List name="holiday_rules">
              { (fields, { add, remove }) => (
                <>
                  <Button onClick={ () => {
                    onCreate()
                    //setRows(fields.length, 'Added')
                  } } icon={ <PlusOutlined /> } type="primary" className="add-node-button">Add </Button>
                  <div className="table-container">
                    <table className="table">
                      {
                        fields.length !== 0 && <thead>
                          <tr>
                            <th style={ { paddingLeft: 20 } }>{ trans(`${langKey}.name`) }</th>
                            <th>{ trans(`${langKey}.rule.type`) }</th>
                            <th>{ trans(Commons.actions) }</th>
                          </tr>
                        </thead>
                      }
                      <tbody>
                        { fields.map(({ key, name, ...restField }) => (
                          <>
                            <tr>
                              <td style={ { minWidth: '200px', paddingLeft: 20 } }>

                                <Form.Item
                                  { ...restField }
                                  name={ [name, 'name'] }
                                  rules={ rules.name }
                                >
                                  <InputText placeholder={ trans(`${langKey}.name`) } disabled />
                                </Form.Item>
                              </td>
                              <td style={ { minWidth: '200px' } }>

                                <Form.Item
                                  { ...restField }
                                  name={ [name, 'type'] }
                                  rules={ rules.type }

                                >
                                  <InputText placeholder={ trans(`${langKey}.rule.type`) } disabled />
                                </Form.Item>
                              </td>

                              <td>
                                <DeleteOutlined className="formlist-remove" onClick={ () => {
                                  remove(name)
                                } } />
                                < EditOutlined className="ms-2" onClick={ () => {
                                  onEdit(key)
                                } } />
                              </td>
                            </tr>
                          </>
                        )) }
                      </tbody>
                    </table>
                  </div>
                </>
              ) }
            </Form.List>
          </div>
        </Form>
      </ModalComponent>
    </>
  )
}

export default CreateHoliday

const rules = {
  name: [
    { required: true, message: trans(`${langKey}.name.req`) },
  ],
  type: [
    { required: true, message: trans(`${langKey}.rule.type.req`) },
  ],
  start: [
    { required: true, message: trans(`${langKey}.start.req`) },
  ],
  end: [
    { required: true, message: trans(`${langKey}.end.req`) },
  ],


};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}