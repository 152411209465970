import React, { useEffect, useState } from "react";
import { Form, Upload, Modal, message, Col, Row } from "antd";
import { SaveButton, CancelButton, ModalComponent, trans, SelectWithSearch, InputDate, InputText,UploadFile } from "@comps/components";
import { makeRequest, makeRequestStateless, getErrorProps, notify } from "@utils/helpers";
import { createSelectList, createFormattedCurrencies, getPlaceholder } from '@comps/commonFunctions';
import { getAllTimeZonesReq, getAllCurrenciesReq, createCompanyReq } from "../requests";
import { Const, Commons } from '../../constants';
import { PaperClipOutlined } from "@ant-design/icons";
import "../../Organization.css"
const formName = "createCompany";
const langKey = Const["companies"].lngKey
const CreateCompany = (props) => {
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [timezone, setTimezones] = useState([]);
  const [currency, setCurrencies] = useState([]);
  const [fiscalStartYear, setfiscalStartYear] = useState()
  const [fiscalEndYear, setfiscalEndYear] = useState()
  const [form] = Form.useForm()
  useEffect(() => {
    getRemoteData();
    // eslint-disable-next-line
  }, []);

  const getRemoteData = () => {
    makeRequestStateless(getAllTimeZonesReq, false, onTimeZonesSuccess, onTimeZonesError)
    makeRequestStateless(getAllCurrenciesReq, false, onCurrenciesSuccess, onCurrenciesError)
  }
  const onTimeZonesSuccess = (data, res) => {
    setTimezones(createSelectList(data))
  }
  const onTimeZonesError = (err, res) => {
    console.error(err)
  }
  const onCurrenciesSuccess = (data, res) => {
    const formatedCurrencies = createFormattedCurrencies(data)
    setCurrencies(formatedCurrencies)
  }
  const onCurrenciesError = (err, res) => {
    console.error(err)
  }

  const getFiscalStartDate=(fullDate,date)=>{
    // Set the date to the first day of the month
    let firstDateMonth=fullDate.date(date);
    return firstDateMonth.format('YYYY-MM-DD');
  }
  const getFiscalEndDate=(fullDate)=>{
    // Set the date to the last day of the month
    const lastDayOfMonth = fullDate.endOf('month');
    return lastDayOfMonth.format('YYYY-MM-DD');
  }

  const onSubmit = (data) => {

    data.file_attachment = fileList.length > 0 && fileList[0].id === undefined ? fileList[0].originFileObj : null
    data.start_year = fiscalStartYear
    data.end_year = fiscalEndYear
 let startDate=getFiscalStartDate(data.fiscal_start,1);
    let endDate=getFiscalEndDate(data.fiscal_end);

    let payload = {
      name: data.name,
      short_name: data.short_name,
      timezone_id: data.time_zone,
      currency_id: data.currency,
      fiscal_start:startDate,
      fiscal_end: endDate,
      is_active: true,
      file_attachment: data.file_attachment,
      address: data.address
    }
    makeRequest(setLoader, createCompanyReq, payload, onSuccess, onError);
  }
  const handleFiscalYearStart = (date) => {
    if (date) {
      setfiscalStartYear(date)
      setfiscalEndYear(date.add(1, 'y').subtract(1, 'months'))
      form.setFieldValue('fiscal_end', date.add(1, 'y').subtract(1, 'months'))
    }
  }

  const onSuccess = (data, res) => {
    setLoader(true)
    notify("Company Created", res.msg);
    props.onCreated(data.companies.data);
  }

  const onError = (err, res) => {
    message.error(res.response.data.message);
    //setErrors(errorList);
  }
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------
  const footer = [
    <SaveButton disabled={ loader } form={ formName } key="create_button" htmlType="submit" state={ loader } />,
    <CancelButton key="close_button" onClick={ () => props.onCreated(false) } />
  ];
  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  //============uploading file============
  const [fileList, setFileList] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const handleCancel = () => setPreviewOpen(false);
  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };
  const onPreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    //setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };
  //=========================================
  //------------------------------------
  return (
    <ModalComponent width={ 1000 } top={ 20 } maskClosable={ false }
      mainTitle={ trans(Commons.create) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } onCancel={ () => props.onCreated(false) } footer={ footer }>
      <Form
        form={ form }
        layout="vertical"
        name={ formName }
        onFinish={ onSubmit }
        scrollToFirstError
      >
        <Row gutter={ 30 }>
          <Col lg={ 12 } sm={ 24 } xs={ 24 } >
            <Form.Item name="name" rules={ rules.name } label={ trans(`${langKey}.name`) } className="da-mb-16"
              { ...getErrorProps(errors['name']) } size="small"
            >
              <InputText placeholder={ trans(`${langKey}.name`) } />
            </Form.Item>

            <Form.Item name="time_zone" label={ trans(`${langKey}.timezone.select`) } rules={ rules.time_zone } className="da-mb-16"
              { ...getErrorProps(errors['time_zone']) }
            >
              <SelectWithSearch
                className="da-mb-16"
                placeholder={ trans(`${langKey}.timezone.select`) }
                options={ timezone } />
            </Form.Item>
            <Form.Item name='fiscal_start' label={ trans(`${langKey}.fiscalStart`) } rules={ rules.start_year }
              { ...getErrorProps(errors['fiscalStart']) }
            >
              <InputDate

                onChange={ handleFiscalYearStart }
                picker="month" format="MMM-YYYY"
                rules={ rules.start_year }
              />
            </Form.Item>

            <Form.Item name="address" rules={ rules.complete_address } label={ trans(`${langKey}.address`) }
              className="da-mb-16"
              { ...getErrorProps(errors['name']) } size="small"

            >
              <InputText placeholder={ trans(`${langKey}.address`) } />
            </Form.Item >
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="short_name" rules={ rules.short_name } label=" Short Name" className="da-mb-16"
              { ...getErrorProps(errors['short_name']) }
            >
              <InputText placeholder={ trans(`${langKey}.short_name`) } />
            </Form.Item>

            <Form.Item name="currency" label={ trans(`${langKey}.currency.select`) } rules={ rules.currency } className="da-mb-16"
              { ...getErrorProps(errors['currency']) }
            >
              <SelectWithSearch
                placeholder={ trans(`${langKey}.currency.select`) }
                options={ currency }
              />
            </Form.Item>

            <Form.Item name='fiscal_end' label={ trans(`${langKey}.fiscalEnd`) } rules={ rules.end_year }
              { ...getErrorProps(errors['fiscalEnd']) }
            >
              <InputDate

                picker="month" format='MMM-YYYY'
                value={ fiscalEndYear }
                disabled rules={ rules.end_year }
              />
            </Form.Item>

            <Form.Item label="Logo" name="file_attachment" >
            <UploadFile fileList={ fileList } setFileList={ setFileList } accept=".jpg,.png,.jpeg"/>
            </Form.Item>

          </Col >
        </Row >
        <Modal open={ previewOpen } title={ previewTitle } footer={ null } onCancel={ handleCancel }>
          <img
            alt="example"
            style={ {
              width: '100%',
            } }
            src={ previewImage }
          />
        </Modal>
      </Form >
    </ModalComponent >
  )
}
export default CreateCompany
const rules = {
  name: [
    {
      required: true, message: trans(`${langKey}.name.required`),
    },
  ],
  short_name: [
    { required: true, message: trans(`${langKey}.shorName.required`), },
  ],
  time_zone: [
    { required: true, message: trans(`${langKey}.timezone.required`) },
  ],
  currency: [
    { required: true, message: trans(`${langKey}.currency.required`), },
  ],
  start_year: [
    { required: true, message: trans(`${langKey}.fiscalStart.required`) },
  ],
  end_year: [
    { required: true, message: trans(`${langKey}.fiscalEnd.required`) },
  ],
};