import { get, del, post, put } from "@utils/axios";

const api = "employee";

export const getAllBanksReq = (payload) => {
  return get(`employee_banks?page=${payload.page}&filters=${payload.filters}`);
};

export const createBankReq = (payload) => {
  return post(`employee_banks?&filters=${payload.filters}`, payload);
};

export const updateBankReq = (payload) => {
  return put(`employee_banks/${payload.employee_bank.id}?&filters=${payload.filters}`, payload);
};

export const deleteBankReq = (id) => {
  return del(`employee_banks/${id}`);
};

//-----------on create
export const getAllEmployeesReq = () => {
  return get("resources/employee?page=1");
};

export const getRelatedDataReq = () => {
  return get(`employee_banks/get-related-data?`)
}

export const getEmployeesBankByIdReq = (id) => {
  return get(`employee_banks/employee_related_data/${id}`)
}
export const getFilterReq = (payload) => {
  return get(`filters?company_id=1&fields=page=1&filters=supporting_fields_|employees;preset_|employee_banks;`)
}
