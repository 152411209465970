import React, { useEffect, useState } from "react";
import { Form, message, Skeleton, Row, Col } from "antd";
import { SaveButton, InputTextArea, InputDate, SelectMultiWithSearch, CancelButton, ModalComponent, trans } from "@comps/components"
import { makeRequest, } from "@utils/helpers";
import { createDataReq } from "../requests";
import { Commons, Const } from "../../constants";
import { createSelectList } from '@comps/commonFunctions';
import dayjs from 'dayjs'
const langKey = Const["restrictRequest"].lngKey
const formName = "viewRestrictRequest";

const ViewRestrictRequest = (props) => {
  const [loader, setLoader] = useState(false);
  const [saving, setsaving] = useState(false);
  const [requests, setRequests] = useState([])
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm()
  useEffect(() => {
    getData()
    // eslint-disable-next-line
  }, []);

  const getData = () => {
    setLoading(false)
    setRequests(createSelectList(props.allData.requests))
    form.setFieldsValue({
      ...props.record,
      start_date: dayjs(props.record.start_date), end_date: dayjs(props.record.end_date)
    })
  }

  const onSubmit = (data) => {
    setsaving(true)
    const payload = { restrict_request: { ...data, company_id: 1, is_active: true, start_date: data.start_date.format('YYYY-MM-DD'), end_date: data.end_date.format('YYYY-MM-DD') } };
    makeRequest(setLoader, createDataReq, payload, onSuccess, onError);
  }

  const onSuccess = (data, res) => {
    message.success(res.message);
    props.onCompleted(data.restrict_requests.data);
  }

  const onError = (err, res) => {
    setsaving(false)
    message.error(res.response.data.message);
  }

  //-----------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [
    !props.disabled &&
    <SaveButton loading={ saving } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } width={ 1000 } maskClosable={ false }
      mainTitle={ trans(Commons.view) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) } >
      <Skeleton loading={ loading }>
        <Form { ...formLayout } name={ formName } onFinish={ onSubmit } form={ form } disabled={ props.disabled }>
          <Row gutter={ 30 }>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="start_date" rules={ rules.start_date } label={ trans(`${langKey}.start_date`) }
                className="da-mb-16"
              >
                <InputDate />
              </Form.Item>
            </Col>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="end_date" rules={ rules.end_date } label={ trans(`${langKey}.end_date`) }
                className="da-mb-16"
              >
                <InputDate />
              </Form.Item>
            </Col>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="request_id" rules={ rules.request_id } label={ trans(`${langKey}.request_id`) }
                className="da-mb-16"
              >
                <SelectMultiWithSearch
                  options={ requests }
                  placeholder={
                    trans(`${langKey}.request_id`)
                  } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="description" rules={ rules.description } label={ trans(Commons.description) }
                className="da-mb-16"
              >
                <InputTextArea placeholder={ trans(`${langKey}.description`) } />
              </Form.Item>
            </Col>
          </Row>

        </Form>
      </Skeleton>

    </ModalComponent>
  )
}

export default ViewRestrictRequest

const rules = {
  start_date: [
    { required: true, message: trans(`${langKey}.start_date`) },
  ],
  end_date: [
    { required: true, message: trans(`${langKey}.end_date`) },
  ],
  request_id: [
    { required: true, message: trans(`${langKey}.request_id`) },
  ],

};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}