import { get } from "@utils/axios";
import { isNaN, isString } from "mathjs";
import CryptoJS from 'crypto-js';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import Pusher from 'pusher-js';
import BaseProgressbar from "./others/BaseProgressbar";

dayjs.extend(utc);
dayjs.extend(timezone);

// Set the default timezone for your application


var actionPermissions = null
const getLabel = (element, extra) => {
  if (element.title !== undefined) {
    return element.title
  }
  if (element.value === true || element.value === false) {
    return element.name
  }
  if (extra.key === 'employees' || extra.key === 'employments') {
    return `${element.full_name_code === undefined ? element.full_name : element.full_name_code}`
  }
  if (element.name && element.profile_pic_url === undefined) {
    return element.name
  }
  if (element.full_name) {
    return element.full_name
  }
  if (element.label) {
    return element.label
  }
  if (element.full_name_code) {
    return element.full_name_code
  }
  if (element.profile_pic_url !== undefined) {
    return `${element.name} ${element.code ? '-' + element.code : ''}`
  }
}

const getValue = (element, extra) => {
  if (extra.filterKey === 'employee_id') {
    return element.employee_id
  }
  if (extra.filterKey) {
    return element[extra.filterKey]
  }
  if (element.id || element.id === 0) {
    return element.id
  }
  if (element.key) {
    return element.key
  }
  if (element.value === true || element.value === false) {
    return element.value.toString()
  }
}

export const createSelectList = (data, extra) => {

  let newList = []
  data.forEach(element => {
    let obj = { "value": getValue(element, extra !== undefined ? extra : { key: '', filterKey: '' }), "label": getLabel(element, extra !== undefined ? extra : { key: '', filterKey: '' }) };
    newList.push(obj);
  });
  return newList;
}

export const createSelectResourceList = (data) => {
  let newList = []
  for (const key in data) {
    let obj = { "value": `${data[key]}` };
    newList.push(obj);
  };
  return newList;
}

export const createSelectEmploymentList = (data, extra) => {
  let newList = []
  data.forEach(element => {
    let obj = { "value": getValue(element, extra !== undefined ? extra : { key: '', filterKey: '' }), "label": element.full_name_code ? element.full_name_code : element.title };
    newList.push(obj);
  });
  return newList;
}

export const createFormattedCurrencies = (data, extra) => {
  let newList = []
  data.forEach(element => {
    let obj = { "value": getValue(element, extra !== undefined ? extra : { key: '', filterKey: '' }), "label": element.name ? `${element.name}` : element.title };
    newList.push(obj);
  });
  return newList;
}

export const createRelationshipsList = (data) => {
  let newList = []
  data.forEach(element => {
    let obj = { "value": element.name, "label": element.name ? element.name : element.title };
    newList.push(obj);
  });
  return newList;
}

export const createCurrenciesListWithName = (data) => {
  let newList = []
  data.forEach(element => {
    let obj = { "value": element.id, "label": element.name ? `${element.name} (${element.intl_symbol}) - ${element.country}` : element.title };
    newList.push(obj);
  });
  return newList;
}
export const createDepartmentListWithCode = (data) => {
  let newList = []
  data.forEach(element => {
    let obj = { "value": element.hierarchy_code, "label": element.name ? element.name : element.title };
    newList.push(obj);
  });
  return newList;
}
export const createSelectScales = (data) => {
  let newList = []
  data.forEach(element => {
    let obj = { "value": getValue(element), "label": element.name ? element.name : element.title };
    newList.push(obj);
  });
  return newList;
}

export const convertMinutesToHours = (totalMinutes) => {
  let h = ''
  let m = ''
  if (isString(totalMinutes)) {
    totalMinutes = Number(totalMinutes)
  }
  let hours = Math.floor(totalMinutes / 60);
  if (isNaN(hours)) {
    hours = 0
    h = ''
  }
  else {
    h = hours + 'h'
  }

  let minutes = totalMinutes % 60;
  if (isNaN(minutes) || !minutes) {
    minutes = 0
    m = ''
  }
  else {
    m = minutes + 'm'
  }

  return `${h} ${m}`;
}

//resources
export const getResourceDataReq = (payload) => {
  return get(`resources/${payload.type}?page=${payload.page}&filters=${payload.filters};`);
};

export const createEmploymentResource = (data) => {
  let newList = []
  data.forEach(element => {
    let obj = { "value": element.id, "label": element.identity === undefined ? element.full_name_code : element.identity };
    newList.push(obj);
  });
  return newList;
}
export const createEmployeeResource = (data) => {
  let newList = []
  data.forEach(element => {
    let obj = { "value": element.employee_id, "label": element.identity === undefined ? element.full_name_code : element.identity };
    newList.push(obj);
  });
  return newList;
}
export const convertTimeTotz = (datetime) => {

  let userTimezone = localStorage.getItem('timezone');
  if (userTimezone === "undefined" || userTimezone === 'null' || !userTimezone || userTimezone === '[object Object]') {
    userTimezone = 'Asia/karachi';
  }
  return dayjs.utc(datetime).tz(userTimezone);
}

export const getApiBaseUrl = () => {
  // Get the base URL
  const baseUrl = window.location.origin
  var urlArray = baseUrl.split('.')
  urlArray[0] = urlArray[0] + '-api'
  const urlString = urlArray.join('.')
  return urlString + '/api/v1/';
}

export const getDomainUrl = () => {
  // Get the base URL
  if (process.env.REACT_APP_ENV === "local") {
    return 'http://localhost:3001'
  }

  return 'https://' + decryptLocalData('user').tenant + '.klokhr.com:3000'

}
const getPermissions = () => {
  return actionPermissions === null ? decryptLocalData('permissions') : actionPermissions;
}
export const checkPermissions = (modkey = null, action = null) => {
  actionPermissions = getPermissions()
  var modulekey = !modkey ? localStorage.getItem('modulekey') : modkey

  if (!action && modkey && actionPermissions[modulekey]) {
    return true
  }
  if (!modulekey) {
    return true;
  }
  if (modulekey.includes('custom_payitem')) {
    return true
  }
  if (actionPermissions !== null && actionPermissions !== undefined && actionPermissions[modulekey + '_' + action] !== undefined && actionPermissions[modulekey + '_' + action]) {
    return true
  }
  else {
    return false
  }
}


export const getErrorColumns = () => {
  return [
    {
      key: 'row',
      title: "Row",
      dataIndex: "row",
    },
    {
      key: 'column',
      title: "Column",
      dataIndex: "column",
    },
    {
      key: 'value',
      title: 'Value',
      dataIndex: "value",
    },
    {
      key: 'message',
      title: "Message",
      dataIndex: "message",
    },
  ]
}

//
export const getPlaceholder = (placeholder) => {
  const ph = placeholder;
  if (typeof ph === 'object' && ph.props !== undefined && ph.props.id !== undefined) {
    const lastString = ph.props.id.substring(ph.props.id.lastIndexOf('.') + 1);
    // Use a regular expression to insert space before capital letters
    const stringWithSpaces = lastString.replace(/([a-z])([A-Z])/g, '$1 $2');
    // Capitalize the first letter of the resulting string
    const capitilizeStr = stringWithSpaces.charAt(0).toUpperCase() + stringWithSpaces.slice(1);
    const res = capitilizeStr.replace('_id', ' ').replace('_', ' ')
    return res;
  }
  else {
    return ph
  }
}

export const encryptLocalData = (key, data) => {
  // Encrypt data and store in localStorage
  try {
    const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), 'secret-key');
    const encryptedData = encrypted.toString();
  
    localStorage.setItem(key, encryptedData);
  } catch (error) {
    console.error('Error while encrypting data:', error);
  }
 
}

export const decryptLocalData = (key) => {
  try {
    const encryptedData = localStorage.getItem(key) ? localStorage.getItem(key) : ''
    const bytes = CryptoJS.AES.decrypt(encryptedData, 'secret-key');
    const decryptedText = bytes.toString(CryptoJS.enc.Utf8);
    if (decryptedText) {
      return JSON.parse(decryptedText);
    }
    return decryptedText;
  } catch (error) {
    console.error('Error while decrypting data:', error);
    localStorage.clear();
    return null;
  }
};


//=======================pusher===============================



export const pusherInitializer = () => {

  const pusher = new Pusher("01664d4a0b939eb74816", {
    cluster: "ap2",
    encrypted: true,
  });
  // pusher.connection.bind('connected', () => {
  //   console.log('Connected to Pusher');
  // });

  // pusher.connection.bind('disconnected', () => {
  //   console.log('Disconnected from Pusher');
  // });

  // pusher.connection.bind('error', (err) => {
  //   console.error('Pusher connection error:', err);
  // });

  return pusher;
}

//show progress 
export const ProgressComponent = BaseProgressbar

export const lightenColor = (hex, percent) => {
  // Remove the hash at the start if it's there
  hex = hex.replace(/^#/, '');

  // Parse the r, g, b values
  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);

  // Calculate the new r, g, b values
  r = Math.min(255, Math.floor(r + (255 - r) * (percent / 100)));
  g = Math.min(255, Math.floor(g + (255 - g) * (percent / 100)));
  b = Math.min(255, Math.floor(b + (255 - b) * (percent / 100)));

  // Convert back to hex and return
  return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
}