
import { Layout, Skeleton, theme } from 'antd';
import ModuleRoutes from '../modules/routes';
import TopHeader from './components/header/TopHeader';
import NavSide from './components/sider/NavSide';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const { Content } = Layout;

const Layouts = () => {
  const [currentKey, setCurrentKey] = useState(null)
  const location = useLocation();
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    if (location) {
      setCurrentKey(location.pathname)
    }
    // eslint-disable-next-line
  }, [location]);

  const {
    token: { colorBgContainer },
  } = theme.useToken();
  return (
    <Skeleton loading={ loading }>
      <Layout style={ {  } }>
        <NavSide setLoading={ setLoading } />
        <Layout style={ { height: '98vh', } }>
          <TopHeader />
          <Content className='maincontent'>
            <ModuleRoutes />
          </Content>
        </Layout>
      </Layout>
    </Skeleton>

  );
};

export default Layouts;