import React, { useEffect, useState } from "react";
import { Form, Input, message } from "antd";
import { SaveButton, InputText, CancelButton, ModalComponent, trans } from "@comps/components"
import { makeRequest, getErrorProps } from "@utils/helpers";
import { updateJobTitleReq } from "../requests";
import { Commons, Const } from "../../constants";

const langKey = Const["jobTitle"].lngKey
const formName = "updateJobTitle";

const UpdateJobTitle = (props) => {
  const [form] = Form.useForm()
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [saving, setsaving] = useState(false);

  useEffect(() => {
    getAndSetRecord();
    // eslint-disable-next-line
  }, []);

  const getAndSetRecord = () => {
    if (!props.record)
      return
    form.setFieldsValue({
      "name": props.record.name,
    })
  }


  const onSubmit = (data) => {
    setsaving(true)
    if (!props.record) {
      return
    }
    const payload = { name: data.name, id: props.record.id, code: props.record.code }
    makeRequest(setLoader, updateJobTitleReq, payload, onSuccess, onError);
  }

  const onSuccess = (data, res) => {
    message.success("JobTitle Updated successfully");
    props.onCompleted(data.job_titles.data);
  }

  const onError = (err, res) => {
    setsaving(false)
    message.error(res.response.data.message);
    //setErrors(errorList);
  }

  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [
    <SaveButton disabled={ loader } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } maskClosable={ false }
      mainTitle={ trans(Commons.update) } subTitle={ trans(`${langKey}.JobTitle`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>

      <Form { ...formLayout } form={ form } name={ formName } onFinish={ onSubmit }>
        <Form.Item name="name" rules={ rules.name } label={ trans(`${langKey}.name`) }
          className="da-mb-16"
          { ...getErrorProps(errors['name']) } size="small"
        >
          <InputText placeholder={ trans(`${langKey}.name`) } />
        </Form.Item>
      </Form>
    </ModalComponent>
  )
}

export default UpdateJobTitle

const rules = {
  name: [
    { required: true, message: trans(`${langKey}.name.required`) },
  ],
  description: [
    { required: true, message: trans(`${langKey}.description.required`) },
  ]
};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  // wrapperCol: { span: 14, offset: 2 },
  labelAlign: "left"
}