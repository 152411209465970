import React, { useEffect, useState } from "react";
import { message, Skeleton, Table } from "antd";
import {
  CancelButton, ModalComponent, trans, BodyComponent,
} from "@comps/components"
import { makeRequestStateless } from "@utils/helpers";
import { getKpiEvaluationRelatedReq } from "../../requests";
import { Commons, Const } from "../../../constants";

const langKey = Const["kpi"].lngKey

const ViewKpiEvaluation = (props) => {

  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(true);

  const [totalRecords, setTotalRecords] = useState(0);
  const [dataSource, setDataSource] = useState([]);




  const [pagination, setPagination] = useState({
    "total": 0,
    "per_page": 1,
    "total_pages": 0,
  });

  useEffect(() => {
    getData()

    // eslint-disable-next-line
  }, []);

  const getData = () => {
    getRelatedData()
  }
  const getRelatedData = () => {
    makeRequestStateless(getKpiEvaluationRelatedReq, props.record.id, onGetRelated, onError)
  }
  const onGetRelated = (response, data) => {
    setLoading(false)
    if (response && response.length !== undefined) {
      setDataSource(response);
      setTotalRecords(response.length)
    }
  }

  const onError = (err, res) => {
    setLoading(false)
    message.error(res.response.data.message);
  }

  const columns = [
    {
      key: 'kpi_task_name',
      title: trans(`${langKey}.kpi_task_name`),
      render: (record) => (
        record.kpi_task_name
      )
    },
    {
      key: 'kpi_task_type',
      title: trans(`${langKey}.kpi_task_type`),
      render: (record) => (
        record.kpi_task_type
      )
    },
    {
      key: 'kpi_task_value',
      title: trans(`${langKey}.kpi_task_value`),
      render: (record) => (
        record.kpi_task_value
      )
    },
    {
      key: 'remarks',
      title: trans(`${langKey}.remarks`),
      render: (record) => (
        record.remarks
      )
    },
    {
      key: 'status',
      title: trans(`${langKey}.status`),
      render: (record) => (
        record.status && record.status === 'Completed' ? <span className="text-success">{ 'Completed' }</span> : <span className="text-danger">Pending</span>
      )
    },
  ]

  const handleTableChange = (page, fil, sorter) => {
    let payload = {
      ...pagination,
      current: page.current,
      pageSize: page.pageSize,
      sortName: sorter.field || "id",
      sortType: sorter.order === "ascend" ? "asc" : "desc",
    };
    setPagination(payload);
  };
  //-----------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------
  const footer = [
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];
  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } width={ 1200 } maskClosable={ false }
      mainTitle={ trans(Commons.view) } subTitle={ trans(`${langKey}.task_evaluation`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) } >
      <Skeleton loading={ loading }>
        <BodyComponent>

          <Table
            loader={ loader }
            columns={ columns }
            dataSource={ dataSource }
            onChange={ handleTableChange }
            pagination={ { ...pagination, total: totalRecords } }
          />
        </BodyComponent>
      </Skeleton>

    </ModalComponent>
  )
}

export default ViewKpiEvaluation
