import { get, del, postFd, put, putFd } from "@utils/axios";

const api = "departments";
const apiIdentifiers = "common/getIdentifiers";
export const getAllDepartments = (payload) => {
  return get(`${api}?page=${payload.page}&filters=${payload.filters}`)
};

export const getHodsByCompanyIdReq = (payload) => {
  return postFd(`${api}/getHODs`, payload);
};

export const createDepartmentReq = (payload) => {
  return postFd(`${api}?`, payload);
};

export const updateDepartmentReq = (payload) => {
  return put(`${api}/${payload.id}`, payload);
};

export const deleteDepartment = (id) => {
  return del(`${api}/${id}`);
};

export const getIdentifierReq = () => {
  return get(apiIdentifiers)
}

export const getCompanyReq = () => {
  return get('common/getCompany')
}
export const getFilterReq = (payload) => {
  return get(`resources/filter-resources/${payload}`)
}
