import React, { useEffect, useState } from "react";
import { Form, message, Row, Col } from "antd";
import { SaveButton, InputText, InputTextArea, InputCheck, CancelButton, ButtonComponent, ModalComponent, SelectWithSearch, trans } from "@comps/components"
import { makeRequest } from "@utils/helpers";
import { updateDataReq } from "../requests";
import { Commons, Const } from "../../../constants";
import { PlusCircleOutlined } from "@ant-design/icons";
import CreateExpression from "../../formula/components/CreateExpression";
import { createSelectList } from '@comps/commonFunctions';

const langKey = Const["payitem"].lngKey
const formName = "createPayitem";

const UpdatePayitem = (props) => {
  const [loader, setLoader] = useState(false);
  const [saving, setsaving] = useState(false);
  const [childComponent, setChildComponent] = useState(false);
  const [form] = Form.useForm()
  const [expression, setExpression] = useState('');
  const [isValid, setIsValid] = useState(true)
  const [hasRule, setHasRule] = useState(false)
  const [calculationType, setCalculationType] = useState()
  const [hasEmloyerContribution, setHasEmloyerContribution] = useState(false)
  const [payitemType, setType] = useState()
  const [payitemTypes, setPayitemTypes] = useState([])
  const [formulas, setFormulas] = useState([])
  const [calculationTypes, setCalculationTypes] = useState([])

  useEffect(() => {
    setData()
    // eslint-disable-next-line
  }, []);


  const setData = () => {
    if (props.allData) {
      setPayitemTypes(createSelectList(props.allData.payitem_types))
      setCalculationTypes(createSelectList(props.allData.calculation_types))

      setFormulas(createSelectList(props.allData.formulas))

      //set form data
      form.setFieldsValue(props.record)
      setHasRule(props.record.has_rule)
      setCalculationType(props.record.calculation_type)
      setHasEmloyerContribution(props.record.has_employer_contribution)
      setType(props.record.type)
      setExpression(props.record.rule_expression)
    }

  }
  const onSubmit = (data) => {
    if ((expression === '' || expression === null) && hasRule) {
      message.error('Please enter a valid expression')
      return
    }

    const payload = {
      id: props.record.id,

      payitem: {
        ...data,
        employee_contribution_id: data.employee_contribution_id,
        employer_contribution_id: null, //temp hardcode
        has_employer_contribution: false, //temp hardcode
        id: props.record.id,
        rule_expression: expression,
      }
    };
    setsaving(true)
    makeRequest(setLoader, updateDataReq, payload, onSuccess, onError);
  }

  const onSuccess = (data, res) => {

    message.success(res.message);
    props.onCompleted(data.payitems.data);
  }

  const onError = (err, res) => {

    setsaving(false)
    message.error(res.response.data.message);
  }

  const onCreateExpression = () => {
    const options = {
      exclude: null,
      payitems: null,
      formulas: null,
      rules: true
    }
    setChildComponent(<CreateExpression onCompleted={ onCompleted } record={ expression } isValid={ isValid } options={ options } />)
  }

  const onCompleted = (data, isValid) => {
    if (data) {
      setExpression(data)
      setIsValid(isValid)
    }

    setChildComponent(null)
  }
  //-----------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [
    props.disabled ? '' :
      <SaveButton loading={ saving } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } width={ 1000 } maskClosable={ false }
      mainTitle={ trans(Commons[props.disabled ? 'view' : 'update']) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      { childComponent }
      <Form { ...formLayout } name={ formName } onFinish={ onSubmit } form={ form } disabled={ props.disabled } >
        <Row gutter={ 30 }>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="name" rules={ rules.name } label={ trans(Commons.name) }
              className="da-mb-16"
            >
              <InputText placeholder={ trans(`${langKey}.name`) } disabled={ true } />
            </Form.Item>
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="type" rules={ rules.type } label={ trans(`${langKey}.type`) }
              className="da-mb-16"
            >
              <SelectWithSearch options={ payitemTypes } placeholder={ trans(`${langKey}.type`) } onChange={ (value) => setType(value) } />
            </Form.Item>
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="calculation_type" rules={ rules.calculation_type } label={ trans(`${langKey}.calculation_type`) }
              className="da-mb-16"
            >
              <SelectWithSearch options={ calculationTypes } placeholder={ trans(`${langKey}.calculation_type`) } onChange={ (value) => setCalculationType(value) } />
            </Form.Item>
          </Col>
          {/* {
            calculationType !== 'manual_entry' &&
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="has_employer_contribution" rules={ rules.has_employer_contribution } label={ trans(`${langKey}.has_employer_contribution`) }
                className="da-mb-16"
                valuePropName="checked"
              >
                <InputCheck onChange={ (e) => setHasEmloyerContribution(e.target.checked) } />
              </Form.Item>
            </Col>
          } */}

          {/* {
            hasEmloyerContribution && calculationType === 'fixed' &&
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="employer_contribution_id" rules={ rules.employer_contribution_id } label={ trans(`${langKey}.employer_contribution_id`) }
                className="da-mb-16"
              >
                <SelectWithSearch options={ formulas } placeholder={ trans(`${langKey}.employer_contribution_id`) } />
              </Form.Item>
            </Col>
          } */}
          {
            calculationType === 'fixed' && <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="employee_contribution_id" rules={ rules.employee_contribution_id } label={ trans(`${langKey}.employee_contribution_id`) }
                className="da-mb-16"
              >
                <SelectWithSearch options={ formulas } placeholder={ trans(`${langKey}.employee_contribution_id`) } />
              </Form.Item>
            </Col>
          }
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="is_benefit" rules={ rules.is_benefit } label={ trans(`${langKey}.is_benefit`) }
              className="da-mb-16"
              valuePropName="checked"
            >
              <InputCheck />
            </Form.Item>
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="has_rule" rules={ rules.has_rule } label={ trans(`${langKey}.has_rule`) }
              className="da-mb-16"
              valuePropName="checked"
            >
              <InputCheck onChange={ (e) => setHasRule(e.target.checked) } />
            </Form.Item>
          </Col>

          {
            hasRule &&
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item style={ { paddingRight: 35 } } name="rule_expression" label={ trans(`${langKey}.rule_expression`) }
                className="da-mb-16"
              >
                <InputTextArea placeholder={ trans(`${langKey}.rule_expression`) } disabled={ true } value={ expression } /><ButtonComponent className="formula-btn" onClick={ onCreateExpression } icon={ <PlusCircleOutlined style={ { marginLeft: -7 } } /> } />
              </Form.Item>
            </Col>
          }
        </Row>

      </Form>
    </ModalComponent>
  )
}

export default UpdatePayitem

const rules = {
  name: [
    { required: true, message: trans(`${langKey}.name`) },
  ],
  employer_contribution_id: [
    { required: true, message: trans(`${langKey}.employer_contribution_id`) },
  ],
  type: [
    { required: true, message: trans(`${langKey}.type`) },
  ],
  calculation_type: [
    { required: true, message: trans(`${langKey}.calculation_type`) },
  ],
};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}