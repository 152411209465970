import React, { useEffect, useState } from "react";
import { Divider, Form, message, Skeleton } from "antd";
import { SaveButton, CancelButton, ModalComponent, trans, InputTime, InputDate, InputTextArea } from "@comps/components"
import { makeRequest, getErrorProps } from "@utils/helpers";
import { cancelRequestReq, getRosterReq, updateDataReq } from "../requests";
import { Commons, Const } from "../../../constants";
import { useSelector } from "react-redux";
import RoasterView from "@mods/common/RoasterView";
import moment from "moment/moment";
import dayjs from 'dayjs';

const langKey = Const["relaxationRequest"].lngKey
const formName = "createRelaxationRequest";
//const moment = require('moment')
const ViewRelaxationRequest = (props) => {
  const authUser = useSelector((state) => state.auth.authUser);
  const [loader, setLoader] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [roasterView, setRoasterView] = useState(null)
  const [shifts, setShifts] = useState({})
  const [preEdit, setPreEdit] = useState(true)
  const [formData, setFormData] = useState({})
  const [loading, setloading] = useState(true)
  const [form] = Form.useForm()
  useEffect(() => {
    setData();
    // eslint-disable-next-line
  }, []);
  const setData = () => {
    let date = props.record.date ? dayjs(props.record.date) : null
    getRoster(date)
    props.record.date = date
    props.record.start_time = props.record.start_time ? dayjs(props.record.start_time) : null
    props.record.end_time = props.record.end_time ? dayjs(props.record.end_time) : null
    form.setFieldsValue(props.record);
  }
  const onChangeDate = (date) => {
    setPreEdit(false)
    if (date) {
      getRoster(date)
    }
  }
  const getRoster = (date) => {
    const payload = { relaxation: { employment_id: authUser.employment_id, date: date.format('YYYY-MM-DD') } }
    makeRequest(setLoader, getRosterReq, payload, onGetRosterSuccess, onGetRosterSuccess);
  }

  const onGetRosterSuccess = (response, data) => {
    setloading(false)
    if (data.shifts) {
      setRoasterView(<RoasterView record={ data } />)
      setShifts(data.shifts[moment(data.from_date).format('YYYYMMDD') + '-' + data.employments[0].id][0]);
      let start_time = data.shifts[moment(data.from_date).format('YYYYMMDD') + '-' + data.employments[0].id][0]['start']
      let end_time = data.shifts[moment(data.from_date).format('YYYYMMDD') + '-' + data.employments[0].id][0]['end']

      if (preEdit === false) {
        form.setFieldsValue({
          start_time: dayjs(start_time),
          end_time: dayjs(end_time),
        })
      }
    }
    else {
      message.error('No Shift Found For Given Date!')
      return
    }
  }
  const onSubmit = (data) => {
    setSaveLoader(true)
    if (props.action === 'edit') {
      const payload = { relaxation: { shift: shifts, id: props.record.id, employment_id: shifts.employment_id, date: data.date.format('YYYY-MM-DD'), start_time: data.start_time.format('hh:mm a'), end_time: data.end_time.format('hh:mm a'), reason: data.reason, employee_shift_id: shifts.id } };
      payload.id = props.record.id;
      makeRequest(setLoader, updateDataReq, payload, onSuccess, onError);
    }
    if (props.action === 'cancel') {
      const payload = { cancel_request: { employment_id: props.record.employment_id, requestable_class: 'relaxation_request', requestable_id: props.record.id, description: data.description } }
      makeRequest(setLoader, cancelRequestReq, payload, onSuccess, onError);
    }

  }
  const onSuccess = (data, res) => {
    setSaveLoader(false)
    message.success("Saved successfully");
    props.onCompleted(data.relaxations.data);
  }

  const onError = (err, res) => {
    setSaveLoader(false)
    message.error(res.response.data.message);
  }

  //-----------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [
    !props.disabled &&
    <SaveButton loading={ saveLoader } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } width={ 1000 } maskClosable={ false }
      mainTitle={ trans(Commons[props.disabled ? 'view' : 'update']) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Skeleton loading={ loading }>
        <Form { ...formLayout } form={ form } name={ formName } onFinish={ onSubmit } initialValues={ formData } disabled={ props.disabled } scrollToFirstError>

          <Form.Item name="date" rules={ rules.date } label={ trans(`${langKey}.date`) }
            className="da-mb-16"
            { ...getErrorProps(errors['date']) } size="small"
          >
            <InputDate onChange={ (date) => onChangeDate(date) } />
          </Form.Item>
          { roasterView }
          <Form.Item name="start_time" rules={ rules.start_time } label={ trans(`${langKey}.start_time`) }
            className="da-mb-16"
            { ...getErrorProps(errors['start_time']) } size="small"
          >
            <InputTime placeholder={ trans(`${langKey}.start_time`) } />
          </Form.Item>
          <Form.Item name="end_time" rules={ rules.end_time } label={ trans(`${langKey}.end_time`) }
            className="da-mb-16"
            { ...getErrorProps(errors['end_time']) } size="small"
          >
            <InputTime placeholder={ trans(`${langKey}.end_time`) } />
          </Form.Item>

          <Form.Item name="reason" rules={ rules.reason } label={ trans(`${langKey}.reason`) }
            className="da-mb-16"
            { ...getErrorProps(errors['reason']) } size="small"
          >
            <InputTextArea placeholder={ trans(`${langKey}.reason`) } />
          </Form.Item>

          { props.action === 'cancel' ?
            <>
              <Divider>Cancelation</Divider>
              <Form.Item name="description" rules={ rules.reason } label={ trans(`${langKey}.reason`) }
                className="da-mb-16"
                { ...getErrorProps(errors['reason']) } size="small"
              >
                <InputTextArea placeholder={ trans(`${langKey}.reason`) } />
              </Form.Item>
            </>
            : ''
          }
        </Form>
      </Skeleton>
    </ModalComponent>
  )
}

export default ViewRelaxationRequest

const rules = {
  date: [
    { required: true, message: trans(`${langKey}.date`) },
  ],
  start_time: [
    { required: true, message: trans(`${langKey}.start_time`) },
  ],
  end_time: [
    { required: true, message: trans(`${langKey}.end_time`) },
  ],
  reason: [
    { required: true, message: trans(`${langKey}.reason`) },
  ],
};

const formLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14, offset: 2 },
  labelAlign: "left"
}