
import React, { useEffect, useState } from "react";
import { Button, Form, Checkbox, message, Row, Col, Skeleton } from "antd";
import { SaveButton, trans, SelectWithSearch, InputText, InputDate, AddNewButton } from "@comps/components";
import { makeRequest, makeRequestStateless, getErrorProps } from "@utils/helpers";
import { createSelectList, checkPermissions } from '@comps/commonFunctions';
import { getCompanyRelatedDataReq, getBranchsByLocationIdReq, getEmploymentByIdReq, updateEmploymentReq } from "../requests";
import { Const, } from '../../constants';
import dayjs from 'dayjs';

import { EditOutlined } from '@ant-design/icons';
import CreateGrade from "../../../organizationManagement/grades/components/CreateGrade";
import CreateLocation from '../../../organizationManagement/locations/components/CreateLocation';
import CreateDepartment from '../../../organizationManagement/departments/components/CreateDepartment';
import CreateBranch from '../../../organizationManagement/branches/components/CreateBranch';
import CreateDesignation from '../../../organizationManagement/designations/components/CreateDesignation';
import CreateJobTitle from '../../../organizationManagement/jobTitles/components/CreateJobTitle';

const langKey = Const["employee"].lngKey
const formName = "updateEmployment";
const UpdateEmploymentInfo = (props) => {

  const [editMode, setEditMode] = useState(false);
  const handleEditClick = () => {
    setEditMode(!editMode);
  };

  const handleSaveClick = () => {
    // Handle the save action here, e.g., submit the form data
    // You can access the edited data in formData
    // Implement your save logic here

    // After saving, you may want to exit edit mode
    setEditMode(false);
  };



  const [form] = Form.useForm()
  const [loader, setLoader] = useState(false);
  const [isFinalAuthority, setIsFinalAuthority] = useState()
  const [isProbation, setIsProbation] = useState()
  const [errors, setErrors] = useState([]);

  const [employeeTypes, setEmployeeTypes] = useState([]);
  const [employmentTypes, setEmploymentTypes] = useState([]);

  const [designations, setDesignations] = useState([]);
  const [locations, setLocations] = useState([]);
  const [branches, setBranches] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [grades, setGrades] = useState([]);
  const [lineManagers, setLineManagers] = useState([]);
  const [finalAuthorities, setFinalAuthorities] = useState([]);
  const [jobTitles, setJobTitles] = useState([]);
  const [roles, setRoles] = useState([]);
  const [isEmploymentType, setIsEmploymentType] = useState();
  const [childComponent, setChildComponent] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getEmploymentById(props.id)
    getCompanyRelatedData();
    // eslint-disable-next-line
  }, [props]);

  //select

  const getEmploymentById = (id) => {
    makeRequest(setLoader, getEmploymentByIdReq, id, onGetEmploymentSuccess, onGetEmploymentSuccess);
  }
  const onGetEmploymentSuccess = (response) => {
    setLoading(false)
    setIsFinalAuthority(response.is_final_authority);
    makeRequestStateless(getBranchsByLocationIdReq, response.location_id, getBranchsByLocationId, null)
    response.joining_date = dayjs(response.joining_date)
    setIsEmploymentType(response.contracttype_id)
    response.contract_expiry_date = dayjs(response.contract_expiry_date)

    form.setFieldsValue(response);
  }
  const getBranchsByLocationId = (response) => {
    setBranches(createSelectList(response))
  }

  const getCompanyRelatedData = () => {
    makeRequestStateless(getCompanyRelatedDataReq, false, onCompanyRelatedDataSuccess, onCompanyRelatedDataError);
  }

  const onCompanyRelatedDataSuccess = (data, res) => {
    setLoading(false)
    setGrades(createSelectList(data.grades));
    setLocations(createSelectList(data.locations));
    setDepartments(createSelectList(data.departments));
    setDesignations(createSelectList(data.designations));
    setLineManagers(createSelectList(data.line_managers));
    setFinalAuthorities(createSelectList(data.final_authorities));
    setRoles(createSelectList(data.roles));
    setEmployeeTypes(createSelectList(data.employee_types))
    setEmploymentTypes(createSelectList(data.contract_types))
    setJobTitles(createSelectList(data.job_titles))
  }
  const onCompanyRelatedDataError = (error, res) => {

  }
  const onChangeProbation = (e) => {
    setIsProbation(e.target.checked);
  }

  //------------on submit forms----------------

  const onSubmitSecondStep = (data) => {
    if (isFinalAuthority) {
      data.is_hod = isFinalAuthority;
      data.is_manager = isFinalAuthority;
    }
    data.joining_date = data.joining_date.format('YYYY-MM-DD');
    setsecondStepData(data)
    let payload = { employee_employment: data }
    payload.id = props.id;
    makeRequest(setLoader, updateEmploymentReq, payload, onEmployeeUpdateSuccess, onEmployeeCreateError);
    setLoader(true)
  }

  const onEmployeeUpdateSuccess = (data, response) => {
    
    setLoader(false)
    props.setDefaultProfileCard(response.profile_card);
    if(response.message){
      message.success(response.message)
    }
    else{
      message.success('Employee Employment Updated Successfully!')
    }
  }
  const onEmployeeCreateError = (error, response) => {
    message.error(response.response.data.message)
  }

  //-----------------onChange--------------------

  const onChangeLocation = (locationId) => {
    makeRequestStateless(getBranchsByLocationIdReq, locationId, onBranchsByLocationIdSuccess, onCompanyRelatedDataError);
  }
  const onBranchsByLocationIdSuccess = (data) => {
    setBranches(createSelectList(data));
  }

  const onChangeIsFinalAuthority = (e) => {
    setIsFinalAuthority(e.target.checked);

  };
  const onChangeEmploymentType = (id) => {
    setIsEmploymentType(id);
  }
  //-------------------end--------------------
  // ------------------------------------
  // Start footer buttons array
  //---------------------form layout------------




  //------------------step 2 form---------------------------
  // Create component modal
  //-----new grade------
  const onCreateGrade = () => {
    setChildComponent(<CreateGrade onCompleted={ onGradeCreated } />);
  };
  const onGradeCreated = (data) => {
    if (data.length) {
      form.setFieldValue('grade_id', data[0].id)
      setGrades(createSelectList(data))
    }
    setChildComponent(null);
  };

  //-------onCreateLocation
  const onCreateLocation = () => {
    setChildComponent(<CreateLocation onCompleted={ onLocationCreated } />);
  };
  const onLocationCreated = (data) => {
    if (data.length) {
      form.setFieldValue('location_id', data[0].id)
      setLocations(createSelectList(data))
      onChangeLocation(data[0].id)
      form.setFieldValue("branch_id", null)
    }
    setChildComponent(null);
  };

  //--------new department------
  const onCreateDepartment = () => {
    setChildComponent(<CreateDepartment onCompleted={ onDepartmentCreated } />);
  };
  const onDepartmentCreated = (data) => {
    if (data.length) {
      form.setFieldValue('department_id', data[0].id)
      setDepartments(createSelectList(data))
    }
    setChildComponent(null);
  };
  //--------new branch------
  const onCreateBranch = () => {

    setChildComponent(<CreateBranch onCompleted={ onBranchCreated } />);
  };
  const onBranchCreated = (data) => {
    if (data.length) {
      form.setFieldValue('branch_id', data[0].id)
      setBranches(createSelectList(data))
    }
    setChildComponent(null);
  };
  //--------new Designation------
  const onCreateDesignation = () => {
    setChildComponent(<CreateDesignation onCompleted={ onDesignationCreated } />);
  };
  const onDesignationCreated = (data) => {
    if (data.length) {
      form.setFieldValue('designation_id', data[0].id)
      setDesignations(createSelectList(data))
    }
    setChildComponent(null);
  };
  //--------new job title------
  const onCreateJobTitle = () => {
    setChildComponent(<CreateJobTitle onCompleted={ onJobTitlesCreated } />);
  };
  const onJobTitlesCreated = (data) => {
    if (data.length) {
      form.setFieldValue('jobtitle_id', data[0].id)
      setJobTitles(createSelectList(data))
    }
    setChildComponent(null);
  };
  //------------------------------------
  //------------current step---------
  const [secondStepData, setsecondStepData] = useState();
  //------------------step 2 form---------------------------
  const secondStep = () => {
    return (<>
      { childComponent }
      <div style={ { border: '1px solid #C9D6DF', marginTop: 20, borderRadius: 10 } }>
        <div className="employeeInfo"><h5 className="employeeInfo-text">Employment Details</h5></div>
        <Skeleton loading={ loading }>
          <Form
            { ...formLayout }
            form={ form }
            name={ formName }
            onFinish={ onSubmitSecondStep }
            className="custom-form"
            disabled={ !editMode }
            scrollToFirstError

          >
            <div style={ { marginLeft: 12, marginTop: 20 } }>
              <Row>
                <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                  <Form.Item name="prev_code" rules={ rules.user_defined_Code } label={ trans(`${langKey}.userDefinedCode`) } className="da-mb-16"
                    { ...getErrorProps(errors['user_defined_Code']) } size="small"
                  >
                    <InputText
                      placeholder={ 'User Defined Code' } />
                  </Form.Item>
                </Col>

                <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                  <Form.Item name="joining_date" rules={ rules.joining_date } label={ trans(`${langKey}.joiningdate`) } className="da-mb-16"
                    { ...getErrorProps(errors['joining_date']) } size="small"
                  >
                    <InputDate
                      format="DD-MMM-YYYY" />
                  </Form.Item>
                </Col>

                <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                  <AddNewButton onClick={ onCreateGrade } />
                  <Form.Item name="grade_id" label={ trans(`${langKey}.selectgrades`) } rules={ rules.grade_id } className="da-mb-16"
                    { ...getErrorProps(errors['grade_id']) }
                  >
                    <SelectWithSearch
                      placeholder={ trans(`${langKey}.selectgrades`) }
                      options={ grades }
                    />
                  </Form.Item>
                </Col>

                <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                  <AddNewButton onClick={ onCreateLocation } />
                  <Form.Item name="location_id" label={ trans(`${langKey}.selectlocation`) } rules={ rules.location_id } className="da-mb-16"
                    { ...getErrorProps(errors['location_id']) }
                  >
                    <SelectWithSearch
                      placeholder={ trans(`${langKey}.selectlocation`) }
                      options={ locations }
                      onChange={ onChangeLocation }
                    />
                  </Form.Item>
                </Col>


                <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                  <AddNewButton onClick={ onCreateDepartment } />
                  <Form.Item name="department_id" label={ trans(`${langKey}.selectdepartment`) } rules={ rules.department_id }
                    { ...getErrorProps(errors['department_id']) }
                  >
                    <SelectWithSearch
                      placeholder={ trans(`${langKey}.selectdepartment`) }
                      options={ departments }
                    />
                  </Form.Item>
                </Col>

                <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                  <AddNewButton onClick={ onCreateBranch } />
                  <Form.Item name="branch_id" label={ trans(`${langKey}.selectbranch`) } rules={ rules.branch_id }
                    { ...getErrorProps(errors['branch_id']) }
                  >
                    <SelectWithSearch
                      placeholder={ trans(`${langKey}.selectbranch`) }
                      options={ branches }
                    />
                  </Form.Item>
                </Col>

                <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                  <AddNewButton onClick={ onCreateDesignation } />
                  <Form.Item name="designation_id" label={ trans(`${langKey}.selectdesignation`) } rules={ rules.designation_id }
                  >
                    <SelectWithSearch
                      placeholder={ trans(`${langKey}.selectdesignation`) }
                      options={ designations }
                    />
                  </Form.Item>
                </Col>

                <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                  <Form.Item name="is_on_probation" valuePropName="checked" label={ trans(`${langKey}.probation`) }>
                    <Checkbox onChange={ onChangeProbation } disabled></Checkbox>
                  </Form.Item>
                </Col>

                {/* { isProbation ?
                  <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                    <Form.Item name="probation_period_month" rules={ isProbation ? rules.probation_end_date : '' } label={ trans(`${langKey}.probationEndDate`) } className="da-mb-16"
                      { ...getErrorProps(errors['probation_end_date']) } size="small"
                    >
                      <InputText placeholder={ trans(`${langKey}.probationEndDate`) } type="number" disabled={ isProbation ? false : true } />
                    </Form.Item>
                  </Col>
                  : ''
                } */}


                <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                  <AddNewButton onClick={ onCreateJobTitle } />
                  <Form.Item name="jobtitle_id" label={ trans(`${langKey}.selectjobtitle`) } rules={ rules.jobtitle_id }
                    { ...getErrorProps(errors['jobtitle_id']) }
                  >
                    <SelectWithSearch
                      placeholder={ trans(`${langKey}.selectjobtitle`) }
                      options={ jobTitles }
                    />
                  </Form.Item>
                </Col>

                <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                  <Form.Item name="official_email" rules={ rules.official_email } label={ trans(`${langKey}.officialEmail`) } className="da-mb-16"
                    { ...getErrorProps(errors['official_email']) } size="small"
                  >
                    <InputText className="input-width" placeholder={ "Enter here" } type="email" />
                  </Form.Item>
                </Col>

                <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                  <Form.Item style={ { paddingTop: 0 } } name="employee_type_id" label={ trans(`${langKey}.selectemployeetype`) } rules={ rules.employee_type_id }
                    { ...getErrorProps(errors['employee_type_id']) }
                  >
                    <SelectWithSearch
                      placeholder={ trans(`${langKey}.selectemployeetype`) }
                      options={ employeeTypes }
                    />
                  </Form.Item>
                </Col>

                <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                  <Form.Item
                    name="contracttype_id" label={ trans(`${langKey}.selectemploymenttype`) } rules={ rules.contracttype_id } className="da-mb-16"
                    { ...getErrorProps(errors['contracttype_id']) }
                  >
                    <SelectWithSearch
                      onChange={ onChangeEmploymentType }
                      placeholder={ trans(`${langKey}.selectemploymenttype`) }
                      options={ employmentTypes }
                    />
                  </Form.Item>
                </Col>
                { isEmploymentType === 1 ?
                  <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                    <Form.Item name="contract_expiry_date" rules={ rules.contract_expiry_date } label={ trans(`${langKey}.contract_expiry_date`) } className="da-mb-16"
                      { ...getErrorProps(errors['contract_expiry_date']) }
                    >
                      <InputDate />
                    </Form.Item>
                  </Col>
                  : '' }

                <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                  <Form.Item valuePropName="checked" name="exempt_attendance" label={ trans(`${langKey}.isattendanceexcempt`) } className="da-mb-16">
                    <Checkbox></Checkbox>
                  </Form.Item>
                </Col>

                <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                  <Form.Item valuePropName="checked" name="is_retail" label={ trans(`${langKey}.isretailemployee`) } className="da-mb-16">
                    <div style={ { display: "flex", marginLeft: -0, color: '#687980' } }>

                      <Form.Item valuePropName="checked" name="is_final_authority">
                        <Checkbox onChange={ onChangeIsFinalAuthority }>Is Final Authority</Checkbox>
                      </Form.Item>

                      <Form.Item valuePropName="checked" name="is_hod">
                        <Checkbox checked={ isFinalAuthority } disabled={ isFinalAuthority }>Is HOD</Checkbox>
                      </Form.Item>

                      <Form.Item valuePropName="checked" name="is_manager">
                        <Checkbox checked={ isFinalAuthority } disabled={ isFinalAuthority }>Is Line Manager</Checkbox>
                      </Form.Item>
                    </div>
                  </Form.Item>
                </Col>

                <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                  <Form.Item
                    name="linemanager_id"
                    label={ trans(`${langKey}.selectlinemanager`) } rules={ !isFinalAuthority ? rules.linemanager_id : [] } className="da-mb-16"
                    { ...getErrorProps(errors['linemanager_id']) }
                    style={ { display: isFinalAuthority ? 'none' : 'block' } }
                  >
                    <SelectWithSearch
                      placeholder={ trans(`${langKey}.selectlinemanager`) }
                      options={ lineManagers }
                    />
                  </Form.Item>
                </Col>

                <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                  <Form.Item
                    name="final_authority_id"
                    label={ trans(`${langKey}.selectfinalauthority`) } rules={ !isFinalAuthority ? rules.finalauthority : [] } className="da-mb-16"
                    { ...getErrorProps(errors['finalauthority']) }
                    style={ { display: isFinalAuthority ? 'none' : 'block', marginTop: 0 } }
                  >
                    <SelectWithSearch
                      placeholder={ trans(`${langKey}.selectfinalauthority`) }
                      options={ finalAuthorities }
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
            {
              editMode &&
              <div className="employeeInfo-update">
                <Form.Item style={ { float: 'right' } }>
                  <SaveButton className="employeeInfo-update-save" type="primary" htmlType="submit" loading={ loader } >
                    Update
                  </SaveButton>
                </Form.Item>
              </div>
            }

          </Form>
        </Skeleton>

        { editMode ? (
          <Button className="employeeInfo-update-cancel" style={ { marginTop: -58 } } onClick={ handleSaveClick }>
            <span style={ { color: "#EA4335" } }>Cancel</span></Button>
        ) : (
          <div>
            { checkPermissions('profile_employeeEmployment') &&
              <Button className="edit-button" type="primary" onClick={ handleEditClick } >
                <span className="edit-button-text"><EditOutlined /> Edit</span>
              </Button>
            }
          </div>

        ) }
      </div>
    </>)
  }
  //-----------------
  return (
    <>
      { secondStep() }
    </>
  )
}

export default UpdateEmploymentInfo

const rules = {

  salutation_id: [
    { required: true, message: trans(`${langKey}.salutation.required`), },
  ],
  first_name: [
    { required: true, message: trans(`${langKey}.firstName.required`), },
  ],

  last_name: [
    { required: true, message: trans(`${langKey}.lastName.required`), },
  ],
  father_or_husband_name: [
    { required: true, message: trans(`${langKey}.fatherName.required`), },
  ],
  gender_id: [
    { required: true, message: trans(`${langKey}.gender.required`), },
  ],
  d_o_b: [
    { required: true, message: trans(`${langKey}.dob.required`), },
  ],
  marital_status_id: [
    { required: true, message: trans(`${langKey}.dob.required`), },
  ],
  nationality_id: [
    { required: true, message: trans(`${langKey}.nationality.required`), },
  ],
  mobile_no: [
    { required: true, message: trans(`${langKey}.mobileNo.required`), },
  ],
  cnic_no: [
    { required: true, message: trans(`${langKey}.cnicNo.required`), },
  ],
  passport_no: [
    { required: true, message: trans(`${langKey}.passportNo.required`), },
  ],
  joining_date: [
    { required: true, message: trans(`${langKey}.joiningDate.required`), },
  ],
  grade_id: [
    { required: true, message: trans(`${langKey}.grade.required`), },
  ],
  location_id: [
    { required: true, message: trans(`${langKey}.location.required`), },
  ],
  department_id: [
    { required: true, message: trans(`${langKey}.department.required`), },
  ],
  branch_id: [
    { required: true, message: trans(`${langKey}.branch.required`), },
  ],
  designation_id: [
    { required: true, message: trans(`${langKey}.designation.required`), },
  ],

  jobtitle_id: [
    { required: true, message: trans(`${langKey}.jobtitle.required`), },
  ],
  linemanager_id: [
    { required: true, message: trans(`${langKey}.lineManager.required`), },
  ],

  finalauthority: [
    { required: true, message: trans(`${langKey}.finalAuthority.required`), },
  ],
  official_email: [
    { required: true, message: trans(`${langKey}.officialEmail.required`), },
  ],
  employee_type_id: [
    { required: true, message: trans(`${langKey}.employeeType.required`), },
  ],
  contracttype_id: [
    { required: true, message: trans(`${langKey}.employmentType.required`), },
  ],
  user_name: [
    { required: true, message: trans(`${langKey}.userName.required`), },
  ],
  password: [
    { required: true, message: trans(`${langKey}.password.required`), },
  ],

  passwordconfirmation: [
    { required: true, message: trans(`${langKey}.passwordConfirmation.required`), },
  ],

  timezone_id: [
    { required: true, message: trans(`${langKey}.timezone.required`), },
  ],

  role_id: [
    { required: true, message: trans(`${langKey}.role.required`), },
  ],

  probation_end_date: [
    { required: true, message: trans(`${langKey}.probationEndDate.required`), },
  ],
  contract_expiry_date: [
    { required: true, message: trans(`${langKey}.contract_expiry_date.required`), },
  ]
};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 23, },
  labelAlign: "left"
}