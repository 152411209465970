import React, { useEffect, useState } from "react";
import { Form, message, Checkbox, Divider,Button,Skeleton } from "antd";
import { SaveButton, InputText, InputTextArea, CancelButton, ModalComponent, trans, SelectWithSearch } from "@comps/components"
import { makeRequest, makeRequestStateless, getErrorProps } from "@utils/helpers";
import { updateDataReq, getRelatedReq } from "../requests";
import { Commons, Const, Validations } from "../../../constants";
import { createSelectList } from '@comps/commonFunctions';
import {DeleteOutlined, PlusOutlined } from '@ant-design/icons';
const langKey = Const["benefit"].lngKey
const formName = "updateBenefit";

const UpdateBenefit = (props) => {
  const [loader, setLoader] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [isGroup, setIsGroup] = useState(false);
  const [payItems, setPayitem] = useState([])
  const [formulas, setFormulas] = useState([])
  const [benefitGroups, setBenefitGroups] = useState([])
  const [deletedRows, setDeletedRows] = useState([])
  const [loading,setLoading]=useState(true)
  const [form] = Form.useForm();

  useEffect(() => {
    setFormData();
    makeRequestStateless(getRelatedReq, null, ongetRelatedReq, null);
    // eslint-disable-next-line
  }, []);
  const ongetRelatedReq = (response) => {
    setLoading(false)
    setPayitem(createSelectList(response.payitems));
    setFormulas(createSelectList(response.formulas));
  }
  const setFormData = () => {
    if (!props) {
      return;
    }
    setBenefitGroups(props.record.benefit_groups)
    setIsGroup(props.record.is_group)
    form.setFieldsValue(props.record);
  }
  const onSubmit = (data) => {
    let newList = [];
    if(isGroup){
      if(data.benefit_groups===undefined){
        message.info("Please Add memebers");
        return
      }
      if(data.benefit_groups!==undefined && data.benefit_groups.length<1){
        message.info("Please Add memebers");
        return
      }
      //update payitems
    
     data.benefit_groups !== undefined && data.benefit_groups.forEach((each) => {
       if (each.id === undefined) {
         each.row_state = 'Added'
       }
       if (each.id !== undefined) {
         each.row_state = 'Updated'
       }
       each.company_id=1;
       newList.push(each)
     })
 
     deletedRows.forEach(row => {
       newList.push(row)
     })
    }
     
    setSaveLoader(true)
    data.is_group=isGroup;
 
    const payload = { benefit: {...props.record,...data,benefit_groups:newList} };
    payload.id = props.record.id;
    makeRequest(setLoader, updateDataReq, payload, onSuccess, onError);
  }

  const onSuccess = (data, res) => {
    setSaveLoader(false)
    message.success("Record Updated");
    props.onCompleted(data.benefits.data);
  }

  const onError = (err, res) => {
    setSaveLoader(false)
    message.error(res.response.data.message);
  }
  const onChangeIsGroup = (e) => {
    setIsGroup(e.target.checked);
  }
  //-----------------------

  const setRows = (key, state) => {
    if (benefitGroups[key] !== undefined && benefitGroups[key].id !== undefined && state === 'Deleted') {
      benefitGroups[key].row_state = 'Deleted'
      deletedRows.push(benefitGroups[key])
    }
    //setDeletedRows(deletedRows)
  }
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [
    !props.disabled &&
    <SaveButton loading={ saveLoader } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } width={ 1000 } maskClosable={ false }
      mainTitle={ trans(Commons[props.disabled ? 'view' : 'update']) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
        <Skeleton loading={loading}>
      <Form { ...formLayout } name={ formName } onFinish={ onSubmit } form={ form } disabled={ props.disabled } scrollToFirstError>

        <Form.Item name="name" rules={ rules.name } label={ trans(Commons.name) }
          className="da-mb-16"
          { ...getErrorProps(errors['name']) } size="small"
        >
          <InputText placeholder="Name" />
        </Form.Item>

        <Form.Item name="payitem_id" rules={ rules.payitem_id } label={ trans(`${langKey}.payitem_id`) }
          className="da-mb-16"
          { ...getErrorProps(errors['name']) } size="small"
        >
          <SelectWithSearch
            placeholder={ trans(`${langKey}.payitem_id`) }
            options={ payItems }
          />
        </Form.Item>

        <Form.Item name="is_group" rules={ rules.is_group } label={ trans(`${langKey}.is_group`) }
          className="da-mb-16"
          { ...getErrorProps(errors['name']) } size="small"
          valuePropName="checked"
        >
          <Checkbox onChange={ onChangeIsGroup } />
        </Form.Item>

        <Form.Item name="description" rules={ rules.description } label={ trans(Commons.description) }
          className="da-mb-16"
          { ...getErrorProps(errors['description']) } size="small"
        >
          <InputTextArea placeholder={ trans(`${langKey}.description`) } />
        </Form.Item>

        {
        isGroup ? 
        <div className="formlist-container">
          <h5 className="formlist-title">{ trans(`${langKey}.benefit_groups`) }</h5>
          <Form.List name="benefit_groups" >
            { (fields, { add, remove }) => (
              <>
                <Button onClick={ () => {
                  add()

                } } icon={ <PlusOutlined /> } type="primary" className="add-node-button">Add </Button>
                <div className="table-container">
                  <table className="table">
                    {
                      fields.length !== 0 && <thead>
                        <tr>
                        <th>{ trans(`${langKey}.name`) }</th>
                          <th style={ { paddingLeft: 20 } }>{ trans(`${langKey}.formula_id`) }</th>
                        
                       
                          <th>{ trans(Commons.actions) }</th>
                        </tr>
                      </thead>
                    }
                    <tbody>
                      { fields.map(({ key, name, ...restField }) => (
                        <>
                          <tr>
                          <td style={ { minWidth: '200px' } }>
                              <Form.Item
                                { ...restField }
                                name={ [name, 'name'] }
                                rules={ rules.name }
                              >
                                <InputText className="formlist-remove" />
                              </Form.Item>
                            </td>
                            <td style={ { minWidth: '200px', paddingLeft: 20 } }>
                              <Form.Item
                                { ...restField }
                                name={ [name, 'formula_id'] }
                                rules={ rules.formula_id }
                              >
                                <SelectWithSearch
                                  options={ formulas }
                                  placeholder={ trans(`${langKey}.formula_id`) } />
                              </Form.Item>
                            </td>
                         
                           
                            <td>
                              <DeleteOutlined className="formlist-remove" onClick={ () => {
                                 remove(name)
                                 setRows(key, 'Deleted')
                              } } />
                            </td>
                          </tr>
                        </>
                      )) }
                    </tbody>
                  </table>
                </div>
              </>
            ) }
          </Form.List>
        </div> :
          null
      }
      </Form>
      </Skeleton>
      {/* <Divider orientation="left">{ trans(`${langKey}.additional_infos`) }</Divider> */}
     
    </ModalComponent>
  )
}

export default UpdateBenefit

const rules = {
  name: [
    { required: true, message: trans(Validations.name) },
  ],
  payitem_id: [
    { required: true, message: trans(Validations.payitem_id) },
  ],
  expiry_date: [
    { required: true, message: trans(`${langKey}.expiry_date.req`) },
  ],
  // attachment: [
  //   { required: true, message: trans(`${langKey}.attachment.req`) },
  // ],

};

const formLayout = {
  // layout: "horizontal",
  labelCol: { span: 6 },
  wrapperCol: { span: 14, offset: 2 },
  labelAlign: "left"
}