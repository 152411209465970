import { get, del, post, put, postFd } from "@utils/axios";

const api = "lendRepayments";

export const getAllDataReq = (payload) => {
  return get(`${api}?page=${payload.page}&filters=${payload.filters}`) //filters=employee_|id
};
export const getDataByIdReq = (payload) => {
  return get(`${api}/${payload}`)
};
export const scheduleRepaymentReq = (payload) => {
  return put(`${api}/${payload.lend.id}`, payload)
};

export const createDataReq = (payload) => {
  return post(`${api}/adjustRepayment`, payload);
};

export const updateDataReq = (payload) => {
  return postFd(`${api}/edit?page=1&filters=${payload.filters}`, payload);
};
export const cancelRequestReq = (payload) => {
  return postFd('cancelRequest', payload);
};

export const deleteDataReq = (payload) => {
  return del(`${api}/${payload.id}?page=1&filters=${payload.filters}`);
};
export const getEmploymentsReq = (payload) => {
  return get(`resources/employment?page=1&filters=${payload.filters}`);
};

export const getEmployeeRelatedDataReq = (payload) => {
  return post(`${api}/employeeRelatedData`, payload); //employment_id=id
};

export const getFilterReq = (payload) => {
  return get(`filters?company_id=1&fields=page=1&filters=supporting_fields_|employees,request_status;organization_structure_fields_|location,branch,department;preset_|company_lends;`)
}

export const onDateChange = (payload) => {
  return post(`${api}/dateChange?&`, payload)
}
export const onShiftSelectionChangeReq = (payload) => {
  return post(`${api}/shiftSelectionChange?&`, payload)
}

export const getRelatedDataByReqIdReq = (payload) => {
  return get(`${api}/${payload}`)
};

