import React from "react";
import { ReloadOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';

const BaseRefresh = (props) => 

<Tooltip title="Refresh">
<Button className={ "refresh-button" } { ...props } ><ReloadOutlined className="refresh-button-icon" /></Button>
</Tooltip>


export default BaseRefresh;
