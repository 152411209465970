import React, { useEffect, useState } from "react";
import { Form, Skeleton, message, Row, Col } from "antd";
import { SaveButton, InputText, CancelButton, ModalComponent, trans, SelectWithSearch, SelectResourceList } from "@comps/components"
import { makeRequest, makeRequestStateless, getErrorProps } from "@utils/helpers";
import { createAddressReq, getCitiesByStateReq, getStatesByCountryReq } from "../requests";
import { Commons, Const } from "../../constants";
import { createSelectList } from '@comps/commonFunctions';
import { getAllcountriesReq } from "../../commonRequests";

const langKey = Const["address"].lngKey

const formName = "createAddress";

const CreateAddress = (props) => {

  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [saving, setsaving] = useState(false);
  const [loading, setloading] = useState(true);
  const [form] = Form.useForm()
  useEffect(() => {

    getData()
    // eslint-disable-next-line
  }, []);


  const getData = () => {
    form.setFieldValue('employee_id', props.filters !== undefined ? props.filters.replace('employments_|', '') : null)
    makeRequestStateless(getAllcountriesReq, false, onGetCountries, false)
  }
  const onGetCountries = (response) => {
    setCountries(createSelectList(response.countries))
    setloading(false)
  }
  const onSubmit = (data) => {
    setsaving(true)
    const payload = { employee_address: data, filters: props.filters }
    makeRequest(setLoader, createAddressReq, payload, onSuccess, onError);
  }

  const onSuccess = (data, res) => {
    message.success("Address Saved successfully");
    setsaving(false)
    props.onCompleted(data.employee_addresses.data);
    props.onSuccess(data)
  }

  const onError = (err, res) => {
    message.error(res.response.data.message);
    //setErrors(errorList);
  }
  //-----------on changes------------

  const onChangeCountry = (id) => {
    makeRequestStateless(getStatesByCountryReq, id, onGetStates, false)
  }

  const onGetStates = (data, response) => {
    setStates(createSelectList(data.all_states))
  }

  const onChangeState = (id) => {
    makeRequestStateless(getCitiesByStateReq, id, onGetCities, false)
  }

  const onGetCities = (data, response) => {
    setCities(createSelectList(data.cities))

  }
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [
    <SaveButton loading={ saving } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent width={ 1000 } top={ 20 } maskClosable={ false }
      mainTitle={ trans(Commons.create) } subTitle={ trans(`${langKey}.address`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Skeleton loading={ loading }>
        <Form { ...formLayout } name={ formName } onFinish={ onSubmit } form={ form } scrollToFirstError>
          <Row gutter={ 30 }>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="employee_id" label={ trans(`${langKey}.select.employee`) } rules={ rules.employee_id } className="da-mb-16"
                { ...getErrorProps(errors['employee_id']) }
              >
                <SelectResourceList type='employee' disabled={ props.filters !== undefined } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="address_type" label={ trans(`${langKey}.select.addresstype`) } rules={ rules.address_type } className="da-mb-16"
                { ...getErrorProps(errors['address_type']) }
              >
                <SelectWithSearch
                  placeholder={ trans(`${langKey}.select.addresstype`) }
                  options={ [
                    {
                      value: 'Permanent',
                      label: 'Permanent',
                    },
                    {
                      value: 'Present',
                      label: 'Present',
                    },
                  ] }
                />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="complete_address" rules={ rules.complete_address } label={ trans(`${langKey}.completeAddress`) }
                className="da-mb-16"
                { ...getErrorProps(errors['name']) } size="small"
              >
                <InputText placeholder={ trans(`${langKey}.completeAddress`) } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="country_id" label={ trans(`${langKey}.select.country`) } rules={ rules.country_id } className="da-mb-16"
                { ...getErrorProps(errors['country_id']) }
              >
                <SelectWithSearch
                  onChange={ onChangeCountry }
                  placeholder={ trans(`${langKey}.select.country`) }
                  options={ countries }
                />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="state_id" label={ trans(`${langKey}.select.state`) } rules={ rules.state_id } className="da-mb-16"
                { ...getErrorProps(errors['state_id']) }
                disabled={ true }
              >
                <SelectWithSearch
                  onChange={ onChangeState }
                  placeholder={ trans(`${langKey}.select.state`) }
                  options={ states }
                />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="city_id" label={ trans(`${langKey}.select.city`) } rules={ rules.city_id } className="da-mb-16"
                { ...getErrorProps(errors['city_id']) }
                disabled={ true }
              >
                <SelectWithSearch
                  placeholder={ trans(`${langKey}.select.city`) }
                  options={ cities }
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Skeleton>

    </ModalComponent>
  )
}

export default CreateAddress

const rules = {
  complete_address: [
    { required: true, message: trans(`${langKey}.address.req`) },
  ],
  employee_id: [
    { required: true, message: trans(`${langKey}.employee.req`) },
  ],
  address_type: [
    { required: true, message: trans(`${langKey}.addresstype.req`) },
  ],

};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}