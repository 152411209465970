import { useEffect, useState } from "react";
import { Form, Divider, Row, Col, Collapse, Checkbox, } from "antd";
import { trans, InputText, InputTextArea, SelectMultiWithSearch, SelectWithSearch, InputDate,TableWithFilter } from "@comps/components"
import { Commons, Const } from "../../constants"
import { createSelectList } from '@comps/commonFunctions';
import dayjs from 'dayjs'
const CreatePreview = (props) => {
  const langKey = Const["requestPolicyPlanner"].lngKey
  const langKeyTravelPolicy = Const["travelPolicy"].lngKey
  const formName = "createMyRoaster";
  const [travelTypes, setTravelTypes] = useState([])
  const [travelModes, setTravelModes] = useState([])
  const [record, setRecord] = useState()

  const [form] = Form.useForm()
  useEffect(() => {
    setData()
  }, [props])
  const setData = () => {
    setRecord(props.record)
    form.setFieldsValue({
      name: props.record.name,
      date: props.record.date ? dayjs(props.record.date) : null,
      for_all: props.record.for_all
    })

    const travelType = []
    if (props.record.travel_policy !== undefined) {
      props.record.travel_policy.details.forEach(each => {
        travelType.push(each.travel_type)
      })
      setTravelTypes(
        createSelectList(travelType)
      )
      setTravelModes(createSelectList(props.record.travel_modes))
    }
  }

  //travel policy info


  const travelPolicyRecord = <Form { ...formLayout } name={ formName } disabled={ true } initialValues={ props.record.travel_policy }>
    <Row gutter={ 30 }>
      <Col lg={ 12 } sm={ 24 } xs={ 24 }>
        <Form.Item name="name" rules={ rules.name } label={ trans(Commons.name) }
          className="da-mb-16"

        >
          <InputText placeholder={ trans(`${langKey}.name`) } />
        </Form.Item>
      </Col>
      <Col lg={ 12 } sm={ 24 } xs={ 24 }>
        <Form.Item name="description" rules={ rules.description } label={ trans(Commons.description) }
          className="da-mb-16"

        >
          <InputTextArea placeholder={ trans(`${langKey}.description`) } />
        </Form.Item>
      </Col>
    </Row>

    <Divider> { trans(`${langKeyTravelPolicy}.details`) }</Divider>
    <Form.List name="details" >
      { (fields, { add, remove }) => (
        <>

          <div className="table-container">
            <table className="table">
              {
                fields.length !== 0 && <thead>
                  <tr>
                    <th>{ trans(`${langKeyTravelPolicy}.traveling_type_id`) }</th>
                    <th>{ trans(`${langKeyTravelPolicy}.traveling_mode_id`) }</th>
                    <th>{ trans(`${langKeyTravelPolicy}.company_stay_amount`) }</th>
                    <th>{ trans(`${langKeyTravelPolicy}.self_stay_amount`) }</th>
                    <th>{ trans(`${langKeyTravelPolicy}.daily_allowance`) }</th>

                  </tr>
                </thead>
              }
              <tbody>
                { fields.map(({ key, name, ...restField }) => (
                  <>
                    <tr>
                      <td style={ { minWidth: '200px' } }>
                        <Form.Item
                          { ...restField }
                          name={ [name, 'traveling_type_id'] }
                          rules={ rules.traveling_type_id }
                        >
                          <SelectWithSearch
                            options={ travelTypes }
                            placeholder={ trans(`${langKeyTravelPolicy}.traveling_type_id`) } />
                        </Form.Item>
                      </td>
                      <td style={ { minWidth: '200px' } }>
                        <Form.Item
                          { ...restField }
                          name={ [name, 'traveling_mode_id'] }
                          rules={ rules.traveling_mode_id }
                        >
                          <SelectMultiWithSearch
                            options={ travelModes }
                            placeholder={ trans(`${langKeyTravelPolicy}.traveling_mode_id`) } />
                        </Form.Item>
                      </td>
                      <td style={ { minWidth: '200px' } }>
                        <Form.Item
                          { ...restField }
                          name={ [name, 'company_stay_amount'] }
                          rules={ rules.company_stay_amount }
                        >
                          <InputText placeholder={ trans(`${langKey}.company_stay_amount`) } type="number" />
                        </Form.Item>
                      </td>
                      <td style={ { minWidth: '200px' } }>
                        <Form.Item
                          { ...restField }
                          name={ [name, 'self_stay_amount'] }
                          rules={ rules.self_stay_amount }
                        >
                          <InputText placeholder={ trans(`${langKey}.self_stay_amount`) } type="number" />
                        </Form.Item>
                      </td>
                      <td style={ { minWidth: '200px' } }>
                        <Form.Item
                          { ...restField }
                          name={ [name, 'daily_allowance'] }
                          rules={ rules.daily_allowance }
                        >
                          <InputText placeholder={ trans(`${langKey}.daily_allowance`) } type="number" />
                        </Form.Item>
                      </td>
                    </tr>
                  </>
                )) }
              </tbody>
            </table>
          </div>
        </>
      ) }
    </Form.List>
  </Form>


  const items = [
    {
      key: '1',
      label: trans(`${langKey}.travel_policy_id`),
      children: travelPolicyRecord

    },

  ];

  //=============
  return (
    <>
      <Form { ...formLayout } name={ formName } initialValues={ {} } form={ form } disabled={ true }>
        <Row gutter={ 30 }>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="name" rules={ rules.name } label={ trans(`${langKey}.name`) }
              className="da-mb-16"

            >
              <InputText placeholder={ trans(`${langKey}.name`) } />
            </Form.Item>
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="date" rules={ rules.date } label={ trans(`${langKey}.date`) }
              className="da-mb-16"

            >
              <InputDate />
            </Form.Item>
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="for_all" rules={ rules.for_all } label={ trans(`${langKey}.for_all`) }
              className="da-mb-10"

              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>
          </Col>
        </Row>

      </Form>

      <Collapse accordion items={ items } />
      {
        !props.record.for_all && props.record.employments &&
        <>
          <h5 className="formlist-title">Employees</h5>
          <div style={ {
            overflowY: 'scroll',
            maxHeight: '50vh'
          } }>
            <TableWithFilter columns={ [{
              title: 'Name',
              dataIndex: 'name',
              key: 'name',
            },
            {
              title: 'designation',
              dataIndex: 'designation_name',
              key: 'designation',
            },] }

              dataSource={ props.record.employments }
            >
            </TableWithFilter>
          </div>
        </>
      }


    </>
  )
}

export default CreatePreview


const rules = {
  start_date: [
    { required: true, message: 'Required' },
  ],
  end_date: [
    { required: true, message: 'Required' },
  ],
  repeat: [
    { required: true, message: 'Required' },
  ],
  font_colour: [
    { required: true, message: 'Required' },
  ],
  bg_colour: [
    { required: true, message: 'Required' },
  ],
  days: [
    { required: true, message: 'Please Select At least one day' },
  ],

};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}
