import React, { useEffect, useState } from "react";
import { Form, message, Skeleton, Row, Col } from "antd";
import { SaveButton, InputTextArea, SelectMultiWithSearch, CancelButton, ModalComponent, trans, SelectResourceList } from "@comps/components"
import { makeRequest, makeRequestStateless, } from "@utils/helpers";
import { createDataReq } from "../requests";
import { Commons, Const } from "../../constants";
import { createSelectList } from '@comps/commonFunctions';
import { getEmploymentsDataReq } from "@mods/common/requests";
const langKey = Const["delegation"].lngKey
const formName = "createDelegation";

const CreateDelegation = (props) => {
  const [loader, setLoader] = useState(false);
  const [saving, setsaving] = useState(false);
  const [types, setTypes] = useState([])
  const [loading, setLoading] = useState(true);
  const [delegators, setDelegators] = useState([])
  const [delegatees, setDelegatees] = useState([])
  const [form] = Form.useForm()
  useEffect(() => {
    getData()
    // eslint-disable-next-line
  }, []);

  const getData = () => {
    setTypes(createSelectList(props.allData.delegation_types))
    getEmployments()
  }
  const getEmployments = () => {
    const payload = { filters: null }
    makeRequestStateless(getEmploymentsDataReq, payload, onGetEmployments, false)
  }
  const onGetEmployments = (response) => {
    setLoading(false)
    setDelegators(createSelectList(response.resources.data))
  }
  const onChangeDelegator = (id) => {

    const oldDelegatee = form.getFieldValue('delegatee_employment_id')
    if (oldDelegatee === id) {
      form.setFieldValue('delegatee_employment_id', null)
    }

    const newDelegatees = delegators.filter(each => { return each.value !== id })
    setDelegatees(newDelegatees)
  }
  const onSubmit = (data) => {
    const payload = { delegation: { ...data, company_id: 1, is_active: true } };
    setsaving(true)
    makeRequest(setLoader, createDataReq, payload, onSuccess, onError);
  }

  const onSuccess = (data, res) => {
    message.success(res.message);
    props.onCompleted(data.delegations.data);
    props.onSuccess(data)
  }

  const onError = (err, res) => {
    setsaving(false)
    message.error(res.response.data.message);
  }

  //-----------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [
    <SaveButton loading={ saving } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } width={ 1000 } maskClosable={ false }
      mainTitle={ trans(Commons.create) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) } >
      <Skeleton loading={ loading }>
        <Form { ...formLayout } name={ formName } onFinish={ onSubmit } form={ form }>
          <Row gutter={ 30 }>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>

              <Form.Item name="delegator_employment_id" rules={ rules.delegator_employment_id } label={ trans(`${langKey}.delegator_employment_id`) }
                className="da-mb-16"
              >
                <SelectResourceList type='employment' disabled={ props.filters !== undefined } onChange={ onChangeDelegator } />

              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="delegation_types" rules={ rules.delegation_types } label={ trans(`${langKey}.delegation_types`) }
                className="da-mb-16"
              >
                <SelectMultiWithSearch
                  options={ types }
                  placeholder={
                    trans(`${langKey}.delegation_types`)
                  } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="delegatee_employment_id" rules={ rules.delegatee_employment_id } label={ trans(`${langKey}.delegatee_employment_id`) }
                className="da-mb-16"
              >
                <SelectResourceList type='employment' disabled={ props.filters !== undefined } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="remarks" rules={ rules.remarks } label={ trans(`${langKey}.remarks`) }
                className="da-mb-16"
              >
                <InputTextArea placeholder={ trans(`${langKey}.remarks`) } />
              </Form.Item>
            </Col>
          </Row>

        </Form>
      </Skeleton>
    </ModalComponent>
  )
}

export default CreateDelegation

const rules = {
  delegator_employment_id: [
    { required: true, message: trans(`${langKey}.delegator_employment_id`) },
  ],
  delegatee_employment_id: [
    { required: true, message: trans(`${langKey}.delegatee_employment_id`) },
  ],
  delegation_types: [
    { required: true, message: trans(`${langKey}.delegation_types`) },
  ],

};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}