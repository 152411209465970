// Widget.js
import { Tooltip } from 'antd';
import React from 'react';
import { useDrag } from 'react-dnd';

const Widget = ({ id, text,item }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'WIDGET',
    item: { id },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (


 <div 
 data-toggle="tooltip" data-placement="top" title={'Drag and Drop Widget'}
      ref={drag}
      style={{
        opacity: isDragging ? 0.5 : 1,
        padding: '16px',
        margin: '16px',
        backgroundColor: 'lightblue',
        cursor: 'move',
       // width:'20%'
       
      }}
    >
      <Tooltip title={item.description}>      {item.name}</Tooltip>
  
    </div>
  );
};

export default Widget;
