
import React, { useEffect, useState } from "react";
import { Form, Input, message, Modal, Skeleton, Row, Col } from "antd";
import { SaveButton, CancelButton, ModalComponent, trans, SelectWithSearch, UploadFile, UploadButton, InputDate, SelectResourceList } from "@comps/components"
import { makeRequest, makeRequestStateless, getErrorProps } from "@utils/helpers";
import { createAssetReq, getAssetTypesReq, getAssetsByTypeReq } from "../requests";
import { Commons, Const } from "../../constants";
import { createSelectList } from '@comps/commonFunctions';
const { TextArea } = Input;
const langKey = Const["asset"].lngKey

const formName = "createAsset";

const CreateAsset = (props) => {

  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [assetTypes, setAssetTypes] = useState([]);
  const [assets, setAssets] = useState(false);
  const [saving, setsaving] = useState(false);
  const [form] = Form.useForm()

  useEffect(() => {
    makeRequestStateless(getAssetTypesReq, false, ongetAssetTypes, false)
    form.setFieldValue('employment_id', props.filters !== undefined ? props.filters.replace('employments_|', '') : null)
    // eslint-disable-next-line
  }, []);
  const ongetAssetTypes = (response) => {
    setAssetTypes(createSelectList(response))
  }

  const onSubmit = (data) => {
    const payload = { employment_asset: { ...data, issue_date: data.issue_date.format('YYYY-MM-DD') }, filters: props.filters }
    makeRequest(setLoader, createAssetReq, payload, onSuccess, onError);
    setsaving(true);
  }

  const onSuccess = (data, res) => {

    message.success("Asset Saved successfully");
    props.onCompleted(data.employment_assets.data);
  }

  const onError = (err, res) => {

    setsaving(false)
    message.error(res.response.data.message);
    //setErrors(errorList);
  }
  //-----------on changes-----------
  const onChangeAssetType = (id) => {
    makeRequestStateless(getAssetsByTypeReq, id, ongetAssets, false)
  }
  const ongetAssets = (response) => {
    response ? setAssets(createSelectList(response)) : setAssets(createSelectList(false));
  }
  //============uploading file============
  const [fileList, setFileList] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const handleCancel = () => setPreviewOpen(false);
  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };
  const onPreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };
  // ------------------------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------
  const footer = [
    <SaveButton loading={ saving } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];
  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } width={ 1000 } maskClosable={ false }
      mainTitle={ trans(Commons.create) } subTitle={ trans(`${langKey}.asset`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Skeleton loading={ false }>
        <Form { ...formLayout } name={ formName } onFinish={ onSubmit } form={ form }>
          <Row gutter={ 20 } scrollToFirstError>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="employment_id" label={ trans(`${langKey}.employment_id`) } rules={ rules.employment_id } className="da-mb-16"
                { ...getErrorProps(errors['employment_id']) }
              >
                <SelectResourceList type='employment' disabled={ props.filters !== undefined } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="asset_type_id" rules={ rules.asset_type_id } label={ trans(`${langKey}.asset_type_id`) }
                className="da-mb-16"
                { ...getErrorProps(errors['asset_type_id']) } size="small"
              >
                <SelectWithSearch
                  onChange={ onChangeAssetType }
                  placeholder={ trans(`${langKey}.asset_type_id`) }
                  options={ assetTypes }
                />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="asset_id" rules={ rules.asset_id } label={ trans(`${langKey}.asset_id`) }
                className="da-mb-16"
                { ...getErrorProps(errors['asset_id']) } size="small"
              >
                <SelectWithSearch
                  disabled={ assets ? false : true }
                  placeholder={ trans(`${langKey}.asset_id`) }
                  options={ assets }
                />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="issue_date" rules={ rules.issue_date } label={ trans(`${langKey}.issue_date`) }
                className="da-mb-16"
                { ...getErrorProps(errors['issue_date']) } size="small"
              >
                <InputDate format='YYYY-MM-DD' />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="description" rules={ rules.description } label={ trans(`${langKey}.description`) }
                className="da-mb-16"
                { ...getErrorProps(errors['description']) } size="small"
              >
                <TextArea placeholder="Description" />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item label={ trans(`${langKey}.attachment`) } name="attachment" >
                <UploadFile
                  accept=".jpg,.png,.jpeg" listType="picture-card"
                  fileList={ fileList }
                  onChange={ onChange }
                  onPreview={ onPreview }
                  multiple={ false }
                  beforeUpload={ (file) => {
                    return false;
                  } }
                >
                  { fileList.length === 1 ? null : <UploadButton /> }
                </UploadFile>
              </Form.Item>
            </Col>


          </Row>

        </Form>
      </Skeleton>

      <Modal open={ previewOpen } title={ previewTitle } footer={ null } onCancel={ handleCancel }>
        <img
          alt="example"
          style={ {
            width: '100%',
          } }
          src={ previewImage }
        />
      </Modal>
    </ModalComponent>

  )
}

export default CreateAsset

const rules = {
  employment_id: [
    { required: true, message: trans(`${langKey}.employment_id.req`) },
  ],
  asset_type_id: [
    { required: true, message: trans(`${langKey}.asset_type_id.req`) },
  ],
  asset_id: [
    { required: true, message: trans(`${langKey}.asset_id.req`) },
  ],
  issue_date: [
    { required: true, message: trans(`${langKey}.issue_date.req`) },
  ],
};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}


