const employeeKey = "endOfEmployment";
const baseRoute = "/eoemanagement/endofemployment";

export const Const = {
  EOEmployment: {
    lngKey: `${employeeKey}.EOEmployment`,
    route: `${baseRoute}/index`,
  },
  reason: {
    lngKey: `${employeeKey}.reason`,
    route: `/eoemanagement/settings/reasons`,
  },
  checklist: {
    lngKey: `${employeeKey}.checklist`,
    route: `/eoemanagement/settings/checklist`,
  },
  questions: {
    lngKey: `${employeeKey}.questions`,
    route: `/eoemanagement/settings/questions`,
  },

};

const cmn = "common";
export const Commons = {
  create: `${cmn}.create`,
  update: `${cmn}.update`,
  view: `${cmn}.view`,
  name: `${cmn}.name`,
  code: `${cmn}.code`,
  location: `${cmn}.location`,
  branch: `${cmn}.branch`,
  department: `${cmn}.department`,
  designation: `${cmn}.designation`,
  joiningDate: `${cmn}.joiningDate`,
  grade: `${cmn}.grade`,
  ProfileCompletion: `${cmn}.ProfileCompletion`,
  actions: `${cmn}.actions`,
  is_active: `${cmn}.is_active`,
  status: `${cmn}.status`,
  description: `${cmn}.description`,
  shortname: `${cmn}.shortname`,
  country: `${cmn}.country`,
};
