import { useState, useEffect } from "react";
import {
  HeaderComponent,
  BodyComponent,
  TableComponent,
  ActionComponent,
  CreateButton,RefreshButton,
  trans,
  FilterComponent,
  FilterButton,
  EmployeeWithProfile,
  PaginationComponent
} from "@comps/components";
import { makeRequest } from "@utils/helpers";
import { checkPermissions } from '@comps/commonFunctions';
import { getAllContactsReq, deleteContactReq, getFilterReq } from "./requests";
import { Commons, Const } from "../constants";
import CreateContact from './components/CreateContact';
import UpdateContact from "./components/UpdateContact";
import { message } from "antd";

// ==================================
//   Page Breadcrumbs Start
// ==================================

const langKey = Const["contact"].lngKey

const pageConfig = {
  headers: {
    title: trans(`${langKey}.moduleName`),
    breadcrumb: [
      {
        name: trans(`${langKey}.moduleName`),
        path: Const["contact"].route,
      },
    ],
  },
};

// ==================================
//   Page Breadcrumbs End
// ==================================

const IndexContacts = (props) => {

  const [totalRecords, setTotalRecords] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [loader, setLoader] = useState(false);
  const [childComponent, setChildComponent] = useState(null);
  const [filters, setFilters] = useState(props.filters);
  //start pagination states
  const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState({
    "total": 0,
    "count": 0,
    "per_page": 0,
    "current_page": 0,
    "total_pages": 0,
    "links": {}
  });
  //end pagination states

  // ==================================
  //   Use States End
  // ==================================

  // ================================== UpdateLocation
  //   Table Data End
  // ==================================

  const columns = [
    {
      key: "full_name",
      title: trans(`${langKey}.select.employee`),
      render: (record) => {
        return <EmployeeWithProfile record={ record } />
      },
    },
    {
      key: "name",
      title: trans(`${langKey}.name`),
      dataIndex: "name",
    },
    {
      key: "relationship",
      title: trans(`${langKey}.select.relationship`),
      dataIndex: "relationship",
    },
    {
      key: "address",
      title: trans(`${langKey}.address`),
      dataIndex: "address",
    },
    {
      key: "mobile_no",
      title: trans(`${langKey}.mobile_no`),
      dataIndex: "mobile_no",
    },
    {
      key: "contact_type",
      title: trans(`${langKey}.select.contact_type`),
      dataIndex: "contact_type",
    },
    {
      key: trans(Commons.actions),
      title: trans(Commons.actions),
      render: (record) => (
        <ActionComponent
          each={ record } modkey={props.modkey}
          onDelete={ onDelete }
          onEdit={ onEdit }

        ></ActionComponent>
      ),
    },
  ];

  // ==================================
  //   Table Data End
  // ==================================

  useEffect(() => {
    getAllData();
    // eslint-disable-next-line
  }, [filters, currentPage]);

  const getAllData = () => {
    const payload = {
      page: currentPage,
      filters: filters
    }
    makeRequest(setLoader, getAllContactsReq, payload, onSuccess, null);
  };

  const onSuccess = (response) => {
    setPagination(response.employee_contacts.meta.pagination);
    setDataSource(response.employee_contacts.data);
  };

  const onEdit = (record) => {
    setChildComponent(
      <UpdateContact onCompleted={ onCompleted } record={ record } disabled={ false } filters={ props.filters } />
    );
  };

  const onView = (record) => {
    setChildComponent(
      <UpdateContact onCompleted={ onCompleted } record={ record } disabled={ true } />
    );
  };

  const onDelete = (record) => {
    makeRequest(setLoader, deleteContactReq, record.id, onDeleted, onError);
  };
  const onDeleted = (data, res) => {
    onSuccess(data)
    message.success("Contacts Deleted successfully");
    setDataSource(data.employee_contacts.data);
  };
  const onError = (err, res) => {
    message.error(res.response.data.message);
    //setErrors(errorList);
  }

  // Create component modal
  const onCreate = () => {
    setChildComponent(<CreateContact onCompleted={ onCompleted } onSuccess={ onSuccess } filters={ props.filters } />);
  };

  const onCompleted = (data) => {
    if (data.length) {
      setDataSource(data);
    }
    setChildComponent(null);
  };

  const handleTableChange = (page, fil, sorter) => {
    let payload = {
      ...pagination,
      current: page.current,
      pageSize: page.pageSize,
      sortName: sorter.field || "id",
      sortType: sorter.order === "ascend" ? "asc" : "desc",
    };
    setPagination(payload);
  };

  //---------------filters-----------------------
  const [filerComponent, setFilterComponent] = useState(null);

  const onOpenFilter = () => {

    setFilterComponent(<FilterComponent filters={ filterFields } modkey={props.modkey} selected={ filters } api={ getFilterReq } setCurrentPage={ setCurrentPage } onFilter={ setFilters } onCloseFilter={ onCloseFilter } moduleName="address"
    />
    )
  }

  const onCloseFilter = () => {
    setFilterComponent(null);

  }


  //----end filter--------------------

  return (
    <>
      { filerComponent }
      { childComponent }
      <HeaderComponent headers={ pageConfig.headers } modkey={props.modkey}>
        { checkPermissions('profile_employeeContact') &&
<>
<RefreshButton onClick={ getAllData } /> <CreateButton onClick={ onCreate } />
</>
        }
        { props.filters === undefined && <>
          <FilterButton onClick={ onOpenFilter } /> </> }
      </HeaderComponent>
      <BodyComponent>
        <TableComponent modkey={props.modkey}
          loader={ loader }
          columns={ columns }
          dataSource={ dataSource }
          pagination={ { ...pagination, total: totalRecords } }
          onChange={ handleTableChange }
        />
      </BodyComponent>
      <PaginationComponent pagination={ !props.filters ? pagination : undefined } setCurrentPage={ setCurrentPage } currentPage={ currentPage } display={ !props.filters } />
    </>
  );
};

export default IndexContacts;

const filterFields = [
  {
    type: "select",
    placeholder: trans(`${langKey}.select.employee`),
    key: "employments",
    data_key: "supporting_data.employees",
    //is_resource: true
    resource_type: 'employee'
  },
  {
    type: "select",
    placeholder: trans(`${langKey}.select.contact_type`),
    key: "contact_type",
    data_key: "supporting_data.contact_type",
    //is_resource: true
  },
  {
    type: "text",
    placeholder: "Name",
    key: "name",
  },
  {
    type: "text",
    placeholder: "Relation",
    key: "relation_name",
  },

]