import React, { useEffect, useState } from "react";
import { Form, Skeleton, message, Row, Col } from "antd";
import { SaveButton, InputText, CancelButton, ModalComponent, trans, SelectWithSearch, SelectResourceList } from "@comps/components"
import { makeRequest, makeRequestStateless, getErrorProps } from "@utils/helpers";
import { updateContactReq, getAllEmployeesReq, getRelatedDataReq } from "../requests";
import { Commons, Const } from "../../constants";
import { createSelectList, createRelationshipsList } from '@comps/commonFunctions';

const langKey = Const["contact"].lngKey

const formName = "updateContact";

const UpdateContact = (props) => {

  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [relationships, setRelationships] = useState([]);
  const [saving, setsaving] = useState(false);
  const [loading, setloading] = useState(true);
  const [form] = Form.useForm()
  useEffect(() => {
    getAndSetRecord()
    makeRequestStateless(getRelatedDataReq, false, ongetRelatedData, false)
    makeRequestStateless(getAllEmployeesReq, false, ongetAllEmployees, false)
    // eslint-disable-next-line
  }, []);

  const getAndSetRecord = () => {
    if (!props.record) {
      return;
    }
    form.setFieldsValue(props.record)
  }
  const ongetRelatedData = (response) => {
    setRelationships(createRelationshipsList(response.relationships))
  }
  const ongetAllEmployees = (response) => {
    setloading(false)
  }

  const onSubmit = (data) => {
    setsaving(true)
    const payload = { employee_contact: data, filters: props.filters }
    payload.id = props.record.id;
    makeRequest(setLoader, updateContactReq, payload, onSuccess, onError);
  }

  const onSuccess = (data, res) => {
    message.success("Contact Updated successfully");
    props.onCompleted(data.employee_contacts.data);

  }

  const onError = (err, res) => {
    setsaving(false)
    message.error(res.response.data.message);
    //setErrors(errorList);
  }
  //-----------on changes------------

  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [
    <SaveButton loading={ saving } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } width={ 1000 } maskClosable={ false }
      mainTitle={ trans(Commons[props.disabled ? 'view' : 'update']) } subTitle={ trans(`${langKey}.contact`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Skeleton loading={ loading }>
        <Form { ...formLayout } form={ form } name={ formName } onFinish={ onSubmit } scrollToFirstError>
          <Row gutter={ 30 }>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="employee_id" label={ trans(`${langKey}.select.employee`) } rules={ rules.employee_id } className="da-mb-16"
                { ...getErrorProps(errors['employee_id']) }
              >
                <SelectResourceList type='employee'
                  disabled={ props.filters !== undefined }
                  value={ props.record.employee_id }
                />
              </Form.Item>
            </Col>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="contact_type" label={ trans(`${langKey}.select.contact_type`) } rules={ rules.contact_type } className="da-mb-16"
                { ...getErrorProps(errors['contact_type']) }
              >
                <SelectWithSearch
                  placeholder={ trans(`${langKey}.select.contact_type`) }
                  options={ [{
                    'value': "Primary",
                    "label": "Primary"
                  },
                  {
                    "value": "Secondary",
                    "label": "Secondary"
                  }

                  ] }
                />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="name" rules={ rules.name } label={ trans(`${langKey}.name`) }
                className="da-mb-16"
                { ...getErrorProps(errors['name']) } size="small"

              >
                <InputText placeholder="contact" />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="relationship" label={ trans(`${langKey}.select.relationship`) } rules={ rules.relationship } className="da-mb-16"
                { ...getErrorProps(errors['relationship']) }
              >
                <SelectWithSearch

                  placeholder={ trans(`${langKey}.select.relationship`) }
                  options={ relationships }
                />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="landline_no" rules={ rules.landline_no } label={ trans(`${langKey}.landline_no`) }
                className="da-mb-16"
                { ...getErrorProps(errors['landline_no']) } size="small"

              >
                <InputText placeholder="landline_no" />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="mobile_no" rules={ rules.mobile_no } label={ trans(`${langKey}.mobile_no`) }
                className="da-mb-16"
                { ...getErrorProps(errors['mobile_no']) } size="small"

              >
                <InputText placeholder="mobile_no" />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="email" rules={ rules.email } label={ trans(`${langKey}.email`) }
                className="da-mb-16"
                { ...getErrorProps(errors['email']) } size="small"

              >
                <InputText placeholder="email" type="email" />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="address" rules={ rules.address } label={ trans(`${langKey}.address`) }
                className="da-mb-16"
                { ...getErrorProps(errors['address']) } size="small"

              >
                <InputText placeholder="Address" />
              </Form.Item>
            </Col>
          </Row>

        </Form>
      </Skeleton>

    </ModalComponent>
  )
}

export default UpdateContact

const rules = {
  name: [
    { required: true, message: trans(`${langKey}.name.req`) },
  ],
  employee_id: [
    { required: true, message: trans(`${langKey}.employee.req`) },
  ],
  relationship: [
    { required: true, message: trans(`${langKey}.relationship.req`) },
  ],
  mobile_no: [
    { required: true, message: trans(`${langKey}.mobile_no.req`) }, { max: 13, min: 10 },
  ],

  contact_type: [
    { required: true, message: trans(`${langKey}.contact_type.req`) },
  ],
  address: [
    { required: true, message: trans(`${langKey}.address.req`) },
    {
      max: 1000, // Adjust the maximum number of characters as needed
      message: 'Max characters limit 1000 exceeded',
    },
  ],
};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}