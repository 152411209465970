import { useState, useEffect } from "react";
import {
  BodyComponent,
  TableComponent,
  ActionComponent,
  trans,
  HeaderComponent,
  EmployeeWithProfile,
  PaginationComponent
} from "@comps/components";
import { makeRequest } from "@utils/helpers";
import { Commons, Const, colors } from "../constants";
import { Badge, Space, message, Tooltip } from 'antd';
import { FilterComponent, FilterButton } from "@comps/components";
import { getAllDataReq, getFilterReq } from "./requests";
import { useSelector } from "react-redux";
import moment from "moment";
import AdjustRepayment from "./components/AdjustRepayment";
import ScheduleRepayment from "./components/ScheduleRepayment";
import { ClockCircleOutlined, PieChartOutlined } from "@ant-design/icons";
import { getOnlyRequestFilters } from "../../common/commonFilterFields";

const langKey = Const["lendRepayment"].lngKey;
const pageConfig = {
  headers: {
    title: trans(`${langKey}.moduleName`),
    breadcrumb: [
      {
        name: trans(`${langKey}.moduleName`),
        path: Const["lendRepayment"].route
      }
    ]
  }
}

const IndexLendRepayments = (props) => {
  const authUser = useSelector((state) => state.auth.authUser);
  const [totalRecords, setTotalRecords] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [loader, setLoader] = useState(false);
  const [childComponent, setChildComponent] = useState(null);
  const [filerComponent, setFilterComponent] = useState(null);
  const [filters, setFilters] = useState('');

  const [response, setResponse] = useState({})
  //start pagination states
  const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState({
    "total": 0,
    "count": 0,
    "per_page": 0,
    "current_page": 0,
    "total_pages": 0,
    "links": {}
  });
  //end pagination states

  // ==================================
  //   Use States End
  // ==================================

  // ==================================
  //   Table Data End
  // ==================================

  const columns = [
    {
      key: 'code',
      title: trans(Commons.code),
      dataIndex: "code",
    },
    {
      key: 'employment_id',
      title: trans(`${langKey}.employment_id`),
      render: (record) => {
        return <EmployeeWithProfile record={ record.requestor } />
      },
    },
    {
      key: 'department_id',
      title: trans(`${langKey}.department_name`),
      render: (record) => {
        return record.requestor.department_name;
      }
    },

    {
      key: 'date',
      title: trans(`${langKey}.date`),
      render: (record) => {
        return record.repayment_date ? moment(record.repayment_date).format('YYYY-MM-DD') : null
      }
    },
    {
      key: 'status',
      title: trans(`${langKey}.status`),
      render: (record) => {
        return <Badge
          count={ record.status ? record.status : 'none' }
          style={ { background: colors[record.status_class], color: colors[record.status_class + '-color'] } }
        />
      }
    },

    {
      key: trans(Commons.actions),
      title: trans(Commons.actions),
      render: (record) => (
        <ActionComponent

        >
          <Space>
            <Tooltip title="Adjust" color="black"><PieChartOutlined className="icon-style da-text-color-warning-1" style={ { color: "#1677ff" } } onClick={ () => onAdjust(record) } /></Tooltip>
            <Tooltip title="Schedule" color="black"><ClockCircleOutlined className="icon-style da-text-color-info-1" style={ { color: "#1677ff" } } onClick={ () => onSchedule(record) } /></Tooltip>
          </Space>

        </ActionComponent>
      ),
    },
  ];

  // ==================================
  //   Table Data End
  // ==================================

  useEffect(() => {
    getAllData();
    // eslint-disable-next-line
  }, [filters, currentPage]);

  const getAllData = () => {
    const payload = {
      page: currentPage,
      filters: filters
    }
    makeRequest(setLoader, getAllDataReq, payload, onSuccess, onGetAllDataError);
  };

  const onSuccess = (response) => {
    setPagination(response.lends);
    setDataSource(response.lends.data);
    setResponse(response)

  };
  const onGetAllDataError = (err, res) => {
    message.error(res.response.data.message);
  }
  const onAdjust = (record) => {
    setChildComponent(
      <AdjustRepayment onCompleted={ onCompleted } record={ record } disabled={ false } action='adjust' />
    );
  };

  const onSchedule = (record) => {
    setChildComponent(
      <ScheduleRepayment onCompleted={ onCompleted } record={ record } disabled={ false } action='adjust' />
    );
  };

  const onCompleted = (data) => {
    if (data.length) {
      setDataSource(data);
    }
    setChildComponent(null);
  };

  const handleTableChange = (page, fil, sorter) => {
    let payload = {
      ...pagination,
      current: page.current,
      pageSize: page.pageSize,
      sortName: sorter.field || "id",
      sortType: sorter.order === "ascend" ? "asc" : "desc",
    };
    setPagination(payload);
  };

  //---------------filters-----------------------
  const onOpenFilter = () => {
    setFilterComponent(<FilterComponent filters={ filterFields } modkey={props.modkey} selected={ filters } api={ getFilterReq } setCurrentPage={setCurrentPage} onFilter={ setFilters } onCloseFilter={ onCloseFilter }
    />
    )
  }

  const onCloseFilter = () => {
    setFilterComponent(null);
  }

  //----end filter--------------------

  return (
    <>
      { filerComponent }
      { childComponent }
      <BodyComponent>
        <HeaderComponent headers={ pageConfig.headers } modkey={props.modkey}>
          <FilterButton onClick={ onOpenFilter } />
        </HeaderComponent>
        <TableComponent modkey={props.modkey}
          loader={ loader }
          columns={ columns }
          dataSource={ dataSource }
          pagination={ { ...pagination, total: totalRecords } }
          onChange={ handleTableChange }
        />
      </BodyComponent>
      <PaginationComponent pagination={ pagination } modkey={props.modkey} setCurrentPage={ setCurrentPage } currentPage={ currentPage } />
    </>
  );
};

export default IndexLendRepayments;
const filterFields = getOnlyRequestFilters('')

