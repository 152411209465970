import { useState, useEffect } from "react";
import {
  HeaderComponent,
  BodyComponent,
  TableComponent,
  ActionComponent,
  CreateButton,RefreshButton,
  trans,
  FilterComponent,
  FilterButton,
  EmployeeWithProfile,
  PaginationComponent, ImportButton, DownloadButton
} from "@comps/components";
import { checkPermissions } from '@comps/commonFunctions';
import { makeRequest, makeRequestStateless, handleDownloadFile } from "@utils/helpers";
import { getAllBanksReq, deleteBankReq, getFilterReq } from "./requests";
import { Commons, Const } from "../constants";
import CreateBank from './components/CreateBank';
import { message } from "antd";
import UpdateBank from "./components/UpdateBank";
import moment from "moment";
import ImportSample from "../../common/ImportSample";
import { downloadSampleReq } from "../../common/requests";
// ==================================
//   Page Breadcrumbs Start
// ==================================

const langKey = Const["bank"].lngKey

const pageConfig = {
  headers: {
    title: trans(`${langKey}.moduleName`),
    breadcrumb: [
      {
        name: trans(`${langKey}.moduleName`),
        path: Const["bank"].route,
      },
    ],
  },
};

// ==================================
//   Page Breadcrumbs End
// ==================================

const IndexBanks = (props) => {
  const [totalRecords, setTotalRecords] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [loader, setLoader] = useState(false);
  const [childComponent, setChildComponent] = useState(null);
  const [filters, setFilters] = useState(props.filters);
  //start pagination states
  const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState({
    "total": 0,
    "count": 0,
    "per_page": 0,
    "current_page": 0,
    "total_pages": 0,
    "links": {}
  });
  //end pagination states

  // ==================================
  //   Use States End
  // ==================================

  // ================================== UpdateLocation
  //   Table Data End
  // ==================================

  const columns = [
    {
      key: "full_name",
      title: trans(`${langKey}.employee`),
      render: (record) => {
        return <EmployeeWithProfile record={ record.employee_row } />
      },
    },
    {
      key: "bank_name",
      title: trans(`${langKey}.bank_name`),
      dataIndex: "bank_name",
    },
    {
      key: "bank_branch",
      title: trans(`${langKey}.bank_branch`),
      dataIndex: "bank_branch",
    },
    {
      key: "bank_branch_code",
      title: trans(`${langKey}.bank_branch_code`),
      dataIndex: "bank_branch_code",
    },
    {
      key: "bank_account_title",
      title: trans(`${langKey}.bank_account_title`),
      dataIndex: "bank_account_title",
    },
    {
      key: "bank_account_no",
      title: trans(`${langKey}.bank_account_no`),
      dataIndex: "bank_account_no",
    },
    {
      key: "bank_effective_date",
      title: trans(`${langKey}.bank_effective_date`),
      render: (record) => {
        return moment(record.bank_effective_date).format('YYYY-MM-DD')
      },
    },

    {
      key: trans(Commons.actions),
      title: trans(Commons.actions),
      render: (record) => (
        <ActionComponent
          each={ record } modkey={props.modkey}
          onDelete={ onDelete }
          onEdit={ onEdit }
          onView={ onView }
        ></ActionComponent>
      ),
    },
  ];

  // ==================================
  //   Table Data End
  // ==================================

  useEffect(() => {
    getAllData();
    // eslint-disable-next-line
  }, [filters, currentPage]);

  const getAllData = () => {
    const payload = {
      page: currentPage,
      filters: filters
    }
    makeRequest(setLoader, getAllBanksReq, payload, onSuccess, null);
  };

  const onSuccess = (response) => {
    setPagination(response.employee_banks);
    //response.employee_banks.data.employee_row.full_name = response.employee_banks.data.full_name
    setDataSource(response.employee_banks.data);
  }
  const onEdit = (record) => {
    setChildComponent(
      <UpdateBank onCompleted={ onCompleted } record={ record } disabled={ false } filters={ props.filters } />
    );
  };

  const onView = (record) => {
    setChildComponent(
      <UpdateBank onCompleted={ onCompleted } record={ record } disabled={ true } />
    );
  };

  const onDelete = (record) => {
    makeRequest(setLoader, deleteBankReq, record.id, onDeleted, onError);
  };
  const onDeleted = (data, res) => {

    message.success("Bank Deleted successfully");
    setDataSource(data.employee_banks.data);
    setPagination(data.employee_banks);
  };

  const onError = (err, res) => {
    message.error(res.response.data.message);
    //setErrors(errorList);
  }
  // Create component modal
  const onCreate = () => {
    setChildComponent(<CreateBank onCompleted={ onCompleted } onSuccess={ onSuccess } filters={ props.filters } />);
  };

  const onCompleted = (data) => {
    if (data.length) {
      setDataSource(data);
    }
    setChildComponent(null);
  };

  const handleTableChange = (page, fil, sorter) => {
    let payload = {
      ...pagination,
      current: page.current,
      pageSize: page.pageSize,
      sortName: sorter.field || "id",
      sortType: sorter.order === "ascend" ? "asc" : "desc",
    };
    setPagination(payload);
  };
  //---------------filters-----------------------
  const [filerComponent, setFilterComponent] = useState(null);

  const onOpenFilter = () => {

    setFilterComponent(<FilterComponent filters={ filterFields } modkey={props.modkey} selected={ filters } api={ getFilterReq } setCurrentPage={ setCurrentPage } onFilter={ setFilters } onCloseFilter={ onCloseFilter } moduleName="address"
    />
    )
  }

  const onCloseFilter = () => {
    setFilterComponent(null);

  }
  //download sample log
  const onDownloadSample = () => {
    makeRequestStateless(downloadSampleReq, 'employee-banks/downloadSample', onSuccessDownloadSample, onSuccessDownloadSample);
  }
  const onSuccessDownloadSample = (res, err) => {
    if (res.code === undefined) {
      // Extract file extension
      const fileExtension = res.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ? 'xlsx' : 'pdf'
      const fileName = `bank_import_sample.${fileExtension}`
      handleDownloadFile(fileName, res)
    }
    else {
      message.error(res.message)
    }
  }

  //----end filter--------------------
  return (
    <> { filerComponent }
      { childComponent }
      <HeaderComponent headers={ pageConfig.headers } modkey={props.modkey}>
        { checkPermissions('profile_employeeBank') &&
         <>
          <RefreshButton onClick={ getAllData } /> <CreateButton onClick={ onCreate } />
         </>
        }

        { !props.filters && <>
          <DownloadButton onClick={ onDownloadSample } />
          <ImportButton onClick={ () => setChildComponent(<ImportSample onSuccess={getAllData} api="employee-banks/import" module="Employee Bank" onCompleted={ () => setChildComponent(null) } />) } />
          <FilterButton onClick={ onOpenFilter } /> </> }
      </HeaderComponent>
      <BodyComponent>
        <TableComponent modkey={props.modkey}
          loader={ loader }
          columns={ columns }
          dataSource={ dataSource }
          pagination={ { ...pagination, total: totalRecords } }
          onChange={ handleTableChange }
        />
      </BodyComponent>
      <PaginationComponent pagination={ !props.filters ? pagination : undefined } setCurrentPage={ setCurrentPage } currentPage={ currentPage } display={ !props.filters } />

    </>
  );
};

export default IndexBanks;


const filterFields = [
  {
    type: "select",
    placeholder: trans(`${langKey}.employee`),
    key: "employments",
    data_key: "supporting_data.employees",
    resource_type: 'employee'
    //is_resource: true
  },
  {
    type: "text",
    placeholder: "Bank Name",
    key: "bank_name",
  },
  {
    type: "text",
    placeholder: "Bank Branch",
    key: "bank_branch",
  },
  {
    type: "text",
    placeholder: "Bank Branch Code",
    key: "bank_branch_code",
  },
  {
    type: "text",
    placeholder: "Account Title",
    key: "bank_account_title",
  },
  {
    type: "text",
    placeholder: "Account No",
    key: "bank_account_number",
  },

]