import { Form, Row, Col } from "antd"
import { useEffect, useState } from "react"
import { Commons, Const } from "./constants";
import { SaveButton, InputText, CancelButton, ModalComponent, trans, SelectWithSearch, AddNewButton } from "@comps/components"
import { createSelectList } from '@comps/commonFunctions';
import CreateEncashmentType from '../policyManagement/settings/encashmentTypes/components/CreateEncashmentType';
import CreateDeductionType from '../policyManagement/settings/deductionTypes/components/CreateDeductionType';


const langKey = Const["policySlab"].lngKey
const UpdatePolicySlab = (props) => {

  const [deductionTypes, setDeductionTypes] = useState([])
  const [repeats, setRepeats] = useState([])
  const [deductionTypesAll, setDeductionTypesAll] = useState([])
  const [encashmentTypesAll, setEncashmentTypesAll] = useState([])
  const [encashmentTypes, setEncashmentTypes] = useState([])
  const [slabs, setSlabs] = useState([])
  const [fromSalary, setFromSalary] = useState(false)
  const [form] = Form.useForm()
  const [childComponent, setChildComponent] = useState(null);
  const [fallBackTypes,setFallbackTypes]=useState([]);

  useEffect(() => {
    getRelatedData()
    // eslint-disable-next-line
  }, []);

  const getRelatedData = () => {
    let fallbacks=[];
    form.setFieldsValue(props.record)
    if (props.policyType === 'overtime' || props.policyType === 'cpl') {
      setEncashmentTypes(createSelectList(props.allData.encashment_types))
      setEncashmentTypesAll(props.allData.encashment_types)
      props.allData.encashment_types.forEach(each => {
        if (form.getFieldValue('encashment_type_id') === each.id) {
          setFromSalary(each.from_salary)
        }
        if(each.from_salary){
          fallbacks.push(each)
        }
      })
    }
    else {
      setDeductionTypesAll(props.allData.deduction_types)
      setDeductionTypes(createSelectList(props.allData.deduction_types))
      setRepeats(createSelectList(props.allData.repeats))
      props.allData.deduction_types.forEach(each => {
        if (form.getFieldValue('deduction_type_id') === each.id) {
          setFromSalary(each.from_salary)
        }
        if(each.from_salary){
          fallbacks.push(each)
        }
      })
    }
    setFallbackTypes(createSelectList(fallbacks));
  }

  const onChangeDeductionType = (id) => {
    let fallbacks=[];
    if (props.policyType === 'overtime' || props.policyType === 'cpl') {
      props.allData.encashment_types.forEach(each => {
        if (each.id === id) {
          setFromSalary(each.from_salary)
        }
        if(each.from_salary){
          fallbacks.push(each)
        }
      })

    }
    else {
      props.allData.deduction_types.forEach(each => {
        if (each.id === id) {
          setFromSalary(each.from_salary)
        }
        if(each.from_salary){
          fallbacks.push(each)
        }
      })
    }

    setFallbackTypes(createSelectList(fallbacks));
  }
  const onSubmit = (data) => {
    const obj = { ...props.record, ...data }
    props.onCompleted(obj, 'Updated')
  }
  const onAddChild = (field) => {
    if (field === 'encashment_type_id') {
      setChildComponent(<CreateEncashmentType onCompleted={ (data) => onChildCreated(data, field) } />)
    }
    if (field === 'fallback_type_id' || field === 'deduction_type_id') {
      setChildComponent(<CreateDeductionType onCompleted={ (data) => onChildCreated(data, field) } />)
    }

  }
  const onChildCreated = (data, field) => {
    if (data.length) {

      if (field === 'encashment_type_id') {
        setEncashmentTypes(createSelectList(data))
      }
      if (field === 'fallback_type_id' || field === 'deduction_type_id') {
        setDeductionTypes(createSelectList(data))
      }
      form.setFieldValue(field, data[0].id)
    }
    setChildComponent(null);
  }
  const footer = [
    props.disabled ? '' :
      <SaveButton form={ 'createSlab' } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];
  return (

    <ModalComponent top={ 20 } width={ 1000 } maskClosable={ false }
      mainTitle={ trans(Commons[props.disabled ? 'view' : 'update']) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Form { ...formLayout } name={ 'createSlab' } onFinish={ onSubmit } form={ form } disabled={ props.disabled } scrollToFirstError>
        <Row gutter={ 30 }>
          {
            props.fields.map((field) => (
              field.type === 'text' || field.type === 'number' ?
                <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                  <Form.Item name={ field.name } rules={ rules[field.name] } label={ trans(`${langKey}.${field.name}`) }

                  >
                    <InputText type={ field.type } />
                  </Form.Item>
                </Col>
                :
                field.name === 'repeat_id' ?
                <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name={ field.name } rules={ rules[field.name] } label={ trans(`${langKey}.${field.name}`) }
                >
                  <SelectWithSearch options={ field.options } placeholder={ trans(`${langKey}.${field.name}`) } />
                </Form.Item>
              </Col>
                  :
                  field.name === 'deduction_type_id' ?
                    <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                      <AddNewButton text={ field.name } onClick={ () => onAddChild(field.name) } />
                      <Form.Item name={ field.name } rules={ rules[field.name] } label={ trans(`${langKey}.${field.name}`) }
                      >
                        <SelectWithSearch options={ deductionTypes } onChange={ field.name === 'deduction_type_id' || field.name === 'deduction_type_id' ? onChangeDeductionType : null } placeholder={ trans(`${langKey}.${field.name}`) } />
                      </Form.Item>
                    </Col>
                    :
                    field.name === 'encashment_type_id' ?
                      <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                        <AddNewButton text={ field.name } onClick={ () => onAddChild(field.name) } />
                        <Form.Item name={ field.name } rules={ rules[field.name] } label={ trans(`${langKey}.${field.name}`) }
                        >
                          <SelectWithSearch options={ encashmentTypes } onChange={ field.name === 'deduction_type_id' || field.name === 'encashment_type_id' ? onChangeDeductionType : null } placeholder={ trans(`${langKey}.${field.name}`) } />
                        </Form.Item>
                      </Col>
                      :
                      !fromSalary && field.name === 'fallback_type_id' ?
                        <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                          <AddNewButton text={ field.name } onClick={ () => onAddChild(field.name) } />
                          <Form.Item name={ field.name } rules={ rules[field.name] } label={ trans(`${langKey}.${field.name}`) }
                          >
                            <SelectWithSearch options={ fallBackTypes } placeholder={ trans(`${langKey}.${field.name}`) } />
                          </Form.Item>
                        </Col>
                        : ''

            ))
          }
        </Row>

      </Form>
    </ModalComponent>

  )
}
export default UpdatePolicySlab
const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}
const rules = {
  min_times: [
    { required: true, message: trans(`${langKey}.min_times`) },
  ],
  repeat_id: [
    { required: true, message: trans(`${langKey}.repeat_id`) },
  ],
  deduction_type_id: [
    { required: true, message: trans(`${langKey}.deduction_type_id`) },
  ],
  fallback_type_id: [
    { required: true, message: trans(`${langKey}.fallback_type_id`) },
  ],

}

//required props
//!slab.deduction_type.from_salary (fallback type)