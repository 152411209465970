import React, { useEffect, useState } from "react";
import { Form, message, Space, Divider, Row, Col, Skeleton ,Tooltip} from "antd";
import { SaveButton, InputText, InputTextArea, CancelButton, ModalComponent, TableComponent, ButtonComponent, SelectWithSearch, trans } from "@comps/components"
import { makeRequest, makeRequestStateless } from "@utils/helpers";
import { Commons, Const } from "../../constants";
import { getPreviewQueryReq, getReportTypesAndTables, getTableInfoByIdReq, updateDataReq } from "../requests";
import { createSelectList } from '@comps/commonFunctions';
import { PlayArrowOutlined } from "@mui/icons-material";
const langKey = Const["allReports"].lngKey
const formName = "createRawReport";

const UpdateRawReport = (props) => {
  const [loader, setLoader] = useState(false);
  const [saving, setsaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const [reportTypes, setReportTypes] = useState([]);
  const [allTables, setAllTables] = useState('')
  const [tables, setTables] = useState([])
  const [columns, setColumns] = useState([])
  const [queryResultTable, setQueryResultTable] = useState(null)
  const [queryError, setQueryError] = useState('')
  const [selectedTableId, setSelectedTableId] = useState(null)
  const [running, setRunning] = useState(false)
  let [tempQuery, setTempQuery] = useState('')

  const [form] = Form.useForm()
  useEffect(() => {
    getRelatedData()
    setData()
    // eslint-disable-next-line
  }, []);

  const setData = () => {
    setSelectedTableId(props.record.schema_table_id)
    setTempQuery(props.record.query)
    form.setFieldsValue(props.record)
  }
  //get report types and tables
  const getRelatedData = () => {
    makeRequestStateless(getReportTypesAndTables, null, onSuccessRelatedData, null);
  }
  const onSuccessRelatedData = (data) => {

    setReportTypes(createSelectList(data.report_types))
    setAllTables(data.tables)
    const newTablesList = []
    data.tables.forEach(table => (
      newTablesList.push(
        {
          label: table.table_name,
          value: table.id
        }
      )
    ))
    setTables(newTablesList)
    setLoading(false)
  }

  const onSubmit = (data) => {
    const payload = { ...props.record, ...data, valid: false, query: tempQuery, is_raw_query: true, schema_table_id: selectedTableId }
    payload.id = props.record.id
    makeRequest(setLoader, updateDataReq, payload, onSuccess, onError);
    setsaving(true)
  }

  const onSuccess = (data, res) => {
    message.success("Saved Successfully");
    props.onCompleted(data.reports.data);
  }

  const onError = (err, res) => {
    setsaving(false)
    message.error(res.response.data.message);
    //setErrors(errorList);
  }

  //add query
  const addQuery = (tag, action) => {
    if (action === 'adding') {
      tempQuery += tag + ' '
    }
    if (action === 'typing') {

      tempQuery = tag

    }
    setTempQuery(tempQuery)
  }

  const getTableName = (id) => {
    const table = allTables.filter(each => each.id === id)
    return table[0].table_name
  }
  const onSelectTable = (id) => {
    if (id) {
      setSelectedTableId(id)
      tempQuery = tempQuery === '' ? 'select * from ' : tempQuery
      const tableName = getTableName(id)
      tempQuery += tableName

      setTempQuery(tempQuery)
      makeRequestStateless(getTableInfoByIdReq, id, onGetTableInfo, null);
    }
    else {
      setSelectedTableId(null)
    }
  }
  const onGetTableInfo = (data) => {
    setColumns(data.table.columns)
  }

  const runQuery = () => {
    const payload = {
      query: tempQuery
    }
    makeRequestStateless(getPreviewQueryReq, payload, onQuerySuccess, onQueryError);
    setRunning(true)
  }
  const onQuerySuccess = (data) => {
    setRunning(false)
    if (data.error !== undefined && data.error) {
      setQueryError(data.error)
      message.error(data.error)
      setQueryResultTable('')
      return
    }

    setQueryError('')
    //show result in table
    const queryColumns = []
    data.columns.forEach(column => {
      queryColumns.push(
        {
          key: column.column_key,
          title: column.display_name,
          dataIndex: column.column_key
        },
      )
    })
    const dataSource = data.report_data

    setQueryResultTable(

      <TableComponent
        scroll={ { x: 'max-content' } }
        loader={ loader }
        columns={ queryColumns }
        dataSource={ dataSource }
        pagination={ false }
      />

    )
  }

  const onQueryError = (err, res) => {

  }


  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------
  const footer = [
    !props.disabled &&
    <SaveButton loading={ saving } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } width={ 1400 } maskClosable={ false }
      mainTitle={ trans(Commons.update) } subTitle={ trans(`${langKey}.rawReport`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Skeleton loading={ loading }>


        <Form name={ formName } onFinish={ onSubmit } form={ form } disabled={ props.disabled }>
        <Row gutter={ 30 }>
      <Col lg={ 10 } sm={ 24 } xs={ 24 } >
      <Form.Item name="title" rules={ rules.name } label={ trans(`${langKey}.name`) }
          
          >
            <InputText placeholder="Name" />
          </Form.Item>

       
      </Col>
      <Col lg={ 10 } sm={ 24 } xs={ 24 } >
      <Form.Item name="report_type_id" label={ trans(`${langKey}.report_type_id`) } rules={ rules.report_type_id }
         
          >
            <SelectWithSearch
            
              placeholder={ trans(`${langKey}.report_type_id`) }
              options={ reportTypes } />
          </Form.Item>
       
      </Col>
      </Row>
          <Col span={20}>
          <Form.Item name="schema_table_id" label={ trans(`${langKey}.schema_table_id`) }>

<SelectWithSearch

  placeholder={ trans(`${langKey}.schema_table_id`) }
  options={ tables }
  onChange={ onSelectTable }

/>
</Form.Item>
          </Col>
          <Row gutter={20}>
            <Col style={ {
              maxHeight: '200px',
              overflowY: 'scroll',
              
              paddingLeft: ''
            } } span={ 3 } className="border"
            >
              <div>
                <span style={ { marginLeft: '10px' } }>
                <span><b>Columns</b></span>
                  {
                    columns.map(each => (
                      <li>
                        <Space>
                          <ButtonComponent type="success" onClick={ () => addQuery(each.column_name, 'adding') }>{ each.display_name }</ButtonComponent>
                        </Space>
                      </li>
                    ))
                  }
                </span>
              </div>
            </Col>
            <Col span={ 20 }>
              <Form.Item name="query"
               
              >
                <label className="d-none">.</label>
                <InputTextArea value={ tempQuery } onChange={ (e) => addQuery(e.target.value, 'typing') } placeholder="Type Sql Query"
                  style={ {
                    fontFamily: 'monospace', // Set font to monospace for code-like appearance
                    fontSize: '14px', // Adjust font size as needed
                    width: '100%', // Make the textarea take up the full width
                    height: '200px', // Set a fixed height or adjust as needed
                    padding: '10px', // Add some padding for better aesthetics
                    boxSizing: 'border-box', // Include padding and border in the element's total width and height
                    border: '1px solid #ccc', // Add a border for better visual separation
                    borderRadius: '5px', // Optional: Add rounded corners
                  } }
                />
                <div style={ { float: 'right', marginTop: '2px' } }> <Tooltip title="Run Query"><ButtonComponent className="bg-success" icon={ <PlayArrowOutlined /> } onClick={ runQuery } type='primary' loading={ running } /></Tooltip></div>
              </Form.Item>
              
            </Col>
            <Col>
            <span className="text-danger">{ queryError }</span>
        { queryResultTable }
            </Col>
          </Row>
        </Form>
       
      </Skeleton>

    </ModalComponent>
  )
}

export default UpdateRawReport

const rules = {
  name: [
    { required: true, message: trans(`${langKey}.name.required`) },
  ],
  report_type_id: [
    { required: true, message: trans(`${langKey}.report_type_id`) },
  ],
  schema_table_id: [
    { required: true, message: trans(`${langKey}.schema_table_id`) },
  ]
};
