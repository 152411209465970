import { get, del, postFd, put } from "@utils/axios"

const api = "locations"

export const getAllLocationsReq = (payload) => {
  return get(`${api}?page=${payload.page}&filters=${payload.filters}`)
}

export const createLocationReq = (payload) => {
  return postFd(`${api}?`, payload)
}

export const updateLocationReq = (payload) => {
  return put(`${api}/${payload.id}`, payload)
}

export const deleteLocationReq = (id) => {
  return del(`${api}/${id}`)
}

export const getFilterReq = (payload) => {
  return get(`${api}?page=1&filters=${payload}`)
}
