
const EmpShortInfo = (props) => {
  let name = '';

  if (props.record && props.record.emp_code === undefined) {
    name = <small>{ props.record.full_name_code }</small>
  }
  else {
    name = (
      <div style={{
        textAlign:'left'
      }}>
        <p style={{marginBottom:0}}>{ props.record && props.record.full_name }</p>
        <p>{ props.record ? '(' + props.record.emp_code + ')' : '' }</p>
      </div>
    )
  }
  if (props.record && props.record.full_name && props.record.full_name.includes('-')) {
    name = <small>{ props.record.full_name }</small>
  }
  return (
    <>
      { props.record ?
        <div style={{
          display:'flex'
        }}>
          <div>
            <img style={ { borderRadius: '40px', width: 44, height: 44 } } src={ props.record.profile_pic_url !== "images/arnold-avatar.jpg" ? props.record.profile_pic_url : "/images/arnold-avatar.jpg" } alt="" className="me-2" width={ 50 } />
          </div>
          { name }
        </div>
        : ''

      }
    </>
  )
}

export default EmpShortInfo