import React, { useEffect, useState } from "react";
import { Checkbox, Col, Form, Row, Spin, message, Table } from "antd";
import { SaveButton, InputText, CancelButton, ModalComponent, trans } from "@comps/components"
import { makeRequest, makeRequestStateless } from "@utils/helpers";
import { getPermissionsByRoleReq, updateRolePermissionsReq } from "../requests";
import { Commons, Const, Validations } from "../../../constants";
import './../../../style.css'
import './../role-permission.css'

const langKey = Const["roles"].lngKey
const formName = "updateRole";

const UpdateRole = (props) => {
  const [loader, setLoader] = useState(false);
  const [rolePermissions, setRolePermissions] = useState([])
  const [loading, setLoading] = useState(true)
  const [saving, setSaving] = useState(false)
  const [form] = Form.useForm();
  useEffect(() => {
    getPermissions()
    // eslint-disable-next-line
  }, []);

  const getPermissions = () => {
    makeRequestStateless(getPermissionsByRoleReq, props.roleId, onGetPermissions, onErrorGettingPermissions);
  }
  const onErrorGettingPermissions = (err, res) => {
    setLoading(false)
    message.error(res.response.data.message);
  }
  const onGetPermissions = (response) => {
    setRolePermissions(response)
    setLoading(false)
  }

  const onSubmit = (data) => {
    const payload = { permissions: data };
    payload.id = props.record.id
    setSaving(true)
    makeRequest(setLoader, updateRolePermissionsReq, payload, onPermissionsUpdated, onError);
  }

  const onPermissionsUpdated = (data, res) => {
    if (res.code === 200) {
      message.success("Record Updated");
      props.onCompleted(data);
      setSaving(false)
    }
    setSaving(false)
    message.error(res.response.data.message);
  }

  const onError = (err, res) => {
    setSaving(false)
    message.error(res.response.data.message);
  }


  //===========================onchange modulename========================
  // const onChangeModuleCheck = (module, check) => {
  //   if (module.actions !== undefined) {
  //     module.actions.map((action, index) => (
  //       form.setFieldValue(action['role_permission_id'], check)
  //     ))
  //   }
  //   if (module.modules !== undefined && module.modules.length > 0) {
  //     onChangeModuleCheck(module.modules, check)
  //   }
  // }
  //=======================================================================
  const getActionName=(action)=>{
    const str=action.name
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  const columns = [
    {
      title: 'Module',
      dataIndex: 'description',
      key: 'description',
      render: (text, record) => (
        <span style={ { marginLeft: record.parent_id ? '30px' : '', fontWeight: 'bold' } }>
          { `${record.currentIndex}: ${text.replace(/_/g, ' ')}` }
        </span>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (actions, record) => (
        <Row style={ { marginLeft: record.parent_id ? '30px' : '' } }>
          { actions.map((action, key) => (
            <Col span={ 4 } key={ 'colAction' + action.id + key } style={ { display: 'inline-block', marginRight: '10px' } }>
              <Form.Item
                name={ action['role_permission_id'] }
                valuePropName="checked"
                initialValue={ action.enable }
              >
                <Checkbox>{ getActionName(action) }</Checkbox>
              </Form.Item>
            </Col>
          )) }
        </Row>
      ),
    },
  ];

  const getPreview = (module, parentIndex = "") => {
    const data = module.map((each, modIndex) => ({
      key: `row${each.id}${modIndex}`,
      currentIndex: parentIndex ? `${parentIndex}.${modIndex + 1}` : `${modIndex + 1}`,
      description: each.description,
      actions: each.actions,
      modules: each.modules,
      parent_id: each.parent_id,
    }));

    const ExpandIcon = ({ expanded, onExpand, record }) => {
      return (
        <span onClick={ (e) => onExpand(record, e) } className={ record.modules && record.modules.length > 0 ? 'show-icon' : 'hide-icon' }>
          { expanded ? <span>-</span> : <span>+</span> }
        </span>
      );
    };

    return (
      <Table
        columns={ columns }
        dataSource={ data }
        expandable={ {
          expandIcon: ExpandIcon,
          expandRowByClick: true,
          defaultExpandAllRows: false, // Change to false if you want to collapse all rows initially
          expandedRowRender: (record) => (
            <div>
              { record.modules && record.modules.length > 0 && getPreview(record.modules, record.currentIndex) }
              { record.modules.children && record.modules.children.length > 0 && getPreview(record.modules.children, record.currentIndex) }
            </div>
          ),
        } }
        pagination={ false }
      />
    );
  };
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------
  const footer = [
    !props.disabled &&
    <SaveButton loading={ saving } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];
  /*  */
  // ------------------------------------
  // End footer buttons array
  // ------------------------------------
  return (
    <ModalComponent top={ 20 } width={ 1200 } maskClosable={ false }
      mainTitle={ trans(Commons.update) } subTitle={ trans(`${langKey}.moduleName`) }
      open={ true } footer={ footer } onCancel={ () => props.onCompleted(false) } disabled={ props.disabled }>

      <Form { ...formLayout } form={ form } name={ formName } onFinish={ onSubmit } disabled={ props.disabled } className="update-form">

        <Form.Item name="name" rules={ rules.name } label={ trans(Commons.name) }
          className="da-mb-16"

          initialValue={ props.record.name }
        >
          <InputText />
        </Form.Item>

        <Spin spinning={ loading }>
          <h5 className="formlist-title text-center">Permissions</h5>
          {
            getPreview(rolePermissions)
          }
        </Spin>
      </Form>
    </ModalComponent>
  )
}

export default UpdateRole

const rules = {
  name: [
    { required: true, message: trans(Validations.name) },
  ],
};

const formLayout = {
  // layout: "horizontal",
  labelCol: { span: 6 },
  wrapperCol: { span: 14, offset: 0 },
  labelAlign: "left"
}