import { Tabs, Badge } from "antd";
import { useState, useEffect } from "react";

//-----------end use states--------------------

const RequestAsTab = (props) => {
  const onChangeTab = (e) => {
    setCurrentTab(e)
    //props.getAllData(e)
    props.setRequestAs(e)
  };

  const [currentTab, setCurrentTab] = useState();

  useEffect(() => {
    // eslint-disable-next-line
  }, [props]);

  const getTabName = (each) => {
    return (
      <>
        <span style={ { fontSize: '18px' } }>{ 'As ' + each.name }</span> <Badge count={ each.count !== 0 ? each.count : '' } color="#faad14" />
      </>
    )
  }


  return (
    props.setAsFilters.length !== undefined && props.setAsFilters.length > 0 &&
    <>
      <Tabs
        className="border bg-light"
        centered
        tabPosition='top'
        onChange={ onChangeTab }
      >
        { props.setAsFilters && props.setAsFilters.map((each) => (
          <Tabs.TabPane tab={ getTabName(each) } key={ each.id } />
        )) }
      </Tabs>
    </>
  )
}

export default RequestAsTab