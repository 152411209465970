import { useState, useEffect } from "react";
import {
  BodyComponent,
  SelectWithSearch,
  SaveButton,
  trans,
  HeaderComponent,
  CreateButton,RefreshButton, SelectResourceList
} from "@comps/components";
import { makeRequest } from "@utils/helpers";
import { Const } from "../../constants";
import { Popover, Form, message, Skeleton, Row, Col } from 'antd';
import { getDashboardDirectivesReq, createDataReq, getDashboardTypes } from "../requests";
import { createSelectList } from '@comps/commonFunctions';
import { ArrowLeftOutlined, SplitCellsOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Widget from './Widget';
import DroppableBox from './DroppableBox';
import WidgetColumn from "./WidgetColumn";


const langKey = Const["customDashboard"].lngKey;
const formName = "createAttendanceRequest";
const pageConfig = {
  headers: {
    title: trans(`${langKey}.moduleName`),
    breadcrumb: [
      {
        name: trans(`${langKey}.moduleName`),
        path: Const["customDashboard"].route
      }
    ]
  }
}

const CreateCustomDashboard = (props) => {
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const [loader, setLoader] = useState(false);
  const [saving, setSaving] = useState(false)
  const [loading, setLoading] = useState(true)

  const [dashboardTypes, setDashboardTypes] = useState([])
  const [roles, setRoles] = useState([]);
  const [forEmployment, setForEmployment] = useState(false)
  const [widgets, setWidgets] = useState([

  ]);

  const [rows, setRows] = useState([
    {
      columns: [
        {
          width: '100%',
          widgets:[]
        }
      ]
    },
  ]);
 
  useEffect(() => {
    getRelatedData();
    // eslint-disable-next-line
  }, []);

  const getRelatedData = () => {
    makeRequest(setLoader, getDashboardDirectivesReq, null, onGetDirectivesSuccess, onGetDirectiveError);
    makeRequest(setLoader, getDashboardTypes, null, onGetDashboardTypes, onGetDashboardTypesError);
  }

  const onGetDirectivesSuccess = (response) => {
    setRoles(createSelectList(response.roles));

    setWidgets(response.dashboard_directives);
    setLoading(false)
  };
  const onGetDashboardTypes = (response) => {
    const result = Object.values(response.resources.data).map(item => ({
      label: item.name,
      value: item.id
    }));
    setDashboardTypes(result)
    setLoading(false)
  };
  const onGetDashboardTypesError = (response) => {
    setLoading(false)
  };

  const onGetDirectiveError = (err, res) => {
    message.error(res.response.data.message);
  }

  const formatRowsForPayload = (rows) => {
    return {
      rows: rows.map((box, rowIndex) => ({
        order: rowIndex,
        columns: box.columns.map(column => {
          const length = getColumnLength(column.width); // Convert width to length
          return {
            length: length,
            dashboard_directive_id: column.widgets.length>0?column.widgets[0] : null // Get widget ID if exists, else null
          };
        })
      }))
    };
  };
  
  // Helper function to convert width to length
  const getColumnLength = (width) => {
    switch (width) {
      case '100%':
        return 12; // Length 12 corresponds to 100%
      case '75%':
        return 9;  // Length 9 corresponds to 75%
      case '50%':
        return 6;  // Length 6 corresponds to 50%
      case '25%':
        return 3;  // Length 3 corresponds to 25%
      case '33.33%':
        return 4;  // Length 4 corresponds to 33.33%
      default:
        return 12; // Default to 100% if unknown width
    }
  };
  
const onSubmit = (data) => {
const dashboard_rows=formatRowsForPayload(rows)
    let payload={
      custom_dashboard:{
    "employment_id": forEmployment?data.employment_id:null,
    "role_id": !forEmployment?data.role_id:null,
    "for_employment": forEmployment,
    "type":data.type,
    rows: dashboard_rows.rows,
    dashboard_rows:dashboard_rows.rows
      }
    }
    makeRequest(setLoader, createDataReq, payload, onSuccess, onError);
    setSaving(true)
  }

  const onSuccess = (data, res) => {
    message.success("Saved successfully");
    goBack();
  }

  const onError = (err, res) => {
    setSaving(false)
    message.error(res.response.data.message);
  }
  const goBack = () => {
    navigate('/workflowManagement/customDashboard/index')
  }
  //-----------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const moveBox = (boxId, direction) => {
     if(direction==='up') {
      moveBoxUp(boxId);
     }
     else{
       moveBoxDown(boxId);
     }
  };
  const moveBoxUp = (index) => {
    setRows(prevBoxes => {
      if (index <= 0) return prevBoxes; // Prevent moving the first box up
  
      // Create a new array of rows
      const updatedBoxes = [...prevBoxes];
      
      // Swap the box with the previous one
      const temp = updatedBoxes[index - 1];
      updatedBoxes[index - 1] = updatedBoxes[index];
      updatedBoxes[index] = temp;
  
      return updatedBoxes;
    });
  };
  const moveBoxDown = (index) => {
    setRows(prevBoxes => {
      if (index >= prevBoxes.length - 1) return prevBoxes; // Prevent moving the last box down
  
      // Create a new array of rows
      const updatedBoxes = [...prevBoxes];
      
      // Swap the box with the next one
      const temp = updatedBoxes[index + 1];
      updatedBoxes[index + 1] = updatedBoxes[index];
      updatedBoxes[index] = temp;
  
      return updatedBoxes;
    });
  };
  
  const removeBox = (indexToRemove) => {
    setRows(prevBoxes => {
      if (!Array.isArray(prevBoxes)) {
        console.error("prevBoxes is not an array");
        return [];
      }
      return prevBoxes.filter((_, index) => index !== indexToRemove);
    });
  };
  
  const handleDrop = (widgetId, targetColIndex, targetRowIndex) => {
    setRows(prevBoxes => {
      // Step 1: Create a new state where the widget is removed from all columns in all rows
      const updatedBoxes = prevBoxes.map(box => ({
        ...box,
        columns: box.columns.map(column => ({
          ...column,
          widgets: column.widgets.filter(id => id !== widgetId) // Remove the widget if it exists
        }))
      }));
      // Step 2: Add the widget to the target column in the specified row
      const finalUpdatedBoxes = updatedBoxes.map((box, rowIndex) => {
        if (rowIndex === Number(targetRowIndex)) {
          const updatedColumns = box.columns.map((column, colIndex) => {
            if (colIndex === targetColIndex) {
              // Add the widget to the target column
              return {
                ...column,
                widgets: [widgetId] // Ensure only the new widget is in this column
              };
            }
            return column;
          });
  
          return {
            ...box,
            columns: updatedColumns
          };
        }
  
        // Return other rows unchanged
        return box;
      });
  
      // Return the updated state
      return finalUpdatedBoxes;
    });
  };
  
  
  const addNewRow = () => {
    setRows(prevBoxes => {
      if (!Array.isArray(prevBoxes)) {
        console.error("prevBoxes is not an array");
        return [];
      }
      return [
        ...prevBoxes,
        {
          columns: [
            {
              width: '100%',
              widgets: []
            }
          ]
        }
      ];
    });
  };
  

  const clearColumnWidgets = (rowIndex, colIndex) => {
    setRows(prevBoxes => 
      prevBoxes.map((box, rIndex) => {
        if (rIndex === Number(rowIndex)) {
          // Update the specific row
          const updatedColumns = box.columns.map((column, cIndex) => {
            if (cIndex === Number(colIndex)) {
              // Clear widgets in the target column
              return {
                ...column,
                widgets: [] // Clear the widgets in this column
              };
            }
            // Return other columns unchanged
            return column;
          });
  
          return {
            ...box,
            columns: updatedColumns
          };
        }
  
        // Return other rows unchanged
        return box;
      })
    );
  };
  


  const splitColumn = (rowIndex,row,colIndex,col,division) => {
    setRows(prevBoxes => {
      // Define the maximum number of columns per row
      const MAX_COLUMNS = 4;
  
      // Calculate the width for each column based on the division type
      const getColumnWidth = (division) => {
        switch (division) {
          case 'divide1_2':
            return ['50%', '50%'];
          case 'divide1_4':
            return ['25%', '75%'];
          case 'divide3_1':
            return ['75%', '25%'];
          case 'divide3_3':
            return ['33.33%', '33.33%', '33.33%'];
          default:
            return ['100%']; // Default case if division is not recognized
        }
      };
  
      // Get the new widths based on the division type
      const columnWidths = getColumnWidth(division);
  
      // Check if the row already has the maximum number of columns
      return prevBoxes.map((box, rIndex) => {
        if (rIndex === rowIndex) {
          // Check if the total number of columns after splitting would exceed the maximum allowed
          const totalColumnsAfterSplit = box.columns.length - 1 + columnWidths.length;
          if (totalColumnsAfterSplit > MAX_COLUMNS) {
            message.warning('Cannot split the column. The row can have a maximum of 4 columns.');
            return box; // Return the box unchanged if the limit is exceeded
          }
  
          // Update the specific row
          const updatedColumns = box.columns.map((column, cIndex) => {
            if (cIndex === colIndex) {
              // Split the target column into new columns
              return columnWidths.map(width => ({
                width: width,
                widgets: [] // Initialize new columns with empty widgets
              }));
            }
            return column; // Keep other columns unchanged
          }).flat(); // Flatten the array to get a single-level array of columns
  
          return {
            ...box,
            columns: updatedColumns,
          };
        }
  
        // Return other rows unchanged
        return box;
      });
    });
  };
  

  const popOverColumns=(rowIndex,row,colIndex,col)=>{
    return (
      <>
        <Popover content={ columnOptions(rowIndex,row,colIndex,col) } title="" trigger="hover" placement="top">
          <SplitCellsOutlined className="text-primary" style={ { background: "rgb(222, 223, 224,0.5)", borderRadius: '25px', cursor: 'pointer' } } />
        </Popover>
      </>
    );
  }

  const columnOptions=(rowIndex,row,colIndex,col)=>{
    return (
      <>
      <div className="p-0" style={{width:'142px'}}>
        <div className="divide1_2" onClick={()=>splitColumn(rowIndex,row,colIndex,col,'divide1_2')}>

        </div>
        <div className="divide1_4" onClick={()=>splitColumn(rowIndex,row,colIndex,col,'divide1_4')}>

        </div>
        <div className="divide3_1" onClick={()=>splitColumn(rowIndex,row,colIndex,col,'divide3_1')}>

        </div>
        <div className="divide3_3" onClick={()=>splitColumn(rowIndex,row,colIndex,col,'divide3_3')}>

        </div>
      </div>
      </>
    )
  }

const colSize=(col)=>{
  return col.width;
  }

  const renderBox = (rowIndex, boxData,isSub) => {
    const row=rows[Number(rowIndex)];
    return (
       <div key={ rowIndex } style={ { position: 'relative', marginBottom: '16px' } }>
      <DroppableBox id={ rowIndex } onDrop={ handleDrop } onRemove={ () => removeBox(rowIndex) } onMoveUp={ () => moveBox(rowIndex, 'up') } onMoveDown={ () => moveBox(rowIndex, 'down') } boxData={boxData} rows={rows} row={row} isSub={isSub} >
        <div style={{display:'flex',width:'100%'}}>
        { row.columns.map((col,colIndex) =>
           
          <div style={{width:colSize(col)}} >
          <WidgetColumn column={col} id={ colIndex } rowIndex={rowIndex} onDrop={ handleDrop } onRemove={ () => clearColumnWidgets(rowIndex,colIndex) } onMoveUp={ () => moveBox(rowIndex, 'up') } onMoveDown={ () => moveBox(rowIndex, 'down') } boxData={boxData} rows={rows} row={row} isSub={isSub} splitColumn={()=>splitColumn(rowIndex,row,colIndex,col)}>

          {boxData.columns[colIndex].widgets.length===0 && 
     <div className="text-center mt-5"> 
     {popOverColumns(rowIndex,row,colIndex,col)}
   </div>
       }
          { boxData.columns[colIndex].widgets.map(widgetId => (
          <Widget
            key={ widgetId }
            id={ widgetId }
            text={ widgets.find(widget => widget.id === widgetId).name }
            item={ widgets.find(widget => widget.id === widgetId) }
          />
        )) }
      
        </WidgetColumn>
          </div>
        ) }
        </div>
      </DroppableBox>
    </div>
    )
  }
  //=================
  return (
    <BodyComponent>
      <HeaderComponent headers={ pageConfig.headers } modkey={ props.modkey }>
        <SaveButton key="create_button" htmlType="submit" form={ formName } loading={ saving } />
        <SaveButton text='Back' icon={ <ArrowLeftOutlined /> } onClick={ goBack } />
      </HeaderComponent>
      <Skeleton loading={ loading }>
        <Form { ...formLayout } form={ form } name={ formName } onFinish={ onSubmit }>
          <Row>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="for_employment" rules={ rules.for_employment }

                initialValue={ false }
              >
                <SelectWithSearch options={ [{
                  label: 'Role',
                  value: false
                },
                {
                  label: 'Employee',
                  value: true
                }] } onChange={ (id) => setForEmployment(id) } placeholder="For" />
              </Form.Item>
            </Col>

            {
              forEmployment ? <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="employment_id" rules={ rules.employment_id }
                >
                  <SelectResourceList type="employment" placeholder="Search Employee"

                  />
                </Form.Item>
              </Col>
                :
                <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                  <Form.Item name="role_id" rules={ rules.role_id }


                  >
                    <SelectWithSearch
                      options={ roles }
                      placeholder={ 'Role' }
                    />
                  </Form.Item>
                </Col>
            }

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="type" rules={ rules.dashboard_type }

              >
                <SelectWithSearch

                  options={ dashboardTypes }
                  placeholder={ trans(`${langKey}.type`) }
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <DndProvider backend={ HTML5Backend }>
          <div style={ { display: 'flex' } }>
            <div style={ { marginBottom: '16px', height: '500px', overflowY: 'scroll' } }>
              <h4 className="text-center">Widgets</h4>
              { widgets.map(widget => (
                <Widget key={ widget.id } id={ widget.id } text={ widget.name } item={ widget } />
              )) }
            </div>

            <div style={ { width: '100%', border: '2px dashed gray', } }>
              { Object.entries(rows).map(([boxId, boxData]) =>
                renderBox(Number(boxId), boxData,false)
              ) }
              <div className="text-center"> <CreateButton onClick={ addNewRow } style={ { marginBottom: '16px' } } text="Add" />
              </div>
            </div>

            <br />
          </div>
        </DndProvider>
      </Skeleton>
    </BodyComponent>
  )
}

export default CreateCustomDashboard

const rules = {

  role_id: [
    { required: true, message: "Role is required" },
  ],
  employment_id: [
    { required: true, message: "For?" },
  ],
  dashboard_type: [
    { required: true, message: trans(`${langKey}.dashboard_type`) },
  ],
  for_employment: [
    { required: true, message: "Select for role or employee?" },
  ],
};

const formLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 6, offset: 2 },
  labelAlign: "left"
}
