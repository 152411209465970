import React, { useEffect, useState } from "react";
import { Form, Skeleton, message, Row, Col } from "antd";
import { SaveButton, InputText, InputTextArea, CancelButton, ModalComponent, trans, SelectResourceList,InputCheck } from "@comps/components"
import { makeRequest, makeRequestStateless, getErrorProps } from "@utils/helpers";
import { updateDataReq, getEmploymentsReq } from "../requests";
import { Commons, Const } from "../../../constants";
import EmployeeInfo from '@mods/common/EmployeeInfo';
const langKey = Const["probation"].lngKey
const formName = "updateProbation";

const UpdateProbation = (props) => {
  const [loader, setLoader] = useState(true);
  const [saveLoader, setSaveLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(true)
  const [form] = Form.useForm();
  const [montWiseEvaluation,setMonthWiseEvaluation]=useState(true)

  useEffect(() => {
    getRemoteData()
    setFormData();
  }, []);
  const getRemoteData = () => {
    makeRequestStateless(getEmploymentsReq, false, onGetEmployments, false)
  }
  const onGetEmployments = (response) => {
    setLoading(false)
    setLoader(false)
  }
  const setFormData = () => {
    if (!props) {
      return;
    }
    setMonthWiseEvaluation(props.record.month_wise_evaluation)
    form.setFieldsValue(props.record);
  }
  const onSubmit = (data) => {
    data.id = props.record.id;
    const payload = { probation: {...data,month_wise_evaluation:montWiseEvaluation,employment_id:props.record.employment_id} };
    payload.id = props.record.id;
    makeRequest(setLoader, updateDataReq, payload, onSuccess, onError);
    setSaveLoader(true)
  }

  const onSuccess = (data, res) => {
    setSaveLoader(false)
    message.success("Record Updated");
    props.onCompleted(data.probations.data);
  }

  const onError = (err, res) => {
    setSaveLoader(false)
    message.error(res.response.data.message);
  }

  //-----------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [
    !props.disabled &&
    <SaveButton loading={ saveLoader } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent width={ 1000 } top={ 20 } maskClosable={ false }
      mainTitle={ trans(Commons[props.disabled ? 'view' : 'update']) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) } disabled={ props.disabled } loader={ true }>
      <Skeleton loading={ loading }>
        <EmployeeInfo record={ props.record.requestor && props.record.requestor } />
        <Form { ...formLayout } form={ form } name={ formName } onFinish={ onSubmit } disabled={ props.disabled } scrollToFirstError>

          <Row gutter={ 20 }>
            {/* <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="employment_id" rules={ rules.employment_id } label={ trans(`${langKey}.employment_id`) }
                className="da-mb-16"
                { ...getErrorProps(errors['employment_id']) } size="small"
              >
                <SelectResourceList type='employment' value={ props.record.employment_id } />
              </Form.Item>
            </Col> */}

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="duration" rules={ rules.duration } label={ trans(`${langKey}.duration`) }
                className="da-mb-16"
                { ...getErrorProps(errors['duration']) } size="small"
              >
                <InputText type="number" placeholder={ trans(`${langKey}.duration`) } />
              </Form.Item>
            </Col>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="month_wise_evaluation" rules={ rules.month_wise_evaluation } label={ trans(`${langKey}.month_wise_evaluation`) }
                className="da-mb-16"
                { ...getErrorProps(errors['month_wise_evaluation']) } size="small"
              >
                <InputCheck checked={montWiseEvaluation} onChange={(e)=>setMonthWiseEvaluation(e.target.checked)} disabled={props.record.histories.length>0}/>
              </Form.Item>
            </Col>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="description" rules={ rules.description } label={ trans(Commons.description) }
                className="da-mb-16"
                { ...getErrorProps(errors['description']) } size="small"
              >
                <InputTextArea placeholder={ trans(`${langKey}.description`) } />
              </Form.Item>
            </Col>
          </Row>

        </Form>
      </Skeleton>
    </ModalComponent>


  )
}

export default UpdateProbation

const rules = {
  name: [
    { required: true, message: '' },
  ],

};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}