const key = "travel";
const baseRoute = "/Travel";

export const Const = {

  travelMode: {
    lngKey: `${key}.travelMode`,
    route: ``,
  },
  travelType: {
    lngKey: `${key}.travelType`,
    route: ``,
  },
  stayType: {
    lngKey: `${key}.stayType`,
    route: ``,
  },
  companyOfficialDuty: {
    lngKey: `${key}.companyOfficialDuty`,
    route: ``,
  },
  myOfficialDuty: {
    lngKey: `${key}.myOfficialDuty`,
    route: ``,
  },
  officialDutyApproval: {
    lngKey: `${key}.officialDutyApproval`,
    route: ``,
  },
  myTravel: {
    lngKey: `${key}.myTravel`,
    route: ``,
  },
  companyTravel: {
    lngKey: `${key}.companyTravel`,
    route: ``,
  },
  myTravel: {
    lngKey: `${key}.myTravel`,
    route: ``,
  },
  travelApproval: {
    lngKey: `${key}.travelApproval`,
    route: ``,
  },


};

const cmn = "common";
export const Commons = {
  create: `${cmn}.create`,
  update: `${cmn}.update`,
  view: `${cmn}.view`,
  name: `${cmn}.name`,
  code: `${cmn}.code`,
  location: `${cmn}.location`,
  branch: `${cmn}.branch`,
  department: `${cmn}.department`,
  designation: `${cmn}.designation`,
  joiningDate: `${cmn}.joiningDate`,
  grade: `${cmn}.grade`,
  ProfileCompletion: `${cmn}.ProfileCompletion`,
  actions: `${cmn}.actions`,
  is_active: `${cmn}.is_active`,
  status: `${cmn}.status`,
  description: `${cmn}.description`,
  shortname: `${cmn}.shortname`,
  country: `${cmn}.country`,
  history: `${cmn}.history`,
  flow: `${cmn}.flow`,
};
export const colors = {
  "bg-warning": "#faad14",
  "bg-warning-color": "white",
  "bg-success": "green",
  "bg-success-color": "white",
  "bg-darkgray": "#333",
  "bg-danger": "#d9534f"
}


