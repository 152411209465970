import React, { useEffect, useState } from "react";
import { Form, Input, message, Checkbox, Modal, Skeleton, Row, Col } from "antd";

import { SaveButton, InputText, CancelButton, ModalComponent, trans, SelectWithSearch, UploadFile, UploadButton, InputDate, SelectResourceList } from "@comps/components"
import { makeRequest, makeRequestStateless, getErrorProps } from "@utils/helpers";
import { createExperienceReq, getAllEmployeesReq } from "../requests";
import { Commons, Const } from "../../constants";
import { createSelectList, createCurrenciesListWithName } from '@comps/commonFunctions';
import { getAllCurrenciesReq } from '../../commonRequests';
const { TextArea } = Input;
const langKey = Const["experience"].lngKey

const formName = "createExperience";

const CreateExperience = (props) => {

  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [isWorking, setisWorking] = useState(false);
  const [currencies, setCurrencies] = useState([]);
  const [saving, setsaving] = useState(false);
  const [loading, setloading] = useState(true);
  const [form] = Form.useForm()

  useEffect(() => {
    makeRequestStateless(getAllCurrenciesReq, false, ongetCurrencies, false)
    makeRequestStateless(getAllEmployeesReq, false, ongetAllEmployees, false)
    form.setFieldValue('employee_id', props.filters !== undefined ? props.filters.replace('employments_|', '') : null)
    // eslint-disable-next-line
  }, []);
  const ongetAllEmployees = (response) => {
    setEmployees(createSelectList(response.resources.data))
    setloading(false)
  }
  const ongetCurrencies = (response) => {
    setCurrencies(createCurrenciesListWithName(response))
  }

  const onSubmit = (data) => {
    if (data.experience_letter !== undefined && data.experience_letter !== null && data.experience_letter.url === undefined) {
      data.experience_letter.file = data.experience_letter.fileList.length > 0 ? data.experience_letter.file : null
    }
    const payload = { employee_experience: data, filters: props.filters }
    makeRequest(setLoader, createExperienceReq, payload, onSuccess, onError);
    setsaving(true)
  }

  const onSuccess = (data, res) => {
    message.success("Experience Saved successfully");
    setLoader(true)
    props.onCompleted(data.employee_experiences.data);
    props.onSuccess(data)

  }

  const onError = (err, res) => {
    setsaving(false)
    message.error(res.response.data.message);
    //setErrors(errorList);
  }
  //-----------on changes------------
  const onChangeIsDegree = (e) => {
    setisWorking(e.target.checked)
  }
  //============uploading file============
  const [fileList, setFileList] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const handleCancel = () => setPreviewOpen(false);
  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };
  const onPreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };
  // ------------------------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [
    <SaveButton loading={ saving } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } width={ 1000 } maskClosable={ false }
      mainTitle={ trans(Commons.create) } subTitle={ trans(`${langKey}.experience`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Skeleton loading={ loading }>
        <Form { ...formLayout } name={ formName } onFinish={ onSubmit } form={ form } scrollToFirstError >
          <Row gutter={ 30 }>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="employee_id" label={ trans(`${langKey}.employee`) } rules={ rules.employee_id } className="da-mb-16"
                { ...getErrorProps(errors['employee_id']) }
              >
                <SelectResourceList type='employee'
                  disabled={ props.filters !== undefined }
                />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="employer_name" rules={ rules.employer_name } label={ trans(`${langKey}.employer_name`) }
                className="da-mb-16"
                { ...getErrorProps(errors['employer_name']) } size="small"
              >
                <InputText placeholder={ trans(`${langKey}.employer_name`) } />

              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="designation" rules={ rules.designation } label={ trans(`${langKey}.designation`) }
                className="da-mb-16"
                { ...getErrorProps(errors['designation']) } size="small"
              >
                <InputText placeholder={ trans(`${langKey}.designation`) } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="company_name" rules={ rules.company_name } label={ trans(`${langKey}.company_name`) }
                className="da-mb-16"
                { ...getErrorProps(errors['company_name']) } size="small"

              >
                <InputText placeholder={ trans(`${langKey}.company_name`) } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="currency_id" label={ trans(`${langKey}.currency_id`) } rules={ rules.currency_id } className="da-mb-16"
                { ...getErrorProps(errors['currency_id']) }
              >
                <SelectWithSearch
                  placeholder={ trans(`${langKey}.currency_id`) }
                  options={ currencies }
                />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="last_salary" rules={ rules.last_salary } label={ trans(`${langKey}.last_salary`) }
                className="da-mb-16"
                { ...getErrorProps(errors['last_salary']) } size="small"

              >
                <InputText placeholder={ trans(`${langKey}.last_salary`) } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="country" rules={ rules.country } label={ trans(`${langKey}.country`) }
                className="da-mb-16"
                { ...getErrorProps(errors['country']) } size="small"

              >
                <InputText placeholder={ trans(`${langKey}.country`) } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="city" rules={ rules.city } label={ trans(`${langKey}.city`) }
                className="da-mb-16"
                { ...getErrorProps(errors['city']) } size="small"

              >
                <InputText placeholder={ trans(`${langKey}.city`) } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item valuePropName="checked" name="is_working" label={ trans(`${langKey}.is_working`) } className="da-mb-16">
                <Checkbox onChange={ onChangeIsDegree }></Checkbox>
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="from_date" rules={ rules.from_date } label={ trans(`${langKey}.from_date`) }
                className="da-mb-16"
                { ...getErrorProps(errors['from_date']) } size="small"

              >
                <InputDate format='YYYY-MM-DD' />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="to_date" rules={ !isWorking ? rules.to_date : [] } label={ trans(`${langKey}.to_date`) }
                className="da-mb-16"
                { ...getErrorProps(errors['to_date']) } size="small"
                style={ { display: isWorking ? 'none' : 'block' } }
              >
                <InputDate format='YYYY-MM-DD' />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="contact_person_name" rules={ rules.contact_person_name } label={ trans(`${langKey}.contact_person_name`) }
                className="da-mb-16"
                { ...getErrorProps(errors['contact_person_name']) } size="small"

              >
                <InputText placeholder={ trans(`${langKey}.contact_person_name`) } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="contact_person_no" rules={ rules.contact_person_no } label={ trans(`${langKey}.contact_person_no`) }
                className="da-mb-16"
                { ...getErrorProps(errors['contact_person_no']) } size="small"

              >
                <InputText placeholder={ trans(`${langKey}.contact_person_no`) } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="contact_person_email" rules={ rules.contact_person_email } label={ trans(`${langKey}.contact_person_email`) }
                className="da-mb-16"
                { ...getErrorProps(errors['contact_person_email']) } size="small"

              >
                <InputText placeholder={ trans(`${langKey}.contact_person_email`) } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="employer_address" rules={ rules.employer_address } label={ trans(`${langKey}.employer_address`) }
                className="da-mb-16"
                { ...getErrorProps(errors['employer_address']) } size="small"

              >
                <TextArea placeholder="Employer Address" />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="leaving_reason" rules={ rules.leaving_reason } label={ trans(`${langKey}.leaving_reason`) }
                className="da-mb-16"
                { ...getErrorProps(errors['leaving_reason']) } size="small"

              >
                <TextArea placeholder="Reason for leaving" />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item label={ trans(`${langKey}.attachment`) } name="experience_letter" >
                <UploadFile
                  accept=".jpg,.png,.jpeg" listType="picture-card"
                  fileList={ fileList }
                  onChange={ onChange }
                  onPreview={ onPreview }
                  multiple={ false }
                  beforeUpload={ (file) => {
                    return false;
                  } }
                >
                  { fileList.length === 1 ? null : <UploadButton /> }
                </UploadFile>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Skeleton>

      <Modal open={ previewOpen } title={ previewTitle } footer={ null } onCancel={ handleCancel }>
        <img
          alt="example"
          style={ {
            width: '100%',
          } }
          src={ previewImage }
        />
      </Modal>
    </ModalComponent>
  )
}

export default CreateExperience

const rules = {
  employer_name: [
    { required: true, message: trans(`${langKey}.employer_name.req`) },
  ],
  employee_id: [
    { required: true, message: trans(`${langKey}.employee_id.req`) },
  ],
  designation: [
    { required: true, message: trans(`${langKey}.designation.req`) },
  ],
  from_date: [
    { required: true, message: trans(`${langKey}.from_date.req`) },
  ],
  to_date: [
    { required: true, message: trans(`${langKey}.to_date.req`) },
  ],
};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}