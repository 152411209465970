import { get, del, postFd, put } from "@utils/axios";

const api = "jobTitles";

export const getAllJobTitlesReq = (payload) => {
  return get(`${api}?page=${payload.page}&filters=${payload.filters}`)
};

export const createJobTitleReq = (payload) => {
  return postFd(`${api}?`, payload);
};

export const updateJobTitleReq = (payload) => {
  return put(`${api}/${payload.id}`, payload);
};

export const deleteJobTitleReq = (id) => {
  return del(`${api}/${id}`);
};

export const getFilterReq = (payload) => {
  return get(`${api}?page=1&filters=${payload}`)
}
