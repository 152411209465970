import { useState, useEffect } from "react";
import {
  BodyComponent,
  TableComponent,
  ActionComponent,
  CreateButton,RefreshButton,
  trans,
  HeaderComponent,
  EmployeeWithProfile,
  PaginationComponent
} from "@comps/components";
import { makeRequest } from "@utils/helpers";
import { Commons, Const, colors } from "../../constants";
import { Badge, message } from 'antd';
import { FilterComponent, FilterButton } from "@comps/components";
import { deleteDataReq, getAllDataReq, getFilterReq } from "./requests";
import { useSelector } from "react-redux";
import moment from "moment";
import ViewFlow from "@mods/common/ViewFlow";
import ViewRequestHistory from "@mods/common/ViewRequestHistory";
import UpdateCompanyPromotionRequest from './components/UpdateCompanyPromotionRequest';
import CreateCompanyPromotionRequest from "./components/CreateCompanyPromotionRequest";
import { getRequestFilters } from "@mods/common/commonFilterFields";

const langKey = Const["companyPromotions"].lngKey;
const pageConfig = {
  headers: {
    title: trans(`${langKey}.moduleName`),
    breadcrumb: [
      {
        name: trans(`${langKey}.moduleName`),
        path: Const["companyPromotions"].route
      }
    ]
  }
}

const IndexCompanyPromotionRequests = (props) => {
  const authUser = useSelector((state) => state.auth.authUser);
  const [totalRecords, setTotalRecords] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [loader, setLoader] = useState(false);
  const [childComponent, setChildComponent] = useState(null);
  const [filerComponent, setFilterComponent] = useState(null);
  const [allData, setAllData] = useState({})
  const [filters, setFilters] = useState(props.promotionof === 'my' ? `employee_|${authUser.employment_id}` : '');
  //start pagination states
  const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState({
    "total": 0,
    "count": 0,
    "per_page": 0,
    "current_page": 0,
    "total_pages": 0,
    "links": {}
  });
  //end pagination states

  // ==================================
  //   Use States End
  // ==================================

  // ==================================
  //   Table Data End
  // ==================================

  const columns = [
    {
      key: 'code',
      title: trans(Commons.code),
      dataIndex: "code",
    },
    {
      key: 'employment_id',
      title: trans(`${langKey}.employment_id`),
      render: (record) => {
        return <EmployeeWithProfile record={ record.requestor } />
      },
    },
    {
      key: 'date',
      title: trans(`${langKey}.date`),
      render: (record) => {
        return moment(record.date).format('YYYY-MM-DD')
      }
    },


    {
      key: 'next_requestee',
      title: trans(`${langKey}.next_requestee`),
      render: (record) => {
        return <EmployeeWithProfile record={ record.next_requestee_name } />
      },
    },
    {
      key: 'status',
      title: trans(`${langKey}.status`),
      render: (record) => {
        return <Badge
          count={ record.status ? record.status : 'none' }
          style={ { background: colors[record.status_class], color: colors[record.status_class + '-color'] } }
        />
      }
    },
    {
      key: trans(Commons.actions),
      title: trans(Commons.actions),
      render: (record) => (
        <ActionComponent
          each={ record } modkey={props.modkey}
          onViewHistory={ record.status !== 'Pending' ? onViewHistory : '' }
          onViewFlow={ record.flow.length !== 0 ? onViewFlow : '' }
          onEdit={ record.status === 'Pending' ? onEdit : '' }
          onView={ onView }
          onDelete={ record.status === 'Pending' ? onDelete : '' }

        >
        </ActionComponent>
      ),
    },
  ];

  // ==================================
  //   Table Data End
  // ==================================

  useEffect(() => {
    getAllData();
    // eslint-disable-next-line
  }, [filters, currentPage]);
  const getAllData = () => {
    const payload = {
      page: currentPage,
      filters: filters
    }
    setChildComponent(null)
    makeRequest(setLoader, getAllDataReq, payload, onSuccess, onGetAllDataError);
  };
  const onSuccess = (response) => {
    if (response.errors.length > 0) {
      message.info(response.errors[0])
    }
    setAllData(response);
    setPagination(response.promotions);
    setDataSource(response.promotions.data);
  };
  const onGetAllDataError = (err, res) => {
    message.error(res.response.data.message);
  }
  const onEdit = (record) => {
    setChildComponent(<UpdateCompanyPromotionRequest onCompleted={ onCompleted } authUser={ authUser } record={ record } allData={ allData } disabled={ false } />);
  };
  const onView = (record) => {
    setChildComponent(<UpdateCompanyPromotionRequest onCompleted={ onCompleted } authUser={ authUser } record={ record } allData={ allData } disabled={ true } />);
  };
  const onViewHistory = (record) => {
    setChildComponent(
      <ViewRequestHistory onCompleted={ onCompleted } record={ record } disabled={ true } subTitle={ trans(Commons.history) } />
    );
  };
  const onViewFlow = (record) => {
    setChildComponent(
      <ViewFlow onCompleted={ onCompleted } record={ record } disabled={ true } subTitle={ trans(Commons.flow) } />
    );
  };
  const onDelete = (record) => {
    const payload = {
      filters: '', id: record.id
    }
    makeRequest(setLoader, deleteDataReq, payload, onDeleted, onError);
  };
  const onDeleted = (res) => {
    onSuccess(res)
    setDataSource(res.promotions.data);
    message.success("Record Deleted")
  };

  const onError = (err, res) => {
    message.error(res.response.data.message);
    //setErrors(errorList);
  }

  // Create component modal
  const onCreate = () => {
    setChildComponent(<CreateCompanyPromotionRequest onCompleted={ onCompleted } authUser={ authUser } allData={ allData } onSuccess={ onSuccess } />);
  };

  const onCompleted = (data) => {
    if (data.length) {
      setDataSource(data);
    }
    setChildComponent(null);
  };

  const handleTableChange = (page, fil, sorter) => {
    let payload = {
      ...pagination,
      current: page.current,
      pageSize: page.pageSize,
      sortName: sorter.field || "id",
      sortType: sorter.order === "ascend" ? "asc" : "desc",
    };
    setPagination(payload);
  };

  //---------------filters-----------------------
  const onOpenFilter = () => {
    setFilterComponent(<FilterComponent filters={ filterFields } modkey={props.modkey} selected={ filters } api={ getFilterReq } setCurrentPage={setCurrentPage} onFilter={ setFilters } onCloseFilter={ onCloseFilter }
    />
    )
  }

  const onCloseFilter = () => {
    setFilterComponent(null);

  }

  //----end filter--------------------

  return (
    <>
      { filerComponent }
      { childComponent }
      <BodyComponent>
        <HeaderComponent headers={ pageConfig.headers } modkey={props.modkey}>
          <RefreshButton onClick={ getAllData } /> <CreateButton onClick={ onCreate } />
          <FilterButton onClick={ onOpenFilter } />
        </HeaderComponent>
        <TableComponent modkey={props.modkey}
          loader={ loader }
          columns={ columns }
          dataSource={ dataSource }
          pagination={ { ...pagination, total: totalRecords } }
          onChange={ handleTableChange }
        />
      </BodyComponent>
      <PaginationComponent pagination={ pagination } modkey={props.modkey} setCurrentPage={ setCurrentPage } currentPage={ currentPage } />
    </>
  );
};

export default IndexCompanyPromotionRequests;
const filterFields = getRequestFilters('')
