import React from "react";
import BaseButton from "./BaseButton";
import ViewApprovalHistory from '@mods/common/ViewApprovalHistory';
import ViewFlow from '@mods/common/ViewFlow';
import { Space } from 'antd'
const BaseFlowHistory = (props) => {

  const onViewHistory = (record) => {
    props.setChildComponent(
      <ViewApprovalHistory onCompleted={ () => props.setChildComponent(null) } record={ record } disabled={ true } />
    );
  };

  const onViewFlow = (record) => {
    props.setChildComponent(
      <ViewFlow onCompleted={ () => props.setChildComponent(null) } record={ record } disabled={ true } />
    );
  };
  return (
    <>
      <Space>
        <BaseButton onClick={ () => onViewFlow(props.record) } type="primary" { ...props }>{ props.text ? props.text : "Flow" }</BaseButton>
        <BaseButton onClick={ () => onViewHistory(props.record) } type="primary" { ...props }>{ props.text ? props.text : "History" }</BaseButton>
      </Space>
    </>
  )
}
export default BaseFlowHistory;
