import React, { useEffect } from 'react';
import { Row, Col } from 'antd';
import "./dashboard.css"
import { ButtonComponent } from '@comps/components';
import WidgetIcon from '@assets/icons/widget.svg';
import Birthdays from './components/Birthdays';
import GenderWiseCount from './components/GenderWiseCount';
import ClockWidget from './components/ClockWidget';
import CheckInTrends from './components/CheckInTrends';
import AbsentMonthWise from './components/AbsentMonthWise';
import HeadCountByAge from './components/HeadCountByAge';
import HeadCountByBranch from './components/HeadCountByBranch';
import HeadCountByDepartment from './components/HeadCountByDepartment';
import HeadCountByDesignation from './components/HeadCountByDesignation';
import HeadCountByEmployeeType from './components/HeadCountByEmployeeType';
import HeadCountByLocation from './components/HeadCountByLocation';
import HeadCountByService from './components/HeadCountByService';
import HeadCountBySeparationType from './components/HeadCountBySeparationType';
import MonthlyAttendanceReport from './components/MonthlyAttendanceReport';
import AttendanceDrillByDepartment from './components/AttendanceDrillByDepartment';
import AttendanceDrillByBranch from './components/AttendanceDrillByBranch';
import MarkCheckinCheckout from './components/MarkCheckinCheckout';
import { makeRequest } from '@utils/helpers';
import { getDashboardReq } from './requests';
import AttendanceSummaryReport from './components/AttendanceSummaryReport';
import LateArrivals from './components/LateArrivals';
import EarlyArrivals from './components/EarlyArrivals';
import LeaveSummary from './components/LeaveSummary';
import MyTeam from './components/MyTeam';
import ChatBot from '../../wrappers/layout/ChatBot';
import ActiveEmployees from './components/ActiveEmployees';
import NoticePeriodEmployees from './components/NoticePeriodEmployees';
import ProbationConfirmation from './components/ProbationConfirmation';
import EoeEmployees from './components/EoeEmployees';

const Dashboard = () => {
  const [loader, setLoader] = React.useState(false);
  const [dashboardData, setDashboardData] = React.useState([]);

  useEffect(() => {
    makeRequest(setLoader, getDashboardReq, null, onSuccess, onError);
  }, []);

  const onSuccess = (data) => {
    setDashboardData(data);
  }

  const onError = (err) => {
    console.log(err);
  }

  return (
    <>
      {/* <Row className='heading'>
        <Col span={ 12 }>
          <h1>Dashboard</h1>
        </Col> 
         <Col className='widget' span={ 12 }>
          <ButtonComponent icon={ <img src={ WidgetIcon } alt='widget' /> } className='widgetbtn' text="Manage Widgets" />
        </Col>
      </Row> */}

      <div className='dashboardbox'>
        { dashboardData && dashboardData.length > 0 && dashboardData.map((row, index) =>
          <Row gutter={ 24 } key={ index }>
            { row.columns && row.columns.map((column, index) =>
              <Col className='dashboardcol' key={ index } lg={ column.length + column.length } sm={ 24 } xs={ 24 }>
                { column.directive && column.directive.tag === "shift-wise-attendance" && <AttendanceDrillByBranch /> }
                { column.directive && column.directive.tag === "mark-attendance" && <MarkCheckinCheckout /> }
                { column.directive && column.directive.tag === "dashboard-clock" && <ClockWidget /> }
                { column.directive && column.directive.tag === "absents-month-wise" && <AbsentMonthWise /> }
                { column.directive && column.directive.tag === "birthday" && <Birthdays /> }
                { column.directive && column.directive.tag === "employees-count-by-age" && <HeadCountByAge /> }
                { column.directive && column.directive.tag === "employees-count-by-branch" && <HeadCountByBranch /> }
                { column.directive && column.directive.tag === "employees-count-by-department" && <HeadCountByDepartment /> }
                { column.directive && column.directive.tag === "employees-count-by-designation" && <HeadCountByDesignation /> }
                { column.directive && column.directive.tag === "employees-count-by-employee-type" && <HeadCountByEmployeeType /> }
                { column.directive && column.directive.tag === "employees-count-by-location" && <HeadCountByLocation /> }
                { column.directive && column.directive.tag === "employees-count-by-service" && <HeadCountByService /> }
                { column.directive && column.directive.tag === "monthly-attendance-count" && <MonthlyAttendanceReport /> }
                { column.directive && column.directive.tag === "employees-termination-count-by-reason" && <HeadCountBySeparationType /> }
                { column.directive && column.directive.tag === "employee-daily-arrivals" && <CheckInTrends /> }
                { column.directive && column.directive.tag === "gender-breakdown" && <GenderWiseCount /> }
                { column.directive && column.directive.tag === "attendance-summary" && <AttendanceSummaryReport /> }
                { column.directive && column.directive.tag === "daily-late-arrivals" && <LateArrivals /> }
                { column.directive && column.directive.tag === "daily-early-arrivals" && <EarlyArrivals /> }
                { column.directive && column.directive.tag === "leave-summary" && <LeaveSummary /> }
                { column.directive && column.directive.tag === "my-team" && <MyTeam /> }
                { column.directive && column.directive.tag === "active-employee" && <ActiveEmployees /> }
                { column.directive && column.directive.tag === "employees-notice-period" && <NoticePeriodEmployees /> }
                { column.directive && column.directive.tag === "probation-confirmation" && <ProbationConfirmation /> }
                { column.directive && column.directive.tag === "eoe-employees" && <EoeEmployees /> }
              </Col>
            ) }
          </Row>
        ) }
        {/* <AttendanceDrillByDepartment />
        < />
        < /> */}
      </div>

      <ChatBot />
    </>
  )
}

export default Dashboard;