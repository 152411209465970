import { useState, useEffect } from "react";
import {
  BodyComponent,
  TableComponent,
  ActionComponent,
  trans,
  HeaderComponent, PaginationComponent, CustomButton
} from "@comps/components";
import { makeRequest } from "@utils/helpers";
import { Commons, Const } from "../../../constants";
import { getKpiEvaluationsReq } from "../../requests";
import ViewKpiEvaluation from "./ViewKpiEvaluation";

import { checkPermissions, convertTimeTotz } from '@comps/commonFunctions';
const langKey = Const["kpi"].lngKey;


//========================================
const IndexKpiEvaluation = (props) => {
  const [dataSource, setDataSource] = useState([]);
  const [loader, setLoader] = useState(false);
  const [childComponent, setChildComponent] = useState(null);
  const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState({
    "total": 0,
    "count": 0,
    "per_page": 0,
    "current_page": 0,
    "total_pages": 0,
    "links": {}
  });

  // ==================================
  //   Use States End
  // ==================================

  // ==================================
  //   Table Data End
  // ==================================

  const columns = [
    {
      key: 'kpi_ids',
      title: trans(`${langKey}.kpi_id`),
      render: (record) => (
        record.kpi ? record.kpi.title : ''
      )
    },
    {
      key: 'verifiedBy',
      title: trans(`${langKey}.verifiedby_id`),
      render: (record) => (
        record.verified_by ? record.verified_by.full_name_code : ''
      )
    },
    {
      key: 'approve_status',
      title: trans(`${langKey}.approve_status`),
      render: (record) => (
        <span className={ record.approve_status === '2' ? 'text-success' : 'text-danger' }>
          { record.approve_status === '2' ? "Approved" : "Pending" }
        </span>
      )
    },
    {
      key: 'filledBy',
      title: trans(`${langKey}.filledby_id`),
      render: (record) => (
        record.filled_by ? record.filled_by.full_name_code : ''
      )
    },
    {
      key: 'kpi_status',
      title: trans(`${langKey}.kpi_status`),
      render: (record) => (
        <span className={ record.status ? 'text-success' : 'text-danger' }>
          { record.status ? "Submitted" : "Pending" }
        </span>
      )
    },
    {
      key: 'performed_at',
      title: "Performed At",
      render: (record) => (
        convertTimeTotz(record.created_at).format('YYYY-MM-DD h:m a')
      )
    },
    {
      key: trans(Commons.actions),
      title: trans(Commons.actions),
      render: (record) => (
        <ActionComponent
          each={ record } modkey={props.modkey}
          //onDelete={checkPermissions('kpiEvaluation','delete') && onDelete }
          onView={ checkPermissions('kpiEvaluation', 'view') && onView }
        //onEdit={checkPermissions('kpiEvaluation','update') && onEdit }
        >
        </ActionComponent>
      ),
    },
  ];

  // ==================================
  //   Table Data End
  // ==================================

  useEffect(() => {
    getAllData();
    // eslint-disable-next-line
  }, [currentPage]);

  const getAllData = () => {
    const payload = {
      page: currentPage
    }
    makeRequest(setLoader, getKpiEvaluationsReq, payload, onSuccess, null);
  };

  const onSuccess = (data, response) => {
    setDataSource(data.data);
    setPagination(data)
    //message.info(response.message)
  };


  const onView = (record) => {
    setChildComponent(
      <ViewKpiEvaluation onCompleted={ onCompleted } record={ record } disabled={ true } />
    );
  };


  const onCompleted = (data) => {
    if (data) {
      getAllData()
    }
    setChildComponent(null);
  };


  const handleTableChange = (page, fil, sorter) => {
    let payload = {
      ...pagination,
      current: page.current,
      pageSize: page.pageSize,
      sortName: sorter.field || "id",
      sortType: sorter.order === "ascend" ? "asc" : "desc",
    };
    setPagination(payload);
  };

  //============allocation==============================
  return (
    <>
      { childComponent }
      <BodyComponent>
        <HeaderComponent modkey={props.modkey}>
          <CustomButton onClick={ getAllData } text="Refresh" />
        </HeaderComponent>
        <TableComponent modkey={props.modkey}
          loader={ loader }
          columns={ columns }
          dataSource={ dataSource }
          onChange={ handleTableChange }

        />
      </BodyComponent>
      <PaginationComponent pagination={ pagination } modkey={props.modkey} setCurrentPage={ setCurrentPage } currentPage={ currentPage } />
    </>
  );
};

export default IndexKpiEvaluation;


