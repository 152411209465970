import { get, del, post, put } from "@utils/axios";

const api = "holiday";

export const getAllDataReq = (payload) => {
  return get(`${api}?page=${payload.page}&filters=${payload.filters}`)
};

export const createDataReq = (payload) => {
  return post(`${api}?`, payload);
};

export const updateDataReq = (payload) => {
  return put(`${api}/${payload.id}`, payload);
};

export const deleteDataReq = (id) => {
  return del(`${api}/${id}`);
};
export const getRelatedDataReq = (id) => {
  return get(`${api}/get-related-data`);
};
export const getLocationsWithBranchesReq = (id) => {
  return get(`company-locations-with-branches?`);
};

export const getStatesByCountryReq = (id) => {
  return get(`states/all?filters=country_|${id}`)
}

export const getCitiesByStateReq = (id) => {
  return get(`cities/all?filters=state_|${id}`)
}

export const getFilterReq = (payload) => {
  return get(`${api}?page=1&filters?company_id=1&fields=page=1&filters=organization_structure_fields_|company;preset_|grade;`)
}