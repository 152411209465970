import { trans, InputText, AddNewButton ,TableComponent} from "@comps/components";
import { useEffect, useState } from "react";
import { getSalaryHistoriesReq } from "../requests";
import { makeRequest, makeRequestStateless } from "@utils/helpers";
import dayjs from 'dayjs'
const SalaryHistories=(props)=>{

  const [dataSource,setDatasource]=useState([])
  const [loader, setLoader] = useState(false);
  useEffect(()=>{
getRemoteData()
  },[])

  const getRemoteData=()=>{
    const payload={
      employmentId:props.employmentId
    }
    makeRequest(setLoader, getSalaryHistoriesReq, payload, onSuccess, onError);
  }
  const onSuccess=(res)=>{

setDatasource(res.histories.data)
  }
  const onError=(res,err)=>{

  }
  const columns = [
    {
      key: 'description',
      title: "Type",
      dataIndex: "type",
    },
    {
      key: 'previous_amount',
      title: "Last Salary",
      dataIndex: "previous_amount",
    },
    {
      key: 'new_amount',
      title: "Revised Salary",
      dataIndex: "new_amount",
    },
    {
      key: 'date',
      title: "Effective Date",
      render: (record)=>(
        dayjs(record.date).format('YYYY-MM-DD')
      ),
    },

  ]
  if(dataSource.length<1){
    return ''
  }
  return (
    <>
       <div className="employeeInfo" ><h5 className="employeeInfo-text">History</h5></div>
       <TableComponent
          columns={columns}
          dataSource={dataSource}
          />
    </>
  )
}

export default SalaryHistories