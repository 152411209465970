import { get } from "@utils/axios";

export const getFilterReq = (payload) => {
  return get(`filters?company_id=1&fields=page=1&filters=supporting_fields_|chart_type;preset_|organization_chart;`)
}

export const getFilterDataReq = (payload) => {
  return get(`companies/getOrganizationHierarchy?page=1&filters=chart_type_|` + payload)
}
export const getDataByIdTypeReq = (payload) => {
  return get(`companies/getSubordinates/${payload.id}?page=1&filters=chart_type_|` + payload.chartType)
}
