import { get, del, postFd, put } from "@utils/axios";

const api = "grades";

export const getAllGrades = (payload) => {
  return get(`${api}?page=${payload.page}&filters=${payload.filters}`)
};

export const createGradeReq = (payload) => {
  return postFd(`${api}?`, payload);
};

export const updateGradeReq = (payload) => {
  return put(`${api}/${payload.id}`, payload);
};

export const deleteGrade = (id) => {
  return del(`${api}/${id}`);
};

export const getCompanyReq = () => {
  return get('common/getCompany')
}
export const getFilterReq = (payload) => {
  return get(`${api}?page=1&filters?company_id=1&fields=page=1&filters=organization_structure_fields_|company;preset_|grade;`)
}
export const getCompanyRelatedDataReq = (payload) => {
  return get(`${api}/get-company-related-data/1`)
}

//grades / get - company - related - data / 1
//in response {"benefits":[],"payitems":[]}