import { get, del, post, put } from "@utils/axios";

const api = "payrolls";
export const getAllDataReq = (payload) => {
  return get(`${api}?page=${payload.page}&filters=${payload.filters}`)
};
export const getDataByIdReq = (payload) => {
  return get(`${api}/${payload}`)
};
export const getRelatedDataReq = (payload) => {
  return post(`${api}/getRelatedData`)
};

export const createDataReq = (payload) => {
  return post(`${api}`, payload);
};

export const updateDataReq = (payload) => {
  return put(`${api}/${payload.id}`, payload);
};

export const runPayrollDataReq = (payload) => {
  return post(`${api}/run`, payload);
};
export const closePayrollDataReq = (payload) => {
  return post(`${api}/close`, payload);
};


export const deleteDataReq = (payload) => {
  return del(`${api}/${payload.id}?page=1&filters=${payload.filters}`);
};

export const getRosterReq = (payload) => {
  return post(`${api}/getRoster`, payload);
};
export const getExpectedTimeReq = (payload) => {
  return get(`${api}/getExpectedTime?&attendance_date=${payload.date}&employment_id=${payload.employment_id}`);
};

export const getFilterReq = (payload) => {
  return get(`${api}?page=1&filters?company_id=1&fields=page=1&filters=organization_structure_fields_|company;preset_|grade;`)
}
export const onDateChange = (payload) => {
  return post(`${api}/dateChange?&`, payload)
}
export const onShiftSelectionChangeReq = (payload) => {
  return post(`${api}/shiftSelectionChange?&`, payload)
}

export const getRelatedDataByReqIdReq = (payload) => {
  return get(`${api}/${payload}`)
};

