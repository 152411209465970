
import { Divider, Layout, Space, Tabs } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  HeaderComponent,
} from "@comps/components";
import IndexUsers from './components/users/IndexUsers';
import IndexRoles from './components/roles/IndexRoles';
import IndexLogs from './components/logs/IndexLogs';
import IndexMachineTypes from './components/machineTypes/IndexMachineTypes';
import IndexCodes from './components/codes/IndexCodes';
import IndexRequestFlows from './components/requestFlow/IndexRequestFlow';
import IndexMachines from './components/machines/IndexMachine';

const { Content } = Layout;

const IndexSystemMasters = (props) => {
  const navigate = useNavigate()

  //------------use states start----------------
  const [tabPosition, setTabPosition] = useState('left');
  const [currentTab, setCurrentTab] = useState('Users');
  //-----------end use states--------------------
  const changeTabPosition = (e) => {
    setTabPosition(e.target.value);
  };
  const onChangeTab = (e) => {
    localStorage.setItem('filterPath', '/masters/system/' + e)
    setCurrentTab(e)
  };
  //-----------styling------------

  const TabContentStyle = { background: 'white', padding: '20px' }
  const pageConfig = {
    headers: {
      title: "",
      breadcrumb: [
        {
          name: "Masters",
          path: "",
        },
        {
          name: "System",
          path: "",
        },
        {
          name: `${currentTab}`,
          path: "",
        },
      ],
    },
  };
  //----------end styling-----------
  return (
    <>
      <Space
        style={ {
          marginBottom: 24,
        } }
      >
      </Space>
      <Layout>
        <HeaderComponent headers={ pageConfig.headers } modkey={props.modkey}>

        </HeaderComponent>
        <Layout hasSider>
          <Content style={ TabContentStyle }>
            <Tabs
              centered
              tabPosition='left'
              onChange={ onChangeTab }
              tabBarStyle={ { fontWeight: 'bold' } }
            >
              <Tabs.TabPane tab="Users" key={ 'Users' }>
                <IndexUsers modkey="users"/>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Roles" key={ 'Roles' }>
                <IndexRoles modkey="roles"/>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Logs" key={ 'Logs' }>
                <IndexLogs modkey="logs"/>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Machine Types" key={ 'Machine Types' }>
                <IndexMachineTypes modkey="machineType"/>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Machines" key={ 'Machines' }>
                <IndexMachines modkey="machines"/>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Codes" key={ 'Codes' }>
                <IndexCodes />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Request Flow" key={ 'Request Flow' }>
                <IndexRequestFlows modkey="requestFlow"/>
              </Tabs.TabPane>

            </Tabs>
          </Content>

        </Layout>
      </Layout>
    </>
  );

};
export default IndexSystemMasters;