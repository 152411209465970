import React from "react";
import BaseButton from "./BaseButton";
import { SaveOutlined } from '@ant-design/icons';
import { Button } from 'antd';


const BaseCreate = (props) => {
  return (
    <Button className="savebtn" type="primary" { ...props } icon={ props.icon }>{ props.text ? props.text : "Save" }</Button>

  )
}

export default BaseCreate;
