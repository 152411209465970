import { get, del, post } from "@utils/axios";

export const getAllSalariesReq = (payload) => {
  return get(`independent/employment/salaries?page=${payload.page}&filters=${payload.filters}`);
};

export const createSalaryReq = (payload) => {
  return post(`independent/employment/salaries?&filters=${payload.filters}`, payload);
};

export const updateSalaryReq = (payload) => {
  return post(`independent/employment/salaries/${payload.id}/update?&filters=${payload.filters}`, payload);
};

export const deleteSalaryReq = (id) => {
  return del(`independent/employment/salaries/${id}`);
};

//-----------on create
export const getAllEmployeesReq = () => {
  return get("company/employee/employments?page=1");
};

export const getRelatedDataReq = () => {
  return get(`independent/employment/salaries/get-related-data?`)
}
export const getSalaryTypesReq = () => {
  return get(`resources/salaryTypes?page=1?`)
}
export const getBankByEmploymentIdReq = (id) => {
  return get(`independent/employment/salaries/get-employment-related-data/${id}`)
}

export const getEmployeesSalaryByIdReq = (id) => {
  return get(`employee_banks/employee_related_data/${id}`)
}
export const getFilterReq = (payload) => {
  return get(`/filters?company_id=1&fields=page=1&filters=supporting_fields_|tax_exempted,employments,currency,payroll,trashed_type,payment_methods;organization_structure_fields_|department;preset_|salary;`)
}
