import React, { useEffect, useState } from "react";
import { Form, message, Skeleton, Row, Col } from "antd";
import { SaveButton, SelectWithSearch, CancelButton, ModalComponent, trans, InputDate } from "@comps/components"
import { makeRequest, makeRequestStateless, removeById } from "@utils/helpers";
import { getWorksheetsReq, updateAllocateWorkSheetReq } from "../requests";
import { Commons, Const } from "../../constants";
import { createSelectList } from '@comps/commonFunctions';
import { getEmploymentsByCriteria } from "@mods/common/requests";
import { useSelector } from "react-redux";
import dayjs from 'dayjs'


const langKey = Const["kpi"].lngKey
const formName = "AllocateWorkSheet";

const UpdateKpiAllocation = (props) => {

  const [loader, setLoader] = useState(false);
  const [saving, setsaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const [childComponent, setChildComponent] = useState(null)
  const authUser = useSelector((state) => state.auth.authUser);
  const [durationType, setDurationType] = useState('')
  const [workSheets, setWorkSheets] = useState([]);
  const [branches, setBranches] = useState([]);
  const [employments, setEmployments] = useState([]);
  const [verifeirs, setVerifiers] = useState([]);
  const [assignTos, setAssignTos] = useState([]);
  const [form] = Form.useForm()
  useEffect(() => {
    getData()

    // eslint-disable-next-line
  }, []);

  const getData = () => {
    // setWorkSheets(createSelectList(response.resources.data))
    getRelatedData()
    form.setFieldsValue(props.record)
    form.setFieldValue('kpi_ids', [props.record.kpi_id])

    setDurationType(props.record.recursion_type);
    form.setFieldValue('custom_date', props.record.custom_date ? dayjs(props.record.custom_date) : null)
    getRelatedData()
    form.setFieldsValue(props.record)
    form.setFieldValue('kpi_ids', [props.record.kpi_id])
    form.setFieldValue('custom_date', props.record.custom_date ? dayjs(props.record.custom_date) : null)
    getEmploymentByBranch(props.record.branch_id)
  }
  const getRelatedData = () => {
    makeRequestStateless(getWorksheetsReq, null, onGetRelated, false)
  }
  const onGetRelated = (response, data) => {
    setLoading(false)
    if (response) {
      setBranches(createSelectList(response.branches))
      setWorkSheets(createSelectList(response.kpis))
    }
  }

  const onSubmit = (data) => {
    const customDate = data.custom_date !== undefined ? data.custom_date : null;
    const payload = { id: props.record.id, kpi_allocation: { kpi_ids: data.kpi_ids, assignby_id: authUser.employment_id, branch_id: data.branch_id, assignto_id: data.assignto_id, verifiedby_id: data.verifiedby_id, recursion_type: data.recursion_type, custom_date: customDate } };
    setsaving(true)
    makeRequest(setLoader, updateAllocateWorkSheetReq, payload, onSuccess, onError);
  }

  const onSuccess = (data, res) => {

    setsaving(false)
    message.success(res.message);
    props.onCompleted(res.data);
    props.onSuccess(data)
  }

  const onError = (err, res) => {
    setLoader(false)
    setsaving(false)
    message.error(res.response.data.message);
  }

  const durations = [
    {
      label: 'Daily',
      value: "daily",
    },
    {
      label: "Custom Date",
      value: "custom_date"
    }
  ]
  const getEmploymentByBranch = (id) => {

    if (!id) {
      form.setFieldsValue({ assignto_id: null, verifiedby_id: null })
      setEmployments([])
      setVerifiers([])
      setAssignTos([])
      return
    }
    setLoader(true)
    const payload = { findBy: 'branch_id', id }
    makeRequestStateless(getEmploymentsByCriteria, payload, onSuccessGettingEmployments, onError)
  }
  const onSuccessGettingEmployments = (data, res) => {
    setLoader(false)
    setEmployments(data.employments)
    setVerifiers(createSelectList(removeById(data.employments, props.record.assignto_id)))
    setAssignTos(createSelectList(removeById(data.employments, props.record.verifiedby_id)))
  }
  const onChangeVerifyBy = (id) => {
    //set assignto
    const res = removeById(employments, id)
    setAssignTos(createSelectList(res))
  }
  const onChangeAssignTo = (id) => {
    //set verify
    const res = removeById(employments, id)
    setVerifiers(createSelectList(res))
  }
  //-----------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------
  const footer = [
    <SaveButton loading={ saving } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];
  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } width={ 1200 } maskClosable={ false }
      mainTitle={ trans(Commons.update) } subTitle={ trans(`${langKey}.allocation`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) } >
      { childComponent }
      <Skeleton loading={ loading }>
        <Form { ...formLayout } name={ formName } onFinish={ onSubmit } form={ form } scrollToFirstError>
          <Row gutter={ 30 }>
            {/* <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name='assignby_id' rules={ rules.assignby_id } label={ trans(`${langKey}.assignby_id`) }
              >
                <SelectWithSearch defaultValue={ authUser.employment_id } options={ employments } disabled />
              </Form.Item>
            </Col> */}
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name='kpi_ids' rules={ rules.kpi_id } label={ trans(`${langKey}.kpi_id`) }
              >
                <SelectWithSearch options={ workSheets } mode="multiple" placeholder={ trans(`${langKey}.kpi_id`) } disabled />
              </Form.Item>
            </Col>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name='branch_id' rules={ rules.branch_id } label={ 'Branch Name' }
              >
                <SelectWithSearch options={ branches } placeholder={ 'Branch Name' } onChange={ getEmploymentByBranch } />
              </Form.Item>
            </Col>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name='assignto_id' rules={ rules.assignto_id } label={ trans(`${langKey}.assignto_id`) }
              >
                <SelectWithSearch options={ assignTos } placeholder={ trans(`${langKey}.assignto_id`) } disabled={ employments.length < 1 } onChange={ onChangeAssignTo } />
              </Form.Item>
            </Col>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name='verifiedby_id' rules={ rules.verifiedby_id } label={ trans(`${langKey}.verifiedby_id`) }
              >
                <SelectWithSearch options={ verifeirs } placeholder={ trans(`${langKey}.verifiedby_id`) } disabled={ employments.length < 1 } onChange={ onChangeVerifyBy } />
              </Form.Item>
            </Col>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name='recursion_type' rules={ rules.recursion_type } label={ trans(`${langKey}.recursion_type`) }
              >
                <SelectWithSearch placeholder={ trans(`${langKey}.recursion_type`) } options={ durations } onChange={ (type) => setDurationType(type) } />
              </Form.Item>
            </Col>
            {
              durationType === 'custom_date' &&
              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name='custom_date' rules={ rules.custom_date } label={ trans(`${langKey}.custom_date`) }
                >
                  <InputDate />
                </Form.Item>
              </Col>
            }
          </Row>

        </Form>
      </Skeleton>

    </ModalComponent>
  )
}

export default UpdateKpiAllocation

const rules = {

  kpi_id: [
    { required: true, message: trans(`${langKey}.kpi_id`) },
  ],
  title: [
    { required: true, message: trans(`${langKey}.title`) },
  ],
  short_title: [
    { required: true, message: trans(`${langKey}.short_title`) },
  ],
  assignby_id: [
    { required: false, message: trans(`${langKey}.assignby_id`) },
  ],
  assignto_id: [
    { required: true, message: trans(`${langKey}.assignto_id`) },
  ],
  verifiedby_id: [
    { required: true, message: trans(`${langKey}.verifiedby_id`) },
  ],
  recursion_type: [
    { required: true, message: trans(`${langKey}.recursion_type`) },
  ],
  custom_date: [
    { required: true, message: trans(`${langKey}.custom_date`) },
  ],


};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}
