
import React from "react";
import { Route, Routes } from "react-router-dom";
import OrgnizationModules from './organizationManagement/routes';
import Dashboard from "./dashboard/DashboardIndex";
import EmployeeModules from "./employeeManagement/routes";
import GeneralMasterModules from "./masterManagement/general/routes";
import EndOfEmploymentsModules from "./EOEServiceManagement/routes";
import RosterModules from "./rosterManagement/routes";
import AssessmentModules from "./assessmentManagement/routes";
import LeaveModules from "./leaveManagement/routes";
import SystemMasterModules from "./masterManagement/system/routes";
import ExpenseModules from "./expenseManagement/routes";
import TravelManagementModules from "./travelManagement/routes";
import LendManagementModuls from "./lendManagement/routes";
import AttendanceManagementModules from "./attendanceManagement/routes";
import TransferPromotionModules from "./transferAndPromotionManagement/routes";
import PayrollManagementModules from "./payrollManagement/routes";
import PolicyManagementModules from "./policyManagement/routes";
import WorkflowManagementModules from "./workflowManagement/routes";
import ReportsModules from "./reports/routes";
import AuthApp from "./userManagement/auth/routes";
import BaseMuiDataTable from "../wrappers/layout/BaseMuiDataTable";

const ModuleRoutes = () => {
  return (
    <Routes>
       <Route index path="/" element={ <Dashboard modkey="dashboard"/> } /> 
      <Route index path="user/*" element={ <AuthApp /> } />
      <Route path="organization/*" element={ <OrgnizationModules /> } />
      <Route path="employees/*" element={ <EmployeeModules /> } />
      <Route path="masters/general/*" element={ <GeneralMasterModules /> } />
      <Route path="masters/system/*" element={ <SystemMasterModules /> } />
      <Route path="eoemanagement/*" element={ <EndOfEmploymentsModules /> } />
      <Route path="roster/*" element={ <RosterModules /> } />
      <Route path="assessment-management/*" element={ <AssessmentModules /> } />
      <Route path="leavemanagement/*" element={ <LeaveModules /> } />
      <Route path="expense-management/*" element={ <ExpenseModules /> } />
      <Route path="travel-management/*" element={ <TravelManagementModules /> } />
      <Route path="lend-management/*" element={ <LendManagementModuls /> } />
      <Route path="attendance/*" element={ <AttendanceManagementModules /> } />
      <Route path="transfer-promotion/*" element={ <TransferPromotionModules /> } />
      <Route path="payrollmanagement/*" element={ <PayrollManagementModules /> } />
      <Route path="policymanagement/*" element={ <PolicyManagementModules /> } />
      <Route path="workflowManagement/*" element={ <WorkflowManagementModules /> } />
      <Route path="reports/*" element={ <ReportsModules /> } />
      <Route path="/export-table-data" element={ <BaseMuiDataTable /> } />
    
      {/* <Route path="work-sheets/*" element={ <WorkSheetModules /> } /> */ }
    </Routes>
  )

}

export default ModuleRoutes;
