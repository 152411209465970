
import React, { useEffect, useState } from "react";
import { Button, Form, message, Row, Col, Skeleton, Select } from "antd";
import { trans, InputText, AddNewButton, TableComponent } from "@comps/components";
import { makeRequest, makeRequestStateless } from "@utils/helpers";
import { createSelectList, checkPermissions } from '@comps/commonFunctions';
import { getEmployeeRelatedDataReq, getSalaryPolicyByEmpId, updateSalaryPolicyReq } from "../requests";
import { Const } from '../../constants';
import { SelectWithSearch } from "@comps/components";
import "./Employee.css";
import CreatePayroll from "../../../payrollManagement/payrolls/CreatePayroll";
import SalaryHistories from "./SalaryHistories";

const langKey = Const["employee"].lngKey
var salary_benefits = [];
var benefit_groups = [];
var grade_benefits = [];

const UpdatePayrollPolicy = (props) => {
  const [loading, setLoading] = useState(true)
  const [form] = Form.useForm()
  const [loader, setLoader] = useState(false);
  const [payrolls, setPayrolls] = useState([]);
  const [paymentmethods, setPaymentmethods] = useState([]);
  const [policies, setPolicies] = useState([])
  const [gradeBenefits, setGradeBenefits] = useState([])
  const [banks, setBanks] = useState([]);
  const [levels, setLevels] = useState([]);
  const [childComponent, setChildComponent] = useState(null);
  const [updated, setUpdated] = useState(false);
  const [salaryBenefits, setSalaryBenefits] = useState([]);
  const [salary, setSalary] = useState();

  const salaryTypes = [
    {
      value: 'monthly',
      label: 'Monthly',
    },
    {
      value: 'daily',
      label: 'Daily Wages',
    },
    {
      value: 'hourly',
      label: 'Hourly',
    },
  ]

  useEffect(() => {
    getRemoteData()
    getDataByEmploymentId(props.id);
    // eslint-disable-next-line
  }, [updated]);

  const getRemoteData = () => {
    salary_benefits = [];
    benefit_groups = [];
    grade_benefits = [];
    makeRequestStateless(getEmployeeRelatedDataReq, false, onEmployeeRelatedDataSuccess, onEmployeeRelatedDataError)
  }
  const onEmployeeRelatedDataSuccess = (data, res) => {
    setLoading(false)
    setPayrolls(createSelectList(data.payrolls
    ))
    setPaymentmethods(createSelectList(data.payment_methods
    ))
    setPolicies(createSelectList(data.policies
    ))
  }

  const onEmployeeRelatedDataError = (err) => {
    console.error(err)
  }

  //select
  const getDataByEmploymentId = (id) => {
    makeRequest(setLoader, getSalaryPolicyByEmpId, id, onGetSalaryPolicyByEmployeeId, onErrorGetSalaryPolicyByEmployeeId);
  }
  const onGetSalaryPolicyByEmployeeId = (data, response) => {

    if (data !== undefined && data) {
      setSalary(data.salary);
      form.setFieldsValue(data.salary)
      form.setFieldValue('policy_id', data.policy.policy_planer_id)
      setSalaryBenefits(data.salary.salary_benefits);
      setGradeBenefits(data.grade_benefits)
      setLevels(data.level_detail ? data.level_detail : [])
      setBanks(data.banks)
      updateGradeBenefits(data)
    }
    setLoading(false)
  }
  const onErrorGetSalaryPolicyByEmployeeId = (err, response) => {
    setLoading(false)
  }

  const updateGradeBenefits = (data) => {

    for (var i in data.grade_benefits) {
      grade_benefits[data.grade_benefits[i].id] = data.grade_benefits[i];
      if (data.salary) {
        for (var j in data.salary.salary_benefits) {
          if (data.salary.salary_benefits[j].grade_benefit_id === data.grade_benefits[i].id) {
            grade_benefits[data.grade_benefits[i].id].selected = true;
            if (data.salary.salary_benefits[j].has_group === true) {
              if (!benefit_groups[data.grade_benefits[i].id]) {
                benefit_groups[data.grade_benefits[i].id] = [];
              }
              benefit_groups[data.grade_benefits[i].id] = data.salary.salary_benefits[j].benefit_group_ids;
              for (var x in grade_benefits[data.grade_benefits[i].id].benefit.benefit_groups) {
                var value = grade_benefits[data.grade_benefits[i].id].benefit.benefit_groups[x].id;
                var list = data.salary.salary_benefits[j].benefit_group_ids;
                if (list.indexOf(value) !== -1) {
                  grade_benefits[data.grade_benefits[i].id].benefit.benefit_groups[x].selected = true;
                }
              }
            }
            let obj = deepCopy(grade_benefits[data.grade_benefits[i].id]);
            obj.benefit_details = deepCopy(data.salary.salary_benefits[j].benefit_details);
            salary_benefits.push(obj);
          }
        }
        setGradeBenefits(grade_benefits)
      }
    }
  }
  function deepCopy(obj) {
    if (obj === null || typeof obj !== 'object') {
      return obj;
    }

    let copy = Array.isArray(obj) ? [] : {};

    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        copy[key] = deepCopy(obj[key]);
      }
    }

    return copy;
  }
  //------------on submit forms----------------
  const onSubmit = (data) => {
    let payload = { payroll_info: data, employmentId: props.id, salary_benefits: getSalaryBenefits() }
    makeRequest(setLoader, updateSalaryPolicyReq, payload, onSuccess, onError);
    setLoader(true)

  }

  const onSuccess = (response) => {
    setUpdated(!updated)
    setLoader(false)
    message.success('User Updated Successfully!')
  }
  const onError = (error, response) => {

    message.error(response.response.data.message)
  }

  //==========new payroll creation=====
  const onPayrollCreated = (data) => {
    if (data.length) {
      form.setFieldValue('payroll_id', data[0].id)
      setPayrolls(createSelectList(data))
    }
    setChildComponent(null);
  };
  //-----------------onChange--------------------

  const gradeBenefitEnabled = (grade_benefit, value) => {

    if (value) {
      salary_benefits.push(grade_benefit);
    } else {
      for (var i in salary_benefits) {
        if (salary_benefits[i].id === grade_benefit.id) {
          salary_benefits.splice(i, 1);
        }
      }
    }
  }
  const groupBenefitEnabled = (grade_benefit, benefit_group, value) => {

    if (value) {
      if (!benefit_groups[grade_benefit.id]) {
        benefit_groups[grade_benefit.id] = [];
      }
      benefit_groups[grade_benefit.id].push(benefit_group.id);
    } else {
      for (var i in benefit_groups) {
        if (parseInt(i) === grade_benefit.id) {
          for (var j in benefit_groups[i]) {
            if (benefit_groups[i][j] === benefit_group.id) {
              benefit_groups[i].splice(j, 1);
            }
          }
        }
      }
    }
  }

  const getSalaryBenefits = () => {

    var new_salary_benefits = [];
    for (var i in salary_benefits) {
      var salary_benefit = {};
      salary_benefit.benefit_details = JSON.stringify(salary_benefits[i].benefit_details);
      salary_benefit.grade_benefit_id = salary_benefits[i].id;
      if (!salary_benefit.benefit_group_ids) {
        salary_benefit.benefit_group_ids = [];
      }
      for (var j in benefit_groups) {
        if (parseInt(j) === salary_benefits[i].id) {
          for (var x in benefit_groups[j]) {
            salary_benefit.benefit_group_ids.push(benefit_groups[j][x]);
          }
        }
      }
      new_salary_benefits.push(salary_benefit);
    }
    return new_salary_benefits;
  }
  //-------------------end--------------------
  // ------------------------------------

  // ------------------------------------
  //---------------------form layout---------

  return (
    <>
      <div style={ { border: '1px solid #C9D6DF', marginTop: 20, borderRadius: 10 } }>
        <div className="employeeInfo" ><h5 className="employeeInfo-text">Payroll & Salary</h5></div>
        <Skeleton loading={ loading }>
          <Form
            { ...formLayout }
            layout="vertical"
            name={ 'updatePayroll' }
            onFinish={ onSubmit }
            form={ form }
            className="m-2"
            scrollToFirstError
            disabled={ !checkPermissions('employmentSalary', 'update') }
          >
            <div style={ { paddingTop: 25 } }>
              <Row>
                { childComponent }
                <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                  <AddNewButton onClick={ () => setChildComponent(<CreatePayroll onCompleted={ onPayrollCreated } />) } />
                  <Form.Item name="payroll_id" rules={ rules.payroll } label={ trans(`${langKey}.payroll`) } className="da-mb-16"
                  >
                    <SelectWithSearch options={ payrolls } placeholder={ "Payroll" } />
                  </Form.Item>
                </Col>

                <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                  <Form.Item name="type" label={ trans(`${langKey}.payrollType`) } rules={ rules.payrollType } className="da-mb-16"
                  >
                    <SelectWithSearch
                      options={ salaryTypes }
                      placeholder={ trans(`${langKey}.payrollType`) }
                    />
                  </Form.Item>
                </Col>

                <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                  <Form.Item name="payment_method_id" rules={ rules.payrollMethod } label={ trans(`${langKey}.payrollMethod`) } className="da-mb-16"
                  >
                    <SelectWithSearch options={ paymentmethods } placeholder={ "Payroll Method" } />
                  </Form.Item>
                </Col>

                <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                  <Form.Item name="gross_salary" rules={ rules.grossSalary } label={ trans(`${langKey}.grossSalary`) } className="da-mb-16"
                  >
                    <InputText type="number" placeholder={ "Gross Salary" } />
                  </Form.Item>
                </Col>

                <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                  <Form.Item name="policy_id" rules={ rules.policy } label={ trans(`${langKey}.policy`) } className="da-mb-16"
                  >
                    <SelectWithSearch options={ policies } placeholder={ "Policy" } />
                  </Form.Item>
                </Col>
              </Row>
            </div>
            <div className="employeeInfo" ><h5 className="employeeInfo-text">Benefits with Salary</h5></div>



            { gradeBenefits.length > 0 && gradeBenefits.map((grade_benefit, gbk) => (
              <table >
                <thead>
                  <tr>
                    <th>
                      <div><label className="m-2">{ grade_benefit.benefit.name } :  </label>
                        <Select defaultValue={ grade_benefit.selected ? true : false } options={ [{ label: 'Applied', value: true }, { label: 'Not Applied', value: false }] } onChange={ (value) => gradeBenefitEnabled(grade_benefit, value) } />
                      </div>
                      {/* <div><label className="m-2">{key+1 +'. '+ grade_benefit.benefit.name} :  </label>
              <label> No</label>
                  <Switch style={ { margin: 10 } } onChange={(value)=>gradeBenefitEnabled(grade_benefit,value)} checked={grade_benefit.selected?true:false}/>
                  <label>Yes</label>
              </div> */}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    { grade_benefit.benefit.benefit_groups.map((group, gk) => (
                      <td>
                        <div><label className="m-2">{ gk + 1 + '. ' + group.name } :  </label>
                          <Select defaultValue={ group.selected ? true : false } options={ [{ label: 'Included', value: true }, { label: 'Not Included', value: false }] } onChange={ (value) => groupBenefitEnabled(grade_benefit, group, value) } />
                          {/* <InputCheck onChange={(e)=>groupBenefitEnabled(grade_benefit,group,e.target.checked)} checked={group.selected?true:false}/> */ }
                        </div>
                      </td>
                    )) }
                  </tr>
                  <hr />
                </tbody>
              </table>
            )) }

            <div className="employee-button" >
              <Form.Item style={ { float: 'right' } }>
                <Button loading={ loader } className="employee-next-button" form="updatePayroll" style={ { fontWeight: 600 } } type="primary" htmlType="submit" >
                  Update
                </Button>
              </Form.Item>
            </div>



          </Form>

          <SalaryHistories employmentId={ props.id } salary={ salary } />
        </Skeleton>

        {/* <div>
          <Button
            style={ {
              width: 72,
              height: 28,
              borderRadius: 20,
              background: "#4079FC",
              border: "1px solid #4079FC",
              marginTop: -370,
              float: 'right',
              marginRight: 15
            } }
            type="primary"
            onClick={ handleEditClick }
          >
            <span
              style={ {
                color: '#FFFFFF',
                fontSize: 14,
                fontStyle: 'normal',
                fontWeight: 700,
                lineHeight: 'normal',
                position: 'relative',
                right: 3,
                bottom: 2
              } }
            ><EditOutlined /> Edit</span>
          </Button>
        </div> */}

      </div>

    </>)

}

export default UpdatePayrollPolicy;

const rules = {
  payroll: [
    { required: true, message: trans(`${langKey}.payroll.required`), },
  ],
  payrollType: [
    { required: true, message: trans(`${langKey}.payrollType.required`), },
  ],
  payrollMethod: [
    { required: true, message: trans(`${langKey}.payrollMethod.required`), },
  ],
  grossSalary: [
    { required: true, message: trans(`${langKey}.grossSalary.required`), },
  ],
  policy: [
    { required: false, message: trans(`${langKey}.policy.required`), },
  ],

};


const formLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 23 },
  labelAlign: "left"
}
