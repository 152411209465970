import React, { useEffect, useState } from "react";
import { Form, message } from "antd";
import { SaveButton, InputText, InputTextArea, CancelButton, ButtonComponent, ModalComponent, trans } from "@comps/components"
import { makeRequest } from "@utils/helpers";
import { updateDataReq } from "../requests";
import { Commons, Const } from "../../../constants";
import { PlusCircleOutlined } from "@ant-design/icons";
import CreateExpression from "../../formula/components/CreateExpression";

const langKey = Const["rule"].lngKey
const formName = "createRule";

const UpdateRule = (props) => {
  const [loader, setLoader] = useState(false);
  const [saving, setsaving] = useState(false);
  const [childComponent, setChildComponent] = useState(false);
  const [form] = Form.useForm()
  const [expression, setExpression] = useState('');
  const [isValid, setIsValid] = useState(true)
  useEffect(() => {
    setData()
    // eslint-disable-next-line
  }, []);

  const setData = () => {
    form.setFieldsValue(
      props.record
    )
    setExpression(props.record.expression)
  }

  const onSubmit = (data) => {
    if (expression === '' || expression === null || expression === undefined) {
      message.error('Please enter a valid expression')
      return
    }
    setsaving(true)
    const payload = {
      id: props.record.id,
      payroll_rule: {
        ...props.record,
        name: data.name,
        expression: expression,
      }
    };
    makeRequest(setLoader, updateDataReq, payload, onSuccess, onError);
  }

  const onSuccess = (data, res) => {

    message.success(res.message);
    props.onCompleted(data.payroll_rules.data);
  }

  const onError = (err, res) => {

    setsaving(false)
    message.error(res.response.data.message);
  }

  const onCreateExpression = () => {
    const options = {
      exclude: null,
      formulas: true,
      payitems: null,
      rules: null
    }
    setChildComponent(<CreateExpression onCompleted={ onCompleted } record={ expression } isValid={ isValid } options={ options } />)
  }

  const onCompleted = (data, isValid) => {
    if (data) {
      setExpression(data)
      setIsValid(isValid)
    }

    setChildComponent(null)
  }
  //-----------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [
    props.disabled ? '' :
      <SaveButton loading={ saving } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } width={ 600 } maskClosable={ false }
      mainTitle={ trans(Commons[props.disabled ? 'view' : 'update']) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      { childComponent }
      <Form { ...formLayout } name={ formName } onFinish={ onSubmit } form={ form } disabled={ props.disabled }>

        <Form.Item name="name" rules={ rules.name } label={ trans(Commons.name) }
          className="da-mb-16"
        >
          <InputText placeholder={ trans(`${langKey}.name`) } />
        </Form.Item>
        <Form.Item style={ { paddingRight: 35 } } name="expression" label={ trans(`${langKey}.expression`) }
          className="da-mb-16"
        >
          <InputTextArea placeholder={ trans(`${langKey}.expression`) } disabled={ true } value={ expression } /><ButtonComponent className="formula-btn" onClick={ onCreateExpression } icon={ <PlusCircleOutlined style={ { marginLeft: -7 } } /> } />
        </Form.Item>

      </Form>
    </ModalComponent>
  )
}

export default UpdateRule

const rules = {
  name: [
    { required: true, message: trans(`${langKey}.name`) },
  ],
  expression: [
    { required: true, message: trans(`${langKey}.expression`) },
  ],


};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}