import React, { useEffect, useState } from "react";
import { Form, message, Checkbox, Modal, Skeleton, Row, Col } from "antd";
import { SaveButton, InputText, CancelButton, ModalComponent, trans, SelectResourceList, UploadFile, UploadButton, InputDate } from "@comps/components"
import { makeRequest, makeRequestStateless, getErrorProps } from "@utils/helpers";
import { createEducationReq, getAllEmployeesReq } from "../requests";
import { Commons, Const } from "../../constants";

const langKey = Const["education"].lngKey

const formName = "createEducation";

const CreateEducation = (props) => {

  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [isDegreeCompleted, setIsDegreeCompleted] = useState(false);
  const [saving, setsaving] = useState(false);
  const [loading, setloading] = useState(true);
  const [form] = Form.useForm()

  useEffect(() => {
    makeRequestStateless(getAllEmployeesReq, false, ongetAllEmployees, false)
    form.setFieldValue('employee_id', props.filters !== undefined ? props.filters.replace('employments_|', '') : null)
    // eslint-disable-next-line
  }, []);
  const ongetAllEmployees = (response) => {
    setloading(false)
  }

  const onSubmit = (data) => {
    setsaving(true)
    if (data.degree_attachment !== undefined) {
      data.degree_attachment.file = data.degree_attachment.fileList.length > 0 ? data.degree_attachment.file : null
    }
    const payload = { employee_education: data, filters: props.filters }
    makeRequest(setLoader, createEducationReq, payload, onSuccess, onError);
    setsaving(true)
  }

  const onSuccess = (data, res) => {
    message.success("Education Saved successfully");
    props.onCompleted(data.employee_educations.data);
    props.onSuccess(data)
  }

  const onError = (err, res) => {

    setsaving(false)
    message.error(res.response.data.message);
    //setErrors(errorList);
  }
  //-----------on changes------------
  const onChangeIsDegree = (e) => {
    setIsDegreeCompleted(e.target.checked)
  }
  //============uploading file================
  const [fileList, setFileList] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const handleCancel = () => setPreviewOpen(false);
  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };
  const onPreview = async (file) => {

    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };
  // ------------------------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [
    <SaveButton loading={ saving } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } width={ 1000 } maskClosable={ false }
      mainTitle={ trans(Commons.create) } subTitle={ trans(`${langKey}.education`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Skeleton loading={ loading }>
        <Form { ...formLayout } name={ formName } onFinish={ onSubmit } form={ form } scrollToFirstError>
          <Row gutter={ 30 }>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="employee_id" label={ trans(`${langKey}.select.employee`) } rules={ rules.employee_id } className="da-mb-16"
                { ...getErrorProps(errors['employee_id']) }
              >
                <SelectResourceList type='employee'
                  disabled={ props.filters !== undefined }
                />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="institute_name" rules={ rules.institute_name } label={ trans(`${langKey}.institute_name`) }
                className="da-mb-16"
                { ...getErrorProps(errors['institute_name']) } size="small"
              >
                <InputText placeholder={ trans(`${langKey}.institute_name`) } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="degree" rules={ rules.degree } label={ trans(`${langKey}.degree`) }
                className="da-mb-16"
                { ...getErrorProps(errors['degree']) } size="small"
              >
                <InputText placeholder={ trans(`${langKey}.degree`) } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item valuePropName="checked" name="is_degree_completed" label={ trans(`${langKey}.is_degree_completed`) } className="da-mb-16">
                <Checkbox onChange={ onChangeIsDegree }></Checkbox>
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="from_date" rules={ rules.from_date } label={ trans(`${langKey}.from_date`) }
                className="da-mb-16"
                { ...getErrorProps(errors['from_date']) } size="small"

              >
                <InputDate format='YYYY-MM-DD' />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="to_date" rules={ isDegreeCompleted ? rules.to_date : [] } label={ trans(`${langKey}.to_date`) }
                className="da-mb-16"
                { ...getErrorProps(errors['to_date']) } size="small"

              >
                <InputDate format='YYYY-MM-DD' />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="country" rules={ rules.country } label={ trans(`${langKey}.country`) }
                className="da-mb-16"
                { ...getErrorProps(errors['country']) } size="small"
              >
                <InputText placeholder={ trans(`${langKey}.country`) } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item label={ trans(`${langKey}.attachment`) } name="degree_attachment" >
                <UploadFile
                  accept=".jpg,.png,.jpeg" listType="picture-card"
                  fileList={ fileList }
                  onChange={ onChange }
                  onPreview={ onPreview }
                  multiple={ false }
                  beforeUpload={ (file) => {
                    return false;
                  } }
                >
                  { fileList.length === 1 ? null : <UploadButton /> }
                </UploadFile>
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="city" rules={ rules.city } label={ trans(`${langKey}.city`) }
                className="da-mb-16"
                { ...getErrorProps(errors['city']) } size="small"

              >
                <InputText placeholder={ trans(`${langKey}.city`) } />
              </Form.Item>
            </Col>

          </Row>
        </Form>
      </Skeleton>


      <Modal open={ previewOpen } title={ previewTitle } footer={ null } onCancel={ handleCancel }>
        <img
          alt="example"
          style={ {
            width: '100%',
          } }
          src={ previewImage }
        />
      </Modal>

    </ModalComponent>
  )
}

export default CreateEducation

const rules = {
  degree: [
    { required: true, message: trans(`${langKey}.degree.req`) },
  ],
  employee_id: [
    { required: true, message: trans(`${langKey}.employee_id.req`) },
  ],
  institute_name: [
    { required: true, message: trans(`${langKey}.institute_name.req`) },
  ],
  from_date: [
    { required: true, message: trans(`${langKey}.from_date.req`) },
  ],
  to_date: [
    { required: true, message: trans(`${langKey}.to_date.req`) },
  ],
};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}