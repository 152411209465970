
import { useState, useEffect } from "react";
import {
  BodyComponent,
  trans,
  HeaderComponent,
  SaveButton,
  InputText
} from "@comps/components";
import { Checkbox, Form, Skeleton, message } from "antd";
import { makeRequest } from "@utils/helpers";
import { Const } from "../../constants";
import { getAllDataReq, createDataReq } from "./requests";


const langKey = Const["attendanceConfiguration"].lngKey;
const pageConfig = {
  headers: {
    title: trans(`${langKey}.moduleName`),
    breadcrumb: [
      {
        name: trans(`${langKey}.moduleName`),
        path: Const["attendanceConfiguration"].route
      }
    ]
  }
}

const IndexAttendanceConfigurations = (props) => {
  const [loader, setLoader] = useState(false);
  const [configuration, setConfiguraion] = useState({});
  const [saving, setSaving] = useState(false)
  const [loading, setLoading] = useState(true)

  const [form] = Form.useForm()


  useEffect(() => {
    getAllData();
    // eslint-disable-next-line
  }, []);

  const getAllData = () => {
    makeRequest(setLoader, getAllDataReq, null, onSuccess, onSuccess);
  };

  const onSuccess = (response, data) => {

    setConfiguraion(data.general)
    form.setFieldsValue(data.general)
    setLoading(false)
  };

  const onSubmit = (data) => {
    setSaving(true)
    const payload = { configurations: data }
    makeRequest(setLoader, createDataReq, payload, onCreated, onError);
  }
  const onCreated = (response, data) => {

    setSaving(false)
    getAllData()
    message.success('Configurations Set Successfully!')
  };
  const onError = (err, res) => {
    message.success('Configurations Set Successfully!')
    getAllData()
    setSaving(false)
    message.error(res.response.data.message);
  }
  return (
    <>
      <BodyComponent>
        <HeaderComponent headers={ pageConfig.headers } modkey={props.modkey}>
        </HeaderComponent>
        <Skeleton loading={ loading } >
          <div style={ { background: "white", borderRadius: 10 } }>
            <SaveButton key="create_button" htmlType="submit" form="attendanceConfigurationForm" loading={ saving } style={ { float: 'right', marginRight: 20, marginTop: 18 } } />

            <Form { ...formLayout } onFinish={ onSubmit } form={ form } name="attendanceConfigurationForm" style={ { paddingLeft: 20, paddingRight: 20 } } scrollToFirstError>

              <h5 className="formlist-title" style={ { borderBottom: "1px solid #C9D6DF", height: 80, paddingTop: 30 } }>{ trans(`${langKey}.general`) } </h5>

              <table class="table-configurations table table-bordered table-striped table-condensed" >
                <tbody>
                  <tr>
                    <td>
                      <Form.Item name="ignore_punch_type" label={ trans(`${langKey}.ignore_punch_type`) }
                        className="da-mb-16"
                        valuePropName="checked"
                      >
                        <Checkbox />
                      </Form.Item>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <Form.Item name="ignore_short_duration" label={ trans(`${langKey}.ignore_short_duration`) }
                        className="da-mb-16"
                        valuePropName="checked"
                      >
                        <Checkbox />
                      </Form.Item>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <Form.Item name="is_checkin_overtime" label={ trans(`${langKey}.is_checkin_overtime`) }
                        className="da-mb-16"
                        valuePropName="checked"
                      >
                        <Checkbox />
                      </Form.Item>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <Form.Item name="is_checkout_overtime" label={ trans(`${langKey}.is_checkout_overtime`) }
                        className="da-mb-16"
                        valuePropName="checked"
                      >
                        <Checkbox />
                      </Form.Item>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <Form.Item name="overtime_approval_required" label={ trans(`${langKey}.overtime_approval_required`) }
                        className="da-mb-16"
                        valuePropName="checked"
                      >
                        <Checkbox />
                      </Form.Item>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <Form.Item name="overtime_after_duty_time" label={ trans(`${langKey}.overtime_after_duty_time`) }
                        className="da-mb-16"
                        valuePropName="checked"
                      >
                        <Checkbox />
                      </Form.Item>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <Form.Item name="mark_attendance_allowed" label={ trans(`${langKey}.mark_attendance_allowed`) }
                        className="da-mb-16"
                        valuePropName="checked"
                      >
                        <Checkbox />
                      </Form.Item>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <Form.Item name="multi_shift_allowed" label={ trans(`${langKey}.multi_shift_allowed`) }
                        className="da-mb-16"
                        valuePropName="checked"
                      >
                        <Checkbox />
                      </Form.Item>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <Form.Item name="min_workable_minutes" label={ trans(`${langKey}.min_workable_minutes`) }
                        className="da-mb-16"
                      >
                        <InputText type="number" />
                      </Form.Item>
                    </td>
                  </tr>
                  <h5 className="formlist-title" style={ { borderBottom: "1px solid #C9D6DF", height: 80, paddingTop: 30 } }>{ trans(`${langKey}.mobile`) } </h5>
                  <tr>
                    <td>
                      <Form.Item name="mark_attendance_allowed_mobile" label={ trans(`${langKey}.mark_attendance_allowed_mobile`) }
                        className="da-mb-16"
                        valuePropName="checked"
                      >
                        <Checkbox />
                      </Form.Item>
                    </td>
                  </tr>
                </tbody>
              </table>

            </Form>
          </div>
        </Skeleton>
      </BodyComponent>
    </>
  )

}

export default IndexAttendanceConfigurations


const formLayout = {
  // layout: "horizontal",
  labelCol: { span: 6 },
  wrapperCol: { span: 14, offset: 6 },
  labelAlign: "left"
}
