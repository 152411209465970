import React, { useEffect, useState } from "react";
import { Button, Form, Skeleton, message, Row, Col } from "antd";
import { SaveButton, InputText, InputTextArea, CancelButton, ModalComponent, trans, InputDate, InputTime, TableComponent, ActionComponent, FlowHistoryButton } from "@comps/components"
import { makeRequest, getErrorProps } from "@utils/helpers";
import { Commons, Const } from "../../../constants";
import moment from "moment/moment";
import RoasterView from "@mods/common/RoasterView";
import { approveRequest, getRosterReq } from "../requests";
import CreateConsent from "@mods/common/CreateConsent";
import CreateConsentChat from "@mods/common/CreateConsentChat";
import EmployeeInfo from '@mods/common/EmployeeInfo';
import { convertTimeTotz } from '@comps/commonFunctions';
const langKey = Const["overtimeApproval"].lngKey
const formName = "updateOvertimeApproval";

const UpdateOvertimeApproval = (props) => {
  const [loader, setLoader] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [roasterView, setRoasterView] = useState(null)
  const [preEdit, setPreEdit] = useState(true)
  const [consents, setConsents] = useState([]);
  const [creatConsentModal, setCreateConsentModal] = useState(null);
  const [loading, setLoading] = useState(true)
  const [childComponent, setChildComponent] = useState(null)
  const [form] = Form.useForm()
  useEffect(() => {
    setData();
    // eslint-disable-next-line
  }, []);
  const setData = () => {
    setConsents(props.record.consents)
    let date = props.record.date ? convertTimeTotz(props.record.date) : null
    getRoster(date)
    props.record.date = date
    props.record.start = props.record.start ? convertTimeTotz(props.record.start) : null
    props.record.end = props.record.end ? convertTimeTotz(props.record.end) : null
    form.setFieldsValue(props.record);
    form.setFieldValue('employment_id', props.record.requestor.full_name_code);
  }
  const getRoster = (date) => {
    const payload = { overtime: { employment_id: props.record.employment_id, date: date.format('YYYY-MM-DD') } }
    makeRequest(setLoader, getRosterReq, payload, onGetRosterSuccess, onGetRosterSuccess);
  }
  const onGetRosterSuccess = (response, data) => {
    setLoading(false)
    if (data.shifts) {
      setRoasterView(<RoasterView record={ data } />)
      let start_time = data.shifts[moment(data.from_date).format('YYYYMMDD') + '-' + data.employments[0].id][0]['start']
      let end_time = data.shifts[moment(data.from_date).format('YYYYMMDD') + '-' + data.employments[0].id][0]['end']
      if (preEdit === false) {
        form.setFieldsValue({
          start_time: convertTimeTotz(start_time),
          end_time: convertTimeTotz(end_time),
        })
      }
    }
    else {
      message.error('No Shift Found For Given Date!')
      return
    }
  }

  //===========constents =============
  const onCreateConsent = () => {
    setCreateConsentModal(<CreateConsent onCreatedConsent={ onCreatedConsent } employment_id={ props.record.employment_id } request_id={ props.record.id } request_as={ props.requestAsId } api="overtimeApprovals/createConsent" />)
  }
  const onCreatedConsent = (response) => {
    if (response) {
      message.success("Consent created successfully");
      props.onCompleted(response.overtimes.data);
    }
    setCreateConsentModal(null)
  }
  const onConsentChat = (record) => {
    setCreateConsentModal(<CreateConsentChat record={ record } onCreatedConsent={ onCreatedConsent } request_as={ props.requestAsId } api="overtimeApprovals/messageConsent" />)
  }
  //============end consents================================
  const onSubmit = (isApproval) => {
    const approvedDuration = form.getFieldValue('approved_duration');
    if (isApproval && !approvedDuration) {
      message.info('Enter Approved Duration')
      return;
    }
    const last_remarks = form.getFieldValue('last_remarks');
    let requestObj = {
      request: {
        ...props.record, approved_duration: approvedDuration,
        current_history: { approved_duration: approvedDuration, remarks: last_remarks },
        is_approval: isApproval,
        request_as: props.requestAsId, last_remarks: last_remarks, remarks: last_remarks,
      },

      delegator_id: props.delegatorId, api: props.api, filters: props.filters,
    }
    const payload = requestObj
    makeRequest(setLoader, approveRequest, payload, onSuccess, onError);
  }
  const onSuccess = (data, res) => {
    setSaveLoader(false)
    message.success("Saved successfully");
    props.onCompleted(data.overtimes.data);
  }
  const onError = (err, res) => {
    setSaveLoader(false)
    message.error(res.response.data.message);
  }
  //----------------------
  const consentColumns = [
    {
      key: 'consentor_name',
      title: trans(`${langKey}.consentor_name`),
      dataIndex: 'consentor_name'
    },
    {
      key: 'consentee_name',
      title: trans(`${langKey}.consentee_name`),
      dataIndex: 'consentee_name'
    },
    {
      key: 'consent_date',
      title: trans(`${langKey}.consent_date`),
      render: (record) => {
        return record.created_at ? moment(record.created_at).format('DD-MM-YYYY h:mm:ss a') : null
      }
    },
    {
      key: trans(Commons.actions),
      title: trans(Commons.actions),
      render: (record) => (
        <ActionComponent
          each={ record } modkey={props.modkey}
          onView={ onConsentChat }
        ></ActionComponent>
      ),
    },
  ]
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------
  const footer = [
    childComponent,
    <div style={ { float: 'left' } }>
      < FlowHistoryButton record={ props.record } setChildComponent={ setChildComponent } />
    </div>,
    <SaveButton disabled={ props.disabled } loading={ saveLoader } form={ formName } key="create_button" onClick={ () => onSubmit(true) } text='Approve' />,
    <CancelButton disabled={ props.disabled } loading={ saveLoader } key="reject_button" form={ formName } htmlType='submit' text='Reject' onClick={ () => onSubmit(false) } />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];
  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------
  return (
    <ModalComponent top={ 20 } width={ 1200 } maskClosable={ false }
      mainTitle={ trans(props.disabled ? Commons.view : Commons.update) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Skeleton loading={ loading }>
        <EmployeeInfo record={ props.record.requestor && props.record.requestor } />
        <Form { ...formLayout } name={ formName } onFinish={ onSubmit } form={ form } disabled={ true } scrollToFirstError>

          <h5 className="formlist-title">Overtime Request</h5>
          <Row gutter={ 20 }>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="date" rules={ rules.date } label={ trans(`${langKey}.date`) }
                className="da-mb-16"
                { ...getErrorProps(errors['date']) } size="small"
              >
                <InputDate />
              </Form.Item>
            </Col>
          </Row>
          { roasterView }
          <Row gutter={ 30 }>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="start" rules={ rules.start } label={ trans(`${langKey}.start`) }
                className="da-mb-16"
                { ...getErrorProps(errors['start']) } size="small"
              >
                <InputTime placeholder={ trans(`${langKey}.start`) } />
              </Form.Item>
            </Col>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="end" rules={ rules.end } label={ trans(`${langKey}.end`) }
                className="da-mb-16"
                { ...getErrorProps(errors['end']) } size="small"
              >
                <InputTime placeholder={ trans(`${langKey}.end`) } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="description" rules={ rules.description } label={ trans(`${langKey}.description`) }
                className="da-mb-16"
                { ...getErrorProps(errors['description']) } size="small"
              >
                <InputTextArea placeholder={ trans(`${langKey}.description`) } />
              </Form.Item>
            </Col>
          </Row>


          <h5 className="formlist-title">Approval</h5>
          <Row gutter={ 30 }>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="approved_duration" rules={ rules.approved_duration } label={ trans(`${langKey}.approved_duration`) }
                className="da-mb-16"
                { ...getErrorProps(errors['approved_duration']) } size="small"
              >
                <InputText placeholder={ trans(`${langKey}.approved_duration`) } type='number' disabled={ false } onChange={ (e) => e.target.value > props.record.duration ? message.info('Maximum Duration only ' + props.record.duration) && form.setFieldValue('approved_duration', null) : '' } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="last_remarks" rules={ rules.remarks } label={ trans(`${langKey}.remarks`) }
                className="da-mb-16"
                { ...getErrorProps(errors['remarks']) } size="small"
              >
                <InputTextArea placeholder={ trans(`${langKey}.remarks`) } disabled={ false } />
              </Form.Item>
            </Col>
          </Row>

        </Form>

        <h5 className="formlist-title" style={ { paddingTop: 0 } }>Consents <Button type="primary" title='Create' onClick={ onCreateConsent } className="float-end me-5">Create</Button></h5>
        { creatConsentModal }
        <TableComponent dataSource={ consents } columns={ consentColumns } />
      </Skeleton>
    </ModalComponent>
  )
}

export default UpdateOvertimeApproval

const rules = {
  name: [
    { required: true, message: '' },
  ],
  approved_duration: [
    { required: true, message: '' }
  ],

};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}