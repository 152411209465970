

import { CloseOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React from 'react';
import { useDrop } from 'react-dnd';

  const WidgetColumn = ({ id, onDrop,onRemove,onMoveUp,onMoveDown, children,rowIndex ,column,disabled}) => {
    const [{ isOver }, drop] = useDrop(() => ({
      accept: 'WIDGET',
      drop: (item) => onDrop(item.id, id,rowIndex),
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    }));
  
  return(
    <div style={{display:'flex'}}>
     
    <div
      ref={drop}
      style={{
        height: '105px',
        maxHeight: '100%',
        width: '100%',
        border: '2px dashed green',
        backgroundColor: isOver ? 'lightgreen' : 'white',
        margin: '5px',
        display: 'inline-block',
        verticalAlign: 'top',
       // position: 'relative',
        
      }}
    >
      {children}
      
    </div>
    {!disabled && column.widgets && column.widgets.length>0 &&   <Tooltip title="Clear widget"><div style={{ marginTop:'16px' }}>
            <li style={{listStyle:'none',marginRight:'5px'}}> {onRemove && <CloseOutlined onClick={onRemove} />}</li>
          </div></Tooltip>}
        
        </div>
  )
}

export default WidgetColumn;


