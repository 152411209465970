import React, { useState, useEffect,useRef } from 'react';
import { Row, Col, Spin } from 'antd';
import { makeRequest } from '@utils/helpers';
import { getMyTeamReq } from '../requests';
import "../dashboard.css"
import useInViewport from '../useInViewport';
import { RefreshButton } from '../../../wrappers/components';
const MyTeam = () => {
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const viewRef = useRef(null);
  const viewport = useInViewport(viewRef);
  useEffect(() => {
    if (viewport && data.length === 0) {
    makeRequest(setLoader, getMyTeamReq, null, onSuccess, onError)
    }
  }, [viewport]);

  const onSuccess = (data) => {
    setData(data.subordinates);
  }

  const onError = (error) => {
  }

  return (
    <div className='widgetbox' ref={viewRef}>
      <Row >
        <Col span={ 24 } >
          <h3><span>My Team</span></h3>
        </Col>
      </Row>
      <div className='birthdaycontent'>
        <div className='birthdaymain'>
          <Spin spinning={ loader } tip="Loading...">
            { data && data.map((birthday, index) => (
              <Row className='birthdaysingle' key={ index }>
                <Col span={ 12 }>
                  <div className='birthdayimg'>
                    <img src={ birthday.profile_pic_url } alt='birthday' />
                  </div>
                  <div className='birthdaydetails'>
                    <h4 className='birthdayname'>{ birthday.full_name }</h4>
                    <p className='birthdaycode'>{ birthday.designation_name }</p>
                  </div>
                </Col>
                <Col span={ 12 } style={ { textAlign: 'right' } }>
                  {/* <h4 className='birthdaydate'>{ moment(birthday.d_o_b).format('MMMM DD') }</h4> */ }
                </Col>
              </Row>
            )) }
          </Spin>
        </div>
      </div>
    </div>
  );
}

export default MyTeam;