import { get, del, post, postFd, put, download } from "@utils/axios";

const api = "attendanceLogs";

export const getAllDataReq = (payload) => {
  return get(`${api}?page=${payload.page}&filters=${payload.filters}`)
};
export const getRelatedDataReq = (payload) => {
  return get(`${api}/getRelatedData`)
};

export const createDataReq = (payload) => {
  return post(`${api}`, payload);
};

export const updateDataReq = (payload) => {
  return put(`${api}/${payload.id}`, payload);
};
export const cancelRequestReq = (payload) => {
  return post('cancelRequest', payload);
};

export const deleteDataReq = (payload) => {
  return del(`${api}/${payload.id}?page=1&filters=${payload.filters}`);
};

export const getRosterReq = (payload) => {
  return post(`${api}/getRoster`, payload);
};
export const getExpectedTimeReq = (payload) => {
  return get(`${api}/getExpectedTime?&attendance_date=${payload.date}&employment_id=${payload.employment_id}`);
};

export const getFilterReq = (payload) => {
  return get(`filters?company_id=1&fields=page=1&filters=supporting_fields_|machine,punch_type,mapped_type,employments;preset_|attendance_logs;`)
}
export const onDateChange = (payload) => {
  return post(`${api}/dateChange?&`, payload)
}
export const onShiftSelectionChangeReq = (payload) => {
  return post(`${api}/shiftSelectionChange?&`, payload)
}

export const getRelatedDataByReqIdReq = (payload) => {
  return get(`${api}/${payload}`)
};

//import machine attendance

export const importMachineAttendanceReq = (payload) => {
  return post(`machines/pushLogRequests`, payload)
};


//download log sample
export const downloadSampleLogReq = (payload) => {
  return download(`${api}/log-sample`)
};
//download log sample By date
export const downloadSampleLogByDateReq = (date) => {
  return download(`${api}/log-dynamic-sample?date=${date}`)
};
//import attendance
export const importAttendanceReq = (payload) => {
  return postFd(`${api}/import`, payload)
};
