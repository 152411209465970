import { DeleteOutlined, EditOutlined, PlusOutlined, EyeOutlined } from "@ant-design/icons"
import { Button, Form, Space } from "antd"
import { useEffect } from "react"
import { Commons, Const } from "./constants";
import { InputText, SelectWithSearch, trans, ActionComponent } from "@comps/components"
const langKey = Const["policySlab"].lngKey
const ViewPolicySlabList = (props) => {

  useEffect(() => {

    // eslint-disable-next-line
  }, []);


  return (
    <Form.List name="slabs" >
      { (fields, { add, remove }) => (
        <>
          <Button onClick={ () => {
            props.onCreate()
          } } icon={ <PlusOutlined /> } type="primary" className="add-node-button">{ trans(`${langKey}.add`) } </Button>
          <div className="table-container">
            <table className="table">
              {
                fields.length !== 0 && <thead>
                  <tr>

                    {
                      props.fields.map(config => (
                        <th style={ { paddingLeft: 20 } }>{ trans(`${langKey}.${config.name}`) }</th>
                      ))
                    }
                    { props.type !== 'planner' && <th>{ trans(Commons.actions) }</th> }

                  </tr>
                </thead>
              }
              <tbody>
                { fields.map(({ key, name, ...restField }) => (
                  <>
                    <tr>
                      {
                        props.fields.map(config => (
                          <td style={ { minWidth: '200px', paddingLeft: 20 } }>
                            <Form.Item
                              { ...restField }
                              name={ [name, config.name] }
                            //rules={ rules.min_times }
                            >
                              {
                                config.type === 'text' || config.type === 'number' ?
                                  <InputText type={ config.type } disabled />
                                  : <SelectWithSearch options={ config.options } disabled />

                              }
                            </Form.Item>
                          </td>
                        ))
                      }

                      {
                        props.type !== 'planner' &&
                        <td>
                          <Space className="formlist-remove">
                            <DeleteOutlined onClick={ () => {
                              remove(name)
                              props.setRows(key, 'Deleted')
                            } } />
                            <EditOutlined className="ms-3" onClick={ () => {
                              props.onEdit(key)
                            } } />
                            <EyeOutlined className="ms-3" onClick={ () => {
                              props.onEdit(key)
                            } } />
                          </Space>
                        </td>
                      }
                    </tr>
                  </>
                )) }
              </tbody>
            </table>
          </div>
        </>
      ) }
    </Form.List>
  )
}
export default ViewPolicySlabList

const rules = {
  min_times: [
    { required: true, message: trans(`${langKey}.min_times`) },
  ],
  repeat_id: [
    { required: true, message: trans(`${langKey}.repeat_id`) },
  ],
  deduction_type_id: [
    { required: true, message: trans(`${langKey}.deduction_type_id`) },
  ],
  fallback_type_id: [
    { required: true, message: trans(`${langKey}.fallback_type_id`) },
  ],

}

//required props
//!slab.deduction_type.from_salary (fallback type)