import React, { useEffect, useState } from "react";
import { Form, message, Row, Col } from "antd";
import { SaveButton, InputText, InputTextArea, SelectWithSearch, CancelButton, ModalComponent, trans } from "@comps/components"
import { makeRequest } from "@utils/helpers";
import { updateDataReq, } from "../requests";
import { Commons, Const } from "../../constants";

import CreatePolicySlab from "@mods/common/CreatePolicySlab";
import UpdatePolicySlab from "@mods/common/UpdatePolicySlab";
import ViewPolicySlabList from "@mods/common//ViewPolicySlabList";
import { createSelectList } from '@comps/commonFunctions';
const langKey = Const["cplPolicy"].lngKey
const formName = "createCplPolicy";

const UpdateCplPolicy = (props) => {
  const [loader, setLoader] = useState(false);
  const [saving, setsaving] = useState(false);
  const [encashmentTypes, setEncashmentTypes] = useState([])
  const [slabs, setSlabs] = useState([])
  const [deletedRows, setDeletedRows] = useState([])
  const [childComponent, setChildComponent] = useState(null)
  const [form] = Form.useForm()
  useEffect(() => {
    getRelatedData()
    setData()
    // eslint-disable-next-line
  }, []);

  const getRelatedData = () => {
    setEncashmentTypes(createSelectList(props.allData.encashment_types))
    //setDeductionTypes(createSelectList(props.allData.deduction_types))
    //setRepeats(createSelectList(props.allData.repeats))
  }
  const setData = () => {
    form.setFieldsValue(props.record)
    setSlabs(props.record.slabs)
  }

  const onSubmit = (data) => {
    if (data.slabs === undefined || data.slabs.length < 1) {
      message.info('Please Add Slabs For Policy!')
      return;
    }
    //update slabs
    const newSlabs = []
    data.slabs.forEach(each => {
      if (each.id === undefined) {
        each.row_state = 'Added'
      }
      if (each.id !== undefined) {
        each.row_state = 'Updated'
      }
      newSlabs.push(each)
    })

    deletedRows.forEach(row => {
      newSlabs.push(row)
    })

    data.slabs = newSlabs;
    //
    const payload = {
      cpl_policy:
      {
        ...props.record, ...data
      }

    };

    makeRequest(setLoader, updateDataReq, payload, onSuccess, onError);
    setsaving(true)
  }

  const onSuccess = (data, res) => {
    message.success(res.message);
    props.onCompleted(data.cpl_policys.data);
  }

  const onError = (err, res) => {
    setsaving(false)
    message.error(res.response.data.message);
  }

  // ==================================slabs actions =================================
  const onCreateSlab = () => {
    setChildComponent(<CreatePolicySlab onCompleted={ onCompleted } policyType='cpl' allData={ props.allData } fields={ slabFields } />)
  }

  //edit slab
  const onEdit = (key) => {
    const record = form.getFieldValue(`slabs`)[key];
    setChildComponent(<UpdatePolicySlab onCompleted={ onCompleted } allData={ props.allData } record={ { ...record, key: key } } disabled={ props.disabled } fields={ slabFields } policyType='cpl' />)
  };

  //on submit slabs
  const onCompleted = (data, action) => {
    let listArray = form.getFieldValue('slabs');
    if (listArray === undefined) {
      listArray = []
    }
    if (data && action === 'Added') {

      listArray.push(data)
      form.setFieldValue('slabs', listArray)
      setSlabs(listArray)
    }
    if (data && action === 'Updated') {

      listArray[data.key] = data
      form.setFieldValue('slabs', listArray)
      setSlabs(listArray)
    }
    setChildComponent(null)
  }

  //set deleted rows
  const setRows = (key, state) => {
    if (slabs[key] !== undefined && slabs[key].id !== undefined && state === 'Deleted') {
      slabs[key].row_state = 'Deleted'
      deletedRows.push(slabs[key])
    }
  }
  const slabFields = [
    {
      name: 'min',
      label: 'Text Field',
      type: 'number',
      rules: '',
      langkey: 'min',
    },
    {
      name: 'max',
      label: 'Text Field',
      type: 'number',
      rules: '',
      langkey: 'max',
    },

    {
      name: 'encashment_type_id',
      label: 'Select Field',
      type: 'select',
      options: encashmentTypes,
      rules: [],
      langkey: 'encashment_type_id',
    },
    // Add more field configurations as needed
  ];
  // ==================================
  //   Table Data End
  // ==================================
  //-----------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [
    props.disabled ? '' :
      <SaveButton loading={ saving } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------
  return (
    <ModalComponent top={ 20 } width={ 1000 } maskClosable={ false }
      mainTitle={ trans(Commons[props.disabled ? 'view' : 'update']) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      { childComponent }
      <Form { ...formLayout } name={ formName } onFinish={ onSubmit } form={ form } disabled={ props.disabled }>
        <Row gutter={ 30 }>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="name" rules={ rules.name } label={ trans(Commons.name) }
              className="da-mb-16"
            >
              <InputText placeholder={ trans(`${langKey}.name`) } />
            </Form.Item>
          </Col>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="is_daywise" rules={ rules.is_daywise } label={ trans(`${langKey}.is_daywise`) }
              className="da-mb-16"
            >
              <SelectWithSearch placeholder={ trans(`${langKey}.is_daywise`) } options={
                [
                  {
                    label: 'Monthly',
                    value: false
                  },
                  {
                    label: 'Daily',
                    value: true
                  }
                ]
              } />
            </Form.Item>
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="description" rules={ rules.description } label={ trans(Commons.description) }
              className="da-mb-16"
            >
              <InputTextArea placeholder={ trans(`${langKey}.description`) } />
            </Form.Item>
          </Col>
        </Row>

        <div className="formlist-container">
          <h5 className="formlist-title">{ trans(Commons.slabs) }</h5>
          <ViewPolicySlabList setRows={ setRows } onCreate={ onCreateSlab } onEdit={ onEdit } disabled={ props.disabled } fields={ slabFields } />
        </div>

      </Form>

    </ModalComponent>
  )
}

export default UpdateCplPolicy

const rules = {
  name: [
    { required: true, message: trans(`${langKey}.name.required`) },
  ],
  type: [
    { required: true, message: trans(`${langKey}.type.required`) },
  ],
  value: [
    { required: true, message: trans(`${langKey}.value.required`) },
  ],
  formula_id: [
    { required: true, message: trans(`${langKey}.formula_id.required`) },
  ],
};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}

