import { get, del, post, put } from "@utils/axios";

const api = "notifications";

export const getTopNotificationsReq = (payload) => {
  return get(`${api}?page=${payload.page}&filters=${payload.filters}`)
};
export const viewNotificationReq = (id) => {
  return get(`${api}/${id}`)
};

export const countTopNotificationsReq = (id) => {
  return get(`${api}/count`)
};
export const getBatchProgressByIdReq = (id) => {
  return get(`${api}/batch-progress/${id}`)
};
