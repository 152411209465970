import React, { useEffect, useState } from "react";
import { Form, Skeleton, message, Row, Col } from "antd";
import { SaveButton, InputCheck, CancelButton, ModalComponent, trans, InputDate, SelectMultiWithSearch, SelectWithSearch } from "@comps/components"
import { makeRequest, makeRequestStateless } from "@utils/helpers";
import { createDataReq, getEncashmentLeaveTypes } from "../requests";
import { getEmploymentsResourseReq } from "@mods/common/requests";
import { Commons, Const } from "../../constants";
import { createSelectList } from '@comps/commonFunctions';
import { getFormulasResourceReq } from '../../../common/requests';
import ResponseErrors from "./ResponseErrors";


const langKey = Const["leaveEncashment"].lngKey
const formName = "createAttendanceRequest";
const CreateLeaveEncashment = (props) => {
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [errors, setErrors] = useState(null);
  const [employments, setEmployments] = useState([])
  const [leaveType, setLeaveTypes] = useState([])
  const [formulas, setFormulas] = useState([])
  const [allEmployees, setAllEmployees] = useState(false)
  const [isManual, setManual] = useState(false)

  const [form] = Form.useForm()
  useEffect(() => {
    getLeaveType()
    getFormulas()
    //getEmployments()
    // eslint-disable-next-line
  }, []);
  const getEmployments = (leaveType) => {

    const payload = {
      page: 1,
      filters: `leave_types_|${leaveType}`
    }
    makeRequestStateless(getEmploymentsResourseReq, payload, onGetEmployments, false)

  }
  const onGetEmployments = (response) => {
    const data = response.resources.data
    setEmployments(createSelectList(data))
  }
  const getLeaveType = () => {
    const payload = {
      page: 1
    }
    makeRequestStateless(getEncashmentLeaveTypes, payload, onGetLeaveTypes, false)
  }
  const onGetLeaveTypes = (response) => {
    const data = response.resources.data
    const newList = []
    for (let key in data) {
      const element = data[key];
      newList.push(element)

    }
    setLeaveTypes(createSelectList(newList))
  }
  const getFormulas = () => {
    const payload = {
      page: 1
    }
    makeRequestStateless(getFormulasResourceReq, payload, onGetFormulas, false)
  }
  const onGetFormulas = (response) => {
    const data = response.resources.data
    const newList = []
    for (let key in data) {
      const element = data[key];
      newList.push(element)

    }
    setFormulas(createSelectList(newList))
  }

  const onSubmit = (data) => {
    const payload = {
      leave_encashment: {
        ...data,
        company_id: 1,
        year: data.year.format('YYYY-MM-DD'),
      }
    }
    makeRequest(setLoader, createDataReq, payload, onSuccess, onError);
    setSaveLoader(true)
  }
  const onSuccess = (data, res) => {

    if (res.data.errors.length > 0) {

      setErrors(<ResponseErrors errors={ res.data.errors } closeErrors={ closeErrors } />)
      setSaveLoader(false)
      return
    }
    message.success("Saved successfully");
    props.onCompleted(data.leave_encashments.data);
  }

  const onError = (err, res) => {

    setErrors(<ResponseErrors errors={ res.data.errors } closeErrors={ closeErrors } />)
    setSaveLoader(false)
    message.error(res.response.data.message);
  }

  const closeErrors = (res) => {
    setErrors(null)
  }
  //-----------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------
  const footer = [
    <SaveButton loading={ saveLoader } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];
  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------
  return (
    <ModalComponent top={ 20 } width={ 1000 } maskClosable={ false }
      mainTitle={ trans(Commons.create) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>

      <Skeleton loading={ loading }>
        <Form { ...formLayout } form={ form } name={ formName } onFinish={ onSubmit }>
          <Row gutter={ 30 }>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="leave_type_ids" rules={ rules.leave_type_ids } label={ trans(`${langKey}.leave_type_ids`) }
                className="da-mb-16"
              >
                <SelectMultiWithSearch
                  options={ leaveType }
                  placeholder={ trans(`${langKey}.leave_type_ids`) }
                  onChange={ getEmployments }
                />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="all_employees" rules={ rules.all_employees } label={ trans(`${langKey}.all_employees`) }
                className="da-mb-16"
                valuePropName="checked"
              >
                <InputCheck onChange={ (e) => setAllEmployees(e.target.checked) } />
              </Form.Item>
            </Col>
            { !allEmployees &&
              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="employment_id" rules={ rules.leave_type_ids } label={ trans(`${langKey}.employment_id`) }
                  className="da-mb-16"
                >
                  <SelectMultiWithSearch
                    options={ employments }
                    placeholder={ trans(`${langKey}.employment_id`) }
                  />
                </Form.Item>
              </Col>
            }

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="year" rules={ rules.year } label={ trans(`${langKey}.year`) }
                className="da-mb-16"
              >
                <InputDate picker="year" format='YYYY' />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="is_manually" rules={ rules.is_manually } label={ trans(`${langKey}.is_manually`) }
                className="da-mb-16"
              >
                <SelectWithSearch
                  defaultValue={ false }
                  options={ [
                    {
                      label: "Payroll",
                      value: false
                    },
                    {
                      label: "Manually",
                      value: true
                    }
                  ] }
                  placeholder={ trans(`${langKey}.is_manually`) }
                  onChange={ (value) => setManual(value) }
                />
              </Form.Item>
            </Col>
            {
              isManual &&
              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="formula_id" rules={ rules.formula_id } label={ trans(`${langKey}.formula_id`) }
                  className="da-mb-16"

                >
                  <SelectWithSearch
                    options={ formulas }
                    placeholder={ trans(`${langKey}.formula_id`) }

                  />
                </Form.Item>
              </Col>
            }
          </Row>

        </Form>
      </Skeleton>

      { errors }
    </ModalComponent>


  )
}

export default CreateLeaveEncashment

const rules = {
  employment_id: [
    { required: true, message: trans(`${langKey}.employment_id`) },
  ],
  leave_type_ids: [
    { required: true, message: trans(`${langKey}.leave_type_ids`) },
  ],
  year: [
    { required: true, message: trans(`${langKey}.year`) },
  ],
  formula_id: [
    { required: true, message: trans(`${langKey}.formula_id`) },
  ],

};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}
