import { Dropdown, Layout, theme, Badge ,Popover, Form,Spin} from "antd";
import { useCallback, useEffect, useState } from "react";
import { logout } from '../../../modules/userManagement/auth/authSlice'
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { checkPermissions, pusherInitializer,decryptLocalData } from "@comps/commonFunctions"
import {
   ButtonComponent,SelectWithSearch
} from "@comps/components";
import { NotificationOutlined } from '@ant-design/icons';
import ViewProfile from '../../../modules/employeeManagement/companyEmployees/components/ViewProfile';

import { makeRequest ,makeRequestStateless} from '@utils/helpers';
import { countTopNotificationsReq, getBatchProgressByIdReq } from "./requests";
import { notify } from '@utils/helpers';
import TopNotification from './TopNotification';
import { ShowErrorModal } from "../../../wrappers/components";
import { getJobResultErrors } from "../../../modules/common/requests";
import { getResourceDataReq ,createSelectList} from "@comps/commonFunctions";
import _ from "lodash";

const { Header } = Layout;

const TopHeader = () => {
  const [loader, setLoader] = useState(false);
  const authUser = useSelector((state) => state.auth.authUser);
  const [employeesAll, setAllEmployees] = useState([]);
  const [childComponent, setChildComponent] = useState(null)
  const [notifications, setNotifications] = useState(null);
  const [notifyCount, setNotifyCount] = useState(null)
  const [showProgress,setShowProgress]=useState(null);
const [empployments,setEmployments]=useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate()
  useEffect(() => {
    countNotify();

  }, [])

  //end report
  const handleLogout = () => {
    localStorage.clear();
    dispatch(logout())
    navigate('/')
  }

  useEffect(() => {
    const pusher=pusherInitializer();
    const channel= pusher.subscribe(decryptLocalData('user').tenant);
    channel.bind('bell-notification-event', function (data) {
      if(data.data){
        const decode=JSON.parse(data.data)
        if(decode.employment_id && decode.employment_id===decryptLocalData('user').employment_id){
          notify(decode.message)
          countNotify()
        }
        else{
          countNotify()
        }
      }
     
    });
     // Cleanup function for unsubscribing and unbinding when the component is unmounted
     return () => {
      // Unbind the event handler
      channel.unbind('bell-notification-event');
      // Unsubscribe from the channel
      pusher.unsubscribe(decryptLocalData('user').tenant);
      // Disconnect from Pusher
      pusher.disconnect();
    };
    // eslint-disable-next-line
  });

      //==========================Start Pusher=================================
      useEffect(() => {
        const pusher=pusherInitializer();
        const channel= pusher.subscribe(decryptLocalData('user').tenant);
        channel.bind('shifts-created-event', function (res) {
          if(res.data.success){
             //notify('Employees Shifts Created Successfully!')
          }
        else{
          if(res.employment_id===decryptLocalData('user').employment_id){
            makeRequest(setLoader, getJobResultErrors, res.data.id, onSuccessErrors, null);
          }
        
        }
        });
         // Cleanup function for unsubscribing and unbinding when the component is unmounted
         return () => {
          // Unbind the event handler
          channel.unbind('shifts-created-event');
          // Unsubscribe from the channel
          pusher.unsubscribe(decryptLocalData('user').tenant);
          // Disconnect from Pusher
          pusher.disconnect();
        };
        // eslint-disable-next-line
      });

      const onSuccessErrors=(res)=>{
        const errors=JSON.parse(res.errors)
  setChildComponent(<ShowErrorModal errors={ errors } module={ "Create Roaster" } onCompleted={ () => setChildComponent(null) } />)
      }
      //==========================end Pusher =================================


  //on select employee after search
  const onView = (id, isAuth) => {
    if (id) {
      const filterEmp = employeesAll.filter(each => each.id === id)
      let employeeId = null
      if (isAuth === true && authUser.employee_id) {
        employeeId = authUser.employee_id
      }
      else {
        employeeId = filterEmp.length > 0 ? filterEmp[0].employee_id : null
      }
      const record = { id: id, employee_id: employeeId }
      setChildComponent(<ViewProfile record={ record } disabled={ false } onCompleted={ onCompleted } />);
    }
  };
  const onCompleted = (data) => {
    
    if (!data) {
      setNotifications(null)
      setChildComponent(null)
      searchFrom.setFieldValue('employment_id',null);
    }
  }
  //===================notification start=======================================
  const getNotifications = () => {
    countNotify()
    setNotifications(<TopNotification onCompleted={ onCompleted } />)
  }

  const countNotify = () => {
    makeRequest(setLoader, countTopNotificationsReq, null, onSuccessView, onError);
  }
  const onSuccessView = (res) => {
    setNotifyCount(res.count)
  }
  const onError = (res, err) => {
  }

  const dropdown = () => (
    <>
      <div>
      <ButtonComponent style={ { position: "relative", left: 4,right:5, top: -6 } } onClick={ handleLogout } text={ "Logout" } danger />
      </div>
    </>
  );

  const debouncedGetEmployees = useCallback(
    _.debounce((value) => {
      getEmployees(`search_|${value}`);
    }, 500),
    [] // Empty array ensures the function is created only once
  );
  const onSearchEmployee=(value)=>{
    debouncedGetEmployees(value)
    //getEmployees(`search_|${value}`)
  }
  const getEmployees = (filters) => {
    if(authUser.role_id===1 || authUser.role_id===4){

    }else{
      filters=`${filters};subordinate_|${authUser.employment_id}`;
    }
    let payload = {
      page: 1,
      type: 'employment',
      filters: filters
    }
      makeRequest(setLoader, getResourceDataReq, payload, onSuccess, onError);
  }

  const onSuccess = (response) => {
     setAllEmployees(response.resources.data)
     setEmployments(createSelectList(response.resources.data));
    setLoader(false)
  }
 
  const onFocusEmployeeSearch=()=>{
    if(empployments.length===0){
      getEmployees()
    }
  }

const [searchFrom]=Form.useForm();
  //==================notification ends=========================================
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  return (
    <>
      <Header style={ { padding: 0, background: colorBgContainer, height: 68, display: 'flex' } }>
        {childComponent}
       
        { checkPermissions('dashboard', 'employmentSearch') ?
          <div className="search-container" >
            <Form form={searchFrom} name="searchFrom" className="mt-3">
            <Spin spinning={ loader }>
              <Form.Item name={'employment_id'}>
            <SelectWithSearch options={empployments} onSearch={onSearchEmployee} style={ { minWidth: 282, borderRadius: 5, background: '#FFFFFF', marginLeft: '80px', height: 41 } } searchEmployeeHeader={true} setallemployees={ setAllEmployees } onChange={ onView } module="header" type="employment" placeholder="Search Employee"  onFocus={()=>onFocusEmployeeSearch('')}/>
          
              </Form.Item>
              </Spin>
        </Form>
           
          </div>
          : ''
        }
      <div style={{
        width:'100%',
        textAlign:'right',
        paddingRight:30,
        
      }}>
     <Popover content={ notifications } title="Notifications" trigger="click" placement="right" onClick={()=>getNotifications()}>
          <Badge size="small" count={ notifyCount } color='#f5222d'>
            <NotificationOutlined  style={ { cursor:'pointer',
        } }
            />
          </Badge>
        </Popover>
        </div>

        <Dropdown overlay={ dropdown } trigger={ 'hover' }>
         <div>
       <div>
       <img width={ 35 } src={ authUser.profile_pic_url !== "images/arnold-avatar.jpg" ? authUser.profile_pic_url : "/images/arnold-avatar.jpg" } alt=" " style={ { position: "absolute", right: 50, top: 15, cursor: 'pointer', borderRadius: '30px' } } onClick={ () => onView(authUser.employment_id, true) } />
       </div>
          <div style={{marginTop:50,marginRight:32,lineHeight:'normal',whiteSpace:'nowrap'}}>
          <small className="mt-2">{ authUser.full_name }</small>
          </div>
         </div>
        </Dropdown>

      </Header>
    </>
  )
}

export default TopHeader;