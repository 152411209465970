import React, { useEffect, useState } from "react";
import { Button, Checkbox, Form, Input, message } from "antd";
import { SaveButton, InputText, CancelButton, ModalComponent, trans, SelectWithSearch,ButtonComponent ,SelectResourceList} from "@comps/components"
import { makeRequest } from "@utils/helpers";
import { setFlowReq } from "../requests";
import { Commons, Const } from "../../../constants";
import { DeleteOutlined, PlusOutlined, EditOutlined } from "@ant-design/icons";
import { createSelectList } from '@comps/commonFunctions';
import '../style.css';
import AddNode from "./AddNode";

const langKey = Const["requestFlow"].lngKey
const formName = "createRequestFlow";

const ChangeRequestFlow = (props) => {
  const [loader, setLoader] = useState(false);
  const [saving, setSaving] = useState(false)
  const [formList, setFormLsit] = useState([])
  const [nodeTypes, setNodeTypes] = useState([])
  const [employees, setEmployees] = useState([])
  const [hasCondition, setHasCondition] = useState(false)
  const [isNode, setIsNode] = useState()
  const [childComponent, setChildComponents] = useState(null)
  const [deletedRows, setDeletedRows] = useState([])
  const [flows, setRequestFlow] = useState([])
  const [form] = Form.useForm()
  useEffect(() => {
    setData()
    onGetEmployment()
    // eslint-disable-next-line
  }, []);

  const onGetEmployment = (response) => {
    //setEmployees(createSelectList(props.record.employees))
  }
  const setData = () => {

    setNodeTypes(createSelectList(props.record.node_types))
    form.setFieldsValue({ flows: props.record.flow })

    setRequestFlow(props.record.flow)
  }

  const onSubmit = (data) => {
    
    if (data.flows === undefined || data.flows.length < 1) {
      message.info('Please Add Flow!')
      return;
    }
    const newFlows = []
    data.flows.forEach((each, key) => {
      if (key === 0) {
        each.is_first = true
      }
      else {
        each.is_first = false
      }
      if(key===data.flows.length-1){
        each.is_last=true;
      }else{
        each.is_last=false
      }
      // if (each.id === undefined) {
      //   each.row_state = 'Added'
      // }
      // if (each.id !== undefined) {
      //   each.row_state = 'Updated'
      // }
      if (each.request_as_id !== 8) {
        each.employment_id = null
      }
      newFlows.push(each)
    })

    let flows = {company_id: props.requestFlow.company_id,
      type_id: props.requestFlow.type_id,
      for_node_id: props.requestFlow.for_node_id,
      for_employee_id: props.requestFlow.for_employee_id,
      for_employment_id: props.requestFlow.for_employment_id, flows: newFlows.concat(deletedRows) }
    const payload = {
      request_flow: flows,
    };

    makeRequest(setLoader, setFlowReq, payload, onSuccess, onError);
    setSaving(true)
  }

  const onSuccess = (data, res) => {
    //setSaving(false)
    message.success('Request Flow Updated Successfully');
    props.onCompleted(res);
  }

  const onError = (err, res) => {
    setSaving(false)
    message.error(res.response.data.message);
  }

  const addNode = (key) => {
    let flowid=0;
    let record = null
let rowState='Added';
    if (key !== null) {
      record = { ...form.getFieldValue(`flows`)[key], key: key }
      rowState='Updated';
      flowid=key+1;
    }

    setChildComponents(<AddNode employees={ employees } nodeTypes={ nodeTypes } onCompleted={ onCompleted } record={ record } rowState={rowState} nextLimit={flowid+1}/>)
  }

  //on submit slabs
  const onCompleted = (data, action) => {
    
    let listArray = form.getFieldValue('flows');
    if (listArray === undefined) {
      listArray = []
    }
    if (data && action === 'Added') {
      listArray.push(data)
      form.setFieldValue('flows', listArray)

    }
    if (data && action === 'Updated') {

      listArray[data.key] = data
      form.setFieldValue('flows', listArray)

    }
    setChildComponents(null)
  }
  //-----------------------

  //set deleted rows
  const onDeleteRow = (key, state) => {
    if (flows[key] !== undefined && flows[key].id !== undefined && state === 'Deleted') {
      flows[key].row_state = 'Deleted'
      deletedRows.push(flows[key])
    }
  }
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [
    !childComponent &&
    <SaveButton loading={ saving } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } width={ 1200 } maskClosable={ false }
      mainTitle={ trans(Commons.update) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      { childComponent }
      {/* <Button onClick={ () => addNode(null) } icon={ <PlusOutlined /> } type="primary" className="add-node-button">Add Node</Button> */}
      <Form name={ formName } onFinish={ onSubmit } form={ form } disabled={ true }>

        <Form.List name="flows">
          { (fields, { add, remove }) => (
            <>
 { !childComponent && <ButtonComponent disabled={ false } onClick={ () => addNode(null) } icon={ <PlusOutlined /> } type="primary" className="add-node-button">Add Node</ButtonComponent> }
             
 <div className="table-container mt-5">
                <table className="table">
                  {
                    fields.length !== 0 && <thead>
                      <tr>
                        <th>{ trans(`${langKey}.id`) }</th>
                        <th>{ trans(`${langKey}.editable`) }</th>
                        <th>{ trans(`${langKey}.has_condition`) }</th>
                        <th>{ trans(`${langKey}.request_as_name`) }</th>
                        <th>{ trans(`${langKey}.employee_id`) }</th>
                        <th>{ trans(`${langKey}.action`) }</th>
                      </tr>
                    </thead>
                  }
                  { fields.map(({ key, name, ...restField }) => (
                    <>
                      <tbody>
                        <tr>
                          <td>
                            <Form.Item
                              className="form-item"
                              { ...restField }
                              name={ [name, 'id'] }
                              rules={ rules.name }
                              initialValue={ key + 1 }
                            >
                              <InputText disabled={ true } />
                            </Form.Item>
                          </td>
                          <td >
                            <Form.Item
                              className="form-item"
                              { ...restField }
                              name={ [name, 'editable'] }
                              rules={ rules.name }
                              valuePropName="checked"
                            >
                              <Checkbox />
                            </Form.Item>
                          </td>
                          <td >
                            <Form.Item
                              className="form-item"
                              { ...restField }
                              name={ [name, 'has_condition'] }
                              rules={ rules.name }
                              valuePropName="checked"
                            >
                              <Checkbox />
                            </Form.Item>
                          </td>
                          <td width={ 150 }>
                            <Form.Item
                              className="form-item"
                              { ...restField }
                              name={ [name, 'request_as_id'] }

                            >
                              <SelectWithSearch
                                options={ nodeTypes }

                              />
                            </Form.Item>
                          </td>
                          <td width={ 150 }>
                            <Form.Item
                              className="form-item"
                              { ...restField }
                              name={ [name, 'employment_id'] }
                              rules={ rules.employment_id }
                            >
                              <SelectResourceList
                               type="employment"

                              />
                            </Form.Item>
                          </td>
                          <td>
                            <DeleteOutlined className="formlist-remove" onClick={ () => {
                              remove(name)
                              onDeleteRow(key, 'Deleted')
                            } } />
                            <EditOutlined className="ms-2" onClick={ () => addNode(key) } />
                          </td>
                        </tr>
                      </tbody>
                    </>
                  )) }
                </table>
              </div>
            </>
          ) }
        </Form.List>
      </Form>
    </ModalComponent>
  )
}

export default ChangeRequestFlow

const rules = {
  request_as_id: [
    { required: true, message: trans(`${langKey}.request_as_id`) },
  ],
  // value: [
  //   { required: true, message: trans(`${langKey}.value`) },
  // ],
  // count: [
  //   { required: true, message: trans(`${langKey}.count`) },
  // ],
  // padding: [
  //   { required: true, message: trans(`${langKey}.padding`) },
  // ],

};

const formLayout = {
  // disabled:{props.disabled}
  // layout: "horizontal",
  labelCol: { span: 6 },
  wrapperCol: { span: 14, offset: 2 },
  labelAlign: "left"

}