import React, { useEffect, useState } from "react";
import { Checkbox, Form, message, Row, Col, Skeleton } from "antd";
import { SaveButton, CancelButton, ModalComponent, trans, SelectWithSearch, InputText, AddNewButton } from "@comps/components"
import { makeRequest, getErrorProps, makeRequestStateless } from "@utils/helpers";
import { createSingleShift, getRelatedDataReq } from "../requests";
import { Commons, Const } from "../../constants";
import { createSelectList } from '@comps/commonFunctions';
import CreateTimeSlot from "../../timeSlots/components/CreateTimeSlot";
const langKey = Const["companyRoaster"].lngKey
const formName = "updateReason";

const CreateShift = (props) => {
  const [loader, setLoader] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [timeSlots, setTimeSlots] = useState([]);
  const [childComponent, setChildComponent] = useState(null);
  const [loading, setloading] = useState(true);
  const [form] = Form.useForm();
  useEffect(() => {
    getRemoteData()
    setFormData()
    // eslint-disable-next-line
  }, []);
  const getRemoteData = () => {
    makeRequestStateless(getRelatedDataReq, null, ongetRelatedDataReq, null);
  }
  const ongetRelatedDataReq = (response) => {
    setTimeSlots(createSelectList(response.time_slots))
    setloading(false)
  }
  const setFormData = () => {
    if (!props) {
      return;
    }
  }
  const onSubmit = (data) => {
    setSaveLoader(true)
    let emp_ids = ''
    props.employments.map((each) => (
      emp_ids += `${each.id},`
    ))
    const payload = { shift: { date: data.date, time_slot_id: data.time_slot_id, employment_id: props.employment_id }, filters: props.filters }
    makeRequest(setLoader, createSingleShift, payload, onSuccess, onError);
  }

  const onSuccess = (data, res) => {
    setSaveLoader(false)
    message.success("Record Updated");
    props.getAllData(data);
  }

  const onError = (err, res) => {
    setSaveLoader(false)
    message.error(res.response.data.message);
  }

  const onTimeSlotCreated = (data) => {
    if (data.length) {
      setTimeSlots(createSelectList(data))
      form.setFieldValue('time_slot_id', data[0].id)
    }
    setChildComponent(null);
  }
  //-----------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [
    <SaveButton loading={ saveLoader } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCreated(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent width={ 1200 } top={ 20 } maskClosable={ false }
      mainTitle={ trans(Commons.create) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCreated(false) } disabled={ props.disabled }>
      <Skeleton loading={ loading }>
        <Form
          { ...formLayout }
          form={ form }
          name={ formName }
          onFinish={ onSubmit }
          disabled={ props.disabled }
        >
          <div style={ { marginTop: 30 } }>
            <Row gutter={ 30 }>
              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="date" rules={ rules.date } label={ trans(`${langKey}.date`) }
                  className="da-mb-16"
                  { ...getErrorProps(errors['name']) } size="small"
                  initialValue={ props.date }
                >
                  <InputText
                    disabled={ true }
                  />
                </Form.Item>

                <Form.Item name="is_full_overtime_shift" rules={ rules.is_full_overtime_shift } label={ trans(`${langKey}.is_full_overtime_shift`) }
                  className="da-mb-10"
                  { ...getErrorProps(errors['name']) } size="small"
                  valuePropName="checked"
                >
                  <Checkbox />
                </Form.Item>
              </Col>

              { childComponent }
              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <AddNewButton text="Time Slot" onClick={ () => setChildComponent(<CreateTimeSlot onCreated={ onTimeSlotCreated } />) } />
                <Form.Item name="time_slot_id" rules={ rules.time_slot_id } label={ trans(`${langKey}.time_slot_id`) }
                  className="da-mb-10"
                  { ...getErrorProps(errors['name']) } size="small"
                >
                  <SelectWithSearch
                    options={ timeSlots }
                    placeholder={ trans(`${langKey}.time_slot_id`) }
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>

        </Form>
      </Skeleton>
    </ModalComponent>
  )
}

export default CreateShift

const rules = {
  name: [
    { required: true, message: '' },
  ],
  time_slot_id: [
    { required: true, message: trans(`${langKey}.time_slot_id`), }
  ]

};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}