import React, { useState, useEffect } from 'react';
import { Row, Col, Spin } from 'antd';
import { makeRequest } from '@utils/helpers';
import { getEarlyArrivalsReq } from '../requests';
import "../dashboard.css"
import { InputDate } from '@comps/components';
import dayjs from 'dayjs';
const moment = require('moment');

const EarlyArrivals = () => {
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState(moment());

  useEffect(() => {
    let payload = {
      "date": startDate ? startDate.format('YYYY-MM-DD') : moment().startOf('month').format('YYYY-MM-DD'),
    }
    makeRequest(setLoader, getEarlyArrivalsReq, payload, onSuccess, onError)
  }
    , [startDate]);

  const onSuccess = (data) => {
    setData(data.daily_early_arrivals);
  }

  const onError = (error) => {
  }

  return (
    <div className='widgetbox'>
      <Row>
        <Col span={ 12 }>
          <h3>Early Risers</h3>
        </Col>
        <Col span={ 12 }>
          <div className='changeDate'>
            <Row gutter={ 10 }>
              <Col span={ 24 }>
                <InputDate onChange={ setStartDate } defaultValue={ dayjs(startDate) } placeholder="Date" />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <div className='widgetcontent widgetboxfixH'>
        <Spin spinning={ loader } tip="Loading...">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col">Minutes</th>
              </tr>
            </thead>
            <tbody>
              { data && data.map((row, index) =>
                <tr>
                  <th scope="row">{ index + 1 }</th>
                  <td>{ row.full_name }</td>
                  <td>{ row.minutes } Mins</td>
                </tr>
              ) }

            </tbody>
          </table>
        </Spin>
      </div>
    </div>
  );
}

export default EarlyArrivals;