const key = "policyManagement";
const baseRoute = "/attendanceManagement";

export const Const = {

  encashmentType: {
    lngKey: `${key}.encashmentType`,
    route: ``,
  },
  deductionType: {
    lngKey: `${key}.deductionType`,
    route: ``,
  },
  absentPolicy: {
    lngKey: `${key}.absentPolicy`,
    route: ``,
  },
  
  policySlab: {
    lngKey: `policyManagement.policySlab`,
    route: ``,
  },
  missingPolicy: {
    lngKey: `${key}.missingPolicy`,
    route: ``,
  },
  lateArrivalPolicy: {
    lngKey: `${key}.lateArrivalPolicy`,
    route: ``,
  },
  earlyLeftPolicy: {
    lngKey: `${key}.earlyLeftPolicy`,
    route: ``,
  },
  shortDurationPolicy: {
    lngKey: `${key}.shortDurationPolicy`,
    route: ``,
  },
  relaxationPolicy: {
    lngKey: `${key}.relaxationPolicy`,
    route: ``,
  },
  leavePolicy: {
    lngKey: `${key}.leavePolicy`,
    route: ``,
  },
  overtimePolicy: {
    lngKey: `${key}.overtimePolicy`,
    route: ``,
  },
  cplPolicy: {
    lngKey: `${key}.cplPolicy`,
    route: ``,
  },
  travelPolicy: {
    lngKey: `${key}.travelPolicy`,
    route: ``,
  },
  requestPolicyPlanner: {
    lngKey: `${key}.requestPolicyPlanner`,
    route: ``,
  },
  attendancePolicyPlanner: {
    lngKey: `${key}.attendancePolicyPlanner`,
    route: ``,
  },

};

const cmn = "common";
export const Commons = {
  create: `${cmn}.create`,
  update: `${cmn}.update`,
  view: `${cmn}.view`,
  name: `${cmn}.name`,
  code: `${cmn}.code`,
  location: `${cmn}.location`,
  branch: `${cmn}.branch`,
  department: `${cmn}.department`,
  designation: `${cmn}.designation`,
  joiningDate: `${cmn}.joiningDate`,
  grade: `${cmn}.grade`,
  ProfileCompletion: `${cmn}.ProfileCompletion`,
  actions: `${cmn}.actions`,
  is_active: `${cmn}.is_active`,
  status: `${cmn}.status`,
  description: `${cmn}.description`,
  shortname: `${cmn}.shortname`,
  country: `${cmn}.country`,
  history: `${cmn}.history`,
  flow: `${cmn}.flow`,
  slabs: `${cmn}.slabs`,
};
export const colors = {
  "bg-warning": "#faad14",
  "bg-warning-color": "white",
  "bg-success": "green",
  "bg-success-color": "white",
  "bg-darkgray": "#333",
  "bg-danger": "#d9534f",
  "bg-off":"#36c2aa"
}


