import React, { useEffect, useRef, useState } from 'react';
import { Row, Col, Spin } from 'antd';
import { makeRequest } from '@utils/helpers';
import { getDepartmentSummaryDetailReq, getDepartmentAttendanceDetailReq } from '../requests';
import { InputDate } from '@comps/components';
import dayjs from 'dayjs';
import useInViewport from '../useInViewport';
const moment = require('moment');



const AttendanceDrillByDepartment = (props) => {
  const [loader, setLoader] = useState(false);
  const [departmentSummary, setDepartmentSummary] = useState([]);
  const [departmentAttendance, setDepartmentAttendance] = useState([]);
  const [date, setDate] = useState(moment());

  const viewRef = useRef(null);
  const viewport = useInViewport(viewRef);

  useEffect(() => {
    let payload = {
      date: date.format('YYYY-MM-DD')
    }
    if(viewport){
      makeRequest(setLoader, getDepartmentSummaryDetailReq, payload, onSuccess, Function)
      makeRequest(setLoader, getDepartmentAttendanceDetailReq, payload, onAttendanceSuccess, Function)
    }
   
  }, [viewport,date]);

  const onSuccess = (data) => {
    setDepartmentSummary(data);
  }

  const onAttendanceSuccess = (data) => {
    setDepartmentAttendance(data);
  }

  return (
    <div className='widgetbox' ref={viewRef}>
      <Row gutter={ 10 }>
        <Col span={ 12 }>
          <h3>Attendance Drill Down By Department</h3>
        </Col>
        <Col span={ 12 } style={ {
          textAlign: 'right',
        } }>
          <div className='changeDate'>
            <InputDate onChange={ setDate } defaultValue={ dayjs(date) } placeholder="Date" />
          </div>
        </Col>
      </Row>
      <Spin spinning={ loader } tip="Loading...">
        <div className='widgetcontent'>
          <Row>
            { departmentSummary && Object.keys(departmentSummary).map((key, index) => (
              <Col span={ 6 } style={ {
                paddingRight: '20px',
                marginBottom: '20px',
              } }>
                <Row className='summarysinglecol'>
                  <Col span={ 18 } style={ {
                    textTransform: 'capitalize',
                  } }>
                    { key.split('_').join(' ') }
                  </Col>
                  <Col span={ 6 } className='headcount'>
                    { departmentSummary[key] ? departmentSummary[key] : 0 }
                  </Col>
                </Row>
              </Col>
            )) }
          </Row>

          <Row>
            <Col span={ 24 } style={ {
              paddingRight: '20px',
            } }>
              { departmentAttendance && Object.keys(departmentAttendance).length > 0 && (
                <table className="table table-bordered">
                  <thead className="thead-dark">
                    <tr className="thead-dark">
                      <th className="thead-dark" scope="col">Department</th>
                      <th className="thead-dark" scope="col">Head Count</th>
                      <th className="thead-dark" scope="col">Presents</th>
                      <th className="thead-dark" scope="col">Absents</th>
                      <th className="thead-dark" scope="col">Leaves</th>
                      <th className="thead-dark" scope="col">Days Off</th>
                      <th className="thead-dark" scope="col">Travel</th>
                      <th className="thead-dark" scope="col">Late Arrivals</th>
                      <th className="thead-dark" scope="col">Early Left</th>
                    </tr>
                  </thead>
                  <tbody>

                    { departmentAttendance && Object.keys(departmentAttendance).map((key, index) =>
                      <tr>
                        <td>{ departmentAttendance[key].item.dept_name }</td>
                        <td>{ departmentAttendance[key].item.head_count }</td>
                        <td>{ departmentAttendance[key].item.present }</td>
                        <td>{ departmentAttendance[key].item.absent }</td>
                        <td>{ departmentAttendance[key].item.leave_count }</td>
                        <td>{ departmentAttendance[key].item.day_off_count }</td>
                        <td>{ departmentAttendance[key].item.travel_count }</td>
                        <td>{ departmentAttendance[key].item.late_arrival_count }</td>
                        <td>{ departmentAttendance[key].item.early_leave_count }</td>
                      </tr>
                    ) }

                  </tbody>
                </table>
              ) }
            </Col>
          </Row>
        </div>
      </Spin>
    </div>

  )

}

export default AttendanceDrillByDepartment;