import React from "react";
import { Route, Routes } from "react-router-dom";
import IndexGeneralMasters from './IndexGeneralMasters';

const GeneralMasterModules = () => {
  return (
    <Routes>
      <Route path="/" element={ <IndexGeneralMasters /> } />

    </Routes>
  );
}

export default GeneralMasterModules;
