

import React, { useEffect, useState } from "react";
import { Button, Checkbox, Form, message } from "antd";
import {
  SaveButton, InputText, CancelButton, ModalComponent, trans, InputDate
} from "@comps/components"
import { Commons, Const } from "../../../constants";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import dayjs from 'dayjs'

const ViewRepayments = (props) => {

  const langKey = Const["lendApproval"].lngKey
  const formName = "updateLendApproval";
  const [deletedRows, setDeletedRows] = useState([])
  const [form] = Form.useForm()
  const [repayments, setRepayments] = useState([]);
  const [totalAmount, setTotalAmount] = useState(null)

  useEffect(() => {
    setData()
    // eslint-disable-next-line
  }, []);


  const setData = () => {
    setRepayments(props.record)
    props.record.forEach(each => (
      each.repayment_date = each.repayment_date ? dayjs(each.repayment_date) : null
    ));

    form.setFieldsValue({
      approved_amount: props.approvedAmount,
      repayments: props.record
    })

    getTotalAmount()
  }
  //calculations

  const getTotalAmount = () => {
    const values = form.getFieldValue('repayments')
    let totalAmount = 0
    values.forEach((value) => {
      if (value !== undefined) {
        let amount = value.amount !== undefined ? parseFloat(value.amount) : 0
        if (value.amount === undefined || value.amount === '') {
          amount = 0
        }
        totalAmount = totalAmount + amount
      }
    })

    setTotalAmount(totalAmount)
  }

  const setRows = (key, state) => {
    if (repayments[key] !== undefined && repayments[key].id !== undefined && state === 'Deleted') {
      repayments[key].row_state = 'Deleted'
      deletedRows.push(repayments[key])
    }
    setDeletedRows(deletedRows)
  }

  const onSubmit = (data) => {

    if (data.repayments === undefined || data.repayments.length < 1) {
      message.info('Please Add Repayment Plan');
      return
    }

    data.repayments.concat(deletedRows)
    props.onSaveRepayments(data.repayments)
  }


  const footer = [
    totalAmount == props.approvedAmount &&
    <SaveButton htmlType="submit" form={ 'repaymentForm' } key="create_button" />,

    <CancelButton key="close_button" onClick={ () => props.onSaveRepayments(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 30 } width={ 1200 } maskClosable={ false }
      mainTitle={ trans(props.disabled ? Commons.view : Commons.update) } subTitle={ trans(`${langKey}.repayment`) }
      visible={ true } footer={ footer } onCancel={ () => props.onSaveRepayments(false) }>

      <Form { ...formLayout } name={ 'repaymentForm' } onFinish={ onSubmit } form={ form } scrollToFirstError>
        <Form.Item name="approved_amount" rules={ rules.approved_amount } label={ trans(`${langKey}.approved_amount`) }
          className="da-mb-16"
          size="small"

        >
          <InputText placeholder={ trans(`${langKey}.approved_amount`) } type="number" disabled
          />
        </Form.Item>

        <div className="formlist-container">
          <h5 className="formlist-title">Re-Payments</h5>

          <Form.List name="repayments" >
            { (fields, { add, remove }) => (
              <>
                <Button onClick={ () => {
                  add()
                  //setRows(fields.length, 'Added')
                } } icon={ <PlusOutlined /> } type="primary" className="add-node-button">Add </Button>
                <div className="table-container">
                  <table className="table">
                    {
                      fields.length !== 0 && <thead>
                        <tr>
                          <th>{ trans(`${langKey}.repayment_date`) }</th>
                          <th>{ trans(`${langKey}.amount`) }</th>
                          <th>{ trans(`${langKey}.is_manuall_entry`) }</th>
                          <th>{ trans(`${langKey}.actions`) }</th>
                        </tr>
                      </thead>
                    }
                    <tbody>
                      { fields.map(({ key, name, ...restField }) => (
                        <>
                          <tr>
                            <td style={ { minWidth: '200px' } }>

                              <Form.Item
                                { ...restField }
                                name={ [name, 'repayment_date'] }
                                rules={ rules.amount }
                              >
                                <InputDate />
                              </Form.Item>
                            </td>
                            <td style={ { minWidth: '200px' } }>
                              <Form.Item
                                { ...restField }
                                name={ [name, 'amount'] }
                                rules={ rules.amount }
                              >
                                <InputText placeholder={ trans(`${langKey}.amount`) } type="number" onChange={ () => getTotalAmount() } />
                              </Form.Item>
                            </td>
                            <td style={ { minWidth: '200px' } }>
                              <Form.Item
                                valuePropName="checked"
                                { ...restField }
                                name={ [name, 'is_manuall_entry'] }
                                rules={ rules.is_manuall_entry }
                              >
                                <Checkbox className="formlist-remove" disabled={ true } defaultChecked={ key > repayments.length - 1 } />
                              </Form.Item>
                            </td>

                            <td>
                              <DeleteOutlined className="formlist-remove" onClick={ () => {
                                remove(name)
                                getTotalAmount()
                                setRows(key, 'Deleted')
                              } } />
                            </td>
                          </tr>
                        </>
                      )) }
                      <tr style={ { fontWeight: 'bold' } }>
                        <td >
                          Total Amount:
                        </td>
                        <td colspan={ 4 } >
                          { totalAmount }
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </>
            ) }
          </Form.List>
        </div>

      </Form>

    </ModalComponent>
  )


}


export default ViewRepayments


const rules = {
  name: [
    { required: true, message: '' },
  ],
};

const formLayout = {
  // layout: "horizontal",
  labelCol: { span: 6 },
  wrapperCol: { span: 14, offset: 2 },
  labelAlign: "left"
}
