import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { get } from "@utils/axios";
import Cookies from 'js-cookie';

// Define a name for slice
const sliceName = "common";

// Define the initial state using that type
const initialState = {
    permissions: JSON.parse(localStorage.getItem("moduleList")),
}
//initial navigation state

export const getNavigationWithPermissions = createAsyncThunk(
    `${sliceName}/getNavigationWithPermissions`,
    async (auth, thunkApi) => {
        let exists = localStorage.getItem("navigation");
        if (exists) {
            return;
        }
        const res = await get("nav");

        if (res.code === 420) {
            localStorage.clear();
            Cookies.remove("token");
            window.location.href = "/";
        }

        if (res.data && res.data.length) {
            localStorage.setItem("navigation", JSON.stringify(res.data));
        }
    }
);

//end navigation
export const getPermissions = createAsyncThunk(
    `${sliceName}/getPermissions`,
    async (auth, thunkApi) => {
        let exists = localStorage.getItem("moduleList");
        if (exists) {
            return;
        }
        const res = await get("user-management/permission-list");

        if (res.code === 420) {
            localStorage.clear();
            Cookies.remove("token");
            window.location.href = "/";
        }

        if (res.data && res.data.length) {
            localStorage.setItem("moduleList", JSON.stringify(res.data));
        }
    }
);

export const commonSlice = createSlice({
    name: sliceName,
    initialState,

    reducers: {

        clearPermissions: (state, action) => {
            localStorage.removeItem("moduleList");

            return {
                ...initialState,
                permissions: [],
            };
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getPermissions.pending, (state, action) => {
            localStorage.removeItem("moduleList");

            return {
                ...initialState,
                permissions: [],
            };
        });
        builder.addCase(getPermissions.fulfilled, (state, action) => {
            return {
                ...initialState,

            };
        });
    }
});

export default commonSlice.reducer;
export const { clearPermissions } = commonSlice.actions;
