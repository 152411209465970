import React, { useEffect, useState } from "react";
import { Form, Input, message, Row, Col } from "antd";
import { SaveButton, InputText, CancelButton, ModalComponent, trans, InputDate } from "@comps/components"
import { makeRequest, getErrorProps } from "@utils/helpers";
import { createDataReq, getDataByIdReq } from "../requests";
import { Commons, Const } from "../../constants";
import EmployeeInfo from '@mods/common/EmployeeInfo';
import { useSelector } from "react-redux";

const { TextArea } = Input;
const langKey = Const["lendRepayment"].lngKey
const formName = "createLendType";

const AdjustRepayment = (props) => {

  const authUser = useSelector((state) => state.auth.authUser);
  const [loader, setLoader] = useState(false);
  const [saving, setsaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [childComponent, setChildComponent] = useState(false)
  const [lend, setLend] = useState({});

  const [form] = Form.useForm()
  useEffect(() => {
    getRepaymentById()
    // eslint-disable-next-line
  }, []);
  const getRepaymentById = () => {
    makeRequest(setLoader, getDataByIdReq, props.record.id, setData, onErrorGettingData);
  }

 const getPaidAmount = (lend) =>{
    var total = 0;
    for (var i in lend.repayments) {
      if (lend.repayments[i] && lend.repayments[i].processed) {
        total += lend.repayments[i].amount;
      }
    }
    return total;
  }
  const setData = (response) => {
    setLend(response.lend)
    form.setFieldsValue({
      all_approved_amount: response.lend.approved_amount,
      all_paid_amount: getPaidAmount(response.lend),
      remaining_amount: response.lend.remaining_amount
    })
  }
  const onErrorGettingData = () => {

  }


  const onSubmit = (data) => {
    const payload = { adjustment: { lend_id: props.record.id, payment: data.payment, repayment_date: data.repayment_date.format('YYYY-MM-DD') } };
    makeRequest(setLoader, createDataReq, payload, onSuccess, onError);
    setsaving(true)
  }

  const onSuccess = (data, res) => {
    message.success("Saved successfully");
    props.onCompleted(data.lends.data);
  }

  const onError = (err, res) => {
    setsaving(false)
    message.error(res.response.data.message);
  }

  const onChangeAmount=(value)=>{
     if(value && value>lend.remaining_amount){
      message.info('Should not be greater than '+ lend.remaining_amount)
      form.setFieldValue('payment',null)
     }
  }
  //==============add details =================
  //-----------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------
  const footer = [
    props.disabled ? '' :
      <SaveButton loading={ saving } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } width={ 1200 } maskClosable={ false }
      mainTitle={ trans(Commons.create) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      { childComponent }
      <EmployeeInfo record={ props.record.requestor && props.record.requestor } />
      <Form { ...formLayout } name={ formName } onFinish={ onSubmit } form={ form } disabled={ props.disabled } scrollToFirstError>
        <Row gutter={ 30 }>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="all_approved_amount" rules={ rules.approved_amount } label={ trans(`${langKey}.approved_amount`) }
              className="da-mb-16"
              { ...getErrorProps(errors['name']) } size="small"
            >
              <InputText disabled={ true } type="number"
              />
            </Form.Item>
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="all_paid_amount" rules={ rules.all_paid_amount } label={ trans(`${langKey}.all_paid_amount`) }
              className="da-mb-16"
            >
              <InputText type="number" disabled={ true } />
            </Form.Item>
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="remaining_amount" rules={ rules.remaining_amount } label={ trans(`${langKey}.remaining_amount`) }
              className="da-mb-16"
            >
              <InputText type="number" disabled={ true } />
            </Form.Item>
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="payment" rules={ rules.payment } label={ trans(`${langKey}.payment`) }
              className="da-mb-16"
            >
              <InputText type="number" placeholder="Rs" onChange={(e)=>onChangeAmount(e.target.value)}/>
            </Form.Item>
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="repayment_date" rules={ rules.repayment_date } label={ trans(`${langKey}.repayment_date`) }
              className="da-mb-16"
            >
              <InputDate />
            </Form.Item>
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="description" rules={ rules.description } label={ trans(`${langKey}.description`) }
              className="da-mb-16"
            >
              <TextArea placeholder="description" />
            </Form.Item>
          </Col>
        </Row>

      </Form>

    </ModalComponent>
  )
}

export default AdjustRepayment

const rules = {
  payment: [
    { required: true, message: trans(`${langKey}.payment`) },
  ],
  repayment_date: [
    { required: true, message: trans(`${langKey}.repayment_date`) },
  ],


};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}
