import React, { useEffect, useState } from "react";
import { Checkbox, Form, message, Row, Col } from "antd";
import { SaveButton, InputText, InputTextArea, CancelButton, ModalComponent, trans } from "@comps/components"
import { makeRequest, getErrorProps } from "@utils/helpers";
import { updateDataReq } from "../requests";
import { Commons, Const } from "../../../constants";
const langKey = Const["evaluationScale"].lngKey
const formName = "updateEvaluationScale";

const UpdateEvaluationScale = (props) => {
  const [loader, setLoader] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [form] = Form.useForm();
  useEffect(() => {
    setFormData();
  }, []);

  const setFormData = () => {
    if (!props) {
      return;
    }
    form.setFieldsValue(props.record);
  }
  const onSubmit = (data) => {
    data.id = props.record.id;
    const payload = { evaluation_scale: data };
    makeRequest(setLoader, updateDataReq, payload, onSuccess, onError);
    setSaveLoader(true)
  }

  const onSuccess = (data, res) => {
    setSaveLoader(false)
    message.success("Record Updated");
    props.onCompleted(data.evaluation_scales.data);
  }

  const onError = (err, res) => {
    setSaveLoader(false)
    message.error(res.response.data.message);
  }

  //-----------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [
    !props.disabled &&
    <SaveButton loading={ saveLoader } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } width={ 1000 } maskClosable={ false }
      mainTitle={ trans(Commons[props.disabled ? 'view' : 'update']) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) } disabled={ props.disabled }>

      <Form { ...formLayout } form={ form } name={ formName } onFinish={ onSubmit } disabled={ props.disabled } scrollToFirstError>

        <Row gutter={ 20 }>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="name" rules={ rules.name } label={ trans(Commons.name) }
              className="da-mb-16"
              { ...getErrorProps(errors['name']) } size="small"
            >
              <InputText placeholder={ trans(`${langKey}.name`) } />
            </Form.Item>
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="short_name" rules={ rules.short_name } label={ trans(`${langKey}.short_name`) }
              className="da-mb-16"
              { ...getErrorProps(errors['short_name']) } size="small"
            >
              <InputText placeholder={ trans(`${langKey}.short_name`) } />
            </Form.Item>
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="scale" rules={ rules.scale } label={ trans(`${langKey}.scale`) }
              className="da-mb-16"
              { ...getErrorProps(errors['scale']) } size="small"
            >
              <InputText placeholder={ trans(`${langKey}.scale`) } type="number" />
            </Form.Item>
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="is_active" rules={ rules.is_active } label={ trans(`${langKey}.is_active`) }
              className="da-mb-16"
              { ...getErrorProps(errors['is_active']) } size="small"
              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="description" rules={ rules.description } label={ trans(Commons.description) }
              className="da-mb-16"
              { ...getErrorProps(errors['description']) } size="small"
            >
              <InputTextArea placeholder={ trans(`${langKey}.description`) } />
            </Form.Item>
          </Col>
        </Row>

      </Form>
    </ModalComponent>
  )
}

export default UpdateEvaluationScale

const rules = {
  name: [
    { required: true, message: '' },
  ],

};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}