import { memo } from "react";
import { useSelector } from 'react-redux'
import AuthApp from '@mods/userManagement/auth/routes';
import Layouts from "./Layout";
import { AppProvider } from "../utils/context";
import { IntlProvider } from "react-intl";
import AppLocale from '../lngProvider/index';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
const App = () => {
  const authUser = useSelector((state) => state.auth.authUser);
  const currentAppLocale = AppLocale["en"]

  return (
    <DndProvider backend={HTML5Backend}>
   <AppProvider>
      <IntlProvider messages={ currentAppLocale.messages }
        locale={ currentAppLocale.locale } defaultLocale="en">
        { authUser ? <Layouts /> : <AuthApp /> }
      </IntlProvider>
    </AppProvider>
    </DndProvider>
   
  );
}

export default memo(App);
