import React, { useEffect, useMemo, useState } from "react";
import { Button, Form, Steps, message, ColorPicker, Space, Row, Col, Checkbox } from "antd";
import { SaveButton, ModalComponent, trans, InputDate } from "@comps/components"
import { makeRequest, makeRequestStateless, getErrorProps } from "@utils/helpers";
import { createDataReq, getPreviewDataReq, getRelatedDataReq } from "../requests";
import { Commons, Const } from "../../constants";
import FilterSelectEmployees from "@mods/common/FilterSelectEmployees"
import moment from "moment";
const langKey = Const["restday"].lngKey
const formName = "createRestday";

const CreateRestday = (props) => {
  const [loader, setLoader] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [fontcolorHex, setFontColorHex] = useState('#1B24C2');
  const [bgcolorHex, setBgColorHex] = useState('#16FF31');
  const [formatHex, setFormatHex] = useState('hex');
  const [companyRelatedData, setCompanyRelatedData] = useState([]);

  const [selectedEmployments, setEmployments] = useState([]);
  const [setInitialEmployeesCriteria] = useState(null);
  const [previewEmployment, setPreviewEmployments] = useState([]);

  const [dates, setDates] = useState([]);

  //------------intitial steps state---------
  const [firstStepData, setFirstStepData] = useState(null);
  const [currentStep, setCurrent] = useState(0);
  const [isRepeat, setIsRepeat] = useState(false);
  const [fixStartDate, setFixStartDate] = useState(null);
  const [daysOn, setDaysOn] = useState([]);

  const [preEmployeesFilter, setPreEmployeesFilter] = useState({})
  const [unSelectedEmployees, setUnSelectedEmployees] = useState([])
  const [selectedEmployees, setSelectedEmployees] = useState([])
  //=======================================

  useEffect(() => {
    getCompanyRelatedData()
    // eslint-disable-next-line
  }, []);
  const getCompanyRelatedData = () => {
    makeRequestStateless(getRelatedDataReq, null, ongetRelatedDataReq, null);
  }
  const ongetRelatedDataReq = (response) => {
    setCompanyRelatedData(response)
  }
  //==============================Save all Data=========================================
  const onSubmit = (firstStepData, employmentIds) => {
    firstStepData.font_colour = firstStepData.font_colour !== undefined ? firstStepData.font_colour : fontcolorHex
    const payload = {
      start_date: firstStepData.start_date.format('YYYY-MM-DD'), end_date: firstStepData.end_date.format('YYYY-MM-DD'), font_colour: firstStepData.font_colour, bg_colour: firstStepData.bg_colour, dates: firstStepData.dates, employment_ids: selectedEmployments.employment_ids
    };
    makeRequest(setLoader, createDataReq, payload, onSuccess, onError);
    setSaveLoader(true)
  }

  const onSuccess = (data, res) => {
    setSaveLoader(false)
    if (data.errors.length > 0) {
      message.error(`${data.errors[0].message}`);
    }
    else {
      message.success("Saved successfully");
    }

    props.onCreated(data.rest_days.data);
    props.onSuccess(data)
  }
  const onError = (err, res) => {
    setSaveLoader(false)
    message.error(res.response.data.message);
  }

  //=================Submit First Step 1==============================================

  const onSelectDays = (day) => {
    setDaysOn(day)
  }
  const onChangeStartDate = (date) => {
    setFixStartDate(date)
  }
  const onChangeEndDate = (date) => {
    let start = fixStartDate ? fixStartDate.format('YYYY-MM-DD') : null;
    let end = date ? date.format('YYYY-MM-DD') : null
    if (date !== null) {
      if (end < start) {
        message.error('End Date should be greater than start date')
        setIsRepeat(false)
        return
      }
      if (start === end) {
        setIsRepeat(false)
      }
      else {
        setIsRepeat(true)
      }
    }
    if (date === null) {
      setIsRepeat(false)
    }
  }

  const days = [
    { value: 'Mon', label: 'Mon' },
    { value: 'Tue', label: 'Tue' },
    { value: 'Wed', label: 'Wed' },
    { value: 'Thu', label: 'Thu' },
    { value: 'Fri', label: 'Fri' },
    { value: 'Sat', label: 'Sat' },
    { value: 'Sun', label: 'Sun' },
  ];
  const onSubmitFirstStep = (data) => {

    if (data.end_date === undefined || data.end_date === null) {
      data.end_date = data.start_date;
    }
    if (data.end_date.format('YYYY-MM-DD') < data.start_date.format('YYYY-MM-DD')) {
      message.error('End Date can only be greater Than or equal to Start Date!')
      return
    }
    data.font_colour = data.font_colour !== undefined ? fontColorHexString : fontcolorHex
    data.bg_colour = data.font_colour !== undefined ? bgColorHexString : bgcolorHex
    let dateRange = daysOn.length > 0 ? getDatesRange(data.start_date, data.end_date) : [data.start_date.format('YYYY-MM-DD')];
    setDates(dateRange);
    data.dates = dateRange;
    setFirstStepData(data);
    setCurrent(currentStep + 1)
  }

  const getDatesRange = (start, end) => {
    let startDate = start;
    let endDate = end;
    let daterange = []
    while (startDate <= endDate) {
      const day = startDate.format('dddd').substring(0, 3);
      if (daysOn.includes(day)) { //only push dates in selected days
        daterange.push(startDate.format('YYYY-MM-DD'));
      }
      startDate = startDate.add(1, 'days');
    }
    return daterange;
  }
  //---------color to hex string--------------
  const fontColorHexString = useMemo(
    () => (
      typeof fontcolorHex === 'string' ? fontcolorHex : fontcolorHex.toHexString()),
    [fontcolorHex],
  );
  const bgColorHexString = useMemo(
    () => (
      typeof bgcolorHex === 'string' ? bgcolorHex : bgcolorHex.toHexString()),
    [bgcolorHex],

  );
  //=====================On Submit Second Step===========================
  const setSelectedEmploymentsIds = (ids, initialCriteria) => {
    //set selected employments ids
    // setInitialEmployeesCriteria(initialCriteria)
    setEmployments(ids)
    setCurrent(currentStep + 1)
    let payload = ids;
    makeRequest(setLoader, getPreviewDataReq, payload, ongetPreviewDataReq, null);
  }
  const ongetPreviewDataReq = (response) => {
    setPreviewEmployments(response.employments)
  }

  const onCancel = () => {
    props.onCreated(false);
  }
  const onBack = () => {
    setCurrent(currentStep - 1)
  }
  //-----------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------
  //====================steps============================


  //======================================================
  const firstStep = () => {
    return (
      <>
        <Form { ...formLayout } name={ formName } onFinish={ onSubmitFirstStep } initialValues={ firstStepData }>
          <Row gutter={ 20 }>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="start_date" rules={ rules.start_date } label={ trans(`${langKey}.start_date`) }
                className="da-mb-16"
                { ...getErrorProps(errors['name']) } size="small"
              >
                <InputDate onChange={ onChangeStartDate } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="end_date" rules={ []} label={ trans(`${langKey}.end_date`) }
                className="da-mb-16"
                { ...getErrorProps(errors['name']) } size="small"
              >
                <InputDate onChange={ onChangeEndDate } disabled={ fixStartDate ? false : true } />
              </Form.Item>
            </Col>
            {
              isRepeat ?
                <>
                  <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                    <Form.Item name="days" rules={ rules.days } label={ trans(`${langKey}.days`) }
                      className="da-mb-16"
                      { ...getErrorProps(errors['name']) } size="small"
                    >
                      <Checkbox.Group
                        style={ { width: "100%" } }
                        value={ daysOn }
                        onChange={ onSelectDays }
                        options={ days }
                      >
                        { days.map((day) => (
                          <Checkbox key={ day.value } value={ day.value }>
                            { day.label }
                          </Checkbox>
                        )) }
                      </Checkbox.Group>
                    </Form.Item>
                  </Col>
                </>

                : ''
            }

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="font_colour" rules={ []} label={ trans(`${langKey}.font_colour`) }
                className="da-mb-16"
                { ...getErrorProps(errors['font_colour']) } size="small"
              >
                <ColorPicker
                  format={ formatHex }
                  value={ fontcolorHex }
                  onChange={ setFontColorHex }
                  onFormatChange={ setFormatHex } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="bg_colour" rules={ []} label={ trans(`${langKey}.bg_colour`) }
                className="da-mb-16"
                { ...getErrorProps(errors['bg_colour']) } size="small"
              >
                <ColorPicker
                  format={ formatHex }
                  value={ bgcolorHex }
                  onChange={ setBgColorHex }
                  onFormatChange={ setFormatHex }
                />
              </Form.Item>
            </Col>
          </Row>

          <Row justify="end">
            <Space>

              <Button className="button-next" type="primary" htmlType="submit">
                Next
              </Button>
            </Space>
          </Row>
        </Form>
      </>
    )
  }
  //==============================Second Step(2)=============================

  //============================Third Step(3)====================================
  const thirdStep = () => {
    return (
      <>
        <Row gutter={ 30 }>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <div className="preview-employee-table">
              <div className="preview-employee-title">
                <h5 className="preview-employee-title-style">Employees</h5>
              </div>
              { previewEmployment && previewEmployment.map((each) => (
                <Space>
                  <li key={ each.id }>
                    <div style={ { width: 250, height: 64, border: "1px solid #4079FC", borderRadius: 5, marginTop: 16, marginLeft: 16, background: "rgba(64, 121, 252, 0.05)" } }>
                      <div >
                        <div><img className="each-profile-pic-thumb-url" src={ "/images/arnold-avatar.jpg" } alt="" /></div>
                        <div className="each-employee"><p className="each-full-name">{ each.name }</p>
                          <p className="each-designation-name" >{ each.designation_name }</p>
                        </div>
                      </div>
                    </div>
                  </li>
                </Space>
              )) }
            </div>
          </Col>


          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <div className="preview-employee-table">
              <div className="preview-employee-title">
                <h5 className="preview-employee-title-style">Rest Days</h5>
              </div>
              <table style={ { width: "100%", marginTop: 10 } }>
                <thead>
                  <tr style={ { borderBottom: "1px solid #C9D6DF" } }>
                    <th style={ { paddingLeft: 20 } }>Rest Days</th>
                    <th style={ { paddingLeft: 160 } }>Days</th>
                    <th style={ { paddingLeft: 80 } }>Months</th>
                  </tr>
                </thead>
                <tbody>
                  { dates.map((date) => {

                    const currentDate = moment(date, 'YYYY-MM-DD');

                    return (
                      <tr key={ date } style={ { color: "#687980" } }>
                        <td style={ { paddingLeft: 20, paddingTop: 5 } }>{ currentDate.format('DD') }</td>
                        <td style={ { paddingLeft: 160 } }>{ currentDate.format('dddd') }</td>
                        <td style={ { paddingLeft: 80, whiteSpace: "nowrap" } }>{ currentDate.format('MMM D,YYYY') }</td>
                      </tr>
                    );
                  }) }
                </tbody>
              </table>
            </div>
          </Col>
        </Row>


        <Row justify="end">
          <Space>
            <Button className="button-back" type="primary" onClick={ () => setCurrent(currentStep - 1) } disabled={ currentStep > 0 ? false : true }>
              <span style={ { color: "#4079FC" } }>Back</span>
            </Button>
            <SaveButton loading={ saveLoader } className="button-next" disabled={ firstStepData.length > 0 ? false : previewEmployment.length > 0 ? false : true } form={ formName } key="create_button" onClick={ () => onSubmit(firstStepData, previewEmployment) } />
          </Space>
        </Row>
      </>
    )
  }
  //================================Main Modal Component==================================
  return (
    <ModalComponent top={ 20 } width={ 1200 } maskClosable={ false }
      mainTitle={ trans(Commons.create) } subTitle={ <>
        <p style={ { paddingLeft: 75, marginTop: -30 } }>{ trans(`${langKey}.moduleName`) }</p>
        <Steps
          className="steps-style"
          current={ currentStep }
          onChange={ setCurrent }
        >
          <Steps.Step className="steps-step1" title={ "Time and Date" } style={ { paddingLeft: 50 } } />
          <Steps.Step className="steps-step2" title={ "Select Employees" } disabled={ firstStepData ? false : true } />
          <Steps.Step className="steps-step3" title={ "Finalise" } disabled={ previewEmployment.length > 0 ? false : true } />
        </Steps>
      </> }
      visible={ true } footer={ '' } onCancel={ () => props.onCreated(false) }>


      { currentStep === 0 ? firstStep() : currentStep === 1 ?
        <FilterSelectEmployees setSelectedEmploymentsIds={ setSelectedEmploymentsIds } companyRelatedData={ companyRelatedData } onCancel={ onCancel } onBack={ onBack }
          employment_ids={ selectedEmployments }
          moduleName='restDay' selected={ selectedEmployees } unSelected={ unSelectedEmployees } preFilters={ preEmployeesFilter } setSelected={ setSelectedEmployees } setUnselected={ setUnSelectedEmployees } setPrefilters={ setPreEmployeesFilter }
        />
        : currentStep === 2 ? thirdStep() : '' }
    </ModalComponent>
  )
}

export default CreateRestday

const rules = {
  start_date: [
    { required: true, message: 'Required' },
  ],
  end_date: [
    { required: true, message: 'Required' },
  ],
  repeat: [
    { required: true, message: 'Required' },
  ],
  font_colour: [
    { required: true, message: 'Required' },
  ],
  bg_colour: [
    { required: true, message: 'Required' },
  ],
  days: [
    { required: true, message: 'Please Select At least one day' },
  ],

};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}
