import { get, del, post, postFd, put, putFd } from "@utils/axios";

const api = "employment/assets";

export const getAllAssetsReq = (payload) => {
  return get(`${api}?page=${payload.page}&filters=${payload.filters}`);
};

export const createAssetReq = (payload) => {
  return postFd(`${api}`, payload);
};

export const updateAssetReq = (payload) => {
  return postFd(`${api}/${payload.id}/update`, payload);
};
export const deallocateAssetReq = (payload) => {
  return postFd(`${api}/deallocate/${payload.id}`, payload);
};

export const deleteAssetReq = (id) => {
  return del(`employment/assets/${id}`);
};

//-----------on create
export const getAllEmploymentsReq = () => {
  return get("resources/employment?page=1&filters=company_id_|1");
};

export const getRelatedDataReq = () => {
  return get(`employee_assets/get-related-data?`)
}

export const getAssetTypesReq = (id) => {
  return get(`common/getAssetTypes`)
}
export const getAssetsByTypeReq = (id) => {
  return get(`common/getAssetsByType/${id}`)
}

//on filter
export const getFilterReq = (payload) => {
  return get(`filters?company_id=1&fields=page=1&filters=supporting_fields_|employees,asset_type,allocation_status;organization_structure_fields_|company;preset_|employment_assets;`)
}