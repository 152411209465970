import { useState, useEffect } from "react";
import {
  BodyComponent,
  TableComponent,
  ActionComponent,
  CreateButton,RefreshButton,
  trans,
  HeaderComponent,
  PaginationComponent,DownloadButton,ImportButton
} from "@comps/components";
import { makeRequest ,makeRequestStateless, handleDownloadFile} from "@utils/helpers";
import { Commons, Const } from "../../constants";
import { message } from 'antd';
import { FilterComponent, FilterButton } from "@comps/components";
import { deleteDataReq, getAllDataReq, getFilterReq } from "./requests";
import CreateCustomPayitem from "./components/CreateCustomPayitem";
import UpdateCustomPayitem from "./components/UpdateCustomPayitem";
import { useParams } from 'react-router-dom';
import EmpShortInfo from "@mods/common/EmpShortInfo";
import { convertTimeTotz } from '@comps/commonFunctions';
import ImportSample from "@mods/common/ImportSample";
import { downloadSampleReq } from "@mods/common/requests";

const langKey = Const["payitem"].lngKey;


//========================================
const IndexCustomPayitem = (props) => {
  const { name,id } = useParams()
  const [totalRecords, setTotalRecords] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [allData, setAllData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [childComponent, setChildComponent] = useState(null);
  const [filerComponent, setFilterComponent] = useState(null);
  const [filters, setFilters] = useState();
  //start pagination states
  const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState({
    "total": 0,
    "count": 0,
    "per_page": 0,
    "current_page": 0,
    "total_pages": 0,
    "links": {}
  });
  //end pagination states

  // ==================================
  //   Use States End
  // ==================================

  // ================================== UpdateLocation
  //   Table Data End
  // ==================================
  const pageConfig = {
    headers: {
      title: name+' CPI',
      breadcrumb: [
        {
          name: trans(`${langKey}.customPayitem`),
          path: Const["payitem"].route
        }
      ]
    }
  }

  const columns = [
    {
      key: 'employee',
      title: trans(`${langKey}.employee`),
     render:(record)=>(
      record.employee_row?<EmpShortInfo record={record.employee_row}/>:''
     )
    },
    {
      key: 'date',
      title: trans(`${langKey}.date`),
     render:(record)=>(
       convertTimeTotz(record.date).format('YYYY-MM-DD')
     )
    },
    {
      key: 'amount',
      title: trans(`${langKey}.amount`),
      render:(record)=>(
        'Rs '+record.amount
      )
    },
    {
      key: 'processed',
      title: trans(`${langKey}.processed`),
      render: (record) => (
        record.processed?"Yes":"No"
      ),
    },
    {
      key: trans(Commons.actions),
      title: trans(Commons.actions),
      render: (record) => (
        <ActionComponent
          each={ record } modkey={props.modkey}
          onDelete={!record.processed && onDelete }
          onEdit={!record.processed && onEdit }
          onView={ onView }
        ></ActionComponent>
      ),
    },
  ];

  // ==================================
  //   Table Data End
  // ==================================

  useEffect(() => {
    getAllData();
    // eslint-disable-next-line
  }, [filters, currentPage,id]);

  const getAllData = () => {
    const payload = {
      id:id,
      page: currentPage,
      filters: filters
    }
    makeRequest(setLoader, getAllDataReq, payload, onSuccess, null);
  };

  const onSuccess = (response) => {
    setAllData(response)
    setPagination(response.custom_payitems);
    setDataSource(response.custom_payitems.data);

  };

  const onEdit = (record) => {
    setChildComponent(
      <UpdateCustomPayitem onCompleted={ onCompleted } record={ record } disabled={ false } allData={ allData } />
    );
  };

  const onView = (record) => {
    setChildComponent(
      <UpdateCustomPayitem onCompleted={ onCompleted } record={ record } disabled={ true } allData={ allData } />
    );
  };

  const onDelete = (record) => {

    makeRequest(setLoader, deleteDataReq, record, onDeleted, onError);
  };
  const onDeleted = (res) => {
    onSuccess(res)
    setDataSource(res.custom_payitems.data);
    message.success("Record Deleted")
  };

  const onError = (err, res) => {
    message.error(res.response.data.message);
    //setErrors(errorList);
  }

  // Create component modal
  const onCreate = () => {
    setChildComponent(<CreateCustomPayitem onCompleted={ onCompleted } allData={ allData } onSuccess={ onSuccess } payitemId={id}/>);
  };

  const onCompleted = (data) => {
    if (data.length) {
      setDataSource(data);
    }
    setChildComponent(null);
  };

  const handleTableChange = (page, fil, sorter) => {
    let payload = {
      ...pagination,
      current: page.current,
      pageSize: page.pageSize,
      sortName: sorter.field || "id",
      sortType: sorter.order === "ascend" ? "asc" : "desc",
    };
    setPagination(payload);
  };

  //---------------filters-----------------------
  const onOpenFilter = () => {
    setFilterComponent(<FilterComponent filters={ filterFields } modkey={props.modkey} selected={ filters } api={ getFilterReq } setCurrentPage={setCurrentPage} onFilter={ setFilters } onCloseFilter={ onCloseFilter }
    />
    )
  }

  const onCloseFilter = () => {
    setFilterComponent(null);
  }

  //----end filter--------------------
//download sample log
const onDownloadSample = (api) => {
  makeRequestStateless(downloadSampleReq, api, onSuccessDownloadSample, onSuccessDownloadSample);
  setLoader(true)
}
const onSuccessDownloadSample = (res, err) => {
setLoader(false)
  if (res.code === undefined) {
    // Extract file extension
    const fileExtension = res.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ? 'xlsx' : 'pdf'
    const fileName = `custom_payitem_import.${fileExtension}`
    handleDownloadFile(fileName, res)
  }
  else {
    message.error(res.message)
  }
}


  return (
    <>
      { filerComponent }
      { childComponent }
      <BodyComponent>
        <HeaderComponent headers={ pageConfig.headers } modkey={props.modkey} moduleKey="customPayitem">
        <DownloadButton onClick={ ()=>onDownloadSample('payitem/'+id+'/customPayitems/download-sample') } disabled={loader}/>
        <DownloadButton onClick={ ()=>onDownloadSample('payitem/'+id+'/customPayitems/download/pdf?page='+currentPage) } text="Pdf"  disabled={loader}/>
        <DownloadButton onClick={ ()=>onDownloadSample('payitem/'+id+'/customPayitems/download/xlsx?page='+currentPage) } text="Excel"  disabled={loader}/>
          <ImportButton onClick={ () => setChildComponent(<ImportSample onSuccess={getAllData} api={`payitem/${id}/customPayitems/import`} module="Custom Payitem" onCompleted={ () => setChildComponent(null) } />) } />
          <RefreshButton onClick={ getAllData } /> <CreateButton onClick={ onCreate } />
          
          <FilterButton onClick={ onOpenFilter } />
        </HeaderComponent>
        <TableComponent modkey={props.modkey}
          loader={ loader }
          columns={ columns }
          dataSource={ dataSource }
          pagination={ { ...pagination, total: totalRecords } }
          onChange={ handleTableChange }
        />
      </BodyComponent>
      <PaginationComponent pagination={ pagination } modkey={props.modkey} setCurrentPage={ setCurrentPage } currentPage={ currentPage } />
    </>
  );
};

export default IndexCustomPayitem;
const filterFields = [
  {
    type: "select",
    placeholder: 'Employee',
    key: "employees",
    data_key: "supporting_data.employments",
    resource_type: 'employment',
  },
  {
    type: "date",
    placeholder: "From",
    key: "from"
  },
  {
    type: "date",
    placeholder: "To",
    key: "to"
  },
]

