import React, { useEffect, useMemo, useState } from "react";
import { Form, message, ColorPicker, Row, Col } from "antd";
import { SaveButton, InputText, CancelButton, ModalComponent, trans, InputDate } from "@comps/components"
import { makeRequest, getErrorProps } from "@utils/helpers";
import { updateDataReq } from "../requests";
import { Commons, Const } from "../../constants";
import moment from "moment";

const langKey = Const["restday"].lngKey
const formName = "updateRestday";

const UpdateRestday = (props) => {
  const [loader, setLoader] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [fontcolorHex, setFontColorHex] = useState('#1B24C2');
  const [bgcolorHex, setBgColorHex] = useState('#5BC62A');
  const [formatHex, setFormatHex] = useState('hex');
  const [form] = Form.useForm();
  useEffect(() => {
    setFormData();
  }, []);

  const setFormData = () => {
    if (!props) {
      return;
    }
    props.record.date = props.record.date ? moment(props.record.date) : null;
    form.setFieldsValue(
      {
        "employment_name": props.record.employment_name,
        "date": props.record.date,
        "font_colour": props.record.font_colour,
        "bg_colour": props.record.bg_colour
      }
    );
  }
  const onSubmit = (data) => {
    data.font_colour = fontColorHexString;
    data.bg_colour = bgColorHexString;
    data.id = props.record.id;
    const payload = { rest_day: data };
    payload.id = props.record.id;
    makeRequest(setLoader, updateDataReq, payload, onSuccess, onError);
    setSaveLoader(true)
  }

  const onSuccess = (data, res) => {
    setSaveLoader(false)
    message.success("Record Updated");
    props.onCreated(data.rest_days.data);
  }

  const onError = (err, res) => {
    setSaveLoader(true)
    message.error(res.response.data.message);
  }
  //---------color to hex string--------------
  const fontColorHexString = useMemo(
    () => (
      typeof fontcolorHex === 'string' ? fontcolorHex : fontcolorHex.toHexString()),
    [fontcolorHex],
  );
  const bgColorHexString = useMemo(
    () => (
      typeof bgcolorHex === 'string' ? bgcolorHex : bgcolorHex.toHexString()),
    [bgcolorHex],
  );
  // ------------------------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [
    !props.disabled &&
    <SaveButton loading={ saveLoader } form={ formName } key="update_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCreated(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } width={ 1000 } maskClosable={ false }
      mainTitle={ trans(Commons[props.disabled ? 'view' : 'update']) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCreated(false) } disabled={ props.disabled }>

      <Form { ...formLayout } form={ form } name={ formName } onFinish={ onSubmit } disabled={ props.disabled }>
        <Row gutter={ 30 }>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="employment_name" rules={ rules.employment_id } label={ trans(`${langKey}.employee`) }
              className="da-mb-16"
              { ...getErrorProps(errors['name']) } size="small"
            >
              <InputText disabled />
            </Form.Item>
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="date" rules={ rules.date } label={ trans(`${langKey}.date`) }
              className="da-mb-16"
              { ...getErrorProps(errors['date']) } size="small"
            >
              <InputDate disabled />
            </Form.Item>
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="font_colour" rules={ rules.font_colour } label={ trans(`${langKey}.font_colour`) }
              className="da-mb-16"
              { ...getErrorProps(errors['font_colour']) } size="small"
            >
              <ColorPicker
                format={ formatHex }
                value={ fontcolorHex }
                onChange={ setFontColorHex }
                onFormatChange={ setFormatHex } />
            </Form.Item>
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="bg_colour" rules={ rules.bg_colour } label={ trans(`${langKey}.bg_colour`) }
              className="da-mb-16"
              { ...getErrorProps(errors['bg_colour']) } size="small"
            >
              <ColorPicker
                format={ formatHex }
                value={ bgcolorHex }
                onChange={ setBgColorHex }
                onFormatChange={ setFormatHex }
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </ModalComponent>
  )
}

export default UpdateRestday

const rules = {
  name: [
    { required: true, message: '' },
  ],

};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}