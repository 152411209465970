import { Divider, Layout, Space, Tabs } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import IndexAccolades from './components/accolades/IndexAccolades';
import IndexSkills from './components/skills/IndexSkills';
import {
  HeaderComponent,
} from "@comps/components";
import IndexAssetTypes from './components/assetTypes/IndexAssetTypes';
import IndexCareerLevel from './components/careerLevels/IndexCareerLevel';
import IndexBloodGroups from './components/bloodGroups/IndexBloodGroups';
import IndexContractTypes from './components/employmentTypes/IndexContractTypes';
import IndexEmployeeTypes from './components/employeeTypes/IndexEmployeeTypes';
import IndexCertifications from './components/certifications/IndexCertifications';
import IndexMaritalStatuses from './components/maritalStatuses/IndexMaritalStatuses';
import IndexQualifications from './components/qualifications/IndexQualifications';
import IndexRelationships from './components/relationships/IndexRelationships';
import IndexReligions from './components/religions/IndexReligions';
import IndexSalutations from './components/salutations/IndexSalutations';
import IndexInstitutes from './components/institutes/IndexInstitutes';
import IndexLanguages from './components/languagess/IndexLanguages';
import IndexOccupationTypes from './components/occupationTypes/IndexOccupationTypes';
import IndexCountries from './components/countries/IndexCountries';
import IndexStates from './components/states/IndexStates';
import IndexCities from './components/cities/IndexCities';
import IndexDocuments from './components/documentss/IndexDocuments';
import IndexDocumentTypes from './components/documentTypes/IndexDocumentTypes';
import IndexWows from './components/wow/IndexWows';
import IndexNewsLetters from './components/newsletter/IndexNewsLetters';
import IndexCurrencies from './components/currency/IndexCurrencies';
import IndexAssets from './components/assetss/IndexAssets';
import IndexBenefits from './components/benefits/IndexBenefits';
import IndexKpi from './kpiManagement/kpi/IndexKpi';
import IndexKpiAllocation from './kpiManagement/kpi/IndexKpiAllocation';
import IndexKpiEvaluation from './kpiManagement/kpi/components/kpiEvaluation.jsx/IndexKpiEvaluation';
import { checkPermissions } from '@comps/commonFunctions';

const { Content } = Layout;

const IndexGeneralMasters = (props) => {
  const navigate = useNavigate()
  //------------use states start----------------
  const [tabPosition, setTabPosition] = useState('left');
  const [currentTab, setCurrentTab] = useState('KpiManagement');
  //-----------end use states--------------------
  const changeTabPosition = (e) => {
    setTabPosition(e.target.value);
  };
  const onChangeTab = (e) => {
    setCurrentTab(e)
  };
  //-----------styling------------

  const TabContentStyle = { background: 'white', padding: '20px' }
  const pageConfig = {
    headers: {
      title: "",
      breadcrumb: [
        {
          name: "Masters",
          path: "",
        },
        {
          name: "General",
          path: "",
        },
        {
          name: `${currentTab}`,
          path: "",
        },
      ],
    },
  };
  //----------end styling-----------
  return (
    <>
      <Space
        style={ {
          marginBottom: 24,
        } }
      >
      </Space>
      <Layout>

        <HeaderComponent headers={ pageConfig.headers } modkey={props.modkey}>

        </HeaderComponent>

        <Layout hasSider>
          <Content style={ TabContentStyle }>
            <Tabs
              centered
              tabPosition='left'
              onChange={ onChangeTab }
            >
              <Tabs.TabPane tab="KPI Management" key={ 'KpiManagement' }>
                <Tabs
                  type="card"
                  tabPosition='top'
                  onChange={ onChangeTab }
                >
                  <Tabs.TabPane tab="KPIs" key={ 'kpi' }>
                    { checkPermissions('kpi', 'index') && <IndexKpi /> }
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Allocations" key={ 'kpiAllocations' }>
                    { checkPermissions('kpiAllocation', 'index') && <IndexKpiAllocation /> }
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Evaluations" key={ 'kpiEvaluations' }>
                    { checkPermissions('kpiEvaluation', 'index') && <IndexKpiEvaluation /> }
                  </Tabs.TabPane>
                </Tabs>
              </Tabs.TabPane>

              <Tabs.TabPane tab="Accolades" key={ 'Accolades' }>
                <IndexAccolades />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Skills" key={ 'Skills' } >
                <IndexSkills />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Career Levels" key={ 'Career Levels' }>
                <IndexCareerLevel />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Assets" key={ 'Assets' }>
                <IndexAssets />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Asset Types" key={ 'Asset Types' }>
                <IndexAssetTypes />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Benefits" key={ 'Benefits' }>
                <IndexBenefits />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Blood Groups" key={ 'Blood Groups' }>
                <IndexBloodGroups />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Certifications" key={ 'Certifications' }>
                <IndexCertifications />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Cities" key={ 'Cities' }>
                <IndexCities />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Countries" key={ 'Countries' }>
                <IndexCountries />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Currencies" key={ 'Currencies' }>
                <IndexCurrencies />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Documents" key={ 'Documents' }>
                <IndexDocuments />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Document Types" key={ 'Document Types' }>
                <IndexDocumentTypes />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Employee Types" key={ 'Employee Types' }>
                <IndexEmployeeTypes />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Employment Types" key={ 'Employment Types' }>
                <IndexContractTypes />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Institutes" key={ 'Institutes' }>
                <IndexInstitutes />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Languages" key={ 'Languages' }>
                <IndexLanguages />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Occupation Types" key={ 'Occupation Types' }>
                <IndexOccupationTypes />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Marital Statuses" key={ 'Marital Statuses' }>
                <IndexMaritalStatuses />
              </Tabs.TabPane>
              <Tabs.TabPane tab="News Letters" key={ 'News Letters' }>
                <IndexNewsLetters />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Relationships" key={ 'Relationships' }>
                <IndexRelationships />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Religions" key={ 'Religions' }>
                <IndexReligions />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Qualifications" key={ 'Qualifications' }>
                <IndexQualifications />
              </Tabs.TabPane>
              <Tabs.TabPane tab="States" key={ 'States' }>
                <IndexStates />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Salutations" key={ 'Salutations' }>
                <IndexSalutations />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Words of Wisdoms" key={ 'Words of Wisdoms' }>
                <IndexWows />
              </Tabs.TabPane>
            </Tabs>
          </Content>
          <Divider orientation='left' type='vertical' />

        </Layout>

      </Layout>


    </>
  );


};
export default IndexGeneralMasters;