import React, { useEffect, useState } from "react";
import { Divider, Form, Skeleton, message, Row, Col } from "antd";
import { SaveButton, CancelButton, ModalComponent, trans, InputDate, InputTime, InputTextArea, SelectWithSearch, FlowHistoryButton } from "@comps/components"
import { makeRequest, getErrorProps } from "@utils/helpers";
import { Commons, Const } from "../../../constants";
import EmployeeInfo from '@mods/common/EmployeeInfo';
import dayjs from 'dayjs';
import { approveRequest } from "../requests";
import { convertTimeTotz } from '@comps/commonFunctions';
const langKey = Const["attendanceApproval"].lngKey
const formName = "updateattendanceApproval";

const UpdateAttendanceApproval = (props) => {
  const [loader, setLoader] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [remarks, setRemarks] = useState('');
  const [loading, setLoading] = useState(false)
  const [childComponent, setChildComponent] = useState(null)
  const [form] = Form.useForm()

  useEffect(() => {
    setData();
    // eslint-disable-next-line
  }, []);

  const setData = () => {
    props.record.date = convertTimeTotz(props.record.date)
    props.record.expected_check_in = null
    props.record.expected_check_out = null
    props.record.punch_time = convertTimeTotz(props.record.punch_time)
    form.setFieldsValue({ ...props.record, remarks: props.record.last_remarks })
  }

  const onSubmit = (isApproval) => {
    setSaveLoader(true)
    const payload = { request: { ...props.record, is_approval: isApproval, request_as: props.requestAsId, last_remarks: remarks, remarks: remarks, current_history: { remarks: remarks } }, delegator_id: props.delegatorId, api: props.api, filters: props.filters, }
    makeRequest(setLoader, approveRequest, payload, onSuccess, onError);
  }

  const onSuccess = (data, res) => {
    setSaveLoader(false)
    message.success("Saved successfully");
    props.onCompleted(data.attendance_requests.data);
  }
  const onError = (err, res) => {
    setSaveLoader(false)
    message.error(res.response.data.message);
  }

  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------
  const footer = [
    childComponent,
    <div style={ { float: 'left' } }>
      < FlowHistoryButton record={ props.record } setChildComponent={ setChildComponent } />
    </div>,
    <SaveButton disabled={ props.disabled } loading={ saveLoader } form={ formName } key="create_button" onClick={ () => onSubmit(true) } text='Approve' />,
    <CancelButton disabled={ props.disabled } loading={ saveLoader } key="reject_button" form={ formName } htmlType='submit' text='Reject' onClick={ () => onSubmit(false) } />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];
  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------
  return (
    <ModalComponent top={ 20 } width={ 1200 } maskClosable={ false }
      mainTitle={ trans(props.disabled ? Commons.view : Commons.update) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Skeleton loading={ loading }>
        <EmployeeInfo record={ props.record.requestor && props.record.requestor } />
        <Form { ...formLayout } name={ formName } onFinish={ onSubmit } form={ form } disabled={ true } scrollToFirstError>
          <Divider>Request</Divider>
          {/* <Form.Item name="requestor.full_name_code" rules={ rules.employment_id } label={ trans(`${langKey}.employment_id`) }
            className="da-mb-16"
            { ...getErrorProps(errors['name']) } size="small"
            initialValue={ props.record.requestor.full_name_code }
          >
            <InputText disabled={ true }
            />
          </Form.Item> */}

          <Row gutter={ 20 }>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="date" rules={ rules.date } label={ trans(`${langKey}.date`) }
                className="da-mb-16"
                { ...getErrorProps(errors['date']) } size="small"
              >
                <InputDate />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="punch_type" rules={ rules.punch_type } label={ trans(`${langKey}.punch_type`) }
                className="da-mb-16"
                { ...getErrorProps(errors['name']) } size="small"
              >
                <SelectWithSearch
                  options={ [
                    {
                      label: "Check In",
                      value: "Check In"
                    },
                    {
                      label: "Check Out",
                      value: "Check Out"
                    },
                  ] }
                  placeholder={ trans(`${langKey}.punch_type`) }

                />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="punch_time" rules={ rules.punch_time } label={ trans(`${langKey}.punch_time`) }
                className="da-mb-16"
                { ...getErrorProps(errors['punch_time']) } size="small"
              >
                <InputTime placeholder={ trans(`${langKey}.punch_time`) } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="description" rules={ rules.description } label={ trans(`${langKey}.description`) }
                className="da-mb-16"
                { ...getErrorProps(errors['description']) } size="small"
              >
                <InputTextArea placeholder={ trans(`${langKey}.description`) } />
              </Form.Item>
            </Col>
          </Row>
          <Divider>Approval</Divider>
          <Form.Item name="last_remarks" rules={ rules.last_remarks } label={ trans(`${langKey}.last_remarks`) }
            className="da-mb-16"
            { ...getErrorProps(errors['last_remarks']) } size="small"
          >
            <InputTextArea placeholder={ trans(`${langKey}.last_remarks`) } disabled={ props.disabled } onChange={ (e) => setRemarks(e.target.value) } />
          </Form.Item>
        </Form>
      </Skeleton>


    </ModalComponent>
  )
}

export default UpdateAttendanceApproval

const rules = {
  name: [
    { required: true, message: '' },
  ],

};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}