import { useState, useEffect } from "react";
import {
  HeaderComponent,
  BodyComponent,
  TableComponent,
  ActionComponent,
  CreateButton,RefreshButton,
  trans,
  PaginationComponent
} from "@comps/components";
import { makeRequest } from "@utils/helpers";
import { getAllDesignations, deleteDesignation, getFilterReq } from "./requests";
import { Commons, Const } from "../constants";
import CreateDesignation from './components/CreateDesignation';
import UpdateDesignation from './components/UpdateDesignation';
import { message } from "antd";
import { FilterComponent, FilterButton } from "@comps/components";
// ==================================
//   Page Breadcrumbs Start
// ==================================

const langKey = Const["designation"].lngKey;

const pageConfig = {
  headers: {
    title: trans(`${langKey}.moduleName`),
    breadcrumb: [
      {
        name: trans(`${langKey}.moduleName`),
        path: Const["designation"].route,
      },
    ],
  },
};

// ==================================
//   Page Breadcrumbs End
// ==================================

const IndexDesignations = (props) => {
  const [totalRecords, setTotalRecords] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [loader, setLoader] = useState(false);
  const [childComponent, setChildComponent] = useState(null);
  const [filerComponent, setFilterComponent] = useState(null);
  const [filters, setFilters] = useState();
 //start pagination states
 const [currentPage, setCurrentPage] = useState(1)
 const [pagination, setPagination] = useState({
   "total": 14,
   "count": 10,
   "per_page": 10,
   "current_page": 1,
   "total_pages": 2,
   "links": {}
 });
 //end pagination states

  // ==================================
  //   Use States End
  // ==================================

  // ================================== UpdateLocation
  //   Table Data End
  // ==================================

  const columns = [
    {
      key: trans(Commons.code),
      title: trans(Commons.code),
      dataIndex: "code",
    },
    {
      key: trans(Commons.name),
      title: trans(Commons.name),
      dataIndex: "name",
    },
    {
      key: trans(`${langKey}.company`),
      title: trans(`${langKey}.company`),
      dataIndex: "company_name",
    },
    {
      key: trans(Commons.actions),
      title: trans(Commons.actions),
      render: (record) => (
        <ActionComponent
          each={ record } modkey={props.modkey}
          onDelete={ onDelete }
          onEdit={ onEdit }
          onView={ onView }
        ></ActionComponent>
      ),
    },
  ];

  // ==================================
  //   Table Data End
  // ==================================

  useEffect(() => {
    getAllData();
    // eslint-disable-next-line
  }, [filters, currentPage]);

  const getAllData = () => {
    const payload = {
      page: currentPage,
      filters: filters
    }
    makeRequest(setLoader, getAllDesignations, payload, onSuccess, onSuccess);
  };

  const onSuccess = (response) => {
    setPagination(response.designations);
    setDataSource(response.designations.data);
  };

  const onEdit = (record) => {
    setChildComponent(
      <UpdateDesignation onCompleted={ onCompleted } record={ record } disabled={ false } />
    );
  };

  const onView = (record) => {
    setChildComponent(
      <UpdateDesignation onCompleted={ onCompleted } record={ record } disabled={ true } />
    );
  };

  const onDelete = (record) => {
    makeRequest(setLoader, deleteDesignation, record.id, onDeleted, onError);
  };
  const onDeleted = (res) => {
    onSuccess(res)
    message.success('Designation Deleted')
    setDataSource(res.designations.data);
  };

  const onError = (err, res) => {
    message.error(res.response.data.message);
    //setErrors(errorList);
  }

  // Create component modal
  const onCreate = () => {
    setChildComponent(<CreateDesignation onCompleted={ onCompleted } onSuccess={onSuccess} />);
  };

  const onCompleted = (data) => {
    if (data.length) {
      setDataSource(data);
    }
    setChildComponent(null);
  };

  const handleTableChange = (page, fil, sorter) => {
    let payload = {
      ...pagination,
      current: page.current,
      pageSize: page.pageSize,
      sortName: sorter.field || "id",
      sortType: sorter.order === "ascend" ? "asc" : "desc",
    };
    setPagination(payload);
  };


  //---------------filters-----------------------
  const onOpenFilter = () => {
    setFilterComponent(<FilterComponent filters={ filterFields } modkey={props.modkey} selected={ filters } api={ getFilterReq } setCurrentPage={setCurrentPage} onFilter={ setFilters } onCloseFilter={ onCloseFilter }
    />
    )
  }

  const onCloseFilter = () => {
    setFilterComponent(null);

  }

  //----end filter--------------------

  return (
    <>
      { filerComponent }
      { childComponent }
      <HeaderComponent headers={ pageConfig.headers } modkey={props.modkey}>
        <RefreshButton onClick={ getAllData } /> <CreateButton onClick={ onCreate } />
        <FilterButton onClick={ onOpenFilter } />
      </HeaderComponent>
      <BodyComponent>
        <TableComponent modkey={props.modkey}
          loader={ loader }
          columns={ columns }
          dataSource={ dataSource }
          pagination={ { ...pagination, total: totalRecords } }
          onChange={ handleTableChange }
        />
      </BodyComponent>
      <PaginationComponent pagination={ pagination } modkey={props.modkey} setCurrentPage={ setCurrentPage } currentPage={ currentPage }/>
    </>
  );
};

export default IndexDesignations;

const filterFields = [
  {
    type: "text",
    placeholder: "Code",
    key: "code"
  },
  {
    type: "text",
    placeholder: "Name",
    key: "name"
  },
]
