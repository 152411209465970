

const context = require.context('../locales/en/', true, /.json$/);
let all = {};

context.keys().forEach((key) => {
  const fileName = key.replace('./', '');
  const objectKey = fileName.replace("/", '.').replace(".json", "");
  const resource = require(`../locales/en/${fileName}`);
  Object.keys(resource).forEach((item) => all[`${objectKey}.${item}`] = resource[item]);
});

const EnLang = {
  messages: {
    ...all
  },
  locale: 'en-US'
};
export default EnLang;
