import React, { useEffect, useState } from "react";
import { Form, message, Skeleton, Switch, Row, Col } from "antd";
import { SaveButton, InputCheck, InputText, CancelButton, ModalComponent, trans } from "@comps/components"
import { makeRequest, makeRequestStateless, } from "@utils/helpers";
import { createDataReq, getRelatedDataReq } from "../requests";
import { Commons, Const } from "../../constants";
import { createSelectList } from '@comps/commonFunctions';
import { getEmploymentsDataReq } from "@mods/common/requests";
import ViewPrivilegeFilters from "../../previleges/components/ViewPrivilegeFilters";
import CreatePrivilegeFilters from "../../previleges/components/CreatePrivilegeFilters";
import UpdatePrivilegeFilters from "../../previleges/components/UpdatePrivilegeFilters";

const langKey = Const["ipRestriction"].lngKey
const formName = "createPrivilege";

const CreateIpRestriction = (props) => {
  const [loader, setLoader] = useState(false);
  const [saving, setsaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const [employments, setEmployments] = useState([])
  const [relatedData, setRelatedData] = useState('')
  const [allPriviliges, setAllPrivileges] = useState(false);
  const [childComponent, setChildComponent] = useState(null)
  const [privilegeFilters, setPrivilegeFilters] = useState([])
  const [ipType, setIpType] = useState(false);
  const [isValidIp, setIsValidIp] = useState(false)
  const [ipAddress, setIpAddress] = useState(null)
  const [subnetMask, setSubnetMask] = useState(null)
  const [netInfo, setNetInfo] = useState({})
  const [form] = Form.useForm()
  useEffect(() => {
    getData()
    // eslint-disable-next-line
  }, []);

  const getData = () => {
    getEmployments()
    getRelatedData()
    const initialIp = '192.168.2.38'
    setDefaultValues(initialIp)
  }

  const setDefaultValues = (initialIp) => {

    const subnetInfo = calculateDefaultSubnetInfo(initialIp);

    form.setFieldsValue({
      ip_address: initialIp,
      prefix: 31,
      mask: subnetInfo.subnetMask,
      first: subnetInfo.startRange,
      last: subnetInfo.endRange,
      hosts: subnetInfo.numHosts
    })
  }
  const getRelatedData = () => {
    makeRequestStateless(getRelatedDataReq, null, onGetRelated, false)
  }
  const onGetRelated = (response, data) => {
    setRelatedData(response)
  }
  const getEmployments = () => {
    const payload = { filters: null }
    makeRequestStateless(getEmploymentsDataReq, payload, onGetEmployments, false)
  }
  const onGetEmployments = (response) => {
    setLoading(false)
    setEmployments(createSelectList(response.resources.data))
  }

  const onSubmit = (data) => {

    if (allPriviliges && data.privilege_filters !== undefined && data.privilege_filters.length < 1) {
      message.info('Please Add Criteria!');
      return
    }

    let obj = { name: data.name, prefix: data.prefix, ip_address: data.ip_address, all_company: allPriviliges, is_allowed: true, ip_restriction_filters: data.privilege_filters }
    const payload = { ip_restriction: obj };
    setsaving(true)
    makeRequest(setLoader, createDataReq, payload, onSuccess, onError);
  }

  const onSuccess = (data, res) => {

    message.success(res.message);
    props.onCompleted(data.ip_restrictions.data);
    props.onSuccess(data)
  }

  const onError = (err, res) => {

    setsaving(false)
    message.error(res.response.data.message);
  }
  // ==================================slabs actions =================================
  const onCreate = () => {
    setChildComponent(<CreatePrivilegeFilters onCompleted={ onCompleted } allData={ relatedData } employments={ employments } />)
  }

  //edit slab
  const onEdit = (key) => {
    const record = form.getFieldValue(`privilege_filters`)[key];
    setChildComponent(<UpdatePrivilegeFilters onCompleted={ onCompleted } allData={ relatedData } record={ { ...record, key: key } } disabled={ false } employments={ employments } />)
  };
  const onView = (key) => {
    const record = form.getFieldValue(`privilege_filters`)[key];
    setChildComponent(<UpdatePrivilegeFilters onCompleted={ onCompleted } allData={ relatedData } record={ { ...record, key: key } } disabled={ true } employments={ employments } />)
  };
  //set deleted rows
  const setRows = (key, state) => {

  }
  //on submit slabs
  const onCompleted = (data, action) => {

    let listArray = form.getFieldValue('privilege_filters');
    if (listArray === undefined) {
      listArray = []
    }
    if (data && action === 'Added') {
      listArray.push(data)
      form.setFieldValue('privilege_filters', listArray)
      setPrivilegeFilters(listArray)
    }
    if (data && action === 'Updated') {

      listArray[data.key] = data
      form.setFieldValue('privilege_filters', listArray)
      setPrivilegeFilters(listArray)
    }
    setChildComponent(null)
  }

  const validateIPAddress = (rule, value, callback) => {
    const ipRegex = /^(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)$/;

    if (!value || ipRegex.test(value)) {
      setIsValidIp(true);
      setDefaultValues(value)
      callback();
    } else {
      setIsValidIp(false);
      callback('Please enter a valid IP address!');
    }
  };


  function calculateSubnetInfo(ipAddress, subnetMask) {
    // Convert IP address and subnet mask to binary strings
    const ipBinary = ipAddress.split('.').map(part => parseInt(part, 10).toString(2).padStart(8, '0')).join('');
    const subnetBinary = subnetMask.split('.').map(part => parseInt(part, 10).toString(2).padStart(8, '0')).join('');

    // Calculate network address in binary
    const networkBinary = ipBinary.slice(0, subnetBinary.indexOf('0'));

    // Calculate number of host bits
    const hostBits = subnetBinary.slice(subnetBinary.indexOf('0')).length;

    // Calculate number of hosts
    const numHosts = Math.pow(2, hostBits) - 2; // Subtract 2 for network and broadcast addresses

    // Calculate start and end range
    const startRange = parseInt(networkBinary + '1'.padEnd(hostBits, '0'), 2);
    const endRange = startRange + numHosts;

    // Convert start and end range to IP address format
    const startRangeIP = Array.from({ length: 4 }, (_, i) => (startRange >> (24 - i * 8)) & 255).join('.');
    const endRangeIP = Array.from({ length: 4 }, (_, i) => (endRange >> (24 - i * 8)) & 255).join('.');

    return {
      subnetMask: subnetMask,
      startRange: startRangeIP,
      endRange: endRangeIP,
      numHosts: numHosts
    };
  }

  function calculateDefaultSubnetInfo(ipAddress) {
    // Split the IP address into octets
    const octets = ipAddress.split('.');

    // Check the class of the IP address to determine the default subnet mask
    let subnetMask;
    if (octets[0] >= 1 && octets[0] <= 126) {
      subnetMask = '255.0.0.0'; // Class A
    } else if (octets[0] >= 128 && octets[0] <= 191) {
      subnetMask = '255.255.0.0'; // Class B
    } else {
      subnetMask = '255.255.255.0'; // Class C (and others)
    }

    // Calculate subnet information using the previous function
    return calculateSubnetInfo(ipAddress, subnetMask);
  }

  //-----------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [
    <SaveButton loading={ saving } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } width={ 1000 } maskClosable={ false }
      mainTitle={ trans(Commons.create) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) } >
      { childComponent }

      <Skeleton loading={ loading }>
        <Form { ...formLayout } name={ formName } onFinish={ onSubmit } form={ form }>
          <Row gutter={ 30 } scrollToFirstError>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="name" rules={ rules.name } label={ trans(Commons.name) }
                className="da-mb-16"
              >
                <InputText placeholder={ trans(`${langKey}.name`) }
                />
              </Form.Item>
            </Col>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="all_company" rules={ rules.all_company } label={ trans(`${langKey}.all_company`) }
                className="da-mb-16"
                valuePropName="checked"
              >
                <InputCheck onChange={ (e) => setAllPrivileges(e.target.checked) } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="ip_type" rules={ rules.ip_type } label={ trans(`${langKey}.ip_type`) }
                valuePropName="checked"
              >
                <label>{ trans(`${langKey}.net_mask`) }</label>
                <Switch style={ { margin: 10 } } onChange={ (value) => setIpType(value) } />
                <label>{ trans(`${langKey}.range`) }</label>
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="ip_address" label={ trans(`${langKey}.ip_address`) }
                className="da-mb-16"
                rules={ [
                  { required: true, message: 'Please enter an IP address!' },
                  { validator: validateIPAddress },
                ] }
              >
                <InputText placeholder="___.___.___.___"
                />
              </Form.Item>
            </Col>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="prefix" rules={ rules.prefix } label={ trans(`${langKey}.prefix`) }
                className="da-mb-16"
              >
                <InputText type='number' min={ 23 } max={ 31 } disabled={ ipType } />
              </Form.Item>
            </Col>

            {
              !ipType &&
              <>
                <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                  <Form.Item name="mask" rules={ rules.mask } label={ trans(`${langKey}.mask`) }
                    className="da-mb-16"
                  >
                    <InputText placeholder="___.___.___.___" disabled
                    />
                  </Form.Item>
                </Col>
                <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                  <Form.Item name="first" rules={ rules.first } label={ trans(`${langKey}.first`) }
                    className="da-mb-16"
                  >
                    <InputText placeholder="___.___.___.___" disabled
                    />
                  </Form.Item>
                </Col>
                <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                  <Form.Item name="last" rules={ rules.last } label={ trans(`${langKey}.last`) }
                    className="da-mb-16"
                  >
                    <InputText placeholder="___.___.___.___" disabled
                    />
                  </Form.Item>
                </Col>
                <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                  <Form.Item name="hosts" rules={ rules.hosts } label={ trans(`${langKey}.hosts`) }
                    className="da-mb-16"
                  >
                    <InputText type='number' disabled
                    />
                  </Form.Item>
                </Col>
              </>
            }
          </Row>

          {
            !allPriviliges &&
            <>
              <div className="formlist-container">
                <h5 className="formlist-title">{ trans(`${langKey}.privilege_filters`) }</h5>
                <ViewPrivilegeFilters setRows={ setRows } onCreate={ onCreate } onEdit={ onEdit } onView={ onView } />
              </div>
            </>
          }


        </Form>
      </Skeleton>

    </ModalComponent>
  )
}

export default CreateIpRestriction


const rules = {
  name: [
    { required: true, message: trans(Commons.name) },
  ],

  prefix: [
    { required: true, message: trans(`${langKey}.prefix`) }
  ],

};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}
