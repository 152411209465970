import React, { useEffect, useState } from "react";
import { Form, Skeleton, message, Row, Col } from "antd";
import { SaveButton, InputText, CancelButton, ModalComponent, trans, SelectResourceList, InputDate } from "@comps/components"
import { makeRequest, makeRequestStateless, getErrorProps } from "@utils/helpers";
import { updateBankReq, getAllEmployeesReq } from "../requests";
import { Commons, Const } from "../../constants";
import dayjs from 'dayjs';
const langKey = Const["bank"].lngKey

const formName = "createBank";

const UpdateBank = (props) => {

  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [saving, setsaving] = useState(false);
  const [loading, setloading] = useState(true);
  const [form] = Form.useForm()
  useEffect(() => {
    getAndSetRecord();
    makeRequestStateless(getAllEmployeesReq, false, ongetAllEmployees, false)
    // eslint-disable-next-line
  }, []);

  const getAndSetRecord = () => {
    props.record.bank_effective_date = props.record.bank_effective_date ? dayjs(props.record.bank_effective_date) : null;
    form.setFieldsValue(props.record)
  }
  const ongetAllEmployees = (response) => {
    setloading(false)
  }


  const onSubmit = (data) => {
    setsaving(true)
    const payload = { employee_bank: data, filters: props.filters }
    payload.employee_bank.id = props.record.id;
    makeRequest(setLoader, updateBankReq, payload, onSuccess, onError);
  }

  const onSuccess = (data, res) => {
    message.success("Bank updated successfully");
    props.onCompleted(data.employee_banks.data);
  }

  const onError = (err, res) => {
    setsaving(false)
    message.error(res.response.data.message);
    //setErrors(errorList);
  }
  //-----------on changes------------

  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [
    !props.disabled &&
    <SaveButton loading={ saving } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } width={ 1000 } maskClosable={ false }
      mainTitle={ trans(Commons[props.disabled ? 'view' : 'update']) } subTitle={ trans(`${langKey}.bank`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Skeleton loading={ loading }>
        <Form { ...formLayout } form={ form } name={ formName } onFinish={ onSubmit } disabled={ props.disabled } scrollToFirstError>
          <Row gutter={ 30 }>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="employee_id" label={ trans(`${langKey}.employee`) } rules={ rules.employee_id } className="da-mb-16"
                { ...getErrorProps(errors['employee_id']) }
              >
                <SelectResourceList type='employee' value={ props.record.employee_id } disabled={ props.filters !== undefined } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="bank_name" rules={ rules.bank_name } label={ trans(`${langKey}.bank_name`) }
                className="da-mb-16"
                { ...getErrorProps(errors['bank_name']) } size="small"
              >
                <InputText placeholder={ trans(`${langKey}.bank_name`) } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="bank_branch" rules={ rules.bank_branch } label={ trans(`${langKey}.bank_branch`) }
                className="da-mb-16"
                { ...getErrorProps(errors['bank_branch']) } size="small"
              >
                <InputText placeholder={ trans(`${langKey}.bank_branch`) } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="bank_branch_code" rules={ rules.bank_branch_code } label={ trans(`${langKey}.bank_branch_code`) }
                className="da-mb-16"
                { ...getErrorProps(errors['bank_branch_code']) } size="small"
              >
                <InputText type="number" placeholder={ trans(`${langKey}.bank_branch_code`) } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="bank_account_title" rules={ rules.bank_account_title } label={ trans(`${langKey}.bank_account_title`) }
                className="da-mb-16"
                { ...getErrorProps(errors['bank_account_title']) } size="small"
              >
                <InputText placeholder={ trans(`${langKey}.bank_account_title`) } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="bank_account_no" rules={ rules.bank_account_no } label={ trans(`${langKey}.bank_account_no`) }
                className="da-mb-16"
                { ...getErrorProps(errors['bank_account_no']) } size="small"
              >
                <InputText placeholder={ trans(`${langKey}.bank_account_no`) } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="bank_effective_date" rules={ rules.bank_effective_date } label={ trans(`${langKey}.bank_effective_date`) }
                className="da-mb-16"
                { ...getErrorProps(errors['bank_effective_date']) } size="small"
              >
                <InputDate format='YYYY-MM-DD' />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Skeleton>

    </ModalComponent>
  )
}

export default UpdateBank

const rules = {
  bank_name: [
    { required: true, message: trans(`${langKey}.bank_name.req`) },
  ],
  employee_id: [
    { required: true, message: trans(`${langKey}.employee_id.req`) },
  ],
  bank_branch: [
    { required: true, message: trans(`${langKey}.bank_branch.req`) },
  ],
  bank_branch_code: [
    { required: true, message: trans(`${langKey}.bank_branch_code.req`) },
  ],
  bank_account_title: [
    { required: true, message: trans(`${langKey}.bank_account_title.req`) },
  ],
  bank_account_no: [
    { required: true, message: trans(`${langKey}.bank_account_no.req`) }, { max: 64, min: 4 }
  ],
  bank_effective_date: [
    { required: true, message: trans(`${langKey}.bank_effective_date.req`) },
  ],
};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}