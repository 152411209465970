import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Spin } from 'antd';
import BirthdayImage from '@assets/icons/birthday-cake.png';
import { makeRequest } from '@utils/helpers';
import { getBirthdaysReq } from '../requests';
import "../dashboard.css"
import moment from 'moment/moment';
import useInViewport from '../useInViewport';

const Birthdays = () => {
  const [loader, setLoader] = useState(false);
  const [birthdays, setBirthdays] = useState([]);

  const viewRef = useRef(null);
  const viewport = useInViewport(viewRef);
  useEffect(() => {
    if (viewport && birthdays.length === 0) {
      makeRequest(setLoader, getBirthdaysReq, null, onSuccess, onError)
    }
  }, [viewport]);

  const onSuccess = (data) => {
    setBirthdays(data.upcoming_birthdays);
  }

  const onError = (error) => {
  }

  return (
    <div className='widgetbox' ref={viewRef}>
      <Row >
        <Col span={ 24 } >
          <h3><img src={ BirthdayImage } width={ 25 } alt='birthday cake' /><span>Birthday</span></h3>
        </Col>
      </Row>
      <div className='birthdaycontent'>
        <div className='birthdaymain'>
          <Spin spinning={ loader } tip="Loading...">
            { birthdays && birthdays.map((birthday, index) => (
              <Row className='birthdaysingle' key={ index }>
                <Col span={ 12 }>
                  <div className='birthdayimg'>
                    <img src={ birthday.profile_pic_url } alt='birthday' />
                  </div>
                  <div className='birthdaydetails'>
                    <h4 className='birthdayname'>{ birthday.full_name }</h4>
                    <p className='birthdaycode'>{ birthday.designation }</p>
                  </div>
                </Col>
                <Col span={ 12 } style={ { textAlign: 'right' } }>
                  <h4 className='birthdaydate'>{ moment(birthday.d_o_b).format('MMMM DD') }</h4>
                </Col>
              </Row>
            )) }
          </Spin>
        </div>
      </div>
    </div>
  );
}

export default Birthdays;