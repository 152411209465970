import { get, del, post, put } from "@utils/axios";

const api = "restDays";

export const getAllDataReq = (payload) => {
  return get(`${api}?page=${payload.page}&filters=${payload.filters}`)
};

export const createDataReq = (payload) => {
  return post(`${api}?`, payload);
};

export const updateDataReq = (payload) => {
  return put(`${api}/${payload.id}`, payload);
};

export const deleteDataReq = (id) => {
  return del(`${api}/${id}`);
};
export const getRelatedDataReq = (id) => {
  return get(`employment-picker/get-company-related-data/1`);
};
export const getEmploymentsDataReq = (payload) => {
  return get(`resources/employment?page=1&filters=privilege_|false;`);
};
export const getPreviewDataReq = (payload) => {
  return post(`${api}/preview`, payload);
};
export const filterEmploymentsReq = (payload) => {
  return get(`resources/employment?page=1&filters=${payload}`);
};

export const getFilterReq = (payload) => {
  return get(`filters?company_id=1&fields=page=1&filters=supporting_fields_|employees;`)
}

