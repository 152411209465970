import { get, del, post, put, download } from "@utils/axios";

const api = "reports";
export const getAllDataReq = (payload) => {
  return get(`${api}?page=${payload.page}&filters=${payload.filters}`)
};

//create report
export const createDataReq = (payload) => {
  return post(`${api}`, payload);
};

export const updateDataReq = (payload) => {
  return put(`${api}/${payload.id}`, payload);
};
export const cancelRequestReq = (payload) => {
  return post('cancelRequest', payload);
};

export const deleteDataReq = (payload) => {
  return del(`${api}/${payload.id}?page=1&filters=${payload.filters}`);
};

export const getTenantsReq = (payload) => {
  return get(`${api}/get_tenants`);
};
export const cloneReportInSaasReq = (payload) => {
  return post(`${api}/clone_report`,payload);
};

export const getRosterReq = (payload) => {
  return post(`${api}/getRoster`, payload);
};
export const getExpectedTimeReq = (payload) => {
  return get(`${api}/getExpectedTime?&attendance_date=${payload.date}&employment_id=${payload.employment_id}`);
};


export const getInitialDataReq = (payload) => {
  return post(`${api}/get_report_initial_data`, payload);
};

export const getFilterReq = (payload) => {
  return get(`filters?company_id=1&fields=page=${payload.page}&filters=${payload.filters}`)
}
export const downloadReportReq = (payload) => {
  return download(`${api}/download?page=${payload.page}&filters=${payload.filters}&employment_id=${payload.employment_id}`)
}

//creating report
export const getReportTypesAndTables = (payload) => {
  return get(`${api}/create`);
}

export const getTableInfoByIdReq = (id) => {
  return get(`schema_tables/${id}`)
}
//run query
export const getPreviewQueryReq = (payload) => {
  return post(`${api}/preview_query`, payload)
}
//load related tables
// id: 12
// selected_tables[]: 791
// alias: employments
// related_table: 791
export const loadRelatedTablesReq = (payload) => {
  return post(`schema_tables/load_related_table`, payload)
}


//generate report with prefilterd
// payload =
//   id: 39
// page: 1
// filters[sorting]:
// pagination_options[pageNumber]: 1
// pagination_options[pageSize]: 50
// pagination_options[sort]:
// print_call: false
export const generateReportReq = (payload) => {
  return post(`${api}/generate_report`, payload)
}

//generate custom report 

//get inital report data
export const getReportInitialDataReq = (reportId) => {
  return post(`${api}/get_report_initial_data`, reportId)
}

// call when apply filter from dialog box

export const generateCustomReportReq = (payload) => {
  return post(`${api}/generate_report`, payload)
}
export const printCustomReportReq = (payload) => {
  return post(`${api}/print_report`, payload)
}

export const getReportsFromJobsReq = (id) => {
  return get(`get-report-jobs`);
};
export const downloadReportFromJobsReq = (id) => {
  return download(`download-report-job/${id}`);
};



