import React, { useEffect, useState } from "react";
import { Form, message } from "antd";
import { SaveButton, CancelButton, ModalComponent, trans, SelectWithSearch } from "@comps/components"
import { makeRequest, makeRequestStateless } from "@utils/helpers";
import { Commons, Const } from "../constants";
import TextArea from "antd/es/input/TextArea";
import { createConsentReq, getEmploymentExcludeId } from "./requests";
import { createSelectList } from '@comps/commonFunctions';

const langKey = Const["leaveApproval"].lngKey
const formName = "createConsentForm";

const CreateConsent = (props) => {
  const [loader, setLoader] = useState(false);
  const [employments, setEmployments] = useState([])
  const [saveLoader, setSaveLoader] = useState(false)
  useEffect(() => {
    getEmployments()
  }, []);

  const getEmployments = () => {
    let id = props.employment_id;
    makeRequestStateless(getEmploymentExcludeId, id, onGetEmployments, false)
  }
  const onGetEmployments = (response) => {
    setEmployments(createSelectList(response.resources.data))
  }
  const onSubmit = (data) => {
    const payload = { consent: { employment_id: data.employment_id, message: data.message, request_id: props.request_id, request_as: props.request_as }, api: props.api }
    makeRequest(setLoader, createConsentReq, payload, onSuccess, onError);
    setSaveLoader(true)
  }
  const onSuccess = (data) => {
    setSaveLoader(false)
    props.onCreatedConsent(data)
  }
  const onError = (err, res) => {
    message.error(res.response.data.message);
    setSaveLoader(false)
  }


  const footer = [
    <SaveButton type="primary" htmlType="submit" loading={ saveLoader } form={ formName } key="create_button" />,
    <CancelButton key="back" onClick={ () => props.onCreatedConsent(null) } />
  ]

  return (
    <>
      <ModalComponent mainTitle={ trans(Commons.create) } subTitle={ "Consents" } open={ true } footer={ footer } onCancel={ () => props.onCreatedConsent(null) }>
        <Form { ...formLayout } onFinish={ onSubmit } name={ formName } scrollToFirstError>
          <Form.Item name="employment_id" rules={ rules.employment_id } label={ trans(`${langKey}.employment_id`) }
            className="da-mb-16"
          >
            <SelectWithSearch
              placeholder={ trans(`${langKey}.employment_id`) }
              options={ employments }
            />
          </Form.Item>

          <Form.Item name="message" rules={ rules.message } label={ trans(`${langKey}.consent_message`) }
            className="da-mb-16"
            size="small"
          >
            <TextArea
              placeholder={ 'Type Message' }
            />
          </Form.Item>

        </Form>
      </ModalComponent>

      {/* <Modal top={ 20 } width={ 1200 } maskClosable={ false }
        mainTitle={ trans(Commons.create) } footer='' onCancel={ () => setOpenConsentChat(false) } subTitle={ trans(`${langKey}.moduleName`) }
        open={ openConsentChat }>
        <Form name="consent" onFinish={ onSubmitConsentChat }>
          <TableComponent
            columns={ consentChatColumns }
            dataSource={ consentMesssages }
          />
          <Form.Item name="message" rules={ rules.consent_message } label={ trans(`${langKey}.consent_message`) }
            className="da-mb-16"
            { ...getErrorProps(errors['consent_message']) } size="small"
          >
            <TextArea
              placeholder={ 'Type Message' }
            />
          </Form.Item>
          <Space>
            <SaveButton htmlType='submit' />
            <CancelButton onClick={ () => setOpenConsentChat(false) } />
          </Space>
        </Form>
      </Modal> */}
    </>
  )

}

export default CreateConsent

const rules = {
  employment_id: [
    { required: true, message: trans(`${langKey}.employment_id`) },
  ],
  message: [
    { required: true, message: trans(`${langKey}.consent_message`) },
  ],

};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}
