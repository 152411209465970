import React, { useEffect, useState } from "react";
import { Form, message } from "antd";
import { SaveButton, InputCheck, CancelButton, ModalComponent, trans } from "@comps/components"
import { makeRequest } from "@utils/helpers";
import { importMachineAttendanceReq } from "../requests";
import { Const } from "../../constants";


const langKey = Const["attendanceLogs"].lngKey
const formName = "createAttendanceRequest";
const ImportMachineAttendance = (props) => {
  const [loader, setLoader] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [form] = Form.useForm()
  useEffect(() => {
    setRelatedData()
    // eslint-disable-next-line
  }, []);

  const setRelatedData = () => {

  }

  const onSubmit = (data) => {

    const ids = []

    Object.keys(data).forEach((each, key) => {
      if (data[each] !== undefined && data[each]) {
        ids.push(parseInt(each))
      }
    })

    if (ids.length === 0) {
      message.info('Please Select At least One Machine!');
      return
    }
    const payload = { ids: ids }
    makeRequest(setLoader, importMachineAttendanceReq, payload, onSuccess, onError);
    //setSaveLoader(true)
  }
  const onSuccess = (data, res) => {

    setSaveLoader(false)
    message.success(res.message);
    props.getAllData()
    props.onCompleted(true)
  }

  const onError = (err, res) => {

    setSaveLoader(false)
    message.error(res.response.data.message);
  }
  //-----------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------
  const footer = [
    <SaveButton loading={ saveLoader } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];
  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------
  return (
    <ModalComponent top={ 20 } width={ 1000 } maskClosable={ false }
      mainTitle={ trans(`${langKey}.import`) } subTitle={ trans(`${langKey}.machineAttandance`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>

      <Form { ...formLayout } form={ form } name={ formName } onFinish={ onSubmit } scrollToFirstError>

        <table className="table-configurations table table-bordered table-striped table-condensed">
          <thead>
            <tr>
              <th>Machine</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {
              props.machines !== undefined && props.machines && props.machines.map((each, key) => (
                <tr key={ each.id }>
                  <td>{ each.name }</td>
                  <td>
                    <Form.Item name={ each.id }
                      className="da-mb-16"
                      valuePropName="checked"
                    >
                      <InputCheck
                        className="machine-checkbox"
                      />
                    </Form.Item>
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </Form>

    </ModalComponent>
  )
}

export default ImportMachineAttendance

const rules = {
  punch_date: [
    { required: true, message: trans(`${langKey}.punch_date`) },
  ]
};

const formLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14, offset: 2 },
  labelAlign: "left"
}

