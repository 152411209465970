import React, { useEffect, useState } from "react";
import { Button, Checkbox, Form, message, Space, Row, Col } from "antd";
import { SaveButton, InputText, InputTextArea, CancelButton, ModalComponent, trans, InputDate, SelectResourceList } from "@comps/components"
import { makeRequest, makeRequestStateless, getErrorProps } from "@utils/helpers";
import { createDataReq } from "../requests";
import { Commons, Const } from "../../../constants";
import { getAllEmploymentsReq } from "@mods/common/requests";
import CreateDetails from "./CreateDetails";
import { DeleteOutlined, PlusOutlined, EditOutlined } from "@ant-design/icons";
import UpdateDetails from "./UpdateDetails";
const langKey = Const["companyTravel"].lngKey
const formName = "createTravelRequest";
const CreateCompanyTravelReq = (props) => {
  const [loader, setLoader] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [isAdvance, setIsAdvance] = useState(false)
  const [childComponent, setChildComponent] = useState(null);
  const [dataSource, setDataSource] = useState([])

  const [form] = Form.useForm()

  useEffect(() => {
    getEmployments()
    // eslint-disable-next-line
  }, []);

  const getEmployments = () => {
    const payload = { filters: null }
    makeRequestStateless(getAllEmploymentsReq, payload, onGetEmployments, false)
  }
  const onGetEmployments = (response) => {

  }

  const onSubmit = (data) => {

    if (props.module === "myTravel") {
      data.employment_id = props.authUser.employment_id
    }
    const details = data.details;
    let newDetails = []
    if (details === undefined || details.length < 1) {
      message.info("Please add details");
      return
    }

    details.forEach((each, key) => {
      each.departure_date = each.departure_date.format('YYYY-MM-DD');
      each.arrival_date = each.arrival_date.format('YYYY-MM-DD');
      each.arrival_time = each.arrival_time.second(0);
      each.departure_time = each.departure_time.second(0);

      if (each.hotel_bookings !== undefined) {
        each.hotel_bookings.forEach((each, key) => {
          each.book_from = each.book_from.format("YYYY-MM-DD");
          each.book_to = each.book_to.format("YYYY-MM-DD");
          if (each.id === undefined) {
            each.row_state = 'Added'
          }
          if (each.id !== undefined) {
            each.row_state = 'Updated'
          }
        })
      }

      if (each.ticket_bookings !== undefined) {
        each.ticket_bookings.forEach((each, key) => {
          each.boarding_date = each.boarding_date.format("YYYY-MM-DD");
          each.boarding_time = each.boarding_time.format("YYYY-MM-DD HH:mm:ss");
          if (each.id === undefined) {
            each.row_state = 'Added'
          }
          if (each.id !== undefined) {
            each.row_state = 'Updated'
          }
        })
      }


      newDetails.push(each)
    })
    const payload = { travel: { ...data, details: newDetails }, filters: props.filters };
    setSaveLoader(true)
    makeRequest(setLoader, createDataReq, payload, onSuccess, onError);
  }

  const onSuccess = (data, res) => {
    setSaveLoader(false)
    message.success("Saved successfully");
    props.onCompleted(data.travels.data);
    props.onSuccess(data)
  }

  const onError = (err, res) => {
    setSaveLoader(false)
    message.error(res.response.data.message);
  }

  //create details
  const onCreateDetails = () => {
    const requiredData = { travel_types: props.travel_types, travel_modes: props.travel_modes, stay_types: props.stay_types }
    setChildComponent(<CreateDetails onCompleted={ onCompleted } disabled={ false } requiredData={ requiredData } dataSource={ dataSource } />)
  }

  const onEditDetails = (key) => {
    const record = form.getFieldValue(`details`)[key];
    const requiredData = { travel_types: props.travel_types, travel_modes: props.travel_modes, stay_types: props.stay_types }
    setChildComponent(<UpdateDetails onCompleted={ onCompleted } disabled={ props.disabled } requiredData={ requiredData } dataSource={ dataSource } record={ { ...record, key: key } } />)
  }

  const onCompleted = (data, action) => {
    let listArray = form.getFieldValue('details');
    if (listArray === undefined) {
      listArray = []
    }
    if (data && action === 'Added') {
      listArray.push(data)
      form.setFieldValue('details', listArray)
    }
    if (data && action === 'Updated') {
      listArray[data.key] = data
      form.setFieldValue('details', listArray)
    }
    setChildComponent(null)
  }
  //---------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------
  const footer = [
    <SaveButton loading={ saveLoader } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];
  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------
  return (
    <ModalComponent top={ 20 } width={ 1000 } maskClosable={ false }
      mainTitle={ trans(Commons.create) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Form { ...formLayout } form={ form } name={ formName } onFinish={ onSubmit } scrollToFirstError>
        <Row gutter={ 30 }>
          {
            props.module !== "myTravel" ?
              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="employment_id" rules={ rules.employment_id } label={ trans(`${langKey}.employment_id`) }
                  className="da-mb-16"
                  { ...getErrorProps(errors['name']) } size="small"
                >
                  <SelectResourceList type='employment' />
                </Form.Item>
              </Col> : ''
          }

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="purpose" rules={ rules.purpose } label={ trans(`${langKey}.purpose`) }
              className="da-mb-16"
              { ...getErrorProps(errors['purpose']) } size="small"
            >
              <InputTextArea placeholder={ trans(`${langKey}.purpose`) } />
            </Form.Item>
          </Col>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="extension_no" rules={ rules.extension_no } label={ trans(`${langKey}.extension_no`) }
              className="da-mb-16"
              { ...getErrorProps(errors['extension_no']) } size="small"
            >
              <InputText type="number" placeholder={ trans(`${langKey}.extension_no`) } />
            </Form.Item>
          </Col>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="alternative_phone_no" rules={ rules.alternative_phone_no } label={ trans(`${langKey}.alternative_phone_no`) }
              className="da-mb-16"
              { ...getErrorProps(errors['alternative_phone_no']) } size="small"
            >
              <InputText type="number" placeholder={ trans(`${langKey}.alternative_phone_no`) } />
            </Form.Item>
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="description" rules={ rules.description } label={ trans(`${langKey}.description`) }
              className="da-mb-16"
              { ...getErrorProps(errors['description']) } size="small"
            >
              <InputTextArea placeholder={ trans(`${langKey}.description`) } />
            </Form.Item>
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="is_advance_required" rules={ rules.is_advance_required } label={ trans(`${langKey}.is_advance_required`) }
              className="da-mb-16"
              { ...getErrorProps(errors['is_advance_required']) } size="small"
              valuePropName="checked"
            >
              <Checkbox onChange={ (e) => setIsAdvance(e.target.checked) } />
            </Form.Item>
          </Col>
        </Row>

        { isAdvance ?
          <>

            <div className="formlist-container">
              <h5 className="formlist-title">{ trans(`${langKey}.advances`) }</h5>
              <Form.List name="advances">
                { (fields, { add, remove }) => (
                  <>
                    <Button onClick={ () => add() } icon={ <PlusOutlined /> } type="primary" className="add-node-button">Add </Button>
                    <div className="table-container">
                      <table className="table">
                        {
                          fields.length !== 0 && <thead>
                            <tr>
                              <th style={ { paddingLeft: 20 } }>{ trans(`${langKey}.amount`) }</th>
                              <th>{ trans(Commons.actions) }</th>
                            </tr>
                          </thead>
                        }
                        <tbody>
                          { fields.map(({ key, name, ...restField }) => (

                            <tr>
                              <td style={ { minWidth: '200px', paddingLeft: 20 } }>
                                <Form.Item
                                  { ...restField }
                                  name={ [name, 'amount'] }
                                  rules={ rules.amount }
                                >
                                  <InputText placeholder={ trans(`${langKey}.name`) } type="number" />
                                </Form.Item>
                              </td>

                              <td>
                                <DeleteOutlined className="formlist-remove" onClick={ () => remove(name) } />
                              </td>
                            </tr>
                          )) }
                        </tbody>
                      </table>
                    </div>

                  </>
                ) }
              </Form.List>
            </div>
          </>
          : '' }



        { childComponent }

        <div className="formlist-container">
          <h5 className="formlist-title">{ trans(`${langKey}.details`) }</h5>

          <Form.List name="details">
            { (fields, { add, remove }) => (
              <>
                <Button onClick={ () => {
                  onCreateDetails()
                  //setRows(fields.length, 'Added')
                } } icon={ <PlusOutlined /> } type="primary" className="add-node-button">Add </Button>
                <div className="table-container">
                  <table className="table">
                    {
                      fields.length !== 0 && <thead>
                        <tr>
                          <th style={ { paddingLeft: 20 } }>{ trans(`${langKey}.from`) }</th>
                          <th>{ trans(`${langKey}.to`) }</th>
                          <th>{ trans(`${langKey}.departure_date`) }</th>
                          <th>{ trans(`${langKey}.arrival_date`) }</th>
                          <th>{ trans(Commons.actions) }</th>
                        </tr>
                      </thead>
                    }
                    <tbody>
                      { fields.map(({ key, name, ...restField }) => (
                        <>
                          <tr>
                            <td style={ { minWidth: '200px', paddingLeft: 20 } }>
                              <Form.Item
                                { ...restField }
                                name={ [name, 'from'] }
                                rules={ rules.from }
                              >
                                <InputText placeholder={ trans(`${langKey}.from`) } />
                              </Form.Item>
                            </td>
                            <td style={ { minWidth: '200px' } }>
                              <Form.Item
                                { ...restField }
                                name={ [name, 'to'] }
                                rules={ rules.to }
                              >
                                <InputText />
                              </Form.Item>
                            </td>

                            <td style={ { minWidth: '230px' } }>
                              <Form.Item
                                { ...restField }
                                name={ [name, 'departure_date'] }
                                rules={ rules.departure_date }
                              >
                                <InputDate placeholder={ trans(`${langKey}.departure_date`) } />
                              </Form.Item>
                            </td>
                            <td style={ { minWidth: '230px' } }>
                              <Form.Item
                                { ...restField }
                                name={ [name, 'arrival_date'] }
                                rules={ rules.arrival_date }
                              >
                                <InputDate placeholder={ trans(`${langKey}.arrival_date`) } />
                              </Form.Item>
                            </td>

                            <td>
                              <Space className="formlist-remove">
                                <DeleteOutlined onClick={ () => {
                                  remove(name)
                                  //setRows(key, 'Deleted')
                                } } />
                                <EditOutlined onClick={ () => {
                                  onEditDetails(key)
                                } } />
                              </Space>
                            </td>
                          </tr>
                        </>
                      )) }
                    </tbody>
                  </table>
                </div>
              </>
            ) }
          </Form.List>
        </div>

      </Form>


    </ModalComponent>
  )
}

export default CreateCompanyTravelReq

const rules = {
  date: [
    { required: true, message: trans(`${langKey}.date`) },
  ],
  start_time: [
    { required: true, message: trans(`${langKey}.start_time`) },
  ],
  end_time: [
    { required: true, message: trans(`${langKey}.end_time`) },
  ],
  purpose: [
    { required: true, message: trans(`${langKey}.purpose`) },
  ],
  employment_id: [
    { required: true, message: trans(`${langKey}.employment_id`) },
  ],
};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}