import { useState, useEffect } from "react";
import {
  BodyComponent,
  TableComponent,
  ActionComponent,
  CreateButton,RefreshButton,
  trans,
  HeaderComponent
} from "@comps/components";
import { makeRequest } from "@utils/helpers";
import { Commons, Const } from "../../constants";
import { message ,Button,Tooltip} from 'antd';

import { deleteDataReq, getAllDataReq, updateDataReq } from "./requests";
import CreateGratuity from "./components/CreateGratuity";
import UpdateGratuity from "./components/UpdateGratuity";
import { CheckOutlined } from '@ant-design/icons';
import { StopOutlined } from '@ant-design/icons';

const langKey = Const["gratuity"].lngKey;

const pageConfig = {
  headers: {
    title: trans(`${langKey}.moduleName`),
    breadcrumb: [
      {
        name: trans(`${langKey}.moduleName`),
        path: Const["gratuity"].route
      }
    ]
  }
}
//========================================
const IndexGratuity = (props) => {

  const [dataSource, setDataSource] = useState([]);
  const [loader, setLoader] = useState(false);
  const [childComponent, setChildComponent] = useState(null);
  const [allData,setAllData]=useState()
  // ==================================
  //   Use States End
  // ==================================

  // ================================== UpdateLocation
  //   Table Data End
  // ==================================

  const columns = [
    
    // {
    //   key: 'month_of_calculation',
    //   title: trans(`${langKey}.month_of_calculation`),
    //   dataIndex: "calc_month_after_doj",
    // },
    {
      key: 'name',
      title: trans(`${langKey}.name`),
      dataIndex: "name",
    },
    {
      key: 'start_date',
      title: trans(`${langKey}.start_date`),
      dataIndex: "start_date",
    },
    {
      key: 'month_of_reimbursement',
      title: trans(`${langKey}.month_of_reimbursement`),
      dataIndex: "disburse_month_after_doj",
    },
  
    // {
    //   key: 'formula_id',
    //   title: trans(`${langKey}.formula_id`),
    //   render: (record)=>(
    //     record.formula && record.formula.tag
    //   ),
    // },
    {
      key: 'type',
      title: trans(`${langKey}.type`),
      render: (record)=>(
        record.type.replace('_',' ')
      ),
    },
    // {
    //   key: 'fixed_amount',
    //   title: trans(`${langKey}.fixed_amount`),
    //   render: (record)=>(
    //     record.fixed_amount
    //   ),
    // },
    {
      key: 'is_active',
      title: trans(`${langKey}.is_active`),
      render: (record)=>(
        record.is_active ? 'Yes': 'No'
      ),
    },

    {
      key: trans(Commons.actions),
      title: trans(Commons.actions),
      render: (record) => (
        <ActionComponent
          each={ record } modkey={props.modkey}
          onDelete={ onDelete }
          //onEdit={ onEdit }
          onView={ onView }
        >
           <Tooltip title={ record.is_active ? 'Deactivate' : 'Activate' } color={ record.is_active ? 'red' : 'green' }>
            <Button type="link" size="middle" onClick={ () => onChangeStatus(record) }>
              { record.is_active ?
                <StopOutlined className="icon-style da-text-color-info-1" /> :
                <CheckOutlined className="icon-style da-text-color-info-1" />
              }
            </Button>

          </Tooltip>
        </ActionComponent>
      ),
    },
  ];

  // ==================================
  //   Table Data End
  // ==================================

  useEffect(() => {
    getAllData();
    // eslint-disable-next-line
  }, []);

  const getAllData = () => {
    const payload = {
      page: 1,
      filters: ''
    }
    makeRequest(setLoader, getAllDataReq, payload, onSuccess, null);
  };

  const onSuccess = (response) => {

setAllData(response);
    setDataSource(response.gratuities);

  };

  const onEdit = (record) => {
    setChildComponent(
      <UpdateGratuity onCompleted={ onCompleted } record={ record } disabled={ false } allData={allData}/>
    );
  };
  const onChangeStatus = (record) => {
    const payload = {
      id:record.id,
      gratuity: {...record,is_active:record.is_active?false:true}
    };
    makeRequest(setLoader, updateDataReq, payload, getAllData, onError);
  };

  const onView = (record) => {
    setChildComponent(
      <UpdateGratuity onCompleted={ onCompleted } record={ record } disabled={ true } allData={allData}/>
    );
  };

  const onDelete = (record) => {
    makeRequest(setLoader, deleteDataReq, record.id, onDeleted, onError);
  };
  const onDeleted = (res) => {
    getAllData()
    message.success("Record Deleted")
  };

  const onError = (err, res) => {
    message.error(res.response.data.message);
    //setErrors(errorList);
  }

  // Create component modal
  const onCreate = () => {
    setChildComponent(<CreateGratuity onCompleted={ onCompleted } onSuccess={ onSuccess } allData={allData} />);
  };

  const onCompleted = (data) => {
    if(data){
      getAllData()
    }
    setChildComponent(null);
  };


  return (
    <>

      { childComponent }
      <BodyComponent>
        <HeaderComponent headers={ pageConfig.headers } modkey={props.modkey}>
          <RefreshButton onClick={ getAllData } /> <CreateButton onClick={ onCreate } />
        </HeaderComponent>
        <TableComponent modkey={props.modkey}
          loader={ loader }
          columns={ columns }
          dataSource={ dataSource }
        />
      </BodyComponent>

    </>
  );
};

export default IndexGratuity;
const filterFields = [
  {
    type: "text",
    placeholder: "Name",
    key: "name"
  },
]

