const orgKey = "systemMaster";
const baseRoute = "/masters/system";

export const Const = {
  users: {
    lngKey: `${orgKey}.users`,
    route: `${baseRoute}/users`,
  },
  roles: {
    lngKey: `${orgKey}.roles`,
    route: `${baseRoute}/roles`,
  },
  logs: {
    lngKey: `${orgKey}.logs`,
    route: `${baseRoute}/logs`,
  },
  machineType: {
    lngKey: `${orgKey}.machineType`,
    route: `${baseRoute}/machineTypes`,
  },
  codes: {
    lngKey: `${orgKey}.codes`,
    route: `${baseRoute}/codes`,
  },
  scheduler: {
    lngKey: `${orgKey}.scheduler`,
    route: `${baseRoute}/schedulers`,
  },
  requestFlow: {
    lngKey: `${orgKey}.requestFlow`,
    route: `${baseRoute}/requestFlows`,
  },
  machines: {
    lngKey: `${orgKey}.machines`,
    route: `${baseRoute}/machines`,
  },
};

const cmn = "common";
export const Commons = {
  create: `${cmn}.create`,
  update: `${cmn}.update`,
  view: `${cmn}.view`,
  name: `${cmn}.name`,
  count: `${cmn}.currentHeadCount`,
  identifier: `${cmn}.identifier`,
  code: `${cmn}.code`,
  companyName: `${cmn}.companyName`,
  actions: `${cmn}.actions`,
  is_active: `${cmn}.is_active`,
  status: `${cmn}.status`,
  description: `${cmn}.description`,
  shortname: `${cmn}.shortname`,
  country: `${cmn}.country`,
};

const val = "validations";
export const Validations = {
  name: `${val}.name.required`,
}

export const colors = {
  "bg-warning": "#faad14",
  "bg-warning-color": "white",
  "bg-success": "green",
  "bg-success-color": "white",
  "bg-darkgray": "#333",
  "bg-danger": "#d9534f"
}