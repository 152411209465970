import React, { useEffect, useState } from "react";
import { Spin, message } from "antd";
import { SaveButton, CancelButton, ModalComponent, trans } from "@comps/components"
import { Commons, Const } from "../../constants";
import { makeRequest, handleDownloadFile } from "@utils/helpers";
import { downloadPayslipReq } from "../requests";
import './style.css'
import moment from "moment";
import { downloadFinalSettlePayslipReq } from "../../finalSettlements/requests";
const langKey = Const["myPayslip"].lngKey
const formName = "viewMyPayslip";
const ViewPayslip = (props) => {
  const [loader, setLoader] = useState(false);
  const [payslip, setPayslip] = useState();

  useEffect(() => {
    setData()
    // eslint-disable-next-line
  }, []);

  const setData = () => {
    setPayslip(props.record)
  }


  function number_format(value, decimals = 2) {
    return value.toFixed(decimals).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  function ucwords(str) {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  }

  const onDownload = (record) => {
    const payload = {
      filters: `ids_|${record.id};download_type_|pdf;`,
    }
    if (props.finalSettleId === undefined) {
      makeRequest(setLoader, downloadPayslipReq, payload, onDownloadSuccess, onDownloadSuccess);
    }

    if (props.finalSettleId) {
      makeRequest(setLoader, downloadFinalSettlePayslipReq, props.finalSettleId, onDownloadSuccess, onDownloadSuccess);
    }
    setLoader(true)
  }

  const onDownloadSuccess = (err, res) => {
    setLoader(false)
    if (res.code === undefined) {
      const fileExtension = res.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ? 'xlsx' : 'pdf'
      const fileName = `paysilp.${fileExtension}`
      handleDownloadFile(fileName, res)
    }
    else {
      message.error(res.message)
    }
  }
  //-----------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------
  const footer = [
    // props.disabled ? '' :
    <SaveButton loading={ loader } text="Download" form={ formName } key="create_button" onClick={ () => onDownload(payslip) } />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];
  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------
  if (!payslip) {
    return
  }
  return (
    <ModalComponent top={ 20 } width={ 1400 } maskClosable={ false }
      mainTitle={ trans(Commons.view) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Spin spinning={ loader }>

        {/* <EmployeeInfo record={ payslip.requestor } /> */ }

        <section className="full-height">
          <section id="payslip" className="tile noBottom" fullscreen="isFullscreen01">
            <div className="tile-header dvd dvd-btm">
              <br />
              <div className="inline-block" style={ { width: '100%', textAlign: '-webkit-center', paddingLeft: '10px' } }>
                { payslip.company_img_url ? (
                  <div className="company-logo" style={ { padding: '4px 2px 0px 2px', width: 'auto', height: '40px', float: 'left' } }>
                    <img src={ payslip.company_img_url ? payslip.company_img_url : '/public/images/building.png' } style={ { maxHeight: '100%', float: 'left' } } alt="" />
                  </div>
                ) :
                  <div className="company-logo" style={ { padding: '4px 2px 0px 2px', width: 'auto', height: '40px', float: 'left' } }>
                    <img src='/images/building.png' style={ { maxHeight: '100%', float: 'left' } } alt="" />
                  </div>
                }
                <div style={ { verticalAlign: 'middle', width: '50%' } }>
                  <div>
                    <strong className="ng-binding">{ payslip.requestor.company_name }</strong>
                    <p>
                      <small>{ payslip.company_address }</small>
                    </p>
                  </div>
                  <div>
                    { payslip.is_final_settlement ? (
                      <strong> Final Settlement</strong>
                    ) : (
                      <strong>
                        Payslip </strong>
                    ) }
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="tile-body form-validation clearfix">
              <div className="form-group clearfix">
                <div className="col-md-6 column-left">
                  <table className="table table-striped">
                    <thead className="bg-blue" style={ { borderRight: '2px solid #297fa3', borderLeft: '2px solid #297fa3' } }>
                      <tr>
                        <th colSpan="2">
                          <center>Employee Info</center>
                        </th>
                      </tr>
                    </thead>
                    <tbody style={ { borderBottom: '1px solid' } }>
                      <tr>
                        <td>{ "Code" }</td>
                        <td>{ payslip.requestor.emp_code }</td>
                      </tr>
                      <tr>
                        <td>{ "Name" }</td>
                        <td>{ payslip.requestor.full_name }</td>
                      </tr>
                      <tr>
                        <td>{ "DOJ" }</td>
                        <td>{ moment(payslip.requestor.joining_date).format('MMMM D, YYYY') }</td>
                      </tr>
                      {/* Assuming 'trans' is a translation function or object that handles translation logic */ }
                      <tr>
                        <td>{ trans(`${langKey}.DESIGNATION`) }</td>
                        <td>{ payslip.requestor.designation_name }</td>
                      </tr>
                      <tr>
                        <td>{ trans(`${langKey}.DEPARTMENT`) }</td>
                        <td>{ payslip.requestor.department_name }</td>
                      </tr>
                      <tr>
                        <td>{ trans(`${langKey}.BRANCH`) }</td>
                        <td>{ payslip.requestor.branch_name }</td>
                      </tr>
                      <tr>
                        <td>{ trans(`${langKey}.LOCATION`) }</td>
                        <td>{ payslip.requestor.location_name }</td>
                      </tr>
                      <tr>
                        <td>{ trans(`${langKey}.MONTH`) }</td>

                        {
                          payslip['month'] ? <td>{ moment(payslip['month']).format('MMMM, YYYY') }</td> :
                            payslip['attendance_count']['start'] ?
                              <td>{ moment(payslip['attendance_count']['start']).format('MMMM, YYYY') + '-' + moment(payslip['attendance_count']['end']).format('MMMM, YYYY') }</td>
                              :
                              <td>{ moment(payslip['attendance_count']['start_range']).format('MMMM, YYYY') + '-' + moment(payslip['attendance_count']['end_range']).format('MMMM, YYYY') }</td>
                        }

                      </tr>
                      { payslip.last_working_date && (
                        <tr>
                          <td>Date of Separation</td>
                          <td>{ payslip.last_working_date }</td>
                        </tr>
                      ) }

                    </tbody>
                  </table>
                </div>

                {/* Attendance info */ }

                <div className="col-md-6 column-right">
                  <table className="table table-striped">
                    <thead className="bg-blue" style={ { borderRight: '2px solid #297fa3', borderLeft: '2px solid #297fa3' } }>
                      <tr>
                        <th colSpan="2">
                          <center>Attendance Info</center>
                        </th>
                      </tr>
                    </thead>
                    { payslip.attendance_count && (
                      <tbody style={ { borderBottom: '1px solid' } }>
                        <tr>
                          <td>{ trans(`${langKey}.PRESENTS`) }</td>
                          <td className="text-right">{ payslip.attendance_count.presents }</td>
                        </tr>
                        <tr>
                          <td>{ trans(`${langKey}.MISSINGS`) }</td>
                          <td className="text-right">{ payslip.attendance_count.missings }</td>
                        </tr>
                        <tr>
                          <td>{ trans(`${langKey}.ABSENTS`) }</td>
                          <td className="text-right">{ payslip.attendance_count.absents }</td>
                        </tr>
                        <tr>
                          <td>{ trans(`${langKey}.LATE_ARRIVALS`) }</td>
                          <td className="text-right">{ payslip.attendance_count.late_arrivals }</td>
                        </tr>
                        <tr>
                          <td>{ trans(`${langKey}.EARLY_LEFTS`) }</td>
                          <td className="text-right">{ payslip.attendance_count.early_lefts }</td>
                        </tr>
                        <tr>
                          <td>{ trans(`${langKey}.SHORT_DURATION`) }</td>
                          <td className="text-right">{ payslip.attendance_count.short_durations }</td>
                        </tr>
                        <tr>
                          <td>{ trans(`${langKey}.LEAVES`) }</td>
                          <td className="text-right">{ payslip.attendance_count.leaves }</td>
                        </tr>
                        <tr>
                          <td>{ trans(`${langKey}.RELAXATION`) }</td>
                          <td className="text-right">{ payslip.attendance_count.relaxations }</td>
                        </tr>
                      </tbody>
                    ) }
                  </table>
                </div>


                {/*End Attendance info */ }
              </div>

              {/* Salary info */ }
              <div class="form-group clearfix">
                { payslip.net_earning > 0 && (
                  <div className="column-full">
                    <table className="table table-striped">
                      <thead className="bg-blue" style={ { borderRight: '2px solid #297fa3', borderLeft: '2px solid #297fa3' } }>
                        <tr>
                          <th colSpan="4">{ trans(`${langKey}.EARNINGS`) }</th>
                          <th className="text-right">{ trans(`${langKey}.AMOUNT`) }</th>
                        </tr>
                      </thead>
                      <tbody>
                        { payslip.payitems !== undefined && payslip.payitems.length !== undefined &&
                          payslip.payitems
                            .filter((payitem) => payitem.type === 'earning' && payitem.amount > 0)
                            .map((payitem, index) => (
                              <tr key={ index }>
                                <td colSpan="4">{ payitem.payitem_name }</td>
                                <td className="text-right">{ number_format(parseFloat(payitem.amount), 2) }</td>
                              </tr>
                            )) }
                      </tbody>
                      <thead style={ { borderBottom: '1px solid', borderTop: '1px solid', color: '#666' } }>
                        <tr>
                          <th colSpan="4">{ trans(`${langKey}.GROSS_EARNINGS`) }</th>
                          <th className="text-right">
                            { payslip.currency_symbol }
                            { number_format(parseFloat(payslip.net_earning), 2) }
                          </th>
                        </tr>
                      </thead>
                    </table>
                  </div>
                ) }

                { payslip.net_deduction > 0 && (
                  <div className="column-full">
                    <table className="table table-striped">
                      <thead className="bg-blue" style={ { borderRight: '2px solid #297fa3', borderLeft: '2px solid #297fa3' } }>
                        <tr>
                          <th colSpan="5">{ trans(`${langKey}.DEDUCTIONS`) }</th>
                          <th className="text-right">{ trans(`${langKey}.AMOUNT`) }</th>
                        </tr>
                      </thead>
                      <tbody>
                        { payslip.payitems !== undefined && payslip.payitems.length !== undefined &&
                          payslip.payitems
                            .filter((payitem) => payitem.type === 'deduction' && payitem.amount > 0)
                            .map((payitem, index) => (
                              <tr key={ index }>
                                <td colSpan="5">{ payitem.payitem_name }</td>
                                <td className="text-right">{ number_format(parseFloat(payitem.amount), 2) }</td>
                              </tr>
                            )) }
                      </tbody>
                      <thead style={ { borderBottom: '1px solid', borderTop: '1px solid', color: '#666' } }>
                        <tr>
                          <th colSpan="5">{ trans(`${langKey}.GROSS_DEDUCTIONS`) }</th>
                          <th className="text-right">
                            { payslip.currency_symbol }
                            { number_format(parseFloat(payslip.net_deduction), 2) }
                          </th>
                        </tr>
                      </thead>
                    </table>
                  </div>
                ) }

                <div className="column-full">
                  { payslip.net_reimbursement > 0 && (
                    <table>
                      <thead>
                        <tr>
                          <th colSpan="8" style={ { paddingRight: '0' } }>
                            <p style={ { textAlign: 'center', margin: '10px 0' } }>
                              <strong>{ trans(`${langKey}.REIMBURSEMENT_DETAILS`) }</strong>
                            </p>
                          </th>
                        </tr>
                        <tr>
                          <th colSpan="4">{ trans(`${langKey}.COMPONENTS`) }</th>
                          <th colSpan="4" className="text-right">{ trans(`${langKey}.AMOUNT`) }</th>
                        </tr>
                      </thead>
                      { payslip.payitems &&
                        payslip.payitems
                          .filter((payitem) => payitem.type === 'reimbursement' && payitem.amount > 0)
                          .map((payitem, index) => (
                            <tbody key={ index }>
                              <tr>
                                <td colSpan="4">{ payitem.payitem_name }</td>
                                <td colSpan="4" className="text-right column-full-value">
                                  { number_format(parseFloat(payitem.amount), 2) }
                                </td>
                              </tr>
                            </tbody>
                          )) }
                      <thead>
                        <tr>
                          <th colSpan="6">{ trans(`${langKey}.TOTAL_REIMBURSEMENT_OF_THE_MONTH`) }</th>
                          <th className="text-right">
                            { payslip.currency_symbol }
                            { number_format(parseFloat(payslip.gross_reimbursement), 2) }
                          </th>
                        </tr>
                      </thead>
                    </table>
                  ) }

                  <div className="bottom-table mt-10 mb-10">
                    <table className="table table-striped">
                      <thead className="bg-blue" style={ { borderRight: '2px solid #297fa3', borderLeft: '2px solid #297fa3' } }>
                        <tr>
                          <th colSpan="8">
                            <h4>
                              <strong>{ trans(`${langKey}.NET_TRANSFER`) }</strong>
                            </h4>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td colSpan="2">
                            <strong>{ trans(`${langKey}.NET_TRANSFER`) }</strong>
                          </td>
                          <td colSpan="6" className="">
                            <div>
                              { payslip.currency_symbol }
                              { number_format(parseFloat(payslip.net_salary)) }/-
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="2">
                            <strong>{ trans(`${langKey}.IN_WORDS`) }</strong>
                          </td>
                          <td colSpan="6" className="" style={ {} }>
                            { payslip.net_salary_words && (
                              <div>{ ucwords(payslip.net_salary_words) + " Only" }</div>
                            ) }
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                </div>
              </div>

              {/*End Salary info */ }
            </div>

            { payslip.is_final_settlement && (
              <table className="text-center">
                <tbody>
                  <tr>
                    <td style={ { border: 'none' } }>
                      <p>___________________________</p>
                      <p>Prepared By</p>
                    </td>
                    <td>
                      <p>___________________________</p>
                      <p>Audited By</p>
                    </td>
                    <td>
                      <p>___________________________</p>
                      <p>Reviewed By</p>
                    </td>
                    <td style={ { border: 'none' } }>
                      <p>___________________________</p>
                      <p>Approved By</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            ) }
            <div
              className=""
              style={ {
                marginLeft: '15px',
                marginRight: '15px',
                marginBottom: '25px',
                paddingTop: '0px',
                color: '#666',
              } }
            >
              { payslip.is_final_settlement &&
                ((payslip.note_in_english && payslip.note_in_english !== '') ||
                  (payslip.note_in_other_language && payslip.note_in_other_language !== '')) && (
                  <React.Fragment>
                    <strong> Note :</strong>
                    <table>
                      <tbody>
                        <tr>
                          <td style={ { border: 'none', width: '50%', verticalAlign: 'top' } }>
                            { payslip.note_in_english && (
                              <span style={ { fontFamily: 'DejaVu Serif' } }>{ payslip.note_in_english }</span>
                            ) }
                          </td>
                          <td dir="rtl" style={ { border: 'none', width: '50%', verticalAlign: 'top' } }>
                            { payslip.note_in_other_language && (
                              <span style={ { fontFamily: 'DejaVu Serif' } }>{ payslip.note_in_other_language }</span>
                            ) }
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </React.Fragment>
                ) }
              <strong>{ trans(`${langKey}.THIS_IS_COMPUTER_GENERATED_DOCUMENT_HENCE_NO_SIGNATURE_IS_REQUIRED`) }</strong>
            </div>

          </section>
        </section>
      </Spin>
    </ModalComponent>
  )
}

export default ViewPayslip


