import Checkbox from "antd/es/checkbox/Checkbox"

const BaseCheckBox = (props) => {
  return (

    <Checkbox
      { ...props }
    />
  )
}

export default BaseCheckBox
