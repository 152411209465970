import { get, del, post } from "@utils/axios";

const apiMy = "companyOvertimes";
export const getAllDataReq = (payload) => {
  return get(`${apiMy}?page=${payload.page}&filters=${payload.filters}`) //filters=employee_|id
};

export const createDataReq = (payload) => {
  return post(`${apiMy}?`, payload);
};

export const updateDataReq = (payload) => {
  return post(`${apiMy}/edit?page=1`, payload);
};
export const cancelRequestReq = (payload) => {
  return post('cancelRequest', payload);
};

export const deleteDataReq = (payload) => {
  return del(`${apiMy}/${payload.id}?page=1&filters=${payload.filters}`);
};

export const getRosterReq = (payload) => {
  return post(`${apiMy}/getRoster`, payload);
};

export const getFilterReq = (payload) => {
  return get(`filters?company_id=1&fields=page=1&filters=supporting_fields_|employments,request_status;organization_structure_fields_|location,branch,department;`)
}

export const onDateChange = (payload) => {
  return post(`${apiMy}/dateChange?&`, payload)
}

export const onShiftSelectionChangeReq = (payload) => {
  return post(`${apiMy}/shiftSelectionChange?&`, payload)
}

export const getRelatedDataByReqIdReq = (payload) => {
  return get(`${apiMy}/${payload}`)
};

