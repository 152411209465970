import React, { useEffect, useState } from "react";
import { Form, message, Row, Col } from "antd";
import { SaveButton, InputTextArea, SelectMultiWithSearch, InputText, CancelButton, ModalComponent, trans } from "@comps/components"
import { makeRequest } from "@utils/helpers";
import { createDataReq } from "../requests";
import { Commons, Const } from "../../constants";
import { createSelectList } from '@comps/commonFunctions';
const langKey = Const["delegation"].lngKey
const formName = "viewDelegation";

const ViewDelegation = (props) => {
  const [loader, setLoader] = useState(false);
  const [saving, setsaving] = useState(false);
  const [types, setTypes] = useState([])

  const [form] = Form.useForm()
  useEffect(() => {
    getData()
    // eslint-disable-next-line
  }, []);

  const getData = () => {
    setTypes(createSelectList(props.allData.delegation_types))
    form.setFieldsValue(props.record)
    //getEmployments()
  }


  const onSubmit = (data) => {
    setsaving(true)
    const payload = { delegation: { ...data, company_id: 1, is_active: true } };
    makeRequest(setLoader, createDataReq, payload, onSuccess, onError);
  }

  const onSuccess = (data, res) => {
    message.success(res.message);
    props.onCompleted(data.delegations.data);
  }

  const onError = (err, res) => {
    setsaving(false)
    message.error(res.response.data.message);
  }

  //-----------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [
    !props.disabled &&
    <SaveButton loading={ saving } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } width={ 1000 } maskClosable={ false }
      mainTitle={ trans(Commons.view) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) } >

      <Form { ...formLayout } name={ formName } onFinish={ onSubmit } form={ form } disabled={ props.disabled }>
        <Row gutter={ 30 }>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="delegator_name" rules={ rules.delegator_employment_id } label={ trans(`${langKey}.delegator_employment_id`) }
              className="da-mb-16"
            >
              <InputText
              />
            </Form.Item>
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="delegation_types" rules={ rules.delegation_types } label={ trans(`${langKey}.delegation_types`) }
              className="da-mb-16"
            >
              <SelectMultiWithSearch
                options={ types }
                placeholder={
                  trans(`${langKey}.delegation_types`)
                } />
            </Form.Item>
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="delegatee_name" rules={ rules.delegatee_employment_id } label={ trans(`${langKey}.delegatee_employment_id`) }
              className="da-mb-16"
            >
              <InputText
              />
            </Form.Item>
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="remarks" rules={ rules.remarks } label={ trans(`${langKey}.remarks`) }
              className="da-mb-16"
            >
              <InputTextArea placeholder={ trans(`${langKey}.remarks`) } />
            </Form.Item>
          </Col>
        </Row>

      </Form>
    </ModalComponent>
  )
}

export default ViewDelegation

const rules = {
  delegator_employment_id: [
    { required: true, message: trans(`${langKey}.delegator_employment_id`) },
  ],
  delegatee_employment_id: [
    { required: true, message: trans(`${langKey}.delegatee_employment_id`) },
  ],
  delegation_types: [
    { required: true, message: trans(`${langKey}.delegation_types`) },
  ],

};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}