import React, { useEffect, useState } from "react";
import { Form, message, Space, Divider, Row, Col, Skeleton, Steps, Collapse, Button } from "antd";
import { SaveButton, InputText, InputCheck, CancelButton, ModalComponent, TableComponent, ActionComponent, SelectWithSearch, trans } from "@comps/components"
import { makeRequest, makeRequestStateless } from "@utils/helpers";
import { Commons, Const } from "../../constants";
import { createDataReq, getReportTypesAndTables, getTableInfoByIdReq, loadRelatedTablesReq } from "../requests";
import { createSelectList } from '@comps/commonFunctions';

const langKey = Const["allReports"].lngKey
const formName = "CreateCustomReport";

var orgData = [];

const CreateCustomReport = (props) => {
  const [loader, setLoader] = useState(false);
  const [saving, setsaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const [reportTypes, setReportTypes] = useState([]);
  const [allTables, setAllTables] = useState('')
  const [tables, setTables] = useState([])
  const [selectedTableId, setSelectedTableId] = useState(null)
  const [collapseItems, setCollapseItems] = useState([])
  const [current, setCurrent] = useState(0);
  const [rerender, setRerender] = useState(false)
  const [loadingTableData, setLoadingTableData] = useState(false)
  let [selectedItems, setSelectedItems] = useState({})
  let [collapsed, setCollapsed] = useState([])
  const [columns, setColumns] = useState([])
  const [relatedTables, setRelatedTables] = useState([])
  var tempColumns = []
  var tempRelatedTables = []
  useEffect(() => {
    getRelatedData()
    // eslint-disable-next-line
  }, []);

  //get report types and tables
  const getRelatedData = () => {
    makeRequestStateless(getReportTypesAndTables, null, onSuccessRelatedData, null);
  }
  const onSuccessRelatedData = (data) => {
    setReportTypes(createSelectList(data.report_types))
    setAllTables(data.tables)
    const newTablesList = []
    data.tables.forEach(table => (
      newTablesList.push(
        {
          label: table.table_name.replace('_', ' ').toUpperCase(),
          value: table.id
        }
      )
    ))
    setTables(newTablesList)
    setLoading(false)
  }

  const onSubmit = (data) => {
    if (columns.length === 0) {
      message.info('Please select columns!')
      return
    }

    columns.forEach(each => (
      each.report_display_name = each.display_name
    ))

    let updadateRelated = []
    relatedTables.forEach(related_table => {

      related_table.alias = related_table.pivot.relation_name
      delete related_table.columns
      delete related_table.related_tables
      updadateRelated.push(related_table)

    }
    )
    const payload = { ...data, columns: columns, related_tables: updadateRelated }
    makeRequest(setLoader, createDataReq, payload, onSuccess, onError);
  }

  const onSuccess = (data, res) => {

    message.success("Saved Successfully");
    props.onCompleted(data.reports.data);

  }

  const onError = (err, res) => {

    setsaving(false)
    message.error(res.response.data.message);
    //setErrors(errorList);
  }

  const onSelectTable = (id) => {

    tempColumns = []
    tempRelatedTables = []
    setRelatedTables([])
    setColumns([])
    if (id) {
      setSelectedTableId(id)
      makeRequestStateless(getTableInfoByIdReq, id, onSuccessTableInfo, null);
    }
    else {
      setSelectedTableId(null)
    }
  }

  const onSuccessTableInfo = (data) => {
    orgData = data.table
    setCollapseItems(getCollapseItems(data.table))
    //setRelatedTables(data.table.related_tables)
    setLoadingTableData(false)
  }

  //show columns and related tables selected
  const selectRelatedTable = (identity, e, related_table, parent_table) => {
    setLoadingTableData(true)
    //set collapsed if checked
    if (e.target.checked) {
      collapsed.push(identity)
      delete related_table.related_tables
      delete related_table.related_columns
      tempRelatedTables.push(related_table)
      setRelatedTables(tempRelatedTables)
    }
    if (!e.target.checked) {
      if (collapsed.includes(identity)) {
        const index = collapsed.indexOf(identity);
        if (index !== -1) {
          collapsed.splice(index, 1)
        }
      }
    }
    //
    var alias = "";
    if (parent_table.alias !== undefined && parent_table.alias) {
      alias = parent_table.alias + "_" + related_table.pivot.relation_name;
    } else {
      alias = related_table.pivot.relation_name;
    }

    const payload = {
      id: related_table.id,
      selected_tables: [related_table.id],
      alias: alias,
      related_table: related_table.pivot.id,
    };
    setRerender(true);
    makeRequestStateless(loadRelatedTablesReq, payload, (res) => onLoadRelatedTableSuccess(res, parent_table), null);
  }

  const onLoadRelatedTableSuccess = (res, data) => {
    var finalData = data
    finalData.related_tables = addChildToParent(res, data)
    orgData = finalData
    setCollapseItems(getCollapseItems(finalData))
    setRerender(false);
    setLoadingTableData(false)
  }

  const addChildToParent = (res, data) => {
    var complete_data = [];
    data.related_tables.forEach((e) => {
      if (e.related_tables && e.related_tables.length > 0) {
        e.related_tables = addChildToParent(res, e);
      }
      if (e.id === res.table.id) {
        let table = e
        table.related_tables = res.table.related_tables
        table.columns = res.table.columns
        complete_data.push(table);
      } else {
        complete_data.push(e);
      }
    })
    return complete_data;
  }

  //add columns to selected
  const selectColumn = (identity, e, column, index, table) => {


    if (e.target.checked) {
      tempColumns.push({ ...column, relation_name: table.table_name, selected: true })
    }
    // if (e.target.checked && column.is_related !== undefined) {
    //   delete table.columns
    //   delete table.related_tables
    //   tempRelatedTables.push({ ...table, selected: true })
    // }

    if (!e.target.checked) {
      // Filtering out the object
      tempColumns = tempColumns.filter(obj => obj.column_key !== column.column_key);
    }

    // if (!e.target.checked && column.is_related !== undefined) {
    //   tempRelatedTables = tempRelatedTables.filter(obj => obj.id !== column.related_table_id)
    // }

    // tempRelatedTables = tempRelatedTables.map(each => {
    //   return each
    // })

    tempColumns = tempColumns.map(each => {
      return each
    })


    selectedItems = {
      ...selectedItems,
      [identity]: e.target.checked
    }

    //setRelatedTables(tempRelatedTables)
    setColumns(tempColumns)
  }

  const getCollapseItems = (table) => {

    const items = []
    // columns
    table && table.columns && table.columns.forEach((column, key) => {

      const columnKey = column.column_key
      items.push(
        {
          key: columnKey,
          label: (
            <div>
              <InputCheck onChange={ (e) => selectColumn(columnKey, e, column, key, table) } defaultChecked={ selectedItems[columnKey] }>
                { column.display_name }
              </InputCheck>
            </div>
          ),
          showArrow: false,
          collapsible: 'disabled'
        }
      )
    })
    //Related Tables
    table &&
      table.related_tables &&
      table.related_tables.forEach((relatedTable, key) => {
        const identity = relatedTable.pivot.relation_name
        items.push(
          {
            key: identity,
            label: (
              <div>
                <InputCheck onChange={ (e) => selectRelatedTable(identity, e, relatedTable, orgData) } >
                  { relatedTable.pivot.relation_name.toUpperCase() }
                </InputCheck>
              </div>
            ),
            children: relatedTable.related_tables ? getSelectColumnsBox(getCollapseItems(relatedTable)) : null, // Ensure this is always an array
            showArrow: true,
            // collapsible: 'disabled'
          }
        )
      })

    return items
  }

  const onChangePanel = (key) => {

  }

  const getSelectColumnsBox = (collapseItems) => {
    return <Collapse defaultActiveKey={ collapsed } items={ collapseItems } />
  }
  const displayColumns = (columns) => {

    return columns.map(column => (
      <li key={ column.id }>{ column.display_name }</li>
    ))
  }

  //steps

  const stepOne = () => {
    return (
      <>
        <Row>
          <Col span={ 12 } style={ {
            maxHeight: '450px',
            overflowY: 'scroll',
            overflowX: 'scroll',
            paddingLeft: ''
          } } className="border"
          >
            <Divider>Select Columns</Divider>
            <div >
              <span style={ { marginLeft: '10px' } }>

                <li>
                  <Skeleton loading={ loadingTableData }>
                    <Space>
                      { !rerender && getSelectColumnsBox(collapseItems) }

                    </Space>
                  </Skeleton>

                </li>
              </span>
            </div>
          </Col>


          <Col span={ 12 } style={ {
            maxHeight: '450px',
            overflowY: 'scroll',
            overflowX: 'scroll',
            paddingLeft: ''
          } } className="border ">
            <Divider>Selected Columns</Divider>
            <ul>
              {
                displayColumns(columns) }
            </ul>
          </Col>
        </Row>
      </>
    )
  }
  //
  const columnsOfSelectedColumns = [
    {
      key: 'column_name',
      title: trans(`${langKey}.column_name`),
      dataIndex: 'display_name'
    },
    {
      key: 'column_type',
      title: trans(`${langKey}.column_type`),
      dataIndex: 'column_type'
    },
    {
      key: 'relation_name',
      title: trans(`${langKey}.relation_name`),
      dataIndex: 'relation_name'
    },
    {
      key: trans(Commons.actions),
      title: trans(Commons.actions),
      render: (record) => (
        <ActionComponent
          each={ record } modkey={props.modkey}
        //onEdit={ record.is_raw_query ? onEdit : '' }
        //onView={ record.is_raw_query ? onViewRawReport : onView }
        //onDelete={ onDelete }
        >

        </ActionComponent>
      ),
    },
  ]
  //second step (2) :show columns 
  const stepTwo = () => {
    return (
      <>
        <Divider className="h1">Selected Columns({ columns.length })</Divider>
        <TableComponent
          columns={ columnsOfSelectedColumns }
          dataSource={ columns }
        />
      </>
    )
  }
  //steps
  const steps = [
    {
      title: 'First',
      content: stepOne(),
    },
    {
      title: 'Second',
      content: stepTwo(),
    },
  ]

  const next = () => {
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
  };
  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));
  //end steps
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------
  const footer = [

    current > 0 && (
      <Button
        style={ {
          margin: '0 8px',
        } }
        onClick={ () => prev() }
      >
        Previous
      </Button>
    ),
    current < steps.length - 1 && (
      <Button type="primary" onClick={ () => next() }>
        Next
      </Button>
    ),
    current === steps.length - 1 &&
    <SaveButton loading={ saving } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];
  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------
  return (

    <ModalComponent top={ 30 } height={ 30 } width={ 1500 } maskClosable={ false }
      mainTitle={ trans(Commons.create) } subTitle={ trans(`${langKey}.customReport`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Skeleton loading={ loading }>


        <Form { ...formLayout } name={ formName } onFinish={ onSubmit }>
          <Form.Item name="title" rules={ rules.name } label={ trans(`${langKey}.name`) }
            className="da-mb-16"

          >
            <InputText placeholder="Name" />
          </Form.Item>

          <Form.Item name="report_type_id" label={ trans(`${langKey}.report_type_id`) } rules={ rules.report_type_id }
          >
            <SelectWithSearch
              className="da-mb-16"
              placeholder={ trans(`${langKey}.report_type_id`) }
              options={ reportTypes } />
          </Form.Item>
          <Form.Item name="schema_table_id" label={ trans(`${langKey}.schema_table_id`) } rules={ rules.schema_table_id }>

            <SelectWithSearch
              className="da-mb-16"
              placeholder={ trans(`${langKey}.schema_table_id`) }
              options={ tables }
              onChange={ onSelectTable }

            />
          </Form.Item>

          <Steps current={ current } items={ items } />
          <div >{ steps[current].content }</div>
        </Form>

      </Skeleton>
    </ModalComponent>
  )
}

export default CreateCustomReport

const rules = {
  name: [
    { required: true, message: trans(`${langKey}.name.required`) },
  ],
  report_type_id: [
    { required: true, message: trans(`${langKey}.report_type_id`) },
  ],
  schema_table_id: [
    { required: true, message: trans(`${langKey}.schema_table_id`) },
  ]
};

const formLayout = {
  // layout: "horizontal",
  labelCol: { span: 6 },
  wrapperCol: { span: 14, offset: 2 },
  labelAlign: "left"
}
