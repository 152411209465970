import React from "react";
import { Route, Routes } from "react-router-dom";
import IndexBreakType from "./settings/breakTypes/IndexBreakType";
import IndexAttendanceConfigurations from "./settings/configurations/IndexAttendanceConfigurations";
import IndexCompanyAttendanceRequests from "./attendances/companyAttendances/IndexCompanyAttendanceRequests";
import IndexAttendanceApprovals from "./attendances/attendanceApprovals/IndexAttendanceApprovals";
import IndexCompanyOvertimeRequests from "./overtimes/companyOvertime/IndexCompanyOvertimeRequests";
import IndexOvertimeApprovals from "./overtimes/overtimeApproval/IndexOvertimeApprovals";
import IndexAttendanceLogs from "./attendanceLogs/IndexAttendanceLogs";
import IndexCompanyTimeSheet from "./companyTimeSheet/IndexCompanyTimeSheet";

import { useSelector } from "react-redux";

const AttendanceManagementModules = () => {

  const authUser = useSelector((state) => state.auth.authUser);
  return (
    <Routes>
      <Route path="settings/break-types" element={ <IndexBreakType modkey="breakTypes" /> } />
      <Route path="settings/configurations" element={ <IndexAttendanceConfigurations modkey="attendanceConfigurations"/> } />
      <Route path="attendance-requests/requests" element={ <IndexCompanyAttendanceRequests filters={ `employee_|${authUser.employment_id}` } module="my-attendance" modkey="attendanceRequests"/> } />
      <Route path="attendance-requests/company-requests" element={ <IndexCompanyAttendanceRequests filters={ '' } module="company-attendance" modkey="attendanceRequests"/> } />
      <Route path="attendance-requests/approvals" element={ <IndexAttendanceApprovals api="attendanceRequestApprovals" modkey="attendanceApprovals"/> } />
      <Route path="attendance-requests/delegates" element={ <IndexAttendanceApprovals api="attendanceRequestDelegates" modkey="attendanceDelegates" /> } />
      <Route path="overtimes/company-requests" element={ <IndexCompanyOvertimeRequests modkey="companyOvertimes"/> } />
      <Route path="overtimes/approvals" element={ <IndexOvertimeApprovals api="overtimeApprovals" modkey="overtimeApprovals"/> } />
      <Route path="overtimes/delegates" element={ <IndexOvertimeApprovals api="overtimeDelegates" modkey="overtimeDelegates"/> } />
      <Route path="attendancelogs" element={ <IndexAttendanceLogs modkey="attendanceLogs"/> } />
      {/* Time sheet routes */ }
      <Route path="my-attendance" element={ <IndexCompanyTimeSheet filters={ `employee_|${authUser.employment_id}` } module="my-attendance" modkey="myAttendance"/> } />
      <Route path="company-attendance" element={ <IndexCompanyTimeSheet filters={ '' } module="company-attendance" modkey="companyAttendances"/> } />
      <Route path="subordinate-attendance" element={ <IndexCompanyTimeSheet filters={ `subordinate_|${authUser.employment_id}` } module="subordinate-attendance" modkey="subordinateAttendance"/> } />

    </Routes>
  );
}

export default AttendanceManagementModules;