import { get, post } from "@utils/axios";

const api = "attendance/configurations";

export const getAllDataReq = (payload) => {
  return get(`${api}`)
};

export const createDataReq = (payload) => {
  return post(`attendance/general/configurations`, payload);
};