import React from "react";
import { Route, Routes } from "react-router-dom";
import IndexDelegation from "./delegations/IndexDelegation";
import IndexRestrictRequest from "./restrictRequests/IndexRestrictRequest";
import IndexPrivilege from "./previleges/IndexPrivilege";
import IndexIpRestriction from "./IpRestrictions/IndexIpRestriction";
import IndexCustomDashboard from "./customDashboard/IndexCustomDashboard";
import CreateCustomDashboard from "./customDashboard/components/CreateCustomDashboard";
import UpdateCustomDashboard from "./customDashboard/components/UpdateCustomDashboard";

const WorkflowManagementModules = () => {
  return (
    <Routes>
      <Route path="delegation" element={ <IndexDelegation /> } />
      <Route path="restrictRequests" element={ <IndexRestrictRequest /> } />
      <Route path="privilege" element={ <IndexPrivilege /> } />
      <Route path="ipRestriction" element={ <IndexIpRestriction /> } />
      <Route path="customDashboard/index" element={ <IndexCustomDashboard /> } />
      <Route path="customDashboard/index/create" element={ <CreateCustomDashboard /> } />
      <Route path="customDashboard/index/edit/:id" element={ <UpdateCustomDashboard disabled={false}/> } />
      <Route path="customDashboard/index/view/:id" element={ <UpdateCustomDashboard disabled={true}/> } />

    </Routes>
  );
}

export default WorkflowManagementModules;
