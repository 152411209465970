import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Spin } from 'antd';
import { makeRequest } from '@utils/helpers';
import { getHeadCountByServiceReq } from '../requests';
import "../dashboard.css"
import ReactApexChart from 'react-apexcharts';
import useInViewport from '../useInViewport';

var names = [];
var values = [];

const HeadCountByService = () => {
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);

  const viewRef = useRef(null);
  const viewport = useInViewport(viewRef);
  useEffect(() => {
    if (viewport && data.length === 0) {
      makeRequest(setLoader, getHeadCountByServiceReq, '', onSuccess, onError)
    }
  }, [viewport]);

  const onSuccess = (data) => {
    data.forEach(element => {
      names.push(element.name);
      values.push(element.y);
    }
    );
    setData(data);
  }

  const onError = (error) => {
  }

  const chartOptions = {
    // Define your chart options here
    series: values,
    labels: names,
    options: {
      chart: {
        width: 380,
        type: 'pie',
      },
    },
  };

  return (
    <div className='widgetbox' ref={ viewRef }>
      <Row>
        <Col span={ 12 }>
          <h3>Head Count By Service</h3>
        </Col>
        <Col span={ 12 }>
          <div className='changeDate'>
          </div>
        </Col>
      </Row>
      <div className='gendermain'>
        <Spin spinning={ loader } tip="Loading...">
          { data && names.length > 0 && values.length > 0 && (
            <ReactApexChart
              options={ chartOptions }
              series={ chartOptions.series }
              type="pie"
              height={ 380 }
            />
          ) }
        </Spin>
      </div>
    </div>
  );
}

export default HeadCountByService;