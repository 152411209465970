import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Modal } from "antd";
import { ButtonComponent } from "@comps/components";
import "./SignIn.css"


const RecoverPassword = ({ visible, onCancel }) => {
  const dispatch = useDispatch();
  const { loaderState, errors } = useSelector((state) => state.auth);

  const [form] = Form.useForm();


  const onFinish = (values) => {
    onCancel();
  };


  return (
    <div style={ { background: '#F3F6F9' } }>
      <Modal open={ visible } onCancel={ onCancel } footer={ null } closeIcon={ null }>
        <div style={ { marginTop: -30 } }>
          <img src="./loginicon/Vect.svg" alt="not found" style={ { marginLeft: 100, marginTop: 50 } } />
          <img src="./loginicon/happy.svg" alt="not found" style={ { marginLeft: 115, marginTop: -190 } } />
          <img src="./loginicon/Vec2.svg" alt="not found" style={ { marginLeft: -212, marginTop: -100 } } />
          <img src="./loginicon/Vec3.svg" alt="not found" style={ { marginLeft: 233, marginTop: -180 } } />
          <img src="./loginicon/Vec2.svg" alt="not found" style={ { marginLeft: -95, marginTop: -320, width: 82 } } />
          <img src="./loginicon/check.svg" alt="not found" style={ { marginLeft: -65, marginTop: -320 } } />
          <img src="./loginicon/Vec4.svg" alt="not found" style={ { marginLeft: -250, marginTop: -340 } } />
        </div>
        <h1 className="klok-forgot">Check Your Email</h1>
        <p className="klok-forgot-text">
          We have sent a link to recover your password on your email fardeen***gmail.com
        </p>
        <Form form={ form } onFinish={ onFinish }
          layout="vertical"
          name="basic"
          initialValues={ { remember: true } }
          className="da-mt-sm-16 da-mt-32"
        >

          <Form.Item className="da-mt-16 da-mb-8">
            <ButtonComponent style={ { marginTop: 30 } } className="actionButton" type="primary" htmlType="submit" state={ loaderState }>
              Okay Got It
            </ButtonComponent>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );

};

export default RecoverPassword;
