import { get, del, post, put } from "@utils/axios";

const api = "kpi";

export const getAllDataReq = (payload) => {
  return get(`${api}?page=${payload.page}&filters=${payload.filters}`)
};
export const getRelatedDataReq = (payload) => {
  return post(`${api}/relatedData`)
};

export const createDataReq = (payload) => {
  return post(`${api}?`, payload);
};

export const updateDataReq = (payload) => {
  return put(`${api}/${payload.id}`, payload);
};

export const deleteDataReq = (id) => {
  return del(`${api}/${id}`);
};

export const getFilterReq = (payload) => {
  return get(`${api}?page=1&filters?company_id=1&fields=page=1&filters=organization_structure_fields_|company;preset_|grade;`)
}
export const getWorksheetsReq = () => {
  return get(`${api}/allocations/get-related`)
}
export const allocateWorkSheetReq = (payload) => {
  return post(`${api}-allocations`, payload)
}
export const updateAllocateWorkSheetReq = (payload) => {
  return put(`${api}-allocations/${payload.id}`, payload)
}

export const getAllocationsReq = (payload) => {
  return get(`${api}-allocations`)
}

export const deleteAllocationReq = (id) => {
  return del(`${api}-allocations/${id}`)
}

//evaluations api
export const getKpiEvaluationsReq = (id) => {
  return get(`${api}-evaluations`)
}
export const getKpiEvaluationRelatedReq = (id) => {
  return get(`${api}-evaluations-get-related/${id}`)
}

//for mobile to submit evaluation against kpi and tasks
export const createKpiEvaluationsReq = (payload) => {
  return post(`${api}-evaluations`, payload)
}