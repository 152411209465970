
import { Drawer } from 'antd';
import ViewEmployee from './ViewEmployee';

const ViewProfile = (props) => {

  return (
    <>
      <Drawer
        placement="right"
        onClose={ () => props.onCompleted(null) }
        open={ true }
        width={ 615 }
      >
        <div style={ { overflow: "hidden" } }>
          <div style={ { marginTop: 60 } }>
            <ViewEmployee employment_id={ props.record.id } employee_id={ props.record.employee_id } disabled={ false } onCompleted={ () => props.onCompleted(true) } />
          </div>
        </div>
      </Drawer>
    </>
  )
}

export default ViewProfile