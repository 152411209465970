import { get, del, post, postFd, put, putFd } from "@utils/axios";

const api = "requestFlows";

export const getAllDataReq = (payload) => {
  return get(`${api}?page=1&filters=${payload}`)
};

export const getFlowReq = (payload) => {
  return post(`${api}/getFlow?`, payload)
};

export const setFlowReq = (payload) => {
  return post(`${api}/setFlow`, payload);
};

export const updateDataReq = (payload) => {
  return put(`${api}/${payload.id}`, payload);
};

export const deleteDataReq = (id) => {
  return del(`${api}/${id}`);
};

export const getFilterReq = (payload) => {
  return get(`${api}?page=1&filters?company_id=1&fields=page=1&filters=organization_structure_fields_|company;preset_|grade;`)
}

export const getEmploymentsReq = (payload) => {
  return get(`resources/employment?page=1`);
}