import React, { useEffect, useState } from "react";
import { Button, Steps, message } from "antd";
import { SaveButton, ModalComponent, trans } from "@comps/components"


import { makeRequest, makeRequestStateless } from "@utils/helpers";
import { createDataReq, getRelatedDataReq, getCompanyRelatedDataReq, getPreviewDataReq } from "../requests";
import { Commons, Const } from "../../constants";
import FilterSelectEmployees from "@mods/common/FilterSelectEmployees";
import { createSelectList, convertTimeTotz } from '@comps/commonFunctions';
import StepOne from "../../common/StepOne";
import Preview from "../../common/Preview";
import { ShowErrorModal } from "@comps/components";
const langKey = Const["companyRoaster"].lngKey
const formName = "createCompanyRoaster";

const CreateCompanyRoaster = (props) => {
  //========initial child states===============
  const [time_slot, setTimeSlot] = useState([]);
  //============================
  const [loader, setLoader] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [companyRelatedData, setCompanyRelatedData] = useState([]);
  const [timeSlots, setTimeSlots] = useState([]);
  const [selectedEmployments, setEmployments] = useState([]);
  const [previewEmployment, setPreviewEmployments] = useState([]);
  const [previewData, setPreviewData] = useState([]);
  const [dates, setDates] = useState([]);
  //------------intitial steps state---------
  const [firstStepData, setFirstStepData] = useState(null);
  const [currentStep, setCurrent] = useState(0);
  const [defaultBreaks, setDefaultBreaks] = useState([]);

  const [preEmployeesFilter, setPreEmployeesFilter] = useState({})
  const [unSelectedEmployees, setUnSelectedEmployees] = useState([])
  const [selectedEmployees, setSelectedEmployees] = useState([])
  const [errors, setErrors] = useState([])
  const [childComponent, setChildComponent] = useState(null)
  //=======================================
  //=======================================
  useEffect(() => {
    getRemoteData()
    // eslint-disable-next-line
  }, []);
  const getRemoteData = () => {
    makeRequestStateless(getRelatedDataReq, null, ongetRelatedDataReq, null);
    makeRequestStateless(getCompanyRelatedDataReq, null, ongetCompanyRelatedDataReq, null);
  }
  const ongetCompanyRelatedDataReq = (response) => {
    setCompanyRelatedData(response)
  }
  const ongetRelatedDataReq = (response) => {
    setTimeSlots(createSelectList(response.time_slots))
  }
  //==============================Save all Data=========================================
  const onSubmit = (firstStepData) => {

    setSaveLoader(true)
    if (firstStepData.is_full_overtime_shift === undefined) {
      firstStepData.is_full_overtime_shift = false
    }
    const payload = {
      shift: {
        time_slot_id: firstStepData.time_slot_id,
        is_full_overtime_shift: firstStepData.is_full_overtime_shift,
        dates: dates,
        employment_ids: selectedEmployments.employment_ids,
      }
    };
    makeRequest(setLoader, createDataReq, payload, onSuccess, onError);
  }

  const onSuccess = (data, res) => {

    setSaveLoader(false)
    if (data.errors.length > 0) {
      setChildComponent(<ShowErrorModal errors={ data.errors } module={ "Create Roaster" } onCompleted={ () => setChildComponent(null) } />)
      setErrors(data.errors)
      return;
    }
    else {
      message.success("Shift Job Pushed successfully");
      props.onCreated(data);
    }

  }
  const onError = (err, res) => {
    setSaveLoader(false)
    message.error(res.response.data.message);
  }
  //=================Submit First Step 1==============================================
  const getStepOneData = (data, dates) => {
    setFirstStepData(data);
    //setEmployments(data)
    setDates(dates)
    setCurrent(currentStep + 1)
  }
  //=====================On Submit Second Step===========================
  const setSelectedEmploymentsIds = (employments) => {
    setEmployments(employments)
    let payload = { time_slot_id: firstStepData.time_slot_id, employment_ids: employments.employment_ids };
    makeRequest(setLoader, getPreviewDataReq, payload, onGetPreview, null);
    setCurrent(currentStep + 1)
  }
  const onGetPreview = (response) => {
    if (response) {
      response.time_slot.start = response.time_slot.start ? convertTimeTotz(response.time_slot.start) : null
      response.time_slot.end = response.time_slot.end ? convertTimeTotz(response.time_slot.end) : null;
      if (response.time_slot.breaks) {
        response.time_slot.breaks.forEach((each) => {
          each.start = each.start ? convertTimeTotz(each.start) : null;
          each.end = each.end ? convertTimeTotz(each.end) : null;
        })
      }
      setDefaultBreaks(response.time_slot.breaks ? response.time_slot.breaks : [])
      setTimeSlot(response.time_slot)
      setPreviewData(response)
      setPreviewEmployments(response.employments)
      setCurrent(currentStep + 1)
    }
  }
  const onCancel = () => {
    props.onCreated(false);
  }
  const onBack = () => {
    setCurrent(currentStep - 1)
  }

  // const onBack = () => {
  //   setCurrent(currentStep - 1)
  // }

  //================================Main Modal Component==================================
  return (
    <ModalComponent top={ 20 } width={ 1600 } maskClosable={ false }
      mainTitle={ trans(Commons.create) } subTitle={ <>
        <p style={ { paddingLeft: 75, marginTop: -30 } }>{ trans(`${langKey}.moduleName`) }</p>
        <Steps
          className="steps-style"
          current={ currentStep }
          onChange={ setCurrent }
        >
          <Steps.Step className="steps-step1" title={ "Time and Date" } style={ { paddingLeft: 50 } } />
          <Steps.Step className="steps-step2" disabled={ firstStepData ? false : true } title={ "Select Employees" } />
          <Steps.Step className="steps-step3" disabled={ firstStepData ? false : previewEmployment.length > 0 ? false : true } title={ "Finalise" } />
        </Steps>
      </> }
      visible={ true } onCancel={ () => props.onCreated(false) } footer={ '' }>


      { currentStep === 0 ?
        <StepOne timeSlots={ timeSlots } initialValues={ firstStepData } response={ getStepOneData } onCancel={ onCancel } />
        : currentStep === 1 ?
          <FilterSelectEmployees setSelectedEmploymentsIds={ setSelectedEmploymentsIds } initialValues={ preEmployeesFilter } companyRelatedData={ companyRelatedData } onCancel={ onCancel } onBack={ onBack }
            employment_ids={ selectedEmployments }
            moduleName='companyRoaster' selected={ selectedEmployees } unSelected={ unSelectedEmployees } preFilters={ preEmployeesFilter } setSelected={ setSelectedEmployees } setUnselected={ setUnSelectedEmployees } setPrefilters={ setPreEmployeesFilter }
          />
          : currentStep === 2 ?

            <Preview previewData={ previewData } dates={ dates } /> : '' }

      {
        currentStep === 2 ?
          <div className="button-style">
            <SaveButton className="button-next" text={ "Submit" } loading={ saveLoader } form={ formName } key="create_button" onClick={ () => onSubmit(firstStepData) } disabled={ firstStepData.length > 0 ? false : previewEmployment.length > 0 ? false : true } />
            <Button className="button-back" onClick={ () => setCurrent(currentStep - 1) } disabled={ currentStep > 0 ? false : true }>
              <span style={ { color: "#4079FC" } }>Back</span>
            </Button>
            {/* <CancelButton style={ { marginLeft: 652, } } key="close_button" onClick={ () => props.onCreated(false) } /> */ }
          </div>

          : ''
      }

      { childComponent }
    </ModalComponent>
  )
}
export default CreateCompanyRoaster
