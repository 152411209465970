import React from "react";
import { PageHeader } from "@ant-design/pro-layout"
import { checkPermissions } from "@comps/commonFunctions"
import ChatBot from "./ChatBot";

var newChildren = []
const BaseHeader = (props) => {

  const { children, headers,modkey } = { ...props };

  const getChildren = () => {
    // Remove Create button if user does not have create permission
    newChildren = []
    if (children === undefined || !children) {
      return ''
    }
    if (children.length === undefined) {
      newChildren.push(children)
    }
    else {
      newChildren = children
    }
    let index = null
    if (newChildren) {
      newChildren.forEach((each, key) => {
        if (each.props !== undefined && each.props.onClick !== undefined && each.props.onClick.name === 'onCreate') {
          if (!checkPermissions(modkey, 'create')) {
            index = key
          }
        }
      })
    }
if(props.moduleKey==="customPayitem"){
  index=null
}
    if (index !== null) {
      newChildren = newChildren.slice(0, index).concat(newChildren.slice(index + 1));
    }

    return newChildren
  }

  // const BreadItem = (each, i) => {
  //   let elem = each.name;
  //   if (each.path) {
  //     elem = <Link to={ each.path }>{ each.name }</Link>;
  //   }
  //   return <Breadcrumb.Item key={ i }>{ elem }</Breadcrumb.Item>;
  // };

  return (
    <>
    <div className="da-px-32 da-pt-6 da-pb-6 page-header">
      <PageHeader
        className="site-page-header da-p-0"
        title={ headers?.title }
        extra={ getChildren() }
      // icon={}
      />
      {/* <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/">Home</Link>
        </Breadcrumb.Item>
        { headers ? headers.breadcrumb.map((item, index) => BreadItem(item, index)) : '' }
      </Breadcrumb> */}
      <ChatBot />
    </div>
    </>
  );
};

export default BaseHeader;
