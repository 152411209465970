import { useState, useEffect } from "react";
import {
  BodyComponent,
  TableComponent,
  ActionComponent,
  trans,
  HeaderComponent,
  PaginationComponent
} from "@comps/components";
import { makeRequest } from "@utils/helpers";
import { Commons, Const, colors } from "../../constants";
import { Badge, message } from 'antd';
import { FilterComponent, FilterButton } from "@comps/components";
import { deleteDataReq, getAllDataReq, getFilterReq } from "./requests";
import RequestAsTab from "../../../common/RequestAsTab";
import ViewFlow from '@mods/common/ViewFlow';
import ViewLeaveApprovalHistory from '../../common/ViewLeaveApprovalHistory';
import UpdateRelaxationApproval from "../../common/UpdateRelaxationApproval";
import ApproveCanceRelaxationRequest from "../../common/ApproveCanceRelaxationRequest";

const langKey = Const["relaxationApproval"].lngKey;
const pageConfig = {
  headers: {
    title: trans(`${langKey}.moduleName`),
    breadcrumb: [
      {
        name: trans(`${langKey}.moduleName`),
        path: Const["relaxationApproval"].route
      }
    ]
  }
}

const IndexCancelRelaxationApprovals = (props) => {
  const [totalRecords, setTotalRecords] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [loader, setLoader] = useState(false);
  const [childComponent, setChildComponent] = useState(null);
  const [filerComponent, setFilterComponent] = useState(null);
  const [asFilters, setAsFilters] = useState([]);
  const [requestAs, setRequestAs] = useState(8)
  const [noOfNotifications, setNoOfnotifications] = useState(null)
  const [filters, setFilters] = useState("cancel_request_type_|relaxation_request;");

  //start pagination states
  const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState({
    "total": 0,
    "count": 0,
    "per_page": 0,
    "current_page": 0,
    "total_pages": 0,
    "links": {}
  });

  // ==================================
  //   Use States End
  // ==================================

  // ================================== UpdateLocation
  //   Table Data End
  // ==================================

  const columns = [
    {
      key: 'code',
      title: trans(Commons.code),
      dataIndex: "code",
    },
    {
      key: 'employment_id',
      title: trans(`${langKey}.employment_id`),
      render: (record) => {
        return <div>{ record.requestor.full_name }<br /><small>{ record.requestor.emp_code }</small></div>
      },
    },
    {
      key: 'department_name',
      title: trans(`${langKey}.department_name`),
      render: (record) => {
        return record.requestor.department_name;
      }
    },

    {
      key: 'next_requestee',
      title: trans(`${langKey}.next_requestee`),
      render: (record) => {
        return <div>{ record.next_requestee_name ? record.next_requestee_name.full_name : '' }<br /><small>{ record.next_requestee_name ? record.next_requestee_name.emp_code : '' }</small></div>
      },
    },

    {
      key: 'status',
      title: trans(`${langKey}.status`),
      render: (record) => {
        return <Badge
          count={ record.status ? record.status : 'none' }
          style={ { background: colors[record.status_class], color: colors[record.status_class + '-color'] } }
        />
      }
    },

    {
      key: trans(Commons.actions),
      title: trans(Commons.actions),
      render: (record) => (
        <ActionComponent
          each={ record } modkey={props.modkey}
          onViewHistory={ (record.status === 'Completed' || record.status === 'Rejected') ? onViewHistory : '' }
          onViewFlow={ record.flow.length !== 0 ? onViewFlow : '' }
          onEdit={ (record.status === 'Pending') ? onEdit : '' }
          onView={ onView }
        ></ActionComponent>
      ),
    },
  ];


  // ==================================
  //   Table Data End
  // ==================================

  useEffect(() => {
    getAllData(requestAs);
    // eslint-disable-next-line
  }, [filters, currentPage]);

  const getAllData = (requestAsId) => {
    let payload = {
      page: currentPage,
      filters: filters,
      data: { request_as: requestAsId }
    }
    makeRequest(setLoader, getAllDataReq, payload, onSuccess, null);
  };

  const onSuccess = (response) => {
    setNoOfnotifications(response.cancel_requests.length);
    setPagination(response.cancel_requests);
    setDataSource(response.cancel_requests.data);
    setAsFilters(response.filters);
  };

  const onEdit = (record) => {
    setChildComponent(
      <ApproveCanceRelaxationRequest onCompleted={ onCompleted } record={ record } disabled={ false } requestAsId={ requestAs } />
    );
  };

  const onView = (record) => {
    setChildComponent(
      <ApproveCanceRelaxationRequest onCompleted={ onCompleted } record={ record } disabled={ true } requestAsId={ requestAs } />
    );
  };
  const onViewHistory = (record) => {
    setChildComponent(
      <ViewLeaveApprovalHistory onCompleted={ onCompleted } record={ record } disabled={ true } moduleName="cancel_request" />
    );
  };
  const onViewFlow = (record) => {
    setChildComponent(
      <ViewFlow onCompleted={ onCompleted } record={ record } disabled={ true } />
    );
  };

  const onDelete = (record) => {
    makeRequest(setLoader, deleteDataReq, record.id, onDeleted, onError);
  };
  const onDeleted = (res) => {
    onSuccess(res)
    setDataSource(res.leave_requests.data);
    message.success("Record Deleted")
  };

  const onError = (err, res) => {
    message.error(res.response.data.message);
  }

  // Create component modal
  const onCreate = () => {
    //setChildComponent(<CreateEvaluation onCompleted={ onCompleted } />);
  };

  const onCompleted = (data) => {
    if (data.length) {
      setDataSource(data);
    }
    setChildComponent(null);
  };

  const handleTableChange = (page, fil, sorter) => {
    let payload = {
      ...pagination,
      current: page.current,
      pageSize: page.pageSize,
      sortName: sorter.field || "id",
      sortType: sorter.order === "ascend" ? "asc" : "desc",
    };
    setPagination(payload);
  };

  //---------------filters-----------------------
  const onOpenFilter = () => {
    setFilterComponent(<FilterComponent filters={ filterFields } modkey={props.modkey} selected={ filters } api={ getFilterReq } setCurrentPage={setCurrentPage} onFilter={ setFilters } onCloseFilter={ onCloseFilter }
    />
    )
  }

  const onCloseFilter = () => {
    setFilterComponent(null);
  }

  //----end filter--------------------
  //=============================Tab Menus========================================

  //-----------end use states--------------------


  //==============================================================================
  return (
    <>
      { filerComponent }
      { childComponent }
      <BodyComponent>
        <RequestAsTab getAllData={ getAllData } setAsFilters={ asFilters } setRequestAs={ setRequestAs } />
        <HeaderComponent headers={ pageConfig.headers } modkey={props.modkey}>
          <FilterButton onClick={ onOpenFilter } />
        </HeaderComponent>
        <TableComponent modkey={props.modkey}
          loader={ loader }
          columns={ columns }
          dataSource={ dataSource }
          pagination={ { ...pagination, total: totalRecords } }
          onChange={ handleTableChange }
        />
      </BodyComponent>
      <PaginationComponent pagination={ pagination } modkey={props.modkey} setCurrentPage={ setCurrentPage } currentPage={ currentPage } />
    </>
  );
};

export default IndexCancelRelaxationApprovals;
const filterFields = [
  {
    type: "select",
    placeholder: trans(`${langKey}.select.employee`),
    key: "employees",
    data_key: "supporting_data.employments",
        resource_type:'employment'
    //is_resource: true
  },
  {
    type: "select",
    placeholder: trans(`${langKey}.selectlocation`),
    key: "location",
    data_key: "organization_structure_data.location",
    //is_resource: true
  },
  {
    type: "select",
    placeholder: trans(`${langKey}.selectbranch`),
    key: "branch",
    data_key: "organization_structure_data.branch",

    //is_resource: true
  },
  {
    type: "select",
    placeholder: trans(`${langKey}.selectdepartment`),
    key: "department",
    data_key: "organization_structure_data.department",
     filterKey: "hierarchy_code"
    //is_resource: true
  },

]

