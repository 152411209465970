import { get, post } from "@utils/axios";

const api = "payrollManagement/configurations";

export const getAllDataReq = (payload) => {
  return get(`${api}`)
};

export const createDataReq = (payload) => {
  return post(`payrollManagement/general/configurations`, payload);
};