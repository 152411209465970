import React, { useEffect, useState } from "react";
import { Divider, Form, message, Row, Col, Skeleton } from "antd";
import { SaveButton, CancelButton, ModalComponent, trans, InputTime, InputDate, InputTextArea } from "@comps/components"
import { makeRequest, getErrorProps } from "@utils/helpers";
import { getRosterReq, updateDataReq } from "../requests";
import { Commons, Const } from "../../../constants";
import RoasterView from "@mods/common/RoasterView";
import EmployeeInfo from '@mods/common/EmployeeInfo';
import { convertTimeTotz } from '@comps/commonFunctions'

const langKey = Const["overtimeRequest"].lngKey
const formName = "createOvertimeRequest";
const ViewOvertimeRequest = (props) => {
  const [loader, setLoader] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [roasterView, setRoasterView] = useState(null)
  const [shifts, setShifts] = useState({})
  const [preEdit, setPreEdit] = useState(true)
  const [formData, setFormData] = useState({})
  const [loading, setloading] = useState(true)
  const [form] = Form.useForm()
  useEffect(() => {
    setData();
    // eslint-disable-next-line
  }, []);
  const setData = () => {
    let date = props.record.date ? convertTimeTotz(props.record.date) : null
    getRoster(date)
    props.record.date = date
    props.record.start = props.record.start ? convertTimeTotz(props.record.start) : null
    props.record.end = props.record.end ? convertTimeTotz(props.record.end) : null
    form.setFieldsValue(props.record);
  }
  const onChangeDate = (date) => {
    setPreEdit(false)
    if (date) {
      getRoster(date)
    }
  }
  const getRoster = (date) => {
    setloading(true)
    const payload = { overtime: { employment_id: props.record.employment_id, date: date.format('YYYY-MM-DD') } }
    makeRequest(setLoader, getRosterReq, payload, onGetRosterSuccess, onGetRosterSuccess);
  }

  const onGetRosterSuccess = (response, data) => {
    setloading(false)
    if (data.shifts) {
      setRoasterView(<RoasterView record={ data } />)
      setShifts(data.shifts[convertTimeTotz(data.from_date).format('YYYYMMDD') + '-' + data.employments[0].id][0]);
      let start = data.shifts[convertTimeTotz(data.from_date).format('YYYYMMDD') + '-' + data.employments[0].id][0]['start']
      let end = data.shifts[convertTimeTotz(data.from_date).format('YYYYMMDD') + '-' + data.employments[0].id][0]['end']

      if (preEdit === false) {
        form.setFieldsValue({
          start: convertTimeTotz(start),
          end: convertTimeTotz(end),
        })
      }
    }
    else {
      setloading(false)
      message.error('No Shift Found For Given Date!')
      return
    }
  }
  const onSubmit = (data) => {
    setSaveLoader(true)


    const payload = {
      overtime: {
        id: props.record.id,
        shift: shifts,
        employment_id: shifts.employment_id,
        date: data.date.format('YYYY-MM-DD'),
        start_time: data.start.second(0),
        end_time: data.end.second(0),
        start: data.start.second(0),
        end: data.end.second(0),
        description: data.description,
        employee_shift_id: shifts.id
      }
    };
    payload.id = props.record.id;
    makeRequest(setLoader, updateDataReq, payload, onSuccess, onError);

  }
  const onSuccess = (data, res) => {
    if (data.cancel_requests) {
      props.getAllData();
    }
    else {
      props.onCompleted(data.overtimes.data);
    }
    message.success("Saved successfully");

  }

  const onError = (err, res) => {
    setSaveLoader(false)
    message.error(res.response.data.message);
  }

  //-----------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [
    !props.disabled &&
    <SaveButton loading={ saveLoader } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } width={ 1000 } maskClosable={ false }
      mainTitle={ trans(Commons[props.disabled ? 'view' : 'update']) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Skeleton loading={ loading }>
        <EmployeeInfo record={ props.record.requestor && props.record.requestor } />
        <Form { ...formLayout } form={ form } name={ formName } onFinish={ onSubmit } initialValues={ formData } scrollToFirstError>
          <Row gutter={ 30 }>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="date" rules={ rules.date } label={ trans(`${langKey}.date`) }
                className="da-mb-16"
                { ...getErrorProps(errors['date']) } size="small"
              >
                <InputDate onChange={ (date) => onChangeDate(date) } />
              </Form.Item>
            </Col>
          </Row>
          { roasterView }
          <Row gutter={ 30 }>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="start" rules={ rules.start } label={ trans(`${langKey}.start`) }
                className="da-mb-16"
                { ...getErrorProps(errors['start']) } size="small"
              >
                <InputTime placeholder={ trans(`${langKey}.start`) } />
              </Form.Item>
            </Col>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="end" rules={ rules.end } label={ trans(`${langKey}.end`) }
                className="da-mb-16"
                { ...getErrorProps(errors['end']) } size="small"
              >
                <InputTime placeholder={ trans(`${langKey}.end`) } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="description" rules={ rules.description } label={ trans(`${langKey}.description`) }
                className="da-mb-16"
                { ...getErrorProps(errors['description']) } size="small"
              >
                <InputTextArea placeholder={ trans(`${langKey}.description`) } />
              </Form.Item>
            </Col>

            { props.action === 'cancel' ?
              <>
                <Divider>Cancelation</Divider>
                <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                  <Form.Item name="description" rules={ rules.reason } label={ trans(`${langKey}.reason`) }
                    className="da-mb-16"
                    { ...getErrorProps(errors['reason']) } size="small"
                  >
                    <InputTextArea placeholder={ trans(`${langKey}.reason`) } disabled={ false } />
                  </Form.Item>
                </Col>
              </>
              : ''
            }

          </Row>
        </Form>
      </Skeleton>
    </ModalComponent>
  )
}

export default ViewOvertimeRequest

const rules = {
  date: [
    { required: true, message: trans(`${langKey}.date`) },
  ],
  start: [
    { required: true, message: trans(`${langKey}.start`) },
  ],
  end: [
    { required: true, message: trans(`${langKey}.end`) },
  ],
  reason: [
    { required: true, message: trans(`${langKey}.reason`) },
  ],
};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}