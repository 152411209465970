

import { Button, Checkbox, Divider, Form, Input, message, Space } from "antd";
const BaseFormItem = (props) => {


  return (
    <Form.Item name={ props.name } label={ props.label }
      rules={ props.rule }
      className="da-mb-16"
      size="small"
    >
      <Input placeholder={ props.label } />
    </Form.Item>
  )
}

export default BaseFormItem