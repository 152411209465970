import React, { useEffect, useState } from "react";
import { Button, Form, Space, Row, Checkbox, Col } from "antd";
import { SelectWithSearch, InputText, trans, InputDate } from "@comps/components"

import { Const } from "../../constants";
import { createSelectList } from '@comps/commonFunctions';
const CreateStepOne = (props) => {
  const langKey = Const["attendancePolicyPlanner"].lngKey
  const formName = "createAttendancePolicyPlanner";
  //========================use states start========================
  const [errors, setErrors] = useState([]);
  const [deleteAll, setForAllEmployee] = useState(false);
  const [absentPolicies, setAbsentPolicies] = useState([])
  const [missingPolicies, setMissingPolicies] = useState([])
  const [lateArrivalPolicies, setLateArrivalPolicies] = useState([])
  const [earlyLeftPolicies, setEarlyLeftPolicies] = useState([])
  const [shortDurationPolicies, setShortDurationPolicies] = useState([])
  const [relaxationPolicies, setRelaxationPolicies] = useState([])
  const [overtimePolicies, setOvertimePolicies] = useState([])
  const [cplPolicies, setCplPolicies] = useState([])
  const [leavePolicies, setLeavePolicies] = useState([])
  //=======================================
  useEffect(() => {
    setData();
    // eslint-disable-next-line
  }, [props]);

  //set props data
  //=================Submit First Step 1==============================================
  const setData = () => {
    if (props.relatedData.absent_policies) {
      setAbsentPolicies(createSelectList(props.relatedData.absent_policies))
      setMissingPolicies(createSelectList(props.relatedData.missing_policies))
      setLateArrivalPolicies(createSelectList(props.relatedData.late_arrival_policies))
      setEarlyLeftPolicies(createSelectList(props.relatedData.early_left_policies))
      setShortDurationPolicies(createSelectList(props.relatedData.short_duration_policies))
      setRelaxationPolicies(createSelectList(props.relatedData.relaxation_policies))
      setOvertimePolicies(createSelectList(props.relatedData.overtime_policies))
      setLeavePolicies(createSelectList(props.relatedData.leave_policies))
      setCplPolicies(createSelectList(props.relatedData.cpl_policies))
    }

  }

  //=====================On Submit Second Step===========================
  const onSubmitFirstStep = (data) => {
    props.response(data);
  }
  //-----------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------
  return (
    <>
      <Form { ...formLayout } name={ formName } onFinish={ onSubmitFirstStep } initialValues={ props.initialValues ? props.initialValues : null }>
        <Row gutter={ 30 }>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="name" rules={ rules.name } label={ trans(`${langKey}.name`) }
              className="da-mb-16"

            >
              <InputText placeholder={ trans(`${langKey}.name`) } />
            </Form.Item>
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="date" rules={ rules.date } label={ trans(`${langKey}.date`) }
              className="da-mb-16"

            >
              <InputDate />
            </Form.Item>
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="for_all" rules={ rules.for_all } label={ trans(`${langKey}.for_all`) }
              className="da-mb-10"
              valuePropName="checked"
            >
              <Checkbox onChange={ (e) => setForAllEmployee(e.target.checked) } />
            </Form.Item>
          </Col>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="absent_policy_id" rules={ rules.absent_policy_id } label={ trans(`${langKey}.absent_policy_id`) }
              className="da-mb-10"
            >
              <SelectWithSearch
                placeholder={ trans(`${langKey}.absent_policy_id`) }
                options={ absentPolicies }
              />
            </Form.Item>
          </Col>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="missing_policy_id" rules={ rules.missing_policy_id } label={ trans(`${langKey}.missing_policy_id`) }
              className="da-mb-10"
            >
              <SelectWithSearch
                placeholder={ trans(`${langKey}.missing_policy_id`) }
                options={ missingPolicies }
              />
            </Form.Item>
          </Col>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="late_arrival_policy_id" rules={ rules.late_arrival_policy_id } label={ trans(`${langKey}.late_arrival_policy_id`) }
              className="da-mb-10"
            >
              <SelectWithSearch
                placeholder={ trans(`${langKey}.late_arrival_policy_id`) }
                options={ lateArrivalPolicies }
              />
            </Form.Item>
          </Col>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="early_left_policy_id" rules={ rules.early_left_policy_id } label={ trans(`${langKey}.early_left_policy_id`) }
              className="da-mb-10"
            >
              <SelectWithSearch
                placeholder={ trans(`${langKey}.early_left_policy_id`) }
                options={ earlyLeftPolicies }
              />
            </Form.Item>
          </Col>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="short_duration_policy_id" rules={ rules.short_duration_policy_id } label={ trans(`${langKey}.short_duration_policy_id`) }
              className="da-mb-10"
            >
              <SelectWithSearch
                placeholder={ trans(`${langKey}.short_duration_policy_id`) }
                options={ shortDurationPolicies }
              />
            </Form.Item>
          </Col>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="relaxation_policy_id" rules={ rules.relaxation_policy_id } label={ trans(`${langKey}.relaxation_policy_id`) }
              className="da-mb-10"
            >
              <SelectWithSearch
                placeholder={ trans(`${langKey}.relaxation_policy_id`) }
                options={ shortDurationPolicies }
              />
            </Form.Item>
          </Col>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="leave_policy_id" rules={ rules.leave_policy_id } label={ trans(`${langKey}.leave_policy_id`) }
              className="da-mb-10"
            >
              <SelectWithSearch
                placeholder={ trans(`${langKey}.leave_policy_id`) }
                options={ leavePolicies }
              />
            </Form.Item>
          </Col>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="overtime_policy_id" rules={ rules.overtime_policy_id } label={ trans(`${langKey}.overtime_policy_id`) }
              className="da-mb-10"
            >
              <SelectWithSearch
                placeholder={ trans(`${langKey}.overtime_policy_id`) }
                options={ overtimePolicies }
              />
            </Form.Item>
          </Col>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="cpl_policy_id" rules={ rules.cpl_policy_id } label={ trans(`${langKey}.cpl_policy_id`) }
              className="da-mb-10"
            >
              <SelectWithSearch
                placeholder={ trans(`${langKey}.cpl_policy_id`) }
                options={ cplPolicies }
              />
            </Form.Item>
          </Col>
        </Row>

        <Row justify="end">
          <Space>
            <Button className="button-next" type="primary" htmlType="submit">
              Next
            </Button>
            {/* <CancelButton key="close_button" onClick={ () => props.onCancel(false) } /> */ }
          </Space>
        </Row>
      </Form>
    </>
  )
}
export default CreateStepOne
const rules = {
  date: [
    { required: true, message: 'Required' },
  ],
  name: [
    { required: true, message: 'Required' },
  ],

  travel_policy_id: [
    { required: true, message: 'Required' },
  ],

};
const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}
