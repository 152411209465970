import React, { useEffect, useState } from "react";
import { Form, message, Row, Col } from "antd";
import { SaveButton, InputTextArea, InputCheck, CancelButton, ModalComponent, trans,SelectResourceList,InputDate,InputText } from "@comps/components"
import { makeRequest } from "@utils/helpers";
import { createDataReq } from "../requests";
import { Commons, Const } from "../../../constants";


const langKey = Const["payitem"].lngKey
const formName = "createPayitem";

const CreateCustomPayitem = (props) => {
  const [loader, setLoader] = useState(false);
  const [saving, setsaving] = useState(false);
  const [form] = Form.useForm()
  const [processed, setProcessed] = useState(false)
 

  useEffect(() => {
    
    // eslint-disable-next-line
  }, []);

  const onSubmit = (data) => {
  
    const payload = {
      custom_payitem: {
        ...data,
        processed: processed,
        date: data.date.format('YYYY-MM-DD'),
        company_id: 1,
      
      }
      ,
      payitemId:props.payitemId
    };
    makeRequest(setLoader, createDataReq, payload, onSuccess, onError);
    setsaving(true)
  }

  const onSuccess = (data, res) => {
    
setsaving(false)
    message.success(res.message);
    props.onCompleted(data.custom_payitems.data);
    props.onSuccess(data)
  }

  const onError = (err, res) => {
    setsaving(false)
    message.error(res.response.data.message);
  }
  //-----------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [

    <SaveButton loading={ saving } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } width={ 1000 } maskClosable={ false }
      mainTitle={ trans(Commons.create) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Form { ...formLayout } name={ formName } onFinish={ onSubmit } form={ form } >
        <Row gutter={ 30 }>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
          <Form.Item name="employment_id" rules={ rules.employment_id } label={ trans(`${langKey}.employee`) }
                className="da-mb-16"
              >
                <SelectResourceList type='employment' disabled={ props.filters !== undefined } />

              </Form.Item>
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="date" rules={ rules.date } label={ trans(`${langKey}.date`) }
              className="da-mb-16"
            >
              <InputDate/>
            </Form.Item>
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="amount" rules={ rules.amount } label={ trans(`${langKey}.amount`) }
              className="da-mb-16"
            >
              <InputText type="number" placeholder={trans(`${langKey}.amount`)}/>
            </Form.Item>
          </Col>


             <Col lg={ 12 } sm={ 24 } xs={ 24 }>
               <Form.Item name="processed" rules={ rules.processed } label={ trans(`${langKey}.processed`) }
                className="da-mb-16"
                valuePropName="checked"
              >
                <InputCheck onChange={ (e) => setProcessed(e.target.checked) } />
               </Form.Item>
             </Col>
         
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="description" rules={ rules.description } label={ trans(`${langKey}.description`) }
              className="da-mb-16"
              
            >
              <InputTextArea />
            </Form.Item>
          </Col>


        </Row>
      </Form>
    </ModalComponent>
  )
}

export default CreateCustomPayitem

const rules = {
  employment_id: [
    { required: true, message: "Select Employee" },
  ],
  date: [
    { required: true, message: trans(`${langKey}.date`) },
  ],
  amount: [
    { required: true, message: trans(`${langKey}.amount`) },
  ]
};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}