import React, { useEffect, useState } from "react";
import { Form, message } from "antd";
import { SaveButton, InputText, CancelButton, ModalComponent, trans } from "@comps/components"
import { makeRequest, getErrorProps } from "@utils/helpers";
import { createDesignationReq } from "../requests";
import { Commons, Const } from "../../constants";

const langKey = Const["designation"].lngKey
const formName = "createDesignation";

const CreateDesignation = (props) => {
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [saving, setsaving] = useState(false);
  useEffect(() => {
    // eslint-disable-next-line
  }, []);



  const onSubmit = (data) => {
    setsaving(true)
    const payload = { name: data.name }
    makeRequest(setLoader, createDesignationReq, payload, onSuccess, onError);
  }

  const onSuccess = (data, res) => {
    message.success("Designation Saved successfully");
    props.onCompleted(data.designations.data);
    props.onSuccess(data)
  }

  const onError = (err, res) => {
    setsaving(false)
    message.error(res.response.data.message);
    //setErrors(errorList);
  }

  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [
    <SaveButton loading={ saving } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } maskClosable={ false }
      mainTitle={ trans(Commons.create) } subTitle={ trans(`${langKey}.Designation`) }
      open={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>

      <Form { ...formLayout } name={ formName } onFinish={ onSubmit }>
        <Form.Item name="name" rules={ rules.name } label={ trans(`${langKey}.name`) }
          className="da-mb-16"
          { ...getErrorProps(errors['name']) } size="small"
        >
          <InputText placeholder={ trans(`${langKey}.name`) } />
        </Form.Item>
      </Form>
    </ModalComponent>
  )
}

export default CreateDesignation

const rules = {
  name: [
    { required: true, message: trans(`${langKey}.name.required`) },
  ],
  description: [
    { required: true, message: trans(`${langKey}.description.required`) },
  ]
};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}