import { Button, Tooltip } from "antd";


const BaseButton = (props) => {
  let text = props.text ? props.text : props.children;

  let icon = null;
  let notify = props.notify;

  return (
    <Tooltip { ...notify }>
      <Button { ...props }>
        <span>{ text } </span>
        { icon }
      </Button>
    </Tooltip>
  );
};

export default BaseButton;
