import React, { useEffect, useState } from "react";
import { Button, Checkbox, Divider, Form, Skeleton, message } from "antd";
import { SaveButton, InputText, InputTextArea, CancelButton, ModalComponent, trans, TableComponent, ActionComponent, FlowHistoryButton } from "@comps/components"
import { makeRequest, getErrorProps } from "@utils/helpers";
import { Commons, Const } from "../../../constants";
import moment from "moment/moment";
import { approveRequest } from "../requests";
import CreateConsent from "@mods/common/CreateConsent";
import CreateConsentChat from "@mods/common/CreateConsentChat";
import EmployeeInfo from '@mods/common/EmployeeInfo';
import dayjs from 'dayjs';
import UpdateDetails from "../../companyTravelRequests/components/UpdateDetails";
const langKey = Const["travelApproval"].lngKey
const formName = "updateTravelApproval";

const UpdateTravelApproval = (props) => {
  const [loader, setLoader] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [remarks, setRemarks] = useState('')
  const [form] = Form.useForm();
  const [consents, setConsents] = useState([]);
  const [creatConsentModal, setCreateConsentModal] = useState(null);
  const [loading, setLoading] = useState(true)
  const [details, setDetails] = useState([])
  const [dataSource, setDataSource] = useState([])
  const [childComponent, setChildComponent] = useState(null);
  useEffect(() => {
    setData()
  }, []);

  const setData = () => {
    setConsents(props.record.consents)
    //setTravelTypes(createSelectList(props.allData.travel_types));
    props.record.details.forEach((each) => {
      each.departure_date = each.departure_date === "0000-00-00 00:00:00" ? null : dayjs(each.departure_date)
      each.arrival_date = each.arrival_date === "0000-00-00 00:00:00" ? null : dayjs(each.arrival_date)
      each.departure_time = each.departure_time === "0000-00-00 00:00:00" ? null : dayjs(each.departure_time)
      each.arrival_time = each.arrival_time === "0000-00-00 00:00:00" ? null : dayjs(each.arrival_time)
      details.push(each)
    }
    )
    setDataSource(details)
    props.record.employment_id = props.record.requestor.full_name_code
    form.setFieldsValue(props.record)
    setLoading(false)
  }

  //===========constents =============
  const onCreateConsent = () => {
    setCreateConsentModal(<CreateConsent onCreatedConsent={ onCreatedConsent } employment_id={ props.record.employment_id } request_id={ props.record.id } request_as={ props.requestAsId } api="travelApprovals/createConsent" />)
  }
  const onCreatedConsent = (response) => {
    if (response) {
      message.success("Consent created successfully");
      props.onCompleted(response.travels.data);
    }
    setCreateConsentModal(null)
  }
  const onConsentChat = (record) => {
    setCreateConsentModal(<CreateConsentChat record={ record } onCreatedConsent={ onCreatedConsent } request_as={ props.requestAsId } api="travelApprovals/messageConsent" />)
  }
  //============end consents================================
  const onSubmit = (isApproval) => {
    const last_remarks = form.getFieldValue('last_remarks')
    let obj = { ...props.record, current_history: { ...props.record.current_history, remarks: last_remarks }, last_remarks: last_remarks, remarks: last_remarks, request_as: props.requestAsId, is_approval: isApproval };

    const payload = { request: obj, delegator_id: props.delegatorId, api: props.api, filters: props.filters, }
    makeRequest(setLoader, approveRequest, payload, onSuccess, onError);
    setSaveLoader(true)
  }

  const onSuccess = (data, res) => {
    message.success("Record Updated");
    props.onCompleted(data.travels.data);
  }

  const onError = (err, res) => {
    setSaveLoader(false)
    message.error(res.response.data.message);
  }
  const onCompleted = (data) => {
    setChildComponent(null)
  }
  //===============details============
  const onViewDetails = (record) => {
    const requiredData = { travel_types: props.travel_types, travel_modes: props.travel_modes, stay_types: props.stay_types }
    setChildComponent(<UpdateDetails onCompleted={ onCompleted } disabled={ true } requiredData={ requiredData } dataSource={ dataSource } record={ record } />)
  }
  //-----------------------

  const consentColumns = [
    {
      key: 'consentor_name',
      title: trans(`${langKey}.consentor_name`),
      dataIndex: 'consentor_name'
    },
    {
      key: 'consentee_name',
      title: trans(`${langKey}.consentee_name`),
      dataIndex: 'consentee_name'
    },
    {
      key: 'consent_date',
      title: trans(`${langKey}.consent_date`),
      render: (record) => {
        return record.created_at ? moment(record.created_at).format('DD-MM-YYYY h:mm:ss a') : null
      }
    },
    {
      key: trans(Commons.actions),
      title: trans(Commons.actions),
      render: (record) => (
        <ActionComponent
          each={ record } modkey={props.modkey}
          onView={ onConsentChat }
        ></ActionComponent>
      ),
    },
  ]

  //details columns
  const columns = [
    {
      key: 'from',
      title: trans(`${langKey}.from`),
      dataIndex: "from",
    },
    {
      key: 'to',
      title: trans(`${langKey}.to`),
      dataIndex: "to",
    },
    {
      key: 'departure_date',
      title: trans(`${langKey}.departure_date`),
      render: (record) => (
        dayjs(record.departure_datetime).format('YYYY-MM-DD HH:mm a')
      ),
    },
    {
      key: 'arrival_date',
      title: trans(`${langKey}.arrival_date`),
      render: (record) => (
        dayjs(record.arrival_datetime).format('YYYY-MM-DD HH:mm a')
      ),
    },
    {
      key: trans(Commons.actions),
      title: trans(Commons.actions),
      render: (record) => (
        <ActionComponent
          each={ record } modkey={props.modkey}
          onView={ onViewDetails }
        >
        </ActionComponent>
      ),
    },
  ]
  //============uploading file============
  const [fileList, setFileList] = useState([]);

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [
    childComponent,
    <div style={ { float: 'left' } }>
      < FlowHistoryButton record={ props.record } setChildComponent={ setChildComponent } />
    </div>,
    props.disabled ? '' :
      <SaveButton disabled={ props.disabled } loading={ saveLoader } form={ formName } key="create_button" onClick={ () => onSubmit(true) } text='Approve' />,
    <CancelButton disabled={ props.disabled } loading={ saveLoader } key="reject_button" form={ formName } htmlType='submit' text='Reject' onClick={ () => onSubmit(false) } />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } width={ 1200 } maskClosable={ false }
      mainTitle={ trans(props.disabled ? Commons.view : Commons.update) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Skeleton loading={ loading }>
        <EmployeeInfo record={ props.record.requestor && props.record.requestor } />
        <Form { ...formLayout } name={ formName } onFinish={ onSubmit } form={ form } disabled={ true } >
          <h5 className="formlist-title">Travel Request</h5>
          <Form.Item name="purpose" rules={ rules.purpose } label={ trans(`${langKey}.purpose`) }
            className="da-mb-16"
            { ...getErrorProps(errors['purpose']) } size="small"
          >
            <InputTextArea placeholder={ trans(`${langKey}.purpose`) } />
          </Form.Item>
          <Form.Item name="extension_no" rules={ rules.extension_no } label={ trans(`${langKey}.extension_no`) }
            className="da-mb-16"
            { ...getErrorProps(errors['extension_no']) } size="small"
          >
            <InputText type="number" placeholder="extension_no" />
          </Form.Item>
          <Form.Item name="alternative_phone_no" rules={ rules.alternative_phone_no } label={ trans(`${langKey}.alternative_phone_no`) }
            className="da-mb-16"
            { ...getErrorProps(errors['alternative_phone_no']) } size="small"
          >
            <InputText type="number" placeholder={ trans(`${langKey}.alternative_phone_no`) } />
          </Form.Item>

          <Form.Item name="description" rules={ rules.description } label={ trans(`${langKey}.description`) }
            className="da-mb-16"
            { ...getErrorProps(errors['description']) } size="small"
          >
            <InputTextArea placeholder={ trans(`${langKey}.reason`) } />
          </Form.Item>

          <Form.Item name="is_advance_required" rules={ rules.is_advance_required } label={ trans(`${langKey}.is_advance_required`) }
            className="da-mb-16"
            { ...getErrorProps(errors['is_advance_required']) } size="small"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
        </Form>
        <Divider>Details</Divider>

        <TableComponent disabled={ false }
          columns={ columns }
          dataSource={ dataSource }
        />

        { childComponent }

        <Divider>Approval</Divider>
        <Form.Item name="last_remarks" rules={ rules.last_remarks } label={ trans(`${langKey}.last_remarks`) }
          className="da-mb-16"
          { ...getErrorProps(errors['last_remarks']) } size="small"

        >

          <InputTextArea placeholder={ trans(`${langKey}.last_remarks`) } disabled={ props.disabled } onChange={ (e) => setRemarks(e.target.value) } defaultValue={ props.disabled ? props.record.last_remarks : '' } />
          <p className="d-none">.</p>
        </Form.Item>

        <h5 className="formlist-title">Consents <Button type="primary" title='Create' onClick={ onCreateConsent } className="float-end me-5">Create</Button></h5>
        { creatConsentModal }
        <TableComponent dataSource={ consents } columns={ consentColumns } />

      </Skeleton>

    </ModalComponent>
  )
}

export default UpdateTravelApproval

const rules = {
  name: [
    { required: true, message: '' },
  ],

};

const formLayout = {
  // layout: "horizontal",
  labelCol: { span: 6 },
  wrapperCol: { span: 14, offset: 2 },
  labelAlign: "left"
}
