import React, { useEffect, useState } from "react";
import { Button, Steps, message, Space, Row } from "antd";
import { SaveButton, CancelButton, ModalComponent, trans } from "@comps/components"
import { makeRequest, makeRequestStateless } from "@utils/helpers";
import { createDataReq, getCompanyRelatedDataReq, deleteDataReq } from "../requests";
import { Const } from "../../constants";
import StepOneDelete from "../../common/StepOneDelete";
import FilterSelectEmployees from "@mods/common/FilterSelectEmployees";
//const moment = require('moment');
const langKey = Const["companyRoaster"].lngKey
const formName = "createCompanyRoaster";

const DeleteCompanyRoaster = (props) => {

  //========initial child states===============

  //============================
  const [loader, setLoader] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [companyRelatedData, setCompanyRelatedData] = useState([]);
  const [selectedEmployments, setEmployments] = useState([]);
  const [previewEmployment, setPreviewEmployments] = useState([]);
  const [dates, setDates] = useState([]);
  //------------intitial steps state---------
  const [firstStepData, setFirstStepData] = useState(null);
  const [currentStep, setCurrent] = useState(0);

  const [preEmployeesFilter, setPreEmployeesFilter] = useState({})
  const [unSelectedEmployees, setUnSelectedEmployees] = useState([])
  const [selectedEmployees, setSelectedEmployees] = useState([])
  //=======================================
  //=======================================
  useEffect(() => {
    getRemoteData()
    setData()
    // eslint-disable-next-line
  }, []);
  const getRemoteData = () => {
    makeRequestStateless(getCompanyRelatedDataReq, null, ongetCompanyRelatedDataReq, null);
  }
  const ongetCompanyRelatedDataReq = (response) => {
    setCompanyRelatedData(response)
  }
  const setData = () => {
    let emp_ids = '';
    if (props.employments) {
      props.employments.map((each) => (
        emp_ids = `${each.id},`
      ))
    }
  }
  //==============================Save all Data=========================================
  const onSubmit = (firstStepData) => {
    setSaveLoader(true)
    const payload = {
      shift: {
        delete_all_employees: firstStepData.delete_all_employees,
        dates: dates,
        employment_ids: selectedEmployments.employment_ids,
      },
    };
    makeRequest(setLoader, createDataReq, payload, onSuccess, onError);
  }

  const onSuccess = (data, res) => {
    setSaveLoader(false)
    if (data.errors.length > 0) {
      message.error(`${data.errors[0].reason}`);
      return;
    }
    else {
      message.success("Deleted successfully");
    }
    props.onCreated(data);
  }
  const onError = (err, res) => {
    message.error(res.response.data.message);
  }
  //=================Submit First Step 1==============================================
  const getStepOneData = (data) => {
    if (data.delete_all_employees !== undefined && data.delete_all_employees === true) {
      let emp_ids = '';
      if (props.employments) {
        props.employments.map((each) => (
          emp_ids += `${each.id},`
        ))
      }
      const payload = {
        shifts: {
          delete_all_employees: data.delete_all_employees,
          start_date: data.start_date ? data.start_date.format('YYYY-MM-DD') : '',
          end_date: data.end_date ? data.end_date.format('YYYY-MM-DD') : '',
        },
        emp_ids: emp_ids
      };
      makeRequest(setLoader, deleteDataReq, payload, onSuccess, onError);
    }
    else {
      setFirstStepData(data);
      setCurrent(currentStep + 1);
    }

  }
  // ------------------------------------
  //=====================On Submit Second Step===========================
  const setSelectedEmploymentsIds = (employments) => {
    setEmployments(employments)
    let empIds = ''
    employments.employment_ids.map((each) => (
      empIds += `${each},`
    ))
    const payload = {
      shifts: {
        delete_all_employees: firstStepData.delete_all_employees,
        start_date: firstStepData.start_date ? firstStepData.start_date.format('YYYY-MM-DD') : '',
        end_date: firstStepData.end_date ? firstStepData.end_date.format('YYYY-MM-DD') : '',
        employment_ids: employments.employment_ids,
      },
      emp_ids: empIds
    };
    makeRequest(setLoader, deleteDataReq, payload, onSuccess, onError);
  }
  const onCancel = () => {
    props.onCreated(false);
  }
  const onBack = () => {
    setCurrent(currentStep - 1)
  }
  //================================Main Modal Component==================================
  return (
    <ModalComponent width={ 1200 } top={ 20 } maskClosable={ false }
      mainTitle={ 'Delete' } subTitle={ <>
        <p style={ { paddingLeft: 75, marginTop: -30 } }>{ trans(`${langKey}.moduleName`) }</p>
        <Steps
          className="steps-style"
          current={ currentStep }
          onChange={ setCurrent }
        >
          <Steps.Step className="steps-step4" title={ "Select Date" } style={ { paddingLeft: 100 } } />
          <Steps.Step className="steps-step5" disabled={ firstStepData ? false : true } title={ "Select Employees" } />
        </Steps>
      </> }
      visible={ true } onCancel={ () => props.onCreated(false) } footer={ '' }>

      { currentStep === 0 ?
        <StepOneDelete initialValues={ firstStepData } response={ getStepOneData } onCancel={ onCancel } isTimeslot={ false } />
        : currentStep === 1 ?
          <FilterSelectEmployees setSelectedEmploymentsIds={ setSelectedEmploymentsIds } companyRelatedData={ companyRelatedData } onCancel={ onCancel } onBack={ onBack }
            employment_ids={ selectedEmployments }
            moduleName='deleteRoaster' is_delete={ true } selected={ selectedEmployees } unSelected={ unSelectedEmployees } preFilters={ preEmployeesFilter } setSelected={ setSelectedEmployees } setUnselected={ setUnSelectedEmployees } setPrefilters={ setPreEmployeesFilter }
          />
          : '' }
      {
        currentStep === 2 ?
          <Row justify="end">
            <Space>
              <Button type="primary" onClick={ () => setCurrent(currentStep - 1) } disabled={ currentStep > 0 ? false : true }>
                Back
              </Button>
              <SaveButton loading={ saveLoader } form={ formName } key="create_button" onClick={ () => onSubmit(firstStepData) } disabled={ previewEmployment.length > 0 ? false : true } />
              <CancelButton key="close_button" onClick={ () => props.onCreated(false) } />
            </Space>
          </Row>
          : ''
      }
    </ModalComponent>
  )
}
export default DeleteCompanyRoaster
