
import TextArea from "antd/es/input/TextArea";
import { getPlaceholder } from '@comps/commonFunctions';
const BaseTextarea = (props) => {
  return (

    <TextArea
      style={ {
        width: '100%',
      } }
      { ...props }
      placeholder={ getPlaceholder(props.placeholder) }
    />

  )
}

export default BaseTextarea
