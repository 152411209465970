import { Col, Row } from "antd"

const EmployeeInfo = (props) => {

  return (
    <>

      <Row justify={ 'left' }>
        <Col span={ 6 }>
          <img style={ { borderRadius: '60px', width: 122, height: 122 } } src={ props.record.profile_pic_url !== "images/arnold-avatar.jpg" ? props.record.profile_pic_url : "/images/arnold-avatar.jpg" } alt="" className="m-2" width={ 50 } />
        </Col>
      </Row>
      <Row style={ { marginLeft: 150, marginTop: -140 } }>

        <Col span={ 24 }>
          <span style={ { fontSize: 20, fontWeight: 600 } }>{ `${props.record.full_name + '-' + props.record.emp_code}` }</span><br />
          <span style={ { fontSize: 14, fontWeight: 700, color: '#4079DC' } }>{ props.record.department_name }</span>
        </Col>
        <Col span={ 24 }>
          <img src={ require("@assets/images/Line 19.png") } alt="Employee" style={ { width: "100%" } } />
        </Col>
        <Col span={ 6 }>
          Company
        </Col>
        <Col span={ 6 }>
          { props.record.company_name }
        </Col>
        <Col span={ 6 }>
          Location
        </Col>
        <Col span={ 6 }>
          { props.record.location_name }
        </Col>
        <Col span={ 6 }>
          Branch
        </Col>
        <Col span={ 6 }>
          { props.record.branch_name }
        </Col>
        <Col span={ 6 }>
          Job Title
        </Col>
        <Col span={ 6 }>
          { props.record.jobtitle_name }
        </Col>
        <Col span={ 6 }>
          Joining_date
        </Col>
        <Col span={ 6 }>
          { props.record.joining_date }
        </Col>
      </Row>
      <br />
      <hr />
    </>
  )
}

export default EmployeeInfo
