import { get, post, postFd, put, download, delWithPayload } from "@utils/axios";

const api = "roster";

export const getAllDataReq = (payload) => {
  return get(`${api}?page=${payload.page}&filters=${payload.filters}`)//filters=employee_|id
};

export const createDataReq = (payload) => {
  return post(`${api}/create-shift-batch?page=1`, payload);
};

export const updateDataReq = (payload) => {
  return put(`${api}/${payload.id}`, payload);
};

export const deleteDataReq = (payload) => {
  return delWithPayload(`${api}/delete-shift-batch?page=1&emp_ids=${payload.emp_ids}`, payload);
};

export const getRelatedDataReq = (payload) => { //time_slots
  return get(`${api}/get-related-data`);
};

export const getCompanyRelatedDataReq = (payload) => {
  return get(`employment-picker/get-company-related-data/1`);
};

export const getTotalHoursReq = (payload) => {
  return get(`${api}/get-total-hours?page=${payload.page}&filters=${payload.filters}&emp_ids=${payload.empIds}`); //employee_|id; & emp_ids='1,2,3'
};

export const getEmploymentsDataReq = (payload) => {
  return get(`resources/employment?page=1&filters=privilege_|false;`);
};

export const getPreviewDataReq = (payload) => {
  return post(`${api}/preview`, payload);
};
export const filterEmploymentsReq = (payload) => {
  return get(`employment-picker?page=1&filters=${payload}`);
};
export const getFilterReq = (payload) => {
  return get(`filters?company_id=1&fields=page=1&filters=supporting_fields_|employments,time_slot;organization_structure_fields_|location,branch,department,group`)
}

//download log sample
export const downloadSampleReq = (payload) => {
  return download(`${api}/download-sample`)
};
