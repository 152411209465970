import React, { useEffect, useState } from "react";
import { Form, message, Row, Col } from "antd";
import { SaveButton, InputText, InputTextArea, InputCheck, CancelButton, ButtonComponent, ModalComponent, trans } from "@comps/components"
import { makeRequest } from "@utils/helpers";
import { createDataReq } from "../requests";
import { Commons, Const } from "../../../constants";
import { PlusCircleOutlined } from "@ant-design/icons";
import CreateExpression from "./CreateExpression";
const langKey = Const["formula"].lngKey
const formName = "createFormula";

const CreateFormula = (props) => {
  const [loader, setLoader] = useState(false);
  const [saving, setsaving] = useState(false);
  const [childComponent, setChildComponent] = useState(false);
  const [form] = Form.useForm()
  const [expression, setExpression] = useState('');
  const [isValid, setIsValid] = useState(true)
  useEffect(() => {
    // eslint-disable-next-line
  }, []);

  const onSubmit = (data) => {
    if (expression === '' || expression === null) {
      message.error('Please enter a valid expression')
    }

    setsaving(true)
    const payload = {
      formula: {
        name: data.name,
        expression: expression,
        is_salary_division: data.is_salary_division,
        description: data.description,
        company_id: 1
      }
    };
    makeRequest(setLoader, createDataReq, payload, onSuccess, onError);
  }

  const onSuccess = (data, res) => {

    message.success(res.message);
    props.onCompleted(data.formulas.data);
    props.onSuccess(data)
  }

  const onError = (err, res) => {

    setsaving(false)
    message.error(res.response.data.message);
  }

  const onCreateExpression = () => {
    const options = {
      exclude: null,
      formulas: true,
      payitems: true,
      rules: null
    }
    setChildComponent(<CreateExpression onCompleted={ onCompleted } record={ expression } isValid={ isValid } options={ options } />)
  }

  const onCompleted = (data, isValid) => {
    if (data) {
      setExpression(data)
      setIsValid(isValid)
    }

    setChildComponent(null)
  }
  //-----------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [

    <SaveButton loading={ saving } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } width={ 1000 } maskClosable={ false }
      mainTitle={ trans(Commons.create) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      { childComponent }
      <Form { ...formLayout } name={ formName } onFinish={ onSubmit } form={ form } >
        <Row gutter={ 30 }>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="name" rules={ rules.name } label={ trans(Commons.name) }
              className="da-mb-16"
            >
              <InputText placeholder={ trans(`${langKey}.name`) } />
            </Form.Item>
          </Col>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item style={ { paddingRight: 35 } } name="expression" label={ trans(`${langKey}.expression`) }
              className="da-mb-16"
            >
              <InputTextArea placeholder={ trans(`${langKey}.expression`) } disabled={ true } value={ expression } /><ButtonComponent className="formula-btn" onClick={ onCreateExpression } icon={ <PlusCircleOutlined style={ { marginLeft: -7 } } /> } />
            </Form.Item>
          </Col>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="is_salary_division" rules={ rules.is_salary_division } label={ trans(`${langKey}.is_salary_division`) }
              className="da-mb-16"
              valuePropName="checked"
            >
              <InputCheck />
            </Form.Item>
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="description" rules={ rules.description } label={ trans(`${langKey}.description`) }
              className="da-mb-16"
            >
              <InputTextArea placeholder={ trans(`${langKey}.description`) } />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </ModalComponent>
  )
}

export default CreateFormula

const rules = {
  name: [
    { required: true, message: trans(`${langKey}.name`) },
  ],
  expression: [
    { required: true, message: trans(`${langKey}.expression`) },
  ],
};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}