import React, { useEffect, useState } from "react";
import { Button, Checkbox, Col, Divider, Form, Row, Skeleton, message } from "antd";
import { SaveButton, InputText, CancelButton, ModalComponent, trans, SelectWithSearch } from "@comps/components"
import { makeRequest, makeRequestStateless, getErrorProps } from "@utils/helpers";
import { createDataReq, getReleatedDataReq } from "../requests";
import { Commons, Const } from "../../../constants";
import { createSelectList } from '@comps/commonFunctions';
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
const langKey = Const["leaveType"].lngKey
const formName = "createLeaveType";

const CreateLeaveType = (props) => {
  const [loader, setLoader] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [genders, setGenders] = useState([])
  const [tenures, setTenures] = useState([])
  const [isComposite, setIscomposite] = useState(false)
  const [isShortLeave, setIsShort] = useState(false)
  const [isSplitable, setSplitable] = useState(false)
  const [isBackDate, setIsBackDate] = useState(false)
  const [isAttach, setIsAttach] = useState(false)
  const [isEncashment, setEncashment] = useState(false)
  const [isCarryForward, setCarryForward] = useState(false)
  const [limitTenureWithin, setLimitTenureWithin] = useState(false)
  const [allLeaveTypes, setAllLeaveTypes] = useState([])
  const [saving, setsaving] = useState(false)
  const [loading, setloading] = useState(true)
  const [fromDoj,setFromDoj]=useState(false);
const [taxStructures,setTaxStructures]=useState([]);
  useEffect(() => {
    getRemoteData();
    // eslint-disable-next-line
  }, []);
  const getRemoteData = () => {
    makeRequestStateless(getReleatedDataReq, false, onGetRelated, false)
  }
  const onGetRelated = (response) => {
    setGenders(createSelectList([...response.genders, { id: 0, name: 'All' }]))
    setTenures(createSelectList(response.tenures))
    setTaxStructures(createSelectList(response.tax_structures))
    setAllLeaveTypes(createSelectList(response.all_leave_types))
    setloading(false)
  }
  const onSubmit = (data) => {
    const payload = {
      leave_type: data,
      pre_condition: {
        gender_id: data.gender_id,
        can_apply_in_probation: data.can_apply_in_probation,
        min_employee_experience: data.min_employee_experience,
        max_applications_in_employment: data.max_applications_in_employment,
        gap_between_applications: data.gap_between_applications,
        min_days_per_leave: data.min_days_per_leave,
        max_days_per_leave: data.max_days_per_leave,
        can_apply_for_remaining: data.can_apply_for_remaining,
        has_max_limit: data.has_max_limit,
        limit_tenure_id: data.limit_tenure_id,
        amount_apply_in_tenure: data.amount_apply_in_tenure,
      }
    };
    makeRequest(setLoader, createDataReq, payload, onSuccess, onError);
    setsaving(true)
  }

  const onSuccess = (data, res) => {
    setSaveLoader(true)
    message.success("Record Saved");
    props.onCompleted(data.leave_types.data);
    props.onSuccess(data)
  }

  const onError = (err, res) => {
    setsaving(false)
    message.error(res.response.data.message);
  }

  //-----------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [
    <SaveButton loading={ saving } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } width={ 1000 } maskClosable={ false }
      mainTitle={ trans(Commons.create) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Skeleton loading={ loading }>
        <Form { ...formLayout } name={ formName } onFinish={ onSubmit } scrollToFirstError>
          <Row gutter={ 30 }>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="name" rules={ rules.name } label={ trans(Commons.name) }
                className="da-mb-16"
                { ...getErrorProps(errors['name']) } size="small"
              >
                <InputText placeholder={ trans(`${langKey}.name`) } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="short_name" rules={ rules.short_name } label={ trans(`${langKey}.short_name`) }
                className="da-mb-16"
                { ...getErrorProps(errors['short_name']) } size="small"
              >
                <InputText placeholder={ trans(`${langKey}.short_name`) } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="is_active" rules={ rules.is_active } label={ trans(`${langKey}.is_active`) }
                className="da-mb-16"
                { ...getErrorProps(errors['is_active']) } size="small"
                valuePropName="checked"
              >
                <Checkbox />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="is_auto_allotment" rules={ rules.is_auto_allotment } label={ trans(`${langKey}.is_auto_allotment`) }
                className="da-mb-16"
                { ...getErrorProps(errors['is_auto_allotment']) } size="small"
                valuePropName="checked"
              >
                <Checkbox />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="is_from_doj" rules={ rules.balance_on_joining } label={ trans(`${langKey}.balance_on_joining`) }
                className="da-mb-16"
                { ...getErrorProps(errors['balance_on_joining']) } size="small"
                valuePropName="checked"
              >
                <Checkbox onChange={(e)=>setFromDoj(e.target.checked)}/>
              </Form.Item>
            </Col>
            {
              !fromDoj &&   <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="tax_structure_id" rules={ rules.tax_structure_id } label={ trans(`${langKey}.tax_structure_id`) }
                className="da-mb-16"
              size="small"
                
              >
                <SelectWithSearch options={taxStructures} placeholder={ trans(`${langKey}.tax_structure_id`) }/>
              </Form.Item>
            </Col>
            }
          

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="is_composite" rules={ rules.is_composite } label={ trans(`${langKey}.is_composite`) }
                className="da-mb-16"
                { ...getErrorProps(errors['is_composite']) } size="small"
                valuePropName="checked"
              >
                <Checkbox onChange={ (e) => setIscomposite(e.target.checked) } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="is_short" rules={ rules.is_short } label={ trans(`${langKey}.is_short`) }
                className="da-mb-16"
                { ...getErrorProps(errors['is_short']) } size="small"
                valuePropName="checked"
              >
                <Checkbox onChange={ (e) => setIsShort(e.target.checked) } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="is_deductible" rules={ rules.is_deductible } label={ trans(`${langKey}.is_deductible`) }
                className="da-mb-16"
                { ...getErrorProps(errors['is_deductible']) } size="small"
                valuePropName="checked"
              >
                <Checkbox />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="sandwich" rules={ rules.sandwich } label={ trans(`${langKey}.sandwich`) }
                className="da-mb-16"
                { ...getErrorProps(errors['sandwich']) } size="small"
                valuePropName="checked"
              >
                <Checkbox />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="splitable" rules={ rules.splitable } label={ trans(`${langKey}.splitable`) }
                className="da-mb-16"
                { ...getErrorProps(errors['splitable']) } size="small"
                valuePropName="checked"
              >
                <Checkbox onChange={ (e) => setSplitable(e.target.checked) } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="prorated" rules={ rules.prorated } label={ trans(`${langKey}.prorated`) }
                className="da-mb-16"
                { ...getErrorProps(errors['prorated']) } size="small"
                valuePropName="checked"
              >
                <Checkbox />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="add_earned_quota" rules={ rules.add_earned_quota } label={ trans(`${langKey}.add_earned_quota`) }
                className="da-mb-16"
                { ...getErrorProps(errors['add_earned_quota']) } size="small"
                valuePropName="checked"
              >
                <Checkbox defaultChecked />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="is_back_date_allowed" rules={ rules.is_back_date_allowed } label={ trans(`${langKey}.is_back_date_allowed`) }
                className="da-mb-16"
                { ...getErrorProps(errors['is_back_date_allowed']) } size="small"
                valuePropName="checked"
              >
                <Checkbox onChange={ (e) => setIsBackDate(e.target.checked) } />
              </Form.Item>
            </Col>
            {
              isBackDate ?
                <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                  <Form.Item name="days_back_date_allowed" rules={ rules.days_back_date_allowed } label={ trans(`${langKey}.days_back_date_allowed`) }
                    className="da-mb-16"
                    { ...getErrorProps(errors['days_back_date_allowed']) } size="small"
                  >
                    <InputText placeholder={ trans(`${langKey}.days_back_date_allowed`) } type="number" />
                  </Form.Item>
                </Col>
                : ''
            }

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="is_attachment_required" rules={ rules.is_attachment_required } label={ trans(`${langKey}.is_attachment_required`) }
                className="da-mb-16"
                { ...getErrorProps(errors['is_attachment_required']) } size="small"
                valuePropName="checked"
              >
                <Checkbox onChange={ (e) => setIsAttach(e.target.checked) } />
              </Form.Item>
            </Col>
            {
              isAttach ?
                <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                  <Form.Item name="days_attachment_required" rules={ rules.days_attachment_required } label={ trans(`${langKey}.days_attachment_required`) }
                    className="da-mb-16"
                    { ...getErrorProps(errors['days_attachment_required']) } size="small"
                    valuePropName="checked"
                  >
                    <InputText placeholder={ trans(`${langKey}.days_attachment_required`) } type="number" />
                  </Form.Item>
                </Col>
                : ''
            }

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="encashment" rules={ rules.encashment } label={ trans(`${langKey}.encashment`) }
                className="da-mb-16"
                { ...getErrorProps(errors['encashment']) } size="small"
                valuePropName="checked"
              >
                <Checkbox onChange={ (e) => setEncashment(e.target.checked) } />
              </Form.Item>
            </Col>

            {
              isEncashment ?
                <>
                  <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                    <Form.Item name="encashment_min" rules={ rules.encashment_min } label={ trans(`${langKey}.encashment_min`) }
                      className="da-mb-16"
                      { ...getErrorProps(errors['encashment_min']) } size="small"
                    >
                      <InputText placeholder={ trans(`${langKey}.encashment_min`) } type="number" />
                    </Form.Item>
                  </Col>

                  <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                    <Form.Item name="encashment_max" rules={ rules.encashment_max } label={ trans(`${langKey}.encashment_max`) }
                      className="da-mb-16"
                      { ...getErrorProps(errors['encashment_max']) } size="small"
                    >
                      <InputText placeholder={ trans(`${langKey}.encashment_max`) } type="number" />
                    </Form.Item>
                  </Col>
                </>
                : ''
            }

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="carry_forward" rules={ rules.carry_forward } label={ trans(`${langKey}.carry_forward`) }
                className="da-mb-16"
                { ...getErrorProps(errors['carry_forward']) } size="small"
                valuePropName="checked"
              >
                <Checkbox onChange={ (e) => setCarryForward(e.target.checked) } />
              </Form.Item>
            </Col>
            { isCarryForward ?
              <>
                <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                  <Form.Item name="carry_forward_min" rules={ rules.carry_forward_min } label={ trans(`${langKey}.carry_forward_min`) }
                    className="da-mb-16"
                    { ...getErrorProps(errors['carry_forward_min']) } size="small"
                  >
                    <InputText placeholder={ trans(`${langKey}.carry_forward_min`) } type="number" />
                  </Form.Item>
                </Col>

                <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                  <Form.Item name="carry_forward_max" rules={ rules.carry_forward_max } label={ trans(`${langKey}.carry_forward_max`) }
                    className="da-mb-16"
                    { ...getErrorProps(errors['carry_forward_max']) } size="small"
                  >
                    <InputText placeholder={ trans(`${langKey}.carry_forward_max`) } type="number" />
                  </Form.Item>
                </Col>

                <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                  <Form.Item name="carry_forward_years" rules={ rules.carry_forward_years } label={ trans(`${langKey}.carry_forward_years`) }
                    className="da-mb-16"
                    { ...getErrorProps(errors['carry_forward_years']) } size="small"
                  >
                    <InputText placeholder={ trans(`${langKey}.carry_forward_years`) } type="number" />
                  </Form.Item>
                </Col>
              </>
              : '' }
          </Row>

          <Divider>Pre Conditions</Divider>
          <Row gutter={ 30 }>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="gender_id" rules={ rules.gender_id } label={ trans(`${langKey}.gender_id`) }
                className="da-mb-16"
                { ...getErrorProps(errors['gender_id']) } size="small"
                initialValue={0}
              >
                <SelectWithSearch
                  defaultValue={ 0 }
                  placeholder={ trans(`${langKey}.gender_id`) }
                  options={ genders }
                />
              </Form.Item>
            </Col>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="can_apply_in_probation" rules={ rules.can_apply_in_probation } label={ trans(`${langKey}.can_apply_in_probation`) }
                className="da-mb-16"
                { ...getErrorProps(errors['can_apply_in_probation']) } size="small"
                valuePropName="checked"
              >
                <Checkbox />
              </Form.Item>
            </Col>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="min_employee_experience" rules={ rules.min_employee_experience } label={ trans(`${langKey}.min_employee_experience`) }
                className="da-mb-16"
                { ...getErrorProps(errors['min_employee_experience']) } size="small"
              >
                <InputText type='number' placeholder={ trans(`${langKey}.min_employee_experience`) } />
              </Form.Item>
            </Col>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="max_applications_in_employment" rules={ rules.max_applications_in_employment } label={ trans(`${langKey}.max_applications_in_employment`) }
                className="da-mb-16"
                { ...getErrorProps(errors['max_applications_in_employment']) } size="small"
              >
                <InputText type='number' placeholder={ trans(`${langKey}.max_applications_in_employment`) } />
              </Form.Item>
            </Col>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="gap_between_applications" rules={ rules.gap_between_applications } label={ trans(`${langKey}.gap_between_applications`) }
                className="da-mb-16"
                { ...getErrorProps(errors['gap_between_applications']) } size="small"
              >
                <InputText type='number' placeholder={ trans(`${langKey}.gap_between_applications`) } />
              </Form.Item>
            </Col>
          </Row>

          <Divider>Balance Settings</Divider>
          <Row gutter={ 30 }>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="tenure_id" rules={ rules.tenure_id } label={ trans(`${langKey}.tenure_id`) }
                className="da-mb-16"
                { ...getErrorProps(errors['tenure_id']) } size="small"
              >
                <SelectWithSearch
                  placeholder={ trans(`${langKey}.tenure_id`) }
                  options={ tenures }
                />
              </Form.Item>
            </Col>

            { !isComposite ?
              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="acc_count" rules={ rules.acc_count } label={ trans(`${langKey}.acc_count`) }
                  className="da-mb-16"
                  { ...getErrorProps(errors['acc_count']) } size="small"
                >
                  <InputText type='number' placeholder={ trans(`${langKey}.acc_count`) } />
                </Form.Item>
              </Col>
              : '' }

            { isSplitable ?
              <>
                <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                  <Form.Item name="min_days_per_leave" rules={ rules.min_days_per_leave } label={ trans(`${langKey}.min_days_per_leave`) }
                    className="da-mb-16"
                    { ...getErrorProps(errors['min_days_per_leave']) } size="small"
                  >
                    <InputText placeholder={ trans(`${langKey}.min_days_per_leave`) } type='number' />
                  </Form.Item>
                </Col>
                <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                  <Form.Item name="max_days_per_leave" rules={ rules.max_days_per_leave } label={ trans(`${langKey}.max_days_per_leave`) }
                    className="da-mb-16"
                    { ...getErrorProps(errors['max_days_per_leave']) } size="small"
                  >
                    <InputText placeholder={ trans(`${langKey}.max_days_per_leave`) } type='number' />
                  </Form.Item>
                </Col>
                <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                  <Form.Item name="can_apply_for_remaining" rules={ rules.can_apply_for_remaining } label={ trans(`${langKey}.can_apply_for_remaining`) }
                    className="da-mb-16"
                    { ...getErrorProps(errors['can_apply_for_remaining']) } size="small"
                    valuePropName="checked"
                  >
                    <Checkbox />
                  </Form.Item>
                </Col>

                <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                  <Form.Item name="has_max_limit" rules={ rules.has_max_limit } label={ trans(`${langKey}.has_max_limit`) }
                    className="da-mb-16"
                    { ...getErrorProps(errors['has_max_limit']) } size="small"
                    valuePropName="checked"
                  >
                    <Checkbox onChange={ (e) => setLimitTenureWithin(e.target.checked) } />
                  </Form.Item>
                </Col>
                { limitTenureWithin ?
                  <>
                    <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                      <Form.Item name="limit_tenure_id" rules={ rules.limit_tenure_id } label={ trans(`${langKey}.limit_tenure_id`) }
                        className="da-mb-16"
                        { ...getErrorProps(errors['limit_tenure_id']) } size="small"
                      >
                        <SelectWithSearch
                          placeholder={ trans(`${langKey}.limit_tenure_id`) }
                          options={ tenures }
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                      <Form.Item name="amount_apply_in_tenure" rules={ rules.amount_apply_in_tenure } label={ trans(`${langKey}.amount_apply_in_tenure`) }
                        className="da-mb-16"
                        { ...getErrorProps(errors['amount_apply_in_tenure']) } size="small"
                      >
                        <InputText placeholder={ trans(`${langKey}.amount_apply_in_tenure`) } type="number" />
                      </Form.Item>
                    </Col>
                  </>
                  : '' }
              </>
              : '' }
          </Row>

          { isComposite ?
            <>
              <div className="formlist-container">
                <h5 className="formlist-title">Composite Leave Settings</h5>
                <Form.List name="composite_leave_types" >
                  { (fields, { add, remove }) => (
                    <>
                      <Button onClick={ () => {
                        add()
                        //setRows(fields.length, 'Added')
                      } } icon={ <PlusOutlined /> } type="primary" className="add-node-button">Add </Button>
                      <div className="table-container">
                        <table className="table">
                          {
                            fields.length !== 0 && <thead>
                              <tr>
                                <th style={ { paddingLeft: 20 } }>{ trans(`${langKey}.leave_type_id`) }</th>
                                <th>{ trans(Commons.actions) }</th>
                              </tr>
                            </thead>
                          }
                          <tbody>
                            { fields.map(({ key, name, ...restField }) => (
                              <>
                                <tr>
                                  <td style={ { minWidth: '200px', paddingLeft: 20 } }>
                                    <Form.Item
                                      { ...restField }
                                      name={ [name, 'leave_type_id'] }
                                      rules={ rules.leave_type_id }
                                    >
                                      <SelectWithSearch
                                        options={ allLeaveTypes }
                                        placeholder={ trans(`${langKey}.leave_type_id`) } />
                                    </Form.Item>
                                  </td>

                                  <td>
                                    <DeleteOutlined className="formlist-remove" onClick={ () => {
                                      remove(name)
                                      //setRows(key, 'Deleted')
                                    } } />
                                  </td>
                                </tr>
                              </>
                            )) }
                          </tbody>
                        </table>
                      </div>
                    </>
                  ) }
                </Form.List>
              </div>
            </>
            : '' }

          { isShortLeave ?
            <>
              <div className="formlist-container">
                <h5 className="formlist-title">Short Leave Slabs</h5>
                <Form.List name="short_leave_type_slabs" >
                  { (fields, { add, remove }) => (
                    <>
                      <Button onClick={ () => {
                        add()
                        //setRows(fields.length, 'Added')
                      } } icon={ <PlusOutlined /> } type="primary" className="add-node-button">Add </Button>
                      <div className="table-container">
                        <table className="table">
                          {
                            fields.length !== 0 && <thead>
                              <tr>
                                <th style={ { paddingLeft: 20 } }>{ trans(`${langKey}.leave_type_id`) }</th>
                                <th>{ trans(`${langKey}.quota`) }</th>
                                <th>{ trans(`${langKey}.type`) }</th>
                                <th>{ trans(`${langKey}.value`) }</th>
                                <th>{ trans(Commons.actions) }</th>
                              </tr>
                            </thead>
                          }
                          <tbody>
                            { fields.map(({ key, name, ...restField }) => (
                              <>
                                <tr>
                                  <td style={ { minWidth: '200px', paddingLeft: 20 } }>
                                    <Form.Item
                                      { ...restField }
                                      name={ [name, 'name'] }
                                      rules={ rules.name }
                                    >
                                      <InputText placeholder={ trans(`${langKey}.name`) } />
                                    </Form.Item>
                                  </td>
                                  <td style={ { minWidth: '200px' } }>
                                    <Form.Item
                                      { ...restField }
                                      name={ [name, 'quota'] }
                                      rules={ rules.quota }
                                    >
                                      <InputText placeholder={ trans(`${langKey}.Balance`) } type="number" />
                                    </Form.Item>
                                  </td>
                                  <td style={ { minWidth: '200px' } }>
                                    <Form.Item
                                      { ...restField }
                                      name={ [name, 'type'] }
                                      rules={ rules.type }
                                    >
                                      <SelectWithSearch
                                        options={ [
                                          {
                                            label: 'Percentage',
                                            value: 'percentage',
                                          },
                                          {
                                            label: 'Flat',
                                            value: 'flat',
                                          }
                                        ] }
                                        placeholder={ trans(`${langKey}.type`) } />
                                    </Form.Item>
                                  </td>

                                  <td style={ { minWidth: '200px' } }>
                                    <Form.Item
                                      { ...restField }
                                      name={ [name, 'value'] }
                                      rules={ rules.value }
                                    >
                                      <InputText placeholder={ trans(`${langKey}.value`) } type="number" />
                                    </Form.Item>
                                  </td>

                                  <td>
                                    <DeleteOutlined className="formlist-remove" onClick={ () => {
                                      remove(name)
                                      //setRows(key, 'Deleted')
                                    } } />
                                  </td>
                                </tr>
                              </>
                            )) }
                          </tbody>
                        </table>
                      </div>
                    </>
                  ) }
                </Form.List>
              </div>
            </>
            : '' }

        </Form>
      </Skeleton>

    </ModalComponent>
  )
}

export default CreateLeaveType

const rules = {
  name: [
    { required: true, message: trans(`${langKey}.name`) },
  ],
  short_name: [
    { required: true, message: trans(`${langKey}.short_name`) },
  ],
  gender_id: [
    { required: true, message: trans(`${langKey}.gender_id`) },
  ],
  tenure_id: [
    { required: true, message: trans(`${langKey}.tenure_id`) },
  ],
  acc_count: [
    { required: true, message: trans(`${langKey}.acc_count`) },
  ],
  leave_type_id: [
    { required: true, message: trans(`${langKey}.leave_type_id`) },
  ],
  quota: [
    { required: true, message: trans(`${langKey}.quota`) },
  ],
  type: [
    { required: true, message: trans(`${langKey}.type`) },
  ],
  value: [
    { required: true, message: trans(`${langKey}.value`) },
  ],
  tax_structure_id: [
    { required: true, message: trans(`${langKey}.tax_structure_id`) },
  ],

};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}