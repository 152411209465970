import React from "react";
import { Route, Routes } from "react-router-dom";
import IndexCompanies from "./companies/IndexCompanies";
import IndexLocations from './locations/IndexLocations';
import IndexAreas from './areas/IndexAreas';
import IndexBranches from './branches/IndexBranches';
import IndexDepartments from './departments/IndexDepartments';
import IndexGrades from './grades/IndexGrades';
import IndexDesignations from './designations/IndexDesignations';
import IndexJobTitles from './jobTitles/IndexJobTitles';
import Indexgroups from './groups/IndexGroups';
import IndexOrgChart from "./orgchart/IndexOrgChart";


const OrgnizationModules = () => {

  return (
    <Routes>
      <Route path="companies" element={ <IndexCompanies modkey="companies"/> } />
      <Route path="locations" element={ <IndexLocations modkey="locations"/> } />
      <Route path="areas" element={ <IndexAreas modkey="areas"/> } />
      <Route path="branches" element={ <IndexBranches modkey="branches"/> } />
      <Route path="departments" element={ <IndexDepartments modkey="departments"/> } />
      <Route path="grades" element={ <IndexGrades modkey="grades"/> } />
      <Route path="designations" element={ <IndexDesignations modkey="designations"/> } />
      <Route path="jobtitles" element={ <IndexJobTitles modkey="jobTitles"/> } />
      <Route path="groups" element={ <Indexgroups modkey="groups"/> } />
      <Route path="organization-structure" element={ <IndexOrgChart /> } />

    </Routes>
  );
}

export default OrgnizationModules;
