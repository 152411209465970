import { get, del, post, put } from "@utils/axios";

const api = "probationRequest";

export const getAllDataReq = (payload) => {
  return get(`${api}?page=${payload.page}&filters=${payload.filters}`)
};

export const createDataReq = (payload) => {
  return post(`${api}?`, payload);
};

export const updateDataReq = (payload) => {
  return put(`${api}/${payload.id}`, payload);
};

export const deleteDataReq = (id) => {
  return del(`${api}/${id}`);
};

export const getFilterReq = (payload) => {
  return get(`filters?company_id=1&fields=page=1&filters=supporting_fields_|employments,request_status;organization_structure_fields_|location,branch,department;`)
}

export const getEmploymentsReq = (payload) => {
  return get(`resources/employment?page=1&filters=privilege_|false;`);
};

export const getRelatedDatareq = (id) => {
  return get(`${api}/relatedData`);
};
