import React, { useEffect, useState } from "react";
import { Divider, Form } from "antd";
import { SaveButton, InputText, CancelButton, ModalComponent, trans, SelectWithSearch } from "@comps/components"
import { makeRequestStateless, getErrorProps } from "@utils/helpers";
import { getCitiesByStateReq, getStatesByCountryReq } from "../requests";
import { Commons, Const } from "../../constants";
import { createSelectList } from '@comps/commonFunctions';
const langKey = Const["holiday"].lngKey
const formName = "createHolidayRule";

const UpdateHolidayRule = (props) => {
  const [saveLoader, setSaveLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [isType, setistype] = useState('');
  const [ruleTypes, setruleTypes] = useState([]);
  const [religions, setreligions] = useState([]);
  const [locations, setlocations] = useState([]);
  const [branches, setbranches] = useState([]);
  const [countries, setcountries] = useState([]);
  const [states, setstates] = useState([]);
  const [cities, setcities] = useState([]);
  const [allBranches, setAllBranches] = useState([])
  const [form] = Form.useForm()
  useEffect(() => {
    getRelated();
    // eslint-disable-next-line
  }, []);


  const getRelated = () => {
    setruleTypes([{ label: props.relatedData.holiday_rule_types.religion, value: 'religion' }, { label: props.relatedData.holiday_rule_types.organization_structure, value: 'organization_structure' }, { label: props.relatedData.holiday_rule_types.geo_location, value: 'geo_location' }])
    setreligions(createSelectList(props.relatedData.religions))
    setlocations(createSelectList(props.relatedData.locations))
    onChangeLocation(props.record.location_id)
    setcountries(createSelectList(props.relatedData.countries))
    setAllBranches(props.relatedData.branches)

    form.setFieldsValue(props.record)
    setistype(props.record.type)
  }

  const onChangeLocation = (id) => {

    let newBranches = []
    props.relatedData.branches.forEach(each => {
      if (id && id === each.location_id) {
        newBranches.push(each)
      }
      else {
        form.setFieldValue('branch_id', null)
        setbranches([])
      }
    })
    setbranches(createSelectList(newBranches))

  }
  const onChangeType = (value) => {
    setistype(value)
  }

  const onChangeCountry = (id) => {
    makeRequestStateless(getStatesByCountryReq, id, ongetStatesReq, null);
  }
  const ongetStatesReq = (response) => {
    setstates(createSelectList(response.all_states))
  }
  const onChangeState = (id) => {
    makeRequestStateless(getCitiesByStateReq, id, ongetCitiesReq, null);
  }
  const ongetCitiesReq = (response) => {
    setcities(createSelectList(response.cities))

  }

  const onSubmit = (data) => {
    const obj = { ...props.record, ...data }
    props.onCompleted(obj, 'Updated')
    setSaveLoader(true)
  }

  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [
    <SaveButton loading={ saveLoader } form={ 'holidayRule' } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <>
      <ModalComponent top={ 20 } width={ 1000 } maskClosable={ false }
        mainTitle={ trans(Commons.update) } subTitle={ trans(`${langKey}.rule`) }
        visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>


        <Form { ...formLayout } name={ 'holidayRule' } onFinish={ onSubmit } form={ form }>

          <Form.Item name="name" rules={ rules.name } label={ trans(Commons.name) }
            className="da-mb-16"
            { ...getErrorProps(errors['name']) } size="small"
          >
            <InputText placeholder={ trans(`${langKey}.name`) } />
          </Form.Item>

          <Form.Item name="type" rules={ rules.type } label={ trans(`${langKey}.rule.type`) }
            className="da-mb-16"
            { ...getErrorProps(errors['type']) } size="small"
          >
            <SelectWithSearch
              placeholder={ trans(`${langKey}.rule.type`) }
              onChange={ onChangeType }
              options={ ruleTypes }
            />
          </Form.Item>
          <Divider>Fields</Divider>

          {

            isType === "religion" ?
              <Form.Item name="religion_id" rules={ rules.religion_id } label={ trans(`${langKey}.religion_id`) }
                className="da-mb-16"
                { ...getErrorProps(errors['religion_id']) } size="small"
              >
                <SelectWithSearch
                  placeholder={ trans(`${langKey}.religion_id`) }
                  options={ religions }
                />
              </Form.Item>
              :
              isType === "organization_structure" ?
                <>
                  <Form.Item name="location_id" rules={ rules.location_id } label={ trans(`${langKey}.location_id`) }
                    className="da-mb-16"
                    { ...getErrorProps(errors['location_id']) } size="small"
                  >
                    <SelectWithSearch
                      placeholder={ trans(`${langKey}.location_id`) }
                      options={ locations }
                      onChange={ onChangeLocation }
                    />
                  </Form.Item>
                  <Form.Item name="branch_id" rules={ rules.branch_id } label={ trans(`${langKey}.branch_id`) }
                    className="da-mb-16"
                    { ...getErrorProps(errors['branch_id']) } size="small"
                  >
                    <SelectWithSearch
                      placeholder={ trans(`${langKey}.branch_id`) }
                      options={ branches }
                    />
                  </Form.Item>
                </>
                :
                isType === "geo_location" ?
                  <>
                    <Form.Item name="country_id" rules={ rules.country_id } label={ trans(`${langKey}.country_id`) }
                      className="da-mb-16"
                      { ...getErrorProps(errors['country_id']) } size="small"
                    >
                      <SelectWithSearch
                        onChange={ onChangeCountry }
                        placeholder={ trans(`${langKey}.country_id`) }
                        options={ countries }
                      />
                    </Form.Item>
                    <Form.Item name="state_id" rules={ rules.state_id } label={ trans(`${langKey}.state_id`) }
                      className="da-mb-16"
                      { ...getErrorProps(errors['state_id']) } size="small"
                    >
                      <SelectWithSearch
                        onChange={ onChangeState }
                        placeholder={ trans(`${langKey}.state_id`) }
                        options={ states }
                      />
                    </Form.Item>
                    <Form.Item name="city_id" rules={ rules.city_id } label={ trans(`${langKey}.city_id`) }
                      className="da-mb-16"
                      { ...getErrorProps(errors['city_id']) } size="small"
                    >
                      <SelectWithSearch
                        placeholder={ trans(`${langKey}.city_id`) }
                        options={ cities }
                      />
                    </Form.Item>
                  </>
                  :
                  ''
          }

        </Form>
      </ModalComponent>
    </>
  )
}

export default UpdateHolidayRule

const rules = {
  name: [
    { required: true, message: trans(`${langKey}.name.req`) },
  ],
  type: [
    { required: true, message: trans(`${langKey}.rule.type.req`) },
  ],
  start: [
    { required: true, message: trans(`${langKey}.start.req`) },
  ],
  end: [
    { required: true, message: trans(`${langKey}.end.req`) },
  ],


};

const formLayout = {
  // layout: "horizontal",
  labelCol: { span: 6 },
  wrapperCol: { span: 14, offset: 2 },
  labelAlign: "left"
}