import React, { useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Button, Upload } from 'antd';
import "../layout/layouts-styles.css";
import { ModalComponent } from "@comps/components"


const BaseUploadFile = (props) => {

  const [fileList, setFileList] = useState(props.fileList !== undefined ? props.fileList : [])
  const acceptType=props.accept?props.accept:'.pdf,.jpg,.png,.jpeg';
  //============uploading file============
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const handleCancel = () => setPreviewOpen(false);
  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    props.setFileList(newFileList)
  };

  const onPreview = async (file) => {

if(file.type==="application/pdf"){
  const { originFileObj } = file;

  // Create a URL representing the Blob
  const blobUrl = URL.createObjectURL(originFileObj);

  // Open the Blob URL in a new tab
  window.open(blobUrl, '_blank');
}
    if ((file.url !== undefined) && (file.extension === 'pdf' || file.type === 'application/pdf')) {
      // Open the PDF file in a new tab
      window.open(file.url, '_blank');
    }
    else {
      if (file.type !== 'application/pdf') {
        // Handle preview for other file types (e.g., images)
        if (!file.url && !file.preview) {
          file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
      }
    }
  };
  // ------------------------------------

  return (
    <>
      <Upload
        { ...props }
        //action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
        listType="picture"
        accept={acceptType}
        fileList={ fileList }
        onChange={ onChange }
        onPreview={ onPreview }
        //defaultFileList={ [...fileList] }
        className="upload-list-inline"
        multiple={ false }
        beforeUpload={ (file) => {
          return false;
        } }
        style={{cursor:"pointer"}}
      >
        { fileList.length === 1 ? null : <Button icon={ <UploadOutlined /> }>Upload</Button> }
      </Upload>

      <ModalComponent width={ 1000 } open={ previewOpen } title={ previewTitle } footer={ null } onCancel={ handleCancel }>
        <img
          alt="example"
          style={ {
            width: '100%',
          } }
          src={ previewImage }
        />
      </ModalComponent>
    </>
  )
}


export default BaseUploadFile;