import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import SignIn from "./components/SignIn";
import ForgetPassword from "./components/ForgetPassword";
import { useSelector } from "react-redux";
import ViewProfile from "../../employeeManagement/companyEmployees/components/ViewProfile";

const AuthApp = () => {
  const onCompleted = (data) => {
    // setChildComponent(null)
  }
  // const authUser = useSelector((state) => state.auth.authUser);
  // const [childComponent, setChildComponent] = useState(authUser && <ViewProfile authUser={ authUser } record={ { id: authUser.employment_id, employee_id: authUser.employee_id } } onCompleted={ onCompleted } />)

  return (
    <>
      <Routes>
        <Route path="/" element={ <SignIn /> } />
        {/* <Route path="/signin" element={ <SignIn /> } /> */}
        <Route path="/forgetPassword" element={ <ForgetPassword /> } />
        {/* <Route path="/profile" element={ childComponent } /> */ }
      </Routes>
    </>
  )
}

export default AuthApp;
