import React, { useEffect, useState } from "react";
import { Button, Steps, message, Space, Row } from "antd";
import { SaveButton, ModalComponent, trans } from "@comps/components"
import { makeRequest, makeRequestStateless } from "@utils/helpers";
import { createDataReq, getRelatedDataReq, getCompanyRelatedDataReq } from "../requests";

import { Commons, Const } from "../../constants";
import FilterSelectEmployees from "@mods/common/FilterSelectEmployees";
import { getPreviewReq } from "@mods/common/requests";
import CreateStepOne from "./CreateStepOne";
import CreatePreview from "./CreatePreview";
const langKey = Const["attendancePolicyPlanner"].lngKey
const formName = "createCompanyRoaster";

const CreateAttendancePolicyPlanner = (props) => {
  //========initial child states===============

  //============================
  const [loader, setLoader] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [companyRelatedData, setCompanyRelatedData] = useState([]);
  const [selectedEmployments, setEmployments] = useState([]);
  const [previewEmployment, setPreviewEmployments] = useState([]);
  const [previewData, setPreviewData] = useState([]);
  //------------intitial steps state---------
  const [firstStepData, setFirstStepData] = useState(null);
  const [relatedData, setRelatedData] = useState({})
  const [currentStep, setCurrent] = useState(0);

  const [preEmployeesFilter, setPreEmployeesFilter] = useState({})
  const [unSelectedEmployees, setUnSelectedEmployees] = useState([])
  const [selectedEmployees, setSelectedEmployees] = useState([])
  //=======================================
  //=======================================
  useEffect(() => {
    getRemoteData()
    // eslint-disable-next-line
  }, []);
  const getRemoteData = () => {
    makeRequestStateless(getRelatedDataReq, null, ongetRelatedDataReq, null);
    makeRequestStateless(getCompanyRelatedDataReq, null, ongetCompanyRelatedDataReq, null);
  }
  const ongetCompanyRelatedDataReq = (response) => {
    setCompanyRelatedData(response)
  }
  const ongetRelatedDataReq = (response) => {
    setRelatedData(response)
  }
  //==============================Save all Data=========================================
  const onSubmit = () => {
    //setSaveLoader(true)
    const obj = { ...firstStepData, company_id: 1, employment_ids: selectedEmployments, date: firstStepData.date.format('YYYY-MM-DD') }
    let payload = { policy_planer: obj };
    makeRequest(setLoader, createDataReq, payload, onSuccess, onError);
  }

  const onSuccess = (data, res) => {

    setSaveLoader(false)
    if (data.errors !== undefined && data.errors.length > 0) {
      message.error(`${data.errors[0].reason}`);
      return;
    }
    else {
      message.success("Saved successfully");
    }
    props.onCompleted(data.policy_planers.data);
    props.onSuccess(data)
  }
  const onError = (err, res) => {

    message.error(res.response.data.message);
  }
  //=================Submit First Step 1==============================================
  const getStepOneData = (data, dates) => {
    setFirstStepData(data);
    if (data.for_all !== undefined && data.for_all) {
      const obj = { ...data, company_id: 1, employment_ids: selectedEmployments }
      getPreview(obj)
    }
    else {
      setCurrent(currentStep + 1)
    }
  }
  //=====================On Submit Second Step===========================
  const setSelectedEmploymentsIds = (employments) => {
    setEmployments(employments.employment_ids)
    let empIds = employments.employment_ids;
    const obj = { ...firstStepData, company_id: 1, employment_ids: empIds }
    getPreview(obj)
    setCurrent(currentStep + 1)
  }

  const getPreview = (data) => {
    let payload = { moduleName: 'policyPlaners', policy_planer: data };
    makeRequest(setLoader, getPreviewReq, payload, onGetPreview, null);
  }
  const onGetPreview = (response) => {
    if (response) {
      setPreviewData(response.policy_planer)
      setPreviewEmployments(response.policy_planer.employments)
      if (response.policy_planer.for_all !== undefined && response.policy_planer.for_all) {
        setCurrent(currentStep + 2)
      }
    }
  }
  const onCancel = () => {
    props.onCompleted(false);
  }
  const onBack = () => {
    setCurrent(currentStep - 1)
  }

  //================================Main Modal Component==================================
  return (
    <ModalComponent top={ 20 } width={ 1200 } maskClosable={ false }
      mainTitle={ trans(Commons.create) } subTitle={ <>
        <p style={ { paddingLeft: 75, marginTop: -30 } }>{ trans(`${langKey}.moduleName`) }</p>
        <Steps
          className="steps-style"
          current={ currentStep }
          onChange={ setCurrent }
        >
          <Steps.Step className="steps-step1" style={ { paddingLeft: 50 } } />
          <Steps.Step className="steps-step2" disabled={ firstStepData ? false : true } />
          <Steps.Step className="steps-step3" disabled={ firstStepData ? false : previewEmployment.length > 0 ? false : true } />
        </Steps>
      </> }
      visible={ true } onCancel={ () => props.onCompleted(false) } footer={ '' }>


      { currentStep === 0 ?
        <CreateStepOne relatedData={ relatedData } initialValues={ firstStepData } response={ getStepOneData } onCancel={ onCancel } />
        : currentStep === 1 ?
          <FilterSelectEmployees setSelectedEmploymentsIds={ setSelectedEmploymentsIds } initialValues={ preEmployeesFilter } companyRelatedData={ companyRelatedData } onCancel={ onCancel } onBack={ onBack }
            employment_ids={ selectedEmployments }
            moduleName='companyRoaster' selected={ selectedEmployees } unSelected={ unSelectedEmployees } preFilters={ preEmployeesFilter } setSelected={ setSelectedEmployees } setUnselected={ setUnSelectedEmployees } setPrefilters={ setPreEmployeesFilter }
          />
          : currentStep === 2 && previewData ?
            <CreatePreview record={ previewData } /> : '' }


      {
        currentStep === 2 ?
          <Row justify="end">
            <Space>
              <Button className="button-back" onClick={ () => setCurrent(currentStep - 1) } disabled={ currentStep > 0 ? false : true }>
                <span style={ { color: "#4079FC" } }>Back</span>
              </Button>
              <SaveButton className="button-next" loading={ saveLoader } form={ formName } key="create_button" onClick={ () => onSubmit() } disabled={ previewData ? false : true } />
            </Space>
          </Row>
          : ''
      }
    </ModalComponent>
  )
}
export default CreateAttendancePolicyPlanner
