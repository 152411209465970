import { useEffect, useState } from "react";
import { Form, Divider, Row, Col, Checkbox, Space } from "antd";
import { trans, InputTime, InputText } from "@comps/components"
import { Commons, Const } from "./constants"
import moment from "moment";
const Preview = (props) => {
  const langKey = Const["myRoaster"].lngKey
  const [previewEmployment, setPreviewEmployments] = useState(props.employments);
  const [shiftDates, setShiftDates] = useState(props.dates);
  const [timeSlotForm] = Form.useForm();
  useEffect(() => {
    setData();
  }, [props])
  const setData = () => {
    setPreviewEmployments(props.previewData.employments)
    setShiftDates(props.dates)
    if(props.previewData.time_slot){
      timeSlotForm.setFieldsValue(props.previewData.time_slot);
    }
  }
  return (
    <>
      <Form
        { ...formLayout }
        layout="vertical"
        form={ timeSlotForm }
        disabled={ true }
      >
        <div style={ { paddingTop: 25, paddingLeft: 20, border: "1px solid #C9D6DF", borderRadius: 10, background: "rgba(201, 214, 223, 0.10)", paddingRight: 20 } }>
          <Row gutter={ 30 }>
            <Col lg={ 8 } sm={ 12 } xs={ 24 }>
              <Form.Item name="name" rules={ rules.name } label={ trans(Commons.name) }
                className="da-mb-16"
                size="small"
              >
                <InputText
                  bordered={ false }
                  style={ { marginTop: -10 } }
                  placeholder="Name"
                />
              </Form.Item>
            </Col>

            <Col lg={ 8 } sm={ 12 } xs={ 24 }>
              <Form.Item name="start" rules={ rules.start } label={ trans(`${langKey}.start`) }
                className="da-mb-16"
                size="small"
              >
                <InputTime
                  bordered={ false }
                  style={ { height: 48, marginTop: -10 } }
                  placeholder="Start Time"
                />
              </Form.Item>
            </Col>

            <Col lg={ 8 } sm={ 12 } xs={ 24 }>
              <Form.Item name="end" rules={ rules.end } label={ trans(`${langKey}.end`) }
                className="da-mb-16"
                size="small"
              >
                <InputTime
                  bordered={ false }
                  style={ { height: 48, marginTop: -10 } }
                  placeholder="End Time"
                />
              </Form.Item>
            </Col>

            <Col lg={ 8 } sm={ 12 } xs={ 24 }>
              <Form.Item name="is_night" rules={ rules.is_night } label={ trans(`${langKey}.is_night`) }
                className="da-mb-16"
                size="small"
                valuePropName="checked"
              >
                <Checkbox />
              </Form.Item>
            </Col>

            <Col lg={ 8 } sm={ 12 } xs={ 24 }>
              <Form.Item name="is_flexible" rules={ rules.is_flexible } label={ trans(`${langKey}.is_flexible`) }
                className="da-mb-16"
                size="small"
                valuePropName="checked"
              >
                <Checkbox />
              </Form.Item>
            </Col>

            <Col lg={ 8 } sm={ 12 } xs={ 24 }>
              <Form.Item name="end_buffer_minutes" rules={ rules.end_buffer_minutes } label={ trans(`${langKey}.end_buffer_minutes`) }
                className="da-mb-16"
                size="small"
              >
                <InputText
                  bordered={ false }
                  style={ { marginTop: -10 } }
                  type="number"
                  placeholder="Late Buffer"
                />
              </Form.Item>
            </Col>

            <Col lg={ 8 } sm={ 12 } xs={ 24 }>
              <Form.Item name="start_buffer_minutes" rules={ rules.start_buffer_minutes } label={ trans(`${langKey}.start_buffer_minutes`) }
                className="da-mb-16"
                size="small"
              >
                <InputText
                  bordered={ false }
                  style={ { marginTop: -10 } }
                  type="number"
                  placeholder="Early Buffer"
                />
              </Form.Item>
            </Col>

            <Col lg={ 8 } sm={ 12 } xs={ 24 }>
              <Form.Item name="punch_end_buffer_minutes" rules={ rules.punch_end_buffer_minutes } label={ trans(`${langKey}.punch_end_buffer_minutes`) }
                className="da-mb-16"
                size="small"
              >
                <InputText
                  bordered={ false }
                  style={ { marginTop: -10 } }
                  type="number"
                  placeholder="Punch Buffer End"
                />
              </Form.Item>
            </Col>

            <Col lg={ 8 } sm={ 12 } xs={ 24 }>
              <Form.Item name="punch_start_buffer_minutes" rules={ rules.punch_start_buffer_minutes } label={ trans(`${langKey}.punch_start_buffer_minutes`) }
                className="da-mb-16"
                size="small"
              >
                <InputText
                  bordered={ false }
                  style={ { marginTop: -10 } }
                  type="number"
                  placeholder="Punch Buffer Start"
                />
              </Form.Item>
            </Col>

            <Col lg={ 8 } sm={ 12 } xs={ 24 }>
              <Form.Item name="flexible_minutes" rules={ rules.flexible_minutes } label={ trans(`${langKey}.flexible_minutes`) }
                className="da-mb-16"
                size="small"
              >
                <InputText
                  bordered={ false }
                  style={ { marginTop: -10 } }
                  type="number" />
              </Form.Item>
            </Col>

            <Col lg={ 8 } sm={ 12 } xs={ 24 }>
              <Form.Item name="is_full_overtime_slot" rules={ rules.is_full_overtime_slot } label={ trans(`${langKey}.is_full_overtime_slot`) }
                className="da-mb-16"
                size="small"
                valuePropName="checked"
              >
                <Checkbox />
              </Form.Item>
            </Col>
          </Row>
        </div>


        <div style={ { border: "1px solid #C9D6DF", borderRadius: 10, marginTop: 30, background: "rgba(201, 214, 223, 0.10)", paddingLeft: 20, paddingRight: 20 } }>
          <Divider orientation="left">Breaks</Divider>
          <Form.List name="breaks">
            { (fields, { add, remove }) => (
              <>
                { fields.map(({ key, name, ...restField }) => (
                  <Row gutter={ 30 }>
                    <Col lg={ 6 } sm={ 12 } xs={ 24 } >
                      <Form.Item
                        { ...restField }
                        name={ [name, 'name'] }
                        rules={ rules.name }
                      >
                        <InputText placeholder="Name" />
                      </Form.Item>
                    </Col>
                    <Col lg={ 6 } sm={ 12 } xs={ 24 }>
                      <Form.Item
                        { ...restField }
                        name={ [name, 'start'] }
                        rules={ rules.start }
                      >
                        <InputTime placeholder="Start Time" />
                      </Form.Item>
                    </Col>
                    <Col lg={ 6 } sm={ 12 } xs={ 24 }>
                      <Form.Item
                        { ...restField }
                        name={ [name, 'end'] }
                        rules={ rules.end }
                      >
                        <InputTime placeholder="End Time" />
                      </Form.Item>
                    </Col>
                    <Col lg={ 6 } sm={ 12 } xs={ 24 }>
                      <Form.Item
                        { ...restField }
                        name={ [name, 'is_inclusive'] }
                        rules={ []}
                        label={ 'Inclusive' }
                        valuePropName="checked"
                      >
                        <Checkbox />
                      </Form.Item>
                    </Col>

                  </Row>
                )) }
              </>
            ) }
          </Form.List>
        </div>


        <Row gutter={ 30 }>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <div style={ { marginTop: 30 } } className="preview-employee-table">
              <div className="preview-employee-title">
                <h5 className="preview-employee-title-style">Employees</h5>
              </div>
              { previewEmployment && previewEmployment.map((each) => (
                <Space>
                  <li key={ each.id }>
                    <div style={ { width: 250, height: 64, border: "1px solid #4079FC", borderRadius: 5, marginTop: 16, marginLeft: 16, background: "rgba(64, 121, 252, 0.05)" } }>
                      <div >
                        <div><img className="each-profile-pic-thumb-url" src={ "/images/arnold-avatar.jpg" } alt="" /></div>
                        <div className="each-employee"><p className="each-full-name">{ each.name }</p>
                          <p className="each-designation-name" >{ each.designation_name }</p>
                        </div>
                      </div>
                    </div>
                  </li>
                </Space>
              )) }
            </div>
          </Col>


          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <div style={ { marginTop: 30 } } className="preview-employee-table">
              <div className="preview-employee-title">
                <h5 className="preview-employee-title-style">Shifts</h5>
              </div>
              <table style={ { width: "100%", marginTop: 10 } }>
                <thead>
                  <tr style={ { borderBottom: "1px solid #C9D6DF" } }>
                    <th style={ { paddingLeft: 20 } }>Shifts</th>
                    <th style={ { paddingLeft: 160 } }>Days</th>
                    <th style={ { paddingLeft: 80 } }>Months</th>
                  </tr>
                </thead>
                <tbody>
                  { shiftDates.map((date) => {

                    const currentDate = moment(date, 'YYYY-MM-DD');

                    return (
                      <tr key={ date } style={ { color: "#687980" } }>
                        <td style={ { paddingLeft: 20, paddingTop: 5 } }>{ currentDate.format('DD') }</td>
                        <td style={ { paddingLeft: 160 } }>{ currentDate.format('dddd') }</td>
                        <td style={ { paddingLeft: 80, whiteSpace: "nowrap" } }>{ currentDate.format('MMM D,YYYY') }</td>
                      </tr>
                    );
                  }) }
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default Preview


const rules = {
  start_date: [
    { required: true, message: 'Required' },
  ],
  end_date: [
    { required: true, message: 'Required' },
  ],
  repeat: [
    { required: true, message: 'Required' },
  ],
  font_colour: [
    { required: true, message: 'Required' },
  ],
  bg_colour: [
    { required: true, message: 'Required' },
  ],
  days: [
    { required: true, message: 'Please Select At least one day' },
  ],

};

const formLayout = {
  // layout: "horizontal",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}
