import { useState, useEffect } from "react";
import {
  BodyComponent,
  TableComponent,
  ActionComponent,
  CreateButton,RefreshButton,
  trans,
  HeaderComponent,
  PaginationComponent,
  EmployeeWithProfile,
} from "@comps/components";
import { makeRequest } from "@utils/helpers";
import { Commons, Const, colors } from "../../constants";
import { Badge, message } from 'antd';
import { FilterComponent, FilterButton } from "@comps/components";
import { deleteDataReq, getAllDataReq, getFilterReq } from "./requests";
import { useSelector } from "react-redux";
import CreateLeaveRequest from "./components/CreateLeaveRequest";
import moment from "moment";
import ViewFlow from '@mods/common/ViewFlow';
//import ViewLeaveRequest from "./components/ViewLeaveRequest";
import ViewLeaveRequestHistory from "../../common/ViewLeaveRequestHistory";
import ViewLeaveRequest from "../myLeaves/components/ViewLeaveRequest";
import { getRequestFilters } from "@mods/common/commonFilterFields";

const langKey = Const["leaveRequest"].lngKey;
const pageConfig = {
  headers: {
    title: trans(`${langKey}.moduleName`),
    breadcrumb: [
      {
        name: trans(`${langKey}.moduleName`),
        path: Const["leaveRequest"].route
      }
    ]
  }
}

const IndexCompanyLeaveRequests = (props) => {
  const authUser = useSelector((state) => state.auth.authUser);
  const [totalRecords, setTotalRecords] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [loader, setLoader] = useState(false);
  const [childComponent, setChildComponent] = useState(null);
  const [filerComponent, setFilterComponent] = useState(null);
  const [filters, setFilters] = useState('');
  const [leaveTypeBalance,setLeaveTypeBalance]=useState([]);
  //start pagination states

  const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState({
    "total": 0,
    "count": 0,
    "per_page": 0,
    "current_page": 0,
    "total_pages": 0,
    "links": {}
  });
  //end pagination states

  // ==================================
  //   Use States End
  // ==================================

  // ==================================
  //   Table Data End
  // ==================================

  const columns = [
    {
      key: 'code',
      title: trans(Commons.code),
      dataIndex: "code",
    },
    {
      key: 'employment_id',
      title: trans(`${langKey}.employment_id`),
      render: (record) => {
        return <EmployeeWithProfile record={ record.requestor } />
      },
    },
    {
      key: 'department_id',
      title: trans(`${langKey}.department_id`),
      render: (record) => {
        return record.requestor.department_name;
      }
    },
    {
      key: 'leave_type_id',
      title: trans(`${langKey}.leave_type_id`),
      dataIndex: "leave_type_name",
    },
    {
      key: 'next_requestee',
      title: trans(`${langKey}.next_requestee`),
      render: (record) => {
        return <EmployeeWithProfile record={ record.next_requestee_name } />
      },
    },
    {
      key: 'count',
      title: trans(`${langKey}.count`),
      dataIndex: "count",
    },
    {
      key: 'date',
      title: trans(`${langKey}.date`),
      render: (record) => {
        return moment(record.start).format('YYYY-MM-DD') + '-to-' + moment(record.end).format('YYYY-MM-DD')
      }
    },
    {
      key: 'status',
      title: trans(`${langKey}.status`),
      render: (record) => {
        return <Badge
          count={ record.status ? record.status : 'none' }
          style={ { background: colors[record.status_class], color: colors[record.status_class + '-color'] } }
        />
      }
    },

    {
      key: trans(Commons.actions),
      title: trans(Commons.actions),
      render: (record) => (
        <ActionComponent
          each={ record } modkey={props.modkey}
          onViewHistory={ record.status !== 'Pending' ? onViewHistory : '' }
          onViewFlow={ record.flow.length !== 0 ? onViewFlow : '' }
          onEdit={ record.status === 'Pending' ? onEdit : '' }
          onView={ onView }
          onDelete={ record.status === 'Pending' ? onDelete : '' }
          onCancelRequest={ (record.status !== 'Pending') ? (record.status !== 'Cancelled') ? onCancelRequest : '' : '' }
        >

        </ActionComponent>
      ),
    },
  ];

  // ==================================
  //   Table Data End
  // ==================================

  useEffect(() => {
    getAllData();
    // eslint-disable-next-line
  }, [filters, currentPage,props]);

  const getAllData = () => {
    let payload = {
      page: currentPage,
      filters: `${props.filters};${filters}`,
    }
    makeRequest(setLoader, getAllDataReq, payload, onSuccess, onGetAllDataError);
  };

  const onSuccess = (response) => {
    if(props.module==="myLeaveRequest"){
      setLeaveTypeBalance(response.leave_types);
    }
    setPagination(response.leave_requests);
    setDataSource(response.leave_requests.data);
  };
  const onGetAllDataError = (err, res) => {
    message.error(res.response.data.message);
  }
  const onEdit = (record) => {
    setChildComponent(
      <ViewLeaveRequest onCompleted={ onCompleted } record={ record } disabled={ false } action='edit' filters={props.filters} module={props.module}/>
    );
  };

  const onView = (record) => {
    setChildComponent(
      <ViewLeaveRequest onCompleted={ onCompleted } record={ record } disabled={ true } action='view' filters={props.filters} module={props.module}/>
    );
  };
  const onCancelRequest = (record) => {
    setChildComponent(
      <ViewLeaveRequest onCompleted={ onCompleted } record={ record } disabled={ true } action='cancel' filters={props.filters} module={props.module}/>
    );
  };
  const onViewHistory = (record) => {
    setChildComponent(
      <ViewLeaveRequestHistory onCompleted={ onCompleted } record={ record } disabled={ true } />
    );
  };
  const onViewFlow = (record) => {
    setChildComponent(
      <ViewFlow onCompleted={ onCompleted } record={ record } disabled={ true } />
    );
  };
  const onDelete = (record) => {
    const payload = {
      filters: '', id: record.id
    }
    makeRequest(setLoader, deleteDataReq, payload, onDeleted, onError);
  };
  const onDeleted = (res) => {
    onSuccess(res)
    setDataSource(res.leave_requests.data);
    message.success("Record Deleted")
  };

  const onError = (err, res) => {
    message.error(res.response.data.message);
    //setErrors(errorList);
  }

  // Create component modal
  const onCreate = () => {
    setChildComponent(<CreateLeaveRequest onCompleted={ onCompleted } authUser={ authUser } leavesOf='company' onSuccess={ onSuccess } filters={props.filters} module={props.module}/>);
  };

  const onCompleted = (data) => {
    if (data.length) {
      setDataSource(data);
    }
    setChildComponent(null);
  };

  const handleTableChange = (page, fil, sorter) => {
    let payload = {
      ...pagination,
      current: page.current,
      pageSize: page.pageSize,
      sortName: sorter.field || "id",
      sortType: sorter.order === "ascend" ? "asc" : "desc",
    };
    setPagination(payload);
  };

  //---------------filters-----------------------
  const onOpenFilter = () => {
    setFilterComponent(<FilterComponent filters={ filterFields } modkey={props.modkey} selected={ filters } api={ getFilterReq } setCurrentPage={setCurrentPage} onFilter={ setFilters } onCloseFilter={ onCloseFilter }
    />
    )
  }

  const onCloseFilter = () => {
    setFilterComponent(null);

  }


  //----end filter--------------------

  return (
    <>
      { filerComponent }
      { childComponent }


      <BodyComponent>

        {
          leaveTypeBalance.length>0 &&
          
          <>
           <h4 >Balance</h4>
          <table className="table">
            <thead>
              <tr>
              <th>Leave Type</th>
              <th>Balance</th>
              <th>Availed</th>
              <th>Available</th>
              </tr>
            </thead>
            <tbody>
              {
                leaveTypeBalance.map((each,key)=>(
                  <tr>
                  <td>{each.name}</td>
                  <td>{each.acc_count}</td>
                  <td>{each.availed}</td>
                  <td>{each.available}</td>
                  </tr>
                ))
              }
            </tbody>
          </table> 
        
          </>
}
        <HeaderComponent headers={ pageConfig.headers } modkey={props.modkey}>
          <RefreshButton onClick={ getAllData } /> <CreateButton onClick={ onCreate } />
          <FilterButton onClick={ onOpenFilter } />
        </HeaderComponent>
        <TableComponent modkey={props.modkey}
          loader={ loader }
          columns={ columns }
          dataSource={ dataSource }
          pagination={ { ...pagination, total: totalRecords } }
          onChange={ handleTableChange }
        />
      </BodyComponent>
      <PaginationComponent pagination={ pagination } modkey={props.modkey} setCurrentPage={ setCurrentPage } currentPage={ currentPage } />
    </>
  );
};

export default IndexCompanyLeaveRequests;

const filterFields = getRequestFilters('')


