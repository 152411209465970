import React, { useEffect, useState } from "react";
import { Checkbox, Divider, Form, message,Button } from "antd";
import { SaveButton, InputText, InputTextArea, CancelButton, ModalComponent, trans, SelectWithSearch } from "@comps/components"
import { makeRequest, makeRequestStateless, getErrorProps } from "@utils/helpers";
import { createDataReq, getRelatedReq } from "../requests";
import { Commons, Const } from "../../../constants";
import { createSelectList } from '@comps/commonFunctions';

import {DeleteOutlined, PlusOutlined } from '@ant-design/icons';
const langKey = Const["benefit"].lngKey
const formName = "createBenefit";

const CreateBenefit = (props) => {
  const [loader, setLoader] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [isGroup, setIsGroup] = useState(false);
  const [payItems, setPayitem] = useState([])
  const [formulas, setFormulas] = useState([])

  useEffect(() => {
    makeRequestStateless(getRelatedReq, null, ongetRelatedReq, null);
    // eslint-disable-next-line
  }, []);
  const ongetRelatedReq = (response) => {
    setPayitem(createSelectList(response.payitems));
    setFormulas(createSelectList(response.formulas));
  }

  const onSubmit = (data) => {
   
  if(isGroup){
    if(data.benefit_groups===undefined){
      message.info("Please Add memebers");
      return
    }
    if(data.benefit_groups!==undefined && data.benefit_groups.length<1){
      message.info("Please Add memebers");
      return
    }
  }

    if (data.file_attachment !== undefined && data.file_attachment !== null && data.file_attachment.url === undefined) {
      data.file_attachment.file = data.file_attachment.fileList.length > 0 ? data.file_attachment.file : null
    }
  
     const payload = { benefit: {...data,is_group:isGroup} };
    makeRequest(setLoader, createDataReq, payload, onSuccess, onError);
    //setSaveLoader(true)
  }

  const onSuccess = (data, res) => {
    setSaveLoader(false)
    message.success("Saved successfully");
    props.onCompleted(data.benefits.data);
    props.onSuccess(data)
  }

  const onError = (err, res) => {
    setSaveLoader(false)
    message.error(res.response.data.message);
  }

  const onChangeIsGroup = (e) => {
    setIsGroup(e.target.checked);
  }
  //=========================================
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [
    <SaveButton loading={ saveLoader } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } width={ 1000 } maskClosable={ false }
      mainTitle={ trans(Commons.create) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Form { ...formLayout } name={ formName } onFinish={ onSubmit } scrollToFirstError>

        <Form.Item name="name" rules={ rules.name } label={ trans(Commons.name) }
          className="da-mb-16"
          { ...getErrorProps(errors['name']) } size="small"
        >
          <InputText placeholder={ trans(`${langKey}.name`) } />
        </Form.Item>

        <Form.Item name="payitem_id" rules={ rules.payitem_id } label={ trans(`${langKey}.payitem_id`) }
          className="da-mb-16"
          { ...getErrorProps(errors['name']) } size="small"
        >
          <SelectWithSearch
            placeholder={ trans(`${langKey}.payitem_id`) }
            options={ payItems }
          />
        </Form.Item>

        <Form.Item name="is_group" rules={ rules.is_group } label={ trans(`${langKey}.is_group`) }
          className="da-mb-16"
          { ...getErrorProps(errors['name']) } size="small"
          valuePropName="checked"
        >
          <Checkbox onChange={ onChangeIsGroup } />
        </Form.Item>


        <Form.Item name="description" rules={ rules.description } label={ trans(Commons.description) }
          className="da-mb-16"
          { ...getErrorProps(errors['description']) } size="small"
        >
          <InputTextArea placeholder={ trans(`${langKey}.description`) } />
        </Form.Item>
        {
        isGroup ? 
        <div className="formlist-container">
          <h5 className="formlist-title">{ trans(`${langKey}.benefit_groups`) }</h5>
          <Form.List name="benefit_groups" >
            { (fields, { add, remove }) => (
              <>
                <Button onClick={ () => {
                  add()

                } } icon={ <PlusOutlined /> } type="primary" className="add-node-button">Add </Button>
                <div className="table-container">
                  <table className="table">
                    {
                      fields.length !== 0 && <thead>
                        <tr>
                        <th>{ trans(`${langKey}.name`) }</th>
                          <th style={ { paddingLeft: 20 } }>{ trans(`${langKey}.formula_id`) }</th>
                        
                       
                          <th>{ trans(Commons.actions) }</th>
                        </tr>
                      </thead>
                    }
                    <tbody>
                      { fields.map(({ key, name, ...restField }) => (
                        <>
                          <tr>
                          <td style={ { minWidth: '200px' } }>
                              <Form.Item
                                { ...restField }
                                name={ [name, 'name'] }
                                rules={ rules.name }
                              >
                                <InputText className="formlist-remove" />
                              </Form.Item>
                            </td>
                            <td style={ { minWidth: '200px', paddingLeft: 20 } }>
                              <Form.Item
                                { ...restField }
                                name={ [name, 'formula_id'] }
                                rules={ rules.formula_id }
                              >
                                <SelectWithSearch
                                  options={ formulas }
                                  placeholder={ trans(`${langKey}.formula_id`) } />
                              </Form.Item>
                            </td>
                         
                           
                            <td>
                              <DeleteOutlined className="formlist-remove" onClick={ () => {
                                remove(name)
                              } } />
                            </td>
                          </tr>
                        </>
                      )) }
                    </tbody>
                  </table>
                </div>
              </>
            ) }
          </Form.List>
        </div> :
          null
      }

      </Form>
      {/* <Divider orientation="left">{ trans(`${langKey}.additional_infos`) }</Divider> */}
    

    </ModalComponent>

  )
}

export default CreateBenefit

const rules = {
  name: [
    { required: true, message: trans(`${langKey}.name.req`) },
  ],
  payitem_id: [
    { required: true, message: trans(`${langKey}.payitem_id`) },
  ],
  formula_id: [
    { required: true, message: trans(`${langKey}.formula_id`) },
  ],

};

const formLayout = {
  // layout: "horizontal",
  labelCol: { span: 6 },
  wrapperCol: { span: 14, offset: 2 },
  labelAlign: "left"
}