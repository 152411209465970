import { DeleteOutlined, EditOutlined, EyeOutlined, PlusOutlined } from "@ant-design/icons"
import { Button, Form, Space } from "antd"
import { useEffect } from "react"
import { Commons, Const } from "../../constants";
import { InputText, trans } from "@comps/components"
const langKey = Const["privilege"].lngKey
const ViewPrivilegeFilters = (props) => {

  useEffect(() => {

    // eslint-disable-next-line
  }, []);


  return (
    <Form.List layout="vertical" name="privilege_filters" >
      { (fields, { add, remove }) => (
        <>
          <Button onClick={ () => {
            props.onCreate()
          } } icon={ <PlusOutlined /> } type="primary" className="add-node-button">{ trans(`${langKey}.add`) } </Button>
          <div className="table-container">
            <table className="table">
              {
                fields.length !== 0 && <thead>
                  <tr>
                    <th style={ { paddingLeft: 20 } }>{ trans(`${langKey}.type`) }</th>
                    <th>{ trans(Commons.actions) }</th>
                  </tr>
                </thead>
              }
              <tbody>
                { fields.map(({ key, name, ...restField }) => (
                  <>
                    <tr>
                      <td style={ { minWidth: '200px', paddingLeft: 20 } }>
                        <Form.Item
                          { ...restField }
                          name={ [name, 'type_name'] }
                        >
                          <InputText placeholder={ trans(`${langKey}.type_name`) } disabled />
                        </Form.Item>
                      </td>
                      <td>
                        <Space className="formlist-remove">
                          <DeleteOutlined onClick={ () => {
                            remove(name)
                            props.setRows(key, 'Deleted')
                          } } />
                          <EditOutlined className="ms-2" onClick={ () => {
                            props.onEdit(key)

                          } } />
                          <EyeOutlined className="ms-2" onClick={ () => {
                            props.onView(key)

                          } } />
                        </Space>
                      </td>
                    </tr>
                  </>
                )) }
              </tbody>
            </table>
          </div>
        </>
      ) }
    </Form.List>
  )
}
export default ViewPrivilegeFilters

const rules = {
  min_times: [
    { required: true, message: trans(`${langKey}.min_times`) },
  ],
  repeat_id: [
    { required: true, message: trans(`${langKey}.repeat_id`) },
  ],
  deduction_type_id: [
    { required: true, message: trans(`${langKey}.deduction_type_id`) },
  ],
  fallback_type_id: [
    { required: true, message: trans(`${langKey}.fallback_type_id`) },
  ],

}