import React, { useEffect, useState } from "react";
import { Form, Skeleton, message, Row, Col } from "antd";
import { SaveButton, CancelButton, ModalComponent, trans, InputTime, InputTextArea, InputDate, SelectWithSearch, SelectResourceList } from "@comps/components"
import { makeRequest, getErrorProps } from "@utils/helpers";
import { createDataReq, getExpectedTimeReq } from "../requests";
import { Commons, Const } from "../../../constants";
import { convertTimeTotz } from '@comps/commonFunctions';

const langKey = Const["attendanceRequest"].lngKey
const formName = "createAttendanceRequest";
const CreateCompanyAttendanceRequest = (props) => {
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [selectedEmploymentId, setEmploymentId] = useState(null)
  const [expectedTime, setExpectedTime] = useState(null)
  const [form] = Form.useForm()
  useEffect(() => {
    getEmployments()
    // eslint-disable-next-line
  }, []);

  const getEmployments = () => {
    if (props.source === 'timeSheet' || props.source === 'my-attendance') {
      form.setFieldValue('date', convertTimeTotz(props.record.date))
      setEmploymentId(props.record.employment_id)
      getExpectedTime(convertTimeTotz(props.record.date), props.record.employment_id)
    }
  }

  const getExpectedTime = (date, employmentId) => {
    if (date) {
      const payload = { employment_id: employmentId, date: date.format('YYYY-MM-DD') }
      makeRequest(setLoader, getExpectedTimeReq, payload, onGetExpectedTimeSuccess, onGetExpectedTimeError);
    }
  }
  const onGetExpectedTimeSuccess = (response, data) => {

    if (data && data.message === "") {
      let start_time = convertTimeTotz(data.data.checkin_expected_time);
      let end_time = convertTimeTotz(data.data.checkout_expected_time);

      form.setFieldsValue({
        expected_check_in: start_time,
        expected_check_out: end_time,
      })
      setExpectedTime(data.data)
    }
    else {
      form.setFieldsValue({
        expected_check_in: null,
        expected_check_out: null,
      })
      message.error(data.message)
    }
    setLoading(false)
  }

  const onGetExpectedTimeError = (err, res) => {

  }

  const onChangePunchtype = (type) => {
    if (type === "Check In") {
      form.setFieldsValue({
        punch_time: convertTimeTotz(expectedTime.checkin_expected_time),
      })
    }
    if (type === "Check Out") {
      form.setFieldsValue({
        punch_time: convertTimeTotz(expectedTime.checkout_expected_time),
      })
    }
  }
  const onSubmit = (data) => {
    data.employment_id = props.source === 'timeSheet' || props.source === 'my-attendance' ? props.record.employment_id : data.employment_id;
    const payload = {
      attendance_request: {
        behalf_employment_id: props.authUser.employment_id, is_on_behalf: true, employment_id: data.employment_id, "date": data.date.format('YYYY-MM-DD'), expected_check_in: data.expected_check_in.second(0), expected_check_out: data.expected_check_out.second(0), punch_type: data.punch_type, punch_time: data.punch_time.second(0), "description": data.description
      }
      ,
      filters: props.filters
    }
    makeRequest(setLoader, createDataReq, payload, onSuccess, onError);
    setSaveLoader(true)
  }

  const onSuccess = (data, res) => {
    setSaveLoader(false)
    message.success("Saved successfully");
    props.onCompleted(data.attendance_requests.data);
    props.onSuccess(data)
  }

  const onError = (err, res) => {

    setSaveLoader(false)
    message.error(res.response.data.message);
  }
  //-----------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------
  const footer = [
    <SaveButton loading={ saveLoader } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];
  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------
  return (
    <ModalComponent top={ 20 } width={ 1000 } maskClosable={ false }
      mainTitle={ trans(Commons.create) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Skeleton loading={ loading }>
        <Form { ...formLayout } form={ form } name={ formName } onFinish={ onSubmit } scrollToFirstError>
          <Row gutter={ 30 }>
            {
              props.source !== 'my-attendance' && props.source !== 'timeSheet' ?
                <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                  <Form.Item name="employment_id" rules={ rules.employment_id } label={ trans(`${langKey}.employment_id`) }
                    className="da-mb-16"
                    { ...getErrorProps(errors['name']) } size="small"
                  >
                    <SelectResourceList type='employment'
                      onChange={ (id) => setEmploymentId(id) }
                    />
                  </Form.Item>
                </Col>
                : ''
            }

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="date" rules={ rules.date } label={ trans(`${langKey}.date`) }
                className="da-mb-16"
                { ...getErrorProps(errors['date']) } size="small"
              >
                <InputDate onChange={ (e) => getExpectedTime(e, selectedEmploymentId) } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="expected_check_in" rules={ rules.expected_check_in } label={ trans(`${langKey}.expected_check_in`) }
                className="da-mb-16"
                { ...getErrorProps(errors['expected_check_in']) } size="small"
              >
                <InputTime placeholder={ trans(`${langKey}.expected_check_in`) } disabled={ true } />
              </Form.Item>
            </Col>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="expected_check_out" rules={ rules.expected_check_out } label={ trans(`${langKey}.expected_check_out`) }
                className="da-mb-16"
                { ...getErrorProps(errors['expected_check_out']) } size="small"
              >
                <InputTime placeholder={ trans(`${langKey}.expected_check_out`) } disabled={ true } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="punch_type" rules={ rules.punch_type } label={ trans(`${langKey}.punch_type`) }
                className="da-mb-16"
                { ...getErrorProps(errors['name']) } size="small"
              >
                <SelectWithSearch
                  options={ [
                    {
                      label: "Check In",
                      value: "Check In"
                    },
                    {
                      label: "Check Out",
                      value: "Check Out"
                    },
                  ] }
                  placeholder={ trans(`${langKey}.punch_type`) }
                  onChange={ onChangePunchtype }
                />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="punch_time" rules={ rules.punch_time } label={ trans(`${langKey}.punch_time`) }
                className="da-mb-16"
                { ...getErrorProps(errors['punch_time']) } size="small"
              >
                <InputTime placeholder={ trans(`${langKey}.punch_time`) } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="description" rules={ rules.description } label={ trans(`${langKey}.description`) }
                className="da-mb-16"
                { ...getErrorProps(errors['description']) } size="small"
              >
                <InputTextArea placeholder={ trans(`${langKey}.description`) } />
              </Form.Item>
            </Col>
          </Row>

        </Form>
      </Skeleton>
    </ModalComponent>
  )
}

export default CreateCompanyAttendanceRequest

const rules = {
  date: [
    { required: true, message: trans(`${langKey}.date`) },
  ],
  punch_time: [
    { required: true, message: trans(`${langKey}.punch_time`) },
  ],
  punch_type: [
    { required: true, message: trans(`${langKey}.punch_type`) },
  ],

  employment_id: [
    { required: true, message: trans(`${langKey}.employment_id`) },
  ],
};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}
