import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Spin } from 'antd';
import { makeRequest } from '@utils/helpers';
import { getHeadCountByDesignationReq } from '../requests';
import "../dashboard.css"
import ReactApexChart from 'react-apexcharts';
import useInViewport from '../useInViewport';

const HeadCountByDesignation = () => {
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const viewRef = useRef(null);
  const viewport = useInViewport(viewRef);
  useEffect(() => {
    if (viewport && data.length === 0) {
      makeRequest(setLoader, getHeadCountByDesignationReq, '', onSuccess, onError)
    }
  }, [viewport]);

  const onSuccess = (data) => {
    setData(data);
  }

  const onError = (error) => {
  }

  const chartOptions = {
    // Define your chart options here
    series: [{
      name: 'Count',
      data: data.values
    }],
    labels: data.labels,
    colors: ['#775DD0'],

  };

  return (
    <div className='widgetbox' ref={ viewRef }>
      <Row gutter={ 10 }>
        <Col span={ 12 }>
          <h3>Head Count By Designation</h3>
        </Col>
        <Col span={ 12 }>
          <div className='changeDate'>
          </div>
        </Col>
      </Row>
      <div className='gendermain'>
        <Spin spinning={ loader } tip="Loading...">
          { data && data.values && data.labels && (
            <ReactApexChart
              options={ chartOptions }
              series={ chartOptions.series }
              type="bar"
              height={ 380 }
            />
          ) }
        </Spin>
      </div>
    </div>
  );
}

export default HeadCountByDesignation;