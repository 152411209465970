import React, { useEffect, useState } from "react";
import { Form, message ,Select} from "antd";
import { SaveButton, InputText, InputTextArea, CancelButton, ModalComponent, trans,InputCheck } from "@comps/components"
import { makeRequest, getErrorProps } from "@utils/helpers";
import { createDataReq } from "../requests";
import { Commons, Const } from "../../../constants";
const langKey = Const["questions"].lngKey
const formName = "createReason";

const CreateQuestion = (props) => {
  const [loader, setLoader] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [type,setType]=useState('text');


  useEffect(() => {
    // eslint-disable-next-line
  }, []);

  const types=[
    {
      label:"Text",
      value:'text'
    },
    {
      label:"Checkbox",
      value:'checkbox'
    },
    {
      label:"Radio",
      value:'radio'
    },
    {
      label:"Date",
      value:'date'
    },

  ]
  const onSubmit = (data) => {
    const payload = { question: data };
    makeRequest(setLoader, createDataReq, payload, onSuccess, onError);
    setSaveLoader(true)
  }

  const onSuccess = (data, res) => {
    setSaveLoader(false)
    message.success("Saved successfully");
    props.onCompleted(data);
  }

  const onError = (err, res) => {
    setSaveLoader(false)
    message.error(res.response.data.message);
  }

  //-----------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [
    <SaveButton loading={ saveLoader } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } width={ 1200 } maskClosable={ false }
      mainTitle={ trans(Commons.create) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Form { ...formLayout } name={ formName } onFinish={ onSubmit } scrollToFirstError>

      <Form.Item name="question_no" rules={ rules.question_no } label={ trans(`${langKey}.question_no`)   }
          className="da-mb-16"
         
        >
          <InputText placeholder={ trans(`${langKey}.question_no`) } type="number"/>
        </Form.Item>
        <Form.Item name="type" rules={ rules.type } label={ trans(`${langKey}.type`)  }
          className="da-mb-16"
          initialValue={type}
        >
          <Select options={types} placeholder={ trans(`${langKey}.type`) } onChange={(value)=>setType(value)}/>
        </Form.Item>
      
{/* <Form.Item name="category" rules={ rules.category } label={ trans(`${langKey}.category`)   }
          className="da-mb-16"
         
        >
          <InputText placeholder={ trans(`${langKey}.category`) } />
        </Form.Item> */}

        <Form.Item name="name" rules={ rules.name } label={ trans(`${langKey}.name`) }
          className="da-mb-16"
         
        >
          <InputTextArea placeholder={ "Question" } />
        </Form.Item>

        {type==='radio' || type==='checkbox' ? 
<Form.Item name="data" rules={ rules.data } label={ trans(`${langKey}.data`)  }
          className="da-mb-16"
          
        >
          <InputText placeholder={ "op1,op2,op3,op4 etc" } />
        </Form.Item>
        :''}


        <Form.Item name="description" rules={ rules.description } label={ trans(Commons.description) }
          className="da-mb-16"
          { ...getErrorProps(errors['description']) } size="small"
        >
          <InputTextArea placeholder={ trans(`${langKey}.description`) } />
        </Form.Item>
        <Form.Item name="required" rules={ rules.required } label={ trans(`${langKey}.required`)  }
          className="da-mb-16"
          valuePropName="checked"
        >
          <InputCheck />
        </Form.Item>

      </Form>
    </ModalComponent>
  )
}

export default CreateQuestion

const rules = {
  name: [
    { required: true, message:trans(`${langKey}.name`)},
  ],
  type: [
    { required: true, message:trans(`${langKey}.type`)},
  ],
  data: [
    { required: true, message:trans(`${langKey}.data`)},
  ],
  category: [
    { required: true, message:trans(`${langKey}.category`)},
  ],
  question_no: [
    { required: true, message:trans(`${langKey}.question_no`)},
  ],

};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}