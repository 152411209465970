import React, { useEffect, useState } from "react";
import { Form, message, Skeleton, Row, Col } from "antd";
import { SaveButton, InputTextArea, CancelButton, ModalComponent, trans, InputText } from "@comps/components"
import { makeRequest, makeRequestStateless, } from "@utils/helpers";
import { updateDataReq } from "../requests";
import { Commons, Const } from "../../constants";
import { createSelectList } from '@comps/commonFunctions';
import { getEmploymentsDataReq } from "@mods/common/requests";
import { useSelector } from "react-redux";
import ViewWorkSheetTasks from "./ViewWorkSheetTasks";
import UpdateKpiTasks from "./UpdateKpiTasks";
import CreateKpiTasks from './CreateKpiTasks';

const langKey = Const["kpi"].lngKey
const formName = "UpdateKpi";

const UpdateKpi = (props) => {
  const [loader, setLoader] = useState(false);
  const [saving, setsaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const [employments, setEmployments] = useState([])
  const [relatedData, setRelatedData] = useState('')
  const [childComponent, setChildComponent] = useState(null)
  const [workSheetTasks, setWorkSheetTasks] = useState([])
  const [deletedRows, setDeletedRows] = useState([])
  const authUser = useSelector((state) => state.auth.authUser);
  const [form] = Form.useForm()
  useEffect(() => {
    getData()
    // eslint-disable-next-line
  }, []);

  const getData = () => {
    getEmployments()
    setWorkSheetTasks(props.record.kpi_tasks)
    form.setFieldsValue(props.record)
  }

  const getEmployments = () => {
    const payload = { filters: null }
    makeRequestStateless(getEmploymentsDataReq, payload, onGetEmployments, false)
  }
  const onGetEmployments = (response) => {
    setLoading(false)
    setEmployments(createSelectList(response.resources.data))
  }

  const onSubmit = (data) => {

    let tasks = []
    if (data.kpi_tasks !== undefined) {
      data.kpi_tasks.forEach(task => {
        if (task.id === undefined) {
          task.row_state = 'Added'
        }
        if (task.id !== undefined) {
          task.row_state = 'Updated'
        }
        task.fields = task.kpi_task_fields === undefined ? task.fields : JSON.stringify(task.kpi_task_fields)
        tasks.push(task)
      })
    }
    deletedRows.forEach(row => {
      tasks.push(row)
    })

    const payload = { kpi: { ...props.record, title: data.title, short_title: data.short_title, description: data.description, assignby_id: authUser.employment_id, assignto_id: null, verifiedby_id: null }, kpi_tasks: tasks, id: props.record.id };
    //setsaving(true)
    makeRequest(setLoader, updateDataReq, payload, onSuccess, onError);
  }

  const onSuccess = (data, res) => {
    message.success(res.message);
    props.onCompleted(data);
  }

  const onError = (err, res) => {
    setsaving(false)
    message.error(res.response.data.message);
  }
  // ==================================slabs actions =================================
  const onCreate = () => {
    setChildComponent(<CreateKpiTasks onCompleted={ onCompleted } allData={ relatedData } employments={ employments } />)
  }

  //edit slab
  const onEdit = (key) => {

    let record = form.getFieldValue(`kpi_tasks`)[key]
    setChildComponent(<UpdateKpiTasks onCompleted={ onCompleted } allData={ relatedData } record={ { ...record, key: key } } disabled={ false } employments={ employments } />)
  };
  const onView = (key) => {

  }
  //set deleted rows
  const setRows = (key, state) => {

    if (workSheetTasks[key] !== undefined && workSheetTasks[key].id !== undefined && state === 'Deleted') {
      workSheetTasks[key].row_state = 'Deleted'
      deletedRows.push(workSheetTasks[key])
    }
  }

  //on submit slabs
  const onCompleted = (data, action) => {

    let listArray = form.getFieldValue('kpi_tasks');
    if (listArray === undefined) {
      listArray = []
    }
    if (data && action === 'Added') {
      listArray.push(data)
      form.setFieldValue('kpi_tasks', listArray)
      //setWorkSheetTasks(listArray)
    }
    if (data && action === 'Updated') {
      listArray[data.key] = data
      form.setFieldValue('kpi_tasks', listArray)
      //setWorkSheetTasks(listArray)
    }
    setChildComponent(null)
  }

  //-----------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [
    !props.disabled &&
    <SaveButton loading={ saving } text="Update" form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } width={ 1200 } maskClosable={ false }
      mainTitle={ trans(props.disabled ? Commons.view : Commons.update) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) } >
      { childComponent }
      <Skeleton loading={ loading }>
        <Form { ...formLayout } name={ formName } onFinish={ onSubmit } form={ form } disabled={ props.disabled } scrollToFirstError>
          <Row gutter={ 30 }>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="title" rules={ rules.title } label={ trans(`${langKey}.title`) }
                className="da-mb-16"
              >
                <InputTextArea placeholder={ trans(`${langKey}.name`) } />
              </Form.Item>
            </Col>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="short_title" rules={ rules.short_title } label={ trans(`${langKey}.short_title`) }
                className="da-mb-16"
              >
                <InputText placeholder={ trans(`${langKey}.short_title`) } />
              </Form.Item>
            </Col>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="description" rules={ rules.description } label={ trans(Commons.description) }
                className="da-mb-16"
              >
                <InputTextArea placeholder={ trans(`${langKey}.description`) } />
              </Form.Item>
            </Col>
          </Row>
          <>
            <div className="formlist-container">
              <h5 className="formlist-title">{ trans(`${langKey}.tasks`) }</h5>
              <ViewWorkSheetTasks setRows={ setRows } onCreate={ onCreate } onEdit={ onEdit } onView={ onView } employments={ employments } />
            </div>
          </>

        </Form>
      </Skeleton>

    </ModalComponent>
  )
}

export default UpdateKpi

const rules = {
  title: [
    { required: true, message: trans(`${langKey}.title`) },
  ],
  short_title: [
    { required: false, message: trans(`${langKey}.short_title`) },
  ],
  assignby_id: [
    { required: false, message: trans(`${langKey}.assignby_id`) },
  ],
  assignto_id: [
    { required: true, message: trans(`${langKey}.assignto_id`) },
  ],
  verifiedby_id: [
    { required: true, message: trans(`${langKey}.verifiedby_id`) },
  ],
};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}
