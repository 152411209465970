import { useEffect } from "react";
import { Divider, Row, Col } from "antd";

import { TableWithFilter} from "@comps/components"

const Preview = (props) => {

  useEffect(() => {
    setData()
  }, [props])
  const setData = () => {

  }
  return (
    <>
      <Divider orientation="left">{ props.previewData.leave_types ? 'Leave Types' : '' }</Divider>
      <div>
        <ul >
          <Row >
            { props.previewData.leave_types ? props.previewData.leave_types.map((each, key) => (
              <Col span={ 2 } key={ each.id }>{ each.name }</Col>
            )) : '' }
          </Row>
        </ul>
      </div>

      <Divider orientation="left">Employees</Divider>

      <TableWithFilter columns={ [{
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'designation',
        dataIndex: 'designation_name',
        key: 'designation',
      },] }

        data={ props.previewData.employments ? props.previewData.employments : [] }
      />
     
    </>
  )
}

export default Preview


const rules = {
  start_date: [
    { required: true, message: 'Required' },
  ],
  end_date: [
    { required: true, message: 'Required' },
  ],
  repeat: [
    { required: true, message: 'Required' },
  ],
  font_colour: [
    { required: true, message: 'Required' },
  ],
  bg_colour: [
    { required: true, message: 'Required' },
  ],
  days: [
    { required: true, message: 'Please Select At least one day' },
  ],

};

const formLayout = {
  // layout: "horizontal",
  labelCol: { span: 6 },
  wrapperCol: { span: 14, offset: 2 },
  labelAlign: "left"
}
