import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Spin } from 'antd';
import { makeRequest } from '@utils/helpers';
import { getAbsentsMonthWiseReq } from '../requests';
import "../dashboard.css"
import ReactApexChart from 'react-apexcharts';
import { InputDate } from '@comps/components';
import dayjs from 'dayjs';
import useInViewport from '../useInViewport';




const moment = require('moment');

const AbsentMonthWise = () => {
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState(moment().startOf('year'));
  const [endDate, setEndDate] = useState(moment().endOf('month'));


  const viewRef = useRef(null);
  const viewport = useInViewport(viewRef);

  useEffect(() => {
    let payload = {
      "start_date": startDate ? startDate.format('YYYY-MM-DD') : moment().startOf('month').format('YYYY-MM-DD'),
      "end_date": endDate ? endDate.format('YYYY-MM-DD') : moment().endOf('month').format('YYYY-MM-DD'),
    }
    if (viewport) {
      makeRequest(setLoader, getAbsentsMonthWiseReq, payload, onSuccess, onError)
    }
  }
    , [viewport, startDate, endDate]);

  const onSuccess = (data) => {
    setData(data);
  }

  const onError = (error) => {
  }

  const chartOptions = {
    // Define your chart options here
    series: [{
      name: 'Percent',
      data: data.values
    }],
    labels: data.labels,
    colors: ['#EB7900'],
    options: {
      chart: {
        height: 400,
        type: 'area'
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'
      },

    },
  };

  return (
    <div className='widgetbox' >
      <Row gutter={ 10 }>
        <Col span={ 12 }>
          <h3>Month Wise Absents</h3>
        </Col>
        <Col span={ 12 }>
          <div className='changeDate'>
            <Row gutter={ 10 }>
              <Col span={ 12 }>
                <InputDate picker="month" onChange={ setStartDate } defaultValue={ dayjs(startDate) } placeholder="Start Date" />
              </Col>
              <Col span={ 12 }>
                <InputDate picker="month" onChange={ setEndDate } defaultValue={ dayjs(endDate) } placeholder="End Date" />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <div className='gendermain'>
        <Spin spinning={ loader } tip="Loading...">
          { data && data.values && data.labels && (
            <ReactApexChart
              options={ chartOptions }
              series={ chartOptions.series }
              type="area"
              height={ 380 }
            />
          ) }
        </Spin>
      </div>
    </div>
  );
}

export default AbsentMonthWise;