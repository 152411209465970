import React, { useEffect, useState } from "react";
import { Checkbox, Form, Modal, message, Row, Col, Skeleton } from "antd";
import { SaveButton, InputText, InputTextArea, CancelButton, ModalComponent, trans, SelectWithSearch, UploadFile, InputDate, UploadButton, InputTime } from "@comps/components"
import { makeRequest, getErrorProps } from "@utils/helpers";
import { createDataReq, getEmployeeRelatedDataReq, onDateChange, onShiftSelectionChangeReq } from "../requests";
import { Commons, Const } from "../../../constants";
import { createSelectList } from '@comps/commonFunctions';
import RoasterView from "@mods/common/RoasterView";
import dayjs from 'dayjs'
const langKey = Const["leaveRequest"].lngKey
const formName = "createLeaveRequest";
const moment = require('moment')

const CreateLeaveRequest = (props) => {
  const [loader, setLoader] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [leaveTypes, setLeaveTypes] = useState([])
  const [responseLeaveTypes, setResponseLeaveTypes] = useState([])
  const [available, setAvailable] = useState(null)
  const [selectedLeaveType, setSelectedLeaveType] = useState({})
  const [roasterData, setRoasterData] = useState({})
  const [selectedStart, setStartDateSelected] = useState(null)
  const [selectedEnd, setEndDateSelected] = useState(null)
  const [disabledSave, setDisableSave] = useState(true)
  const [roasterView, setRosterView] = useState(null)
  const [shiftChangePayload, setShiftChangePayload] = useState({})
  const [shift_ids, setShiftIds] = useState([]);
  const [isShort, setIsShort] = useState(false)
  const [shortLeaveTypes, setShortLeaveTypes] = useState([])
  const [hasShort, setHasShort] = useState(false)
  const [isLeaveType, setIsLeaveType] = useState(false)
  const [employmentId, setEmploymentId] = useState(null)
  const [loading, setLoading] = useState(true)
  const [form] = Form.useForm()
  useEffect(() => {
    getEmployeeRelated()
  }, []);
  const getEmployeeRelated = () => {
    let employmentId = props.authUser.employment_id;
    if (props.source === 'timeSheet') {
      employmentId = props.record.employment_id
      setStartDateSelected(dayjs(props.record.date))
      setEndDateSelected(dayjs(props.record.date))

      form.setFieldsValue({
        start: dayjs(props.record.date),
        end: dayjs(props.record.date),
      })
      setEmploymentId(employmentId)
      onChangeStartDate(dayjs(props.record.date), employmentId)
    }
    setEmploymentId(employmentId)
    const payload = { employment_id: employmentId }
    makeRequest(setLoader, getEmployeeRelatedDataReq, payload, onGetEmployeeRelated, onError);
  }
  const onGetEmployeeRelated = (data, response) => {
    setLeaveTypes(createSelectList(response.leave_types))
    setResponseLeaveTypes(response);
    setLoading(false)
  }
  const onSubmit = (data) => {
    if (data.file_attachment !== undefined) {
      data.file_attachment.file = data.file_attachment.fileList.length > 0 ? data.file_attachment.file : null
    }
    setSaveLoader(true)
    data.start = data.start.format('YYYY-MM-DD');
    data.end = data.end.format('YYYY-MM-DD');
    data.shiftIds = shift_ids
    data.employment_id = employmentId
    const payload = { file_attachment: undefined, leave_request: data, filters: props.filters };
    makeRequest(setLoader, createDataReq, payload, onSuccess, onError);
  }

  const onSuccess = (data, res) => {
    setSaveLoader(false)
    message.success("Saved successfully");
    props.onCompleted(data.leave_requests.data);
    props.onSuccess(data)
  }

  const onError = (err, res) => {
    message.error(res.response.data.message);
  }

  const onChangeLeaveType = (e) => {

    responseLeaveTypes['leave_types'].map((each) => {

      if (each.id === e) {
        setIsLeaveType(true)
        setSelectedLeaveType(each)
        setShortLeaveTypes(each.short_leave_type_slabs)
        form.setFieldsValue({ leave_type_available: each.available })
        setHasShort(each.short_leave_type_slabs.length ? true : false)

      }
    })
    onChangeStartDate(selectedStart, employmentId)
  }
  const onChangeShortLeaveType = (e) => {

  }
  const onChangeStartDate = (e, employmentId) => {
    setStartDateSelected(e)
    if (!selectedEnd) {
      form.setFieldsValue({ end: e })
    }
    if (e) {
      let payload = {
        leave_request: {
          is_short: selectedLeaveType.is_short,
          start: e.format('YYYY-MM-DD'),
          end: selectedEnd ? selectedEnd.format('YYYY-MM-DD') : e.format('YYYY-MM-DD'),
          start_time: null,
          end_time: null,
          employment_id: employmentId,
          leave_type_id: form.getFieldValue('leave_type_id'),
          leave_type_available: selectedLeaveType.available
        }
      }
      setShiftChangePayload(payload)
      makeRequest(setLoader, onDateChange, payload, onSuccessDateChange, onDatechangeError);
    }
  }

  const onChangeEndDate = (e, employmentId) => {
    setEndDateSelected(e)
    if (!selectedStart) {
      form.setFieldsValue({ start: e })
    }
    if (e) {
      let payload = {
        leave_request: {
          is_short: selectedLeaveType.is_short,
          start: selectedStart ? selectedStart.format('YYYY-MM-DD') : e.format('YYYY-MM-DD'),
          end: e.format('YYYY-MM-DD'),
          start_time: null,
          end_time: null,
          employment_id: employmentId,
          leave_type_id: selectedLeaveType.id,
          leave_type_available: selectedLeaveType.available
        }
      }
      setShiftChangePayload(payload)
      makeRequest(setLoader, onDateChange, payload, onSuccessDateChange, onDatechangeError);
    }
  }

  const onSuccessDateChange = (response, data) => {

  }
  const onDatechangeError = (response, data) => {
    if (data.response) {
      message.error(data.response.data.message)
      return
    }
    if (!data.shifts) {
      setDisableSave(true)
      form.setFieldsValue({
        count: 0,
        shift_count: 0,
        sandwich_count: 0
      })
    }
    if (data.shifts) {
      setDisableSave(false)

    }
    setRosterView(<RoasterView record={ data } />)
    setRoasterData(data)
    onShiftChange(data)
  }
  const onShiftChange = (data) => {
    //get shiftIds
    let shiftIds = [];
    let date_total = [];
    let start = moment(new Date(data.from_date))
    let end = moment(new Date(data.to_date))

    if (start === end) {
      date_total.push(start)
      //setDateTotalSource(date_total)
    }
    else {
      while (start <= end) {
        date_total.push(start.format('YYYYMMDD'));
        start = start.add(1, 'days');
      }
    }
    date_total.map((date) => (
      data.employments.map((employee) => (
        data.shifts.hasOwnProperty(date + '-' + employee.id) ?
          shiftIds.push(data.shifts[date + '-' + employee.id][0]['id'])
          : ''
      ))
    ))

    setShiftIds(shiftIds)
    shiftChangePayload.leave_request.shiftIds = shiftIds
    let payload = shiftChangePayload
    makeRequest(setLoader, onShiftSelectionChangeReq, payload, onSuccessShiftChange, onShiftchangeError);
  }
  const onSuccessShiftChange = (response, data) => {

  }
  const onShiftchangeError = (response, data) => {
    
    if (data.response) {
      message.error(data.response.data.message)
      return
    }
    form.setFieldsValue({
      shift_count: data.shift_count,
      sandwich_count: data.sandwich_count,
      count: data.count
    })
  }
  //-----------------------
  //============uploading file Attachment============
  const [fileList, setFileList] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const handleCancel = () => setPreviewOpen(false);
  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };
  const onPreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };
  // ------------------------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [
    <SaveButton loading={ saveLoader } form={ formName } key="create_button" htmlType="submit" disabled={ disabledSave } />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } width={ 1200 } maskClosable={ false }
      mainTitle={ trans(Commons.create) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Skeleton loading={ loading }>
        <Form { ...formLayout } name={ formName } onFinish={ onSubmit } form={ form } scrollToFirstError>
          <Row gutter={ 30 }>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="leave_type_id" rules={ rules.leave_type_id } label={ trans(`${langKey}.leave_type_id`) }
                className="da-mb-16"
                { ...getErrorProps(errors['name']) } size="small"
              >
                <SelectWithSearch
                  options={ leaveTypes }
                  placeholder={ trans(`${langKey}.leave_type_id`) }
                  onChange={ onChangeLeaveType }
                />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="leave_type_available" rules={ rules.current_available } label={ trans(`${langKey}.current_available`) }
                className="da-mb-16"
                { ...getErrorProps(errors['current_available']) } size="small"
              >
                <InputText placeholder={ trans(`${langKey}.current_available`) } type="number" disabled />
              </Form.Item>
            </Col>
            { hasShort ?
              <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                <Form.Item name="is_short" rules={ rules.is_short } label={ trans(`${langKey}.is_short`) }
                  className="da-mb-16"
                  { ...getErrorProps(errors['is_short']) } size="small"
                  valuePropName="checked"
                >
                  <Checkbox onChange={ (e) => setIsShort(e.target.checked) } />
                </Form.Item>
              </Col>
              : '' }

            {
              isShort ?
                <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                  <Form.Item name="short_leave_type_slab_id" rules={ rules.short_leave_type_slab_id } label={ trans(`${langKey}.short_leave_type_slab_id`) }
                    className="da-mb-16"
                    { ...getErrorProps(errors['name']) } size="small"
                  >
                    <SelectWithSearch
                      options={ leaveTypes }
                      placeholder={ trans(`${langKey}.short_leave_type_slab_id`) }
                      onChange={ onChangeShortLeaveType }
                    />
                  </Form.Item>
                </Col>
                : ''
            }

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="start" rules={ rules.start } label={ trans(`${langKey}.start`) }
                className="da-mb-16"
                { ...getErrorProps(errors['name']) } size="small"
              >
                <InputDate onChange={ (e) => onChangeStartDate(e, employmentId) } disabled={ isLeaveType ? false : true } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="end" rules={ rules.end } label={ trans(`${langKey}.end`) }
                className="da-mb-16"
                { ...getErrorProps(errors['name']) } size="small"
              >
                <InputDate onChange={ (e) => onChangeEndDate(e, employmentId) } disabled={ !selectedStart ? true : isShort ? true : false } />
              </Form.Item>
            </Col>
          </Row>

          { roasterView }

          <Row gutter={ 30 }>
            { isShort ?
              <>
                <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                  <Form.Item name="time_start" rules={ rules.time_start } label={ trans(`${langKey}.time_start`) }
                    className="da-mb-16"
                    { ...getErrorProps(errors['name']) } size="small"
                  >
                    <InputTime placeholder={ trans(`${langKey}.time_start`) } />
                  </Form.Item>
                </Col>

                <Col lg={ 12 } sm={ 24 } xs={ 24 }>
                  <Form.Item name="time_end" rules={ rules.time_end } label={ trans(`${langKey}.time_end`) }
                    className="da-mb-16"
                    { ...getErrorProps(errors['name']) } size="small"

                  >
                    <InputTime placeholder={ trans(`${langKey}.time_end`) } />
                  </Form.Item>
                </Col>
              </>
              : ''
            }
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="shift_count" rules={ rules.shift_count } label={ trans(`${langKey}.shift_count`) }
                className="da-mb-16"
                { ...getErrorProps(errors['shift_count']) } size="small"
              >
                <InputText placeholder={ trans(`${langKey}.shift_count`) } type="number" value={ available } disabled={ true } />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="sandwich_count" rules={ rules.sandwich_count } label={ trans(`${langKey}.sandwich_count`) }
                className="da-mb-16"
                { ...getErrorProps(errors['sandwich_count']) } size="small"
              >
                <InputText placeholder={ trans(`${langKey}.sandwich_count`) } type="number" value={ available } disabled={ true } />
              </Form.Item>
            </Col>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="count" rules={ rules.count } label={ trans(`${langKey}.count`) }
                className="da-mb-16"
                { ...getErrorProps(errors['count']) } size="small"
              >
                <InputText placeholder={ trans(`${langKey}.count`) } type="number" value={ available } disabled={ true } />
              </Form.Item>
            </Col>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="reason" rules={ rules.reason } label={ trans(`${langKey}.reason`) }
                className="da-mb-16"
                { ...getErrorProps(errors['reason']) } size="small"
              >
                <InputTextArea placeholder={ trans(`${langKey}.reason`) } />
              </Form.Item>
            </Col>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item label={ trans(`${langKey}.attachment`) } name="file_attachment" >
                <UploadFile
                  accept=".jpg,.png,.jpeg" listType="picture-card"
                  fileList={ fileList }
                  onChange={ onChange }
                  onPreview={ onPreview }
                  multiple={ false }
                  beforeUpload={ (file) => {
                    return false;
                  } }
                >
                  { fileList.length === 1 ? null : <UploadButton /> }
                </UploadFile>
              </Form.Item>
            </Col>
          </Row>

          <Modal open={ previewOpen } title={ previewTitle } footer={ null } onCancel={ handleCancel }>
            <img
              alt="example"
              style={ {
                width: '100%',
              } }
              src={ previewImage }
            />
          </Modal>
        </Form>
      </Skeleton>
    </ModalComponent>
  )
}

export default CreateLeaveRequest

const rules = {
  leave_type_id: [
    { required: true, message: trans(`${langKey}.leave_type_id`) },
  ],
  start: [
    { required: true, message: trans(`${langKey}.start`) },
  ],
  end: [
    { required: true, message: trans(`${langKey}.end`) },
  ],
  time_start: [
    { required: true, message: trans(`${langKey}.time_start`) },
  ],
  time_end: [
    { required: true, message: trans(`${langKey}.time_end`) },
  ],
  count: [
    { required: true, message: trans(`${langKey}.count`) },
  ],
  shift_count: [
    { required: true, message: trans(`${langKey}.shift_count`) },
  ],
  reason: [
    { required: true, message: trans(`${langKey}.reason`) },
  ],
  short_leave_type_slab_id: [
    { required: true, message: trans(`${langKey}.short_leave_type_slab_id`) },
  ],

};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}

