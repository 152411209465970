import { get, del, post, postFd } from "@utils/axios";

const api = "companyTransfers";

export const getAllDataReq = (payload) => {
  return get(`${api}?page=${payload.page}&filters=${payload.filters}`) //filters=employee_|id
};

export const createDataReq = (payload) => {
  return postFd(`${api}?`, payload);
};

export const updateDataReq = (payload) => {
  return postFd(`${api}/edit?page=1&filters=${payload.filters}`, payload);
};
export const cancelRequestReq = (payload) => {
  return postFd('cancelRequest', payload);
};

export const deleteDataReq = (payload) => {
  return del(`${api}/${payload.id}?page=1&filters=${payload.filters}`);
};
export const getEmploymentsReq = (payload) => {
  return get(`resources/employment?page=1&filters=${payload.filters}`);
};

export const getEmployeeRelatedDataReq = (payload) => {
  return post(`${api}/employeeRelatedData`, payload); //employment_id=id
};

export const getFilterReq = (payload) => {
  return get(`${api}?page=1&filters?company_id=1&fields=page=1&filters=organization_structure_fields_|company;preset_|grade;`)
}
export const onDateChange = (payload) => {
  return post(`${api}/dateChange?&`, payload)
}
export const onShiftSelectionChangeReq = (payload) => {
  return post(`${api}/shiftSelectionChange?&`, payload)
}

export const getRelatedDataReq = (payload) => {
  return get(`${api}/${payload}`)
};
export const validateLendTypeReq = (payload) => {
  return post(`${api}/validate`, payload)
};

