import React, { useEffect, useState } from "react";
import { Button, Divider, Form, Input, Skeleton, message, Row, Col } from "antd";
import { SaveButton, SelectResourceList, CancelButton, ModalComponent, trans, InputDate, SelectWithSearch, TableComponent, ActionComponent, FlowHistoryButton, InputTextArea } from "@comps/components"
import { makeRequest, getErrorProps } from "@utils/helpers";
import { Commons, Const } from "../../../constants";
import moment from "moment/moment";
import dayjs from 'dayjs';
import { approveRequest } from "../requests";
import CreateConsent from "@mods/common/CreateConsent";
import CreateConsentChat from "@mods/common/CreateConsentChat";
import EmployeeInfo from '@mods/common/EmployeeInfo';
const { TextArea } = Input;
const langKey = Const["transferApproval"].lngKey
const formName = "updateTransferApproval";

const UpdateTransferApproval = (props) => {
  const [loader, setLoader] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [consents, setConsents] = useState([]);
  const [creatConsentModal, setCreateConsentModal] = useState(null);
  const [loading, setLoading] = useState(false)
  const [childComponent, setChildComponent] = useState(null)
  const [locations, setlocations] = useState([]);
  const [branches, setbranches] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [linemanagers, setlinemanagers] = useState([]);
  const [form] = Form.useForm()
  useEffect(() => {
    setData();
    // eslint-disable-next-line
  }, []);
  const setData = () => {
    setConsents(props.record.consents)
    //setDepartments(createSelectList(props.allData.departments))
    //setlocations(createSelectList(props.allData.locations))
    //setbranches(createSelectList(props.allData.branches))
    //setlinemanagers(createSelectList(props.allData.linemanagers))

    form.setFieldsValue(
      {
        employment_id: props.record.requestor.full_name_code,
        location_id: props.record.requestor.location_name,
        branch_id: props.record.requestor.branch_name,
        department_id: props.record.requestor.department_name,
        linemanager_id: props.record.linemanager_name,
        date: dayjs(props.record.date),
        description: props.record.description,
        remarks: props.record.last_remarks
      }
    )
  }

  //===========constents =============
  const onCreateConsent = () => {
    setCreateConsentModal(<CreateConsent onCreatedConsent={ onCreatedConsent } employment_id={ props.record.employment_id } request_id={ props.record.id } request_as={ props.requestAsId } api="transferApprovals/createConsent" />)
  }
  const onCreatedConsent = (response) => {
    if (response) {
      message.success("Consent created successfully");
      props.onCompleted(response.transfers.data);
    }
    setCreateConsentModal(null)
  }
  const onConsentChat = (record) => {
    setCreateConsentModal(<CreateConsentChat record={ record } onCreatedConsent={ onCreatedConsent } request_as={ props.requestAsId } api="transferApprovals/messageConsent" />)
  }
  //============end consents================================
  const onSubmit = (isApproval) => {
    const remarks = form.getFieldValue('remarks')
    let requestObj = { request: { ...props.record, current_history: { ...props.record.current_history, remarks: remarks }, last_remarks: remarks, remarks: remarks, request_as: props.requestAsId, is_approval: isApproval }, delegator_id: props.delegatorId, api: props.api, filters: props.filters }
    const payload = requestObj
    makeRequest(setLoader, approveRequest, payload, onSuccess, onError);
  }
  const onSuccess = (data, res) => {
    setSaveLoader(false)
    message.success("Saved successfully");
    props.onCompleted(data.transfers.data);
  }
  const onError = (err, res) => {
    setSaveLoader(false)
    message.error(res.response.data.message);
  }
  //----------------------
  const consentColumns = [
    {
      key: 'consentor_name',
      title: trans(`${langKey}.consentor_name`),
      dataIndex: 'consentor_name'
    },
    {
      key: 'consentee_name',
      title: trans(`${langKey}.consentee_name`),
      dataIndex: 'consentee_name'
    },
    {
      key: 'consent_date',
      title: trans(`${langKey}.consent_date`),
      render: (record) => {
        return record.created_at ? moment(record.created_at).format('DD-MM-YYYY h:mm:ss a') : null
      }
    },
    {
      key: trans(Commons.actions),
      title: trans(Commons.actions),
      render: (record) => (
        <ActionComponent
          each={ record } modkey={props.modkey}
          onView={ onConsentChat }
        ></ActionComponent>
      ),
    },
  ]
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------
  const footer = [
    childComponent,
    <div style={ { float: 'left' } }>
      < FlowHistoryButton record={ props.record } setChildComponent={ setChildComponent } />
    </div>,
    props.disabled ? '' :
      <SaveButton disabled={ props.disabled } loading={ saveLoader } form={ formName } key="create_button" onClick={ () => onSubmit(true) } text='Approve' />,
    props.disabled ? '' :
      <CancelButton disabled={ saveLoader } key="reject_button" form={ formName } htmlType='submit' text='Reject' onClick={ () => onSubmit(false) } />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];
  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------
  return (
    <ModalComponent top={ 20 } width={ 1200 } maskClosable={ false }
      mainTitle={ trans(props.disabled ? Commons.view : Commons.update) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Skeleton loading={ loading }>
        <EmployeeInfo record={ props.record.requestor && props.record.requestor } />
        <Form { ...formLayout } name={ formName } onFinish={ onSubmit } form={ form } disabled={ true } >

          <Divider>Transfer Request</Divider>
          <Row gutter={ 30 }>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="employment_id" rules={ rules.employment_id } label={ trans(`${langKey}.employment_id`) }
                className="da-mb-16"
                { ...getErrorProps(errors['name']) } size="small"
              >
                <SelectResourceList type='employment' value={ props.record.employment_id }
                  disabled={ true }
                />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="location_id" rules={ rules.location_id } label={ trans(`${langKey}.location_id`) }
                className="da-mb-16"
                size="small"
              >
                <SelectWithSearch
                  placeholder={ trans(`${langKey}.location_id`) }
                  options={ locations }
                />
              </Form.Item>
            </Col>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="branch_id" rules={ rules.branch_id } label={ trans(`${langKey}.branch_id`) }
                className="da-mb-16"
                size="small"
              >
                <SelectWithSearch
                  placeholder={ trans(`${langKey}.branch_id`) }
                  options={ branches }
                />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="department_id" rules={ rules.department_id } label={ trans(`${langKey}.department_id`) }
                className="da-mb-16"
                size="small"
              >
                <SelectWithSearch
                  placeholder={ trans(`${langKey}.department_id`) }
                  options={ departments }
                />
              </Form.Item>
            </Col>
            {/* <Form.Item name="payroll_id" rules={ rules.payroll_id } label={ trans(`${langKey}.payroll_id`) }
            className="da-mb-16"
            size="small"
          >
            <SelectWithSearch
              placeholder={ trans(`${langKey}.payroll_id`) }
              options={ departments }
            />
          </Form.Item> */}
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="linemanager_id" rules={ rules.linemanager_id } label={ trans(`${langKey}.linemanager_id`) }
                className="da-mb-16"
                size="small"
              >
                <SelectWithSearch
                  placeholder={ trans(`${langKey}.linemanager_id`) }
                  options={ linemanagers }
                />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="date" rules={ rules.date } label={ trans(`${langKey}.date`) }
                className="da-mb-16"
                { ...getErrorProps(errors['date']) } size="small"
              >
                <InputDate />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="description" rules={ rules.description } label={ trans(`${langKey}.description`) }
                className="da-mb-16"
                { ...getErrorProps(errors['description']) } size="small"
              >
                <TextArea placeholder="Description" />
              </Form.Item>
            </Col>
          </Row>
          <Divider>Approval</Divider>

          <Form.Item name="remarks" rules={ rules.remarks } label={ trans(`${langKey}.remarks`) }
            className="da-mb-16"
            { ...getErrorProps(errors['remarks']) } size="small"
          >
            <InputTextArea placeholder={ trans(`${langKey}.remarks`) } disabled={ false } />
          </Form.Item>

        </Form>

        {
          props.disabled ? '' : <h5 className="formlist-title" style={ { paddingTop: 0 } }>Consents <Button type="primary" title='Create' onClick={ onCreateConsent } className="float-end me-5">Create</Button></h5>
        }

        { creatConsentModal }
        <TableComponent dataSource={ consents } columns={ consentColumns } />
      </Skeleton>
    </ModalComponent>
  )
}

export default UpdateTransferApproval

const rules = {
  name: [
    { required: true, message: '' },
  ],

};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}