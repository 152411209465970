import React, { useEffect, useState } from "react";
import { Form, message, Space, Divider, Row, Col, Skeleton ,Tooltip} from "antd";
import { SaveButton, InputText, InputTextArea, CancelButton, ModalComponent, TableComponent, ButtonComponent, SelectWithSearch, trans } from "@comps/components"
import { makeRequest, makeRequestStateless } from "@utils/helpers";
import { Commons, Const } from "../../constants";
import { cloneReportInSaasReq, getPreviewQueryReq, getReportTypesAndTables, getTableInfoByIdReq, getTenantsReq, updateDataReq } from "../requests";
import { createSelectList } from '@comps/commonFunctions';
import { PlayArrowOutlined } from "@mui/icons-material";
const langKey = Const["allReports"].lngKey
const formName = "createRawReport";

const CopyReportToSaas = (props) => {
  const [loader, setLoader] = useState(false);
  const [saving, setsaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const [reportTypes, setReportTypes] = useState([]);

  const [tables, setTables] = useState([])
const [errors,setErrors]=useState([]);

  const [form] = Form.useForm()
  useEffect(() => {
    getRelatedData()
    setData()
    // eslint-disable-next-line
  }, []);

  const setData = () => {
    form.setFieldsValue(props.record)
  }
  //get report types and tables
  const getRelatedData = () => {
    makeRequestStateless(getReportTypesAndTables, null, onSuccessRelatedData, null);
    makeRequestStateless(getTenantsReq, null, onGetTenants, null);
  }
  const onGetTenants = (data, res) => {
    const newList = []
    data.tenants.forEach(tenant => (
      newList.push(
        {
          label: tenant.id,
          value: tenant.id
        }
      )
    ))
    setTables(newList)
  }
  const onSuccessRelatedData = (data) => {
    setReportTypes(createSelectList(data.report_types))
    setLoading(false)
  }

  const onSubmit = (data) => {
    const payload =  {report_id:props.record.id,report_title:props.record.title,tenant_ids:data.tenant_ids};
    makeRequest(setLoader, cloneReportInSaasReq, payload, onSuccess, onError);
    setsaving(true)
  }

  const onSuccess = (data, res) => {
    
    setsaving(false);
    if(data.errors && data.errors.length>0){
      setErrors(data.errors);
      return;
    }
    else{
      setErrors(false)
      message.success("Saved Successfully");
      props.onCompleted([]);
    }
  }

  const onError = (err, res) => {
    
    setsaving(false)
    message.error(res.response.data.message);
    //setErrors(errorList);
  }

  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------
  const footer = [
    !props.disabled &&
    <SaveButton loading={ saving } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } width={ 1400 } maskClosable={ false }
      mainTitle={ "Copy" } subTitle={ "Report" }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Skeleton loading={ loading }>
        <Form name={ formName } onFinish={ onSubmit } form={ form } disabled={ props.disabled }>
        <Row gutter={ 30 }>
      <Col lg={ 10 } sm={ 24 } xs={ 24 } >
      <Form.Item name="title" rules={ rules.name } label={ trans(`${langKey}.title`) }
          
          >
            <InputText placeholder="Name" disabled/>
          </Form.Item>

       
      </Col>
      <Col lg={ 10 } sm={ 24 } xs={ 24 } >
      <Form.Item name="report_type_id" label={ trans(`${langKey}.report_type_id`) } rules={ rules.report_type_id }
         
          >
            <SelectWithSearch
            
              placeholder={ trans(`${langKey}.report_type_id`) }
              options={ reportTypes } disabled/>
          </Form.Item>
       
      </Col>
      </Row>
          <Col span={20}>
          <Form.Item name="tenant_ids" label={ trans(`${langKey}.tenant_ids`) } rules={ rules.tenant_ids }>

<SelectWithSearch

  placeholder={ trans(`${langKey}.tenant_ids`) }
  options={ tables }
  mode="multiple"
  showSearch

/>
</Form.Item>
          </Col>
        
        </Form>
       
      {
        errors.length>0 && <>
        <table className="table border text-danger">
          <thead>
            <tr>
              <th className="text-danger text-center">Errors</th>
            </tr>
          </thead>
          <thead>
           
          </thead>
          <tbody className="text-danger">
          {
            errors.map((error, index) => (
              <tr key={index} >
                <td className="text-danger">{error}</td>
              </tr>
            ))  
           }
          </tbody>
        </table>
        </>
      }
      </Skeleton>

    </ModalComponent>
  )
}

export default CopyReportToSaas

const rules = {
  name: [
    { required: true, message: trans(`${langKey}.name.required`) },
  ],
  report_type_id: [
    { required: true, message: trans(`${langKey}.report_type_id`) },
  ],
  tenant_ids: [
    { required: true, message: trans(`${langKey}.tenant_ids`) },
  ]
};
