import React, { useEffect, useState } from 'react';
import { Row, Col, Spin, Collapse } from 'antd';
import { makeRequest } from '@utils/helpers';
import { getAttendanceByBranchDetailReq } from '../requests';
import { InputDate } from '@comps/components';
import dayjs from 'dayjs';
const moment = require('moment');

const AttendanceDrillByBranch = (props) => {
  const [loader, setLoader] = useState(false);
  const [date, setDate] = useState(moment());
  const [collapsData, setCollapsData] = useState([]);
  const [companyKey, setCompanyKey] = useState([]);

  useEffect(() => {

    let payload = {
      filters: {
        date: date ? date.format('YYYY-MM-DD') : ''
      }
    }

    makeRequest(setLoader, getAttendanceByBranchDetailReq, payload, onSuccess, Function)
  }, [date]);

  const onSuccess = (data) => {
    if (data && data.data && data.data.length > 0) {
      let company = [];
      company.push("company-" + data.data[0].company_id);
      setCompanyKey(company);
      setCollapsData([{
        key: "company-" + data.data[0].company_id,
        label: <table className="table table-bordered" >
          <thead className="thead-dark">
            <tr className="thead-dark">
              <th className="thead-dark" scope="col">Company</th>
              <th className="thead-dark" scope="col">Branches Count	</th>
              <th className="thead-dark" scope="col">Current Planned Shifts	</th>
              <th className="thead-dark" scope="col">Presents</th>
              <th className="thead-dark" scope="col">Absents</th>
              <th className="thead-dark" scope="col">Late Arrivals</th>
              <th className="thead-dark" scope="col">Early Left</th>
              <th className="thead-dark" scope="col">Leaves</th>
              <th className="thead-dark" scope="col">Travel</th>
              <th className="thead-dark" scope="col">Holiday</th>
              <th className="thead-dark" scope="col">Days Off</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{ data.data[0].company_name }</td>
              <td>{ data.data[0].branches_count }</td>
              <td>{ data.data[0].head_count }</td>
              <td>{ data.data[0].present_count }</td>
              <td>{ data.data[0].absent_count }</td>
              <td>{ data.data[0].late_count }</td>
              <td>{ data.data[0].early_leave_count }</td>
              <td>{ data.data[0].leave_count }</td>
              <td>{ data.data[0].travel_count }</td>
              <td>{ data.data[0].holiday_count }</td>
              <td>{ data.data[0].day_off_count }</td>
            </tr>

          </tbody>
        </table>,
        children: <><table className="table table-bordered" style={ { marginTop: '20px' } }>
          <thead className="thead-dark">
            <tr className="thead-dark">
              <th className="thead-dark" width={ 250 }>Branch</th>
              <th className="thead-dark" width={ 135 }>Time Slot Count	</th>
              <th className="thead-dark" width={ 180 }>Current Planned Shifts	</th>
              <th className="thead-dark" width={ 100 }>Presents</th>
              <th className="thead-dark" width={ 100 }>Absents</th>
              <th className="thead-dark" width={ 100 }>Late Arrivals</th>
              <th className="thead-dark" width={ 100 }>Early Left</th>
              <th className="thead-dark" width={ 100 }>Leaves</th>
              <th className="thead-dark" width={ 100 }>Travel</th>
              <th className="thead-dark" width={ 100 }>Holiday</th>
              <th className="thead-dark" width={ 100 }>Days Off</th>
            </tr>
          </thead>
        </table>
          { getCollapsitems(getCollapChild(data.data[0].branches)) }</>
        ,
      }]);
    } else {
      setCollapsData([]);
    }
  }

  const getCollapChild = (data) => {
    let child = [];
    data.forEach(element => {
      child.push({
        key: element.branch_id,
        label:
          <table className="table table-bordered" >
            <tbody>
              <tr style={ { margin: 0 } }>
                <td width={ 250 }>{ element.branch_name }</td>
                <td width={ 135 }>{ element.time_slots.length }</td>
                <td width={ 180 }>{ element.head_count }</td>
                <td width={ 100 }>{ element.present_count }</td>
                <td width={ 100 }>{ element.absent_count }</td>
                <td width={ 100 }>{ element.late_count }</td>
                <td width={ 100 }>{ element.early_leave_count }</td>
                <td width={ 100 }>{ element.leave_count }</td>
                <td width={ 100 }>{ element.travel_count }</td>
                <td width={ 100 }>{ element.holiday_count }</td>
                <td width={ 100 }>{ element.day_off_count }</td>
              </tr>
            </tbody>
          </table>
        ,
        children: <table className="table table-bordered" style={ { marginTop: 20, marginBottom: '20px !important' } } >
          <thead className="thead-dark">
            <tr className="thead-dark">
              <th className="thead-dark" scope="col">Time Slot</th>
              <th className="thead-dark" scope="col">Current Planned Shifts	</th>
              <th className="thead-dark" scope="col">Presents</th>
              <th className="thead-dark" scope="col">Absents</th>
              <th className="thead-dark" scope="col">Late Arrivals</th>
              <th className="thead-dark" scope="col">Early Left</th>
              <th className="thead-dark" scope="col">Leaves</th>
              <th className="thead-dark" scope="col">Travel</th>
              <th className="thead-dark" scope="col">Holiday</th>
            </tr>
          </thead>
          <tbody>
            { element.time_slots && element.time_slots.map((timeslot, index) =>
              <tr>
                <td>{ timeslot.time_slot }</td>
                <td>{ timeslot.head_count }</td>
                <td>{ timeslot.present_count }</td>
                <td>{ timeslot.absent_count }</td>
                <td>{ timeslot.late_count }</td>
                <td>{ timeslot.early_leave_count }</td>
                <td>{ timeslot.leave_count }</td>
                <td>{ timeslot.travel_count }</td>
                <td>{ timeslot.holiday_count }</td>
              </tr>
            ) }
            <br />
          </tbody>
        </table>
      })
    });
    return child;
  }

  const getCollapsitems = (itemData) => {
    return <Collapse items={ itemData } defaultActiveKey={ companyKey } />
  }



  return (

    <div className='widgetbox'>
      <Row gutter={ 10 }>
        <Col span={ 12 } >
          <h3>Attendance Drill Down By Branch</h3>
        </Col>
        <Col span={ 12 } style={ {
          textAlign: 'right',
        } }>
          <div className='changeDate'>
            <InputDate onChange={ setDate } defaultValue={ dayjs(date) } placeholder="Date" />
          </div>
        </Col>
      </Row>
      <Spin spinning={ loader } tip="Loading...">
        <div className='widgetcontent'>
          <Row>
            <Col span={ 24 } style={ {
              paddingRight: '20px',
            } }>
              { !loader && collapsData && companyKey && getCollapsitems(collapsData) }
            </Col>
          </Row>
        </div>
      </Spin>
    </div>

  )

}

export default AttendanceDrillByBranch;