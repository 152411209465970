import { useEffect, useState } from "react";
import { Form, Divider, Row, Col, Collapse, Checkbox, } from "antd";
import { trans, InputText, InputTextArea, InputDate ,TableWithFilter} from "@comps/components"
import { Commons, Const } from "../../constants"
import dayjs from 'dayjs'
import ViewPolicySlabList from "@mods/common/ViewPolicySlabList";
const CreatePreview = (props) => {
  const langKey = Const["attendancePolicyPlanner"].lngKey
  const langKeyTravelPolicy = Const["travelPolicy"].lngKey
  const formName = "createMyRoaster";
  const [record, setRecord] = useState()
  const [loading, setLoading] = useState(true);
  const [employees, setEmployees] = useState([])
  const [items, setItems] = useState([])
  const [form] = Form.useForm()
  useEffect(() => {
    setData(props.record)
  }, [props])

  const [activeItem, setActiveItem] = useState(null); // State to track the active accordion item

  const handleItemClick = (item) => {

    setActiveItem(item);
  };

  const setData = (response) => {
    setLoading(false)
    setEmployees(response.employments ? response.employments : [])
    setRecord(response)
    form.setFieldsValue({
      name: response.name,
      date: response.date ? dayjs(response.date) : null,
      for_all: response.for_all
    })

    let data = response;

    Object.keys(data).forEach((each, key) => {
      if (typeof data[each] === 'object' && data[each] && data[each].id !== undefined && data[each].name !== undefined) {

        items.push(
          {
            key: key,
            label: toTitleCase(each),
            children: getChildren(data, each)

          }
        )
      }
    });
    setItems(items)
  }

  const getChildren = (data, each) => {
    const slabFields = [

    ]

    if (data[each].slabs !== undefined && data[each].slabs.length > 0) {
      slabFields.push(
        {
          name: 'start_mins',
          label: 'Text Field',
          type: 'number',
          rules: '',
          langkey: 'start_mins',
        },
        {
          name: 'end_mins',
          label: 'Text Field',
          type: 'number',
          rules: '',
          langkey: 'end_mins',
        },
        {
          name: 'min_times',
          label: 'Text Field',
          type: 'number',
          rules: '',
          langkey: 'min_times',
        },
        {
          name: 'max_times',
          label: 'Text Field',
          type: 'number',
          rules: '',
          langkey: 'max_times',
        },
        {
          name: 'repeat_name',
          label: 'Text Field',
          type: 'text',
          rules: '',
          langkey: 'repeat_name',
        },
      )
    }

    return (
      <>
        <Form { ...formLayout } name={ formName } disabled={ true } initialValues={ data[each] } >
          <Row gutter={ 30 }>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="name" rules={ rules.name } label={ trans(Commons.name) }
                className="da-mb-16"

              >
                <InputText placeholder="Name" />
              </Form.Item>
            </Col>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="description" rules={ rules.description } label={ trans(Commons.description) }
                className="da-mb-16"

              >
                <InputTextArea placeholder="Description" />
              </Form.Item>
            </Col>
          </Row>

          <Divider> { trans(Commons.slabs) }</Divider>

          { data[each].slabs !== undefined && data[each].slabs.length > 0 &&

            <ViewPolicySlabList fields={ slabFields } disabled={ props.disabled } type='planner' />
          }


        </Form>
      </>
    )

  }

  function toTitleCase(str) {
    return str
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  //=============
  return (
    <>
      <Form { ...formLayout } name={ formName } form={ form } disabled={ true } >
        <Row gutter={ 30 }>
          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="name" rules={ rules.name } label={ trans(`${langKey}.name`) }
              className="da-mb-16"

            >
              <InputText placeholder={ trans(`${langKey}.name`) } />
            </Form.Item>
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="date" rules={ rules.date } label={ trans(`${langKey}.date`) }
              className="da-mb-16"

            >
              <InputDate />
            </Form.Item>
          </Col>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
            <Form.Item name="for_all" rules={ rules.for_all } label={ trans(`${langKey}.for_all`) }
              className="da-mb-10"

              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>
          </Col>
        </Row>

      </Form>

      <Collapse accordion items={ items } activeItem={ activeItem } onItemClick={ handleItemClick } />

      { employees.length > 0 && <>
        <h5 className="formlist-title">Employees</h5>
        <div style={ {
          overflowY: 'scroll',
          maxHeight: '50vh'
        } }>
          <TableWithFilter columns={ [{
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
          },
          {
            title: 'designation',
            dataIndex: 'designation_name',
            key: 'designation',
          },] }

            dataSource={ employees }
          >
          </TableWithFilter>
        </div>
      </>

      }
    </>
  )
}

export default CreatePreview


const rules = {
  start_date: [
    { required: true, message: 'Required' },
  ],
  end_date: [
    { required: true, message: 'Required' },
  ],
  repeat: [
    { required: true, message: 'Required' },
  ],
  font_colour: [
    { required: true, message: 'Required' },
  ],
  bg_colour: [
    { required: true, message: 'Required' },
  ],
  days: [
    { required: true, message: 'Please Select At least one day' },
  ],

};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}
