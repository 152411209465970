import { useState, useEffect } from "react";
import {
  ButtonComponent,
  trans,
  HeaderComponent,
} from "@comps/components";
import { makeRequest } from "@utils/helpers";
import { Commons, Const } from "../constants";
import { message } from 'antd';
import { FilterComponent, FilterButton, PaginationComponent } from "@comps/components";
import { downloadSalarySheetReq, getAllDataReq, getFilterReq,downloadDepartmentSalarySheetReq } from "./requests";
import { DownloadOutlined } from "@ant-design/icons";
import { handleDownloadFile } from "@utils/helpers";
import { Spin } from 'antd';

const langKey = Const["salarySheets"].lngKey;
const pageConfig = {
  headers: {
    title: trans(`${langKey}.moduleName`),
    breadcrumb: [
      {
        name: trans(`${langKey}.moduleName`),
        path: Const["salarySheets"].route
      }
    ]
  }
}
//global variables
var col_span_for_employee = 0;
var col_span_for_deduction = 0;
var col_span_for_earning = 0;
var col_span_for_reimbursement = 0;
var filters='';
const IndexSalarySheets = (props) => {
  const [dataSource, setDataSource] = useState([]);
  const [loader, setLoader] = useState(false);
  const [childComponent, setChildComponent] = useState(null);
  const [filerComponent, setFilterComponent] = useState(null);
  const [allData, setAllData] = useState({});
  // const [filters, setFilters] = useState();
  const [loading, setLoading] = useState(false)
  const [payitems, setPayitems] = useState({})
  const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState({
    "total": 0,
    "count": 0,
    "per_page": 0,
    "current_page": 0,
    "total_pages": 0,
    "links": {}
  });

  // ==================================
  //   Use States End
  // ==================================

  // ==================================
  //   Table Data End
  // ==================================
  // ==================================
  //   Table Data End
  // ==================================

  useEffect(() => {
    getAllData(filters)
    // eslint-disable-next-line
  }, [currentPage]);

  const getAllData = (fil) => {
    if (!fil) {
      onOpenFilter()
      return
    }
    setLoading(true)
   filters=fil
    setChildComponent(null)
    const payload = {
      page: currentPage,
      filters: filters
    }
    makeRequest(setLoader, getAllDataReq, payload, onSuccess, onGetAllDataError);
    onCloseFilter()
  };

  const onSuccess = (data, response) => {
    setAllData(response);
    setPayitems(response.data.payitems)
    setDataSource(response.data.employments.data);
    setPagination(response.data.employments)
    if (response.data.employments.data.length < 1) {
      message.info('Salary Not Found with given filters');
      onOpenFilter();
      setLoading(false)
      return
    }
    if (response.message !== undefined) {

    }
    setLoading(false)
    onCloseFilter()
  };
  const onGetAllDataError = (err, res) => {
    setLoading(false)
    message.error(res.response.data.message);
    onCloseFilter()
  }

  const onDownload = (type) => {
    setLoading(true)
    const payload = {
      filters: `${filters};download_type_|${type};`,
    }
    makeRequest(setLoader, downloadSalarySheetReq, payload, onDownloadSuccess, onDownloadSuccess);
    //makeRequestStateless(downloadSalarySheetReq, payload, onDownloadSuccess, onDownloadSuccess);
  }
  const onDownloadDepartmentWise = (type) => {
    setLoading(true)
    const payload = {
      filters: `${filters};download_type_|${type};`,
    }
    makeRequest(setLoader, downloadDepartmentSalarySheetReq, payload, onDownloadSuccess, onDownloadSuccess);
    //makeRequestStateless(downloadSalarySheetReq, payload, onDownloadSuccess, onDownloadSuccess);
  }

  const onDownloadSuccess = (err, res) => {
    setLoading(false)
    if (res.code === undefined) {
      const fileExtension = res.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ? 'xlsx' : 'pdf'
      const fileName = `SalarySheet.${fileExtension}`
      handleDownloadFile(fileName, res)
    }
    else {
      message.error(res.message)
    }
  }

  const onError = (err, res) => {
    message.error(res.response.data.message);
    //setErrors(errorList);
  }
  //---------------filters-----------------------
  const onOpenFilter = () => {
    setFilterComponent(<FilterComponent filters={ filterFields } modkey={props.modkey} selected={ filters } api={ getFilterReq } setCurrentPage={setCurrentPage} onFilter={ onFilter } onCloseFilter={ onCloseFilter }
    />
    )
  }

  const onFilter = (filters) => {
    getAllData(filters)
  }
  const onCloseFilter = () => {
    setFilterComponent(null);
  }
  //----end filter--------------------
  //get payitems
  const getPayitems = (each) => {
    col_span_for_earning = payitems.earning ? payitems.earning.length : 0;
    col_span_for_deduction = payitems.deduction ? payitems.deduction.length : 0;
    var html = employment(each);
    var earnings = 0;
    if (payitems.earning) {
      col_span_for_earning = payitems.earning.length;
      payitems.earning.forEach(function (row) {
        var payitem = each.computed[row.id];
        if (payitem) {
          earnings += payitem.amount;
          html += getTd(payitem.amount);
        } else {
          html += getTd("-");
        }
      });

      col_span_for_earning++;
      if (each.cpl) {
        html += getTd(each.cpl);
      } else {
        html += getTd("-");
      }
    }
    var deductions = 0;
    if (payitems.deduction) {
      col_span_for_deduction = payitems.deduction.length;
      payitems.deduction.forEach(function (row) {
        var payitem = each.computed[row.id];
        if (payitem) {
          deductions += payitem.amount;
          if (payitem.details) {
            html += getTd(payitem.details);
          } else {
            html += getTd(payitem.amount);
          }
        } else {
          html += getTd("-");
        }
      });

      col_span_for_deduction++;
      if (each.quota) {
        html += getTd(each.quota);
      } else {
        html += getTd("-");
      }
    }
    var reimbursements = 0;
    if (payitems.reimbursement) {
      col_span_for_reimbursement = payitems.reimbursement.length;
      payitems.reimbursement.forEach(function (row) {
        var payitem = each.computed[row.id];
        if (payitem) {
          reimbursements += payitem.amount;
          html += getTd(payitem.details);
        } else {
          html += getTd("-");
        }
      });
    }
    html += total(each,earnings, deductions, reimbursements);
    return html;
  };
  //get emplpoyee info
  function employment(each) {
    col_span_for_employee = 1;
    var html = '<td className="fixed-side" ><span className="display-inline">';
    if (each.profile_pic_url === "images/arnold-avatar.jpg") {
      each.profile_pic_url = '/images/arnold-avatar.jpg'
    }
    html += '<img width=40 height=40 className="thumb img-circle rounded-circle" src="' + each.profile_pic_url + '"/> ';
    html += each.full_name_code;
    html += "</span></td>";

    col_span_for_employee++;
    html += "<td>";
    html += each.branch_name;
    html += "</td>";

    col_span_for_employee++;
    html += "<td>";
    html += each.department_name;
    html += "</td>";

    col_span_for_employee++;
    html += "<td>";
    html += each.designation_name;
    html += "</td>";

    col_span_for_employee++;
    html += '<td className="text-right">';
    html += each.actual_salary.toFixed(2);
    html += "</td>";

    // col_span_for_employee++;
    // html += "<td>";
    // html += each.no_of_days;
    // html += "</td>";

    // col_span_for_employee++;
    // html += "<td>";
    // html += each.worked_minutes + "minutes";
    // html += "</td>";
    col_span_for_employee++;
    html += '<td><span className="label ' + each.payslip_class + '">';
    html += each.payslip_status;
    html += "</span></td>";

    return html;
  }

  // get td columns
  function getTd(amount) {

    if (Array.isArray(amount)) {
      var html = "<td>";
      var total = 0;
      amount.forEach(function (row) {
        total += row.amount;
        html += row.name + ": " + Math.round(row.amount, 2) + "<br/>";
      });
      html += '<hr className="mb-0 mt-0">Total: ' + Math.round(total, 2);
      html += "</td>";
      if (total) {
        return html;
      }
      return "<td>" + Math.round(0, 2) + "</td>";
    }
    if (amount === "-") {
      return "<td className='text-primary'>" + amount + "</td>";
    }
    return "<td>" + Math.round(amount, 2) + "</td>";
  }

  //get total 
  function total(each,earnings, deductions, reimbursement) {
    var html = "";
    html += getTd(earnings);
    html += getTd(deductions);
    html += getTd(reimbursement);
    // html += getTd(earnings - deductions + reimbursement);
    html += getTd(each.cash_salary);
    html += getTd(each.bank_salary);

    return html;
  }
  //
  return (
    <>
      { filerComponent }
      { childComponent }
      <HeaderComponent headers={ pageConfig.headers } modkey={props.modkey}>
        {/* <ButtonComponent text='PDF' icon={ <DownloadOutlined /> } type='primary' onClick={ () => onDownload('pdf') } disabled={ dataSource.length < 1 } /> */}
        <ButtonComponent text='Employee Wise' icon={ <DownloadOutlined /> } type='primary' onClick={ () => onDownload('xlsx') } disabled={ dataSource.length < 1 } />
        <ButtonComponent text='Department Wise' icon={ <DownloadOutlined /> } type='primary' onClick={ () => onDownloadDepartmentWise('xlsx') } disabled={ dataSource.length < 1 } />

        <ButtonComponent text='Refresh' type='primary' onClick={ () => getAllData(filters !== undefined ? filters : '') } />
        <FilterButton onClick={ onOpenFilter } />

      </HeaderComponent>
      <Spin spinning={ loading }>
        {
          filters && dataSource.length > 0 &&
          <div className="basetable-container">
            <table className="table table-bordered table-responsive">
              <thead>
                <tr className="group">
                  <th className="text-center" colspan={ col_span_for_employee }>{ trans(Commons.employee) }</th>
                  {
                    payitems.earning &&
                    <th className="text-center" colspan={ col_span_for_earning }>
                      <strong>{ trans(`${langKey}.earnings`) }</strong>
                    </th>
                  }
                  {
                    payitems.deduction &&
                    <th className="text-center" colspan={ col_span_for_deduction }>
                      <strong>{ trans(`${langKey}.deductions`) }</strong>
                    </th>
                  }
                  {
                    payitems.reimbursement &&
                    <th className="text-center" colspan={ col_span_for_reimbursement }>
                      <strong>{ trans(`${langKey}.reimbursements`) }</strong>
                    </th>
                  }

                  <th className="text-center" colspan="6"><strong>{ trans(`${langKey}.totals`) }</strong></th>
                 
                </tr>
                <tr>
                  <th className="w-100" style={ {
                    minWidth: '350px'
                  } } >{ trans(Commons.employee) }</th>
                  <th >{ trans(Commons.branch) }</th>
                  <th >{ trans(Commons.department) }</th>
                  <th>{ trans(Commons.designation) }</th>
                  <th style={ {
                    minWidth: '150px'
                  } } >{ trans(`${langKey}.actual_salary`) }</th>
                  {/* <th>DAYS</th>
              <th>WORKING_HOURS</th> */}
                  <th style={ {
                    minWidth: '150px'
                  } }>{ trans(`${langKey}.status`) }</th>
                  { payitems.earning !== undefined && payitems.earning.map(payitem => (
                    <th style={ {
                      minWidth: '200px'
                    } }>{ payitem.name }</th>
                  )) }

                  { payitems.earning !== undefined &&

                    <th >{ trans(`${langKey}.cpl`) }</th>
                  }
                  { payitems.deduction !== undefined && payitems.deduction.map(payitem => (
                    <th style={ {
                      minWidth: '250px'
                    } }>{ payitem.name }</th>
                  )) }

                  {
                    payitems.deduction && <th>{ trans(`${langKey}.quota`) }</th>
                  }

                  { payitems.reimbursement !== undefined && payitems.reimbursement.map(payitem => (
                    <th >{ payitem.name }</th>
                  )) }
                  <th style={ {
                    minWidth: '150px'
                  } }>{ trans(`${langKey}.total_earnings`) }</th>
                  <th style={ {
                    minWidth: '150px'
                  } }>{ trans(`${langKey}.total_deductions`) }</th>
                  <th>{ trans(`${langKey}.reimbursements`) }</th>
                  {/* <th>{ trans(`${langKey}.net_pay`) }</th> */}
                  <th>{ "Net Cash" }</th>
                  <th>{ "Net Bank" }</th>
                </tr>
              </thead>
              <tbody>
                { dataSource.length > 0 && dataSource.map(each => (
                  <tr dangerouslySetInnerHTML={ { __html: getPayitems(each) } } />
                )) }

              </tbody>
            </table>

          </div>
        }
      </Spin>
     {filters && dataSource.length>0 &&  <PaginationComponent pagination={ pagination } modkey={props.modkey} setCurrentPage={ setCurrentPage } currentPage={ currentPage } />}
    </>
  );
};

export default IndexSalarySheets;
const filterFields = [

  {
    type: "select",
    placeholder: 'Location',
    key: "location",
    data_key: "organization_structure_data.location",
  },
  {
    type: "select",
    placeholder: 'Branch',
    key: "branch",
    data_key: "organization_structure_data.branch",

  },
  {
    type: "select",
    placeholder: 'Departments',
    key: "department",
    data_key: "organization_structure_data.department",
    filterKey: "hierarchy_code"
  },
  {
    type: "select",
    placeholder: 'Employee',
    key: "employees",
    data_key: "supporting_data.employees",
   resource_type: 'employment'
    //is_resource: true
  },
  {
    type: "select",
    placeholder: 'Payroll',
    key: "payroll",
    data_key: "supporting_data.payroll",
    required: true
    //is_resource: true
  },
  {
    type: "date",
    placeholder: "Month",
    key: "month",
    required: true
  },

]
