import React, { useEffect, useState } from "react";
import { Form, Skeleton, message, Row, Col } from "antd";
import { SaveButton, CancelButton, ModalComponent, trans, InputDate, InputTextArea, SelectWithSearch } from "@comps/components"
import { makeRequest, getErrorProps,makeRequestStateless } from "@utils/helpers";
import { getOrgRelatedDataReq, updateDataReq } from "../requests";
import { Commons, Const } from "../../../constants";
import dayjs from "dayjs";
import { createSelectList } from '@comps/commonFunctions';
import EmployeeInfo from '@mods/common/EmployeeInfo';

const langKey = Const["companyTransfers"].lngKey
const formName = "createTransferRequest";
const UpdateCompanyTransferRequest = (props) => {
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(true);
  const [saveLoader, setSaveLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [locations, setlocations] = useState([]);
  const [branches, setbranches] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [linemanagers, setlinemanagers] = useState([]);
  const [payrolls, setPayrolls] = useState([]);
  const [form] = Form.useForm()
  useEffect(() => {
    getRelated()
    // eslint-disable-next-line
  }, []);

  const getRelated = () => {
    makeRequestStateless(getOrgRelatedDataReq, null, ongetRelated, false)
  }
  const ongetRelated = (data,response) => {
    if(data){
      
      setDepartments(createSelectList(data.departments))
    setlocations(createSelectList(data.locations))
    setbranches(createSelectList(data.branches))
    setlinemanagers(createSelectList(data.linemanagers))
    setPayrolls(createSelectList(data.payrolls))
    }
    
    setLoading(false)
    setData()
  }
  const setData = () => {
   
    form.setFieldsValue(
      {
        employment_id: props.record.requestor.full_name_code,
        location_id: props.record.location_id,
        branch_id: props.record.branch_id,
        department_id: props.record.department_id,
        linemanager_id: props.record.linemanager_id,
        payroll_id: props.record.payroll_id,
        date: dayjs(props.record.date),
        description: props.record.description
      }
    )
  }

  const onSubmit = (data) => {
    const payload = {
      file_attachment: undefined,
      transfer: { "id": props.record.id, "employment_id": props.record.employment_id, "location_id": data.location_id, "branch_id": data.branch_id, "department_id": data.department_id, "linemanager_id": data.linemanager_id, "payroll_id": data.payroll_id, "date": dayjs(data.data).format('YYYY-MM-DD'), "description": data.description },
      "id": props.record.id
    }
    makeRequest(setLoader, updateDataReq, payload, onSuccess, onError);
    setSaveLoader(true)
  }

  const onSuccess = (data, res) => {

    setSaveLoader(false)
    message.success("Saved successfully");
    props.onCompleted(data.transfers.data);
  }

  const onError = (err, res) => {

    setSaveLoader(false)
    message.error(res.response.data.message);
  }
  //-----------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------
  const footer = [
    props.disabled ? '' :
      <SaveButton loading={ saveLoader } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];
  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------
  return (
    <ModalComponent top={ 20 } width={ 1000 } maskClosable={ false }
      mainTitle={ trans(Commons[props.disabled ? 'view' : 'update']) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Skeleton loading={ loading }>
        <EmployeeInfo record={ props.record.requestor && props.record.requestor } />
        <Form { ...formLayout } form={ form } name={ formName } onFinish={ onSubmit } disabled={ props.disabled }>
          <Row gutter={ 30 }>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="location_id" rules={ rules.location_id } label={ trans(`${langKey}.location_id`) }
                className="da-mb-16"
                size="small"
              >
                <SelectWithSearch
                  placeholder={ trans(`${langKey}.location_id`) }
                  options={ locations }
                />
              </Form.Item>
            </Col>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="branch_id" rules={ rules.branch_id } label={ trans(`${langKey}.branch_id`) }
                className="da-mb-16"
                size="small"
              >
                <SelectWithSearch
                  placeholder={ trans(`${langKey}.branch_id`) }
                  options={ branches }
                />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="department_id" rules={ rules.department_id } label={ trans(`${langKey}.department_id`) }
                className="da-mb-16"
                size="small"
              >
                <SelectWithSearch
                  placeholder={ trans(`${langKey}.department_id`) }
                  options={ departments }
                />
              </Form.Item>
            </Col>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="payroll_id" rules={ rules.payroll_id } label={ trans(`${langKey}.payroll_id`) }
                className="da-mb-16"
                size="small"
                initialValue={props.record.payroll_id}
              >
                <SelectWithSearch
                  placeholder={ trans(`${langKey}.payroll_id`) }
                  options={ payrolls }
                />
              </Form.Item>
            </Col>
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="linemanager_id" rules={ rules.linemanager_id } label={ trans(`${langKey}.linemanager_id`) }
                className="da-mb-16"
                size="small"
              >
                <SelectWithSearch
                  placeholder={ trans(`${langKey}.linemanager_id`) }
                  options={ linemanagers }
                />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="date" rules={ rules.date } label={ trans(`${langKey}.date`) }
                className="da-mb-16"
                { ...getErrorProps(errors['date']) } size="small"
              >
                <InputDate />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="description" rules={ rules.description } label={ trans(`${langKey}.description`) }
                className="da-mb-16"
                { ...getErrorProps(errors['description']) } size="small"
              >
                <InputTextArea placeholder={ trans(`${langKey}.description`) } />
              </Form.Item>
            </Col>
          </Row>

        </Form>
      </Skeleton>
    </ModalComponent>
  )
}

export default UpdateCompanyTransferRequest

const rules = {
  date: [
    { required: true, message: trans(`${langKey}.date`) },
  ],
  location_id: [
    { required: true, message: trans(`${langKey}.location_id`) },
  ],
  branch_id: [
    { required: true, message: trans(`${langKey}.branch_id`) },
  ],
  department_id: [
    { required: true, message: trans(`${langKey}.department_id`) },
  ],
  payroll_id: [
    { required: true, message: trans(`${langKey}.payroll_id`) },
  ],
  linemanager_id: [
    { required: true, message: trans(`${langKey}.linemanager_id`) },
  ],
  employment_id: [
    { required: true, message: trans(`${langKey}.employment_id`) },
  ],
};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}
