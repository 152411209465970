
import { HeaderComponent, BodyComponent, SelectWithSearch } from '@comps/components';
import { useState, useEffect } from 'react';
import OrganizationChart from "@dabeng/react-orgchart";
import "./chart.css"
import { getDataByIdTypeReq, getFilterDataReq } from './requests';
import { makeRequest } from '@utils/helpers';
import { message, Spin } from 'antd';

// ==================================
//   Page Breadcrumbs Start
// ==================================
const pageConfig = {
  headers: {
    title: 'Organization Structure Chart',
    breadcrumb: []
  }
}

var clickedNode={};
// ==================================
//   Page Breadcrumbs End
// ==================================

// ==================================
//   Use States Start
// ==================================

const IndexOrgChart = (props) => {
  const [loader, setLoader] = useState(false);
  const [childComponent, setChildComponent] = useState(null);
  const [filters, setFilters] = useState();
  const [data, setData] = useState([]);
  const [chartType,setChartType]=useState('organization');
  // ==================================
  //   Use States End
  // ==================================


  useEffect(() => {
    getOrgFilter(chartType);
    // eslint-disable-next-line
  }, [filters]);

  const getOrgFilter = (payload) => {
    setChartType(payload);
    makeRequest(setLoader, getFilterDataReq, payload, onGetFilterSuccess, onGetFilterError)
  }

  const onGetFilterSuccess = (res) => {
   const d=getFormatedData(res[0]);
    setData(d)
  }

  const getFormatedData=(list)=>{
    return list;
  }

  const onGetFilterError = (error) => {
  }

  const getSubordinates=(id)=>{
    const payload={
      id:id,
      chartType:chartType
    }
    makeRequest(setLoader, getDataByIdTypeReq,payload , onGetSubordinate, onGetFilterError)
  }
const onGetSubordinate=(res)=>{
  const d= updateChildrenById(data,clickedNode.id,res.children);
  //setData(d);
}

// Function to find a child with a specific ID and update or add children to the main nested object
function updateChildrenById(mainObject, idToFind, updatedChildren) {
  if (mainObject.id === idToFind) {
      mainObject.children = updatedChildren;
      return true; // Return true if the child is found and updated
  } else if (mainObject.children) {
      for (let child of mainObject.children) {
          if (updateChildrenById(child, idToFind, updatedChildren)) {
              return true; // Return true if the child is found and updated in the nested children
          }
      }
  }
  setData(data);
  return false; // Return false if the child is not found
}

const onClickNode=(node)=>{
  clickedNode=node;
if(node.is_leaf){
  message.info('This Node has no child!');
  return;
}
if(node.children && node.children.length!==undefined && node.children.length>0){
   return;
}
else{
  getSubordinates(node.id)
}
}

const getBorderColor=(node)=>{
  // if(node.is_company){
  //   return '5px solid #FF9B1B';
  // }
  if(!node.is_leaf){
return '5px solid #00AF53';
  }
  if(node.is_leaf){
    return '5px solid #f4581f'
  }
}
  // ==================================
  //   Component JSX Start
  // ==================================

  const nodeTemplate = ({ nodeData }) => {
    return (
    nodeData.name && <div onClick={()=>onClickNode(nodeData)} className='orgchatbox' style={ {
        borderTop:getBorderColor(nodeData),cursor:nodeData.is_leaf?'':'pointer'
      } }>
        <div className='orgimage'>
          { nodeData.profile_pic_url === 'images/arnold-avatar.jpg' && <img src="/images/arnold-avatar.jpg" alt='noimage' /> }
          { nodeData.profile_pic_url !== 'images/arnold-avatar.jpg' && nodeData.profile_pic_url !== 'images/building.png' && <img src={ nodeData.profile_pic_url } alt='noimage' /> }
          { nodeData.profile_pic_url === 'images/building.png' && <img src="/images/building.png" alt='noimage' /> }
        </div>
        <div className='orgname'>{ nodeData.name }</div>
        <div className='orginfo'>
          <div className='orgkey'>Code</div>
          <div className='orgcode'>{ nodeData.title }</div>
          <div className='clearboth'></div>
          { nodeData.extras && nodeData.extras.map((item, index) =>
            <>
              <div className='orgkey'>{ item.key }</div>
              <div className='orgcode'>{ item.value }</div>
              <div className='clearboth'></div>
            </>
          ) }
        </div>
      </div>
    );
  }

  return (
    <>
      <HeaderComponent headers={ pageConfig.headers } modkey={props.modkey}>
        
        <span class="ant-page-header-heading-title" title="Organization Structure">Chart Type</span>
        <SelectWithSearch style={ {
          width: '400px',
        } }
          onChange={ (e) => getOrgFilter(e) }
          options={ [
            {
              "value": "organization",
              "label": "Organization"
            },
            {
              "value": "department",
              "label": "Department"
            },
            {
              "value": "employee",
              "label": "Employee"
            }
          ] } defaultValue='organization' />
      </HeaderComponent>
      <BodyComponent>
      <div style={{marginLeft:10}}>
          <div>
            <span></span><span></span>
          <span >Parent Node: </span><span style={{backgroundColor:'#00AF53',paddingRight:'20px',marginRight:'10px'}}></span>
          <span>Child Node: </span><span style={{backgroundColor:'#f4581f',paddingRight:'20px'}}></span>
          </div>
         
        </div>
        <Spin spinning={ loader } tip="Loading" size="large">
          { data && data.name &&
            <OrganizationChart datasource={ data } pan={ true } zoom={ true } NodeTemplate={ nodeTemplate } />
          }
        </Spin>
      </BodyComponent>
    </>
  );

}
// ==================================
//   Component JSX End
// ==================================

export default IndexOrgChart
