import React from "react";
import { Route, Routes } from "react-router-dom";

import IndexExpenseTypes from "./setting/expenseType/IndexExpenseTypes";
import IndexCompanyExpenses from "./companyExpenses/IndexCompanyExpenses";
import IndexExpenseApprovals from "./expenseApprovals/IndexExpenseApprovals";
import IndexMyExpenses from "./myExpenses/IndexMyExpenses";
import IndexExpenseDelegates from "./expenseApprovals/IndexExpenseDelegates";


const ExpenseModules = () => {
  return (
    <Routes>
      <Route path="settings/types" element={ <IndexExpenseTypes modkey="expenseTypes"/> } />
      <Route path="company-requests" element={ <IndexCompanyExpenses modkey="companyExpenses"/> } />
      <Route path="requests" element={ <IndexMyExpenses modkey="expenseRequests"/> } />
      <Route path="approvals" element={ <IndexExpenseApprovals api='expenseApprovals' modkey="expenseApprovals"/> } />
      <Route path="delegates" element={ <IndexExpenseApprovals api='expenseDelegates' modkey="expenseDelegates"/> } />
    </Routes>
  );
}

export default ExpenseModules;
