import React, { useState } from "react";
import { Form, message, Divider, Upload } from "antd";
import {
  SaveButton, CancelButton, ModalComponent, trans,
  TableComponent, ButtonComponent

} from "@comps/components"
import { makeRequestStateless } from "@utils/helpers";
import { importSampleReq } from "./requests";
import { Commons, Const } from "./constants";
import { useSelector } from "react-redux";

const langKey = Const["import"].lngKey
const formName = "importAttendance";

const ImportSample = (props) => {
  const authUser = useSelector((state) => state.auth.authUser);
  const [saving, setSaving] = useState(false)
  const [childComponent, setChildComponent] = useState(null)
  const [hasErrors, setHaserrors] = useState(false);

  const errorColumns = [
    {
      key: 'row',
      title: trans(`${langKey}.row`),
      dataIndex: "row",
    },
    {
      key: 'column',
      title: trans(`${langKey}.column`),
      dataIndex: "column",
    },
    {
      key: 'value',
      title: trans(`${langKey}.value`),
      dataIndex: "value",
    },
    {
      key: 'message',
      title: trans(`${langKey}.message`),
      dataIndex: "message",
    },
  ]

  const onSubmit = (data) => {

    let file = null
    if (data.file_attachment === undefined) {
      message.info("Please Select File To Import!")
      return
    }
    if (data.file_attachment !== undefined) {
      file = data.file_attachment.fileList.length > 0 ? data.file_attachment.file : null
    }
    const payload = { api: props.api, file_attachment: file, attendance_log_import: { attachment: { removed: false, org_name: file.name, key: "attachment" } }, user: authUser };
    setSaving(true)
    makeRequestStateless(importSampleReq, payload, onSuccess, onError);
  }


  const onSuccess = (data, res) => {

    setSaving(false)
    if (data && data.errors && data.errors.length!==undefined && data.errors.length === 0) {
      message.success(res.message);
      props.onCompleted(null)
      setChildComponent(null)
      setHaserrors(false)
      props.onSuccess()
    }
    if (data.errors !== undefined && data.errors.length > 0) {
      setHaserrors(true)
      setChildComponent(
        <>
          <Divider className="text-danger">Importing Errors</Divider>
         
          <TableComponent
            scroll={ { x: 'max-content',y:'max-content' } }
            columns={ errorColumns }
            dataSource={ data.errors }
            pagination={ false }
          />
         
        </>
      )
    }
  }

  const onError = (err, res) => {
    setHaserrors(true)
    setSaving(false)
    setChildComponent(null);
    message.error('Server Side Error!');
    if (res.response !== undefined) {
      message.error(res.response.data.message);
    }
  }

  //============uploading file============
  const [fileList, setFileList] = useState([]);

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  //=========================================
  const footer = [
    <SaveButton form={ formName } key="create_button" htmlType="submit" loading={ saving } />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 50 } width={ 1400 } maskClosable={ false }
      mainTitle={ trans(`${langKey}.import`) } subTitle={ props.module }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>

      <Form name={ formName } onFinish={ onSubmit } scrollToFirstError>

        { !hasErrors && <Form.Item name="file_attachment" rules={ rules.file_attachment } label={ trans(`${langKey}.file_attachment`) }
          className="da-mb-16"
          size="small"
        >
          <Upload
            accept=".xlsx"
            listType="picture"
            fileList={ fileList }
            onChange={ onChange }
            //onPreview={ onPreview }
            multiple={ false }
            beforeUpload={ (file) => {
              return false;
            } }
          >
            { fileList.length === 1 ? null : <ButtonComponent text="Upload" /> }
          </Upload>
        </Form.Item> }

      </Form>

      { childComponent }
    </ModalComponent>
  )
}

export default ImportSample

const rules = {
  file_attachment: [
    { required: true, message: trans(`${langKey}.file_attachment`) },
  ],

};