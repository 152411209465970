import { useEffect, useState } from "react";
import { Commons, Const } from "./constants";

import { CancelButton, ModalComponent, trans, TableComponent } from "@comps/components"
import moment from "moment";
const ViewApprovalHistory = (props) => {
  const langKey = Const["approvalHistory"].lngKey
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    setData();
  }, []);

  const setData = () => {
    if (!props) {
      return;
    }
    if (props.record.histories) {
      setDataSource(props.record.histories);
    }
  }

  const footer = [
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  const columns = [
    {
      key: 'requestee',
      title: trans(`${langKey}.requestee`),
      render: (record) => {
        return props.moduleName === "cancel_request" ? record.requestee_full_name :
          <div>{ record.requestee.full_name }<br /><small>{ record.requestee.emp_code }</small></div>
      },
    },
    {
      key: 'status',
      title: trans(`${langKey}.status`),
      render: (record) => {
        return record.status
      }
    },
    {
      key: 'remarks',
      title: trans(`${langKey}.remarks`),
      dataIndex: 'remarks'
    },
    {
      key: 'datetime',
      title: trans(`${langKey}.datetime`),
      render: (record) => {
        return moment(new Date(record.created_at)).format('YYYY-MM-DD hh:mm a')
      }
    },
  ];

  dataSource.length > 0 && dataSource[0]['approved_amount'] !== undefined && columns.push(
    {
      key: 'approved_amount',
      title: 'Approved Amount',
      dataIndex: 'approved_amount'
    },)
  return (
    <ModalComponent top={ 20 } width={ 600 } maskClosable={ false }
      mainTitle={ trans(Commons.view) } subTitle={ trans(`${langKey}.history`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) } disabled={ props.disabled } loader={ true }>
      <TableComponent
        columns={ columns }
        dataSource={ dataSource }
        pagination={ false }
      />
    </ModalComponent>

  )
}

export default ViewApprovalHistory