import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import { Skeleton } from 'antd'
import { makeRequestStateless } from "@utils/helpers";
import { generateReportReq } from "../requests";
import { useParams } from 'react-router-dom';
import { BodyComponent, HeaderComponent, CreateButton } from '@comps/components'
import { useNavigate } from 'react-router-dom';
import { saveAs } from 'file-saver';
import { DownloadOutlined } from '@ant-design/icons'
import dayjs from 'dayjs';

var columnsToDownload = []
const GenerateReport = (props) => {
  const { id } = useParams();
  const navigate = useNavigate()
  //use states start

  const [columns, setColumns] = useState([])
  const [dataSource, setDataSource] = useState([])
  const [loading, setLoading] = useState(true)
  const [report, setReport] = useState(null)

  const [hiddenColumns, setHiddenColumns] = useState([])
  const [visibleColumns, setVisibleColumns] = useState([])
  //var visibleColumns = []

  //render
  useEffect(() => {
    getInitalData();
    // eslint-disable-next-line
  }, []);

  const getInitalData = () => {
    const payload = {
      id: id
    }
    makeRequestStateless(generateReportReq, payload, onSuccessReportDataReq, onErrorReportData);
  }
  const onSuccessReportDataReq = (response) => {
    
    setColumns(response.columns);
    setDataSource(response.report_data.data === undefined ? response.report_data : response.report_data.data)
    setReport(response.report)
    const newColumns = []
    response.columns.forEach(column => {
      newColumns.push(
        {
          name: column.column_key,
          label: column.report_display_name,
          options: {
            filter: true,
            sort: true,
          }
        },
      )
    })

    columnsToDownload = newColumns
    setVisibleColumns(newColumns)
    setColumns(newColumns)
    setLoading(false)
  }
  const onErrorReportData = (response) => {

  }

  const options = {
    //filterType: 'checkbox',
    selectableRows: 'none',
    filter: true,
    download: true,
    // onColumnViewChange: (changedColumn, action) => {
    //   const column = columns.filter(col => col.name === changedColumn)
    //   if (action === 'add' && !columns.includes(column[0])) {
    //     setColumns([...columns, changedColumn])
    //   } else if (action === 'remove' && columns.includes(column[0])) {
    //     setColumns(columns.filter(col => col.name !== changedColumn))
    //   }
    // },
    downloadOptions: {
      filename: `${report && report.title}-${dayjs().format('YYYY-MM-DD')}`, // Set your custom file name here
      //columns: columnsToDownload,
    },
  };

  return (
    <BodyComponent>
      <HeaderComponent modkey={props.modkey}>
        <CreateButton onClick={ () => navigate('/reports/all-reports') } text='Back' />

      </HeaderComponent>
      <Skeleton loading={ loading }>
        <MUIDataTable
          title={ report && report.title !== undefined && report.title }
          data={ dataSource }
          columns={ columns }
          options={ options }
        />
      </Skeleton>
    </BodyComponent>
  );
}

export default GenerateReport

