import React, { useEffect, useState } from "react";
import { Divider, Form, Skeleton, message } from "antd";
import { SaveButton, InputText, CancelButton, ModalComponent, trans, InputCheck } from "@comps/components"
import { Commons, Const } from "../../constants";
import { makeRequest } from "@utils/helpers";
import EmployeeInfo from "@mods/common/EmployeeInfo";
import { HistoryOutlined } from "@ant-design/icons";
import { updateDataReq } from "../requests";
const langKey = Const["myPayslip"].lngKey
const formName = "viewMyPayslip";
const ViewMyPayslip = (props) => {
  const [loading, setLoading] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [loader, setLoader] = useState(false);
  const [form] = Form.useForm()
  useEffect(() => {
    setData()
    // eslint-disable-next-line
  }, []);

  const setData = () => {
    form.setFieldsValue(props.record)

  }

  const onSubmit = (data) => {

    const payitems = data.payitems
    const newItems = [];
    payitems.forEach((payitem) => {
      payitem.row_state = 'Updated';
      payitem.amount = payitem.amount ? Number(payitem.amount) : payitem.amount
      newItems.push(payitem)
    })
    const payslip = { ...props.record, payitems: newItems };
    const payload = { payslip: payslip }
    makeRequest(setLoader, updateDataReq, payload, onSuccess, onError);
    setSaveLoader(true)
  }

  const onSuccess = (data, res) => {

    setSaveLoader(false)
    message.success(res.message);
    props.onCompleted(res);
  }

  const onError = (err, res) => {

    setSaveLoader(false)
    message.error(res.response.data.message);
  }
  //-----------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------
  const footer = [
    props.disabled ? '' :
      <SaveButton loading={ saveLoader } form={ formName } key="create_button" htmlType="submit" />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];
  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------
  return (
    <ModalComponent top={ 20 } width={ 1000 } maskClosable={ false }
      mainTitle={ trans(Commons.view) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Skeleton loading={ loading }>

        <EmployeeInfo record={ props.record.requestor } />
        <Form { ...formLayout } form={ form } name={ formName } onFinish={ onSubmit } disabled={ props.disabled }>

          <Divider>{ trans(`${langKey}.payitems`) }</Divider>

          <Form.List name="payitems" >
            { (fields, { add, remove }) => (
              <>
                <div className="table-container">
                  <table className="table">
                    {
                      fields.length !== 0 && <thead>
                        <tr>
                          <th>{ trans(`${langKey}.payitem_name`) }</th>
                          <th>{ trans(`${langKey}.amount`) }</th>
                          <th>{ trans(`${langKey}.manually_updated`) }</th>
                          <th>{ trans(Commons.actions) }</th>
                        </tr>
                      </thead>
                    }
                    <tbody>
                      { fields.map(({ key, name, ...restField }) => (
                        <>
                          <tr>
                            <td style={ { minWidth: '200px' } }>
                              <Form.Item
                                { ...restField }
                                name={ [name, 'payitem_name'] }
                                rules={ rules.payitem_name }
                              >
                                <InputText disabled />
                              </Form.Item>
                            </td>
                            <td style={ { minWidth: '200px' } }>
                              <Form.Item
                                { ...restField }
                                name={ [name, 'amount'] }
                                rules={ rules.amount }

                              >
                                <InputText type="number" />
                              </Form.Item>
                            </td>
                            <td style={ { minWidth: '200px' } }>
                              <Form.Item
                                valuePropName="checked"
                                { ...restField }
                                name={ [name, 'manually_updated'] }
                                rules={ rules.manually_updated }
                              >
                                <InputCheck disabled={ true } />
                              </Form.Item>
                            </td>

                            <td>
                              <HistoryOutlined onClick={ () => {
                                //remove(name)

                                // setRows(key, 'Deleted')
                              } } />
                            </td>
                          </tr>
                        </>
                      )) }

                    </tbody>
                  </table>
                </div>
              </>
            ) }
          </Form.List>

        </Form>
      </Skeleton>
    </ModalComponent>
  )
}

export default ViewMyPayslip

const rules = {
  branch_id: [
    { required: true, message: trans(`${langKey}.branch_id`) },
  ],
  payitem_id: [
    { required: true, message: trans(`${langKey}.payitem_id`) },
  ],
  month: [
    { required: true, message: trans(`${langKey}.month`) },
  ],
  applicable_amount: [
    { required: true, message: trans(`${langKey}.applicable_amount`) },
  ],
  total_amount: [
    { required: true, message: trans(`${langKey}.total_amount`) },
  ],
  is_flat: [
    { required: true, message: trans(`${langKey}.is_flat`) },
  ],
};

const formLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14, offset: 2 },
  labelAlign: "left"
}

