import { get, del, postFd } from "@utils/axios";

const api = "employee";

export const getAllEducationsReq = (payload) => {
  return get(`${api}/educations?page=${payload.page}&filters=${payload.filters}`);
};

export const createEducationReq = (payload) => {
  return postFd(`${api}/educations?&filters=${payload.filters}`, payload);
};

export const updateEducationReq = (payload) => {
  return postFd(`${api}/educations/${payload.id}/update?&filters=${payload.filters}`, payload);
};

export const deleteEducationReq = (id) => {
  return del(`${api}/educations/${id}`);
};

//-----------on create
export const getAllEmployeesReq = () => {
  return get("resources/employee?page=1");
};

export const getRelatedDataReq = () => {
  return get(`${api}/educations/get-related-data?`)
}

export const getFilterReq = () => {
  return get(`filters?company_id=1&fields=page=1&filters=supporting_fields_|employees;preset_|employee_educations;`)
}
